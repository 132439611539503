import {
    Map
} from 'immutable';
import {
    createFormData,
    AppKey
} from 'utils/configuration';
import Service from 'services/api.service';
import {
    ApiUrl
} from 'services/api-url';
import {
    createSaveLoading
} from 'core/app.store';

const service = new Service();
/** Action Types */

/** Actions */

export const Feedback = (data, formData = new FormData()) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix, formData);
        return service.post(ApiUrl.PostFeedback, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

export const Report = (data, formData = new FormData()) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix, formData);
        return service.post(ApiUrl.PostReport, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

// Initial state
export const initialState = Map({
    isLoading: false
}).toJS();

/* Reducers */
export default function FeedbackReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}