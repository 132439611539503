/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoogleMap from "modules/gmap/googlemap-mobile.view";
import {
  NotificationMessageType,
  NewGuid,
  CheckFileSize,
  CheckMaxFile,
  CheckFileTypeMedia,
} from "utils/configuration";
import * as viVN from "translation/vi-VN.json";
import AddNotification from "components/react-notifications/react-notifications";
import * as feedbackActions from "redux/stores/feedback/feedback.store";
import { GetAllLocationIncidents } from "redux/stores/safe/incidents-area.store";
import { JwtHelper } from 'utils/jwt-helper';
import { getThisProjectLocation } from 'modules/gmap/project-location';
import { PromiseAllTools } from 'core/app.store';

function ReportIncidentsFormMobile(props) {
  const [files, setFiles] = useState([]);
  const [errorFile, setErrorFile] = useState({
    status: false,
    maxFile: "",
    maxSize: "",
    typeError: "",
  });

  const [dropPosition, setDropPosition] = useState(false);
  const [position, setPosition] = useState(null);
  const [positionDefault, setPositionDefault] = useState(props.loggingIn ? new JwtHelper.currentLocation() : getThisProjectLocation());
  const [getLocation, setGetLocation] = useState(true);

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  if (navigator.geolocation && getLocation) {
    setGetLocation(false);
    navigator.geolocation.getCurrentPosition((CurrentPosition) =>
      setPositionDefault({
        lat: CurrentPosition.coords.latitude,
        lng: CurrentPosition.coords.longitude,
      })
    );
  }

  function handleChangeStateDropPosition() {
    setDropPosition(!dropPosition);
    if (position) {
      setPosition(null);
    } else {
      setPosition(positionDefault);
    }
    window.scrollTo(0, 0);
  }

  function ReadUri(e) {
    if (e.target.files) {
      const listFile = Array.from(e.target.files);
      Promise.all(
        listFile.map((file) => {
          return new Promise((resolve, reject) => {
            if (CheckMaxFile(files.length)) {
              setErrorFile({
                status: true,
                maxFile: CheckMaxFile(files.length),
              });
              resolve({
                err: true,
              });
            } else if (
              !errorFile.maxFile &&
              !errorFile.maxSize &&
              CheckFileSize(file.size)
            ) {
              setErrorFile({ status: true, maxSize: CheckFileSize(file.size) });
              resolve({
                err: true,
              });
            } else if (
              !errorFile.maxFile &&
              !errorFile.typeError &&
              CheckFileTypeMedia(file.type)
            ) {
              setErrorFile({
                status: true,
                typeError: CheckFileTypeMedia(file.type),
              });
              resolve({
                err: true,
              });
            } else {
              const reader = new FileReader();
              if (file.type.match("image")) {
                reader.addEventListener("load", (ev) => {
                  resolve({
                    id: NewGuid(),
                    name: file.name,
                    url: ev.target.result,
                    file: file,
                    err: false,
                    type: "image",
                  });
                });
                reader.addEventListener("error", reject);
                reader.readAsDataURL(file);
              } else if (file.type.match("video")) {
                reader.onload = function () {
                  let blob = new Blob([reader.result], {
                    type: file.type,
                  });
                  let url = URL.createObjectURL(blob);
                  let video = document.createElement("video");
                  let timeupdate = function () {
                    if (snapImage()) {
                      video.removeEventListener("timeupdate", timeupdate);
                      video.pause();
                    }
                  };
                  video.addEventListener("loadeddata", function () {
                    if (snapImage()) {
                      video.removeEventListener("timeupdate", timeupdate);
                    }
                  });
                  let snapImage = function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvas
                      .getContext("2d")
                      .drawImage(video, 0, 0, canvas.width, canvas.height);
                    let image = canvas.toDataURL();
                    let success = image.length > 100000;
                    if (success) {
                      resolve({
                        id: NewGuid(),
                        name: file.name,
                        file: file,
                        err: false,
                        url: image,
                        type: "video",
                      });
                      URL.revokeObjectURL(url);
                    }
                    return success;
                  };
                  video.addEventListener("timeupdate", timeupdate);
                  video.preload = "metadata";
                  video.src = url;
                  video.muted = true;
                  video.playsInline = true;
                  video.play();
                };
                reader.readAsArrayBuffer(file);
              } else {
                setErrorFile({
                  status: true,
                  typeError: CheckFileTypeMedia(file.type),
                });
                resolve({
                  err: true,
                });
              }
            }
          });
        })
      ).then(
        (images) => {
          let result = [];
          images.map((image) => {
            if (!image.err) {
              result.push(image);
            }
          });
          setFiles([...files, ...result]);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  function DeleteFile(id) {
    if (id && id != "") {
      const temp = files.filter((x) => x.id != id);
      setFiles(temp.filter((x) => x.id != id));
    }
  }

  const onSubmit = (data) => {
    if (data) {
      const formData = new FormData();
      files &&
        files.map(
          (file) => file.file && formData.append("feedback_file[]", file.file)
        );
      props
        .report(
          {
            address: data.address,
            content: data.description,
            longitude: (position && position.lng) || positionDefault.lng,
            latitude: (position && position.lat) || positionDefault.lat,
          },
          formData
        )
        .then(
          (res) => {
            if (res && res.content) {
              AddNotification(
                viVN.Success.Report,
                NotificationMessageType.Success
              );
              document.getElementById("reportIncidentsForm").reset();
              setFiles([]);
            } else {
              res &&
                res.errorType &&
                AddNotification(
                  viVN.Errors[res && res.errorType],
                  NotificationMessageType.Error
                );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              AddNotification(
                viVN.Errors[err && err.errorType],
                NotificationMessageType.Error
              );
          }
        );
    }
  };

  useEffect(() => {
    props.PromiseAllTools([props.GetAllLocationIncidents()]);
  }, [])

  useEffect(() => {
    errorFile.status &&
      setTimeout(() => {
        setErrorFile({
          status: false,
          maxFile: "",
          maxSize: "",
          typeError: "",
        });
      }, 5000);
  });

  let listIncidentsProcessing = [];
  let listIncidentProcessed = [];
  if (props.listIncidents) {
    const listIncidents = Array.from(props.listIncidents);
    listIncidents.map((incident) => {
      if (incident.solved) {
        listIncidentProcessed.push({
          lat: incident.lat,
          lng: incident.lng,
        });
      } else {
        listIncidentsProcessing.push({
          lat: incident.lat,
          lng: incident.lng,
        });
      }
    });
  }

  return (
    <div className="form-report-incidents-mobile">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bao-su-co"
        id="reportIncidentsForm"
      >
        <GoogleMap
          marker={position}
          dropMarker={dropPosition}
          center={positionDefault}
          listMarkerTwo={listIncidentProcessed}
          listMarkerOne={listIncidentsProcessing}
          idGoogleMap="feed-back-map"
          click={(value) => setPosition(value)}
        />

        <div className="report-incidents-mobile-section p-3">
          <div className="form-group">
            <label>
              Địa điểm sự cố<span className="required"></span>
            </label>
            <input
              type="text"
              name="address"
              disabled={!props.isLogin}
              className={"form-control " + (errors.address ? "is-invalid" : "")}
              placeholder="Nhập địa điểm..."
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            />
            {errors.address && (
              <div className="invalid-feedback">{errors.address.message}</div>
            )}
          </div>

          <div className="form-group">
            <label>
              Nội dung<span className="required"></span>
            </label>
            <textarea
              name="description"
              className={
                "form-control " + (errors.description ? "is-invalid" : "")
              }
              rows="5"
              placeholder="Nhập nội dung..."
              disabled={!props.isLogin}
              ref={register({ required: "Trường này là bắt buộc" })}
            ></textarea>
            {errors.description && (
              <div className="invalid-feedback">
                {errors.description.message}
              </div>
            )}
          </div>
          {files && (
            <div className="album-file">
              {files.map((item) => (
                <RenderFilePreview
                  data={item}
                  DeleteFile={DeleteFile}
                  key={item.id}
                />
              ))}
            </div>
          )}
          {errorFile.status && (
            <div className="form-group mb-0">
              <div className="invalid-feedback">{errorFile.maxFile}</div>
              {!errorFile.maxFile && errorFile.maxSize && (
                <div className="invalid-feedback">{errorFile.maxSize}</div>
              )}
              {!errorFile.maxFile && errorFile.typeError && (
                <div className="invalid-feedback">{errorFile.typeError}</div>
              )}
            </div>
          )}
          <div className="row align-items-center">
            <div className="col-6">
              <div className="img-upload">
                <div className="title-upload">
                  <img
                    src={require("assets/images/place-red.svg")}
                    alt="Image"
                    className="img-fluid"
                  />{" "}
                  <span>Thêm vị trí</span>
                </div>
                <input
                  type="button"
                  className="location"
                  disabled={!props.isLogin}
                  onClick={() => handleChangeStateDropPosition()}
                />
              </div>
            </div>
            <div className="col-6 text-right">
              <div className="img-upload">
                <div className="title-upload">
                  <img
                    src={require("assets/images/collections.png")}
                    alt="Image"
                    className="ml-3 img-fluid"
                  />{" "}
                  <span>Upload ảnh/video</span>
                </div>
                <input
                  type="file"
                  name="mediaFile"
                  accept=".png, .jpg, .jpeg, .mp4"
                  className="media-file"
                  multiple
                  disabled={!props.isLogin}
                  onChange={ReadUri}
                />
              </div>
            </div>
            <div className="col-12 mt-4">
              <button
                type="submit"
                className="btn btn-success w-100"
                disabled={!props.isLogin}
              >
                Báo sự cố
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

function RenderFilePreview(props) {
  const data = props.data;
  if (data.type == "video") {
    return (
      <div className="item mb-2">
        <img src={data.url} alt="video" className="img-video" />
        <div className="overlay"></div>
        <img
          src={require("assets/images/drop-down.svg")}
          className="img-fluid video"
          alt="Video"
        />
        <img
          src={require("assets/images/close-white.svg")}
          className="img-fluid img-delete"
          alt="Delete"
          onClick={() => props.DeleteFile(data.id)}
        />
      </div>
    );
  } else {
    return (
      <div className="item mb-2">
        <img src={data.url} alt="Image" className="img-video" />
        <div className="overlay"></div>
        <img
          src={require("assets/images/close-white.svg")}
          className="img-fluid img-delete"
          alt="Delete"
          onClick={() => props.DeleteFile(data.id)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listIncidents: state.incidentsArea.allLocation,
  loggingIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      report: feedbackActions.Report,
      GetAllLocationIncidents,
      PromiseAllTools
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportIncidentsFormMobile);
