//@ts-check
import React from 'react';
import Slider from "react-slick";
import ItemOfSlider from '../sport-home-childs/item-of-slider.view';

/**
 * @param {{
 * detailUrl:string,
 * title:string,
 * listData:{logoUrl: string;
    name: string;
    address: string;
    id: number;}[]
 * }} [props]
*/
function BlockSliderView(props) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.3,
        slidesToScroll: 2,
        arrows: false,
    }
    return (
        <div className=" p-3">
            <h3 className="text-size-content">{props.title}</h3>
            <div>
                <Slider {...settings}>
                    {
                        props.listData.map((data, index) => <ItemOfSlider detailUrl={props.detailUrl} key={index} data={data} />)
                    }
                </Slider>
                {
                    (props.listData.length===0)&&<div className="text">
                        Không có dữ liệu để hiển thị
                    </div>
                }
            </div>
        </div>
    )
}

export default BlockSliderView