import { NewGuid, randomDate } from "../utils/configuration";

export const Schedules = [
    {
        id: NewGuid(),
        person_name: 'Lê Văn Sơn',
        schedule_time: randomDate(new Date(2020, 0, 4), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Nguyễn Quảng Bá',
        schedule_time: randomDate(new Date(2020, 0, 4), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Trần Quốc Vượng',
        schedule_time: randomDate(new Date(2020, 0, 4), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Phan Văn Quân',
        schedule_time: randomDate(new Date(2020, 0, 4), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Nguyễn Đình Mão',
        schedule_time: randomDate(new Date(2020, 0, 4), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Nguyễn Xuân Trường',
        schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Huỳnh Kiến Hoa',
        schedule_time: randomDate(new Date(2020, 0, 4), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Hoàng Đường Tăng',
        schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Tôn Ngộ Không',
        schedule_time: new Date(),
        responsibility: 'Bảo trì hệ thống điện',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Sa Ngộ Tĩnh',
        schedule_time: new Date(),
        responsibility: 'Bảo trì hệ thống nước',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
    {
        id: NewGuid(),
        person_name: 'Nguyễn Thị Cô Vy',
        schedule_time: new Date(),
        responsibility: 'Lễ tân',
        contact_phone: '0949260275',
        profileUrl: require('assets/images/29-01.png'),
    },
]