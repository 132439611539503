import React from 'react';
import notification from '../react-notifications/helpers/notification';
import { getContainer } from '../react-notifications/helpers/randomize';
import { store } from 'react-notifications-component';
import { NotificationMessageType } from '../../utils/configuration';

const AddNotification = (message, type) => {

    let iconClassName;
    if (type === NotificationMessageType.Success) {
        iconClassName = 'fa fa-check-circle';
    } else if (type === NotificationMessageType.Warning) {
        iconClassName = 'fa fa-exclamation-circle';
    } else if (type === NotificationMessageType.Error) {
        iconClassName = 'fa fa-exclamation-triangle';
    }

    store.addNotification(
        Object.assign({}, notification, {
            // width: 275,
            container: 'top-right',
            content: (
                <div className={`notification-custom-${type}`}>
                    <div className="notification-custom-icon">
                        <i className={iconClassName} />
                    </div>
                    <div className="notification-custom-content">
                        <p className="notification-message">{message}</p>
                    </div>
                </div>
            )
        })
    );
};

export default AddNotification;