/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
//@ts-check
import React, { useState, useEffect, Fragment } from 'react';
import "../payment-desktop.scss";
import { Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// @ts-ignore
import * as ElectricBillStores from 'redux/stores/payment/electric/electric.store';
// @ts-ignore
import * as CreateTransactionStores from 'redux/stores/create-transaction/create-transaction.store';
import PayDetail from './electric-paydetail.view.jsx';
import RenderBillOfMonthly from './electric-bill-month.view.jsx';
// @ts-ignore
import { Error } from 'components/support-create-form/validate-style';
// @ts-ignore
import { ToolSelectPeriodTime, convertTextareaToHtml, GetProjectInfo } from "utils/configuration";
import { handleChangeListHistory } from '../logic-payment/handle-change-list-history';
// @ts-ignore
import { PromiseAllTools } from 'core/app.store';
import Pagination from '../../../components/pagination/paging.view';
// @ts-ignore
import AddNotification from "components/react-notifications/react-notifications";
// @ts-ignore
import * as viVN from "translation/vi-VN.json";
// @ts-ignore
import { NotificationMessageType, TokenKey, getCookies } from "utils/configuration";
import { useRenderPaymentHistoryList } from '../../../components/custom-hook/render-payment-history-list/render-payment-history-list';
export function PaymentElectricDesktopView(props) {
  const { endDate, startDate, handleChangeStartDate, handleChangeEndDate, resetChangeDateTime, filterObj } = new ToolSelectPeriodTime()
  const [showPayDetail, setShowPayDetail] = useState(false)
  const [showWarning, setShowWarning] = useState(false);
  const PaymentGuideDocument = { __html: convertTextareaToHtml(props.guide) || "Không có dữ liệu để hiển thị" };
  const currentDomain = window.location.origin;
  const projectName = getCookies(TokenKey.ProjectName) ? getCookies(TokenKey.ProjectName) : "";
  const projectInfo = GetProjectInfo();
  const isPayment = projectInfo && projectInfo.hasVnpay && projectInfo.hasVnpay == "1" || false;

  function handleChangePayDetail(i) {
    setShowPayDetail(!showPayDetail)
    props.PromiseAllTools([props.GetListElectricDetail(i)]);
  }

  function ControlChangeListHistory() {
    handleChangeListHistory(startDate, endDate, () => props.PromiseAllTools([props.GetElectricList(1, 100, filterObj, props.userInfo.apartmentId)]),
      resetChangeDateTime,
      setShowWarning,
      props.ResetHistoryList
    )
  }

  function PayHistory() {
    const listData = Array.from(props.listPaid);

    return (
      <div className="body mt-4">
        <div className="row align-items-center">
          <div className="col-3">
            <h3 className="body-title">Lịch sử thanh toán</h3>
          </div>
          <div className="col-9">
            <div className="row no-gutters align-items-center ml-n1 mr-n1">
              <div className="col-1 pl-1 pr-1"></div>
              <div className="col-4 text-right position-relative pl-1 pr-1">
                <DatePicker selected={startDate} onChange={handleChangeStartDate} className="rounded-pill" dateFormat="dd/MM/yyyy" disabled={listData.length === 0} />
                <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
              </div>
              <div className="col-4 text-right position-relative pl-1 pr-1">
                <DatePicker selected={endDate} onChange={handleChangeEndDate} className="rounded-pill" dateFormat="dd/MM/yyyy" disabled={listData.length === 0} />
                <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
              </div>
              <div className="col-3 text-right pl-1 pr-1">
                <button className="btn btn-success rounded-pill" onClick={ControlChangeListHistory} disabled={listData.length === 0}>
                  <img src={require("assets/images/search-white.svg")} alt="Search White" className="img-search-white" /> Tìm kiếm
                  </button>
              </div>
            </div>
            <div className='text-center'>
              {showWarning && <Error content="Khoảng thời gian không đúng, vui lòng kiểm tra lại." />}
            </div>
          </div>
        </div>

        {listData.length > 0 ? <RenderHistoryList listData={listData} handleChangePayDetail={handleChangePayDetail} /> : (
          <div className="border-top mt-2 pt-3">
            <p className="text-center mb-0">Không có dữ liệu để hiển thị</p>
          </div>
        )}

      </div>
    )
  }

  function onCreateTransactionPayment(data) {
    props.CreateTransactionVNPay({
      return_url: currentDomain + "/thanh-toan/ket-qua",
      transaction_name: "Thanh toán tiền phí điện tháng " + data.monthly + " " + data.yearly + " căn hộ " + data.houseNumber + " khu " + projectName,
      service_type_code: "electric",
      service_id: data.id
    }).then(
      (res) => {
        window.location.replace(res.content && res.content.redirect_url);
      },
      (err) => {
        err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error);
      }
    );
  }

  useEffect(() => {
    props.PromiseAllTools([
      props.GetElectricBillThisMonth(props.userInfo.apartmentId),
      props.GetElectricList(1, 100, filterObj, props.userInfo.apartmentId),
      props.GetPaymentGuide()
    ])
  }, [])

  return (
    <div className="payment-desktop">
      <RenderBillOfMonthly data={props.monthlyBill} haveData={props.monthlyBill.haveData} />

      {showPayDetail ? <PayDetail data={props.detail} click={() => setShowPayDetail(!showPayDetail)} /> : PayHistory()}
      <div dangerouslySetInnerHTML={PaymentGuideDocument} className="payment-guide"></div>

      {
        props.monthlyBill && props.monthlyBill.datePay == "" && props.monthlyBill.total > "0" && (
          <button className="btn btn-success text-uppercase rounded-pill mt-4 pl-4 pr-4" disabled={!isPayment} onClick={() => onCreateTransactionPayment(props.monthlyBill)}>
            Thanh toán phí
          </button>
        )
      }
    </div>
  );
}

function RenderHistoryList(props) {
  const {
    listData,
    Page_Size,
    displayList,
    setCurrentIndex
  } = useRenderPaymentHistoryList(props.listData)
  return (
    <Fragment>
      <Table hover className="mt-1 mb-0">
        <tbody>
          {displayList.map((data, index) => <RenderFieldOfListHistory data={data} key={index} click={() => props.handleChangePayDetail(data.billId)} />)}
        </tbody>
      </Table>
      {
        (listData.length > Page_Size) && <Pagination totalItems={listData.length} size={Page_Size} goTo={setCurrentIndex} />
      }
    </Fragment>
  )
}

function RenderFieldOfListHistory(props) {
  const data = props.data
  return (
    <tr className="pointer" onClick={props.click}>
      <td>
        <h5>Hóa đơn điện tháng {data.monthly}/{data.yearly}</h5>
        <span>{data.datePay ? `Ngày thanh toán: ${data.datePay}` : 'Hóa đơn chưa được thanh toán'}</span>
      </td>
      <td className="text-right total-price" id="history-total">{data.total}VNĐ</td>
    </tr>
  )
}

const mapStateToProps = state => ({
  monthlyBill: state.electricBill.billThisMonth,
  listPaid: state.electricBill.paymentHistory,
  detail: state.electricBill.billDetail,
  guide: state.electricBill.paymentGuide,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetElectricList: ElectricBillStores.GetElectricList,
  GetElectricBillThisMonth: ElectricBillStores.GetElectricBillThisMonth,
  GetListElectricDetail: ElectricBillStores.GetListElectricDetail,
  GetPaymentGuide: ElectricBillStores.GetPaymentGuide,
  ResetHistoryList: ElectricBillStores.ResetHistoryList,
  CreateTransactionVNPay: CreateTransactionStores.CreateTransactionVNPay,
  PromiseAllTools
},
  dispatch
)
export default connect(mapStateToProps, mapDispatchToProps)(PaymentElectricDesktopView)