import { Map } from 'immutable';
import { createFormData, AppKey } from '../../../utils/configuration';
import Service from '../../../services/api.service';
import { ApiUrl } from '../../../services/api-url';
import { Legals } from '../../../mock-data/legal';
import { createSaveLoading } from '../../../core/app.store';

const service = new Service();
/** Action Types */
const LEGALSAVELIST = 'LEGAL/SAVELIST';
const LEGALSAVETOP4LEGAL = 'LEGAL/SAVETOP4LEGAL';
const LEGALDETAIL = 'LEGAL/DETAIL';

/** Actions */
export const createSaveLegalList = (legalModel) => ({ type: LEGALSAVELIST, payload: { legalList: legalModel.legalList, totalItems: legalModel.totalItems } });
export const createSaveLegalDetail = () => ({ type: LEGALDETAIL });
export const createSaveTop4Legals = (top4Legals) => ({ type: LEGALSAVETOP4LEGAL, payload: top4Legals });


/** Action creators */
export const GetLegalList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = { ...filterObjs, orderBy: 'create_date', folder_id: 1 };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.LegalGetList, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            dispatch(createSaveLegalList({ legalList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetTop4LegalList = (pageIndex = 1, size = 4, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = { ...filterObjs, orderBy: 'create_date', folder_id: 1 };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.LegalGetList, payLoad).then(res => {
            dispatch(createSaveTop4Legals(res.content && res.content.items || []))
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetLegalDetail = (legalId) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('legalId', legalId);
        let payLoad = createFormData({ folder_id: 1 }, AppKey.PostPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.LegalGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteLegal = (legalId) => {
    return dispatch => {
        let payLoad = createFormData({ legalId: legalId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.LegalDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateLegal = (legalModel) => {
    return dispatch => {
        let payLoad = createFormData(legalModel, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.LegalCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */
export const GetLegalListFake = (pageIndex, size, filterObjs) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let legalList = Legals.slice(pageIndex * size - size, (pageIndex - 1) + size);
            if (legalList) {
                dispatch(createSaveLegalList(legalList))
                resolve({
                    content: legalList,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const GetLegalDetailFake = (legalId) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let legalDetail = Legals.find(lg => lg.id == legalId);
            if (legalDetail) {
                resolve({
                    content: legalDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    legalList: [],
    top4Legals: [],
    legalDetail: {},
    totalItems: 0,
}).toJS();

/** Reducers */
export default function LegalReducer(state = initialState, action) {
    switch (action.type) {
        case LEGALSAVELIST:
            return {
                ...state,
                legalList: [...action.payload.legalList],
                totalItems: action.payload.totalItems,
            }
        case LEGALSAVETOP4LEGAL:
            return {
                ...state,
                top4Legals: [...action.payload],
            }
        case LEGALDETAIL:
            return {
                ...state,
                legalDetail: action.payload,
            }
        default:
            return state;
    }
}