import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from '../../../core/app.store';
import {
    createFormData,
    AppKey
} from '../../../utils/configuration';
import Service from '../../../services/api.service';
import {
    ApiUrl
} from '../../../services/api-url';

// import {
//     Code
// } from '../../mock-data/user';

const service = new Service();
/** Action Types */

/** Actions */

export const ConfirmCode = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data);
        return service.post(ApiUrl.ConfirmCode, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

export const ActiveAccount = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data);
        return service.post(ApiUrl.ActiveUser, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

export const SendConfirmCode = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data);
        return service.post(ApiUrl.SendConfirmCode, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

// Initial state
export const initialState = Map({
    isLoading: false,
}).toJS();

/** Reducers */
export default function ConfirmCodeReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}