import { createFormData, AppKey, StripHtmlTag } from "utils/configuration";
import { ApiUrl } from "services/api-url";
import { createSaveLoading } from "core/app.store";
import Service from "services/api.service";
import { Map } from "immutable";

export const ElectricPaymentGuideKeyword = "huong_dan_dong_tien_dien";
export const InternetPaymentGuideKeyword = "huong_dan_thanh_toan_phi_internet";
export const ParkingPaymentGuideKeyword = "huong_dan_thanh_toan_phi_gui_xe";
export const FeePaymenGuideKeyword = "huong_dan_thanh_toan_phi_dich_vu";
export const WaterPaymentGuideKeyword = "huong_dan_dong_tien_nuoc";
export const UBNDphoneNumberKeyword = "so_dien_thoai_ubnd_phuong";

const service = new Service();
const UBNDPHONE = "CONTENTBYKEYWORD/UBNDPHONE";

const InitUBNDphone = (data) => ({
  type: UBNDPHONE,
  data: StripHtmlTag(data),
});

export const GetContentByKeyword = (KEYWORD, createActionToDispatch) => {
  return async (dispatch) => {
    dispatch(createSaveLoading(true));
    let rawData = null;
    let formData = new FormData();
    formData.append("keyword", KEYWORD);
    let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
    await service
      .post(ApiUrl.GetContentByKeyword, payLoad)
      .then((res) => {
        if (res.content && res.content.content) rawData = res.content.content;
      })
      .catch((err) => {
        throw err;
      });
    dispatch(createActionToDispatch(ExchangeFormatData(rawData)));
  };
};

export const GetUBNDPhone = () => {
  return GetContentByKeyword(UBNDphoneNumberKeyword, InitUBNDphone);
};

function ExchangeFormatData(rawData) {
  let standardData = `Không có dữ liệu để hiển thị`;
  if (rawData != null) {
    standardData = rawData;
  }
  return standardData;
}

const initState = Map({
  ubndPhoneNumber: "",
}).toJS();

export default function ReducerContentByKeyWord(state = initState, action) {
  switch (action.type) {
    case UBNDPHONE:
      return {
        ...state,
        ubndPhoneNumber: action.data,
      };
    default:
      return state;
  }
}
