//@ts-check
import React, { useEffect } from 'react';
import ShopHomePageDesktop from '../child-component/shop-home-page/shop-home-page.view'
import * as PageUrls from '../../PageUrls';
import FakeData from '../../../mock-data/sport-index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiseStore from '../../../redux/stores/utillities/utilitis-shop.store';

/**
 * @param {{GetShopList:Function,restaurantList:{
 * address: string,
 logoUrl: string,
 lat: number,
 lng: number,
 id: number,
 name: string,}[]}} [props]
*/
function RestaurantHomeViewDesktop(props) {
    useEffect(() => {
        props.GetShopList(1, 9, UtilitiseStore.RESTAURANKEY)
    }, [])
    return <ShopHomePageDesktop currentTypeUrl={PageUrls.UtilitiesRestaurantHomeDeskTop} viewMoreUrl={PageUrls.UtilitiesRestaurantListPageDeskTop} detailUrl={PageUrls.UtilitiesRestaurantDetailDeskTop} listData={props.restaurantList} shopTypeTile="Nhà hàng" />
}

const mapStateToProps = state => ({
    restaurantList: state.utilitiShop.restaurantList.listShop,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopList: UtilitiseStore.GetShopList,
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(RestaurantHomeViewDesktop);