//@ts-check
import React, { useEffect } from 'react';
import RenderShopListPageViewDesktop from '../child-component/shop-list-page/shop-list-page.view';
import * as PageUrls from '../../PageUrls';
import FakeListSport from '../../../mock-data/spa-list-data';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiesStore from '../../../redux/stores/utillities/utilitis-shop.store';
function UtilitiesSpaListPageDesktop(props) {
    const pageSize = 8;
    const pageIndex = 1;

    useEffect(() => {
        props.GetListSpa(pageIndex, pageSize);
    }, [])
    return <RenderShopListPageViewDesktop
        currentTypeUrl={PageUrls.UtilitiesSpaHomeDeskTop}
        pageSize={8}
        pageIndex={1}
        totalItems={props.totalSpa}
        getDataByPageIndex={(index) => props.GetListSpa(index, pageSize)}
        listData={props.listSpa} detailUrl={PageUrls.UtilitiesSpaDetailDeskTop} titleShopType="Làm đẹp" />
}
const mapStateToProps = state => ({
    listSpa: state.utilitiShop.spaList.listShop,
    totalSpa: state.utilitiShop.spaList.total,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListSpa: UtilitiesStore.GetListSpa,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesSpaListPageDesktop)