import React, { useEffect } from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { GetMatchUrl } from '../../utils/configuration';

class RedirectAuto extends React.Component {
    UNSAFE_componentWillMount() {
        const props = this.props;
        const currentRouteMatch = props.match;
        const history = props.history;
        const _currentDeviceIsMobile = isMobile || isTablet;
        let _needRedirect = false;
        switch (true) {
            case _currentDeviceIsMobile && (!props.currentUrlIsForMobile): {
                _needRedirect = true
                break;
            }
            case (!_currentDeviceIsMobile) && (props.currentUrlIsForMobile): {
                _needRedirect = true
                break;
            }
        }
        if (_needRedirect) {
            const _currentPath = currentRouteMatch.path;
            const _currentParams = currentRouteMatch.params;
            const _isDesktop = !_currentDeviceIsMobile;
            let _newPath = GetMatchUrl(_isDesktop, _currentPath);
            for (let propertyName in _currentParams) {
                _newPath = _newPath.replace(':' + propertyName, _currentParams[propertyName])
            }
            history.push(_newPath)
        }
    }

    render() {
        return <div></div>;
    }
}

RedirectAuto.defaultProps = {
    currentUrlIsForMobile: true,
}
export default RedirectAuto