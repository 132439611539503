import React, { Component } from 'react';
import "../advertising-desktop.scss";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as residentMarketActions from 'redux/stores/resident/resident-market.store';
import "../resident-market/resident-market-desktop.scss";
import ResidentMarketItemDesktopView from './childs/resident-market-item.view';
import PaginationControl from 'components/pagination/pagination.view';

class ResidentMarketDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
      isLoading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetResidentMarketList();
  }

  onGetResidentMarketList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = null) => {
    this.setState({ isLoading: true });
    this.props.getresidentMarketList(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => {
        this.setState({ isLoading: false });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetResidentMarketList(pageNumber);
    })
  }

  render() {
    const { residentMarketList, totalItems, loggedIn } = this.props;
    const { currentPageIndex, isLoading } = this.state;
    return (
      <div className="advertising-desktop">
        <div className="row align-items-center">
          <div className="col-6">
            <h2 className="title-lg mb-0">Rao vặt</h2>
          </div>
          {loggedIn &&
            <div className="col-6 text-right">
              <Link to="/rao-vat/cho-cu-dan/dang-tin" className="title-link"><img src={require('assets/images/add-new.svg')} alt="Add new" /> Thêm sản phẩm</Link>
            </div>
          }
        </div>
        <div className="resident-market mt-3">
          <ResidentMarketItemDesktopView residentMarketList={residentMarketList} callBack={this.onGetResidentMarketList.bind(this)} />
          <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.auth.isLoggedIn,
  residentMarketList: state.residentMarket.residentMarketList,
  totalItems: state.residentMarket.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getresidentMarketList: residentMarketActions.GetResidentMarketList,
  deleteResidentMarket: residentMarketActions.DeleteResidentMarket,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ResidentMarketDesktopView)