//@ts-check
import { Map } from "immutable";
import * as viVN from "translation/vi-VN.json";
import errorImage from "assets/images/image-error-load-compressed.jpg";
import { useState } from "react";
import { browserVersion, isSafari } from "react-device-detect";
import Cookies from "universal-cookie";
import { isMobile } from "react-device-detect";
import { AppUrls } from './config-redirect';
let configuration = Map();

export const ApiServerKey = {
  APP_API_ROOT: "API_ROOT",
  SOCKET_API_ROOT: "SOCKET_ROOT",
};

export const isInitSubdomain = true;

const cookies = new Cookies();
export const domainOrigin = 'ilang.vn';
const domainName = 'ilang.vn';
const dateExpires = new Date();
dateExpires.setTime(dateExpires.getTime() + 720 * 60 * 60 * 1000);

export const isDevelopEnvironment = () => {
  return location.hostname === 'localhost' || location.hostname === "ilang-test.bmbsoft.com.vn";
}

export function setCookies(
  name,
  value,
  options = { path: "/", domain: domainName, expires: dateExpires }
) {
  cookies.set(name, value, options);
}

export function getCookies(name) {
  return cookies.get(name);
}

export function removeCookies(
  name,
  options = { path: "/", domain: domainName }
) {
  cookies.remove(name, options);
}

export function removeListCookies(nameList) {
  if (nameList instanceof Array) {
    nameList.map((name) => {
      cookies.remove(name, { path: "/", domain: domainName });
      cookies.remove(name, { path: "/", domain: window.location.host });
    });
  }
}

export function checkDateInput(e, setValue) {
  if (e && e.getDate()) setValue(e);
}

export function setConfiguration(name, value) {
  configuration = configuration.set(name, value);
}

export function setAll(properties) {
  configuration = configuration.merge(properties);
}

export function unsetConfiguration(name) {
  configuration = configuration.delete(name);
}

/**
 * @example
 * <img onError={SetDefaultErrorImage} />
 */
export function SetDefaultErrorImage(event, imageDefault = errorImage) {
  event.target.src = imageDefault;
}

export function getConfiguration(key) {
  if (!configuration.has(key)) {
    throw new Error("Undefined configuration key: " + key);
  }

  return configuration.get(key);
}

export const ClientId = 1;
export const ClientKey = "56c78fc287f";

export function createFormData(
  data,
  namespace,
  form,
  isIncludingClientInfo = true,
  keepAray = false
) {
  const formData = form || new FormData();

  if (isIncludingClientInfo) {
    const userInfo = getCookies(TokenKey.AuthToken)
      ? getCookies(TokenKey.AuthToken)
      : null;
    if (isSafari === true && Number(browserVersion) <= 11) {
      getCookies(TokenKey.Project) &&
        formData.append("clientId", getCookies(TokenKey.Project));
      userInfo && formData.append("jwt", userInfo);
    } else {
      !formData.has("clientId") &&
        getCookies(TokenKey.Project) &&
        formData.append("clientId", getCookies(TokenKey.Project));
      userInfo && !formData.has("jwt") && formData.append("jwt", userInfo);
    }
  }

  if (data instanceof Object) {
    for (let propertyName in data) {
      if (
        !data.hasOwnProperty(propertyName) ||
        data[propertyName] === "" ||
        data[propertyName] == null
      )
        continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;

      if (data[propertyName] instanceof Date)
        formData.append(formKey, data[propertyName].toISOString());
      else if (data[propertyName] instanceof Array) {
        keepAray
          ? formData.append(formKey, JSON.stringify(data[propertyName]))
          : data[propertyName].forEach((element, index) => {
            const tempFormKey = `${formKey}[${index}]`;
            if (element instanceof File) {
              formData.append(tempFormKey, element);
            } else {
              createFormData(element, tempFormKey, formData);
            }
          });
      } else if (
        typeof data[propertyName] === "object" &&
        !(data[propertyName] instanceof File)
      ) {
        createFormData(data[propertyName], formKey, formData);
      } else {
        formData.append(formKey, data[propertyName]);
      }
    }
  } else if (data != null && data != {}) {
    formData.append(namespace, data);
  }

  return formData;
}

export function CheckFileSize(fileSize) {
  const size = fileSize / 1024 / 1000;
  return size > FileValidate.MaxSize
    ? viVN.FileError.MaxFileSize +
    FileValidate.MaxSize +
    viVN.FileError.SizeType
    : "";
}

export function CheckMaxFile(fileCount) {
  return fileCount > FileValidate.MaxFile
    ? viVN.FileError.MaxFile + FileValidate.MaxFile + " files"
    : "";
}

export function CheckFileTypeImage(fileType) {
  return FileValidate.Image.includes(fileType)
    ? ""
    : viVN.FileError.FileType + FileValidate.Image;
}

export function CheckFileTypeMedia(fileType) {
  const type = FileValidate.Image + "," + FileValidate.Video;
  return type.includes(fileType) ? "" : viVN.FileError.FileType + type;
}

export function CheckFileTypeAttachment(fileType) {
  return FileValidate.Attachment.includes(fileType)
    ? ""
    : viVN.FileError.FileType + FileValidate.Attachment;
}

export const FileValidate = {
  MaxSize: 20,
  MaxFile: 10,
  Video: "video/mp4",
  Image: "image/jpg, image/jpeg, image/gif, image/png",
  Attachment: "application/pdf",
};

export const ImageExtensions = ["png", "jpg", "gif", "bmp", "jpeg"];
export const AllImageExtensions = "image/*";
export const FileMaximunSize = 20; // MB

export function validateFile(
  file,
  acceptFileExtensions = [AllImageExtensions],
  maximumSize = FileMaximunSize
) {
  if (!file) {
    return "File không được để trống.";
  }

  if (file.size > maximumSize * 1024 * 1024) {
    return `Kích thước file không được vượt quá ${maximumSize} MB.`;
  }

  if (file.name !== null && file.name !== "" && file.name !== undefined) {
    let fileExt = file.name.replace(/^.*\./, "");
    if (
      acceptFileExtensions &&
      acceptFileExtensions.length == 1 &&
      acceptFileExtensions[0] == AllImageExtensions
    ) {
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        return `File cho phép tải lên: ${acceptFileExtensions.toString()}`;
      }
    } else if (
      acceptFileExtensions &&
      acceptFileExtensions.length > 0 &&
      !acceptFileExtensions.some(
        (x) => x.toUpperCase() == fileExt.toUpperCase()
      )
    ) {
      return `File cho phép tải lên: ${acceptFileExtensions.toString()}`;
    }
  }

  return "";
}

export function SetProjectInfo(_project = null) {
  if (!_project) {
    return false;
  }

  setCookies(TokenKey.ProjectInfo, JSON.stringify(_project));
  return true;
}

export function GetProjectInfo() {
  let _project = getCookies(TokenKey.ProjectInfo);

  if (!_project) {
    return false;
  }

  return _project;
}

export const UserRole = {
  Admin: 1,
  PM: 2,
  DM: 3,
};

export const TokenKey = {
  AuthToken: "auth.token",
  AvatarUrl: "auth.avatarUrl",
  Location: "location",
  Project: "project",
  ProjectName: "project.name",
  HostName: "host.name",
  ProjectInfo: "project.info",
};

export const AppKey = {
  PostPayloadPrefix: "data",
  GetPayloadPrefix: "filter",
};

export const NotificationMessageType = {
  Success: "success",
  Warning: "warning",
  Error: "error",
};

export const ScreenTypeMobile = {
  Active: "dang-nhap",
  Register: "dang-ky",
  Forgot: "quen-mat-khau",
};

export const AppEvent = {
  Inform: "inform",
};

export const AssistType = {
  HourlyMaid: "GIUP_VIEC_THEO_GIO",
  RentToMarket: "DI_CHO_THUE",
};

export function NewGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function FormatNumber(value) {
  if (value < 10) {
    value = "0" + value;
  }
  return value;
}

export function FormatDate(date) {
  return (
    date.getUTCFullYear() +
    "-" +
    FormatNumber(date.getUTCMonth() + 1) +
    "-" +
    FormatNumber(date.getDate())
  );
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function _range(start, stop, step) {
  if (stop == null) {
    stop = start || 0;
    start = 0;
  }
  if (!step) {
    step = stop < start ? -1 : 1;
  }

  var length = Math.max(Math.ceil((stop - start) / step), 0);
  var range = Array(length);

  for (var idx = 0; idx < length; idx++, start += step) {
    range[idx] = start;
  }

  return range;
}

export function randomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export const ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];

export const convertTextareaToHtml = (text) => {
  return text && text.replace(/\r?\n/g, "<br />");
};

export const convertHtmlToTextarea = (text) => {
  return text && text.replace(/<br\s*[\/]?>/gi, "\n");
};

const PREFIX = "";
export const DECIMAL_SEPARATOR = ".";
export const THOUSANDS_SEPARATOR = ",";
export const BLANK = "";
const SUFFIX = ""; //' $'
const PADDING = "000000";
export const transformCurrency = (value, fractionSize) => {
  let [integer, fraction = ""] = (value || "").toString().split(".");

  fraction =
    fractionSize > 0
      ? DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : "";

  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR);

  return PREFIX + integer + fraction + SUFFIX;
};

export function CutTextByLength(text = "", length = 180) {
  if (text.length < length) return text;
  return text.substr(0, length) + "...";
}

export function StripHtmlTag(text) {
  return text.replace(/(<([^>]+)>)/gi, "");
}

export function StripEnterSymbolHtmlTagAndConverTextToHtmlView(text) {
  const textResult = text.replace(/(<([^>]+)>)/gi, "").replace(/↵/gi, "");
  const divElement = document.createElement("div");
  divElement.innerHTML = textResult;
  return divElement.textContent;
}

/**
 * @param {Date} date
 * @returns {string} -"yyyy-mm-dd" || ""
 */
export function getDateWithFormat(date) {
  if (date) {
    let content = date.getFullYear() + "-";
    if (date.getMonth() + 1 < 10) {
      content += "0" + (date.getMonth() + 1) + "-";
    } else {
      content += date.getMonth() + 1 + "-";
    }
    if (date.getDate() < 10) {
      content += "0" + date.getDate();
    } else {
      content += date.getDate();
    }
    return content;
  } else return "";
}

// in: date start,date end - date obj. ps: changeDateTime : is period has change
export function ToolSelectPeriodTime(dateStart = null, dateEnd = null) {
  const DefaultValue = new Date();
  DefaultValue.setMonth(0);
  const DateTimeStart = dateStart || DefaultValue;
  const DateTimeEnd = dateEnd || new Date();
  const [changeDateTime, setChangeDateTime] = useState(false);
  const [endDate, setEndDate] = useState(DateTimeEnd);
  const [startDate, setStartDate] = useState(DateTimeStart);

  function handleChangeStartDate(date) {
    if (date) setStartDate(date);
    setChangeDateTime(true);
  }

  function handleChangeEndDate(date) {
    setEndDate(date);
    setChangeDateTime(true);
  }

  function resetChangeDateTime() {
    setChangeDateTime(false);
  }

  const filterObj = {
    from: getDateWithFormat(startDate),
    to: endDate ? getDateWithFormat(endDate) : "",
  };

  return {
    changeDateTime,
    endDate,
    startDate,
    handleChangeStartDate,
    handleChangeEndDate,
    resetChangeDateTime,
    filterObj,
  };
}

// in: "12:24",out: 4235 - min
export function ConverTimeToMinutes(time) {
  const timeArray = time.split(":");
  return Number(timeArray[0]) * 60 + Number(timeArray[1]);
}

/**
 * @param {number|string} numberParam - 1234
 * @returns {string} - 1.234
 */
export function ConvertNumberToString(numberParam) {
  const number = String(numberParam);
  const ListNumber = number.split("");
  const result = [];
  let count = 0;
  while (ListNumber.length > 0) {
    result.unshift(ListNumber.pop());
    count++;
    if (count === 3) {
      result.unshift(".");
      count = 0;
    }
  }
  if (result[0] === ".") result.shift();
  return result.join("");
}

export function RemoveNoneNumberFromString(str) {
  return str.replace(/[^0-9]/g, "");
}
/**
 * @param {object} dateObjec - date object
 * @return {string} - 20/06/2020
 */
export function ConverDateObjectToVietNamCalendar(dateObjec) {
  let dayNumber = dateObjec.getDate();
  let monthNumber = dateObjec.getMonth() + 1;
  if (dayNumber < 10) dayNumber = "0" + dayNumber;
  if (monthNumber < 10) monthNumber = "0" + monthNumber;
  return `${dayNumber}/${monthNumber}/${dateObjec.getFullYear()}`;
}
export const ERRORTYPEEMPTYDATAERROR = "EmptyDataError";

export function ConvertTimeToNumber(date) {
  return date.getHours() * 60 + date.getMinutes();
}

export function ReplaceImgTag(htmlString = '') {
  if (!isMobile) return htmlString;
  let resultString = htmlString;
  const listRawImageTag = resultString.match(/<img[^>]+>/g);
  const adddStylePropeerrties = 'max-width: 100%; height: auto; object-fit: contain;';
  if (listRawImageTag) {
    const listNewImageTag = [];
    listRawImageTag.forEach((imgTag) => {
      const styleString = imgTag.match(/style="[^"]*"/g);
      if (styleString) {
        const styleProperties = styleString[0].substr(8, styleString[0].length - 2);
        const propertiesArray = styleProperties.split(';').filter(property => property);
        listNewImageTag.push({
          rawImageTag: imgTag,
          newImageTag: imgTag.replace(styleString[0], `style="${propertiesArray.join('; ') + adddStylePropeerrties}"`),
        });
      } else {
        listNewImageTag.push({
          rawImageTag: imgTag,
          newImageTag: imgTag.substr(0, imgTag.length - 3).concat(' ', 'style="max-width:100%; object-fit: contain;"/>'),
        });
      }
    });
    listNewImageTag.forEach((imageTag) => {
      resultString = resultString.replace(
        imageTag.rawImageTag,
        imageTag.newImageTag
      );
    });
    return resultString;
  } else return htmlString;
}

export function GetMatchUrl(isForDesktop = true, urlCompare = '') {
  let urlMatch = isForDesktop ? '/' : '/m';
  const propertyCompare = isForDesktop ? "mobile" : "desktop";
  const propertyGetValue = isForDesktop ? "desktop" : "mobile";
  for (let pageName in AppUrls) {
    if (urlCompare === AppUrls[pageName][propertyCompare]) {
      urlMatch = AppUrls[pageName][propertyGetValue];
      break;
    }
  }
  return urlMatch
}