//@ts-check
import React from 'react';
import { Link } from 'react-router-dom';
/**
 * @param {{iconPath:string,label:string,utilitiesUrl:string,matchPath:string}} [props]
*/
function HeaderSliderItemMobile(props) {
    return <div className="list-inline-item-custom">
        <Link to={props.utilitiesUrl} className={`m-utilities-nav d-block ${(props.utilitiesUrl === props.matchPath) ? 'disabled-event active' : ''}`}>
            <img src={props.iconPath} alt={props.label} /> <span className="text-size-sub-content">{props.label}</span>
        </Link>
    </div>
}

export default HeaderSliderItemMobile