//@ts-check
import React from 'react';
import RenderShopListPageViewDesktop from '../child-component/shop-list-page/shop-list-page.view';
import * as PageUrls from '../../PageUrls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiseStore from '../../../redux/stores/utillities/utilitis-shop.store';
import { useEffect } from 'react';

/**
 * @param {{total:number,GetShopList:Function,superMarketList:{
 * logoUrl: string,
 name: string,
 address: string,
 id: number,
 type: string,}[]}} [props]
*/
function UtilitiesSuperMarketListPageDesktop(props) {
    const PAGESIZE = 8;
    const PAGEINDEX = 1;
    useEffect(() => {
        props.GetShopList(PAGEINDEX, PAGESIZE, UtilitiseStore.SUPERMARKETKEY)
    }, [])
    const getDataByPageIndex = (index) => props.GetShopList(index, PAGESIZE, UtilitiseStore.SUPERMARKETKEY)
    return <RenderShopListPageViewDesktop
        currentTypeUrl={PageUrls.UtilitiesSuperMarketHomeDeskTop}
        pageSize={PAGESIZE}
        pageIndex={1}
        totalItems={props.total}
        getDataByPageIndex={getDataByPageIndex}
        listData={props.superMarketList} detailUrl={PageUrls.UtilitiesSuperMarketDetailDeskTop} titleShopType="Siêu thị" />
}

const mapStateToProps = state => ({
    superMarketList: state.utilitiShop.superMarketList.listShop,
    total: state.utilitiShop.superMarketList.total,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopList: UtilitiseStore.GetShopList,
},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesSuperMarketListPageDesktop)