/* eslint-disable react/prop-types */
//@ts-check
import React, { useEffect } from 'react';
import "../payment-desktop.scss";
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RenderInfo } from '../electric/electric-bill-month.view.jsx';
// @ts-ignore
import { PromiseAllTools } from 'core/app.store';
// @ts-ignore
import { convertTextareaToHtml, NotificationMessageType, TokenKey, getCookies, GetProjectInfo } from "utils/configuration";
// @ts-ignore
import * as CreateTransactionStores from 'redux/stores/create-transaction/create-transaction.store';
// @ts-ignore
import * as InternetBillStores from 'redux/stores/payment/internet/internet.store';
// @ts-ignore
import AddNotification from "components/react-notifications/react-notifications";
// @ts-ignore
import * as viVN from "translation/vi-VN.json";

export function PaymentInternetDesktopView(props) {
  const currentDomain = window.location.origin;
  const projectName = getCookies(TokenKey.ProjectName) ? getCookies(TokenKey.ProjectName) : "";
  const projectInfo = GetProjectInfo();
  const isPayment = projectInfo && projectInfo.hasVnpay && projectInfo.hasVnpay == "1" || false;

  const PaymentGuideDocument = { __html: convertTextareaToHtml(props.guide) || "Không có dữ liệu để hiển thị" };

  function onCreateTransactionPayment(data) {
    props.CreateTransactionVNPay({
      return_url: currentDomain + "/thanh-toan/ket-qua",
      transaction_name: "Thanh toán tiền phí internet tháng " + data.monthly + " " + data.yearly + " căn hộ " + data.houseNumber + " khu " + projectName,
      service_type_code: "internet",
      service_id: data.id
    }).then(
      (res) => {
        window.location.replace(res.content && res.content.redirect_url);
      },
      (err) => {
        err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error);
      }
    );
  }

  useEffect(() => {
    props.PromiseAllTools([
      props.GetInternetBillThisMonth(props.userInfo.apartmentId),
      props.GetPaymentGuide(),
    ])
  }, [])

  return (
    <div className="payment-desktop">
      <h2 className="title">Internet</h2>

      <div className="body">
        {props.monthlyBill && props.monthlyBill.haveData ? <RenderContent data={props.monthlyBill} /> : <RenderInfo />}
      </div>

      <div className="payment-guide" dangerouslySetInnerHTML={PaymentGuideDocument}></div>

      {
        props.monthlyBill && props.monthlyBill.datePay == "" && props.monthlyBill.total > "0" && (
          <button className="btn btn-success text-uppercase rounded-pill mt-4 pl-4 pr-4" disabled={!isPayment} onClick={() => onCreateTransactionPayment(props.monthlyBill)}>
            Thanh toán phí
          </button>
        )
      }
    </div>
  );

}

function RenderContent(props) {
  const data = props.data;
  return (
    <div>
      <div className="row no-gutters">
        <div className="col-8">
          <h5>Tên khách hàng: <strong>{data.userName}</strong></h5>
          <h5>Căn hộ số: <strong>{data.houseNumber}</strong></h5>
          <h5>Hóa đơn internet: Tháng {data.monthly}/{data.yearly}</h5>
        </div>
        <div className="col-4 text-right">
          {data.datePay
            ? <h6 className="text-success">Đã thanh toán</h6>
            : <h6 className="text-danger">Chưa thanh toán</h6>
          }
        </div>
      </div>


      <Table size="sm" className="mt-3 mb-0">
        <tbody>
          <tr>
            <td className="border-0">Cước internet:</td>
            <td className="text-right border-0">Tháng {data.monthly}</td>
          </tr>
          <tr>
            <td>Số tiền:</td>
            <td className="text-right">{data.price}VNĐ</td>
          </tr>
          <tr>
            <td>Thuế suất GTGT({data.tax}%):</td>
            <td className="text-right">{data.taxMoney}VNĐ</td>
          </tr>
          <tr>
            <td>Tổng cộng:</td>
            <td className="text-right">{data.total}VNĐ</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

const mapStateToProps = state => ({
  monthlyBill: state.internetBill.billThisMonth,
  guide: state.internetBill.guidePayment,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetInternetBillThisMonth: InternetBillStores.GetInternetBillThisMonth,
  GetPaymentGuide: InternetBillStores.GetPaymentGuide,
  CreateTransactionVNPay: CreateTransactionStores.CreateTransactionVNPay,
  PromiseAllTools
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInternetDesktopView)