//@ts-check
import React from 'react';
import { Link } from 'react-router-dom'
import { SetDefaultErrorImage } from 'utils/configuration';

/**detailUrl - /chi-tiet/be-boi/
 * @param {{detailUrl:string,data:{logoUrl:string,name:string,address:string,id:number}}} [props]
*/
function ItemOfSlider(props) {
    return (
        <div className="items">
            <Link to={props.detailUrl + props.data.id}>
                <img src={props.data.logoUrl} alt={props.data.name} onError={SetDefaultErrorImage} className="img-fluid w-100 img-utilities-mobile" />
            </Link>

            <div className="p-2 border rounded-bottom">
                <h5>
                    <Link className="text-truncate mb-0 d-block" to={props.detailUrl + props.data.id}>{props.data.name}</Link>
                </h5>
                <div className="row no-gutters align-items-center">
                    <div className="col-1">
                        <img src={require("assets/images/place-gray.svg")} alt="place" className="img-place" />
                    </div>
                    <div className="col-11 pl-1 text-truncate">
                        <span>{props.data.address}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemOfSlider