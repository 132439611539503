/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Error } from "components/support-create-form/validate-style";
import AddNotification from "components/react-notifications/react-notifications";
import * as joinHandActions from "redux/stores/join-hand/join-hand.store";
import {
  convertTextareaToHtml,
  NotificationMessageType,
  NewGuid,
  validateFile,
} from "utils/configuration";
import { history } from "redux/store";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { JwtHelper } from "utils/jwt-helper";
import * as viVN from "translation/vi-VN.json";
import "../../neighbourhoods-mobile.scss";
import HandleErrorMessaget from '../../../../utils/error-message';

function UpdateJoinsHandForm(props) {
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  console.log(props.model);

  const [previewImg, setUserRequest] = useState(null);
  const [isShowUpload, setShowUpload] = useState(false);
  const [voteOptions, setVoteOptions] = useState([]);
  const [topicImage, setTopicImage] = useState([]);
  const [isToggleVote, setToggleVote] = useState(true);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  let initialValues = {
    topic_id: props.model.topic_id,
    topic_name: props.model.topic_name,
    content: props.model.content,
    location: props.model.location,
    intend_time: props.model.intend_time,
    topic_image: props.model.topic_image,
    responsible: props.model.responsible,
    options: (props.model.options || []).map((option) => {
      return { ...option, render_id: option.option_id };
    }),
  };

  useEffect(() => {
    if (!previewImg && initialValues.topic_image) {
      setUserRequest(initialValues.topic_image);
      setShowUpload(!initialValues.topic_image);
    }

    if (
      !voteOptions.length &&
      initialValues.options &&
      initialValues.options.length > 0
    ) {
      setVoteOptions(initialValues.options);
    }
  }, [props.model]);

  const onSubmit = async (data) => {
    if (!topicImage.length && !previewImg) {
      return;
    }
    // data.content = convertTextareaToHtml(data.content);
    data.content = data.content;
    data.options = [
      ...voteOptions.map((item) => {
        const { render_id, ...option } = item;
        return option;
      }),
    ];
    data.topic_image = topicImage;
    props.onUpdateJoinHand && props.onUpdateJoinHand(data);
  };

  const onFileChange = (event) => {
    let file = event.target.files && event.target.files[0];

    if (file) {
      const validateMsg = validateFile(file);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }
      setValidateImgMessage("");

      var reader = new FileReader();
      reader.onload = (e) => {
        setUserRequest(e.target.result);
        setShowUpload(false);
      };
      reader.readAsDataURL(file);
      setTopicImage(file);
    }
  };

  const onAddMoreVoteOption = () => {
    setVoteOptions([
      ...voteOptions,
      {
        option_name: "",
        render_id: NewGuid(),
      },
    ]);
  };

  const onRemovedVoteOption = (voteOptionId) => {
    let newOptions = [...voteOptions];
    const optionIndex = newOptions.findIndex(
      (vote) => vote.option_id == voteOptionId
    );
    newOptions[optionIndex].has_delete = 1;
    setVoteOptions([...newOptions]);
  };

  const onChangeVoteName = (optionRenderId, e) => {
    const optionIndex = voteOptions.findIndex(
      (vote) => vote.render_id == optionRenderId
    );
    if (optionIndex >= 0 && !!e.target.value) {
      let newOptions = [...voteOptions];
      newOptions[optionIndex].option_name = e.target.value.trim();
      setVoteOptions([...newOptions]);
    }
  };

  const onDeleteImg = (e) => {
    e && e.preventDefault();
    setShowUpload(true);
  };

  const isHasVotedPeople = (vote) => {
    // return vote.voted_people || [].length > 0;
    return false;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="create-joins-hand-mobile p-3"
    >
      <div className="form-group">
        <label>Tên sự kiện</label>
        <input
          type="text"
          defaultValue={initialValues.topic_name}
          name="topic_name"
          placeholder="Tên sự kiện..."
          className="form-control"
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Tên sự kiện phải có ít nhất 6 ký tự",
            },
          })}
        />
        {errors.topic_name && (
          <div className="mt-1">
            <Error content={errors.topic_name.message} />
          </div>
        )}
      </div>

      <div className="form-group">
        <label>Thời gian diễn ra sự kiện</label>
        <input
          type="text"
          defaultValue={initialValues.intend_time}
          name="intend_time"
          placeholder="Thời gian diễn ra sự kiện"
          className="form-control"
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Địa điểm tổ chức sự kiện phải có ít nhất 6 ký tự",
            },
          })}
        />
        {errors.intend_time &&
          <div className="mt-1">
            <Error content={errors.intend_time.message} />
          </div>
        }
      </div>

      <div className="form-group">
        <label>Địa điểm tổ chức</label>
        <input
          type="text"
          defaultValue={initialValues.location}
          name="location"
          placeholder="Địa điểm tổ chức"
          className="form-control"
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Thời gian diễn ra sự kiện phải có ít nhất 6 ký tự",
            },
          })}
        />
        {errors.location && (
          <div className="mt-1">
            <Error content={errors.location.message} />
          </div>
        )}
      </div>

      <div className="form-group">
        <label>Người chịu trách nhiệm</label>
        <input
          type="text"
          defaultValue={initialValues.responsible}
          name="responsible"
          placeholder="Người chịu trách nhiệm"
          className="form-control"
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Tên người chịu trách nhiệm phải có ít nhất 6 ký tự",
            },
          })}
        />
        {errors.responsible && (
          <div className="mt-1">
            <Error content={errors.responsible.message} />
          </div>
        )}
      </div>

      <div className="form-group">
        <label>Thông tin sự kiện</label>
        <textarea
          name="content"
          defaultValue={initialValues.content}
          className="form-control"
          placeholder="Thông tin sự kiện..."
          rows="7"
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Thông tin sự kiện phải có ít nhất 6 ký tự",
            },
          })}
        ></textarea>
        {errors.content && (
          <div className="mt-1">
            <Error content={errors.content.message} />
          </div>
        )}
      </div>

      <div className="form-group">
        {!!previewImg && (
          <div className="preview-img">
            <img
              src={previewImg}
              alt="Preview Img"
              className="img-attachment"
            />
          </div>
        )}
        <div className="file-upload mt-3">
          <input
            type="text"
            className="hidden-text-field"
            id="topic_image"
            name="topic_image"
            ref={register}
          />
          <div className="title-upload">
            <img
              src={require("assets/images/collections.png")}
              alt="Image"
              className="img-fluid"
            />{" "}
            <span>Upload ảnh</span>
          </div>
          <input
            type="file"
            name="attachmentFile"
            accept=".png, .jpg, .jpeg"
            multiple
            className="media-file"
            onChange={onFileChange.bind(this)}
            ref={register({
              required: { value: !previewImg, message: "Trường bắt buộc" },
            })}
          />
          {errors.attachmentFile && (
            <div className="mt-1">
              <Error content={errors.attachmentFile.message} />
            </div>
          )}
          {validateImgMessage && (
            <div className="mt-1">
              <Error content={validateImgMessage} />
            </div>)}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-vote rounded">
            <div className="render-vote">
              <div className="form-group position-relative">
                <div
                  className={
                    isToggleVote
                      ? "create-vote form-control active"
                      : "create-vote form-control"
                  }
                  onClick={() => setToggleVote(!isToggleVote)}
                >
                  <span>Tạo bình chọn</span>
                  <img
                    src={require("assets/images/arrow-up.svg")}
                    className={`img-fluid ${isToggleVote ? "img-arrow-down" : "img-arrow-up"
                      }`}
                  />
                </div>

                <div className="form-create-vote rounded-bottom">
                  {voteOptions.map((option, index) => (
                    <div className="form-group" key={option.render_id}>
                      <div className="row no-gutters align-items-center">
                        {!option.has_delete && (
                          <div className="col-8">
                            <input
                              type="text"
                              name="option_name"
                              defaultValue={option.option_name}
                              placeholder={`Phương án ${index}`}
                              className="form-control"
                              onChange={(e) =>
                                onChangeVoteName(option.render_id, e)
                              }
                            />

                            {!isHasVotedPeople(option) && (
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={() =>
                                  onRemovedVoteOption(option.option_id)
                                }
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            )}
                          </div>
                        )}

                        {(option.voted_people || []).length <= 3 && (
                          <div className="col-4 pre-avatar">
                            {option.voted_people ||
                              [].map((p) => (
                                <img
                                  key={p.voted_by_id}
                                  src={
                                    p.voted_profile_url ||
                                    require("assets/images/29-01.png")
                                  }
                                  title={p.voted_by}
                                  alt={p.voted_by}
                                  className="img-fluid mr-1"
                                />
                              ))}
                          </div>
                        )}
                        {(option.voted_people || []).length > 3 && (
                          <div className="col-4 pre-avatar">
                            <img
                              src={
                                option.voted_people[0].voted_profile_url ||
                                require("assets/images/29-01.png")
                              }
                              title={option.voted_people[0].voted_by}
                              alt={option.voted_people[0].voted_by}
                              className="img-fluid mr-1"
                            />
                            <img
                              src={
                                option.voted_people[1].voted_profile_url ||
                                require("assets/images/29-01.png")
                              }
                              title={option.voted_people[1].voted_by}
                              alt={option.voted_people[1].voted_by}
                              className="img-fluid mr-1"
                            />
                            <RenderOverlay
                              votedMoreCount={option.voted_people.length - 2}
                              items={option.voted_people}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <span className="col-6">
                      {props.votedCount} lượt bình chọn
                    </span>
                    <a
                      className="col-6 add-vote text-right"
                      onClick={() => onAddMoreVoteOption()}
                    >
                      Thêm phương án +
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6">
          <Link to="/m/lang-gieng-gan/chung-tay" className="btn btn-secondary">
            HỦY
          </Link>
        </div>
        <div className="col-6 text-right">
          <button type="submit" className="btn btn-success">
            LƯU THAY ĐỔI
          </button>
        </div>
      </div>
    </form>
  );
}

function RenderOverlay(props) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Người bình chọn</Popover.Title>
      <Popover.Content>
        {props.items.map((p) => (
          <Link key={p.voted_by_id} to="/">
            <img
              src={p.voted_profile_url || require("assets/images/29-01.png")}
              title={p.voted_by}
              alt={p.voted_by}
              className="img-fluid mr-1"
            />
          </Link>
        ))}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <span className="vote-more-count">+{props.votedMoreCount}</span>
    </OverlayTrigger>
  );
}

class EditJoinsHandMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: JwtHelper.currentUser(),
      dataModel: {},
      topicId: this.props.match.params.topicId,
      votedCount: 0,
    };
  }

  UNSAFE_componentWillMount() {
    let { topicId } = this.state;
    topicId && this.onGetJoinHandPostDetail(topicId);
  }

  onGetJoinHandPostDetail = (topicId) => {
    this.props.getJoinHandDetail(topicId).then(
      (res) => {
        res &&
          res.content &&
          this.setState(
            {
              dataModel: res.content,
            },
            () => {
              this.onCountVotedPeople();
            }
          );
      },
      (err) => {
        history.push("/m/lang-gieng-gan/chung-tay");
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onCountVotedPeople = () => {
    let { dataModel } = this.state;
    let count = 0;

    (dataModel && dataModel.options) ||
      [].map((jp) => {
        count += jp.voted_people.length;
      });

    this.setState({
      votedCount: count,
    });
  };

  onUpdateJoinHand = (data) => {
    if (!this.props.loggedIn) return;
    let submittedData = {
      ...data,
      topic_id: this.state.topicId,
      customer_id: this.state.currentUser.customerId,
    };

    this.props.createOrUpdateJoinHand(submittedData).then(
      (res) => {
        AddNotification(
          "Cập nhật bài viết thành công!",
          NotificationMessageType.Success
        );
        history.push("/m/lang-gieng-gan/chung-tay");
      },
      (err) => {
        HandleErrorMessaget(err)
       }
    );
  };

  render() {
    const { dataModel, votedCount } = this.state;
    const { loggedIn } = this.props;
    return (
      <div className="joins-hand">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/lang-gieng-gan/chung-tay">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">
                  Chỉnh sửa sự kiện
                </div>
              </div>
            </div>
          </div>
        </header>
        {dataModel && (
          <UpdateJoinsHandForm
            loggedIn={loggedIn}
            onUpdateJoinHand={this.onUpdateJoinHand.bind(this)}
            model={dataModel}
            votedCount={votedCount}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateJoinHand: joinHandActions.CreateOrUpdateJoinsHand,
      getJoinHandDetail: joinHandActions.GetJoinsHandDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditJoinsHandMobileView);
