/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import '../payment-mobile.scss';
import { Table } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RenderInfo } from '../../payment-desktop/electric/electric-bill-month.view.jsx';
import * as InternetBillStore from 'redux/stores/payment/internet/internet.store';
import RenderNavigationBar from '../nav-header/nav-header.view.jsx';
import { PromiseAllTools } from 'core/app.store';
import { convertTextareaToHtml, TokenKey, getCookies, NotificationMessageType, GetProjectInfo } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import * as CreateTransactionStores from 'redux/stores/create-transaction/create-transaction.store';

function PaymentInternetMobileView(props) {

    useEffect(() => {
        props.PromiseAllTools([
            props.GetInternetBillThisMonth(props.userInfo.apartmentId),
            props.GetPaymentGuide(),
        ])
    }, [])

    const data = props.monthlyBill;
    const currentDomain = window.location.origin;
    const projectName = getCookies(TokenKey.ProjectName) ? getCookies(TokenKey.ProjectName) : "";
    const projectInfo = GetProjectInfo();
    const isPayment = projectInfo && projectInfo.hasVnpay && projectInfo.hasVnpay == "1" || false;

    function onCreateTransactionPayment(data) {
        props.CreateTransactionVNPay({
            return_url: currentDomain + "/m/thanh-toan/ket-qua",
            transaction_name: "Thanh toán tiền phí internet tháng " + data.monthly + " " + data.yearly + " căn hộ " + data.houseNumber + " khu " + projectName,
            service_type_code: "internet",
            service_id: data.id
        }).then(
            (res) => {
                window.location.replace(res.content && res.content.redirect_url);
            },
            (err) => {
                err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error);
            }
        );
    }

    return (
        <div className="payment-mobile">
            <RenderNavigationBar type="internet" />

            <div className="mobile-divide"></div>

            <div className="payment-info bg-white p-3">
                {data.haveData ? <RenderContent data={data} /> : <RenderInfo />}
            </div>

            <div className="mobile-divide"></div>

            <div className="payment-guide-detail p-3" dangerouslySetInnerHTML={{ __html: convertTextareaToHtml(props.guidePayment) || "Không có dữ liệu để hiển thị" }}></div>

            {
                props.monthlyBill && props.monthlyBill.datePay == "" && props.monthlyBill.total > "0" && (
                    <div className="pl-3">
                        <button className="btn btn-success text-uppercase rounded-pill pl-4 pr-4" disabled={!isPayment} onClick={() => onCreateTransactionPayment(props.monthlyBill)}>
                            Thanh toán phí
                        </button>
                    </div>
                )
            }
        </div>
    );
}

function RenderContent(props) {
    const data = props.data;

    return (
        <div>
            <div className="row no-gutters">
                <div className="col-8">
                    <h5>Tên khách hàng: <strong>{data.userName}</strong></h5>
                    <h5>Căn hộ số: <strong>{data.houseNumber}</strong></h5>
                    <h5>Hóa đơn internet: Tháng {data.monthly}/{data.yearly}</h5>
                </div>
                <div className="col-4 text-right">
                    {data.datePay
                        ? <h6 className="text-success">Đã thanh toán</h6>
                        : <h6 className="text-danger">Chưa thanh toán</h6>
                    }
                </div>
            </div>

            <Table size="sm" className="mt-3 mb-0">
                <tr>
                    <td>Cước internet:</td>
                    <td className="text-right">Tháng {data.monthly}</td>
                </tr>
                <tr>
                    <td>Số tiền:</td>
                    <td className="text-right">{data.price}VNĐ</td>
                </tr>
                <tr>
                    <td>Thuế xuất GTGT ({data.tax}%):</td>
                    <td className="text-right">{data.taxMoney}VNĐ</td>
                </tr>
                <tr>
                    <td>Tổng cộng:</td>
                    <td className="text-right total-price">{data.total}VNĐ</td>
                </tr>
            </Table>
        </div>
    )
}

const mapStateToProps = state => ({
    monthlyBill: state.internetBill.billThisMonth,
    guidePayment: state.internetBill.guidePayment,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetInternetBillThisMonth: InternetBillStore.GetInternetBillThisMonth,
    GetPaymentGuide: InternetBillStore.GetPaymentGuide,
    CreateTransactionVNPay: CreateTransactionStores.CreateTransactionVNPay,
    PromiseAllTools
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInternetMobileView)
