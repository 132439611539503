/* eslint-disable react/prop-types */
//@ts-check
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GmapDirect from '../../../../gmap/link-direct';
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import { SetDefaultErrorImage } from "utils/configuration";
import './header-detail-restaurant.scss';
import * as CommonClass from 'utils/css-common-class';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Error } from 'components/support-create-form/validate-style';
import { useRegisterFormRestaurantHook } from '../../../../../components/custom-hook/register-restaurant-form/register-restaurant-form';
import * as viVN from '../../../../../translation/vi-VN.json';

function RenderHeaderRestaurantDetailView(props) {
    const {
        hasRegisted,
        hasShowReserve,
        setHasShowReserve,
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        peopleAmount,
        setPeopleAmount,
        timeReserve,
        setTimeReserve,
        dateReserve,
        setDateReserve,
        handleRegister,
        validateName,
        validatePhoneNumber,
        validatePeopleAmount,
    } = useRegisterFormRestaurantHook(props.data.id, props.registerFunction)

    const data = props.data;

    const settingsSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }

    const [errorHours, setErrorHours] = useState("");

    //--- Validate time
    const today = new Date();
    function onValidateTime(dateReserve, timeReserve) {
        if (timeReserve.getTime() < today.getTime() &&
            dateReserve.getDate() == today.getDate() &&
            dateReserve.getUTCMonth() == today.getUTCMonth() &&
            dateReserve.getUTCFullYear() == today.getUTCFullYear()) {
            setErrorHours("Phải lớn hơn thời gian hiện tại");
        } else {
            setErrorHours("");
        }
    }

    return (
        <div className="header-container-ddb">
            <div className="utilities-breadcrumb">
                <h2 className={`title-lg ${CommonClass.TextSizeTitle}`}>
                    <Link to={props.comeBackLink} className="text-black">{props.titleShopType}</Link>&nbsp;&nbsp;
                    <img src={require('assets/images/keyboard-arrow-right.svg')} alt="keyboard-arrow-right" />&nbsp;&nbsp;
                    <span style={{ fontWeight: "normal" }}>{data.name}</span>
                </h2>
            </div>

            <div className="utilities-img-detail" >
                <Slider {...settingsSlider} className="slider-detail">
                    {(data.imagesSlider.length > 0) ? data.imagesSlider.map((slider, index) =>
                        <img className="w-100 slider-detail" style={{ objectFit: "cover" }} key={index} alt={data.name} onError={SetDefaultErrorImage} src={slider.url} />
                    ) :
                        <img src="" onError={SetDefaultErrorImage} />}
                </Slider>
            </div>

            <h2 className={`title-lg mt-3 ${CommonClass.TextSizeTitle}`}>{data.name}</h2>

            <div className={`row no-gutters ${CommonClass.TextSizeSuperContent}`} style={{ alignItems: "flex-end" }}>
                <div className="col-4">
                    <div className="address mb-1">
                        <img src={require('assets/images/place-red.svg')} alt="Place" className="img-fluid" /> <span className="text-gray">{data.address}</span>
                    </div>
                    <div className="open-time text-gray">
                        <img src={require('assets/images/clock-yellow.svg')} alt="Clock Yellow" className="img-fluid" /> Mở cửa: <span>{data.timeOpen}</span>
                    </div>
                </div>
                <div className="col-2">
                    <div className="social-icon">
                        <a href={data.faceBook} target="_blank" className="d-block mb-1"><img src={require('assets/images/facebook.svg')} alt="Place" className="img-fluid" /></a>
                        <a href={data.faceBook} target="_blank"><span>Facebook</span></a>
                    </div>
                </div>
                <div className="col-2">
                    <div className="social-icon">
                        <a href={`tel:${data.phone}`} className="d-block mb-1">
                            <img src={require('assets/images/phone-call-round.svg')} alt="Place" className="img-fluid" data-tip={`SĐT: ${data.phone}`} />
                        </a>
                        <a href={`tel:${data.phone}`} data-tip={`SĐT: ${data.phone}`}><span>Liên hệ</span></a>
                        <ReactTooltip place="bottom" type="dark" effect="float" />
                    </div>
                </div>
                <div className="col-2">
                    <div className="social-icon" style={{ cursor: "pointer" }} onClick={() => setHasShowReserve(!hasShowReserve)}>
                        <img src={require('assets/images/table.svg')} alt="Place" className="img-fluid mb-1" />
                        <br></br>
                        <span className="fake-link">Đặt bàn</span>
                    </div>
                </div>
                <div className="col-2">
                    <div className="social-icon">
                        <GmapDirect daddr_lat={data.lat} daddr_lng={data.lng} content={<img src={require('assets/images/right-turn.svg')} alt="Place" className="img-fluid mb-1" />} />
                        <br />
                        <GmapDirect daddr_lat={data.lat} daddr_lng={data.lng} content={<span>Chỉ đường</span>} />
                    </div>
                </div>
            </div>
            <hr className='mb-0'></hr>
            <div className={`utilities-form mt-3 bg-white p-3 ${props.isLoggedIn ? '' : 'disabled-event'}`} hidden={!hasShowReserve} >
                <div className="row form-group">
                    <div className="col-6">
                        <label className='text-black' htmlFor="userNameInput">Họ tên</label>
                        <input
                            id='userNameInput'
                            className="form-control"
                            type="text"
                            value={userName}
                            onChange={(event) => setUserName(event.target.value)}
                            name="customer_name"
                            placeholder="Họ tên"
                        />
                        {
                            validateName && <Error content={validateName} />
                        }
                    </div>
                    <div className="col-6">
                        <label className='text-black' htmlFor="phoneNumberInput">Số điện thoại</label>
                        <input
                            id="phoneNumberInput"
                            className="form-control"
                            type="text"
                            name="mobile_number"
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                            placeholder="Nhập SĐT"
                        />
                        {
                            validatePhoneNumber && <Error content={validatePhoneNumber} />
                        }
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-3">
                        <label className='text-black' htmlFor='peopleAmountInput'>Số người</label>
                        <input
                            min='1'
                            id='peopleAmountInput'
                            type="number"
                            name="quantity"
                            defaultValue={peopleAmount}
                            onMouseLeave={(event) => {
                                setPeopleAmount(Number(event.target.value))
                                if (!Number(event.target.value)) event.target.value = 0
                            }}
                            placeholder="Số người"
                            className="form-control"
                        />
                        {
                            validatePeopleAmount && <Error content={validatePeopleAmount} />
                        }
                    </div>
                    <div className="col-3">
                        <label className='text-black' htmlFor='timePickerInput'>Thời gian đặt</label>
                        <input
                            type="time"
                            className="d-none"
                            value={timeReserve.toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                            })}
                            name="intend_time"
                        />
                        <DatePicker
                            id='timePickerInput'
                            selected={timeReserve}
                            value={timeReserve}
                            onChange={(time) => {
                                setTimeReserve(time);
                                onValidateTime(dateReserve, time);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            className="form-control rounded-pill"
                            customInput={<CustomInputDatePicker />}
                        />
                        {errorHours && (
                            <small className="text-danger">
                                {errorHours}
                            </small>
                        )}
                    </div>
                    <div className="col-6 position-relative">
                        <label className='text-black position-relative d-block' htmlFor="datepickerPickDate">
                            Chọn ngày đặt
                        </label>
                        <DatePicker
                            id="datepickerPickDate"
                            selected={dateReserve}
                            onChange={(event) => {
                                setDateReserve(event);
                                onValidateTime(event, timeReserve)
                            }}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                        />
                        <label className='position-absolute' htmlFor="datepickerPickDate" id="icon-restaurant-calendar">
                            <img src={require('assets/images/calendar.svg')} />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <button type="submit" onClick={() => handleRegister()} className="btn btn-success w-50" disabled={errorHours !== ""}>
                            Đặt bàn
                        </button>
                    </div>
                </div>
            </div>
            {
                hasRegisted &&
                (
                    <p className="text-success text-center mt-3">
                        {viVN.Utilities.RegisterSuccess}
                    </p>
                )
            }
            <h3 className={`title-sm ${CommonClass.TextSizeSubTitle} mt-4`}>Giới thiệu</h3>
            <div className={`font-weight-light content-shop ${CommonClass.TextSizeSuperContent}`} dangerouslySetInnerHTML={{ __html: data.introduce }}>
            </div>
        </div>
    )
}

const CustomInputDatePicker = ({ value, onClick }) => (
    <button
        type="button"
        className="form-control rounded-pill text-left"
        onClick={onClick}
    >
        {value}
    </button>
);

export default RenderHeaderRestaurantDetailView