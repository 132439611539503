/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import FooterView from "../footer/footer.view.jsx";
import RedirectAuto from '../redirect-auto/redirect-auto.view';
import { isMobile, isTablet } from "react-device-detect";

class LayoutErrorMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: false,
      mainHeight: window.innerHeight - 64,
    };
  }

  render() {
    document.body.classList.remove("stopScroll");
    if (!isMobile && !isTablet) return <RedirectAuto currentUrlIsForMobile={true} match={this.props.match} history={this.props.history} />

    return (
      <div className="main-mobile">
        <main style={{ minHeight: this.state.mainHeight }}>
          {this.props.children}
        </main>
        <FooterView />
      </div>
    );
  }
}

export default LayoutErrorMobile;
