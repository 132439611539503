import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as rentToMarketActions from 'redux/stores/rent-to-market/rent-to-market.store';
import "../advertising-desktop.scss";
import AddRentToMarketDesktopView from './rent-to-market/add-rent-to-market-desktop.view';
import RentToMarketItemDesktopView from './childs/rent-to-market-item.view';
import PaginationControl from 'components/pagination/pagination.view';
import "../advertising-desktop.scss";
import { AssistType } from 'utils/configuration';

class RentToMarketDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
      isToggleAdding: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetRentToMarketList();
  }

  onGetRentToMarketList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = { category_type: AssistType.RentToMarket }) => {
    this.setState({ isLoading: true });
    this.props.getRentToMarketList(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => {
        this.setState({ isLoading: false });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetRentToMarketList(pageNumber);
    })
  }

  onToggleAddingState = () => {
    this.setState({
      isToggleAdding: !this.state.isToggleAdding
    })
  }

  render() {
    const { rentToMarketList, totalItems } = this.props;
    const { currentPageIndex, isToggleAdding } = this.state;
    return (
      <div className="advertising-desktop">
        <div className="row align-items-center mb-3">
          <div className="col-6">
            <h2 className="title-lg mb-0">Đi chợ thuê</h2>
          </div>
        </div>
        <AddRentToMarketDesktopView onToggleAddingState={this.onToggleAddingState.bind(this)} callBack={this.onGetRentToMarketList.bind(this)} />
        <RentToMarketItemDesktopView rentToMarketList={rentToMarketList} isToggleAdding={isToggleAdding} callBack={this.onGetRentToMarketList.bind(this)} />
        <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rentToMarketList: state.rentToMarket.rentToMarketList,
  totalItems: state.rentToMarket.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getRentToMarketList: rentToMarketActions.GetRentToMarketList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RentToMarketDesktopView)