/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';

export default function RenderBillOfMonthly(props) {
  const data = props.data
  const haveData = props.haveData

  return (
    <div className="position-relative bill-this-month">
      <h2 className="title">Tiền điện</h2>

      <div className="body">
        {haveData ? <RenderMonthlyBIll data={data} /> : <RenderInfo />}
      </div>
    </div>
  )
}

export function RenderInfo(props) {
  const title = props.title;
  return (
    <Fragment>
      <div className="text-center">
        <img src={require("assets/images/bill-emty.png")} />
        <br />
        {title && `${title} : `} Không có dữ liệu để hiển thị
    </div>
    </Fragment>
  )
}

export function RenderMonthlyBIll(props) {
  const data = props.data;
  return (
    <Fragment>
      <div className="row no-gutters">
        <div className="col-8">
          <h5>Tên khách hàng: <strong>{data.userName}</strong></h5>
          <h5>Căn hộ số: <strong>{data.houseNumber}</strong></h5>
          <h5>Hóa đơn điện: Tháng {data.monthly}/{data.yearly}</h5>
        </div>
        <div className="col-4 text-right">
          {data.datePay
            ? <h6 className="text-success">Đã thanh toán</h6>
            : <h6 className="text-danger">Chưa thanh toán</h6>
          }
        </div>
      </div>

      <Table size="sm" className="mt-3 mb-0">
        <tbody>
          <tr>
            <td className="border-0">Chỉ số mới:</td>
            <td className="text-right border-0">{data.newIndex}</td>
          </tr>
          <tr>
            <td>Chỉ số cũ:</td>
            <td className="text-right">{data.oldIndex}</td>
          </tr>
          <tr>
            <td>Tổng tiêu thụ:</td>
            <td className="text-right">{data.usedIndex}</td>
          </tr>
          <tr>
            <td>Đơn giá:</td>
            <td className="text-right">{data.unitPrice}VNĐ</td>
          </tr>
          <tr>
            <td>Thành tiền:</td>
            <td className="text-right">{data.intoMoney}VNĐ</td>
          </tr>
          <tr>
            <td>Thuế xuất GTGT ({data.tax}%):</td>
            <td className="text-right">{data.taxMoney}VNĐ</td>
          </tr>
          <tr>
            <td>Tổng cộng:</td>
            <td className="text-right total-price">{data.total}VNĐ</td>
          </tr>
        </tbody>
      </Table>
    </Fragment>
  )
}