import { Map } from 'immutable';
import { ApiUrl } from 'services/api-url';
import { createFormData, AppKey, ConvertNumberToString, ERRORTYPEEMPTYDATAERROR } from 'utils/configuration';
import Service from 'services/api.service';
import { createSaveLoading } from 'core/app.store';
import { InternetPaymentGuideKeyword, GetContentByKeyword } from '../../get-content-by-keyword/getContentByKeyword.store';
import { JwtHelper } from 'utils/jwt-helper';

const userInfor = JwtHelper.currentUser();
const service = new Service();
const INITGUIDEPAYMENT = 'INTERNETBILL/GUIDEPAYMENT';
const INITBILLMONTH = 'INTERNETBILL/INITBILLTHISMONTH';

export const InitBillThisMonth = data => ({
    type: INITBILLMONTH, data: data
})

export const InitGuidePayment = data => ({
    type: INITGUIDEPAYMENT, data: data
})

export const GetPaymentGuide = () => {
    return GetContentByKeyword(InternetPaymentGuideKeyword, InitGuidePayment)
}

export const GetInternetBillThisMonth = (apartmentId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = null;
        let formData = new FormData();
        formData.append('apartmentId', apartmentId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.InternetBillThismonth, payLoad).then(res => {
            if (res.errorType === ERRORTYPEEMPTYDATAERROR) return false
            rawData = res.content;
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitBillThisMonth(ExchangeDataBillThisMonth(rawData)))
    }
}

function ExchangeDataBillThisMonth(rawData) {
    if (rawData != null) return ({
        id: rawData.internetId,
        haveData: rawData.total > 0 ? true : false,
        userName: userInfor.customerName,
        houseNumber: rawData.houseNumber,
        monthly: rawData.monthly,
        yearly: rawData.yearly,
        price: ConvertNumberToString(rawData.price),
        tax: rawData.vatPercent,
        taxMoney: ConvertNumberToString(rawData.tax),
        total: ConvertNumberToString(rawData.total),
        datePay: rawData.datePay,
    }); else return {
        haveData: false,
    }
}

const initialState = Map({
    billThisMonth: {},
    guidePayment: ""
}).toJS()

export default function InternetBillReducer(state = initialState, action) {
    switch (action.type) {
        case INITBILLMONTH:
            return {
                ...state,
                billThisMonth: action.data
            }
        case INITGUIDEPAYMENT:
            return {
                ...state,
                guidePayment: action.data
            }
        default:
            return state
    }
}