import { Map } from "immutable";
/** Action Types */
const APP_SAVE_LOADING = "APP/SAVE_LOADING";

/** Actions */
export const createSaveLoading = (isLoading) => ({
  type: APP_SAVE_LOADING,
  isLoading,
});

export const ShowLoading = (isLoading) => {
  return (dispatch) => {
    dispatch(createSaveLoading(isLoading));
  };
};

/* Tool */
export const PromiseAllTools = (
  arrayPromise,
  successFunction = null,
  errorFunction = () => {}
) => {
  return (dispatch) => {
    Promise.all(arrayPromise)
      .then((res) => {
        if (successFunction) successFunction(res);
        else return true;
      })
      .catch((err) => {
        errorFunction(err);
      })
      .finally(() => {
        dispatch(createSaveLoading(false));
      });
  };
};

// Initial state
export const initialState = Map({
  loading: false,
}).toJS();

/** Reducers */
export default function AppReducer(state = initialState, action) {
  switch (action.type) {
    case APP_SAVE_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };
    default:
      return { ...state };
  }
}
