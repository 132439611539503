/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "core/app.store";
import * as clubActions from "redux/stores/club/club.store";

import PaginationControl from "components/pagination/pagination.view.jsx";
import TennisItemMobileView from "./childs/tennis-item-mobile.view.jsx";

import { JwtHelper } from "utils/jwt-helper";

import "../club.scss";

class TennisMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JwtHelper.currentUser(),

      listDataPost: [],
      currentPageIndex: 1,
      totalItems: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetListPost();
  }

  onGetListPost = (
    pageIndex = this.state.currentPageIndex,
    size = 10,
    filterObj = {
      orderBy: "club_id",
      orderType: "DESC",
      category_id: clubActions.clubType.TENNIS,
    }
  ) => {
    this.props.showLoading(true);
    this.props.getListPost(pageIndex, size, filterObj).then(
      (res) => {
        res &&
          res.content &&
          res.content.items &&
          this.setState({
            listDataPost: res.content.items || [],
            totalItems: res.content.totalItemCount || 0,
          });
        this.props.showLoading(false);
      },
      (err) => {
        this.props.showLoading(false);
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onGetListPost(pageNumber);
      }
    );
  };

  render() {
    const { loggingIn } = this.props;
    const { listDataPost, currentPageIndex, totalItems } = this.state;

    return (
      <div className="club-mobile p-3">
        <div className="club-post-item mt-3">
          <TennisItemMobileView
            listDataPost={listDataPost}
            callBack={this.onGetListPost.bind(this)}
          />
          <PaginationControl
            pageChanged={this.onSelectPage.bind(this)}
            totalItems={totalItems}
            currentPageIndex={currentPageIndex}
          />
        </div>

        {loggingIn && (
          <Link
            to="/m/lang-gieng-gan/cau-lac-bo/tennis/them-bai-viet"
            className="btn btn-success rounded-pill btn-add-golf"
          >
            <img
              src={require("assets/images/report-problem-white.svg")}
              alt="report-problem-white"
              className="img-report-problem-white"
            />{" "}
            Bài viết mới
          </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getListPost: clubActions.GetListPost,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TennisMobileView);
