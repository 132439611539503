import React, { useState } from 'react';
import * as NotificationStore from 'redux/stores/notification/notifications.store';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SetDefaultErrorImage } from 'utils/configuration';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HitchhikeStore from 'redux/stores/utilities-plus/hitchhike.store';
import { PromiseAllTools } from 'core/app.store';

const getIconUrl = NotificationStore.getIconUrl;
const getBaseUrl = NotificationStore.getBaseUrl;
const getMessageNotification = NotificationStore.getMessageNotification;
const isNeedHelpType = NotificationStore.isNeedHelpType;
const isWantoHitchHike = NotificationStore.isWantoHitchHike;

function RenderNotificationItems(props) {
    const [isClicked, setIsClicked] = useState(false);
    const data = props.data;
    const message = { __html: getMessageNotification(data.type) };
    let url = getBaseUrl(data.type, data.customData);
    const iconUrl = getIconUrl(data.type);
    let mainClassName = "col-10";
    let subClassName = "d-none";
    let subContent = null;
    if (url && (url[url.length - 1] === "/")) url = url + data.recordId;
    if (!message.__html) return <RenderForDevView data={data} />;
    if (isNeedHelpType(data.type)) {
        mainClassName = 'col-9';
        subClassName = "col-1";
        subContent = (data.customData.is_urgent != 0) && <img src={require('assets/images/emergency.svg')} alt="Emergency" className="img-fluid" />;
    }
    if (isWantoHitchHike(data.type) && (!data.isRead) && (!isClicked)) {
        mainClassName = "col-8";
        subClassName = "col-2";
        subContent = <button className="btn btn-primary btn-sm" onClick={onAgreeHitchHike}>Xác Nhận</button>;
    }
    function onAgreeHitchHike() {
        if (isWantoHitchHike(data.type)) {
            setIsClicked(true)
            props.PromiseAllTools([props.acceptOrDenineHitchHikeRequest({ request_id: data.customData.request_id, hitchhike_id: data.customData.hitchhike_id })])
            NotificationStore.MaskNotificationIsRead(data.notificationId)
        }
    }
    return (
        <div className={`notification-resident ${data.backGroundClass}`} onClick={() => NotificationStore.MaskNotificationIsRead(data.notificationId)}>
            {url ? <Link to={url} className="row no-gutters">
                <div className="col-2 text-center">
                    <img src={data.avatarUrl} onError={SetDefaultErrorImage} alt="Avatar" className="img-fluid img-avatar" />
                </div>
                <div className={mainClassName}>
                    <h5><span>{data.senderName}</span> <p className="d-inline" dangerouslySetInnerHTML={message}></p></h5>
                    <div className="datetime">
                        {iconUrl && <img src={iconUrl} />} {moment(data.createDate).fromNow()}
                    </div>
                </div>
                <div className={subClassName}>
                    {subContent}
                </div>
            </Link> : <div className="row no-gutters">
                    {(data.isRead === 0) && (!isWantoHitchHike(data.type)) && NotificationStore.MaskNotificationIsRead(data.notificationId)}
                    <div className="col-2 text-center">
                        <img src={data.avatarUrl} onError={SetDefaultErrorImage} alt="Avatar" className="img-fluid img-avatar" />
                    </div>
                    <div className={mainClassName}>
                        <h5><span>{data.senderName}</span> <p className="d-inline" dangerouslySetInnerHTML={message}></p></h5>
                        <div className="datetime">
                            {iconUrl && <img src={iconUrl} />} {moment(data.createDate).fromNow()}
                        </div>
                    </div>
                    <div className={subClassName}>
                        {subContent}
                    </div>
                </div>}
        </div>
    )
}

function RenderForDevView(props) {
    const data = props.data
    return (
        <div>
            {data.type}:{data.title}
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    acceptOrDenineHitchHikeRequest: HitchhikeStore.AcceptOrDenineHitchhikeRequest,
    PromiseAllTools
}, dispatch)

export default connect(null, mapDispatchToProps)(RenderNotificationItems)