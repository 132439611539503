import React, { useEffect } from 'react';
import "./notification-management-mobile.scss";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as NotificationStore from 'redux/stores/notification/notifications.store';
import Pagination from 'components/pagination/paging.view.jsx';
import { CutTextByLength } from "utils/configuration";
import { PromiseAllTools } from 'core/app.store';

function NotificationManagementMobileView(props) {
    useEffect(() => {
        props.PromiseAllTools(props.GetAgentNotificationList())
    }, [])
    return (
        <div className="notification-management-mobile">
            <table className="table">
                <tbody>
                    {(props.agentNotification.haveData) ? props.agentNotification.listData.map((data, index) => <RebderItemOfList data={data} key={index} />) : <tr><td>Hiện không có thông báo nào</td></tr>}
                    {(props.agentNotification.haveData) && <Pagination totalItems={props.agentNotification.total} goTo={(i) => props.PromiseAllTools(props.GetAgentNotificationList(i))} size={props.agentNotification.pageSize} />}
                </tbody>
            </table>
        </div>
    )
}

function RebderItemOfList(props) {
    const data = props.data;
    return (
        <tr onClick={() => NotificationStore.MaskNotificationIsRead(data.notificationId)} className={data.backGroundClass}>
            <td><Link to={`/m/thong-bao/chi-tiet/${data.informId}`}>
                <h5>
                    {data.informName}
                </h5>
                <span className="time"><img src={require("assets/images/calendar.svg")} alt="calendar" className="img-calendar" /> {data.createDate}</span>
                <p>{CutTextByLength(data.description, 150)}</p>
            </Link>
            </td>
        </tr>

    )
}

const mapStateToProps = state => ({
    agentNotification: state.notifications.agentNotification,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetAgentNotificationList: NotificationStore.GetAgentNotificationList,
    PromiseAllTools
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationManagementMobileView);