/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../payment-mobile.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as WaterBillStores from '../../../../redux/stores/payment/water/water.store';
import { PromiseAllTools } from 'core/app.store';

class PaymentWaterDetailMobileView extends Component {

  componentDidMount() {
    this.props.PromiseAllTools([this.props.GetListWaterDetail(Number(this.props.match.params.waterId))])
  }

  render() {
    const data = this.props.detail || {};
    return (
      <div className="payment-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/thanh-toan/tien-nuoc">
                  <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                </Link>
                <div className="header-title text-center">Thanh toán hóa đơn</div>
              </div>
            </div>
          </div>
        </header>

        <div className="payment-info bg-white p-3">
          <RenderMonthlyBill data={data} />
        </div>
      </div>
    );
  }
}

export function RenderMonthlyBill(props) {
  const data = props.data;

  return (
    <Fragment>
      <div className="row no-gutters">
        <div className="col-8">
          <h5>Tên khách hàng: <strong>{data.userName}</strong></h5>
          <h5>Căn hộ số: <strong>{data.houseNumber}</strong></h5>
          <h5>Hóa đơn nước: Tháng {data.monthly}/{data.yearly}</h5>
        </div>
        <div className="col-4 text-right">
          {data.datePay
            ? <h6 className="text-success">Đã thanh toán</h6>
            : <h6 className="text-danger">Chưa thanh toán</h6>
          }
        </div>
      </div>


      <Table size="sm" className="mt-3 mb-0">
        <tr>
          <td>Chỉ số mới:</td>
          <td className="text-right">{data.newIndex}</td>
        </tr>
        <tr>
          <td>Chỉ số cũ:</td>
          <td className="text-right">{data.oldIndex}</td>
        </tr>
        <tr>
          <td>Tổng tiêu thụ:</td>
          <td className="text-right">{data.usedIndex}</td>
        </tr>
        <tr>
          <td>Đơn giá:</td>
          <td className="text-right">{data.unitPrice}VNĐ</td>
        </tr>
        <tr>
          <td>Thành tiền:</td>
          <td className="text-right">{data.intoMoney}VNĐ</td>
        </tr>
        <tr>
          <td>Thuế xuất GTGT ({data.tax}%):</td>
          <td className="text-right">{data.taxMoney}VNĐ</td>
        </tr>
        <tr>
          <td>Tổng cộng:</td>
          <td className="text-right total-price">{data.total}VNĐ</td>
        </tr>
      </Table>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  detail: state.waterBill.billDetail,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetListWaterDetail: WaterBillStores.GetListWaterDetail,
  PromiseAllTools
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWaterDetailMobileView)
