import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import FooterDesktopView from './footer-desktop.view';
import FooterMobileView from './footer-mobile.view';

class FooterView extends Component {
  renderContent = () => {
    if (isMobile || isTablet) {
      return <FooterMobileView></FooterMobileView>
    }
    return <FooterDesktopView></FooterDesktopView>;
  }

  render() {
    return this.renderContent();
  }
}

export default FooterView;