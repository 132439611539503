import React from 'react';

import '../side-drawer/drawer-toggle-button.scss';

const drawerToggleButton = props => (
    <button className="toggle-button" onClick={props.click}>
        {/* <div className="toggle-button__line" />
        <div className="toggle-button__line" />
        <div className="toggle-button__line" /> */}
        <img src={require('assets/images/menu.svg')} alt="Menu" />
    </button>
);

export default drawerToggleButton;