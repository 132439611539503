export const ApiUrl = {
  /** User*/
  Login: "customer/front/login",
  Register: "customer/front/register",
  ActiveUser: "customer/front/activeCustomer",
  ConfirmCode: "customer/front/confirmResetCode",
  ForgotPassword: "customer/front/forgotPassword",
  ResetPassword: "customer/front/resetPassword",
  SendConfirmCode: "customer/front/sendConfirmCode",
  UserGetProfile: "customer/index/getCustomer",
  UpdateUserProfile: "customer/front/updateProfile",
  ChangePassword: "customer/front/changePassword",

  /* search */
  searchContent: "search/index/getListSearch",
  listHotSearchKeyword: "search/index/getListSearchKeyword",

  /** Shop */
  ShopGetList: "shop/index/getListShopByCustomer",
  ShopGetListUser: "shop/index/getListShop",
  ShopAddOrEdit: "shop/index/updateShop",
  ShopGetDetail: "shop/index/getShop",
  PostRegisterCart: "cart/index/updateCart",
  CartGetList: "cart/index/getListCart",
  CartDelete: "cart/index/deleteCart",

  GetCalendarSlots: "tennis/slot/getCalendarSlots",
  AddOrUpdateCalendarSlot: "tennis/slot/updateSlot",
  DeleteCalendarSlot: "tennis/slot/deleteSlot",

  footballGetCalendarSlots: "football/slot/getCalendarSlots",
  footballAddOrUpdateCalendarSlot: "football/slot/updateSlot",
  footballDeleteCalendarSlot: "football/slot/deleteSlot",

  gymGetCalendarSlots: "gym/slot/getCalendarSlots",
  gymAddOrUpdateCalendarSlot: "gym/slot/updateSlot",
  gymDeleteCalendarSlot: "gym/slot/deleteSlot",

  poolGetCalendarSlots: "pool/slot/getCalendarSlots",
  poolAddOrUpdateCalendarSlot: "pool/slot/updateSlot",
  poolDeleteCalendarSlot: "pool/slot/deleteSlot",

  beautyGetCalendarSlots: "beauty/slot/getCalendarSlots",
  beautyAddOrUpdateCalendarSlot: "beauty/slot/updateSlot",
  beautyDeleteCalendarSlot: "beauty/slot/deleteSlot",

  officeGetCalendarSlots: "office/slot/getCalendarSlots",
  officeAddOrUpdateCalendarSlot: "office/slot/updateSlot",
  officeDeleteCalendarSlot: "office/slot/deleteSlot",

  garageGetCalendarSlots: "garage/slot/getCalendarSlots",
  garageAddOrUpdateCalendarSlot: "garage/slot/updateSlot",
  garageDeleteCalendarSlot: "garage/slot/deleteSlot",

  clinicGetCalendarSlots: "clinic/slot/getCalendarSlots",
  clinicAddOrUpdateCalendarSlot: "clinic/slot/updateSlot",
  clinicDeleteCalendarSlot: "clinic/slot/deleteSlot",

  communityGetCalendarSlots: "community/slot/getCalendarSlots",
  communityAddOrUpdateCalendarSlot: "community/slot/updateSlot",
  communityDeleteCalendarSlot: "community/slot/deleteSlot",

  bbqGetCalendarSlots: "bbq/slot/getCalendarSlots",
  bbqAddOrUpdateCalendarSlot: "bbq/slot/updateSlot",
  bbqDeleteCalendarSlot: "bbq/slot/deleteSlot",

  /** Document */
  DocumentGetList: "paper/index/getListPaper",
  DocumentGetDetail: "paper/index/getPaper",
  DocumentAddOrUpdate: "paper/index/updatePaper",
  DocumentDetele: "paper/index/deletePaper",

  /** Role */
  RoleGetAllPermissions: "api/services/app/Role/GetAllPermissions",

  /** FeedBack */
  PostFeedback: "inform/index/updateInform",
  PostReport: "feedback/index/updateFeedback",
  GetIncidentAreas: "feedback/index/getListFeedback",
  GetIncidentAreasDetail: "feedback/index/getFeedback",

  /** Hot */
  HotGetList: "hot/index/getListHot",
  HotGetDetail: "hot/index/getHot",
  HotAddOrUpdate: "hot/index/updateHot",
  HotDelete: "hot/index/deleteHot",

  /** Comment Hot */
  UpdateCommentHot: "hot/comment/updateComment",
  GetCommentHot: "hot/comment/getComment",
  DeleteCommentHot: "hot/comment/deleteComment",
  GetListCommentHot: "hot/comment/getListComment",

  /** Like Hot */
  IsLikeHot: "hot/like/isDislike",
  UpdateLikeHot: "hot/like/updateLike",

  /** New */
  NewGetList: "news/index/getListNews",
  NewGetDetail: "news/index/getNews",

  /** Comment New */
  UpdateCommentNew: "news/comment/updateComment",
  GetCommentNew: "news/comment/getComment",
  DeleteCommentNew: "news/comment/deleteComment",
  GetListCommentNew: "news/comment/getListComment",

  /** Like New */
  IsLikeNew: "news/like/isDislike",
  UpdateLikeNew: "news/like/updateLike",

  /** Slider */
  SliderGetList: "advertising/index/getListAdvertising",

  /* Quality document */
  GetListQuality: "quality/index/getListQuality",
  GetDetailQuality: "quality/index/getQuality",

  /** Utilities Plus */
  BusGetList: "transport/index/getListTransportType",
  RouteGetList: "transport/index/getListRoute",
  AreaGetList: "transport/index/getListArea",
  BusGetDetail: "transport/index/getTransport",
  HitchhikeGetList: "hitchhike/index/getListHitchhike",
  HitchhikeAddOrUpdate: "hitchhike/index/updateHitchhike",
  HitchhikeCreateRequest: "hitchhike/request/updateRequest",
  HitchhikeAcceptOrDenineRequest: `hitchhike/request/updateRequest`,
  HitchhikeListRequestByIdHitchhike: "hitchhike/request/getListRequest",
  HitchhikeDetailByHitchhikeId: `hitchhike/index/getHitchhike`,
  RepairingHouseholdGetList: "service/index/getListService",
  RepairingHouseholdGetDetail: "service/index/getService",
  EvaluateRepairingHouseholdAddOrUpdate: "service/index/updateVote",
  EvaluateRepairingHouseholdDelete: "service/index/deleteVote",

  /*Utilities */

  //--- Tennis
  AddOrUpdateTennis: "tennis/index/updateTennis",
  GetListRegisterTennis: "tennis/register/getListRegister",
  TennisCalendar: "tennis/index/getListTennis", // will delete later
  TennisRegister: "tennis/index/updateTennisRegister",
  GetListTennis: "tennis/index/getListTennis",
  GetTennisById: "tennis/index/getTennis",
  RegisterUseTennis: "tennis/register/updateRegister",

  //--- BBQ
  BbqList: "bbq/index/getListBbq",
  BbqRegister: "bbq/index/updateBbqRegister",

  AddOrUpdateBbq: "bbq/index/updateBbq",
  GetBbqById: "bbq/index/getBbq",
  GetListRegisterBbq: "bbq/register/getListRegister",
  GetListBbq: "bbq/index/getListBbq",
  RegisterUseBbq: "bbq/register/updateRegister",

  //--- GYM
  GymList: "gym/index/getListGym",
  GetGymById: "gym/index/getGym",
  GymRegister: "gym/index/updateGymRegister",
  RegisterUseGym: "gym/register/updateRegister",

  AddOrUpdateGym: "gym/index/updateGym",
  GetListRegisterGym: "gym/register/getListRegister",
  GetListGym: "gym/index/getListGym",

  //--- Soccer
  SoccerRegister: "football/index/updateFootballRegister",
  GetListSoccer: "football/index/getListFootball",
  GetSoccerById: "football/index/getFootball",
  RegisterUseSoccer: "football/register/updateRegister",

  AddOrUpdateFootball: "football/index/updateFootball",
  GetFootballById: "football/index/getFootball",
  GetListRegisterFootball: "football/register/getListRegister",
  GetListFootball: "football/index/getListFootball",
  RegisterUseFootball: "football/register/updateRegister",

  //--- Spa
  SpaRegister: "beauty/index/updateBeautyRegister",
  GetListSpa: "beauty/index/getListBeauty",
  GetSpaById: "beauty/index/getBeauty",
  RegisterUseSpa: "beauty/register/updateRegister",

  AddOrUpdateBeauty: "beauty/index/updateBeauty",
  GetBeautyById: "beauty/index/getBeauty",
  GetListRegisterBeauty: "beauty/register/getListRegister",
  GetListBeauty: "beauty/index/getListBeauty",
  RegisterUseBeauty: "beauty/register/updateRegister",

  //--- POOL
  PoolList: "pool/index/getListPool",
  GetPoolById: "pool/index/getPool",
  PoolRegister: "pool/index/updatePoolRegister",

  AddOrUpdatePool: "pool/index/updatePool",
  GetListRegisterPool: "pool/register/getListRegister",
  GetListPool: "pool/index/getListPool",
  RegisterUsePool: "pool/register/updateRegister",

  AddOrUpdateGarage: "garage/index/updateGarage",
  GetGarageById: "garage/index/getGarage",
  GetListRegisterGarage: "garage/register/getListRegister",
  GetListGarage: "garage/index/getListGarage",
  RegisterUseGarage: "garage/register/updateRegister",

  AddOrUpdateOffice: "office/index/updateOffice",
  GetOfficeById: "office/index/getOffice",
  GetListRegisterOffice: "office/register/getListRegister",
  GetListOffice: "office/index/getListOffice",
  RegisterUseOffice: "office/register/updateRegister",

  AddOrUpdateCommunity: "community/index/updateCommunity",
  GetCommunityById: "community/index/getCommunity",
  GetListRegisterCommunity: "community/register/getListRegister",
  GetListCommunity: "community/index/getListCommunity",
  RegisterUseCommunity: "community/register/updateRegister",

  AddOrUpdateClinic: "clinic/index/updateClinic",
  GetClinicById: "clinic/index/getClinic",
  GetListRegisterClinic: "clinic/register/getListRegister",
  GetListClinic: "clinic/index/getListClinic",
  RegisterUseClinic: "clinic/register/updateRegister",

  //--- SPORT
  GetListSportNew: "sport/index/getListSportNew",
  GetListSport: "sport/index/getListSport",

  /** Electric */
  ElectricGetList: "electric/index/getListElectric",
  ElectricGetDetail: "electric/index/getElectric",
  ElectricCreateOrUpdate: "electric/index/updateElectric",
  ElectricDelete: "electric/index/deleteElectric",

  /* Electric bill */
  ElectricBillThisMonth: "electric/index/getBillThisMonth",
  ElectricBillByTime: "electric/index/getListElectric",
  ElectricDetailById: "electric/index/getElectric",

  /* Water bill */
  WaterBillThisMonth: "water/index/getBillThisMonth",
  ListWaterBillByTime: "water/index/getListWater",
  WaterBillDetail: "water/index/getWater",

  /* internet bill */
  InternetBillThismonth: "internet/index/getBillThisMonth",

  /* other bill */
  FeeBillThisMonth: "fee/index/getBillThisMonth",
  FeeBillById: "fee/index/getFee",
  FeeBillByTime: "fee/index/getListFee",
  ParkingBillThisMonth: "parking/index/getBillThisMonth",
  ParkingBillByTime: "parking/index/getListParking",
  ParkingBillById: "parking/index/getParking",

  /* commont content like payment guide*/

  GetContentByKeyword: "common/index/getContentByKeyword",

  /** Legal */
  LegalGetList: "document/index/getListDocument",
  LegalGetDetail: "document/index/getDocument",
  LegalCreateOrUpdate: "legal/index/updateLegal",
  LegalDelete: "legal/index/deleteLegal",

  /** Management Information */
  StayingCreateOrUpdate: "staying/index/updateStaying",

  /** Friend */
  FriendGetSuggestionList: "friend/index/getListFriendSuggest",
  FriendGetList: "friend/index/getListFriend",
  FriendGetDetail: "friend/index/getFriend",
  FriendCreateOrUpdate: "friend/index/updateFriend",
  FriendCancel: "friend/index/cancelFriend",
  FriendRemove: "friend/index/removeRequestFriend",
  FriendAcceptRequest: "friend/index/acceptFriend",

  /** Shared api */
  CareerCategory: "customer/index/getListCareer",
  HomeTownGetList: "customer/index/getListProvince",
  DistricGetList: "common/index/getDistric",
  HobbyCategoris: "hobby/index/getAll",
  LandCategoris: "land/index/getLandCategory",
  ShopCategories: "shop/index/getListCategory",
  GenderGetList: "staying/index/getListGender",

  /** AdHouse */
  AdHouseGetList: "land/index/getListLand",
  AdHouseGetDetail: "land/index/getLand",
  AdHouseCreateOrUpdate: "land/index/updateLand",
  AdHouseDelete: "land/index/deleteLand",

  /** Building Management */
  BuildingManagementGetList:
    "BuildingManagement/index/getListBuildingManagement",
  BuildingManagementGetDetail: "company/index/getCompany",
  BuildingManagementCreateOrUpdate:
    "BuildingManagement/index/updateBuildingManagement",
  BuildingManagementDelete: "BuildingManagement/index/deleteBuildingManagement",

  /** Resident Market */
  ResidentMarketGetList: "ad/index/getListAd",
  ResidentMarketGetDetail: "ad/index/getAd",
  ResidentMarketCreateOrUpdate: "ad/index/updateAd",
  ResidentMarketDelete: "ad/index/deleteAd",
  AdListMobileHomePage: "ad/index/getListAdMobile",
  ResidentMarketGetListComment: "ad/comment/getListComment",
  ResidentMarketCreateOrUpdateComment: "ad/comment/updateComment",
  ResidentMarketGetComment: "ad/comment/getComment",
  ResidentMarketDeleteComment: "ad/comment/deleteComment",

  /** Notification */
  NotificationPushMessage: "inform/index/updateInform",
  GetListNotificationFromAgent:
    "/notification/index/getListNotificationFromAgent",
  GetListNotificationFromNeightbor:
    "/notification/index/getListNotificationFromCustomer",
  GetNotificationDetail: "inform/index/getInform",
  GetAllNotificationCount: "notification/index/countNotification",
  GetListCommentNotification: "inform/feedback/getListFeedback",
  PostCommentNotitifcation: "inform/feedback/updateFeedback",
  DeletetNotificationComment: "/inform/feedback/deleteFeedback",
  MaskNotificationIsRead: "/notification/index/read",

  /** Join Event */
  JoinsHandGetList: "topic/index/getListTopic",
  JoinsHandCreateOrUpdate: "topic/index/updateTopic",
  JoinsHandDelete: "topic/index/deleteTopic",
  JoinsHandGetDetail: "topic/index/getTopic",
  JoinsHandUpdateVote: "topic/vote/updateVote",
  JoinsHandDeleteVote: "/topic/vote/deleteVote",

  /** Help */
  HelpingHandGetList: "help/index/getListHelp",
  HelpingHandGetDetail: "help/index/getHelp",
  HelpingHandCreateOrUpdate: "help/index/updateHelp",
  HelpingHandDelete: "help/index/deleteHelp",
  HelpingHandGetComments: "help/comment/getListComment",
  HelpingHandDeleteComment: "help/comment/deleteComment",
  HelpingHandUpdateComment: "help/comment/updateComment",

  /** Project */
  ProjectGetList: "client/client/getAllClient",
  ApartmentGetList: "common/index/getAllApartment",

  /** Security Camera */
  SecurityCameraGetList: "camera/index/getListCamera",

  /** Assist */
  AssistGetList: "assist/index/getListAssist",
  AssistGetDetail: "assist/index/getAssist",
  AssistCreateOrUpdate: "assist/index/updateAssist",
  AssistDelete: "assist/index/deleteAssist",

  /** Members */
  MemberGetList: "agent/index/getListAgent",
  MemberGetDetail: "agent/index/getAgent",

  /** Members */
  ScheduleGetList: "schedule/index/getListSchedule",
  ScheduleGetDetail: "/schedule/index/getSchedule",

  /** Procedures */
  ProcedureGetList: "document/index/getListDocument",
  ProcedureGetCategoryList: "common/index/getDocumentCategory",
  ProcedureGetDetail: "document/index/getDocument",

  //Rent
  GetListCategory: "product/category/getListCategory",
  GetListProduct: "product/index/getListProduct",
  GetProduct: "product/index/getProduct",
  InsertRental: "rental/index/insertRental",

  //--- Create Transaction
  CreateTransactionVNPay: "/transaction/vnpay/createTransaction",

  //-- Club
  GetListCategoryClub: "club/index/getListCategory",
  GetListClub: "club/index/getListClub",
  UpdateClub: "club/index/updateClub",
  GetClub: "club/index/getClub",
  DeleteClub: "club/index/deleteClub",
  GetListCommentClub: "club/comment/getListComment",
  UpdateCommentClub: "club/comment/updateComment",
  GetCommentClub: "club/comment/getComment",
  DeleteCommentClub: "club/comment/deleteComment",

  //--- Advertising
  GetListAdvertising: "advertising/index/getListAdvertising",

  GetPolicy: "client/getPolicy",
};
