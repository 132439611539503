/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "redux/stores/user/user-information.store";
import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { useForm } from "react-hook-form";

class ResetPasswordDesktopView extends Component {
  render() {
    const { user, onChangePassword } = this.props;
    return <ResetPassword user={user} onChangePassword={onChangePassword} />;
  }
}

function ResetPassword(props) {
  const { user, onChangePassword } = props;

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearError,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  const onChangeInputPassword = (e) => {
    const { password, confirmPassword } = getValues();
    console.log(confirmPassword)(
      confirmPassword != "" && password == confirmPassword
    )
      ? clearError(["confirmPassword"])
      : setError("confirmPassword", "validate", "Mật khẩu không trùng khớp");
  };

  const onSubmit = (data) => {
    if (data) {
      onChangePassword({
        customerId: user.customerId,
        oldPassword: data.currentPassword,
        newPassword: data.password,
      }).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.ChangePassword,
              NotificationMessageType.Success
            );
            document.getElementById("changePasswordForm").reset();
          } else {
            AddNotification(
              viVN.Errors[res && res.errorType],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="row"
      id="changePasswordForm"
    >
      <div className="col-10">
        <div className="row form-group">
          <div className="col-4">
            <label className="col-form-label">
              Mật khẩu hiện tại<span className="required"></span>
            </label>
          </div>
          <div className="col-8">
            <input
              type="password"
              name="currentPassword"
              id="currentPassword"
              className={
                "form-control " + (errors.currentPassword ? "is-invalid" : "")
              }
              placeholder="Mật khẩu cũ"
              ref={register({ required: "Trường này là bắt buộc" })}
            />
            {errors.currentPassword && (
              <div className="invalid-feedback">
                {errors.currentPassword.message}
              </div>
            )}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-4">
            <label className="col-form-label">
              Mật khẩu mới<span className="required"></span>
            </label>
          </div>
          <div className="col-8">
            <input
              type="password"
              name="password"
              id="password"
              className={
                "form-control " + (errors.password ? "is-invalid" : "")
              }
              placeholder="Mật khẩu mới"
              onChange={() => onChangeInputPassword()}
              ref={register({
                required: "Trường này là bắt buộc",
                minLength: {
                  value: 6,
                  message: "Mật khẩu phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password.message}</div>
            )}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-4">
            <label className="col-form-label">
              Xác nhận mật khẩu<span className="required"></span>
            </label>
          </div>
          <div className="col-8">
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              className={
                "form-control " + (errors.confirmPassword ? "is-invalid" : "")
              }
              placeholder="Nhập lại mật khẩu"
              ref={register({
                validate: (value) =>
                  value === password.current || "Mật khẩu không trùng khớp",
              })}
            />
            {errors.confirmPassword && (
              <div className="invalid-feedback">
                {errors.confirmPassword.message}
              </div>
            )}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-4"></div>
          <div className="col-4 form-group">
            <button
              type="reset"
              className="btn btn-secondary w-100 text-uppercase"
            >
              Hủy
            </button>
          </div>
          <div className="col-4 form-group">
            <button
              type="submit"
              className="btn btn-success w-100 text-uppercase"
            >
              Đặt lại mật khẩu
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onChangePassword: userActions.ChangePassword,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordDesktopView);
