
import IO from "socket.io-client";
import * as configuration from '../utils/configuration';
import * as apiConfig from './api.config';
configuration.setConfiguration(configuration.ApiServerKey.SOCKET_API_ROOT, apiConfig.socketApi);
const socketRoot = configuration.getConfiguration(configuration.ApiServerKey.SOCKET_API_ROOT);

export default class SocketService {
    constructor(namespace, socketOpts) {
        this.namespace = namespace;
        // console.log(socketRoot)
        this.socket = IO(socketOpts || socketRoot);
        this.socket.on('connect_failed', () => {
            console.log('Socket connection failed.')
        })
        this.socket.on('connect_error', () => {
            console.log('Socket connection error.')
        })
        this.socket.on('disconnect', () => {
            console.log('Socket disconnected.')
        })
    }

    /**
      * Call a service action via Websocket
      * 
      * @param {any} event	name of action
      * @param {any} params	parameters to request
      * @returns	{Promise}
      * 
      * @memberOf Service
      */
    emit(event, params) {
        return new Promise((resolve, reject) => {
            this.socket.emit(event, params, (response) => {
                if (response && response.status == 200)
                    resolve(response.data);
                else {
                    console.error("Socket emit error!", response.error);
                    reject(response.error);
                }
            });

        });
    }

    /** 
     * Listen a service action via Websocket
     * 
     * @param {*} event 
     */
    on(event) {
        return new Promise((resolve, reject) => {
            this.socket.on(event, (response => {
                resolve(response);
            }))
        });
    }

    on(event, action) {
        this.socket.on(event, () => {
            action()
        })
    }

}