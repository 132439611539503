import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../management-desktop.scss";
import 'react-datepicker/dist/react-datepicker.css';
import * as memberActions from 'redux/stores/management/member.store';
import * as scheduleActions from 'redux/stores/management/schedule.store';
import * as viVN from 'translation/vi-VN.json';
import { history } from 'redux/store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType, SetDefaultErrorImage } from 'utils/configuration';
import DatePicker from 'react-datepicker';
import { Table } from 'react-bootstrap';
import moment from 'moment';
moment.locale('vi');
import PaginationControl from 'components/pagination/pagination.view';

class MemberDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member: null,
      agentId: this.props.match.params.agentId,
      startDate: new Date(),
      endDate: new Date(),
      currentPageIndex: 1,
      totalItems: 0,
      schedules: []
    }
  }

  UNSAFE_componentWillMount() {
    const filterObject = {
      from: this.state.startDate,
      to: this.state.endDate,
    };
    this.onGetMember(this.state.agentId, filterObject);
  }

  onGetMember = (agentId, filterObj = null) => {
    this.props.getMember(agentId, filterObj).then(res => {
      if (res.content) {
        this.setState({
          member: res.content
        })
        this.onGetScheduleForAgent(this.state.currentPageIndex, 10, {})
      } else {
        AddNotification(viVN.Errors[res.errorType], NotificationMessageType.Error);
        history.push('/thanh-vien-bql');
      }
    }, err => { 
    })
  }

  onGetScheduleForAgent(pageNumber, filterObj) {
    this.props.getScheduleListForAgent(pageNumber, 10, { ...filterObj, agent_id: this.state.agentId }).then(res => {
      this.setState({
        schedules: res.content && res.content.items || [],
        totalItems: res.content && res.content.totalItemCount || 0
      })
    }, err => { 
    })
  }

  handleChangeStartDate = date => {
    this.setState({
      startDate: date
    });
  };

  handleChangeEndDate = date => {
    this.setState({
      endDate: date
    });
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetScheduleForAgent(pageNumber);
    })
  }

  handleChangeScheduleList = () => {
    const filterObject = {
      from_date: moment(this.state.startDate).format('YYYY-MM-DD'),
      to_date: moment(this.state.endDate).format('YYYY-MM-DD'),
    };
    this.onGetScheduleForAgent(this.state.currentPageIndex, filterObject);
  }

  render() {
    const { startDate, endDate, member, totalItems, schedules, currentPageIndex } = this.state;

    return (
      <div className="management-desktop">
        <h2 className="title-lg mb-0">Thành viên BQL</h2>
        <hr className="mt-2" />
        {member && <div className="member-info-container">

          <div className="row align-items-center">
            <div className="col-2">
              <img src={member.avatarUrl || require('assets/images/29-01.png')} onError={SetDefaultErrorImage} alt="Avatar" className="img-fluid avatar" />
            </div>
            <div className="col-10">
              <div className="member-info text-left">
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-0">
                      {member.agentName}
                    </h5>
                  </div>
                  {member.roleName && <div className="col-12">
                    <span>[{member.roleName}]</span>
                  </div>}
                  <div className="col-12">
                    <a href={`tel:${member.mobileNumber}`}>SĐT: {member.mobileNumber}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row no-gutters mt-3">
            <div className="col-3"></div>
            <div className="col-3 text-right position-relative pr-1">
              <DatePicker selected={startDate} onChange={this.handleChangeStartDate} className="rounded-pill" dateFormat="dd/MM/yyyy" />
              <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
            </div>
            <div className="col-3 text-right position-relative pl-1">
              <DatePicker selected={endDate} onChange={this.handleChangeEndDate} className="rounded-pill" dateFormat="dd/MM/yyyy" />
              <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
            </div>
            <div className="col-3 text-right">
              <button className="btn btn-success rounded-pill" onClick={this.handleChangeScheduleList.bind(this)}>
                <img src={require("assets/images/search-white.svg")} alt="Search White" className="img-search-white" /> Tìm kiếm
                  </button>
            </div>
          </div>

          <div className="bg-white p-3 mt-3">
            <Table hover size="sm" className="mb-0">
              <thead>
                <tr>
                  <th className="border-top-0">Người trực</th>
                  <th className="border-top-0">Thời gian trực</th>
                  <th className="border-top-0">Nhiệm vụ</th>
                </tr>
              </thead>
              <tbody>
                {schedules.map(item => <tr key={item.agentId}>
                  <td>
                    <h5 className="mb-0 align-middle text-capitalize">{member.agentName}</h5>
                    <span>{item.mobileNumber}</span>
                  </td>
                  <td className="align-middle">{item.shiftName} - {item.workDate}</td>
                  <td className="align-middle">{item.mission}</td>
                </tr>)}
                {!schedules.length && <tr><td className="text-center" colSpan="3">Không tìm thấy dữ liệu</td></tr>}
              </tbody>
            </Table>
            <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
          </div>
        </div>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  member: state.member.member,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMember: memberActions.GetMemberDetail,
      getScheduleListForAgent: scheduleActions.GetScheduleListForAgent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MemberDesktopView);