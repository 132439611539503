//@ts-check
import AddNotification from 'components/react-notifications/react-notifications'

/**
 * @param {{errorType:string, errorMessage:string}} error
*/
export default function HandleErrorMessaget(error) {
    console.log(error)
    switch(error.errorType) {
        case "DuplicateDataError": AddNotification('Bạn đã đăng kí thành công , không cần đăng ký lại', "warning"); break;
        case "UnknownError": AddNotification('Lỗi không xác định. Vui lòng liên hệ Admin dể nhận trợ giúp', "error"); break;
        default: AddNotification('Có lỗi xảy ra trong quá xử lý, liên hệ Admin để nhận trợ giúp', "error");
    }
}