//@ts-check
import { useState } from 'react'
import { JwtHelper } from 'utils/jwt-helper';

/**
 * @type {{customerId:number,customerName:string,apartmentId:number,mobileNumber:string,houseNumber:string}|null}
*/
const userInfo = JwtHelper.currentUser() || { customerId: 0, customerName: '', apartmentId: 0, mobileNumber: '', houseNumber: '' };
/**
 * @param {number} shopId - shop id
 * @param {(params:any)=>boolean} registerFunction - sync function, return boolean
*/
export function useRegisterFormHook(shopId, registerFunction) {
    const [hasRegisted, _setHasRegisted] = useState(false);
    const [userName, setUserName] = useState(userInfo.customerName);
    const [phoneNumber, setPhoneNumber] = useState(userInfo.mobileNumber);
    const [validateUserName, _setValidateUserName] = useState('');
    const [validatePhoneNumber, _setValidatePhoneNumber] = useState('');
    const [_registerMonth, setRegisterMonth] = useState(1);
    const handleRegister = () => {
        if (userInfo.customerName) {
            let CheckedRequieFields = true;
            if (!userName.trim()) {
                CheckedRequieFields = false; _setValidateUserName('Trường này không được để trống')
            } else _setValidateUserName('')
            if (!_functionValidatePhoneNumber()) CheckedRequieFields = false;
            if (!CheckedRequieFields) return;
            // call api
            const params = new FormData();
            params.append('id', String(shopId))
            params.append('userName', userName)
            params.append('phone', phoneNumber)
            params.append('registerMonth', String(_registerMonth))
            if (registerFunction({
                shopId: shopId,
                userName: userName,
                phoneNumber: phoneNumber,
                registerMonth: _registerMonth,
            })) _setHasRegisted(true)
        }
    }
    /**
     * @returns {boolean} - result check
    */
    const _functionValidatePhoneNumber = () => {
        if (!phoneNumber.trim()) {
            _setValidatePhoneNumber("Trường này không được để trống");
            return false
        }
        if ((!!!Number(phoneNumber)) || ((phoneNumber.length < 10) || (phoneNumber.length > 11))) {
            _setValidatePhoneNumber("Định dạng số điện thoại không đúng");
            return false
        }
        _setValidatePhoneNumber(null)
        return true
    }
    return ({
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        validateUserName,
        validatePhoneNumber,
        setRegisterMonth,
        handleRegister,
        hasRegisted,
    })
}