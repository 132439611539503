import { Map } from 'immutable';
import { createFormData, AppKey, NewGuid } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { createSaveLoading } from 'core/app.store';
import { RentToMarketList } from 'mock-data/rent-to-market.mock';
import { AssistType } from 'utils/configuration';
let rentToMarketListTemp = RentToMarketList;

const service = new Service();
/** Action Types */
const RENTTOMARKETSAVELIST = 'RENTTOMARKET/SAVELIST';

/** Actions */
export const createRentToMarketList = (rentToMarketListModel) => ({ type: RENTTOMARKETSAVELIST, payload: { rentToMarketList: rentToMarketListModel.rentToMarketList, totalItems: rentToMarketListModel.totalItems } });

/** Action creators */
export const GetRentToMarketList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = { ...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistGetList, payLoad).then(res => {
            dispatch(createRentToMarketList({ rentToMarketList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetRentToMarketDetail = (assistId) => {
    return dispatch => {
        let payLoad = createFormData({ assistId: assistId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteRentToMarket = (assistId) => {
    return dispatch => {
        let payLoad = createFormData({ assistId: assistId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateRentToMarket = (rentToMarketModel) => {
    return dispatch => {
        const model = { ...rentToMarketModel, category_type: AssistType.RentToMarket };
        let payLoad = createFormData(model, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */

export const GetRentToMarketListFake = (pageIndex, size, filterObjs) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            rentToMarketListTemp.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            })
            let rentToMarketList = rentToMarketListTemp.slice(pageIndex * size - size, pageIndex * size);
            if (rentToMarketList) {
                dispatch(createRentToMarketList({ rentToMarketList: rentToMarketList, totalItems: rentToMarketListTemp.length }))
                resolve({
                    content: {
                        items: rentToMarketList,
                        totalItems: rentToMarketList.length
                    },
                    errorType: "",
                    errorMessage: "",
                });
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                });
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetRentToMarketDetailFake = (rentToMarketId) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let rentToMarketDetail = rentToMarketListTemp.find(fr => fr.rent_to_market_id == rentToMarketId);
            if (rentToMarketDetail) {
                resolve({
                    content: rentToMarketDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const CreateOrUpdateRentToMarketFake = (rentToMarketModel) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let newModel;
                let currentIndex = rentToMarketListTemp.findIndex(fr => fr.rent_to_market_id == rentToMarketModel.rent_to_market_id);
                if (currentIndex >= 0) {
                    rentToMarketListTemp[currentIndex] = rentToMarketModel;
                } else {
                    newModel = { ...rentToMarketModel, rent_to_market_id: NewGuid() };
                    rentToMarketListTemp.push(newModel);
                }
                rentToMarketListTemp.sort((a, b) => {
                    return new Date(b.created_date) - new Date(a.created_date);
                });
                dispatch(createRentToMarketList({ rentToMarketList: rentToMarketListTemp, totalItems: rentToMarketListTemp.length }))
                resolve({
                    content: newModel,
                    errorType: "",
                    errorMessage: "",
                })
                dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const DeleteRentToMarketFake = (rentToMarketId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let currentRentToMarket = rentToMarketListTemp.find(item => item.rent_to_market_id == rentToMarketId);
            let newRentToMarketList = rentToMarketListTemp.filter(item => item.rent_to_market_id != rentToMarketId);
            newRentToMarketList.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            });
            rentToMarketListTemp = newRentToMarketList;
            dispatch(createRentToMarketList({ rentToMarketList: rentToMarketListTemp.slice(0, 10), totalItems: rentToMarketListTemp.length }))
            if (currentRentToMarket) {
                resolve({
                    content: {
                        rentToMarketId: rentToMarketId,
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                reject({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    rentToMarketList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function RentToMarketReducer(state = initialState, action) {
    switch (action.type) {
        case RENTTOMARKETSAVELIST:
            return {
                ...state,
                rentToMarketList: action.payload.rentToMarketList,
                totalItems: action.payload.totalItems,
            }
        default:
            return state;
    }
}