import { NewGuid, randomDate } from 'utils/configuration';

export const JoinsHandList = [{
    join_event_id: NewGuid(),
    created_by: 'Tài Nguyễn',
    created_by_id: 1,
    created_date: randomDate(new Date(2020, 0, 1), new Date()),
    join_event_name: `Giao hữu tennis cư dân Splendora`,
    join_event_content: `Giải giao hữu tennis Splendora tiếp nối chuỗi sự kiện cộng đồng đã tổ chức rất thành công tại đây. Giải Tennis Splendora chắc chắn sẽ diễn ra sôi nổi, thân thiện, mang lại nhiều cơ hội giao lưu, hợp tac`,
    join_event_place: 'Trung tâm văn hóa ',
    from_date: randomDate(new Date(2020, 0, 1), new Date()),
    to_date: randomDate(new Date(2020, 0, 1), new Date()),
    join_event_time: '06/02 - 09/02/2020',
    attachmentUrl: require('assets/images/tennis.jpg'),
    responsible_person_id: 1,
    responsible_person_name: 'Tài Nguyễn',
    join_event_poll: {
        name: 'Giải giao hữu tennis',
        items: [
            {
                poll_id: NewGuid(),
                poll_name: 'Tham gia',
                voted_people: [
                    {
                        voted_by: 'Việt Nguyễn',
                        voted_by_id: 2,
                        voted_profile_url: require('assets/images/29-12.png'),
                    },
                    {
                        voted_by: 'Hậu Nguyễn',
                        voted_by_id: 3,
                        voted_profile_url: require('assets/images/29-12.png'),
                    },
                ]
            },
            {
                poll_id: NewGuid(),
                poll_name: 'Không tham gia',
                voted_people: [
                    {
                        voted_by: 'Tài Nguyễn',
                        voted_by_id: 1,
                        voted_profile_url: require('assets/images/29-12.png'),
                    },
                    {
                        voted_by: 'Mão Nguyễn',
                        voted_by_id: 4,
                        voted_profile_url: require('assets/images/29-12.png'),
                    },
                    {
                        voted_by: 'Bình Trịnh',
                        voted_by_id: 5,
                        voted_profile_url: require('assets/images/29-12.png'),
                    },
                    {
                        voted_by: 'Cương Trần',
                        voted_by_id: 6,
                        voted_profile_url: require('assets/images/29-12.png'),
                    },
                    {
                        voted_by: 'Việt Tăng',
                        voted_by_id: 7,
                        voted_profile_url: require('assets/images/29-12.png'),
                    },
                ]
            },
            {
                poll_id: NewGuid(),
                poll_name: 'Test',
                voted_people: [

                ]
            },
        ]
    }
},
{
    join_event_id: NewGuid(),
    created_by: 'Việt Nguyễn',
    created_by_id: 2,
    created_date: randomDate(new Date(2020, 0, 1), new Date()),
    join_event_name: `Giao hữu tennis cư dân Splendora`,
    join_event_content: `Giải giao hữu tennis Splendora tiếp nối chuỗi sự kiện cộng đồng đã tổ chức rất thành công tại đây. Giải Tennis Splendora chắc chắn sẽ diễn ra sôi nổi, thân thiện, mang lại nhiều cơ hội giao lưu, hợp tac`,
    join_event_place: 'Khu vui choi',
    from_date: randomDate(new Date(2020, 0, 1), new Date()),
    to_date: randomDate(new Date(2020, 0, 1), new Date()),
    join_event_time: '06/02 - 09/02/2020',
    attachmentUrl: require('assets/images/tennis.jpg'),
    responsible_person_id: 1,
    responsible_person_name: 'Tài Nguyễn',
    join_event_poll: {
        name: 'Giải giao hữu bóng bàn',
        items: [
            {
                poll_id: NewGuid(),
                poll_name: 'Tham gia',
                voted_people: [{
                    voted_by: 'Việt Nguyễn',
                    voted_by_id: 2,
                    voted_profile_url: require('assets/images/29-12.png'),
                },
                {
                    voted_by: 'Hậu Nguyễn',
                    voted_by_id: 3,
                    voted_profile_url: require('assets/images/29-12.png'),
                },
                ]
            },
            {
                poll_id: NewGuid(),
                poll_name: 'Không tham gia',
                voted_people: [{
                    voted_by: 'Tài Nguyễn',
                    voted_by_id: 1,
                    voted_profile_url: require('assets/images/29-12.png'),
                },
                {
                    voted_by: 'Mão Nguyễn',
                    voted_by_id: 4,
                    voted_profile_url: require('assets/images/29-12.png'),
                },
                {
                    voted_by: 'Bình Trịnh',
                    voted_by_id: 5,
                    voted_profile_url: require('assets/images/29-12.png'),
                },
                ]
            },
        ]
    }
},
];