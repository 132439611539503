/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment";

import * as appActions from "core/app.store";
import * as shopActions from "redux/stores/shop/shop.store";

import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./booking-mobile.scss";

const StatusType = {
  ALL: "ALL",
  BOOKING: "BOOKING",
  PENDING: "PENDING",
};

const StatusCode = {
  CHO_XU_LY: 1,
  DONG_Y: 2,
  TU_CHOI: 3,
};

function BookingGymView(props) {
  const {
    showLoading,
    type,
    shopId,
    onGetShopBooking,
    onAddOrUpdateBooking,
  } = props;

  const [booking, setBooking] = useState([]);
  const [pending, setPending] = useState([]);

  const [bookingDate, setBookingDate] = useState(new Date());
  const [pendingDate, setPendingDate] = useState(new Date());

  useEffect(() => {
    onGetList(
      {
        shopId: shopId,
        date: moment(bookingDate).format("YYYY-MM-DD"),
        statusId: StatusCode.DONG_Y,
        pageIndex: 1,
        size: 9999,
      },
      StatusType.BOOKING
    );
    onGetList(
      {
        shopId: shopId,
        date: moment(pendingDate).format("YYYY-MM-DD"),
        statusId: StatusCode.CHO_XU_LY,
        pageIndex: 1,
        size: 9999,
      },
      StatusType.PENDING
    );
  }, [shopId]);

  const onGetList = (filterObj, statusType = StatusType.ALL) => {
    showLoading(true);
    onGetShopBooking(filterObj, type).then(
      (res) => {
        switch (statusType) {
          case StatusType.ALL:
            setBooking(
              res.booking && res.booking.length > 0 ? res.booking : []
            );
            setPending(
              res.booking && res.booking.length > 0
                ? res.booking.filter(
                    (item) => item.statusId == StatusCode.CHO_XU_LY
                  )
                : []
            );
            break;
          case StatusType.BOOKING:
            setBooking(
              res.booking && res.booking.length > 0 ? res.booking : []
            );
            break;
          case StatusType.PENDING:
            setPending(
              res.booking && res.booking.length > 0
                ? res.booking.filter(
                    (item) => item.statusId == StatusCode.CHO_XU_LY
                  )
                : []
            );
            break;
          default:
            break;
        }
        showLoading(false);
      },
      (err) => {
        showLoading(false);
      }
    );
  };

  const onConfirm = (data) => {
    if (!data) return;
    showLoading(true);
    onAddOrUpdateBooking(
      {
        register_id: data.registerId,
        status_id: StatusCode.DONG_Y, // Xác nhận đặt chỗ
      },
      type
    ).then(
      (res) => {
        AddNotification("Xác nhận thành công", NotificationMessageType.Success);
        onGetList(
          {
            shopId: shopId,
            date: moment(bookingDate).format("YYYY-MM-DD"),
            statusId: StatusCode.DONG_Y,
            pageIndex: 1,
            size: 9999,
          },
          StatusType.BOOKING
        );
        onGetList(
          {
            shopId: shopId,
            date: moment(pendingDate).format("YYYY-MM-DD"),
            statusId: StatusCode.CHO_XU_LY,
            pageIndex: 1,
            size: 9999,
          },
          StatusType.PENDING
        );
      },
      (err) => {
        showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const onCancel = (data) => {
    if (!data) return;
    showLoading(true);
    onAddOrUpdateBooking(
      {
        register_id: data.registerId,
        status_id: StatusCode.TU_CHOI, // Từ chối đặt chỗ
      },
      type
    ).then(
      (res) => {
        AddNotification("Hủy thành công", NotificationMessageType.Success);
        onGetList(
          {
            shopId: shopId,
            date: moment(bookingDate).format("YYYY-MM-DD"),
            statusId: StatusCode.DONG_Y,
            pageIndex: 1,
            size: 9999,
          },
          StatusType.BOOKING
        );
        onGetList(
          {
            shopId: shopId,
            date: moment(pendingDate).format("YYYY-MM-DD"),
            statusId: StatusCode.CHO_XU_LY,
            pageIndex: 1,
            size: 9999,
          },
          StatusType.PENDING
        );
      },
      (err) => {
        showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const FormatDate = (date) => {
    if (!date) return "";
    const listDate = date.split("-");
    if (listDate.length !== 3) return "";
    return moment(new Date(listDate[0], listDate[1] - 1, listDate[2])).format(
      "DD-MM-YYYY"
    );
  };

  const FormatTime = (date) => {
    if (!date) return "";
    const listDate = date.split(":");
    if (listDate.length !== 3) return "";
    let today = new Date();
    return moment(
      new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        listDate[0],
        listDate[1],
        listDate[2]
      )
    ).format("HH:mm");
  };

  return (
    <div className="bg-white booking-mobile">
      <Tab.Container defaultActiveKey="nhan-dat-ban">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="nhan-dat-ban">
              <span>Nhận đặt sân</span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="cho-xu-ly">
              <span>Chờ xử lý</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="nhan-dat-ban">
            <div className="form-group">
              <DatePicker
                selected={bookingDate}
                onChange={(date) => {
                  setBookingDate(date);
                  onGetList(
                    {
                      shopId: shopId,
                      date: moment(date).format("YYYY-MM-DD"),
                      statusId: StatusCode.DONG_Y,
                      pageIndex: 1,
                      size: 9999,
                    },
                    StatusType.BOOKING
                  );
                }}
                dateFormat="dd/MM/yyyy"
                className="form-control rounded-pill"
              />
            </div>
            <table className="table table-borderless table-striped nhan-dat-ban">
              <thead>
                <tr>
                  <th>Khách hàng</th>
                  <th className="text-center">Thời gian</th>
                  <th className="text-right">Ngày đăng ký</th>
                </tr>
              </thead>
              <tbody>
                {booking && booking.length > 0 ? (
                  booking.map((item) => (
                    <tr key={item.registerId}>
                      <td>
                        <h5>{item.registerName}</h5>
                        <span>{item.mobileNumber}</span>
                      </td>
                      <td className="text-center align-middle">
                        {FormatTime(item.fromHour) +
                          " - " +
                          FormatTime(item.toHour)}
                      </td>
                      <td className="text-right align-middle">
                        {FormatDate(item.registerDate)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center align-middle">
                      <span>Không có dữ liệu</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Tab.Pane>
          <Tab.Pane eventKey="cho-xu-ly">
            <div className="form-group">
              <DatePicker
                selected={pendingDate}
                onChange={(date) => {
                  setPendingDate(date);
                  onGetList(
                    {
                      shopId: shopId,
                      date: moment(date).format("YYYY-MM-DD"),
                      statusId: StatusCode.CHO_XU_LY,
                      pageIndex: 1,
                      size: 9999,
                    },
                    StatusType.PENDING
                  );
                }}
                dateFormat="dd/MM/yyyy"
                className="form-control rounded-pill"
              />
            </div>
            <table className="table table-borderless table-striped cho-xu-ly p-3">
              <tbody>
                {pending && pending.length > 0 ? (
                  pending.map((item) => (
                    <tr key={item.registerId}>
                      <td>
                        <h5>{item.registerName}</h5>
                        <span>Số điện thoại: {item.mobileNumber}</span>
                        <br />
                        <span>
                          Thời gian:{" "}
                          {FormatTime(item.fromHour) +
                            " - " +
                            FormatTime(item.toHour)}
                        </span>
                        <br />
                        <span>
                          Ngày đăng ký: {FormatDate(item.registerDate)}
                        </span>
                      </td>
                      <td className="text-center align-middle">
                        <button
                          className="btn btn-secondary mr-2"
                          onClick={() => onCancel(item)}
                        >
                          Hết chỗ
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() => onConfirm(item)}
                        >
                          Xác nhận
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">
                      <span>Không có dữ liệu</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      onGetShopBooking: shopActions.GetCartList,
      onAddOrUpdateBooking: shopActions.AddOrUpdateBooking,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookingGymView);
