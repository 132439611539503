import React, { Component } from 'react';
import './utilities-list-mobile.scss';
import { Link } from 'react-router-dom';
import * as PageUrls from '../../PageUrls';

class UtilitiesListMobileView extends Component {
    render() {
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Tiện ích</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="utilities-list-mobile p-3">
                    <div className="row">
                        <div className="col-3 text-center mb-4">
                            <Link to={PageUrls.UtilitiesSportHomeMobile}>
                                <img src={require('assets/images/gym.svg')} alt="Thể thao" />
                            </Link>
                            <h5>
                                <Link to={PageUrls.UtilitiesSportHomeMobile}>Thể thao</Link>
                            </h5>
                        </div>

                        <div className="col-3 text-center mb-4">
                            <Link to={PageUrls.UtilitiesSpaHomeMobile}>
                                <img src={require('assets/images/spa.svg')} alt="Làm đẹp" />
                            </Link>
                            <h5>
                                <Link to={PageUrls.UtilitiesSpaHomeMobile}>Làm đẹp</Link>
                            </h5>
                        </div>

                        <div className="col-3 text-center mb-4">
                            <Link to={PageUrls.UtilitiesRestaurantHomeMobile}>
                                <img src={require('assets/images/nha-hang.svg')} alt="Nhà hàng" />
                            </Link>
                            <h5>
                                <Link to={PageUrls.UtilitiesRestaurantHomeMobile}>Nhà hàng</Link>
                            </h5>
                        </div>

                        <div className="col-3 text-center mb-4">
                            <Link to={PageUrls.UtilitiesCafeHomeMobile}>
                                <img src={require('assets/images/cafe.svg')} alt="Cafe" />
                            </Link>
                            <h5>
                                <Link to={PageUrls.UtilitiesCafeHomeMobile}>Cafe</Link>
                            </h5>
                        </div>

                        <div className="col-3 text-center mb-4">
                            <Link to={PageUrls.UtilitiesSuperMarketHomeMobile}>
                                <img src={require('/assets/images/sieu-thi.svg')} alt="Siêu thị" />
                            </Link>
                            <h5>
                                <Link to={PageUrls.UtilitiesSuperMarketHomeMobile}>Siêu thị</Link>
                            </h5>
                        </div>

                        <div className="col-3 text-center mb-4">
                            <Link to={PageUrls.UtilitiesSchoolHomeMobile}>
                                <img src={require('assets/images/truong-hoc.svg')} alt="Trường học" />
                            </Link>
                            <h5>
                                <Link to={PageUrls.UtilitiesSchoolHomeMobile}>Trường học</Link>
                            </h5>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default UtilitiesListMobileView;