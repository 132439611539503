/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import HeaderView from "../header/header.view.jsx";
import NavigationView from "../navigation/navigation.view.jsx";
import Footer from "../footer/footer.view.jsx";
import RedirectAuto from '../redirect-auto/redirect-auto.view';
import { isMobile, isTablet } from "react-device-detect";

class LayoutErrorDesktop extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (isMobile || isTablet) return <RedirectAuto currentUrlIsForMobile={false} match={this.props.match} history={this.props.history} />
    return (
      <div>
        <HeaderView />
        <NavigationView />
        <div className="full-width-layout">
          <main>{this.props.children}</main>
          <Footer />
        </div>
      </div>
    );
  }
}

export default LayoutErrorDesktop;
