import { Map } from "immutable";
import { createSaveLoading } from "core/app.store";
import { createFormData, AppKey } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();

export const Registration = (data, formData = new FormData()) => {
  return (dispatch) => {
    dispatch(createSaveLoading(true));
    let payLoad = createFormData(
      data,
      AppKey.PostPayloadPrefix,
      formData,
      false
    );
    return service
      .post(ApiUrl.Register, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

export const GetPolicy = () => {
  return (dispatch) => {
    dispatch(createSaveLoading(true));
    return service
      .post(ApiUrl.GetPolicy)
      .then((res) => {
        dispatch(createSaveLoading(false));
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

// Initial state
export const initialState = Map({
  isLoading: false,
}).toJS();

/* Reducers */
export default function RegistrationReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
