/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Viewer, { Worker, SpecialZoomLevel } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import "../management-desktop.scss";
import * as legalActions from "redux/stores/management/legal.store";
import * as viVN from "translation/vi-VN.json";
import { history } from "redux/store";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType } from "utils/configuration";
import { renderError } from "components/pdf-error-rendering/pdf-error-rendering.jsx";

import { browserVersion, isSafari } from "react-device-detect";

if (isSafari === true && browserVersion <= 11) {
  require("intersection-observer");
}

class LegalDetailDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legal: null,
      legalId: this.props.match.params.legalId,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetLegalDetail(this.state.legalId);
  }

  onGetLegalDetail = (legalId) => {
    this.props.getLegalDetail(legalId).then(
      (res) => {
        if (res.content) {
          this.setState({
            legal: res.content,
          });
        } else {
          AddNotification(
            viVN.Errors[res.errorType],
            NotificationMessageType.Error
          );
          history.push("/van-ban-phap-ly");
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  render() {
    const { legal } = this.state;
    const fileExtension = legal && legal.url && legal.url.split(".");
    const errorFile =
      fileExtension &&
      fileExtension.length > 0 &&
      fileExtension[fileExtension.length - 1] === "pdf"
        ? false
        : true;

    return (
      <div className="management-desktop">
        <h2 className="title-lg mb-0">Văn bản pháp lý</h2>
        <hr className="mt-2" />

        <h4 className="title-sm">{legal && legal.name}</h4>

        {/* <div className="management-tags mt-3 mb-4">
          <ul>
            <li>
              <a href="#">
                <img src={require("assets/images/tag.svg")} alt="tag" className="img-tag" /> Tags
              </a>
            </li>
            <li>
              <a href="#">
                thủ tục BQL
              </a>
            </li>
            <li>
              <a href="#">
                biểu mẫu
              </a>
            </li>
            <li>
              <a href="#">
                biểu mẫu đăng ký
              </a>
            </li>
            <li>
              <a href="#">
                đăng ký cải tạo
              </a>
            </li>
            <li>
              <a href="#">
                phiếu đăng ký
              </a>
            </li>
            <li>
              <a href="#">
                thủ tục
              </a>
            </li>
          </ul>
        </div> */}

        {errorFile && <p>Không đúng định dạng</p>}

        {!errorFile && legal && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div className="pdf-view-container">
              <Viewer
                fileUrl={legal.url}
                defaultScale={SpecialZoomLevel.PageFit}
                renderError={renderError}
              />
            </div>
          </Worker>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLegalDetail: legalActions.GetLegalDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalDetailDesktopView);
