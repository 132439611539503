import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function RenderNavigationBar(props) {
    const activeClass = new Array(4).fill("");
    switch (props.type) {
        case "internet": activeClass[2] = "active-internet"; break;
        case "water": activeClass[1] = "active-water"; break;
        case "electric": activeClass[0] = "active-electric"; break;
        case "other": activeClass[3] = "active-other-service"; break;
    }

    return (
        <Fragment>
            <header className="header-mobile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/m">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                            <div className="header-title text-center">Thanh toán hóa đơn</div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="payment-nav">
                <ul className="list-inline">
                    <li className={`list-inline-item ${activeClass[0]}`}>
                        <Link to="/m/thanh-toan/tien-dien">
                            <img src={require("assets/images/tien-dien.svg")} alt="Tiền điện" />
                        </Link>
                        <br />
                        <Link to="/m/thanh-toan/tien-dien">Tiền điện</Link>
                    </li>
                    <li className={`list-inline-item ${activeClass[1]}`}>
                        <Link to="/m/thanh-toan/tien-nuoc">
                            <img src={require("/assets/images/tien-nuoc.svg")} alt="Tiền nước" />
                        </Link>
                        <br />
                        <Link to="/m/thanh-toan/tien-nuoc">Tiền nước</Link>
                    </li>
                    <li className={`list-inline-item ${activeClass[2]}`}>
                        <Link to="/m/thanh-toan/internet">
                            <img src={require("assets/images/internet.svg")} alt="Internet" />
                        </Link>
                        <br />
                        <Link to="/m/thanh-toan/internet">Internet</Link>
                    </li>
                    <li className={`list-inline-item ${activeClass[3]}`}>
                        <Link to="/m/thanh-toan/dich-vu-khac">
                            <img src={require("assets/images/dich-vu-khac.svg")} alt="Dịch vụ khác" />
                        </Link>
                        <br />
                        <Link to="/m/thanh-toan/dich-vu-khac">Dịch vụ khác</Link>
                    </li>
                </ul>
            </div>
        </Fragment>
    )
}