/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import * as appActions from "core/app.store";
import * as clubActions from "redux/stores/club/club.store";

import { Error } from "components/support-create-form/validate-style";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import {
  NewGuid,
  ValidImageTypes,
  convertHtmlToTextarea,
  convertTextareaToHtml,
  validateFile,
  CheckMaxFile,
  NotificationMessageType,
  ImageExtensions,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";
import { history } from "redux/store";

import "../../club.scss";

function UpdatePingPongForm(props) {
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [previewAttachments, setPreviewAttachment] = useState(
    props.model.clubFile || []
  );
  const [adFiles, setAdFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  let initialValues = {
    content: convertHtmlToTextarea(props.model.content),
  };

  const onSubmit = (data) => {


    let submittedData = {
      category_id: clubActions.clubType.BONG_BAN,
      club_id: props.model.clubId,
      content: convertTextareaToHtml(data.content),
    };

    let formData = new FormData();
    previewAttachments &&
      previewAttachments.length > 0 &&
      previewAttachments.map((file) =>
        formData.append("club_file[]", file.file)
      );
    deleteFiles &&
      deleteFiles.length > 0 &&
      deleteFiles.map((file) => formData.append("delete_file[]", file));

    props.showLoading(true);
    props.addOrUpdatePost(submittedData, formData).then(
      (res) => {
        props.showLoading(false);
        history.push("/m/lang-gieng-gan/cau-lac-bo/bong-ban");
      },
      (err) => {
        props.showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const onFileChange = (event) => {
    let files = Array.from(event.target.files);
    if (files && files.length) {
      // validate maximum length
      var validateMsg = CheckMaxFile(adFiles.length + files.length);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      // validate file
      files.map((file) => {
        if (validateFile(file, ImageExtensions)) {
          validateMsg = validateFile(file, ImageExtensions);
        }
      });
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage("");

      var previewImg = [];
      var imgFiles = [];
      files.map((file) => {
        var reader = new FileReader();
        reader.onload = (e) => {
          previewImg = [
            ...previewImg,
            {
              fileUrl: e.target.result,
              fileName: file.name,
              fileId: NewGuid(),
              file: file,
              fileType: ValidImageTypes.includes(file.type)
                ? "image"
                : file.type,
            },
          ];
          setPreviewAttachment([...previewAttachments, ...previewImg]);
        };
        reader.readAsDataURL(file);
        imgFiles = [...imgFiles, file];
        setAdFiles([...adFiles, ...imgFiles]);
      });
    }
  };

  const onRemovedAttachment = (fileId) => {
    setPreviewAttachment(
      previewAttachments.filter((att) => att.fileId != fileId)
    );
    setDeleteFiles([...deleteFiles, fileId]);
  };

  return (
    <div>
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m/lang-gieng-gan/cau-lac-bo/bong-ban">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">Chỉnh sửa bài viết</div>
            </div>
          </div>
        </div>
      </header>
      <form className="edit-post-mobile p-3" onSubmit={handleSubmit(onSubmit)}>
        <textarea
          defaultValue={initialValues.content}
          name="content"
          className="form-control"
          placeholder="Nội dung"
          rows="5"
          ref={register({
            required: "Trường bắt buộc",
          })}
        ></textarea>
        {errors.content && <Error content={errors.content.message} />}
        <div className="golf-upload mt-3">
          <div className="row">
            {previewAttachments.map((att) => (
              <div
                key={att.fileId}
                className="col-4 position-relative mb-4 attachment-wrapper"
              >
                <img
                  src={att.fileUrl}
                  alt={att.fileName}
                  className="img-golf-market img-fluid"
                />
                <div className="overlay"></div>
                <img
                  onClick={() => onRemovedAttachment(att.fileId)}
                  src={require("assets/images/close-white.svg")}
                  alt="close-white"
                  className="img-close-white"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6 file-upload">
            <div className="title-upload">
              <img
                src={require("assets/images/collections.png")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <span>Thêm ảnh</span>
            </div>
            <input
              type="file"
              name="ad_file"
              accept="image/*"
              multiple
              className="media-file"
              onChange={onFileChange.bind(this)}
            />
            {errors.ad_file && <Error content={errors.ad_file.message} />}
            {validateImgMessage && <Error content={validateImgMessage} />}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn btn-success rounded-pill w-100"
            >
              Lưu chỉnh sửa
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

class EditPingPongMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      model: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetResidentMarketPostDetail();
  }

  onGetResidentMarketPostDetail = () => {
    if (this.props.match.params.topicId) {
      this.props.getPostDetail(this.props.match.params.topicId).then(
        (res) => {
          if (res && res.content) {
            this.setState({
              model: res.content,
            });
          } else {
            history.push("/m/lang-gieng-gan/cau-lac-bo/bong-ban");
            AddNotification(
              viVN.Errors[(res && res.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          history.push("/m/lang-gieng-gan/cau-lac-bo/bong-ban");
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    }
  };

  render() {
    const { model } = this.state;
    return (
      <div>
        {model && <UpdatePingPongForm {...this.props} {...this.state} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      addOrUpdatePost: clubActions.AddOrUpdatePost,
      getPostDetail: clubActions.GetPostDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPingPongMobileView);
