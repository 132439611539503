/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Error } from "components/support-create-form/validate-style";
import * as residentMarketActions from "redux/stores/resident/resident-market.store";
import {
  NewGuid,
  ValidImageTypes,
  convertTextareaToHtml,
  validateFile,
  CheckMaxFile,
  NotificationMessageType,
} from "utils/configuration";
import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";

import "../../advertising-desktop.scss";
import "../../resident-market/resident-market-desktop.scss";

function CreateOrUpdateResidentMarketForm(props) {
  const { register, errors, handleSubmit, getValues, setValue } = useForm({
    mode: "onChange",
  });
  const [previewAttachments, setPreviewAttachment] = useState([]);
  const [adFiles, setAdFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const onSubmit = (data) => {
    if (!adFiles.length) {
      return;
    }

    data.ad_file = [...adFiles];
    data.content = convertTextareaToHtml(data.content);
    props.onCreateOrUpdateResidentMarket &&
      props.onCreateOrUpdateResidentMarket(data);
  };

  const onFileChange = (event) => {
    let files = Array.from(event.target.files);
    if (files && files.length) {
      // validate maximum length
      var validateMsg = CheckMaxFile(adFiles.length + files.length);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      // validate file
      files.map((file) => {
        if (validateFile(file)) {
          validateMsg = validateFile(file);
        }
      });
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage("");

      var previewImgs = [];
      var imgFiles = [];
      files.map((file) => {
        var reader = new FileReader();
        reader.onload = (e) => {
          previewImgs = [
            ...previewImgs,
            {
              fileUrl: e.target.result,
              fileName: file.name,
              fileId: NewGuid(),
              fileType: ValidImageTypes.includes(file.type)
                ? "image"
                : file.type,
            },
          ];
          setPreviewAttachment([...previewAttachments, ...previewImgs]);
        };
        reader.readAsDataURL(file);
        imgFiles = [...imgFiles, file];
        setAdFiles([...adFiles, ...imgFiles]);
      });
    }
  };

  // function onFileChange(e) {
  //   if (e.target.files) {
  //     const listFile = Array.from(e.target.files);
  //     let fileCount = adFiles.length;
  //     Promise.all(
  //       listFile.map((file) => {
  //         return new Promise((resolve, reject) => {
  //           if (CheckMaxFile(fileCount)) {
  //             setErrorFile({
  //               status: true,
  //               maxFile: CheckMaxFile(fileCount),
  //             });
  //             resolve({
  //               err: true,
  //             });
  //           } else if (
  //             !errorFile.maxFile &&
  //             !errorFile.maxSize &&
  //             CheckFileSize(file.size)
  //           ) {
  //             setErrorFile({ status: true, maxSize: CheckFileSize(file.size) });
  //             resolve({
  //               err: true,
  //             });
  //           } else if (
  //             !errorFile.maxFile &&
  //             !errorFile.typeError &&
  //             CheckFileTypeImage(file.type)
  //           ) {
  //             setErrorFile({
  //               status: true,
  //               typeError: CheckFileTypeImage(file.type),
  //             });
  //             resolve({
  //               err: true,
  //             });
  //           } else {
  //             const reader = new FileReader();
  //             if (file.type.match("image")) {
  //               fileCount++;
  //               reader.addEventListener("load", (ev) => {
  //                 resolve({
  //                   fileId: NewGuid(),
  //                   fileName: file.name,
  //                   fileUrl: ev.target.result,
  //                   file: file,
  //                   err: false,
  //                   fileType: "image",
  //                 });
  //               });
  //               reader.addEventListener("error", reject);
  //               reader.readAsDataURL(file);
  //             } else {
  //               setErrorFile({
  //                 status: true,
  //                 typeError: CheckFileTypeImage(file.type),
  //               });
  //               resolve({
  //                 err: true,
  //               });
  //             }
  //           }
  //         });
  //       })
  //     ).then(
  //       (images) => {
  //         let result = [];
  //         images.map((image) => {
  //           !image.err && result.push(image);
  //         });
  //         const fileResult = result.map(file => { return file.file });
  //         setAdFiles([...adFiles, ...fileResult]);
  //         setPreviewActtachment([...previewActtachments, ...result]);
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   }
  // }

  const onRemovedAttachment = (acttactmentId) => {
    setPreviewAttachment(
      previewAttachments.filter((att) => att.fileId != acttactmentId)
    );
  };

  const handleOnPaste = () => {
    const currentContentValue = getValues('content').content;
    const regContent = /[^0-9a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ,\s\.]/gi
    setValue('content', currentContentValue.replace(regContent, ""))
  }

  return (
    <div className="advertising-desktop">
      <h2 className="title-lg">Thêm sản phẩm</h2>
      <form
        className="form-add-resident-market"
        onSubmit={handleSubmit(onSubmit)}
      >
        <textarea
          name="content"
          className="form-control"
          placeholder="Mô tả sản phẩm"
          rows="5"
          id='residentMarketTextArea'
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Nội dung phải có ít nhất 6 ký tự",
            },
          })}
          onPaste={() => setTimeout(() => handleOnPaste(), 50)}
        ></textarea>
        {errors.content && <Error content={errors.content.message} />}
        <div className="row">
          <div className="col-12 preview-img">
            {previewAttachments.map((att) => (
              <div key={att.fileId} className="attachment-wrapper">
                <img
                  src={att.fileUrl}
                  alt={att.fileName}
                  className="img-attachment img-fluid"
                />
                <div className="overlay"></div>
                <img
                  onClick={(e) => onRemovedAttachment(att.fileId)}
                  src={require("assets/images/close-white.svg")}
                  alt="close-white"
                  className="img-close-white"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-6 file-upload">
            <div className="title-upload">
              <img
                src={require("assets/images/collections.png")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <span>Upload ảnh</span>
            </div>
            <input
              type="file"
              name="ad_file"
              accept=".png, .jpg, .jpeg"
              multiple
              className="media-file"
              onChange={onFileChange.bind(this)}
              ref={register({
                required: { value: true, message: "Trường bắt buộc" },
              })}
            />
            {errors.ad_file && <Error content={errors.ad_file.message} />}
            {validateImgMessage && <Error content={validateImgMessage} />}
            {/* {errorFile.status && (
              <div>
                <Error content={errorFile.maxFile} />
                {!errorFile.maxFile && errorFile.maxSize && <Error content={errorFile.maxSize} />}
                {!errorFile.maxFile && errorFile.typeError && <Error content={errorFile.typeError} />}
              </div>
            )} */}
          </div>
          <div className="col-6 text-right">
            <button type="submit" className="btn btn-success w-50">
              Tạo bài viết
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

class AddResidentMarketDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
    };
  }

  UNSAFE_componentWillMount() { }

  onCreateOrUpdateResidentMarket = (data) => {
    let submitedData = {
      ...data,
      customer_id: this.state.currentUser.customerId,
      ad_name: "",
      comments: [],
    };
    this.props.createOrUpdateResidentMarket(submitedData).then(
      (res) => {
        if (res && res.content) {
          AddNotification(
            "Đăng tin thành công, vui lòng đợi phê duyệt",
            NotificationMessageType.Success
          );
          history.push("/rao-vat/cho-cu-dan");
        } else {
          AddNotification(
            viVN.Errors[(res && res.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  render() {
    return (
      <CreateOrUpdateResidentMarketForm
        onCreateOrUpdateResidentMarket={this.onCreateOrUpdateResidentMarket.bind(
          this
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateResidentMarket:
        residentMarketActions.CreateOrUpdateResidentMarket,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddResidentMarketDesktopView);
