/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { NotificationMessageType, TokenKey, FormatDate, getCookies } from "utils/configuration";
import AddNotification from 'components/react-notifications/react-notifications';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as stayingActions from "redux/stores/management/information.store";
import * as viVN from 'translation/vi-VN.json';
import * as projectActions from "redux/stores/project/project.store";

import Select from "react-select";
import AsyncPaginate from "react-select-async-paginate";
import SharedService from "services/share.service.js";

const sharedService = new SharedService();
import './temporary-absence-declaration-mobile.scss';

class TemporaryAbsenceDeclarationMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            genderList: [],
            houseList: [],
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetDropdownData();
    }

    onGetDropdownData = () => {
        const project = getCookies(TokenKey.Project);
        Promise.all([
            sharedService.getListGender(),
            project && this.props.onGetApartmentList(project).then(
                (res) => {
                    return res;
                },
                (err) => { return null }
            )
        ]).then((res) => {
            const [genderModels, houseModels] = res;
            this.setState({
                genderList:
                    (genderModels.content &&
                        genderModels.content.length > 0 &&
                        genderModels.content.map((item) => {
                            return { label: item.categoryName, value: item.categoryId };
                        })) ||
                    [],
                houseList:
                    (houseModels.content &&
                        houseModels.content.length > 0 &&
                        houseModels.content.map((item) => {
                            return { label: item.apartmentName, value: item.apartmentId };
                        })) ||
                    [],
            });
        }).catch((err) => { });
    };

    render() {
        const { isLoggedIn, addOrUpdateStaying } = this.props;
        const { houseList, genderList } = this.state;
        return (
            <StayingForm
                houseList={houseList}
                genderList={genderList}
                addOrUpdateStaying={addOrUpdateStaying}
                isLoggedIn={isLoggedIn}
            />
        )
    }
}

function StayingForm(props) {
    const { isLoggedIn, addOrUpdateStaying, houseList, genderList } = props;

    const today = new Date();
    const fromDate = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        0,
        0
    );
    const toDate = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate() + 1,
        today.getHours(),
        today.getMinutes(),
        0,
        0
    );
    const [house, setHouse] = useState("");
    const [gender, setGender] = useState("");

    const [startDate, setStartDate] = useState(fromDate);
    const [endDate, setEndDate] = useState(toDate);
    const [birthday, setBirthday] = useState(new Date());

    const { register, errors, handleSubmit, setError, clearError } = useForm({
        mode: "onChange",
    });

    const onChangeHouse = (data) => {
        if (data) {
            setHouse(data);
            clearError(["houseNumber"]);
        } else {
            setHouse("");
            setError("houseNumber", "required", "Trường này là bắt buộc");
        }
    };

    const loadOptions = (search, prevOptions) => {
        house
            ? clearError(["houseNumber"])
            : setError("houseNumber", "required", "Trường này là bắt buộc");
        let filteredOptions;
        if (!search) {
            filteredOptions = houseList;
        } else {
            const searchLower = search.toLowerCase();

            filteredOptions = houseList.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }

        const hasMore = filteredOptions.length > prevOptions.length + 10;
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );

        return {
            options: slicedOptions,
            hasMore,
        };
    };

    const onSetFromDate = (time) => {
        setStartDate(time);
        if (time.getTime() < endDate.getTime()) {
            clearError(["fromDate", "toDate"]);
        } else {
            setError("fromDate", "required", "Phải nhỏ hơn thời gian kết thúc");
        }
    };

    const onSetToDate = (time) => {
        setEndDate(time);
        if (time.getTime() > startDate.getTime()) {
            clearError(["fromDate", "toDate"]);
        } else {
            setError("toDate", "required", "Phải lớn hơn thời gian bắt đầu");
        }
    };

    const onSubmit = (data) => {
        if (errors && errors.length > 0) {
            endDate.getTime() <= startDate.getTime() &&
                setError("toDate", "required", "Phải lớn hơn thời gian bắt đầu");
        } else if (data) {
            addOrUpdateStaying({
                staying_name: data.fullName,
                gender_id: data.gioitinh,
                date_of_birth: FormatDate(birthday),
                identity_number: data.cmnd,
                passport_number: data.passport,
                current_address: data.thuongtru,
                apartment_id: data.houseNumber,
                from_date: FormatDate(startDate),
                to_date: FormatDate(endDate),
                staying_reason: data.reason,
            }).then(
                (res) => {
                    if (res && res.content) {
                        AddNotification(
                            viVN.Success.CreateStaying,
                            NotificationMessageType.Success
                        );
                        setHouse("");
                        document.getElementById("stayingForm").reset();
                    } else {
                        res &&
                            res.errorType &&
                            AddNotification(
                                viVN.Errors[res && res.errorType],
                                NotificationMessageType.Error
                            );
                    }
                },
                (err) => {
                    err &&
                        err.errorType &&
                        AddNotification(
                            viVN.Errors[err && err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        }
    };

    return (
        <div className="temporary-absence-declaration-mobile p-3">
            <form onSubmit={handleSubmit(onSubmit)} id="stayingForm">
                <div className="form-group">
                    <label htmlFor="name">Họ tên người xin tạm trú<span className="required"></span></label>
                    <input
                        type="text"
                        className={'form-control ' + (errors.fullName ? 'is-invalid' : '')}
                        name="fullName"
                        disabled={!isLoggedIn}
                        ref={register({
                            required: 'Trường này là bắt buộc'
                        })}
                    />
                    {errors.fullName && (
                        <div className="invalid-feedback">{errors.fullName.message}</div>
                    )}
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label>Ngày sinh<span className="required"></span></label>
                            <DatePicker
                                name="birthday"
                                dateFormat="dd/MM/yyyy"
                                selected={birthday}
                                onChange={date => date && setBirthday(date)}
                                className='form-control'
                                disabled={!isLoggedIn}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label>Giới tính<span className="required"></span></label>
                            <Select
                                onChange={(data) => data && setGender(data)}
                                value={gender}
                                options={genderList}
                                isDisabled={!isLoggedIn}
                                noOptionsMessage={() => "Không có dữ liệu"}
                                className="slAutocomplete"
                                placeholder="Chọn giới tính"
                            />
                            <input
                                name="gioitinh"
                                type="hidden"
                                value={gender.value || ""}
                                ref={register({
                                    required: "Trường này là bắt buộc",
                                })}
                            />
                            {errors.gioitinh && (
                                <div className="invalid-feedback">{errors.gioitinh.message}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label>CMND số<span className="required"></span></label>
                            <input
                                type="text"
                                className={'form-control ' + (errors.cmnd ? 'is-invalid' : '')}
                                name="cmnd"
                                disabled={!isLoggedIn}
                                ref={register({
                                    required: 'Trường này là bắt buộc',
                                    minLength: {
                                        value: 9,
                                        message: "Tối thiểu 9 ký tự"
                                    }
                                })}
                            />
                            {errors.cmnd && (
                                <div className="invalid-feedback">{errors.cmnd.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label>Hộ chiếu số</label>
                            <input
                                type="text"
                                placeholder="Số hộ chiếu"
                                className="form-control"
                                name="passport"
                                disabled={!isLoggedIn}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Nơi thường trú<span className="required"></span></label>
                    <input
                        type="text"
                        placeholder="Địa chỉ thường trú"
                        className={'form-control ' + (errors.thuongtru ? 'is-invalid' : '')}
                        name="thuongtru"
                        disabled={!isLoggedIn}
                        ref={register({
                            required: 'Trường này là bắt buộc'
                        })}
                    />
                    {errors.thuongtru && (
                        <div className="invalid-feedback">{errors.thuongtru.message}</div>
                    )}
                </div>

                <div className="form-group">
                    <label>Xin tạm trú tại<span className="required"></span></label>
                    <AsyncPaginate
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Số căn hộ (*)"
                        noOptionsMessage={() => "Không có dữ liệu"}
                        value={house}
                        isDisabled={!isLoggedIn}
                        loadOptions={loadOptions}
                        onChange={(data) => onChangeHouse(data)}
                    />
                    <input
                        name="houseNumber"
                        type="hidden"
                        value={house.value || ""}
                        ref={register({
                            required: "Trường này là bắt buộc",
                        })}
                    />
                    {errors.houseNumber && (
                        <div className="invalid-feedback">{errors.houseNumber.message}</div>
                    )}
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label>Từ<span className="required"></span></label>
                            <DatePicker
                                name="fromDate"
                                selected={startDate}
                                disabled={!isLoggedIn}
                                onChange={(date) => date && onSetFromDate(date)}
                                className={"form-control " + (errors.fromDate ? "is-invalid" : "")}
                                dateFormat="dd/MM/yyyy"
                            />
                            {errors.fromDate && (
                                <div className="invalid-feedback">{errors.fromDate.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label>Đến<span className="required"></span></label>
                            <DatePicker
                                name="toDate"
                                selected={endDate}
                                disabled={!isLoggedIn}
                                popperPlacement="bottom-end"
                                onChange={(date) => date && onSetToDate(date)}
                                className={"form-control " + (errors.toDate ? "is-invalid" : "")}
                                dateFormat="dd/MM/yyyy"
                            />
                            {errors.toDate && (
                                <div className="invalid-feedback">{errors.toDate.message}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Lý do xin tạm trú<span className="required"></span></label>
                    <textarea
                        name="reason"
                        className={'form-control ' + (errors.reason ? 'is-invalid' : '')}
                        placeholder="Nội dung"
                        rows="5"
                        disabled={!isLoggedIn}
                        ref={register({
                            required: 'Trường này là bắt buộc'
                        })}
                    ></textarea>
                    {errors.reason && (
                        <div className="invalid-feedback">{errors.reason.message}</div>
                    )}
                </div>

                <div className="form-group">
                    <button type="submit" className="btn btn-success rounded-pill w-100" disabled={!isLoggedIn}>Khai báo</button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addOrUpdateStaying: stayingActions.AddOrUpdateStaying,
            onGetApartmentList: projectActions.GetApartmentList,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryAbsenceDeclarationMobileView);