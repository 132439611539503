/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NotificationMessageType,
  CheckFileSize,
  CheckFileTypeImage,
  FormatDate,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as hotActions from "redux/stores/hot/hot.store";
import * as shopActions from "redux/stores/shop/shop.store";
import * as viVN from "translation/vi-VN.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

class AddPromotionDesktopView extends Component {
  render() {
    const { shop, onGetHotList, addOrUpdateHot, shopType } = this.props;
    return (
      <AddPromotion
        shop={shop}
        shopType={shopType}
        onGetHotList={onGetHotList}
        addOrUpdateHot={addOrUpdateHot}
      />
    );
  }
}

function AddPromotion(props) {
  const { shop, onGetHotList, addOrUpdateHot, shopType } = props;

  const today = new Date();
  const fromDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );
  const toDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getDate() + 1,
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );

  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");
  const [startDate, handleChangeStartDate] = useState(fromDate);
  const [endDate, handleChangeEndDate] = useState(toDate);

  const [avatar, setAvatar] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (CheckFileSize(file.size)) {
        setFileError(CheckFileSize(file.size));
        return;
      } else if (CheckFileTypeImage(file.type)) {
        setFileError(CheckFileTypeImage(file.type));
        return;
      } else {
        setFileError("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        setAvatar(event.target.result);
        setAvatarFile(file);
      };
    }
    if (avatarFile && errors.avatar) {
      clearError(["avatar"]);
    }
  };

  const onSetFromDate = (time) => {
    handleChangeStartDate(time);
    if (time.getTime() < endDate.getTime()) {
      clearError(["fromDate", "toDate"]);
    } else {
      setError("fromDate", "required", "Phải nhỏ hơn thời gian kết thúc");
    }
  };

  const onSetToDate = (time) => {
    handleChangeEndDate(time);
    if (time.getTime() > startDate.getTime()) {
      clearError(["fromDate", "toDate"]);
    } else {
      setError("toDate", "required", "Phải lớn hơn thời gian bắt đầu");
    }
  };

  const onChangeContent = (value) => {
    setContent(value);
    value && value !== "<p><br></p>"
      ? setContentError("")
      : setContentError("Trường này là bắt buộc");
  };

  const handleLoad = (reload) => {
    console.log(reload); //Boolean
  };

  const onSubmit = (value) => {
    if (
      (errors && errors.length > 0) ||
      !avatar ||
      !avatarFile ||
      endDate.getTime() <= startDate.getTime()
    ) {
      endDate.getTime() <= startDate.getTime()
        ? setError("toDate", "required", "Phải lớn hơn thời gian bắt đầu")
        : !avatarFile && setFileError("Trường này là bắt buộc");
    } else if (!contentError && value) {
      const formData = new FormData();
      avatarFile && formData.append("image", avatarFile);
      addOrUpdateHot(
        {
          // eslint-disable-next-line react/prop-types
          shop_id: shop.shopId,
          category_id:
            shopActions.SHOP_TYPE_ID[
              (shopType && shopType.toUpperCase()) || ""
            ],
          title: value.promotionName,
          description: value.description,
          content: content,
          from_date: FormatDate(startDate),
          to_date: FormatDate(endDate),
        },
        formData
      ).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.Create,
              NotificationMessageType.Success
            );
            location.reload();
            // document.getElementById("addForm").reset();
            // setAvatar("");
            // setAvatarFile(null);
            // setFileError("");
            // setContent("");
            // onGetHotList(1, 10, {
            //   shop_id: shop.shopId,
            //   orderBy: "update_date",
            //   orderType: "DESC",
            // });
          } else {
            res &&
              res.errorType &&
              AddNotification(
                viVN.Errors[res && res.errorType],
                NotificationMessageType.Error
              );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    } else {
      AddNotification(viVN.Errors.UnknownError, NotificationMessageType.Error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="addForm">
      <div className="form-group">
        <label>
          Tiêu đề<span className="required"></span>
        </label>
        <input
          type="text"
          name="promotionName"
          className={
            "form-control " + (errors.promotionName ? "is-invalid" : "")
          }
          placeholder="Tên chương trình khuyến mãi"
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        />
        {errors.promotionName && (
          <div className="invalid-feedback">{errors.promotionName.message}</div>
        )}
      </div>

      <div className="form-group row">
        <label className="col-12">Thời gian khuyến mãi</label>
        <div className="col-3">
          <DatePicker
            name="fromDate"
            selected={startDate}
            onChange={(date) => date && onSetFromDate(date)}
            className={"form-control " + (errors.fromDate ? "is-invalid" : "")}
            dateFormat="dd/MM/yyyy"
          />
          {errors.fromDate && (
            <div className="invalid-feedback">{errors.fromDate.message}</div>
          )}
        </div>
        <div className="col-3">
          <DatePicker
            name="toDate"
            selected={endDate}
            onChange={(date) => date && onSetToDate(date)}
            className={"form-control " + (errors.toDate ? "is-invalid" : "")}
            dateFormat="dd/MM/yyyy"
          />
          {errors.toDate && (
            <div className="invalid-feedback">{errors.toDate.message}</div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label>
          Mô tả ngắn<span className="required"></span>
        </label>
        <textarea
          name="description"
          className={"form-control " + (errors.description ? "is-invalid" : "")}
          placeholder="Tóm lược chương trình khuyến mãi"
          rows="6"
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        ></textarea>
        {errors.description && (
          <div className="invalid-feedback">{errors.description.message}</div>
        )}
      </div>

      <div className="form-group">
        <label>
          Nội dung<span className="required"></span>
        </label>
        <SunEditor
          enableToolbar={true}
          showToolbar={true}
          setOptions={{
            height: "auto",
            minHeight: 500,
            buttonList: [
              [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "blockquote",
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "fontColor",
                "hiliteColor",
                "textStyle",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "lineHeight",
                "table",
                "link",
                "image",
                "video",
                "audio",
                "fullScreen",
                "showBlocks",
                "codeView",
              ],
            ],
          }}
          onChange={onChangeContent}
          onBlur={(event, editorContents) => onChangeContent(editorContents)}
        />
        {contentError && <div className="invalid-feedback">{contentError}</div>}
      </div>

      {avatar && (
        <div className="form-group album-file">
          <div className="item">
            <img src={avatar} alt="Image" className="img-video" />
          </div>
        </div>
      )}

      <div className="form-group">
        <div className="file-upload">
          <div className="title-upload">
            <img
              src={require("assets/images/collections.png")}
              alt="Image"
              className="img-fluid"
            />{" "}
            <span>Upload ảnh</span>
          </div>
          <input
            type="file"
            name="avatar"
            accept=".png, .jpg, .jpeg"
            className="media-file"
            onChange={(e) => onChangeAvatar(e)}
            onBlur={(e) => onChangeAvatar(e)}
          />
        </div>
        {fileError && <div className="invalid-feedback">{fileError}</div>}
        {errors.avatar && (
          <div className="invalid-feedback">{errors.avatar.message}</div>
        )}
      </div>

      <div className="form-group">
        <button
          type="submit"
          className="btn btn-success text-uppercase rounded-pill"
        >
          Đăng tin
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addOrUpdateHot: hotActions.AddOrUpdateHot }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPromotionDesktopView);
