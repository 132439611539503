import {
    INITCAFELIST,
    INITRESTAURANTLIST,
    INITSCHOOLLIST,
    INITSUPERMARKETLIST,
    INITSPORTLIST,
    INITDETAIL,
    INITTENNISLIST,
    INITDETAILWITHCALENDAR,
    INITGYMLIST,
    INITSOCCERLIST,
    INITPOOLLIST,
    INITSHOPDETAILWITHACTIVECALENDAR,
    INITSPALIST,
} from './utiliti-const';

export const initCafeList = data => ({
    type: INITCAFELIST, data: data
})

export const initRestaurantList = data => ({
    type: INITRESTAURANTLIST, data: data
})

export const initSchoolList = data => ({
    type: INITSCHOOLLIST, data: data
})

export const initSuperMarketList = data => ({
    type: INITSUPERMARKETLIST, data: data
})

export const initSportList = data => ({
    type: INITSPORTLIST, data: data
})

export const initTennisList = data => ({
    type: INITTENNISLIST, data: data
})

export const initSoccerList = data => ({
    type: INITSOCCERLIST, data: data
})

export const initGymList = data => ({
    type: INITGYMLIST, data: data
})

export const initPoolList = data => ({
    type: INITPOOLLIST, data: data
})

export const initSpaList = data => ({
    type: INITSPALIST, data: data
})

export const initDetail = data => ({
    type: INITDETAIL, data: data
})

export const initShopWithCalendar = data => ({
    type: INITDETAILWITHCALENDAR, data: data
})

export const initShopWithActiveCalendar = data => ({
    type: INITSHOPDETAILWITHACTIVECALENDAR, data: data
})