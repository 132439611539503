/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
//@ts-check
import { Map } from 'immutable';
import { createFormData, AppKey, getDateWithFormat } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import AddNotification from 'components/react-notifications/react-notifications'
import * as UtilitiConst from './utiliti-const';
import { AddOneStackToState, PopOneStackToState } from '../loading-stack/loading-stack.store';
import {
    INITCAFELIST,
    INITRESTAURANTLIST,
    INITSCHOOLLIST,
    INITSUPERMARKETLIST,
    INITSPORTLIST,
    INITDETAIL,
    INITTENNISLIST,
    INITDETAILWITHCALENDAR,
    INITSOCCERLIST,
    INITGYMLIST,
    INITPOOLLIST,
    INITSHOPDETAILWITHACTIVECALENDAR,
    INITSPALIST,
} from './utiliti-const';
import {
    initCafeList,
    initRestaurantList,
    initSchoolList,
    initSuperMarketList,
    initSportList,
    initDetail,
    initTennisList,
    initShopWithCalendar,
    initSoccerList,
    initGymList,
    initPoolList,
    initShopWithActiveCalendar,
    initSpaList,
} from './create-action'
import * as viVN from '../../../translation/vi-VN.json';
import {
    ExchangeShopDetail,
    ExchangeShopDetailWithCalendar,
    ExchangeSlotListData,
    ExchangeListImage,
    ExchangeimagesSlider,
    ExchangeListSport,
    ExchangeListSportTennis,
    ExchangeListShop,
    ExchangeSportGymPoolDetail,
} from './exchange-data-from-api'
import HandleErrorMessaget from 'utils/error-message'

export const CAFEKEY = UtilitiConst.CAFEKEY;
export const RESTAURANKEY = UtilitiConst.RESTAURANKEY;
export const SCHOOLKEY = UtilitiConst.SCHOOLKEY;
export const SUPERMARKETKEY = UtilitiConst.SUPERMARKETKEY;
export const CAFELINK = UtilitiConst.CAFELINK;
export const RESTAURANTLINK = UtilitiConst.RESTAURANTLINK;
export const SUPERMARKETLINK = UtilitiConst.SUPERMARKETLINK;
export const SCHOOLLINK = UtilitiConst.SCHOOLLINK;

const service = new Service();
// action type

//--- shop cafe, restaurant, school, supermarket
export const GetShopList = (pageIndex = 1, size = 10, category_key) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let initList = initCafeList;
            switch (category_key) {
                case RESTAURANKEY: initList = initRestaurantList; break;
                case SCHOOLKEY: initList = initSchoolList; break;
                case SUPERMARKETKEY: initList = initSuperMarketList; break
            }
            let formData = new FormData();
            formData.append('pageIndex', String(pageIndex));
            formData.append('size', String(size));
            formData.append(`filter[category_type]`, category_key);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.ShopGetListUser, payLoad).then(res => {
                dispatch(initList(ExchangeListShop(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch {
        } finally {
            dispatch(PopOneStackToState)
        }
    }
}

/**
 * @param {FormData} registerData - customer_id, register_name, apartment_id, tennis_id, slot_id, register_date
*/
export const PostRegisterCart = (registerData) => {
    return async (dispatch) => {
        try {
            dispatch(AddOneStackToState)
            let payLoad = createFormData(null, AppKey.PostPayloadPrefix, registerData);
            return await service.post(ApiUrl.PostRegisterCart, payLoad).then(res => {
                return true;
            }).catch(err => {
                HandleErrorMessaget(err)
                return false;
            })
        } catch {
            return false
        } finally {
            dispatch(PopOneStackToState)
        }
    }
}

export const GetShopDetail = (shopId) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append('shopId', shopId);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.ShopGetDetail, payLoad).then(res => {
                dispatch(initDetail(ExchangeShopDetail(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}

//--- spa

export const PostRegisterUseSpa = (registerData) => {
    return async (dispatch) => {
        try {
            dispatch(AddOneStackToState)
            let payLoad = createFormData(null, AppKey.PostPayloadPrefix, registerData);
            const result = await service.post(ApiUrl.RegisterUseSpa, payLoad).then(res => {
                AddNotification(viVN.Utilities.RegisterSuccess, 'success');
                return true;
            }).catch(err => {
                HandleErrorMessaget(err)
                return false;
            })
            return result
        } catch {
            return false
        } finally {
            dispatch(PopOneStackToState)
        }
    }
}

export const GetListSpa = (pageIndex, pageSize) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("pageIndex", pageIndex)
            formData.append("size", pageSize);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetListSpa, payLoad).then(res => {
                dispatch(initSpaList(ExchangeListSportTennis(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}
/**
 * @param {number} id - tennis id
 * @param {Date} filterDate -
*/
export const GetSpaDetail = (id, filterDate) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("beautyId", String(id))
            formData.append("filter[date]", getDateWithFormat(filterDate));
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetSpaById, payLoad).then(res => {
                dispatch(initShopWithCalendar(ExchangeShopDetailWithCalendar(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}

//--- sport common
export const GetListSportNew = (pageIndex, pageSize) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("pageIndex", pageIndex)
            formData.append("size", pageSize);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetListSport, payLoad).then(res => {
                dispatch(initSportList(ExchangeListSport(res.content)))
            }).catch(err => {
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}

// export const GetListSportNew = () => {
//     return async dispatch => {
//         try {
//             dispatch(AddOneStackToState)
//             let formData = new FormData();
//             let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
//             await service.post(ApiUrl.GetListSportNew, payLoad).then(res => {
//                 dispatch(initSportList(ExchangeListSport(res.content)))
//             }).catch(err => {
//                 // console.log(err);
//             })
//         } catch { } finally {
//             dispatch(PopOneStackToState)
//         }
//     }
// }

//--- pool
export const GetListPool = (pageIndex, pageSize) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("pageIndex", pageIndex)
            formData.append("size", pageSize);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.PoolList, payLoad).then(res => {
                dispatch(initPoolList(ExchangeListSportTennis(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}
export const GetPoolDetail = (id) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("poolId", String(id))
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetPoolById, payLoad).then(res => {
                dispatch(initDetail(ExchangeSportGymPoolDetail(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}
//--- gym
/**
 * @param {number} id - football id
*/
export const GetGymDetail = (id) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("gymId", String(id))
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetGymById, payLoad).then(res => {
                dispatch(initShopWithActiveCalendar(ExchangeSportGymPoolDetail(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}

export const PostRegisterUseGym = (registerData) => {
    return async (dispatch) => {
        try {
            dispatch(AddOneStackToState)
            let payLoad = createFormData(null, AppKey.PostPayloadPrefix, registerData);
            const result = await service.post(ApiUrl.RegisterUseGym, payLoad).then(res => {
                AddNotification(viVN.Utilities.RegisterSuccess, 'success');
                return true;
            }).catch(err => {
                HandleErrorMessaget(err)
                return false;
            })
            return result
        } catch {
            return false
        } finally {
            dispatch(PopOneStackToState)
        }
    }
}

export const GetListGym = (pageIndex, pageSize) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("pageIndex", pageIndex)
            formData.append("size", pageSize);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GymList, payLoad).then(res => {
                dispatch(initGymList(ExchangeListSportTennis(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}
//--- tennis
export const PostRegisterUseTennis = (registerData) => {
    return async (dispatch) => {
        try {
            dispatch(AddOneStackToState)
            let payLoad = createFormData(null, AppKey.PostPayloadPrefix, registerData);
            const result = await service.post(ApiUrl.RegisterUseTennis, payLoad).then(res => {
                AddNotification(viVN.Utilities.RegisterSuccess, 'success');
                return true;
            }).catch(err => {
                HandleErrorMessaget(err)
                return false;
            })
            return result
        } catch {
            return false
        } finally {
            dispatch(PopOneStackToState)
        }
    }
}

export const GetListTennis = (pageIndex, pageSize) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("pageIndex", pageIndex)
            formData.append("size", pageSize);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetListTennis, payLoad).then(res => {
                dispatch(initTennisList(ExchangeListSportTennis(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}
/**
 * @param {number} id - tennis id
 * @param {Date} filterDate -
*/
export const GetTennisDetail = (id, filterDate) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("tennisId", String(id))
            formData.append("filter[date]", getDateWithFormat(filterDate));
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetTennisById, payLoad).then(res => {
                dispatch(initShopWithCalendar(ExchangeShopDetailWithCalendar(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}

//-- soccer
export const PostRegisterUseSoccer = (registerData) => {
    return async (dispatch) => {
        try {
            dispatch(AddOneStackToState)
            let payLoad = createFormData(null, AppKey.PostPayloadPrefix, registerData);
            const result = await service.post(ApiUrl.RegisterUseSoccer, payLoad).then(res => {
                AddNotification(viVN.Utilities.RegisterSuccess, 'success');
                return true;
            }).catch(err => {
                HandleErrorMessaget(err)
                return false;
            })
            return result
        } catch {
            return false
        } finally {
            dispatch(PopOneStackToState)
        }
    }
}

/**
 * @param {number} id - football id
 * @param {Date} filterDate -
*/
export const GetSoccerDetail = (id, filterDate) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("footballId", String(id))
            formData.append("filter[date]", getDateWithFormat(filterDate));
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetSoccerById, payLoad).then(res => {
                dispatch(initShopWithCalendar(ExchangeShopDetailWithCalendar(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}
export const GetListSoccer = (pageIndex, pageSize) => {
    return async dispatch => {
        try {
            dispatch(AddOneStackToState)
            let formData = new FormData();
            formData.append("pageIndex", pageIndex)
            formData.append("size", pageSize);
            let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
            await service.post(ApiUrl.GetListSoccer, payLoad).then(res => {
                dispatch(initSoccerList(ExchangeListSportTennis(res.content)))
            }).catch(err => {
                // console.log(err);
            })
        } catch { } finally {
            dispatch(PopOneStackToState)
        }
    }
}

export const DefaultShopListProperty = { haveData: false, listShop: [], total: 1 }
const DefaultShopStandardDetail = {
    category: '',
    id: 0,
    name: '',
    faceBook: "",
    introduce: '',
    timeOpen: '',
    address: '',
    lat: '',
    lng: '',
    phone: '',
    imagesSlider: [],
    listImage: [],
}

const initialState = Map({
    shopDetail: { ...DefaultShopStandardDetail },
    shopDetailWidthActiveCalendar: { ...DefaultShopStandardDetail, from2to6: [], from7to1: [], paymentGuide: '' },
    shopDetailWithCalendar: { ...DefaultShopStandardDetail, slotList: [] },
    cafeList: { ...DefaultShopListProperty },
    restaurantList: { ...DefaultShopListProperty },
    schoolList: { ...DefaultShopListProperty },
    superMarketList: { ...DefaultShopListProperty },
    sportList: { ...DefaultShopListProperty },
    tennisList: { ...DefaultShopListProperty },
    soccerList: { ...DefaultShopListProperty },
    gymList: { ...DefaultShopListProperty },
    poolList: { ...DefaultShopListProperty },
    spaList: { ...DefaultShopListProperty },
}).toJS()

export default function UtilitisShopReducer(state = initialState, action) {
    switch (action.type) {
        case INITCAFELIST:
            return {
                ...state,
                cafeList: action.data
            }
        case INITRESTAURANTLIST:
            return {
                ...state,
                restaurantList: action.data
            }
        case INITSCHOOLLIST:
            return {
                ...state,
                schoolList: action.data
            }
        case INITSUPERMARKETLIST:
            return {
                ...state,
                superMarketList: action.data
            }
        case INITSPORTLIST:
            return {
                ...state,
                sportList: action.data
            }
        case INITTENNISLIST:
            return {
                ...state,
                tennisList: action.data
            }
        case INITDETAIL:
            return {
                ...state,
                shopDetail: action.data
            }
        case INITDETAILWITHCALENDAR:
            return {
                ...state,
                shopDetailWithCalendar: action.data
            }
        case INITSOCCERLIST: return {
            ...state,
            soccerList: action.data
        }
        case INITGYMLIST: return {
            ...state,
            gymList: action.data
        }
        case INITPOOLLIST: return {
            ...state,
            poolList: action.data
        }
        case INITSHOPDETAILWITHACTIVECALENDAR: return {
            ...state,
            shopDetailWidthActiveCalendar: action.data
        }
        case INITSPALIST: return {
            ...state,
            spaList: action.data
        }
        default:
            return state
    }
}
