/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import GoogleMapMobile from "../../gmap/googlemap-mobile.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as hitchhikeActions from "redux/stores/utilities-plus/hitchhike.store";
import { JwtHelper } from "utils/jwt-helper";
import { SetDefaultErrorImage } from "utils/configuration";
import { isMobile, isTablet } from "react-device-detect";
import { PromiseAllTools } from 'core/app.store';

import "../utilities-plus-mobile.scss";

class HitchhikeMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHitchhikeList(1, 9999, {
      orderBy: "hitchhike_id",
      orderType: "DESC",
    });
  }

  onGetHitchhikeList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getHitchhikeList(pageIndex, size, filterObjs).then(
      (res) => { },
      (err) => { }
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetHitchhikeList(e, 9999, {
          orderBy: "hitchhike_id",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  onClickWantToHitchHike() {
    this.props.PromiseAllTools([this.props.postWantToHitchHike()])
  }

  render() {
    const { currentPageIndex } = this.state;
    const { isLoggedIn, hitchhikeList, totalItems } = this.props;
    const mapStyle = {
      width: "100%",
      height: "200px",
    };
    const initialCenter = {
      lat: 21.03154987467494,
      lng: 105.75224811987198,
    };
    const userInfo = JwtHelper.currentUser();
    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/tien-ich-plus">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Đi nhờ xe</div>
              </div>
            </div>
          </div>
        </header>

        <GoogleMapMobile mapStyle={mapStyle} />

        <div className="hitchhike-mobile p-3">
          {hitchhikeList && hitchhikeList.length > 0 ? (
            <div>
              {hitchhikeList.map((item) => (
                <RenderItem
                  key={item.hitchhikeId.toString()}
                  data={item}
                  user={userInfo}
                  isLoggedIn={isLoggedIn}
                  onClickWantToHitchHike={(data) => this.props.PromiseAllTools([this.props.createHitchhikeRequest(data)])}
                />
              ))}
            </div>
          ) : (
              <div>Không có dữ liệu</div>
            )}

          <div className="hitchhike-create mt-4">
            <div className="row no-gutters align-item-center">
              <div className="col-1">
                <img src={require("assets/images/car.svg")} alt="Car" />
              </div>
              <div className="col-10 pl-2">
                <span>
                  <Link to="/m/di-nho-xe/tao-lo-trinh">Tạo lộ trình mới</Link>
                </span>
              </div>
              <div className="col-1 text-right">
                <Link to="/m/di-nho-xe/tao-lo-trinh">
                  <img src={require("assets/images/plus-white.svg")} alt="plus" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function RenderItem(props) {
  const [isClicked, setIsClicked] = useState(false);
  const item = props.data;
  const data = {
    hitchhike_id: item.hitchhikeId,
  };
  const isNotFullSlot = (item.quantityAllow != item.quantityApprove) ? true : false;
  function onClickSendRequest() {
    setIsClicked(true)
    props.onClickWantToHitchHike(data)
  }
  return (
    <div className="item">
      <div className="row no-gutters">
        <div className="col-2" align="center" valign="center">
          <img
            src={item.avatarUrl || require("assets/images/29-12.png")}
            alt={item.makerName}
            onError={SetDefaultErrorImage}
            className={"img-fluid" + (isTablet ? " img-avatar-tablets" : " img-avatar")}
          />
        </div>
        <div className="col-7 pl-2">
          <h5>{item.makerName}</h5>
          <span>
            <img
              src={require("assets/images/calendar.svg")}
              alt="calendar"
              className="img-calendar"
            />{" "}
            {item.departureDate}
          </span>
          <h4>Lộ trình: <span>{item.route}</span></h4>
          <span className="people">
            <strong>Người đi nhờ:</strong> {item.quantityApprove}/
            {item.quantityAllow}
          </span>
        </div>
        <div className="col-3 text-right">
          {props.user && props.user.customerId != item.makerId && (!item.isRequested) && isNotFullSlot && <button
            onClick={onClickSendRequest}
            disabled={(!props.isLoggedIn) || (isClicked)}
            className="btn btn-success"
          >
            {isClicked ? "Đã gửi" : "Đi nhờ"}
          </button>}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  hitchhikeList: state.hitchhike.hitchhikeList,
  totalItems: state.hitchhike.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHitchhikeList: hitchhikeActions.GetHitchhikeList,
      postWantToHitchHike: hitchhikeActions.PostWantToHitchHike,
      createHitchhikeRequest: hitchhikeActions.CreateHitchhikeRequest,
      PromiseAllTools
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HitchhikeMobileView);
