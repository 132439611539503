/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import './hourly-maid-mobile.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as hourlyMaidActions from 'redux/stores/hourly-maid/hourly-maid.store';
import AddHourlyMaidMobileView from './actions/add-hourly-maid-mobile.view';
import HourlyMaidItemMobileView from './childs/hourly-maid-item-mobile.view';
import PaginationControl from 'components/pagination/pagination.view';
import { AssistType } from 'utils/configuration';

class HourlyMaidMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageIndex: 1,
            isToggleAdding: false
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetHourlyMaidList();
    }

    onGetHourlyMaidList = (pageIndex = 1, size = 10, filterObjs = { category_type: AssistType.HourlyMaid }) => {
        this.setState({ isLoading: true });
        this.props.getHourlyMaidList(pageIndex, size, filterObjs).then(
            res => {
                this.setState({ isLoading: false });
            },
            err => {
                this.setState({ isLoading: false });
            }
        );
    };

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetHourlyMaidList(pageNumber);
        })
    }

    onToggleAddingState = () => {
        this.setState({
            isToggleAdding: !this.state.isToggleAdding
        })
    }

    render() {
        const { hourlyMaidList, totalItems } = this.props;
        const { currentPageIndex, isToggleAdding } = this.state;
        return (
            <div className="hourly-maid-mobile p-3">
                <AddHourlyMaidMobileView onToggleAddingState={this.onToggleAddingState.bind(this)} callBack={this.onGetHourlyMaidList.bind(this)} />
                <HourlyMaidItemMobileView match={this.props.match} hourlyMaidList={hourlyMaidList} isToggleAdding={isToggleAdding} callBack={this.onGetHourlyMaidList.bind(this)} />
                <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    hourlyMaidList: state.hourlyMaid.hourlyMaidList,
    totalItems: state.hourlyMaid.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getHourlyMaidList: hourlyMaidActions.GetHourlyMaidList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HourlyMaidMobileView)