/* eslint-disable react/prop-types */
import React, { Component, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as resetPasswordActions from "redux/stores/user/reset-password.store";
import { Link } from "react-router-dom";
import { history } from "redux/store";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType } from "utils/configuration";
import * as viVN from "translation/vi-VN.json";
import { JwtHelper } from "utils/jwt-helper";

import "./reset-password-mobile.scss";

class ResetPasswordMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      username: "",
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.type) {
      let index = this.props.match.params.type.indexOf("&&");
      let type = this.props.match.params.type;
      if (index != -1) {
        this.setState({
          username: JwtHelper.decodeStringBase64(type.slice(0, index)),
          code: JwtHelper.decodeStringBase64(
            type.slice(index + 2, type.length)
          ),
        });
      } else {
        history.push("/m/dang-nhap");
      }
    } else {
      history.push("/m/dang-nhap");
    }
  }

  render() {
    const { code, username } = this.state;
    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/dang-nhap">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Đặt lại mật khẩu</div>
              </div>
            </div>
          </div>
        </header>
        <ResetPasswordMobileForm
          onResetPassword={this.props.ResetPassword}
          code={code}
          username={username}
        />
      </div>
    );
  }
}

function ResetPasswordMobileForm(props) {
  const { onResetPassword, code, username } = props;

  const [success, setSuccess] = useState(false);

  const { register, errors, handleSubmit, watch } = useForm({
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    if (data) {
      onResetPassword({
        resetCode: code,
        mobileNumber: username,
        password: data.password,
      }).then(
        (res) => {
          if (res && res.content) {
            setSuccess(true);
          } else {
            AddNotification(
              viVN.Errors[(res && res.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    }
  };

  return (
    <div>
      {!success && (
        <form onSubmit={handleSubmit(onSubmit)} className="row form-forgot">
          <div className="col-12">
            <div className="form-group">
              <input
                name="password"
                type="password"
                placeholder="Nhập mật khẩu"
                className="form-control"
                ref={register({
                  required: "Trường này là bắt buộc",
                  minLength: {
                    value: 8,
                    message: "Mật khẩu phải có ít nhất 8 ký tự",
                  },
                })}
              />
              {errors.password && (
                <div className="invalid-feedback">
                  {errors.password.message}
                </div>
              )}
            </div>

            <div className="form-group">
              <input
                name="confirmPassword"
                type="password"
                placeholder="Xác nhận mật khẩu"
                className="form-control"
                ref={register({
                  validate: (value) =>
                    value === password.current || "Mật khẩu không trùng khớp",
                })}
              />
              {errors.confirmPassword && (
                <div className="invalid-feedback">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>

            <div className="form-group text-right">
              <button
                type="submit"
                className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
              >
                Tiếp tục
              </button>
            </div>
          </div>
        </form>
      )}
      {success && (
        <div className="change-password-success text-center pt-5">
          <img
            src={require("assets/images/success-filled.svg")}
            className="img-fluid mb-3"
            alt="Success"
          />
          <h5>Mật khẩu của bạn đã được đặt lại thành công!</h5>
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
              onClick={() => history.push("/m/dang-nhap")}
            >
              Đăng nhập ngay
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ResetPassword: resetPasswordActions.ResetPassword,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordMobileView);
