//@ts-check
import React from 'react';
import { Link } from 'react-router-dom';
import * as CommonClass from 'utils/css-common-class';
import { SetDefaultErrorImage } from '../../../../utils/configuration';
/**
 * @param {{data:{logoUrl:string,name:string,address:string,id:number},
 * detailUrl:string,}} [props]
*/

function ItemOfSliderView(props) {
    const data = props.data;
    return (
        <Link to={props.detailUrl + data.id} className="item slider-item d-block">
            <img src={data.logoUrl} alt="Gym" className="img-fluid w-100" onError={SetDefaultErrorImage}/>
            <div className="content">
                <h5 className="text-black text-size-sub-title text-truncate">
                    {data.name}
                </h5>
                <div className="row no-gutters align-items-center">
                    <div className="col-1">
                        <img src={require('assets/images/place-gray.svg')} alt="Place" className="img-fluid" />
                    </div>
                    <div className="col-11">
                        <p className={`text-truncate mb-0 ${CommonClass.TextSizeContent}`}>{data.address}</p>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ItemOfSliderView