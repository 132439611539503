//@ts-check
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * @param {{
 * title:string,
 * goBackUrl:string
 * }} [props]
*/
function HeaderPageMobileView(props) {
    return (
        <header className="header-mobile">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <Link to={props.goBackUrl}>
                            <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                        </Link>
                        <div className="header-title text-center">{props.title}</div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderPageMobileView