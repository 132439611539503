/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NotificationMessageType,
  CheckFileSize,
  CheckFileTypeImage,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import * as documentActions from "redux/stores/document/document.store";
import { Link } from "react-router-dom";
import { JwtHelper } from "utils/jwt-helper";
import { history } from "redux/store";

import "../../document-management/document-management-mobile.scss";

function UploadDocumentForm(props) {
  const { onUpdateDocument } = props;
  const user = JwtHelper.currentUser();

  const [filePreview, setFile] = useState();
  const [documentFile, setDocumentFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const { register, errors, handleSubmit, clearError } = useForm({
    mode: "onChange",
  });

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (CheckFileSize(file.size)) {
        setFileError(CheckFileSize(file.size));
        return;
      } else if (CheckFileTypeImage(file.type)) {
        setFileError(CheckFileTypeImage(file.type));
        return;
      } else {
        setFileError("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        setFile(event.target.result);
        setDocumentFile(file);
      };
    }
    if (documentFile && errors.avatar) {
      clearError(["avatar"]);
    }
  };

  useEffect(() => {
    fileError &&
      setTimeout(() => {
        setFileError("");
      }, 3000);
  });

  const onSubmit = (item) => {
    if (item) {
      const formData = new FormData();
      documentFile && formData.append("file_name", documentFile);
      onUpdateDocument(
        {
          customer_id: user.customerId,
          document_name: item.title,
          content: item.description,
        },
        formData
      ).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.Create,
              NotificationMessageType.Success
            );
            document.getElementById("addForm").reset();
            setFile(null);
            history.push("/m/quan-ly-giay-to");
          } else {
            res &&
              res.errorType &&
              AddNotification(
                viVN.Errors[res && res.errorType],
                NotificationMessageType.Error
              );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  };

  return (
    <div>
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m/quan-ly-giay-to">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">
                Tải lên giấy tờ của bạn
              </div>
            </div>
          </div>
        </div>
      </header>

      <form
        className="p-3 form-upload-document"
        onSubmit={handleSubmit(onSubmit)}
        id="addForm"
      >
        <div className="form-group">
          <label>
            Tiêu đề<span className="required"></span>
          </label>
          <input
            type="text"
            name="title"
            placeholder="Tiêu đề"
            className={"form-control " + (errors.title ? "is-invalid" : "")}
            ref={register({
              required: "Trường này là bắt buộc",
            })}
          />
          {errors.title && (
            <div className="invalid-feedback">{errors.title.message}</div>
          )}
        </div>

        <div className="form-group">
          <label>
            Ghi chú<span className="required"></span>
          </label>
          <textarea
            name="description"
            id="description"
            rows="5"
            placeholder="Nội dung"
            className={
              "form-control " + (errors.description ? "is-invalid" : "")
            }
            ref={register({
              required: "Trường này là bắt buộc",
            })}
          ></textarea>
          {errors.description && (
            <div className="invalid-feedback">{errors.description.message}</div>
          )}
        </div>

        {filePreview && (
          <div className="form-group">
            <img
              src={filePreview}
              alt="Image"
              className="img-fluid img-preview"
            />
          </div>
        )}

        <div className="form-group">
          <div className="file-upload">
            <div className="title-upload">
              <img
                src={require("assets/images/collections.png")}
                alt="Image"
                className="img-fluid mr-1"
              />{" "}
              <span>Upload ảnh</span>
            </div>
            <input
              type="file"
              name="mediaFile"
              accept=".png, .jpg, .jpeg"
              className="media-file"
              onChange={(e) => onChangeAvatar(e)}
              onBlur={(e) => onChangeAvatar(e)}
            />
          </div>
          {fileError && <div className="invalid-feedback">{fileError}</div>}
          {errors.avatar && (
            <div className="invalid-feedback">{errors.avatar.message}</div>
          )}
        </div>

        <div className="row">
          <div className="col-6">
            <button
              type="reset"
              onClick={() => history.push("/m/quan-ly-giay-to")}
              className="btn btn-secondary w-100 text-uppercase rounded-pill"
            >
              Hủy
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              className="btn btn-success w-100 text-uppercase rounded-pill"
            >
              Tải lên
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateDocument: documentActions.DocumentAddOrUpdate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentForm);
