/* eslint-disable react/prop-types */
import React from 'react';

export function RenderNotHaveDataImage(props) {
    return (
        <div className="text-center">
            <img src={require("assets/images/bill-emty.png")} /><br />
            {props.notCallApi ? "Đang tải dữ liệu..." : "Không có dữ liệu để hiển thị"}
        </div>
    )
}

export function RenderNotHaveDataText(props) {
    return (
        <div className="text-center">
            {props.notCallApi ? "Đang tải dữ liệu..." : "Không có dữ liệu để hiển thị"}
        </div>
    )
}

export function RenderServiceIsNotReady() {
    return (
        <div className="text-center">
            <img className="w-50" src={require("assets/images/service-not-ready.png")} />
            <br />
            <span>Dịch vụ này đang được cập nhật , xin lỗi vì sự bất tiện này.</span>
        </div>
    )
}

export function RenderFeatureIsNotReady() {
    return (
        <div className="text-center">
            <img className="w-50" src={require("assets/images/service-not-ready.png")} />
            <br />
            <span>Tính năng này đang được cập nhật , xin lỗi vì sự bất tiện này.</span>
        </div>
    )
}