/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as helpingHandActions from 'redux/stores/help/helping-hand.store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { JwtHelper } from "utils/jwt-helper";

class HelpingHandCommentDesktopView extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            currentUser: JwtHelper.currentUser(),
            peopleTargetId: null,
            peopleTargetName: '',
            selectedCommentId: null,
            isOpenDeletePopup: false,
            deletedCommentModel: null,
            comments: [],
            totalItems: 0,
            currentPageIndex: 1,
            currentSize: 5,
            loadMoreStep: 5,
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.onTargetCommentInput();
        this.onGetCommentList();
    }

    onGetCommentList = (pageIndex = this.state.currentPageIndex, size = this.state.currentSize, filterObjs = null) => {
        this.setState({ isLoading: true });
        this.props.getHelpComments(pageIndex, size, { ...filterObjs, helpId: this.props.helpId }).then(res => {
            if (this._isMounted) {
                this.setState({ isLoading: false, comments: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 });
            }
        }).catch(err => {
            this.setState({ isLoading: false });
        })
    };

    onTargetCommentInput = () => {
        this['commentInput' + this.props.helpId] && this['commentInput' + this.props.helpId].focus();
    }

    onGetCommentModel = (comment) => {
        return {
            id: comment.commentId,
            profileUrl: comment.profileUrl,
            content: comment.content,
            createdBy: comment.customerName,
            createdById: comment.customerId,
            createdDate: comment.createdDate,
            isAllowEdit: this.state.currentUser && comment.customerId == this.state.currentUser.customerId,
            type: 'comment'
        }
    }

    onPostComment = (e) => {
        e.preventDefault();
        if (!this['commentInput' + this.props.helpId] || !this['commentInput' + this.props.helpId].value || !this['commentInput' + this.props.helpId].value.trim()) {
            return;
        }
        this.props.createOrUpdateComment({
            help_id: this.props.helpId,
            content: this['commentInput' + this.props.helpId].value.trim(),
            customer_id: this.state.currentUser.customerId,
            comment_id: null,
            parent_id: null
        }).then(res => {
            this['commentInput' + this.props.helpId].value = '';
            this.onGetCommentList();
            this.props.callBack && this.props.callBack();
        }, err => {

        })
    }

    onToggleDeletePopup = (isOpenDeletePopup, deletedCommentId) => {
        this.setState({
            isOpenDeletePopup: isOpenDeletePopup,
            deletedCommentId: deletedCommentId
        })
    }

    onDeleteComment = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteComment(this.state.deletedCommentId).then(res => {
                AddNotification('Xoá bình luận thành công!', NotificationMessageType.Success);
                this.setState({
                    isOpenDeletePopup: false
                })
                this.onGetCommentList();
                this.props.callBack && this.props.callBack();
            }, err => {
                this.setState({
                    isOpenDeletePopup: false
                })
            })
        }
    }

    onEditComment = async (editingModel) => {
        if (editingModel.type == 'comment') {
            const payLoad = {
                comment_id: editingModel.id,
                help_id: this.props.helpId,
                customer_id: this.state.currentUser.customerId,
                content: editingModel.content
            }
            this.props.createOrUpdateComment(payLoad).then(res => {
                AddNotification('Cập nhật bình luận thành công!', NotificationMessageType.Success);
                this.onGetCommentList();
            }, err => {
            })
        }
    }

    onGetMoreComments = () => {
        this.setState({
            currentSize: this.state.currentSize + this.state.loadMoreStep
        }, () => {
            this.onGetCommentList();
        })
    }

    render() {
        const { helpId, loggedIn } = this.props;
        const { isOpenDeletePopup, comments, loadMoreStep, totalItems } = this.state;

        return (
            <div className="helping-hand-comments">
                {comments.map(cm =>
                    <div key={cm.commentId} className="sub-comment">
                        <RenderComment data={this.onGetCommentModel(cm)} onTargetCommentInput={this.onTargetCommentInput.bind(this)} onEditComment={this.onEditComment.bind(this)} onToggleDeletePopup={this.onToggleDeletePopup.bind(this)} />
                    </div>
                )}
                {totalItems > comments.length && <a onClick={this.onGetMoreComments.bind(this)}>Xem thêm {totalItems - comments.length >= loadMoreStep ? loadMoreStep : totalItems - comments.length} bình luận</a>}
                {loggedIn && <form onSubmit={(e) => this.onPostComment(e)} className="helping-hand-form">
                    <div className="form-group position-relative">
                        <input name="content" type="text" placeholder="Trả lời..." className="form-control rounded-pill" ref={(input) => { this['commentInput' + helpId] = input; }} />
                        <button className="submit-button" type="submit"></button>
                    </div>
                </form>}
                <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xóa bình luận?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Bình luận sẽ không thể khôi phục sau khi xóa.
                        <br />
                        Bạn có chắc muốn xóa bình luận này.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                        <Button variant="success" onClick={() => this.onDeleteComment(true)}>Xóa</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function RenderComment(props) {
    const { data } = props;
    let commentInput = null;
    const [isShowEditForm, setShowEditForm] = useState(false);

    const onShowDeletePopup = (deletingModel) => {
        props.onToggleDeletePopup(true, deletingModel);
    }

    const onEditComment = async (e) => {
        e.preventDefault();
        if (!commentInput.value.trim()) {
            return;
        }
        let editedData = { ...data };
        if (data.type == 'comment') {
            editedData.comment_id = data.id;
            editedData.content = commentInput.value && commentInput.value.trim();
        }
        props.onEditComment(editedData);
        onShowEditForm(false);
    }

    const onShowEditForm = (isShowEditForm) => {
        setShowEditForm(isShowEditForm);
    }

    return (
        <div className="form-hepling-hand-comment">
            {!isShowEditForm && <div className="row no-gutters">
                <div className="col-1">
                    <img src={data.profileUrl || require('assets/images/29-01.png')} alt={data.createdBy} className="img-avatar img-fluid rounded-circle" />
                </div>
                <div className="col-11">
                    <div className="content">
                        <h5 className="fullname">
                            <span>{data.createdBy}</span>
                            {data.isAllowEdit &&
                                <Dropdown>
                                    <Dropdown.Toggle>
                                        <img src={require('assets/images/more.svg')} className="img-fluid img-more" alt="more" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => onShowEditForm(true)}>
                                            <img src={require('assets/images/pen.svg')} className="img-fluid img-pen" alt="pen" />
                                            <span>Chỉnh sửa bình luận</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => onShowDeletePopup(data.id)}>
                                            <img src={require('assets/images/delete.svg')} className="img-fluid img-delete" alt="pen" />
                                            <span>Xóa</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>}
                        </h5>
                        <p className="comment ml-0 mt-1 mb-1">{data.content}</p>
                        <a className="answer" onClick={props.onTargetCommentInput.bind(this)}>Trả lời.</a> <span className="time">{moment(data.createdDate).fromNow()}</span>
                    </div>
                </div>
            </div>}
            {isShowEditForm && <form onSubmit={onEditComment} className="col-11 pl-2">
                <div className="form-group position-relative">
                    <input name="content" defaultValue={data.content} autoComplete="off" type="text" placeholder="Viết phản hồi..." className="form-control rounded-pill" ref={(input) => { commentInput = input; }} />
                    <button className="submit-button" type="submit"></button>

                    <a className="cancel-edit" onClick={() => onShowEditForm(false)}>Huỷ</a>
                </div>
            </form>}
        </div>
    )
}

RenderComment.prototype = {
    data: {
        id: PropTypes.number.isRequired,
        userId: PropTypes.number.isRequired,
        profileUrl: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        createdBy: PropTypes.string.isRequired,
        createdById: PropTypes.string.isRequired,
        createdDate: PropTypes.string.isRequired,
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    createOrUpdateComment: helpingHandActions.CreateOrUpdateComment,
    deleteComment: helpingHandActions.DeleteComment,
    getHelpComments: helpingHandActions.GetHelpComments,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HelpingHandCommentDesktopView)