import React from 'react';
import RenderNotificationItems from './notice-from-residents-items.view.jsx';

function NoticeFromResidentsDesktopView(props) {
    const listData = props.listData
    return (
        <div className="bg-white p-4">
            <h4 className="title-sm">Thông báo từ cư dân</h4>
            <hr></hr>
            {listData.map((data, index) => <RenderNotificationItems data={data} key={index} />)}
        </div>
    )
}


export default NoticeFromResidentsDesktopView;