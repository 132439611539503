import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../project-information/project-info.scss";

class ProjectInfoDesktopView extends Component {
    render() {
        return (
            <div className="project-info-desktop">
                <div className="row">
                    <div className="col-12 position-relative">
                        <h2 className="title-lg mt-4">Thông tin về dự án</h2>
                        <div className="project-info-border"></div>
                    </div>
                </div>
                <div className="mt-4">
                    <h4 className="title-sm">Công ty quản lý vận hành</h4>
                    <div className="row">
                        <div className="col-2">
                            Tên công ty:
                        </div>
                        <div className="col-10">
                            Công ty TNHH phát triển đô thị mới An Khánh (An Khánh JVC)
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Địa chỉ:
                        </div>
                        <div className="col-10">
                            Km10+600, Đại lộ Thăng Long, Xã An Khánh, Huyện Hoài Đức, Hà Nội.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Hotline:
                        </div>
                        <div className="col-10">
                            844. 3755 6541
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Email BQL:
                        </div>
                        <div className="col-10">
                            info@ankhanhjvc.com
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Website:
                        </div>
                        <div className="col-10">
                            <a href="https://splendora.vn/" target="_blank">www.splendora.vn</a>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="title-sm">Công ty quản lý vận hành</h4>
                    <div className="row">
                        <div className="col-2">
                            Tên công ty:
                        </div>
                        <div className="col-10">
                            Công ty TNHH Savills Việt Nam - Ban QLVH Khu đô thị Splendora
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Địa chỉ:
                        </div>
                        <div className="col-10">
                            Tầng 1, tòa 101 Chung cư KĐT Splendora Bắc An Khánh, An Khánh, Hoài Đức, Hà Nội
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Hotline:
                        </div>
                        <div className="col-10">
                            091.680.8336
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Email BQL:
                        </div>
                        <div className="col-10">
                            bqlsplendora@savills.com.vn
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            Website:
                        </div>
                        <div className="col-10">
                            <a href="https://www.savills.com.vn/" target="_blank">www.savills.com.vn</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfoDesktopView);
