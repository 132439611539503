import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from 'redux/store';
import { Link } from 'react-router-dom';
import ReactStarsRating from 'react-awesome-stars-rating';
import AddNotification from 'components/react-notifications/react-notifications';
import * as repairingHouseholdActions from "redux/stores/utilities-plus/repairing-household.store";
import * as viVN from 'translation/vi-VN.json';
import { TokenKey } from 'utils/configuration';
import { NotificationMessageType } from 'utils/configuration';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { JwtHelper } from 'utils/jwt-helper';

import '../../utilities-plus-mobile.scss';

class RepairingHouseholdDetailMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: null,
            repairingHouseholdId: this.props.match.params.id,
            evaluate: null,
            showEdit: false,
            showDelete: false,
            score: 0,
            vote: null
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetRepairingHouseholdDetail();
    }

    onGetRepairingHouseholdDetail = () => {
        this.props.getRepairingHouseholdDetail(this.state.repairingHouseholdId).then(res => {
            if (res.content) {
                let score = 0;
                if (res.content.listVote && res.content.listVote.length > 0) {
                    res.content.listVote.map(item => { score += parseFloat(item.starNumber) });
                    score = score / res.content.listVote.length;
                }
                this.setState({
                    item: res.content,
                    evaluate: res.content.listVote,
                    score: score
                })
            } else {
                AddNotification(viVN.Errors[res.errorType], NotificationMessageType.Error);
                history.push('/m/sua-chua-lap-dat');
            }
        }, err => {
            console.log(err)
        })
    }

    onDeleteVote = (id) => {
        this.props.deleteVote(id).then(
            res => {
                if (res && res.content) {
                    AddNotification(viVN.Success.Delete, NotificationMessageType.Success);
                    this.onGetRepairingHouseholdDetail();
                    this.handleClose();
                }
            },
            err => {
                // console.log(err);
            }
        );
    }

    onUpdateEvaluate = data => {
        if (data) {
            this.props.addOrUpdateEvaluate(data).then(
                res => {
                    if (res && res.content) {
                        AddNotification(viVN.Success.CreateEvaluate, NotificationMessageType.Success);
                        this.onGetRepairingHouseholdDetail();
                        this.handleClose();
                    }
                },
                err => {
                    // console.log(err);
                }
            );
        }
    };

    setShowEdit = (data) => {
        this.setState({
            showEdit: true,
            showDelete: false,
            vote: data
        })
    };

    setShowDelete = (data) => {
        this.setState({
            showEdit: false,
            showDelete: true,
            vote: data
        })
    };

    handleClose = () => {
        this.setState({
            showEdit: false,
            showDelete: false,
        })
    };

    render() {
        const { item, evaluate, score, showEdit, showDelete, vote } = this.state;
        const { isLoggedIn } = this.props;
        const userInfo = JwtHelper.currentUser();
        return (
            <div className="repairing-household-detail-mobile">
                {item &&
                    <div>
                        <div className="cover">
                            <img src={item.coverImage || require('assets/images/splendora-cover.png')} alt={item.providerName} className="img-fluid img-splendora-cover" />
                            <Link to="/m/sua-chua-lap-dat">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                        </div>

                        <div className="info">
                            <img src={item.avatar || require('assets/images/repairman-avatar.svg')} alt={item.providerName} className="img-fluid img-repairman-avatar" />
                            <h4>{item.providerName}</h4>
                            <h5>{item.serviceName}</h5>
                            <span className="phone">SĐT: {item.mobile_number}</span><br />
                            <span className="address">Địa chỉ: {item.address}</span><br />
                            <span className="rating-number">{item.vote.star}/5</span><br />
                            <ReactStarsRating value={item.vote.star} isEdit={false} size={20} /><br />
                            <a href={"tel:" + item.mobile_number} className="btn btn-success">
                                <img src={require('assets/images/phone-call.svg')} alt="phone-call" className="img-phone-call" /> Liên hệ
                        </a>
                        </div>

                        <div className="mobile-divide"></div>

                        {evaluate &&
                            <RenderRatingInfo
                                evaluate={evaluate}
                                user={userInfo}
                                isLoggedIn={isLoggedIn}
                                serviceId={item.serviceId}
                                onAddOrUpdateEvaluate={this.props.addOrUpdateEvaluate}
                                onDeleteVote={this.props.deleteVote}
                                onGetRepairingHouseholdDetail={this.onGetRepairingHouseholdDetail.bind(this)} />
                        }

                        <div className="mobile-divide"></div>

                        <RenderRatingForm
                            user={userInfo}
                            isLoggedIn={isLoggedIn}
                            serviceId={item.serviceId}
                            onAddOrUpdateEvaluate={this.props.addOrUpdateEvaluate}
                            onGetRepairingHouseholdDetail={this.onGetRepairingHouseholdDetail.bind(this)} />
                    </div>
                }
            </div>
        );
    }
}

function RenderRatingInfo(props) {

    const { evaluate, user, isLoggedIn, serviceId, onAddOrUpdateEvaluate, onDeleteVote, onGetRepairingHouseholdDetail } = props;

    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [data, setData] = useState(false);

    const [rating, setRating] = useState(5);

    const { register, errors, handleSubmit } = useForm({
        mode: "onChange"
    });

    const onSubmit = e => {
        if (e) {
            let dataItem = {
                vote_id: data.voteId,
                customer_id: data.customerId,
                service_id: data.serviceId,
                star_number: rating,
                content: e.evaluate
            }
            onAddOrUpdateEvaluate(dataItem).then(
                res => {
                    if (res && res.content) {
                        AddNotification(viVN.Success.CreateEvaluate, NotificationMessageType.Success);
                        onGetRepairingHouseholdDetail();
                        handleClose();
                    }
                },
                err => {
                    // console.log(err);
                }
            );
        }
    };

    const deleteVote = () => {
        onDeleteVote(data.voteId).then(
            res => {
                if (res && res.content) {
                    AddNotification(viVN.Success.Delete, NotificationMessageType.Success);
                    onGetRepairingHouseholdDetail();
                    handleClose();
                }
            },
            err => {
                // console.log(err);
            }
        );
    }

    const onShowEdit = (data) => {
        setData(data);
        setRating(parseFloat(data.starNumber.toString()));
        setShowEdit(true);
    }

    const onShowDelete = (data) => {
        setData(data);
        setShowDelete(true);
    }

    const handleClose = () => {
        setShowEdit(false);
        setShowDelete(false);
    };

    return (
        <div className="rating-info p-3">
            <h4>Đánh giá ({evaluate.length})</h4>
            <hr />
            {evaluate.map(item =>
                <div className="rating-item" key={item.voteId.toString()}>
                    <div className="row no-gutters">
                        <div className="col-2">
                            <img src={item.avatarUrl || require('assets/images/29-12.png')} alt={item.customerName} className="img-fluid img-avatar" />
                        </div>
                        <div className="col-9 pl-3">
                            <h5>{item.customerName}</h5>
                            <span>{item.voteDate}</span><br />
                            <ReactStarsRating value={parseFloat(item.starNumber.toString())} isEdit={false} size={20} />
                            <p
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    ></p>
                        </div>

                        {user && user.customerId == item.customerId &&
                            <div className="col-1 text-right">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <img src={require('assets/images/more.svg')} className="img-fluid img-more" alt="more" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => onShowEdit(item)}>
                                            <img src={require('assets/images/pen.svg')} className="img-fluid img-pen" alt="pen" /> Chỉnh sửa đánh giá
                                </Dropdown.Item>
                                        <Dropdown.Item onClick={() => onShowDelete(item)}>
                                            <img src={require('assets/images/delete.svg')} className="img-fluid img-delete" alt="pen" /> Xóa
                                </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }
                    </div>
                </div>
            )}

            <Modal show={showEdit} className="modal-edit" centered size="lg" onHide={() => handleClose()}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chỉnh sửa bài viết</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Xếp hạng</label><br />
                            <ReactStarsRating value={rating} onChange={value => setRating(value)} isEdit={true} size={20} />
                        </div>
                        <div className="form-group">
                            <label>Đánh giá</label>
                            <textarea name="evaluate" id="evaluate" defaultValue={data.content || ''} className={"form-control " + (errors.evaluate ? "is-invalid" : "")} placeholder="Nhập đánh giá" rows="5"
                                ref={register({
                                    required: "Trường này là bắt buộc"
                                })}>
                            </textarea>
                            {errors.evaluate && <div className="invalid-feedback">{errors.evaluate.message}</div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="reset" variant="secondary" onClick={() => handleClose()}>Hủy</Button>
                        <Button type="submit" variant="success">Lưu thay đổi</Button>
                    </Modal.Footer>
                </form>
            </Modal>


            <Modal show={showDelete} className="modal-delete-mobile" centered onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Xóa đánh giá?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Đánh giá sẽ không thể khôi phục sau khi xóa.
                    <br />
                    Bạn có chắc muốn xóa đánh giá này.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>Hủy</Button>
                    <Button variant="success" onClick={() => deleteVote()}>Xóa</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function RenderRatingForm(props) {

    const { user, isLoggedIn, serviceId, onAddOrUpdateEvaluate, onGetRepairingHouseholdDetail } = props;

    const [rating, setRating] = useState(5);

    const { register, errors, handleSubmit } = useForm({
        mode: "onChange"
    });

    const onSubmit = data => {
        if (data) {
            let dataItem = {
                customer_id: user.customerId,
                service_id: serviceId,
                star_number: rating,
                content: data.evaluate
            }
            onAddOrUpdateEvaluate(dataItem).then(
                res => {
                    if (res && res.content) {
                        AddNotification(viVN.Success.CreateEvaluate, NotificationMessageType.Success);
                        onGetRepairingHouseholdDetail();
                        setRating(5);
                        document.getElementById("addEvaluate").reset();
                    }
                },
                err => {
                    // console.log(err);
                }
            );
        }
    };

    return (
        <div className="rating-form p-3">
            <form onSubmit={handleSubmit(onSubmit)} id="addEvaluate">
                <div className="form-group">
                    <label>Xếp hạng</label><br />
                    <ReactStarsRating value={rating} onChange={value => setRating(value)} isEdit={isLoggedIn} size={20} />
                </div>
                <div className="form-group">
                    <label>Đánh giá</label>
                    <textarea name="evaluate" id="evaluate" className={"form-control " + (errors.evaluate ? "is-invalid" : "")} placeholder="Nội dung" rows="5"
                        disabled={!isLoggedIn}
                        ref={register({
                            required: "Trường này là bắt buộc"
                        })}>
                    </textarea>
                    {errors.evaluate && <div className="invalid-feedback">{errors.evaluate.message}</div>}
                </div>
                <div className="form-group text-right">
                    <button type="submit" className="btn btn-success" disabled={!isLoggedIn}>Gửi đi</button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getRepairingHouseholdDetail: repairingHouseholdActions.GetRepairingHouseholdDetail,
            addOrUpdateEvaluate: repairingHouseholdActions.AddOrUpdateEvaluate,
            deleteVote: repairingHouseholdActions.DeleteVote
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RepairingHouseholdDetailMobileView);