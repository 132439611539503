/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import GoogleMap from "../../gmap/googlemap.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as hitchhikeActions from "redux/stores/utilities-plus/hitchhike.store";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType, SetDefaultErrorImage } from "utils/configuration";
import * as viVN from "translation/vi-VN.json";
import "react-datepicker/dist/react-datepicker.css";
import { JwtHelper } from "utils/jwt-helper";
import { PromiseAllTools } from 'core/app.store';

import "../utilities-plus-desktop.scss";

class HitchhikeDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHitchhikeList(1, 9999, {
      orderBy: "hitchhike_id",
      orderType: "DESC",
    });
  }

  onGetHitchhikeList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getHitchhikeList(pageIndex, size, filterObjs).then(
      (res) => { },
      (err) => { }
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetHitchhikeList(e, 9999, {
          orderBy: "hitchhike_id",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  render() {
    const { currentPageIndex } = this.state;
    const { isLoggedIn, hitchhikeList, totalItems } = this.props;
    const userInfo = JwtHelper.currentUser();
    const mapStyle = {
      width: "100%",
      height: "400px",
    };
    const initialCenter = {
      lat: 21.03154987467494,
      lng: 105.75224811987198,
    };
    return (
      <div className="utilities-plus-desktop">
        <h2 className="title-lg">Đi nhờ xe</h2>
        <GoogleMap mapStyle={mapStyle} initialCenter={initialCenter} />

        <div className="box-body p-3 mb-3">
          <h3 className="title-md">Tạo lộ trình mới</h3>
          <AddHitchhikeForm
            addOrUpdateHitchhike={this.props.addOrUpdateHitchhike}
            onGetHitchhikeList={this.onGetHitchhikeList.bind(this)}
            isLogin={isLoggedIn}
            user={userInfo}
          />
        </div>

        {hitchhikeList && hitchhikeList.length > 0 ? (
          <div className="row">
            {hitchhikeList.map((item) => (
              <RenderField
                user={userInfo}
                key={item.hitchhikeId.toString()}
                data={item}
                isLoggedIn={isLoggedIn}
                onClickWantToHitchHike={(data) => this.props.PromiseAllTools([this.props.createHitchhikeRequest(data)])}
              />
            ))}
          </div>
        ) : (
            <div className="row">
              <div>Không có dữ liệu</div>
            </div>
          )}
      </div>
    );
  }
}

function RenderField(props) {
  const [isClicked, setIsClicked] = useState(false);
  const item = props.data;
  const data = {
    hitchhike_id: item.hitchhikeId,
  };
  const isNotFullSlot = (item.quantityAllow != item.quantityApprove) ? true : false;
  function onClickSendRequest() {
    setIsClicked(true)
    props.onClickWantToHitchHike(data)
  }
  return (
    <div className="col-12 mb-3">
      <div className="box-body p-3">
        <div className="row no-gutters">
          <div className="col-1">
            <img
              src={item.avatarUrl || require("assets/images/29-12.png")}
              alt={item.makerName}
              onError={SetDefaultErrorImage}
              width="50"
              height="50"
            />
          </div>
          <div className="col-9 pl-2">
            <span className="fullname-md">{item.makerName}</span>
            <br />
            <span className="datetime">
              <img
                src={require("assets/images/calendar.svg")}
                alt="Calendar"
                className="img-fluid"
              />{" "}
              {item.departureDate}
            </span>
            <br />
            <span className="route">
              <strong>Lộ trình:</strong> {item.route}
            </span>
            <br />
            <span className="people-number">
              <strong>Người đi nhờ:</strong> {item.quantityApprove}/
              {item.quantityAllow}
            </span>
          </div>
          <div className="col-2 text-right">
            {props.user && props.user.customerId != item.makerId && (!item.isRequested) && isNotFullSlot && <button
              onClick={onClickSendRequest}
              disabled={(!props.isLoggedIn) || (isClicked)}
              className="btn btn-success"
            >
              {isClicked ? "Đã gửi" : "Đi nhờ"}
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
}

function AddHitchhikeForm(props) {
  const [startDate, setStartDate] = useState(new Date());

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (data) {
      let hitchhike = {
        maker_id: props.user.customerId,
        route: data.title,
        departure_date: startDate,
        quantity_allow: data.peopleNumber,
      };
      props.addOrUpdateHitchhike(hitchhike).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.CreateHitchhike,
              NotificationMessageType.Success
            );
            props.onGetHitchhikeList(1, 10, {
              orderBy: "hitchhike_id",
              orderType: "DESC",
            });
            document.getElementById("hitchhikeForm").reset();
          }
        },
        (err) => { }
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="row no-gutters form-hitchhike"
      id="hitchhikeForm"
    >
      <div className="col-12">
        <div className="form-group">
          <input
            name="title"
            type="text"
            placeholder="Nhập lộ trình"
            className={"form-control " + (errors.title ? "is-invalid" : "")}
            disabled={!props.isLogin}
            ref={register({ required: "Trường này là bắt buộc" })}
          />
          {errors.title && (
            <div className="invalid-feedback">{errors.title.message}</div>
          )}
          <img
            src={require("assets/images/place-gray.svg")}
            alt="Calendar"
            className="img-fluid img-calendar"
          />
        </div>
      </div>

      <div className="col-5 position-relative pr-2">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={startDate}
          onChange={(data) => data && setStartDate(data)}
          className="form-control"
          minDate={new Date()}
          disabled={!props.isLogin}
        />
        <img
          src={require("assets/images/calendar.svg")}
          alt="Calendar"
          className="img-fluid img-calendar"
        />
      </div>

      <div className="col-5 pr-2">
        <input
          type="number"
          name="peopleNumber"
          id="peopleNumber"
          min="0"
          disabled={!props.isLogin}
          className={
            "form-control " + (errors.peopleNumber ? "is-invalid" : "")
          }
          placeholder="Nhập số người cho đi nhờ"
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        />
        {errors.peopleNumber && (
          <div className="invalid-feedback">{errors.peopleNumber.message}</div>
        )}
      </div>

      <div className="col-2 text-right">
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-warning"
            disabled={!props.isLogin}
          >
            Tạo lộ trình{" "}
            <img src={require("assets/images/plus-white.svg")} alt="Plus" />{" "}
          </button>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  hitchhikeList: state.hitchhike.hitchhikeList,
  totalItems: state.hitchhike.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHitchhikeList: hitchhikeActions.GetHitchhikeList,
      addOrUpdateHitchhike: hitchhikeActions.AddOrUpdateHitchhike,
      createHitchhikeRequest: hitchhikeActions.CreateHitchhikeRequest,
      PromiseAllTools
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HitchhikeDesktopView);
