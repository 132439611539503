import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../management-desktop.scss";
import * as memberActions from 'redux/stores/management/member.store';
import PaginationControl from 'components/pagination/pagination.view';
import { Link } from 'react-router-dom';
import { SetDefaultErrorImage } from "utils/configuration";

class MemberListDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetMemberList(1, 12);
  }

  onGetMemberList = (pageIndex = 1, size = 12, filterObjs = null) => {
    this.props.getMemberList(pageIndex, size, filterObjs).then(res => {

    }, err => { 
    })
  }

  onFilterChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      currentPageIndex: 1
    }, () => {
      this.onGetMemberList(1, 12, null);
    });
  }

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetMemberList(pageNumber);
    })
  }


  render() {
    const { memberList, totalItems } = this.props;
    const { currentPageIndex } = this.state;

    return (
      <div className="management-desktop">
        <h2 className="title-lg mb-0">Thành viên BQL</h2>
        <hr className="mt-2" />

        <div className="row">
          {memberList.map(item => <div key={item.agentId} className="col-3">
            <div className="member-info mb-4">
              <div className="row">
                <div className="col-12">
                  <Link to={`/thanh-vien-bql/chi-tiet/${item.agentId}`}>
                    <img
                      src={item.avatarUrl || require('assets/images/29-01.png')}
                      onError={SetDefaultErrorImage}
                      alt="Avatar"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="col-12">
                  <h5 className="mb-0">
                    <Link to={`/thanh-vien-bql/chi-tiet/${item.agentId}`}>{item.agentName}</Link>
                  </h5>
                </div>
                {item.roleName && <div className="col-12">
                  <span>[{item.roleName}]</span>
                </div>}
                <div className="col-12">
                  <a href={`tel:${item.mobileNumber}`}>SĐT: {item.mobileNumber}</a>
                </div>
              </div>
            </div>
          </div>)}
          {!memberList.length && <div className="col-12">Không có bản ghi</div>}
        </div>
        <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  memberList: state.member.memberList,
  totalItems: state.member.totalItems
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMemberList: memberActions.GetMemberList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MemberListDesktopView);