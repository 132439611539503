import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as busActions from "redux/stores/utilities-plus/bus-schedule.store";
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import Select from 'react-select';

import '../utilities-plus-mobile.scss';

class BusScheduleMobileView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busList: [],
            busDefault: { label: '', value: 0 },
            areaList: [],
            areaDefault: { label: '', value: 0 },
            glandList: [],
            glandDefault: { label: '', value: 0 },
            data: null,
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetBusList(1, 999999, { orderBy: "transportTypeId", orderType: "DESC" });
    }

    onGetBusList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getBusList(pageIndex, size, filterObjs).then(
            res => {
                if (res && res.content && res.content.length > 0) {
                    this.setState({ busList: res.content, busDefault: { label: res.content[0].transportTypeName, value: res.content[0].transportTypeId } });
                    this.onGetAreaList(1, 999999, { transport_type_id: res.content[0].transportTypeId, orderBy: "areaId", orderType: "DESC" });
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onGetAreaList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getAreaList(pageIndex, size, filterObjs).then(
            res => {
                if (res && res.content && res.content.length > 0) {
                    this.setState({ areaList: res.content, areaDefault: { label: res.content[0].areaName, value: res.content[0].areaId } });
                    this.onGetRouteList(1, 999999, { transport_type_id: this.state.busDefault.value, area_id: res.content[0].areaId, orderBy: "route", orderType: "DESC" });
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onGetRouteList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getRouteList(pageIndex, size, filterObjs).then(
            res => {
                if (res && res.content && res.content.length > 0) {
                    this.setState({ glandList: res.content, glandDefault: { label: res.content[0].route, value: res.content[0].transportId } });
                    this.onGetData(res.content[0].transportId);
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onGetData = (transportId) => {
        this.props.getBusDetail(transportId).then(
            res => {
                if (res && res.content) {
                    this.setState({ data: res.content });
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onChangeBus = (data) => {
        this.onGetAreaList(1, 999999, { transport_type_id: data.transportTypeId, orderBy: "areaId", orderType: "DESC" });
        this.setState({ busDefault: data });
    }

    onChangeArea = (data) => {
        this.onGetRouteList(1, 999999, { transport_type_id: this.state.busDefault.value, area_id: data.areaId, orderBy: "route", orderType: "DESC" });
        this.setState({ areaDefault: data });
    }

    onChangeGland = (data) => {
        this.onGetData(data.transportId);
        this.setState({ glandDefault: data });
    }

    createMarkup(html) {
        return { __html: html };
    }

    render() {
        const { busList, busDefault, areaList, areaDefault, glandList, glandDefault, data } = this.state;

        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/tien-ich-plus">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Lịch trình xe buýt, tàu điện</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="bus-schedule-mobile p-3">
                    <form>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Phương tiện</label>
                                    <Select
                                        onChange={e => e && this.onChangeBus(e)}
                                        value={busDefault}
                                        defaultValue={busDefault}
                                        options={busList.map((bOption) => ({ ...bOption, label: bOption.transportTypeName, value: bOption.transportTypeId, }))} noOptionsMessage={() => "Không có dữ liệu"}
                                        className="slAutocomplete" placeholder="Chọn phương tiện"
                                    />
                                </div>
                            </div>
                            <div className="col-4 pl-2">
                                <div className="form-group">
                                    <label>Khu vực</label>
                                    <Select
                                        onChange={e => e && this.onChangeArea(e)}
                                        value={areaDefault}
                                        defaultValue={areaDefault}
                                        options={areaList.map((bOption) => ({ ...bOption, label: bOption.areaName, value: bOption.areaId, }))} noOptionsMessage={() => "Không có dữ liệu"}
                                        className="slAutocomplete" placeholder="Chọn khu vực"
                                    />
                                </div>
                            </div>
                            <div className="col-4 pl-2">
                                <div className="form-group">
                                    <label>Chọn tuyến</label>
                                    <Select
                                        onChange={e => e && this.onChangeGland(e)}
                                        value={glandDefault}
                                        defaultValue={glandDefault}
                                        options={glandList.map((bOption) => ({ ...bOption, label: bOption.route, value: bOption.transportId, }))} noOptionsMessage={() => "Không có dữ liệu"}
                                        className="slAutocomplete" placeholder="Chọn tuyến"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    {data ?
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th colSpan="2">
                                        {data.route}.{data.busName}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Đơn vị đảm nhận:</td>
                                    <td>{data.companyName}</td>
                                </tr>

                                <tr>
                                    <td>Giá vé:</td>
                                    <td>{data.ticketPrice && data.ticketPrice + 'đ/lượt'}</td>
                                </tr>

                                <tr>
                                    <td>Cự ly:</td>
                                    <td>{data.distance && data.distance + ' km'}</td>
                                </tr>

                                <tr>
                                    <td>Thời gian hoạt động:</td>
                                    <td>
                                        {data.operationTime && <div dangerouslySetInnerHTML={this.createMarkup(data.operationTime.replace(/\n/g, "<br />"))}></div>}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Thời gian kế hoạch 1 lượt:
                                </td>
                                    <td>
                                        {data.tripTime}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Số chuyến:
                                </td>
                                    <td>
                                        {data.tripQuantity}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Giãn cách chuyến:
                                </td>
                                    <td>
                                        {data.tripSpacing && <div dangerouslySetInnerHTML={this.createMarkup(data.tripSpacing.replace(/\n/g, "<br />"))}></div>}
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colSpan="2">
                                        <strong>Chiều đi:</strong>
                                        <p>
                                            {data.tripForward}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        <strong>Chiều về:</strong>
                                        <p>
                                            {data.tripBack}
                                        </p>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                        :
                        <div>
                            Không có dữ liệu
                    </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBusList: busActions.GetBusList,
            getAreaList: busActions.GetAreaList,
            getRouteList: busActions.GetRouteList,
            getBusDetail: busActions.GetBusDetail,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BusScheduleMobileView);