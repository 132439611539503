import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as NotificationStore from 'redux/stores/notification/notifications.store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { PromiseAllTools } from 'core/app.store';

class NotificationCommentDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: { id: this.props.userInfo.customerId, name: this.props.userInfo.customerName },
            peopleTargetId: null,
            peopleTargetName: '',
            selectedCommentId: null,
            isOpenDeletePopup: false,
            deletedFeebackId: null
        }
    }



    onTargetCommentPeople = (selectCommentId, replyFor = "") => {
        document.getElementById(`input-reply-${selectCommentId}`).value = "@" + replyFor + " ";
        document.getElementById(`input-reply-${selectCommentId}`).focus();
    }

    onPostComment = (e) => {
        e.preventDefault();
        if (!this.commentInput.value.trim()) {
            return;
        }
        this.props.PromiseAllTools([this.props.PostNotificationComment({
            comment: this.commentInput.value && this.commentInput.value.trim(),
            customer_id: this.state.currentUser.id,
            parent_id: 0,
            inform_id: this.props.notification_id,
            feedback_id: null,
        })], (e) => {
            this.commentInput.value = '';
            this.props.GetNotificationComments(this.props.notification_id)
        })
    }

    onPostReply = (commentId) => {

        if (!document.getElementById(`input-reply-${commentId}`).value.trim()) {
            return;
        }
        this.props.PromiseAllTools([this.props.PostNotificationComment({
            comment: document.getElementById(`input-reply-${commentId}`).value && document.getElementById(`input-reply-${commentId}`).value.trim(),
            customer_id: this.state.currentUser.id,
            parent_id: commentId,
            feedback_id: null,
            inform_id: this.props.notification_id,
        })], (success) => {
            document.getElementById(`input-reply-${commentId}`).value = '';
            this.props.GetNotificationComments(this.props.notification_id)
        })
    }

    onToggleDeletePopup = (isOpenDeletePopup, deletedCommentModel) => {
        this.setState({
            isOpenDeletePopup: isOpenDeletePopup,
            deletedCommentModel: deletedCommentModel
        })
    }

    onToggleDeletePopup = (isOpenDeletePopup, deletedFeebackId = null) => {
        this.setState({
            isOpenDeletePopup: isOpenDeletePopup,
            deletedFeebackId: deletedFeebackId
        })
    }

    onDeleteComment = () => {
        this.props.PromiseAllTools([this.props.DeleteNotificationComments(this.state.deletedFeebackId)], (success) => {
            AddNotification('Xoá bình luận thành công!', NotificationMessageType.Success);
            this.setState({
                isOpenDeletePopup: false
            })
            this.props.GetNotificationComments(this.props.notification_id)
        })
    }

    onEditComment = async (dataPost) => {
        this.props.PromiseAllTools([this.props.PostNotificationComment(dataPost)], (success) => {
            this.props.GetNotificationComments(this.props.notification_id)
        })
    }

    render() {
        const comments = this.props.notificationComments.haveData ? this.props.notificationComments.listComment : [];
        const { isOpenDeletePopup } = this.state;

        return (
            <div className="row">
                <div className="col-12">
                    {comments.map(cm =>
                        <div key={cm.feedbackId} className="comment-item">
                            <RenderComment data={cm} style="comment-parent" onTargetCommentPeople={this.onTargetCommentPeople.bind(this)} onToggleDeletePopup={this.onToggleDeletePopup.bind(this)} onEditComment={this.onEditComment} userInfo={this.props.userInfo} inform_id={this.props.notification_id} />
                            {cm.replyData.map(rp =>
                                <RenderComment key={rp.feedbackId} data={rp} style="comment-child" onTargetCommentPeople={this.onTargetCommentPeople.bind(this)} onToggleDeletePopup={this.onToggleDeletePopup.bind(this)} onEditComment={this.onEditComment} inform_id={this.props.notification_id} userInfo={this.props.userInfo} />
                            )}
                            <div className="comment-child">
                                <div className="comment-box position-relative reply-input">
                                    <input name="reply_content" type="text" onKeyUp={e => handleEnterKeyPress(e, `input-submit-reply-notification-desktop-${cm.feedbackId}`)} id={`input-reply-${cm.feedbackId}`} placeholder="Viết phản hồi" className="form-control rounded-pill" ref={(input) => { this['replyInput' + cm.comment_id] = input; }} />
                                    <img src={require('/assets/images/send-gray.svg')} alt="Send" className="img-send" id={`input-submit-reply-notification-desktop-${cm.feedbackId}`} onClick={() => this.onPostReply(cm.feedbackId)} />
                                </div>
                            </div>
                        </div>
                    )}
                    <form onSubmit={this.onPostComment}>
                        <div className="row">
                            <div className="col-12">
                                <div className="comment-box position-relative mt-3">
                                    <input name="comment_content" type="text" placeholder="Gửi bình luận..." className="form-control rounded-pill pr-5" ref={(input) => { this.commentInput = input; }} />
                                    <img src={require('/assets/images/send-gray.svg')} alt="send" className="img-send" onClick={this.onPostComment.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </form>

                    <Modal show={isOpenDeletePopup} className="modal-delete-mobile" centered onHide={() => this.onToggleDeletePopup(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Xóa bình luận?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Bình luận sẽ không thể khôi phục sau khi xóa.
                            <br />
                            Bạn có chắc muốn xóa bình luận này.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                            <Button variant="success" onClick={() => this.onDeleteComment(true)}>Xóa</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}

function RenderComment(props) {
    const { data } = props;
    let commentInput = null;
    const [isShowEditForm, setShowEditForm] = useState(false);
    const style = props.style || "comment-child";
    let showFeedbackButton = true;
    if (style === "comment-child") showFeedbackButton = false;
    const onShowDeletePopup = () => {
        props.onToggleDeletePopup(true, data.feedbackId);
    }

    const onEditComment = async (e) => {
        e.preventDefault();
        if (!commentInput.value.trim()) {
            return;
        }
        await props.onEditComment({
            comment: commentInput.value && commentInput.value.trim(),
            customer_id: data.customerId,
            feedback_id: data.feedbackId,
            inform_id: props.inform_id,
        });
        setShowEditForm(false);
    }

    return (
        <div>
            <div className={style}>
                {!isShowEditForm &&
                    <div className="bg-light p-2 rounded">
                        <h5>{data.customerName}</h5>
                        <p className="overflow-auto">{data.comment}</p>
                    </div>
                }
                {isShowEditForm && <form onSubmit={onEditComment} className="col-11 pl-2">
                    <div className="form-group position-relative">
                        <input name="comment_content" defaultValue={data.comment} type="text" placeholder="Viết phản hồi..." className="form-control rounded-pill pr-5" ref={(input) => { commentInput = input; }} />
                        <img src={require('/assets/images/send-gray.svg')} alt="Send" className="img-send img-send-edit-button-mobile position-absolute" onClick={onEditComment.bind(this)} />
                        <a className="cancel-edit" onClick={() => setShowEditForm(false)}>Huỷ</a>
                    </div>
                </form>}
            </div>
            <span className="datetime">{moment(data.date).fromNow()}</span>
            {showFeedbackButton && <span className="recomment" onClick={() => props.onTargetCommentPeople(data.feedbackId, data.customerName)}>Phản hồi</span>}
            {(props.userInfo.customerId === data.customerId) && (!isShowEditForm) &&
                <span>
                    <span className="recomment" onClick={() => setShowEditForm(true)}>Chỉnh sửa</span>
                    <span className="recomment text-danger" onClick={() => onShowDeletePopup()}>Xóa</span>
                </span>
            }
        </div>
    )
}

function handleEnterKeyPress(event, idSubmitButton) {
    if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById(idSubmitButton).click()
    }
}

RenderComment.prototype = {
    data: {
        id: PropTypes.number.isRequired,
        userId: PropTypes.number.isRequired,
        content: PropTypes.string.isRequired,
        createdBy: PropTypes.string.isRequired,
        createdById: PropTypes.string.isRequired,
        createdDate: PropTypes.string.isRequired,
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    PostNotificationComment: NotificationStore.PostNotificationComment,
    GetNotificationComments: NotificationStore.GetNotificationComments,
    DeleteNotificationComments: NotificationStore.DeleteNotificationComments,
    PromiseAllTools
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCommentDesktopView)