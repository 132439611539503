import React from 'react';

export const renderError = (error) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = 'Định dạng file PDF không đúng';
            break;
        case 'MissingPDFException':
            message = 'Không tìm thấy file';
            break;
        case 'UnexpectedResponseException':
            message = 'Unexpected server response';
            break;
        default:
            message = 'Không thể tải file';
            break;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    borderRadius: '0.25rem',
                    color: '#e53e3e',
                    padding: '0.5rem',
                }}
            >
                {message}
            </div>
        </div>
    );
};