import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as busActions from "../../../redux/stores/utilities-plus/bus-schedule.store";
import "../utilities-plus-desktop.scss";
import { Table } from "react-bootstrap";
import Select from 'react-select';

class BusScheduleDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            busList: [],
            busDefault: { label: '', value: 0 },
            areaList: [],
            areaDefault: { label: '', value: 0 },
            glandList: [],
            glandDefault: { label: '', value: 0 },
            data: null,
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetBusList(1, 999999, { orderBy: "transportTypeId", orderType: "DESC" });
    }

    onGetBusList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getBusList(pageIndex, size, filterObjs).then(
            res => {
                if (res && res.content && res.content.length > 0) {
                    this.setState({ busList: res.content, busDefault: { label: res.content[0].transportTypeName, value: res.content[0].transportTypeId } });
                    this.onGetAreaList(1, 999999, { transport_type_id: res.content[0].transportTypeId, orderBy: "areaId", orderType: "DESC" });
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onGetAreaList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getAreaList(pageIndex, size, filterObjs).then(
            res => {
                if (res && res.content && res.content.length > 0) {
                    this.setState({ areaList: res.content, areaDefault: { label: res.content[0].areaName, value: res.content[0].areaId } });
                    this.onGetRouteList(1, 999999, { transport_type_id: this.state.busDefault.value, area_id: res.content[0].areaId, orderBy: "route", orderType: "DESC" });
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onGetRouteList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getRouteList(pageIndex, size, filterObjs).then(
            res => {
                if (res && res.content && res.content.length > 0) {
                    this.setState({ glandList: res.content, glandDefault: { label: res.content[0].route, value: res.content[0].transportId } });
                    this.onGetData(res.content[0].transportId);
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onGetData = (transportId) => {
        this.props.getBusDetail(transportId).then(
            res => {
                if (res && res.content) {
                    this.setState({ data: res.content });
                }
            },
            err => {
                // console.log(err);
            }
        );
    };

    onChangeBus = (data) => {
        this.onGetAreaList(1, 999999, { transport_type_id: data.transportTypeId, orderBy: "areaId", orderType: "DESC" });
        this.setState({ busDefault: data });
    }

    onChangeArea = (data) => {
        this.onGetRouteList(1, 999999, { transport_type_id: this.state.busDefault.value, area_id: data.areaId, orderBy: "route", orderType: "DESC" });
        this.setState({ areaDefault: data });
    }

    onChangeGland = (data) => {
        this.onGetData(data.transportId);
        this.setState({ glandDefault: data });
    }

    createMarkup(html) {
        return { __html: html };
    }

    render() {
        const { busList, busDefault, areaList, areaDefault, glandList, glandDefault, data } = this.state;

        return (
            <div className="utilities-plus-desktop">
                <h2 className="title-lg mb-3">Lịch trình xe buýt, tàu điện</h2>
                <div className="row form-bus-schedule">
                    <div className="col-4 form-group">
                        <label>Phương tiện</label>
                        <Select
                            onChange={e => e && this.onChangeBus(e)}
                            value={busDefault}
                            defaultValue={busDefault}
                            options={busList.map((bOption) => ({ ...bOption, label: bOption.transportTypeName, value: bOption.transportTypeId, }))} noOptionsMessage={() => "Không có dữ liệu"}
                            className="slAutocomplete" placeholder="Chọn phương tiện"
                        />
                    </div>
                    <div className="col-4 form-group">
                        <label>Khu vực</label>
                        <Select
                            onChange={e => e && this.onChangeArea(e)}
                            value={areaDefault}
                            defaultValue={areaDefault}
                            options={areaList.map((bOption) => ({ ...bOption, label: bOption.areaName, value: bOption.areaId, }))} noOptionsMessage={() => "Không có dữ liệu"}
                            className="slAutocomplete" placeholder="Chọn khu vực"
                        />
                    </div>
                    <div className="col-4 form-group">
                        <label>Chọn tuyến</label>
                        <Select
                            onChange={e => e && this.onChangeGland(e)}
                            value={glandDefault}
                            defaultValue={glandDefault}
                            options={glandList.map((bOption) => ({ ...bOption, label: bOption.route, value: bOption.transportId, }))} noOptionsMessage={() => "Không có dữ liệu"}
                            className="slAutocomplete" placeholder="Chọn tuyến"
                        />
                    </div>
                </div>
                {data ?
                    <div className="bus-schedule-info">
                        <h2 className="pb-2 mt-3 mb-0">{data.route}.{data.busName}</h2>
                        <hr className="mt-0 mb-2"></hr>
                        <Table size="sm" className="tbl-bus-schedule">
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold">
                                        Đơn vị đảm nhận:
                                    </td>
                                    <td className="font-weight-light">
                                        {data.companyName}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">
                                        Giá vé:
                                    </td>
                                    <td className="font-weight-light">
                                        {data.ticketPrice && data.ticketPrice + 'đ/lượt'}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">
                                        Cự ly:
                                    </td>
                                    <td className="font-weight-light">
                                        {data.distance && data.distance + ' km'}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">
                                        Thời gian hoạt động:
                                    </td>
                                    <td className="font-weight-light">
                                        {data.operationTime && <div dangerouslySetInnerHTML={this.createMarkup(data.operationTime.replace(/\n/g, "<br />"))}></div>}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">
                                        Thời gian kế hoạch 1 lượt:
                                    </td>
                                    <td className="font-weight-light">
                                        {data.tripTime}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">
                                        Số chuyến:
                                    </td>
                                    <td className="font-weight-light">
                                        {data.tripQuantity}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">
                                        Giãn cách chuyến:
                                    </td>
                                    <td className="font-weight-light">
                                        {data.tripSpacing && <div dangerouslySetInnerHTML={this.createMarkup(data.tripSpacing.replace(/\n/g, "<br />"))}></div>}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <div className="mb-3">
                            <div className="font-weight-bold">Chiều đi:</div>
                            <div className="font-weight-light">{data.tripForward}</div>
                        </div>
                        <div>
                            <div className="font-weight-bold">Chiều về:</div>
                            <div className="font-weight-light">{data.tripBack}</div>
                        </div>
                    </div>
                    :
                    <div>
                        Không có dữ liệu
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBusList: busActions.GetBusList,
            getAreaList: busActions.GetAreaList,
            getRouteList: busActions.GetRouteList,
            getBusDetail: busActions.GetBusDetail,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BusScheduleDesktopView);
