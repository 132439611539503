import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from '../../../core/app.store';
import {
    createFormData,
    AppKey,
    compareValues
} from '../../../utils/configuration';
import Service from '../../../services/api.service';
import {
    ApiUrl
} from '../../../services/api-url';

const service = new Service();
/** Action Types */
const STAYING = 'STAYING/STAYING';

/** Actions */
export const createSaveStaying = data => ({
    type: STAYING,
    data
});

export const AddOrUpdateStaying = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.StayingCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveStaying(res.content));
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

// Initial state
export const initialState = Map({

}).toJS();

/** Reducers */
export default function StayingReducer(state = initialState, action) {
    switch (action.type) {
        case STAYING:
            return {
                ...state,
                content: action.data
            }
            default:
                return state;
    }
}