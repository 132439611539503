/* eslint-disable react/prop-types */
import React, { useState, useEffect,Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "../payment-desktop.scss";
import { Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as WaterBillStores from 'redux/stores/payment/water/water.store';
import RenderBillOfMonthly from './water-bill-month.view.jsx';
import PayDetail from './water-pay-detail.view.jsx';
import { ToolSelectPeriodTime, convertTextareaToHtml, NotificationMessageType, TokenKey, getCookies, GetProjectInfo } from "utils/configuration";
import { Error } from 'components/support-create-form/validate-style';
import { handleChangeListHistory } from '../logic-payment/handle-change-list-history';
import { PromiseAllTools } from 'core/app.store';
import * as CreateTransactionStores from 'redux/stores/create-transaction/create-transaction.store';
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { useRenderPaymentHistoryList } from '../../../components/custom-hook/render-payment-history-list/render-payment-history-list';
import Pagination from '../../../components/pagination/paging.view';

export function PaymentWaterDesktopView(props) {
  const { endDate, startDate, handleChangeStartDate, handleChangeEndDate, resetChangeDateTime, filterObj } = new ToolSelectPeriodTime()
  const [showPayDetail, setShowPayDetail] = useState(false)
  const [showWarning, setShowWarning] = useState(false);
  const PaymentGuideDocument = { __html: convertTextareaToHtml(props.guide) || "Không có dữ liệu để hiển thị" };

  const currentDomain = window.location.origin;
  const projectName = getCookies(TokenKey.ProjectName) ? getCookies(TokenKey.ProjectName) : "";
  const projectInfo = GetProjectInfo();
  const isPayment = projectInfo && projectInfo.hasVnpay && projectInfo.hasVnpay == "1" || false;

  function handleChangePayDetail(i) {
    setShowPayDetail(!showPayDetail)
    props.PromiseAllTools([props.GetListWaterDetail(i)]);
  }

  function ControlChangeListHistory() {
    handleChangeListHistory(
      startDate,
      endDate,
      () => props.PromiseAllTools([props.GetWaterList(1, 100, filterObj, props.userInfo.apartmentId)]),
      resetChangeDateTime,
      setShowWarning,
      props.ResetHistoryList
    )
  }

  function onCreateTransactionPayment(data) {
    props.CreateTransactionVNPay({
      return_url: currentDomain + "/thanh-toan/ket-qua",
      transaction_name: "Thanh toán tiền phí nước tháng " + data.monthly + " " + data.yearly + " căn hộ " + data.houseNumber + " khu " + projectName,
      service_type_code: "water",
      service_id: data.id
    }).then(
      (res) => {
        window.location.replace(res.content && res.content.redirect_url);
      },
      (err) => {
        err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error);
      }
    );
  }

  function PayHistory() {
    const listData = Array.from(props.listPaid);
    return (
      <div className="body mt-4">
        <div className="row align-items-center">
          <div className="col-3">
            <h3 className="body-title">Lịch sử thanh toán</h3>
          </div>
          <div className="col-9">
            <div className="row no-gutters align-items-center">
              <div className="col-1"></div>
              <div className="col-4 text-right position-relative pr-1">
                <DatePicker selected={startDate} onChange={handleChangeStartDate} className="rounded-pill" dateFormat="dd/MM/yyyy" disabled={listData.length === 0} />
                <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-calendar" />
              </div>
              <div className="col-4 text-right position-relative pr-1">
                <DatePicker selected={endDate} onChange={handleChangeEndDate} className="rounded-pill" dateFormat="dd/MM/yyyy" disabled={listData.length === 0} />
                <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-calendar" />
              </div>
              <div className="col-3 text-right">
                <button className="btn btn-success rounded-pill" onClick={ControlChangeListHistory} disabled={listData.length === 0}>
                  <img src={require('assets/images/search-white.svg')} alt="Search White" className="img-search-white" /> Tìm kiếm
                  </button>
              </div>
            </div>
            <div className='text-center'>
              {showWarning && <Error content="Khoảng thời gian không đúng, vui lòng kiểm tra lại." />}
            </div>
          </div>
        </div>

        {listData.length > 0 ? <RenderHistoryList listData={listData} handleChangePayDetail={handleChangePayDetail} /> : (
            <div className="border-top mt-2 pt-3">
              <p className="text-center mb-0">Không có dữ liệu để hiển thị</p>
            </div>
          )
        }
      </div >
    )
  }

  useEffect(() => {
    props.PromiseAllTools([
      props.GetWaterBillThisMonth(props.userInfo.apartmentId),
      props.GetWaterList(1, 100, filterObj, props.userInfo.apartmentId),
      props.GetPaymentGuide(),
    ])
  }, [])

  return (
    <div className="payment-desktop">
      <RenderBillOfMonthly haveData={props.monthlyBill.haveData} data={props.monthlyBill} />

      {showPayDetail ? <PayDetail data={props.detail} click={() => setShowPayDetail(!showPayDetail)} /> : PayHistory()}

      <div className="payment-guide" dangerouslySetInnerHTML={PaymentGuideDocument}></div>
      {
        props.monthlyBill && props.monthlyBill.datePay == "" && props.monthlyBill.total > "0" && (
          <button className="btn btn-success text-uppercase rounded-pill mt-4 pl-4 pr-4" disabled={!isPayment} onClick={() => onCreateTransactionPayment(props.monthlyBill)}>
            Thanh toán phí
          </button>
        )
      }
    </div>
  );

}

function RenderHistoryList(props) {
  const {
    listData,
    Page_Size,
    displayList,
    setCurrentIndex
  } = useRenderPaymentHistoryList(props.listData)
  return (
    <Fragment>
      <Table hover className="mt-1 mb-0">
        <tbody>
          {displayList.map((data, index) => <RenderFieldOfList data={data} key={index} click={() => props.handleChangePayDetail(data.billId)} />)}
        </tbody>
      </Table>
      {
        (listData.length > Page_Size) && <Pagination totalItems={listData.length} size={Page_Size} goTo={setCurrentIndex} />
      }
    </Fragment>
  )
}

function RenderFieldOfList(props) {
  const data = props.data
  return (
    <tr className="pointer" onClick={props.click}>
      <td>
        <h5>Hóa đơn nước tháng {data.monthly}/{data.yearly}</h5>
        <span>Ngày thanh toán: {data.datePay || "Chưa thanh toán"}</span>
      </td>
      <td className="text-right total-price">{data.total}VNĐ</td>
    </tr>
  )
}

const mapStateToProps = state => ({
  monthlyBill: state.waterBill.billThisMonth,
  listPaid: state.waterBill.paymentHistory,
  detail: state.waterBill.billDetail,
  guide: state.waterBill.paymentGuide,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetListWaterDetail: WaterBillStores.GetListWaterDetail,
  GetWaterList: WaterBillStores.GetWaterList,
  GetWaterBillThisMonth: WaterBillStores.GetWaterBillThisMonth,
  GetPaymentGuide: WaterBillStores.GetPaymentGuide,
  ResetHistoryList: WaterBillStores.ResetHistoryList,
  CreateTransactionVNPay: CreateTransactionStores.CreateTransactionVNPay,
  PromiseAllTools
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWaterDesktopView)
