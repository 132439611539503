/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../notification-desktop.scss";
import * as NotificationsStore from "redux/stores/notification/notifications.store";
import NotificationCommentDesktopView from "./childs/notification-comment.views.jsx";
import { PromiseAllTools } from "core/app.store";
import { SetDefaultErrorImage } from "utils/configuration";

class NotificationDetailDesktopView extends Component {
  componentDidMount() {
    this.props.PromiseAllTools([
      this.props.GetNotificationDetail(
        Number(this.props.match.params.idNotification)
      ),
      this.props.GetNotificationComments(
        Number(this.props.match.params.idNotification),
        1
      ),
    ]);
  }

  render() {
    const detailData = this.props.notificationDetailData || {};

    const content = {
      __html: this.props.notificationDetailData
        ? // ? convertTextareaToHtml(this.props.notificationDetailData.content)
          this.props.notificationDetailData.content
        : "Không có dữ liệu",
    };

    let HeaderTitle = "Thông báo từ BQL";

    if (detailData.type === "SUGESTIONFROMNEIGHBORS") {
      HeaderTitle = "Góp ý từ cư dân";
    }

    return (
      <div className="notification-desktop mt-4">
        <h2 className="title-lg bg-white p-4">
          <Link to="/thong-bao">
            <img
              src={require("assets/images/detailed-arrow.svg")}
              alt="Detailed Arrow"
              className="img-detailed-arrow-left"
            />
          </Link>
          {HeaderTitle}
        </h2>

        <div className="bg-white p-4 mt-4 notification-detail">
          <h5>{detailData.title}</h5>

          <span className="datetime">
            <img
              src={require("assets/images/calendar.svg")}
              alt="Calendar"
              className="img-fluid"
            />{" "}
            {detailData.createDate}
          </span>

          <hr></hr>

          {detailData.type === "SUGESTIONFROMNEIGHBORS" ? (
            <div
              className="text-justify"
              id="content-container-notification-detail"
            >
              {content.__html}
            </div>
          ) : (
            <div
              className="text-justify"
              id="content-container-notification-detail"
              dangerouslySetInnerHTML={content}
            ></div>
          )}

          <div className="row">
            {detailData.haveData &&
              detailData.imageFiles.map((fileImages, index) => (
                <RenderImage key={index} data={fileImages} />
              ))}
          </div>

          <br />
          <br />
          <br />
          {detailData.haveData && detailData.documentFiles.length > 0 && (
            <div className="row">
              <h6 className="col-12">File đính kèm</h6>
              {detailData.documentFiles.map((fileImages, index) => (
                <RenderAttackFile key={index} data={fileImages} />
              ))}
            </div>
          )}

          <hr></hr>

          <div className="comment">
            {this.props.isLogin && (
              <NotificationCommentDesktopView
                userInfo={this.props.userInfo}
                notification_id={Number(this.props.match.params.idNotification)}
                notificationComments={this.props.notificationComments}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function RenderImage(props) {
  const data = props.data;
  return (
    <div className="col-12 col-md-6">
      <img
        src={data.fileUrl}
        alt={data.fileName}
        className="w-100 image-notification-attack-file"
        onError={SetDefaultErrorImage}
      />
    </div>
  );
}

function RenderAttackFile(props) {
  const data = props.data;
  return (
    <a href={data.fileUrl} target="_blank" className="col-12 col-sm-4 col-md-2">
      <img
        src={require("assets/images/pdf-icon.png")}
        alt="PDF DOCUMENT"
        className="img-fluid"
      />
      <p className="text-truncate text-center text-dark">{data.fileName}</p>
    </a>
  );
}

const mapStateToProps = (state) => ({
  notificationDetailData: state.notifications.notificationDetail,
  notificationComments: state.notifications.notificationComments,
  isLogin: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetNotificationDetail: NotificationsStore.GetNotificationDetail,
      GetNotificationComments: NotificationsStore.GetNotificationComments,
      PromiseAllTools,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDetailDesktopView);
