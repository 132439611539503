//@ts-check
import React, { useEffect } from 'react';
import HeaderPageMobileView from '../../../components/header-page-mobile/header-page-mobile.view';
import * as PageUrls from '../../PageUrls';
import BlockSliderView from './childs/list-page-childs/block-slider.view';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiesStore from '../../../redux/stores/utillities/utilitis-shop.store';

/**
 * @param {{
 *  listTennis:{
 * logoUrl: string;
 * name: string;
 * address: string;
 * id: number;}[],
 * listGym:{
 * logoUrl: string;
 * name: string;
 * address: string;
 * id: number;}[],
 * listSoccer:{
 * logoUrl: string;
 * name: string;
 * address: string;
 * id: number;}[],
 * listPool:{
 * logoUrl: string;
 * name: string;
 * address: string;
 * id: number;}[],
 * GetListTennis:Function,
 * GetListGym:Function,
 * GetListSoccer:Function,
 * GetListPool:Function,
 * }} [props]
*/
function UtilitiesSportListPageMobile(props) {
    const pageSize = 100;
    const pageIndex = 1;
    useEffect(() => {
        // call api
        props.GetListTennis(pageIndex, pageSize)
        props.GetListGym(pageIndex, pageSize)
        props.GetListSoccer(pageIndex, pageSize)
        props.GetListPool(pageIndex, pageSize)
    }, [])
    return (
        <div>
            <HeaderPageMobileView goBackUrl={PageUrls.UtilitiesSportHomeMobile} title="Thể thao" />
            <BlockSliderView listData={props.listPool} title="Bể bơi" detailUrl={PageUrls.UtilitiesSportPoolDetailMobile} />
            <BlockSliderView listData={props.listGym} title="Gym" detailUrl={PageUrls.UtilitiesSportGymDetailMobile} />
            <BlockSliderView listData={props.listTennis} title="Tennis" detailUrl={PageUrls.UtilitiesSportTennisDetailMobile} />
            <BlockSliderView listData={props.listSoccer} title="Bóng đá" detailUrl={PageUrls.UtilitiesSportSoccerDetailMobile} />
        </div>
    )
}

const mapStateToProps = state => ({
    listTennis: state.utilitiShop.tennisList.listShop,
    listGym: state.utilitiShop.gymList.listShop,
    listSoccer: state.utilitiShop.soccerList.listShop,
    listPool: state.utilitiShop.poolList.listShop,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListTennis: UtilitiesStore.GetListTennis,
    GetListGym: UtilitiesStore.GetListGym,
    GetListSoccer: UtilitiesStore.GetListSoccer,
    GetListPool: UtilitiesStore.GetListPool,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesSportListPageMobile)