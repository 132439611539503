import { Map } from 'immutable';

/** Action Types */
const SAVELOGININFO = 'LOGIN/SAVELOGININFO';

/** Actions */
export const createSaveLoginInfo = data => ({ type: SAVELOGININFO, payload: data })

// Initial state
export const initialState = Map({
    currentUser: {}
}).toJS();

/** Reducers */
export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case SAVELOGININFO:
            return {
                ...state,
                currentUser: action.payload
            }
        default:
            return state
    }
}
