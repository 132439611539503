//@ts-check
import { useState } from 'react';
import { JwtHelper } from 'utils/jwt-helper';
import { getDateWithFormat } from '../../../utils/configuration';

/**
 * @type {{customerId:number,customerName:string,apartmentId:number,mobileNumber:string,houseNumber:string}|null}
*/
const userInfo = JwtHelper.currentUser() || { customerId: 0, customerName: '', apartmentId: 0, mobileNumber: '', houseNumber: '' };

/**
 * @param {number} shopId - id shop
 * @param {Function} registerFunction - function handle register, has return true (success) or false (error), and it is a sync function (use async await)
 * @param {(id:number,date:Date)=>any} handleGetCalendarByDate - function handle get calendar by date
*/
export function useRegisterCalendarHook(shopId, registerFunction, handleGetCalendarByDate) {
    const [userName, setUserName] = useState(userInfo.customerName);
    const [phoneNumber, setPhoneNumber] = useState(userInfo.mobileNumber);
    const [slotIdRegister, setSlotIdRegister] = useState(-1);
    const [slotNameRegister, setSlotNameRegister] = useState('');
    const [hasShowRegisterModal, setHasShowRegisterModal] = useState(false);
    const [validationUserNameMessage, _setValidationUserNameMessage] = useState('');
    const [validationPhoneNumberMessage, _setValidationPhoneNumberMessage] = useState('');
    const [dateView, setDateView] = useState(new Date());
    const handleChangeDateView = (date) => {
        setDateView(date);
        _getCalendarByDateView(date);
    }

    /**
     * @param {Date} date
    */
    const _getCalendarByDateView = (date) => {
        // conver date, call api
        handleGetCalendarByDate(shopId, date)
    }
    const handleShowRegisterModal = (slotName, slotId) => {
        setSlotIdRegister(slotId);
        setSlotNameRegister(slotName)
        setHasShowRegisterModal(true);
    }
    const handleRegister = async () => {
        let CheckedRequieFields = true;
        if (!userName.trim()) {
            CheckedRequieFields = false; _setValidationUserNameMessage('Trường này không được để trống')
        } else _setValidationUserNameMessage('')
        if (!_functionValidatePhoneNumber()) CheckedRequieFields = false;
        if (!CheckedRequieFields) return;
        // call api
        const paramsData = {
            apartmentId: userInfo.apartmentId,
            shopId: shopId,
            phoneNumber: phoneNumber,
            customerName: userName,
            slotId: slotIdRegister,
            date: getDateWithFormat(dateView)
        }
        if (await registerFunction(paramsData)) setHasShowRegisterModal(false)
    }

    /**
     * @returns {boolean} - result check
    */
    const _functionValidatePhoneNumber = () => {
        if (!phoneNumber.trim()) {
            _setValidationPhoneNumberMessage("Trường này không được để trống");
            return false
        }
        if ((!!!Number(phoneNumber)) || ((phoneNumber.length < 10) || (phoneNumber.length > 11))) {
            _setValidationPhoneNumberMessage("Định dạng số điện thoại không đúng");
            return false
        }
        _setValidationPhoneNumberMessage(null)
        return true
    }

    return ({
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        slotIdRegister,
        setSlotIdRegister,
        slotNameRegister,
        setSlotNameRegister,
        hasShowRegisterModal,
        setHasShowRegisterModal,
        dateView,
        handleChangeDateView,
        handleShowRegisterModal,
        handleRegister,
        validationUserNameMessage,
        validationPhoneNumberMessage,
    })
}