import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../payment-mobile.scss';
import * as OtherBillStores from '../../../../redux/stores/payment/other-services/other-service.store';
import RenderBillOfMonthly from "../other-bill-detail/other-bill-detail-mobile.view.jsx";
import { PromiseAllTools } from 'core/app.store';

class PaymentOtherDetailMobileView extends Component {

    UNSAFE_componentWillMount() {
        let feeId = null;
        let parkingId = null;
        const idArray = this.props.match.params.otherId.split("&");
        if (idArray.length > 1) {
            feeId = Number(idArray[0]);
            parkingId = Number(idArray[1]);
        } else {
            feeId = Number(idArray[0]);
        }
        this.props.PromiseAllTools([this.props.GetOtherBillDetail(feeId, parkingId)])
    }

    render() {
        const data = this.props.otherDetail;
        return (
            <div className="payment-mobile">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/thanh-toan/dich-vu-khac">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Thanh toán hóa đơn</div>
                            </div>
                        </div>
                    </div>
                </header>

                <RenderBillOfMonthly dataFee={data.fee} haveFeeBill={data.haveFee} haveParkingBill={data.haveParking} dataParking={data.parking} />

            </div>
        );
    }
}
const mapStateToProps = state => ({
    otherDetail: state.otherBill.otherDetail,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetOtherBillDetail: OtherBillStores.GetOtherBillDetail,
    PromiseAllTools
},
    dispatch
)
export default connect(mapStateToProps, mapDispatchToProps)(PaymentOtherDetailMobileView)