/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as landModelActions from "modules/advertising-desktop/buy-and-rent-houses/buy-and-rent-houses.store";
import { Error } from "components/support-create-form/validate-style";
import SharedService from "services/share.service.js";
import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  NewGuid,
  ValidImageTypes,
  convertTextareaToHtml,
  validateFile,
  CheckMaxFile,
} from "utils/configuration";
import { history } from "redux/store";
import AddNotification from "components/react-notifications/react-notifications";
import { JwtHelper } from "utils/jwt-helper";
import { Link } from "react-router-dom";
import "./buy-and-rent-mobile.form.scss";

const sharedService = new SharedService();

function CreateOrUpdateLandForm(props) {
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [previewImgs, setPreviewImg] = useState(props.model.landFile || []);
  const [landFiles, setLandFiles] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  if (!previewImgs.length && props.model.landFile) {
    setPreviewImg(props.model.landFile);
  }

  if (!selectCategory && props.model.categoryId) {
    setSelectCategory(props.model.categoryId);
  }

  const onSubmit = (data) => {
    if (!landFiles.length && !previewImgs.length) {
      return;
    }

    data.land_file = [...landFiles];
    data.delete_file = [...deleteFiles];
    data.content = convertTextareaToHtml(data.content);
    props.onCreateOrUpdatelandModel && props.onCreateOrUpdatelandModel(data);
  };

  let initialValues = {
    landId: props.model.landId,
    content: props.model.content,
    land_title: props.model.title,
    category_id: props.model.categoryId && props.model.categoryId.toString(),
    size: props.model.size,
    price: props.model.price,
    mobile_number: props.model.mobileNumber,
    living_room: props.model.livingRoom,
    bedroom: props.model.bedroom,
    kitchen: props.model.kitchen,
    wc: props.model.wc,
  };

  const onFileChange = (event) => {
    if (event.target.files) {
      const listFile = Array.from(event.target.files);
      let msgError = CheckMaxFile(previewImgs.length + listFile.length);
      if (msgError) {
        setValidateImgMessage(msgError);
        return;
      }
      Promise.all(
        listFile.map((file) => {
          return new Promise((resolve, reject) => {
            msgError = validateFile(file);
            if (msgError) {
              setValidateImgMessage(msgError);
              return;
            }

            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve({
                fileId: NewGuid(),
                fileName: file.name,
                fileUrl: ev.target.result,
                fileType: ValidImageTypes.includes(file.type)
                  ? "image"
                  : file.type,
                file: file,
              });
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          setPreviewImg([...previewImgs, ...images]);
          setLandFiles([
            ...landFiles,
            images.map((image) => {
              return image.file;
            }),
          ]);
          setValidateImgMessage("");
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const onRemovedAttachment = (fileId) => {
    setPreviewImg(previewImgs.filter((file) => file.fileId != fileId));
    setDeleteFiles([...deleteFiles, fileId]);
  };

  return (
    <div className="create-buy-and-rent-houses-mobile">
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m/rao-vat/mua-ban-thue-nha">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">
                {props.editMode ? "Chỉnh sửa tin" : "Đăng tin"}
              </div>
            </div>
          </div>
        </div>
      </header>

      <form onSubmit={handleSubmit(onSubmit)} className="p-3">
        <div className="form-row">
          <div className="form-group col-12">
            <input
              type="hidden"
              defaultValue={initialValues.landId}
              name="land_id"
              ref={register}
            />
            <label>Tiêu đề</label>
            <input
              type="text"
              defaultValue={initialValues.land_title}
              id="land_title"
              name="land_title"
              className="form-control"
              placeholder="Tiêu đề"
              ref={register({ required: "Trường bắt buộc" })}
            />
            {errors.land_title && <Error content={errors.land_title.message} />}
          </div>
          <div className="form-group col-12">
            <label>Loại hình bất động sản {selectCategory}</label>
            <select
              defaultValue={selectCategory}
              id="category_id"
              name="category_id"
              className="form-control"
              ref={register({ required: "Trường bắt buộc" })}
            >
              <option value="">Chọn loại hình</option>
              {props.landCategoris.map((item) => (
                <option
                  key={item.categoryId}
                  selected={item.categoryId == selectCategory}
                  value={item.categoryId}
                >
                  {item.categoryName}
                </option>
              ))}
            </select>
            {errors.category_id && (
              <Error content={errors.category_id.message} />
            )}
          </div>
          <div className="form-group col-6">
            <label>Giá (VNĐ)</label>
            <input
              type="number"
              defaultValue={initialValues.price}
              id="price"
              name="price"
              placeholder="Giá"
              className="form-control"
              ref={register({
                required: "Trường bắt buộc",
                maxLength: { value: 12, message: "Lớn nhất 12 ký tự" },
              })}
            />
            {errors.price && <Error content={errors.price.message} />}
          </div>
          <div className="form-group col-6">
            <label>Diện tích</label>
            <input
              type="number"
              defaultValue={initialValues.size}
              id="size"
              name="size"
              className="form-control"
              placeholder="Diện tích"
              ref={register({
                maxLength: { value: 9, message: "Lớn nhất 9 ký tự" },
              })}
            />
            {errors.size && <Error content={errors.size.message} />}
          </div>
          <div className="form-group col-6">
            <label>Phòng khách</label>
            <input
              type="number"
              defaultValue={initialValues.living_room}
              id="living_room"
              name="living_room"
              className="form-control"
              placeholder="Số phòng khách"
              ref={register({
                maxLength: { value: 6, message: "Lớn nhất 6 ký tự" },
              })}
            />
            {errors.living_room && (
              <Error content={errors.living_room.message} />
            )}
          </div>
          <div className="form-group col-6">
            <label>Phòng ngủ</label>
            <input
              type="number"
              defaultValue={initialValues.bedroom}
              id="bedroom"
              name="bedroom"
              className="form-control"
              placeholder="Số phòng ngủ"
              ref={register({
                maxLength: { value: 6, message: "Lớn nhất 6 ký tự" },
              })}
            />
            {errors.bedroom && <Error content={errors.bedroom.message} />}
          </div>
          <div className="form-group col-6">
            <label>Vệ sinh</label>
            <input
              type="number"
              defaultValue={initialValues.wc}
              id="wc"
              name="wc"
              className="form-control"
              placeholder="Số phòng vệ sinh"
              ref={register({
                maxLength: { value: 6, message: "Lớn nhất 6 ký tự" },
              })}
            />
            {errors.wc && <Error content={errors.wc.message} />}
          </div>
          <div className="form-group col-6">
            <label>Bếp</label>
            <input
              type="number"
              defaultValue={initialValues.kitchen}
              id="kitchen"
              name="kitchen"
              className="form-control"
              placeholder="Số bếp"
              ref={register({
                maxLength: { value: 6, message: "Lớn nhất 6 ký tự" },
              })}
            />
            {errors.kitchen && <Error content={errors.kitchen.message} />}
          </div>
          <div className="form-group col-12">
            <label>Số điện thoại</label>
            <input
              type="phone"
              defaultValue={initialValues.mobile_number}
              id="mobile_number"
              name="mobile_number"
              className="form-control"
              placeholder="Số điện thoại"
              ref={register({
                required: "Trường bắt buộc",
                minLength: { value: 10, message: "Ít nhất 10 ký tự" },
                pattern: {
                  value: /^[0-9\b]+$/,
                  message: "Số điện thoại sai định dạng",
                },
              })}
            />
            {errors.mobile_number && (
              <Error content={errors.mobile_number.message} />
            )}
          </div>
          <div className="form-group col-12">
            <label>Thông tin mô tả</label>
            <textarea
              id="content"
              defaultValue={initialValues.content}
              name="content"
              placeholder="Nội dung"
              className="form-control"
              rows="5"
              ref={register}
            ></textarea>
          </div>
        </div>

        <div className="row">
          {previewImgs.map((prev) => (
            <div
              key={prev.fileId}
              className="col-4 col-sm-2 preview-img mb-2 attachment-wrapper"
            >
              <img
                src={prev.fileUrl}
                alt={prev.fileType}
                className="img-attachment"
              />
              <div className="overlay"></div>
              <img
                onClick={() => onRemovedAttachment(prev.fileId)}
                src={require("assets/images/close-white.svg")}
                alt="close-white"
                className="img-close-white"
              />
            </div>
          ))}
        </div>

        <div className="file-upload">
          <input
            type="text"
            className="hidden-text-field"
            defaultValue={initialValues.attachmentUrl}
            id="attachmentUrl"
            name="attachmentUrl"
            ref={register}
          />
          <div className="title-upload">
            <img
              src={require("assets/images/collections.png")}
              alt="Image"
              className="img-fluid"
            />{" "}
            <span>Upload ảnh</span>
          </div>
          <input
            type="file"
            name="attachmentFile"
            accept="image/*"
            multiple
            className="media-file"
            onChange={onFileChange.bind(this)}
            ref={register({
              required: {
                value: !previewImgs.length,
                message: "Trường bắt buộc",
              },
            })}
          />
          {errors.attachmentFile && (
            <Error content={errors.attachmentFile.message} />
          )}
          {validateImgMessage && <Error content={validateImgMessage} />}
        </div>
        <div className="row mt-3">
          <div className="col-6 text-left">
            <Link
              to="/m/rao-vat/mua-ban-thue-nha"
              className="btn btn-secondary rounded-pill w-100"
            >
              Hủy
            </Link>
          </div>
          <div className="col-6 text-right">
            <button
              type="submit"
              className="btn btn-success rounded-pill w-100"
            >
              {props.editMode ? "Lưu thay đổi" : "Đăng tin"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
class BuyAndSaleHouseMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landCategoris: [],
      landId: this.props.match.params.landId,
      editMode: !!this.props.match.params.landId,
      landModel: {},
      currentUser: JwtHelper.currentUser(),
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetDropdownData();
    if (this.state.editMode) {
      this.onGetLandDetail();
    }
  }

  onGetDropdownData = () => {
    Promise.all([sharedService.getLandCategoris()])
      .then((res) => {
        const [landCategorisModel] = res;
        this.setState({
          landCategoris: landCategorisModel.content,
        });
      })
      .catch((err) => {});
  };

  onGetLandDetail = () => {
    this.props.getAdHouseDetail(this.state.landId).then(
      (res) => {
        if (res.content) {
          this.setState({
            landModel: res.content,
          });
        } else {
          AddNotification(
            viVN.Errors[res.errorType],
            NotificationMessageType.Error
          );
          history.push("/m/rao-vat/mua-ban-thue-nha");
        }
      },
      (err) => {}
    );
  };

  onCreateOrUpdateLand = (data) => {
    let submittedModel = {
      ...data,
      customer_id: this.state.currentUser.customerId,
    };
    this.props.createOrUpdateLand(submittedModel).then(
      () => {
        AddNotification(
          this.state.editMode
            ? "Cập nhật bài viết thành công"
            : "Đăng bài thành công",
          NotificationMessageType.Success
        );
        history.push("/m/rao-vat/mua-ban-thue-nha");
      },
      () => {}
    );
  };

  render() {
    return (
      <CreateOrUpdateLandForm
        landCategoris={this.state.landCategoris}
        editMode={this.state.editMode}
        model={this.state.landModel}
        onCreateOrUpdatelandModel={this.onCreateOrUpdateLand.bind(this)}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdHouseDetail: landModelActions.GetAdHouseDetail,
      createOrUpdateLand: landModelActions.CreateOrUpdateLand,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyAndSaleHouseMobileView);
