/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import { JwtHelper } from "utils/jwt-helper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as friendActions from "redux/stores/friend/friend.store";
import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import "../user-message/user-message-mobile.scss";
class UserMessageMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1,
      customerName: "",
      currentUser: JwtHelper.currentUser(),
      tabDefault:
        this.props.match.params.tag === "ban-be" ? "ban-be" : "tin-nhan",
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetFriends();
  }

  componentDidMount() {
    if (this.props.match.params.tag === "ban-be") {
      window.scrollTo({
        top: document.getElementById("warp-content-for-scroll").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  onGetFriends = (
    pageIndex = this.state.currentPageIndex,
    size = 10,
    customerId = this.state.currentUser.customerId,
    filterObjs = {}
  ) => {
    this.setState({ isLoading: true });
    this.props.getFriends(pageIndex, size, customerId, { ...filterObjs }).then(
      () => {
        this.setState({ isLoading: false });
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  onAcceptFriendRequest = (friendId) => {
    this.setState({ isLoading: true });
    this.props
      .acceptFriend(this.state.currentUser.customerId, friendId)
      .then(() => {
        this.setState({ isLoading: false });
        AddNotification(
          "Chấp nhận yêu cầu kết bạn thành công!",
          NotificationMessageType.Success
        );
        this.onGetFriends();
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onDisagreeFriendRequest = (friendId, isUnfriend = false) => {
    this.setState({ isLoading: true });
    this.props
      .disagreeFriendRequest(this.state.currentUser.customerId, friendId)
      .then(() => {
        this.setState({ isLoading: false });
        AddNotification(
          isUnfriend
            ? "Xóa bạn thành công!"
            : "Từ chối yêu cầu kết bạn thành công!",
          NotificationMessageType.Success
        );
        this.onGetFriends();
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onFilterChange = (e) => {
    e && e.preventDefault();
    this.setState(
      {
        [e.target.name]: e.target.value,
        currentPageIndex: 1,
      },
      () => {
        this.onGetFriends(1, 10, this.state.currentUser.customerId, {
          customer_name: this.state.customerName,
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onGetFriends(pageNumber);
      }
    );
  };

  onCustomeNameChange = (e) => {
    e && e.preventDefault();
    this.setState({
      customerName: e.target.value.trim(),
    });
  };

  onShowMessageBox = () => {
    this.props.onShowMessageBox();
  };
  render() {
    const { friends } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="bg-white">
        <div className="tab-user-message">
          {/* <Tab.Container defaultActiveKey={this.state.tabDefault}> */}
          <Tab.Container defaultActiveKey="ban-be">
            <Nav variant="tabs">
              {/* <Nav.Item>
                <Nav.Link eventKey="tin-nhan">
                  <img src={require('assets/images/chat-green.svg')} alt="Processing" className="img-fluid img-active" />
                  <img src={require('assets/images/chat-black.svg')} alt="Processing" className="img-fluid img-inactive" />
                  <span className="ml-2">Tin nhắn</span>
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item className="w-100">
                <Nav.Link eventKey="ban-be">
                  <img
                    src={require("assets/images/friends-green.svg")}
                    alt="Processing"
                    className="img-fluid img-active"
                  />
                  <img
                    src={require("assets/images/friends-black.svg")}
                    alt="Processing"
                    className="img-fluid img-inactive"
                  />
                  <span className="ml-2">Bạn bè</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content id="warp-content-for-scroll">
              {/* <Tab.Pane eventKey="tin-nhan">
                <div className="message">
                  <Link to="/m/tin-nhan">
                    <div className='message-item'>
                      <div className="row no-gutters align-items-center">
                        <div className="col-2">
                          <img src={require('assets/images/chi-hang.jpg')} alt="Message" className="img-fluid rounded-circle" />
                        </div>
                        <div className="col-10 pl-2">
                          <h5>Trần Thu Hương</h5>
                          <span>Bạn muốn lấy báo nhiêu kg</span>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <div className="message-item">
                    <div className="row no-gutters align-items-center">
                      <div className="col-2">
                        <img src={require('assets/images/anh-hung.jpg')} alt="Message" className="img-fluid rounded-circle" />
                      </div>
                      <div className="col-10 pl-2">
                        <h5>Đỗ Huy Thanh</h5>
                        <span>Ok anh</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-item">
                    <div className="row no-gutters align-items-center">
                      <div className="col-2">
                        <img src={require('assets/images/chi-trang.jpg')} alt="Message" className="img-fluid rounded-circle" />
                      </div>
                      <div className="col-10 pl-2">
                        <h5>Hoàng Phương Thảo</h5>
                        <span>Nhận được tiền nhắn chị nhé</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-item">
                    <div className="row no-gutters align-items-center">
                      <div className="col-2">
                        <img src={require('assets/images/chi-hoa.jpg')} alt="Message" className="img-fluid rounded-circle" />
                      </div>
                      <div className="col-10 pl-2">
                        <h5>Đào Thị Hoàng Lan</h5>
                        <span>Dạ ok chị</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-item">
                    <div className="row no-gutters align-items-center">
                      <div className="col-2">
                        <img src={require('assets/images/chi-kim.jpg')} alt="Message" className="img-fluid rounded-circle" />
                      </div>
                      <div className="col-10 pl-2">
                        <h5>Phạm Thị Thảo</h5>
                        <span>Cho mình xin sđt của bạn nhé</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-item">
                    <div className="row no-gutters align-items-center">
                      <div className="col-2">
                        <img src={require('assets/images/anh-binh.jpg')} alt="Message" className="img-fluid rounded-circle" />
                      </div>
                      <div className="col-10 pl-2">
                        <h5>Nghiêm Văn Mạnh</h5>
                        <span>Thứ 4 tới em gửi cho anh nhé</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-item">
                    <div className="row no-gutters align-items-center">
                      <div className="col-2">
                        <img src={require('assets/images/anh-canh.jpg')} alt="Message" className="img-fluid rounded-circle" />
                      </div>
                      <div className="col-10 pl-2">
                        <h5>Phí Đình Phong</h5>
                        <span>Vậy t7 cho mình đi nhờ nhé </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane> */}
              <Tab.Pane eventKey="ban-be">
                <div className="friend">
                  <form onSubmit={this.onFilterChange.bind(this)}>
                    <div className="col-12 pl-2 pr-2">
                      <div className="form-group">
                        <input
                          name="customer_name"
                          type="text"
                          placeholder="Nhập tên cư dân"
                          className="form-control rounded-pill"
                          onChange={this.onCustomeNameChange.bind(this)}
                        />
                      </div>
                    </div>
                  </form>
                  {friends.map((item) => (
                    <div key={item.customerId} className="friend-item">
                      <div className="row no-gutters align-items-center">
                        <div className="col-2">
                          <img
                            src={
                              item.avatarUrl ||
                              require("assets/images/29-01.png")
                            }
                            alt="Message"
                            className="img-fluid rounded-circle img-avatar"
                          />
                        </div>
                        <div className="col-5 pl-2">
                          <h5>{item.customerName}</h5>
                        </div>
                        <div className="col-5 text-right">
                          <div className="friend-actions-container">
                            {item.status ==
                              friendActions.FriendStatus.ConfirmingFriend && (
                              <button
                                className="btn btn-success"
                                title="Đồng ý"
                                disabled={isLoading}
                                onClick={() =>
                                  this.onAcceptFriendRequest(item.customerId)
                                }
                              >
                                Xác nhận
                              </button>
                            )}
                            {item.status ==
                              friendActions.FriendStatus.ConfirmingFriend && (
                              <button
                                className="btn btn-danger unfriend-button"
                                title="Từ chối"
                                disabled={isLoading}
                                onClick={() =>
                                  this.onDisagreeFriendRequest(item.customerId)
                                }
                              >
                                <img
                                  src={require("assets/images/cancel-white.png")}
                                />
                              </button>
                            )}
                            {item.status ==
                              friendActions.FriendStatus.Friend && (
                              <button
                                title="Xóa bạn"
                                className="btn btn-danger unfriend-button"
                                disabled={isLoading}
                                onClick={() =>
                                  this.onDisagreeAddFriendRequest(
                                    item.customerId,
                                    true
                                  )
                                }
                              >
                                Xóa bạn
                              </button>
                            )}
                          </div>
                          {/* <Link to="/m/tin-nhan">
                          {item.status == friendActions.FriendStatus.Friend && <button className="btn btn-primary" disabled={isLoading} onClick={() => this.onShowMessageBox()}>Nhắn tin</button>}
                        </Link> */}
                        </div>
                      </div>
                    </div>
                  ))}
                  {!friends.length && <p>Chưa có bạn bè.</p>}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  friends: state.friend.friends,
  friendTotalItems: state.friend.friendTotalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFriends: friendActions.GetFriends,
      acceptFriend: friendActions.AcceptFriend,
      disagreeFriendRequest: friendActions.DeleteFriend,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMessageMobileView);
