import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RenderNotHaveDataText } from 'components/warning-component/not-have-data';
import Pagination from 'components/pagination/paging.view';
import "../safe-mobile.scss";
import { PromiseAllTools } from 'core/app.store';
import * as QualityDocumentStore from 'redux/stores/safe/quality-document.store';

export function WaterQualityMobileView(props) {

    const [notCallApi, setNotCallApi] = useState(true);

    useEffect(() => {
        props.PromiseAllTools([props.GetWaterDocumentList()])
    }, [])

    function gotoPage(i) {
        props.PromiseAllTools([props.GetWaterDocumentList(i)])
    }

    const listData = props.list ? props.list.data : [];
    const totalItems = props.list ? props.list.totalItems : 1;
    const pageSize = props.list ? props.list.pageSize : 10;

    if (notCallApi && props.list && props.list.data) setNotCallApi(false);

    return (
        <div className="water-quality-mobile">
            <header className="header-mobile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/m/an-toan">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                            <div className="header-title text-center">An toàn</div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="water-quality-header">
                <img src={require('assets/images/chat-luong-nuoc.jpg')} alt="Image" className="img-fluid" />
                <div className="overlay"></div>
                <span>Xem chất lượng nước</span>
            </div>

            <div className="water-quality-list pl-3 pr-3">
                <div className="row align-items-center">
                    {(listData.length > 0) ? listData.map((data, index) => <WaterQuality data={data} key={index} />) : <RenderNotHaveDataText notCallApi={notCallApi} />}
                </div>
            </div>
            {(listData.length > 0) && <Pagination totalItems={totalItems} size={pageSize} goTo={(i) => gotoPage(i)} />}
        </div>
    );
}

const WaterQuality = (props) => {
    const data = props.data

    return (
        <div className="col-12">
            <div className="water-quality-item">
                <div className="row no-gutters">
                    <div className="col-11">
                        <Link to={`/m/an-toan/chat-luong-nuoc/chi-tiet/${data.id}`}>{data.documentName}</Link>
                    </div>
                    <div className="col-1 text-right">
                        <Link to={`/m/an-toan/chat-luong-nuoc/chi-tiet/${data.id}`}>
                            <img src={require("assets/images/keyboard-arrow-right.svg")} alt="Image" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    list: state.qualityDocument.list
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetWaterDocumentList: QualityDocumentStore.GetWaterDocumentList,
            PromiseAllTools
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(WaterQualityMobileView);
