import { Map } from 'immutable';
import { createFormData, AppKey } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { SecurityCameras } from 'mock-data/security-camera.mock';
import { createSaveLoading } from 'core/app.store';
let securityCamerasTemp = [...SecurityCameras];
const service = new Service();
/** Action Types */
const SECURITYCAMERASAVELIST = 'SECURITYCAMERA/SAVELIST';
const SECURITYCAMERASAVETOP3SECURITYCAMERA = 'SECURITYCAMERA/SAVETOP3SECURITYCAMERA';

/** Actions */
export const createSavesecurityCameraList = (securityCameraModel) => ({ type: SECURITYCAMERASAVELIST, payload: { securityCameraList: securityCameraModel.securityCameraList, totalItems: securityCameraModel.totalItems } });
export const createSaveTop3securityCameras = (top3securityCameras) => ({ type: SECURITYCAMERASAVETOP3SECURITYCAMERA, payload: top3securityCameras });


/** Action creators */
export const GetsecurityCameraList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.SecurityCameraGetList, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            dispatch(createSavesecurityCameraList({ securityCameraList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetTop3securityCameraList = (pageIndex = 1, size = 3, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.SecurityCameraGetList, payLoad).then(res => {
            dispatch(createSaveTop3securityCameras(res.content && res.content.items || []))
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetsecurityCameraDetail = (securityCameraId) => {
    return dispatch => {
        let payLoad = createFormData({ securityCameraId: securityCameraId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.securityCameraGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeletesecurityCamera = (securityCameraId) => {
    return dispatch => {
        let payLoad = createFormData({ securityCameraId: securityCameraId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.securityCameraDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

// Initial state
export const initialState = Map({
    securityCameraList: [],
    top3SecurityCameras: [],
    totalItems: 0,
}).toJS();

/** Reducers */
export default function securityCameraReducer(state = initialState, action) {
    switch (action.type) {
        case SECURITYCAMERASAVELIST:
            return {
                ...state,
                securityCameraList: [...action.payload.securityCameraList],
                totalItems: action.payload.totalItems,
            }
        case SECURITYCAMERASAVETOP3SECURITYCAMERA:
            return {
                ...state,
                top3SecurityCameras: [...action.payload],
            }
        default:
            return state;
    }
}