/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NotificationMessageType,
  SetDefaultErrorImage,
} from "utils/configuration";
import * as userActions from "redux/stores/user/user-information.store";
import * as shopActions from "redux/stores/shop/shop.store";
import AddNotification from "components/react-notifications/react-notifications";
import MyShopDesktopView from "./my-shop/my-shop-mobile.view";
import UserMessageMobileView from "./user-message/user-message-mobile.view";
import { JwtHelper } from "utils/jwt-helper";
import * as viVN from "translation/vi-VN.json";
import RenderNotificationBell from "components/header/notification-bell.view.jsx";

import "../user-mobile.scss";
import "../user-information/user-information-mobile.scss";

class UserInformationMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      member: [],
      shopList: [],
    };
  }

  UNSAFE_componentWillMount() {
    const userInfo = JwtHelper.currentUser();
    if (this.props.loggedIn && userInfo) {
      this.onGetUserInformation(userInfo.customerId);
    } else {
      window.location.replace(window.location.origin + "/m");
    }
  }

  onGetUserInformation(userId) {
    this.props.GetUserInformation(userId).then(
      (res) => {
        if (res && res.content) {
          this.onGetShopList();
          this.setState({
            user: res.content,
            member: res.content.otherCustomer,
          });
        } else {
          AddNotification(
            viVN.Errors[res && res.errorType],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[err && err.errorType],
          NotificationMessageType.Error
        );
        window.location.replace(window.location.origin + "/m/dang-nhap");
      }
    );
  }

  onGetShopList = () => {
    this.props.onGetListShopByUser().then(
      (res) => {
        this.setState({
          shopList: res.content,
        });
      },
      () => {}
    );
  };

  render() {
    const { user, member, shopList } = this.state;
    return (
      <div className="user-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <Link to="/m">
                      <img
                        src={require("/assets/images/arrow-back.svg")}
                        alt="Arrow Back"
                        className="img-fluid img-arrow-back"
                      />
                    </Link>
                  </div>
                  <div className="col-4 header-title text-center">User</div>
                  <div className="col-4 text-right">
                    <RenderNotificationBell forMobile={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {user && (
          <div>
            <div className="bg-white p-3 user-info-header">
              <div className="row align-items-center">
                <div className="col-2 col-sm-1">
                  <img
                    src={user.avatarUrl || require("/assets/images/29-12.png")}
                    onError={(error) =>
                      SetDefaultErrorImage(error, "/assets/images/29-12.png")
                    }
                    className="img-avatar-circle rounded-circle"
                    alt={user.customerName || "Avatar"}
                  />
                </div>
                <div className="col-10 col-sm-11">
                  <h5 className="title-md mb-0">{user.customerName}</h5>
                  {user.houseNumber && (
                    <span className="font-weight-light">
                      Căn hộ: {user.houseNumber}
                    </span>
                  )}
                  <div className="feature mt-2">
                    <Link to="/m/chinh-sua-thong-tin-ca-nhan">
                      <img
                        src={require("assets/images/edit-blue.svg")}
                        className="img-fluid"
                        alt="Edit"
                      />
                      Thông tin cá nhân
                    </Link>
                    <Link to="/m/dat-lai-mat-khau" className="ml-4">
                      <img
                        src={require("assets/images/reset-password.svg")}
                        className="img-fluid"
                        alt="Reset password"
                      />
                      Đặt lại mật khẩu
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {member && member.length > 0 && (
              <div>
                <div className="mobile-divide"></div>
                <div className="bg-white p-3">
                  <div className="user-friend">
                    {member.map((item) => (
                      <UserFriend user={item} key={item.customerId} />
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="mobile-divide"></div>

            <div className="row">
              <div className="col-12">
                <MyShopDesktopView shopList={shopList} />

                <div className="mobile-divide"></div>

                <div className="bg-white p-3">
                  <div className="document-management">
                    <img
                      src={require("assets/images/double-file.svg")}
                      alt="Shop Blue"
                      className="img-fluid"
                    />
                    <Link to="/m/quan-ly-giay-to">Quản lý giấy tờ</Link>
                  </div>
                </div>

                <div className="mobile-divide"></div>

                <UserMessageMobileView match={this.props.match} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function UserFriend(props) {
  const { user, scroll } = props;
  return (
    <div className={"item" + (scroll ? " mr-2" : "")}>
      <div className="row align-items-center no-gutters">
        <div className="col-2 col-sm-1">
          <img
            src={user.avatarUrl || require("assets/images/29-01.png")}
            className="img-fluid"
            alt="Avatar"
          />
        </div>
        <div className="col-6 col-sm-7">
          <h5 className="mb-0 text-truncate">{user.customerName}</h5>
        </div>
        <div className="col-4 col-sm-4 text-right">
          <span>Thành viên</span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetUserInformation: userActions.GetUserInformation,
      onGetListShopByUser: shopActions.GetShopList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformationMobileView);
