import Geocode from "react-geocode";
import {
    GoogleMapAPI
} from './googlemap.view';

Geocode.setLanguage("vi")
Geocode.setRegion("vi")
Geocode.enableDebug()
Geocode.setApiKey(GoogleMapAPI);

export default class GeocodeTool {
    constructor() {
        Geocode.setLanguage("vi")
        Geocode.setRegion("vi")
        Geocode.setApiKey(GoogleMapAPI);
    }

    setInputValue(idElement, text) {
        document.getElementById(idElement).value = text
    }

    getAdressAndSetValue(lat, long, idElement, defaultError = "-----------") {

        if (!lat) return console.log("latitude required")
        if (!long) return console.log("longitude required")
        if (!idElement) return console.log("id html element required")

        Geocode.fromLatLng(lat, long).then(
            response => {
                const address = response.results[0].formatted_address;
                this.setInputValue(idElement, address)
            },
            error => {
                console.error(error);
                this.setInputValue(idElement, defaultError)
            }
        )
    }

    getCoordinatesAndSetValue(address, idElement, defaultError = "-----------") {

        if (!address) return console.log("adress required")
        if (!idElement) return console.log("id html element required")

        Geocode.fromAddress(address).then(
            response => {
                const {
                    lat,
                    lng
                } = response.results[0].geometry.location;
                this.setInputValue(idElement, `${lat},${lng}`)
            },
            error => {
                console.error(error);
                this.setInputValue(idElement, defaultError)
            }
        );
    }

    getCoordinatesAndSetValueLatLng(address, idInputLat, idInputLng, defaultErrorLat = "-----------", defaultErrorLng = "-----------") {

        if (!address) return console.log("adress required")
        if (!idInputLat) return console.log("id html element for latitude is required")
        if (!idInputLng) return console.log("id html element for longitude is required")

        Geocode.fromAddress(address).then(
            response => {
                const {
                    lat,
                    lng
                } = response.results[0].geometry.location;
                this.setInputValue(idInputLat, lat)
                this.setInputValue(idInputLng, lng)
            },
            error => {
                console.error(error);
                this.setInputValue(idInputLat, defaultErrorLat)
                this.setInputValue(idInputLng, defaultErrorLng)
            }
        );
    }
}

export const MapTool = {

    // Get latitude & longitude from address.
    getCoordinatesFromAddress(address) {
        address && Geocode.fromAddress(address).then(
            response => {
                console.error(response.results[0].geometry.location);
                return {
                    lat,
                    lng
                } = response.results[0].geometry.location;
            },
            error => {
                console.error(error);
                return {
                    lat: 0,
                    lng: 0
                };
            }
        );
    }
}