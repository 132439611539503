//@ts-check
import React, { Fragment, useState, useRef } from 'react';
import * as CommonClass from 'utils/css-common-class';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Error, Success } from 'components/support-create-form/validate-style';
import './body-detail-gym-pool.scss';
import { useRegisterFormHook } from '../../../../../components/custom-hook/register-form-sport/register-form';
import { JwtHelper } from 'utils/jwt-helper';
import * as viVN from '../../../../../translation/vi-VN.json';
/**
 * @type {{customerId:number,customerName:string,apartmentId:number,mobileNumber:string,houseNumber:string}|null}
*/
const userInfo = JwtHelper.currentUser() || { customerId: 0, customerName: '', apartmentId: 0, mobileNumber: '', houseNumber: '' };
/**
 * registerFunction - recive params to register
 * @param {{
 * shopId:number;
 * registerFunction:(params:any)=>boolean,
 * paymentGuide:string,
 * from2to6:string[],
 * from7to1:string[],
 * isGymBody?:boolean,
 * isLoggedIn?:boolean,
 * }} props
*/
function RenderBodyDetailGymPool(props) {
    const {
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        validateUserName,
        validatePhoneNumber,
        setRegisterMonth,
        handleRegister,
        hasRegisted
    } = useRegisterFormHook(props.shopId, props.registerFunction)
    const from2to6 = Array.from(props.from2to6 || []);
    const from7to1 = Array.from(props.from7to1 || []);
    return (
        <div className="body-container-sport-detail">
            <div hidden={(from2to6.length === 0) && (from7to1.length === 0)}>
                <h3 className="title-sm mt-4 text-size-sub-title">Lịch hoạt động</h3>
                <Table striped className="text-size-super-content">
                    <thead>
                        <tr>
                            <th className="border-0"></th>
                            <th className="border-0 text-center">Thứ 2 - Thứ 6</th>
                            <th className="border-0 text-center">Thứ 7 & Chủ nhật</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border-0"><strong>Sáng</strong></td>
                            <td className="border-0 text-center">{from2to6[0]}</td>
                            <td className="border-0 text-center">{from7to1[0]}</td>
                        </tr>
                        <tr>
                            <td className="border-0"><strong>Trưa</strong></td>
                            <td className="border-0 text-center">{from2to6[1]}</td>
                            <td className="border-0 text-center">{from7to1[1]}</td>
                        </tr>
                        <tr>
                            <td className="border-0"><strong>Chiều</strong></td>
                            <td className="border-0 text-center">{from2to6[2]}</td>
                            <td className="border-0 text-center">{from7to1[2]}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className="row no-gutters">
                <div className="col-6" >
                    <h3 className="text-size-sub-title">Gia hạn thành viên</h3>
                    <div dangerouslySetInnerHTML={{ __html: props.paymentGuide }}>

                    </div>
                </div>
                <div className={`col-6 ${(!props.isLoggedIn) ? CommonClass.DisabledElement : ''}`}>
                    <h3 className="text-size-sub-title">Đăng ký sử dụng</h3>
                    {
                        hasRegisted &&
                        (
                            <p className="text-success text-center mt-3">
                                {viVN.Utilities.RegisterSuccess}
                            </p>
                        )
                    }
                    <div hidden={hasRegisted}>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Họ tên" defaultValue={userName} style={{ borderRadius: "20px" }} onChange={event => setUserName(event.target.value)} />
                            {
                                validateUserName && <Error content={validateUserName} />
                            }
                        </div>
                        <div className="row no-gutters">
                            <div className="col-6 pr-2">
                                <div className="form-group">
                                    <input type="tel" className="form-control" placeholder="Nhập SĐT" defaultValue={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} style={{ borderRadius: "20px" }} />
                                    {
                                        validatePhoneNumber && <Error content={validatePhoneNumber} />
                                    }
                                </div>
                            </div>
                            <div className="col-6 pl-2">
                                <div className="form-group">
                                    <input type="p" disabled className="form-control" style={{ borderRadius: "20px" }} placeholder="Nhập số căn hộ" defaultValue={userInfo ? userInfo.houseNumber : ''} />
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters mb-3 text-secondary" hidden={!props.isGymBody}>
                            <div className="col-6 pl-4 ">
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" type="radio" id="monthRegister1" value="" name="registerMonthRadio" onClick={() => setRegisterMonth(1)} defaultChecked />
                                    <label className="custom-control-label" htmlFor="monthRegister1">
                                        Gói 1 tháng
                                </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" type="radio" id="monthRegister6" value="" name="registerMonthRadio" onClick={() => setRegisterMonth(6)} />
                                    <label className="custom-control-label" htmlFor="monthRegister6">
                                        Gói 6 tháng
                                </label>
                                </div>
                            </div>
                            <div className="col-6 pl-4">
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" type="radio" id="monthRegister3" value="" name="registerMonthRadio" onClick={() => setRegisterMonth(3)} />
                                    <label className="custom-control-label" htmlFor="monthRegister3">
                                        Gói 3 tháng
                                </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" type="radio" id="monthRegister12" value="" name="registerMonthRadio" onClick={() => setRegisterMonth(12)} />
                                    <label className="custom-control-label" htmlFor="monthRegister12">
                                        Gói 12 tháng
                                </label>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-success w-100" style={{ borderRadius: "20px" }} onClick={() => handleRegister()}>Đăng ký</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn
})

export default connect(mapStateToProps, null)(RenderBodyDetailGymPool)