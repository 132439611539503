/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import "../footer/footer-mobile.scss";
import { Link } from 'react-router-dom';

class FooterMobileView extends Component {
    render() {
        return (
            <footer className="footer-mobile">
                <div className="row row-cols-4 no-gutters">
                    <div className="col">
                        <div className="item">
                            <Link to="/m">
                                <img src={require('/assets/images/home.svg')} alt="Trang chủ" className="img-fluid" />
                            </Link>

                            <h5>
                                <Link to="/m">Trang chủ</Link>
                            </h5>
                        </div>
                    </div>

                    <div className="col">
                        <div className="item">
                            <Link to="/m/thong-tin-bql">
                                <img src={require('/assets/images/ban-quan-ly-24px.svg')} alt="Ban quản lý" className="img-fluid" />
                            </Link>
                            <h5>
                                <Link to="/m/thong-tin-bql">BQL</Link>
                            </h5>
                        </div>
                    </div>

                    <div className="col">
                        <div className="item">
                            <Link to="/m/gui-y-kien">
                                <img src={require('/assets/images/gui-y-kien.svg')} alt="Gửi ý kiến" className="img-fluid" />
                            </Link>

                            <h5>
                                <Link to="/m/gui-y-kien">Gửi ý kiến</Link>
                            </h5>
                        </div>
                    </div>

                    <div className="col">
                        <div className="item">
                            <Link to="/m/tien-ich-plus">
                                <img src={require('assets/images/tien-ich.svg')} alt="Tiện ích" className="img-fluid" />
                            </Link>
                            <h5>
                                <Link to="/m/tien-ich-plus">Tiện ích+</Link>
                            </h5>
                        </div>
                    </div>

                    {/* <div className="col">
                        <div className="item">
                            <Link to="/m/an-toan">
                                <img src={require('/assets/images/heart-contours.svg')} alt="An toàn" className="img-fluid" />
                            </Link>
                            <h5>
                                <Link to="/m/an-toan">An toàn</Link>
                            </h5>
                        </div>
                    </div> */}
                </div>

                {/* <div className="contact-support-mobile">
                    <a href="tel:02462943030">
                        <img src={require("assets/images/phone-call-effect.svg")} alt="Phone Call" className="img-phone-call" />
                    </a>
                </div>
                <div className="messenger-support-mobile">
                    <a href="https://www.messenger.com/t/www.iLang.vn" target="_blank">
                        <img src={require("assets/images/messenger.svg")} alt="Messenger" />
                    </a>
                </div> */}
            </footer>
        );
    }
}

export default FooterMobileView;