/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button } from "react-bootstrap";
import * as hotActions from "redux/stores/hot/hot.store";
import * as shopActions from "redux/stores/shop/shop.store";
import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import PaginationControl from "components/pagination/pagination.view";
import { Link } from "react-router-dom";
import { history } from "redux/store";

import "./promotion-mobile.scss";

class ShopPromotionMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopId: this.props.match.params.id,
      shopType:
        (this.props.match.params.type &&
          this.props.match.params.type.toUpperCase()) ||
        "",
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { shopId, shopType } = this.state;
    shopId
      ? this.onGetHotList(1, 10, {
          shop_id: shopId,
          category_id:
            shopActions.SHOP_TYPE_ID[
              (shopType && shopType.toUpperCase()) || ""
            ],
          orderBy: "update_date",
          orderType: "DESC",
        })
      : history.push("/m/thong-tin-ca-nhan");
  }

  onGetHotList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getHotList(pageIndex, size, filterObjs).then(
      () => {},
      () => {}
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetHotList(e, 10, {
          shop_id: this.state.shopId,
          orderBy: "update_date",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  render() {
    const { currentPageIndex, shopId, shopType } = this.state;
    const { hotList, totalItems, onDeleteHot } = this.props;
    return (
      <div className="hot-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to={`/m/shop/${shopType.toLocaleLowerCase()}/${shopId}`}>
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">
                  Đăng tin khuyến mãi
                </div>
                <Link
                  to={`/m/dang-tin-khuyen-mai/${shopType.toLocaleLowerCase()}/${shopId}`}
                >
                  <img
                    src={require("assets/images/edit_white.svg")}
                    alt="edit_white"
                    className="img-fluid img-notification"
                  />
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="container shop-promotion">
          <div className="row mt-3">
            {hotList && hotList.length > 0 ? (
              hotList.map((item) => (
                <PromotionList
                  key={item.hotId}
                  data={item}
                  shopId={shopId}
                  shopType={shopType}
                  deleteHot={onDeleteHot}
                />
              ))
            ) : (
              <p className="col-12 text-center">Không có dữ liệu</p>
            )}
          </div>

          {hotList && hotList.length > 0 && (
            <PaginationControl
              pageChanged={this.onSelectPage.bind(this)}
              totalItems={totalItems}
              currentPageIndex={currentPageIndex}
            />
          )}
        </div>
      </div>
    );
  }
}

const PromotionList = (props) => {
  const { data, shopId, shopType, deleteHot } = props;

  const [showDelete, setShowDelete] = useState(false);

  const onDeleteHot = () => {
    deleteHot(data.hotId).then(
      (res) => {
        if (res && res.content) {
          AddNotification(viVN.Success.Delete, NotificationMessageType.Success);
          history.push(`/m/shop/${shopType.toLocaleLowerCase()}/${shopId}`);
        } else {
          AddNotification(
            viVN.Errors[(res && res.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  return (
    <div className="promotion-item col-12">
      <div className="item">
        <img
          src={data.imageUrl || require("assets/images/cafe-splendora.png")}
          className="img-fluid w-100 img-promotion"
          alt={data.title}
        />
        <div className="item-content">
          <h5>{data.title}</h5>
          <span>
            <img
              src={require("assets/images/clock-15px.svg")}
              alt="Clock"
              className="img-fluid mr-1 mb-1"
            />{" "}
            Từ {data.fromDate} đến {data.toDate}
          </span>
          <div className="mt-2">
            <Link
              to={`/m/chinh-sua-khuyen-mai/${shopType.toLocaleLowerCase()}/${shopId}/${
                data.hotId
              }`}
              className="text-primary"
            >
              Chỉnh sửa
            </Link>
            <a className="text-danger ml-4" onClick={() => setShowDelete(true)}>
              Xóa
            </a>
          </div>
        </div>
      </div>

      <Modal
        show={showDelete}
        className="modal-delete-mobile"
        centered
        onHide={() => setShowDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Xóa tin khuyến mãi?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tin khuyến mãi sẽ không thể khôi phục sau khi xóa.
          <br></br>
          Bạn có chắc muốn xóa tin khuyến mãi này.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Hủy
          </Button>
          <Button variant="success" onClick={() => onDeleteHot()}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hotList: state.hot.hotList,
  totalItems: state.hot.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHotList: hotActions.GetHotList,
      onDeleteHot: hotActions.HotDelete,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopPromotionMobileView);
