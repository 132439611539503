//@ts-check
import React from 'react';
import Gallery from 'react-grid-gallery';
import * as CommonClass from 'utils/css-common-class';

/**
 * @param {{listImage:{
            src: string,
            thumbnail: string,
        }[]}} [props]
*/
function RenderFooterDetailView(props) {
    const listImage = props.listImage;
    listImage.map((imageObject) => {
        imageObject['thumbnailWidth'] = 320;
        imageObject['thumbnailHeight'] = 240;
    })
    return (<div hidden={props.listImage.length === 0}>
        <h3 className={`title-sm mt-4 ${CommonClass.TextSizeSubTitle}`}>Hình ảnh</h3>
        <Gallery images={props.listImage} backdropClosesModal={true} />
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>)
}

export default RenderFooterDetailView