import { Map } from "immutable";
import { JwtHelper } from "utils/jwt-helper";
import {
  TokenKey,
  createFormData,
  setCookies,
  removeCookies,
  getCookies,
} from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";
import { createSaveLoading } from "core/app.store";

const service = new Service();
/** Action Types */
const AUTHLOGIN = "AUTH/LOGIN";
const AUTHLOGOUT = "AUTH/LOGOUT";

/** Actions */
export const createSaveAuth = () => ({
  type: AUTHLOGIN,
});
export const createRemoveAuth = () => ({
  type: AUTHLOGOUT,
});

/** Action creators */
export const Login = (data) => {
  return (dispatch) => {
    dispatch(createSaveLoading(true));
    return service
      .post(ApiUrl.Login, createFormData(data, "", new FormData(), false))
      .then(
        (res) => {
          const UserInfo = JwtHelper.decodeToken(res.content.jwt);

          setCookies(TokenKey.AuthToken, res.content.jwt);
          setCookies(TokenKey.AvatarUrl, res.content.avatarUrl);
          setCookies(
            TokenKey.Location,
            JSON.stringify({
              lat: res.content.latitude,
              lng: res.content.longitude,
            })
          );
          setCookies(
            TokenKey.Project,
            (UserInfo && UserInfo.clientId) || data.clientId
          );

          dispatch(createSaveAuth());
          dispatch(createSaveLoading(false));
          return res;
        },
        (err) => {
          dispatch(createSaveLoading(false));
          throw err;
        }
      );
  };
};

export const Logout = () => {
  return (dispatch) => {
    removeCookies(TokenKey.AuthToken);
    removeCookies(TokenKey.AvatarUrl);
    removeCookies(TokenKey.Location);

    dispatch(createRemoveAuth());
  };
};

// Initial state
export const initialState = Map({
  isLoggedIn: getCookies(TokenKey.AuthToken) ? true : false,
}).toJS();

/** Reducers */
export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHLOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case AUTHLOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
