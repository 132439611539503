/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import * as lendingEquipmentActions from "redux/stores/lending-equipment/lending-equipment.store";
import * as appActions from "core/app.store";
import {
  ConvertNumberToString,
  convertTextareaToHtml,
} from "utils/configuration";
import { history } from "redux/store";

import "../lending-equipment-mobile.scss";

class LendingEquipmentDetailMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,

      quantity: 1,
      product: null,
      productId: this.props.match.params.equipmentId,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.equipmentId) {
      this.onGetProductDetail(this.props.match.params.equipmentId);
    }
  }

  onGetProductDetail = (productId) => {
    this.props.showLoading(true);
    this.props.onGetProductDetail(productId).then(
      (res) => {
        this.setState({
          product: res.content,
        });
        this.props.showLoading(false);
      },
      (err) => {
        this.props.showLoading(false);
      }
    );
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const settingsSlide = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const { quantity, product, productId } = this.state;
    const { isLoggedIn } = this.props;

    return (
      <div className="lending-equipment-detail-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row no-gutters align-items-center">
              <div className="col-1">
                <Link to="/m/cho-thue-muon-do">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
              </div>
              <div className="col-11 text-center">
                <div className="header-title text-center text-truncate">
                  {product && product.productName}
                </div>
              </div>
            </div>
          </div>
        </header>

        {product && (
          <div className="p-3">
            <div className="row lending-equipment-detail-content">
              <div className="col-12">
                <h2>{product.productName}</h2>
              </div>

              <div className="col-12">
                <Slider {...settingsSlide} className="slider-one">
                  {product.images && product.images.length > 0 && (
                    product.images.map((image) => (
                      <div key={image.fileId}>
                        <img
                          src={image.fileUrl}
                          alt={image.fileName}
                          className="w-100 img-fluid"
                        />
                      </div>
                    ))
                  )}
                </Slider>

                {
                  product && product.images && product.images.length <= 0 && product.productImage && (
                    <div className="h-100">
                      <img src={product.productImage} alt={product.productName} className="w-100 h-100 img-fluid object-fit-cover" />
                    </div>
                  )}
              </div>
              <div className="col-12">
                <h6 className="price-by-hours mb-1 mt-3 font-weight-normal">
                  {ConvertNumberToString(product.hourPrice || 0)}đ
                  <span>/giờ</span>
                </h6>
                <h6 className="price-by-day font-weight-normal">
                  {ConvertNumberToString(product.dayPrice || 0)}đ
                  <span>/ngày</span>
                </h6>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: convertTextareaToHtml(product.description),
                  }}
                ></p>

                <h5 className="mb-3 link-policy">
                  <Link to="/m/chinh-sach-cho-thue-muon-do">
                    Chính sách thuê đồ{" "}
                    <img
                      src={require("/assets/images/long-arrow-alt-right-blue.svg")}
                      alt="long-arrow-alt-right-blue"
                    />
                  </Link>
                </h5>

                <hr></hr>

                <div className="row mt-2">
                  <div className="col-12">
                    <span className="quantity-title">Số lượng:</span>
                  </div>
                  <div className="col-12">
                    <div className="quantity mt-1">
                      <button
                        className="minus"
                        onClick={() => {
                          this.setState({
                            quantity: quantity > 1 ? quantity - 1 : quantity,
                          });
                        }}
                      >
                        <img
                          src={require("/assets/images/minus-black.svg")}
                          alt="Minus"
                        />
                      </button>
                      <div className="quantity-number">
                        <input
                          type="text"
                          value={quantity}
                          onChange={(event) =>
                            this.setState({
                              quantity:
                                event.target.value && event.target.value > 1
                                  ? parseInt(
                                    event.target.value.replace(/[^\d]/, "")
                                  )
                                  : 1,
                            })
                          }
                          disabled={!isLoggedIn}
                        />
                      </div>
                      <button
                        className="plus"
                        onClick={() => {
                          {
                            this.setState({
                              quantity: quantity + 1,
                            });
                          }
                        }}
                      >
                        <img
                          src={require("/assets/images/plus-black.svg")}
                          alt="Plus"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                {product.quantityAvailable > 0 ? (
                  <Link
                    to={`/m/dang-ky-thue-do/${product.productId}?quantity=${quantity}`}
                    className={`btn btn-success mt-3 w-100 text-uppercase rounded-pill ${!isLoggedIn ? "disabled" : ""
                      }`}
                  >
                    Đăng ký ngay
                  </Link>
                ) : (
                    <button
                      type="button"
                      className="btn btn-secondary mt-3 w-100 text-uppercase"
                      disabled
                    >
                      Hết hàng
                    </button>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProductDetail: lendingEquipmentActions.GetProductDetail,
      onAddOrUpdateProduct: lendingEquipmentActions.AddOrUpdateProduct,
      onGetContentByKeyword: lendingEquipmentActions.GetContentByKeyword,
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LendingEquipmentDetailMobileView);
