//@ts-check
import React, { useEffect, useState } from "react";
import GoogleMapMobile from '../../gmap/googlemap.view';
import Slider from "react-slick";
import ItemOfSlider from '../child-component/item-of-list-shop-slide-mobile.view';
import * as PageUrls from '../../PageUrls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiseStore from '../../../redux/stores/utillities/utilitis-shop.store';
import DefaultMarkerIcon from 'assets/images/processing.svg';

/**
 * @param {{GetShopList:Function,restaurantList:{
    * address: string,
    logoUrl: string,
    lat: number,
    lng: number,
    id: number,
    name: string,}[]}} [props]
   */
function RestaurantHomePageMobile(props) {
    const shopList = props.restaurantList;
    const pageSize = 100;
    const pageIndex = 1;
    const [listMarkerCustom, setListMarkerCustom] = useState({
        iconUrl: DefaultMarkerIcon,
        listPosition: []
    });
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.3,
        slidesToScroll: 2,
        arrows: false,
    }

    useEffect(() => {
        const newListMarker = [];
        props.restaurantList.map(shop => newListMarker.push({
            lat: shop.lat,
            lng: shop.lng,
        }))
        setListMarkerCustom({
            iconUrl: DefaultMarkerIcon,
            listPosition: newListMarker,
        })
    }, [props.restaurantList])

    useEffect(() => {
        props.GetShopList(pageIndex, pageSize, UtilitiseStore.RESTAURANKEY)
    }, [])

    return (
        <div>
            <GoogleMapMobile listMarkerCustom={listMarkerCustom} />
            <div className="utilities-recovery-mobile p-3">
                <h2 className="text-center">Khám phá</h2>

                <div className="row">
                    <div className="col-12">
                        <Slider {...settings}>
                            {
                                shopList.map((data, index) => <ItemOfSlider detailUrl={PageUrls.UtilitiesRestaurantDetailMobile} key={index} data={data} />)
                            }
                        </Slider>
                        <span hidden={!(shopList.length === 0)}>Không có dữ liệu để hiển thị</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    restaurantList: state.utilitiShop.restaurantList.listShop,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopList: UtilitiseStore.GetShopList,
},
    dispatch
)
export default connect(mapStateToProps, mapDispatchToProps)(RestaurantHomePageMobile)