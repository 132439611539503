//@ts-check
import React, { useEffect } from 'react';
import RenderFooterDetailView from '../child-component/shop-detail-page-child/footer-detail/footer-detail.view';
import RenderHeaderDetailView from '../child-component/shop-detail-page-child/header-detail/header-detail.view';
import UtilitiesListOther from '../child-component/utilities-list-other/utilities-list-other.view';
import * as PageUrls from '../../PageUrls';
import { connect } from 'react-redux';
import * as UtilitiShopStore from 'redux/stores/utillities/utilitis-shop.store';
import { bindActionCreators } from 'redux';

/**
 * @param {{shopDetail:{
 *  category: string,
 id: number,
 name: string,
 faceBook: string,
 introduce: string,
 timeOpen: string,
 address: string,
 lat: number,
 lng: number,
 phone: string,
 imagesSlider: Array,
 listImage: Array,},isLoggedIn:boolean,GetShopDetail:Function,match:{params:{id:number}},
 PostRegisterCart:Function,}} [props]
*/
function UtilitiesCafeDetailPageDesktop(props) {

    useEffect(() => {
        props.GetShopDetail(props.match.params.id)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <div>
            <RenderHeaderDetailView data={props.shopDetail} titleShopType="Cafe" comeBackLink={PageUrls.UtilitiesCafeListPageDeskTop} />
            <RenderFooterDetailView listImage={props.shopDetail.listImage} />
            <UtilitiesListOther currentTypeUrl={PageUrls.UtilitiesCafeHomeDeskTop} />
        </div>
    )
}

const mapStateToProps = state => ({
    shopDetail: state.utilitiShop.shopDetail,
    isLoggedIn: state.auth.isLoggedIn
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiShopStore.GetShopDetail,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesCafeDetailPageDesktop)