import '../management-desktop.scss';

import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LegalSectionDesktopView from '../legal/legal-section-desktop.view';
import BuildingManagementDesktopView from '../buiding-management/buiding-management-desktop.view';
import DeclarationOfTemporaryResidenceForm from '../management-info/staying/staying-desktop.form';
import ProcedureSectionDesktopView from '../procedure/procedure-section-desktop.view';
import ScheduleListDesktopView from '../schedule/schedule-list-desktop.view';
import SecurityCameraSectionDesktopView from '../security-camera/security-camera-section-desktop.view';
import MemberSectionDesktopView from '../member/member-section-desktop.view';

export default class ManagementInfoDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      birthday: new Date(),
      showForm: true,
    };
  }

  onChangeBirthday = date => {
    this.setState({
      birthday: date
    });
  };

  handleChangeStartDate = date => {
    this.setState({
      startDate: date
    });
  };

  handleChangeEndDate = date => {
    this.setState({
      endDate: date
    });
  };

  render() {
    const { startDate, endDate, birthday, showForm } = this.state;
    return (
      <div className="management-desktop">
        <BuildingManagementDesktopView />

        <MemberSectionDesktopView />

        <ScheduleListDesktopView />

        <LegalSectionDesktopView />

        <ProcedureSectionDesktopView />

        <div className="khai-bao-tam-tru mt-4">
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div className="row no-gutters">
                  <div className="col-3">
                    <h2 className="title-lg mt-4">Khai báo tạm trú</h2>
                  </div>
                  <div className="col-9 position-relative text-right pt-3">
                    {showForm ?
                      <a onClick={() => this.setState({ showForm: false })}>Ẩn form</a>
                      :
                      <a onClick={() => this.setState({ showForm: true })}>Hiện form</a>
                    }
                    <div className="management-border w-100"></div>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <DeclarationOfTemporaryResidenceForm />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>

        <SecurityCameraSectionDesktopView />
      </div>
    );
  }
}