//@ts-check
import React, { useEffect } from 'react';
import RenderHeaderDetailView from '../childs/header-detail/header-detail.view';
import RenderFooterDetailView from '../childs/footer-detail/footer-detail.view';
import RenderBodyDetailTennisSoccerDesktop from '../childs/body-detail-tennis-soccer/body-detail-tennis-soccer.view';
import UtilitiesListOther from '../../child-component/utilities-list-other/utilities-list-other.view';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiesStore from '../../../../redux/stores/utillities/utilitis-shop.store';
import * as PageUrls from '../../../PageUrls';

/**
 * @param {{
 * shopDetail:any,
 * slotList:{slotName: string,
 * slotId: number,
 * isFull: boolean}[],
 * match:{path:string,params:{id:number}},
 * GetListTennis:(index:number,size:number)=>any,
 * GetShopDetail:(id:number,date:Date)=>any,
 * PostRegisterUseTennis?:(params:FormData)=>boolean,
 * }} [props]
*/
function TennisDetailViewDesktop(props) {

    /**
     * @param {{shopId: number,phoneNumber: string,customerName: string,slotId: number,date: string}} paramsObject
    */
    const registerFunction = (paramsObject) => {
        const params = new FormData();
        params.append('data[register_name]', paramsObject.customerName)
        params.append('data[tennis_id]', String(paramsObject.shopId))
        params.append('data[slot_id]', String(paramsObject.slotId))
        params.append('data[register_date]', paramsObject.date)
        params.append('data[mobile_number]', paramsObject.phoneNumber)
        return props.PostRegisterUseTennis(params)
    }

    useEffect(() => {
        props.GetShopDetail(props.match.params.id, new Date())
    }, [])

    return (<div>
        <RenderHeaderDetailView listPageUrl={PageUrls.UtilitiesTennisDeskTop} data={props.shopDetail} />
        <RenderBodyDetailTennisSoccerDesktop slotList={props.slotList} handleGetCalendarByDate={props.GetShopDetail} registerFunction={registerFunction} shopId={props.shopDetail.id} isTennis={true} />
        <RenderFooterDetailView listImage={props.shopDetail.listImage} />
        <UtilitiesListOther currentTypeUrl={PageUrls.UtilitiesSportHomeDesktop} />
    </div>)
}

const mapStateToProps = state => ({
    slotList: state.utilitiShop.shopDetailWithCalendar.slotList,
    shopDetail: state.utilitiShop.shopDetailWithCalendar,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiesStore.GetTennisDetail,
    PostRegisterUseTennis: UtilitiesStore.PostRegisterUseTennis,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TennisDetailViewDesktop)