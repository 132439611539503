import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as buidingManagementActions from 'redux/stores/management/building-management.store';
import * as viVN from 'translation/vi-VN.json';
import { history } from 'redux/store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import { JwtHelper } from 'utils/jwt-helper';

class BuildingManagementDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: JwtHelper.currentProject() || 1
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetBuildingManagementDetail(this.state.project);
    }

    onGetBuildingManagementDetail = (project) => {
        this.props.getBuildingManagementDetail(project).then(res => {

        }, err => { 
        })
    }
    render() {
        const { buildingManagementModel } = this.props;
        return (
            <div className="building-management">
                <h2 className="title-lg">Thông tin BQL</h2>
                <h4 className="title-sm mt-3">Công ty quản lý vận hành</h4>
                {buildingManagementModel && <div className="company-info">
                    <div className="row no-gutters p-2">
                        <div className="col-2">Tên công ty:</div>
                        <div className="col-10">
                            {buildingManagementModel.companyName}
                        </div>
                        <div className="col-2">Địa chỉ:</div>
                        <div className="col-10">
                            {buildingManagementModel.address}
                        </div>
                        <div className="col-2">Hotline:</div>
                        <div className="col-10">
                            <strong>{buildingManagementModel.hotline}</strong>
                        </div>
                        <div className="col-2">Email BQL:</div>
                        <div className="col-10">{buildingManagementModel.email}</div>
                        <div className="col-2">Website:</div>
                        <div className="col-10"><a href={buildingManagementModel.website} target="_blank">{buildingManagementModel.website}</a></div>
                    </div>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    buildingManagementModel: state.buildingManagement.buildingManagementModel
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBuildingManagementDetail: buidingManagementActions.GetBuildingManagementDetail,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingManagementDesktopView);
