import { Map } from 'immutable';
import { createSaveLoading } from 'core/app.store';
import { createFormData, AppKey, ERRORTYPEEMPTYDATAERROR } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';
import Service from 'services/api.service';

const SOLVEDKEY = "DA_XU_LY";
const NOTRESOLVEDKEY = "DANG_XU_LY";

const service = new Service();
const INITLOCATION = "INCIDENTSAREA/LOCATION";
const INITINCIDENTAREAS = "INCIDENTSAREA/LIST";
const INITINCIDENAREASNEW = "INCIDENTSAREA/NEWS";
const INITINCIDENAREASDETAIL = "INCIDENTSAREA/DETAIL";
const INITINCIDENTAREAMOBILE = 'INCIDENTSAREA/MOBILELIST';

const InitIncidentAreaDetail = data => ({
    type: INITINCIDENAREASDETAIL, data: data
})

const InitIncidentAreasNew = data => ({
    type: INITINCIDENAREASNEW, data: data
})

const InitIncidentAreas = data => ({
    type: INITINCIDENTAREAS, data: data
})

const InitLocation = data => ({
    type: INITLOCATION, data: data
})

const InitIncidentAreaMobile = data => ({
    type: INITINCIDENTAREAMOBILE, data: data
})

export const GetIncidentAreasMobile = (pageIndex = 1, size = 100, status = null) => {
    const fullData = 1;
    const ExchangeData = ExchangeIncidentAreasMobile;
    const InitFunction = InitIncidentAreaMobile;
    let solved = NOTRESOLVEDKEY;
    if (status) solved = SOLVEDKEY;
    return GetIncidentAreasCall(pageIndex, size, fullData, ExchangeData, solved, InitFunction)
}

export const GetIncidentAreasDesktop = (pageIndex = 1, size = 100, status = null) => {
    const fullData = 0;
    const ExchangeData = ExchangeIncidentAreasDesktop;
    const InitFunction = InitIncidentAreas;
    let solved = NOTRESOLVEDKEY;
    if (status) solved = SOLVEDKEY;
    return GetIncidentAreasCall(pageIndex, size, fullData, ExchangeData, solved, InitFunction)
}

export const GetIncidentAreasDesktopNew = (size = 3, pageIndex = 1) => {
    const status = null;
    const fullData = 0;
    const ExchangeData = ExchangeIncidentAreasDesktop;
    const InitFunction = InitIncidentAreasNew;
    return GetIncidentAreasCall(pageIndex, size, fullData, ExchangeData, status, InitFunction)
}

export const GetAllLocationIncidents = (pageIndex = 1, size = 100, fullData = 2, ExchangeData = ExchangeIncidentLocationAreas, InitFunction = InitLocation) => {
    return GetIncidentAreasCall(pageIndex, size, fullData, ExchangeData, status, InitFunction)
}

export const GetIncidentAreasCall = (pageIndex, size, fullData, ExchangeData, status, InitFunction) => {
    return async (dispatch) => {
        // dispatch(createSaveLoading(true))
        let rawData = null;
        let formData = new FormData();
        formData.append(`filter[orderBy]`, "update_date");
        formData.append(`filter[orderType]`, "DESC");
        formData.append(`filter[status]`, status);
        formData.append('fullData', fullData);
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.GetIncidentAreas, payLoad).then(res => {
            if (res.errorType === ERRORTYPEEMPTYDATAERROR) return false
            rawData = res.content;
        }).catch(err => { })
        dispatch(InitFunction(ExchangeData(rawData)))
    }
}

export const GetIncidentsAreaDetail = (incidentAreaId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true))
        let rawData = null;
        let formData = new FormData();
        formData.append('feedbackId', incidentAreaId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.GetIncidentAreasDetail, payLoad).then(res => {
            if (res.errorType === ERRORTYPEEMPTYDATAERROR) return false
            rawData = res.content;
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitIncidentAreaDetail(ExchangeIncidentAreaDetail(rawData)))
    }
}

const initialState = Map({
    allLocation: [],
    incidentAreas: {},
    incidentNews: {},
    incidentDetail: {},
    incidentAreasMobile: {}
}).toJS()

export default function IncidentsAreaReducer(state = initialState, action) {
    switch (action.type) {
        case INITINCIDENTAREAMOBILE:
            return {
                ...state,
                incidentAreasMobile: action.data
            }
        case INITINCIDENAREASDETAIL:
            return {
                ...state,
                incidentDetail: action.data
            }
        case INITINCIDENAREASNEW:
            return {
                ...state,
                incidentNews: action.data
            }
        case INITINCIDENTAREAS:
            return {
                ...state,
                incidentAreas: action.data
            }
        case INITLOCATION:
            return {
                ...state,
                allLocation: action.data
            }
        default:
            return state
    }
}


const ExchangeIncidentLocationAreas = rawData => {
    const standardData = [];
    if (rawData && rawData.items) {
        rawData.items.map((data) => {
            standardData.push({
                lat: Number(data.latitude) || 0,
                lng: Number(data.longitude) || 0,
                solved: (data.status === NOTRESOLVEDKEY) ? false : true
            })
        })
    }
    return standardData
}

const ExchangeIncidentAreasDesktop = rawData => {
    const standardData = {
        haveData: false,
        listData: []
    };
    if (rawData && rawData.items) {
        standardData.haveData = true;
        standardData.total = rawData.totalItemCount;
        rawData.items.map((data) => {
            standardData.listData.push({
                id: data.feedbackId,
                content: data.content,
                solved: (data.status === NOTRESOLVEDKEY) ? false : true,
                time: data.createDate,
                url: (data.feedbackFile && data.feedbackFile.fileUrl) ? data.feedbackFile.fileUrl : "",
            })
        })
    }
    return standardData;
}

const ExchangeIncidentAreasMobile = rawData => {
    const standardData = {
        haveData: false,
        listData: []
    };
    if (rawData && rawData.items) {
        standardData.haveData = true;
        standardData.total = rawData.totalItemCount;
        rawData.items.map((data) => {
            standardData.listData.push({
                lat: Number(data.latitude) || 0,
                lng: Number(data.longitude) || 0,
                id: data.feedbackId,
                content: data.content,
                solved: (data.status === NOTRESOLVEDKEY) ? false : true,
                time: data.createDate,
                url: (data.feedbackFile && data.feedbackFile.fileUrl) ? data.feedbackFile.fileUrl : "",
            })
        })
    }
    return standardData;
}

const ExchangeIncidentAreaDetail = rawData => {
    if (rawData) return {
        content: rawData.content,
        feedbackId: rawData.feedbackId,
        status: (rawData.status === NOTRESOLVEDKEY) ? false : true,
        address: rawData.address,
        lat: Number(rawData.latitude),
        lng: Number(rawData.longitude),
        createDate: rawData.createDate,
        files: rawData.feedbackFile
    }; else return {}
}