//@ts-check
import React from 'react';
import { Link } from 'react-router-dom';
import { SetDefaultErrorImage } from 'utils/configuration'

/**@param {{listData:{logoUrl:string,name:string,address:string,id:number,type:string}[],detailUrl:string}} [props]*/

function SportListView(props) {
    return (
        <div className="row">
            {
                props.listData.map((data, index) => <Link key={index} to={props.detailUrl + data.id} style={{ textDecoration: "none" }} className="d-block col-6">
                    <div className="utilities-list-item">
                        <img src={data.logoUrl} alt={data.name} onError={SetDefaultErrorImage} />
                        <div className="utilities-list-body bg-white p-3">
                            <h5 className="text-black">
                                {data.name}
                            </h5>
                            <span className="d-block text-truncate">
                                <img src={require('assets/images/place-gray.svg')} alt="place" className="img-fluid" />{data.address}
                            </span>
                        </div>
                    </div>
                </Link>)
            }
            {
                (props.listData.length === 0) && <div className='col-12 text-center'>Không có dữ liệu để hiển thị</div>
            }
        </div>

    )
}

export default SportListView