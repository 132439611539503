import React, { Component } from 'react';
import '../not-found-desktop/not-found-desktop.scss';

class CrashErrorDesktopView extends Component {
    render() {
        return (
            <div className="not-found-desktop">
                <div className="not-found-content">
                    <h1>Oops !</h1>
                    <p>
                        WE are sorry, but something went wrong while displaying this webpage
                    </p>
                    <a href="/" className="btn btn-success rounded-pill">Trang chủ</a>
                </div>
            </div>
        );
    }
}

export default CrashErrorDesktopView;