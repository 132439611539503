import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../management-desktop.scss';
import * as procedureActions from 'redux/stores/management/procedure.store';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

class ProcedureCategoryDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProcedureCategories(this.state.currentPageIndex, 10, {});
  }

  onGetProcedureCategories = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.setState({ isLoading: true });
    this.props.getProcedureCategories(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => { 
        this.setState({ isLoading: false });
      }
    );
  };

  render() {
    const { procedureCategories } = this.props;

    return (
      <div className="management-desktop">
        <h2 className="title-lg mb-0">Thủ tục BQL</h2>
        <hr className="mt-2" />

        <h4 className="title-sm mt-4">Văn bản, biểu mẫu quản lý</h4>

        <Table size="sm" className="tblProcedureCategory">
          <tbody>
            {
              procedureCategories.map(item => <tr key={item.categoryId}>
                <td className="pl-2">
                  <Link to={`/thu-tuc-bql/${item.categoryId}`}>
                    {item.categoryName}
                  </Link>
                </td>
                <td className="text-right pr-2">
                  <Link to={`/thu-tuc-bql/${item.categoryId}`}>
                    <img src={require("assets/images/keyboard-arrow-right.svg")} alt="Arrow right" />
                  </Link>
                </td>
              </tr>)
            }
            {!procedureCategories.length && <div className="col-12">Không có bản ghi</div>}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  procedureCategories: state.procedure.procedureCategories
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProcedureCategories: procedureActions.GetProcedureCategories,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureCategoryDesktopView);