/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dropdown, Modal, Button } from "react-bootstrap";
import ShowMoreText from "react-show-more-text";
import moment from "moment";

import * as appActions from "core/app.store";
import * as clubActions from "redux/stores/club/club.store";

import GolfAttachmentDesktopView from "./golf-attachment.view.jsx";
import GolfCommentDesktopView from "./golf-comment.view.jsx";
import EditGolfDesktopView from "../golf-actions/edit-golf-desktop.view.jsx";

import {
  SetDefaultErrorImage,
  NotificationMessageType,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";

import "../../club.scss";

moment.locale("vi");

class GolfItemDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isDelete: false,

      currentUser: JwtHelper.currentUser(),
      selectedId: null,
    };
  }

  onToggleEditPopup = (isOpen, selectedId) => {
    this.setState({
      isEdit: isOpen,
      selectedId: selectedId,
    });
  };

  onToggleDeletePopup = (isOpen, selectedId) => {
    this.setState({
      isDelete: isOpen,
      selectedId: selectedId,
    });
  };

  onDeletePost = (isConfirm) => {
    if (isConfirm && this.state.selectedId) {
      this.props.showLoading(true);
      this.props.postDelete(this.state.selectedId).then(
        (res) => {
          this.onToggleDeletePopup(false);
          AddNotification(
            "Xoá bài viết thành công!",
            NotificationMessageType.Success
          );
          this.props.callBack && this.props.callBack();
        },
        (err) => {
          this.props.showLoading(false);
          this.onToggleDeletePopup(false);
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    }
  };

  render() {
    const { loggingIn, listDataPost } = this.props;
    const { isEdit, isDelete, currentUser, selectedId } = this.state;

    return (
      <div>
        {listDataPost &&
          listDataPost.length > 0 &&
          listDataPost.map((item, index) => (
            <div key={"itemGolf" + index} className="club-post-list-container">
              <div className="item bg-white p-3">
                <div className="row no-gutters">
                  <div className="col-1">
                    <img
                      src={
                        item.profileUrl || require("assets/images/29-12.png")
                      }
                      onError={SetDefaultErrorImage}
                      className="img-fluid img-avatar radius-50"
                      alt="Avatar"
                    />
                  </div>
                  <div className="col-10 pl-3">
                    <h5 className="full-name mb-0 mt-1">
                      <a>{item.customerName}</a>
                    </h5>
                    <span className="date-time">
                      {moment(new Date(item.createDate)).fromNow()}
                    </span>
                  </div>
                  <div className="col-1 text-right">
                    {loggingIn &&
                      currentUser &&
                      currentUser.customerId == item.customerId && (
                        <Dropdown alignRight>
                          <Dropdown.Toggle id="dropdown-basic">
                            <img
                              src={require("assets/images/more.svg")}
                              className="img-fluid img-more"
                              alt="more"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                this.onToggleEditPopup(true, item.clubId)
                              }
                            >
                              <img
                                src={require("assets/images/pen.svg")}
                                className="img-fluid img-pen"
                                alt="pen"
                              />
                              <span>Chỉnh sửa bài viết</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                this.onToggleDeletePopup(true, item.clubId)
                              }
                            >
                              <img
                                src={require("assets/images/delete.svg")}
                                className="img-fluid img-delete"
                                alt="pen"
                              />
                              <span>Xóa</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                  </div>
                </div>
                <div className="description">
                  <ShowMoreText
                    lines={8}
                    more="Xem thêm"
                    less="Ẩn bớt"
                    expanded={false}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    ></p>
                  </ShowMoreText>
                </div>
                {/* Render images */}
                <GolfAttachmentDesktopView
                  attachments={
                    item.clubFile && item.clubFile.length > 0
                      ? item.clubFile
                      : []
                  }
                />

                <div className="comments">
                  {/* Comments */}
                  <GolfCommentDesktopView postId={item.clubId} />
                </div>
              </div>
            </div>
          ))}
        {isEdit && selectedId && (
          <Modal
            show={isEdit}
            className="modal-edit"
            centered
            size="lg"
            onHide={() => this.setState({ isEdit: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Chỉnh sửa bài viết</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditGolfDesktopView
                postId={selectedId}
                onToggleEditPopup={this.onToggleEditPopup.bind(this)}
                callBack={this.props.callBack}
              />
            </Modal.Body>
          </Modal>
        )}

        {isDelete && selectedId && (
          <Modal
            show={isDelete}
            className="modal-delete"
            centered
            onHide={() => this.onToggleDeletePopup(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Xóa bài viết?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Bài viết sẽ không thể khôi phục sau khi xóa.
              <br />
              Bạn có chắc muốn xóa bài viết này.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.onToggleDeletePopup(false)}
              >
                Hủy
              </Button>
              <Button variant="success" onClick={() => this.onDeletePost(true)}>
                Xóa
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      postDelete: clubActions.PostDelete,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GolfItemDesktopView);
