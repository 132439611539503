/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React from "react";
import { NavLink, Link } from "react-router-dom";
import {
  getCookies,
  TokenKey,
  SetDefaultErrorImage,
} from "utils/configuration";

import "../side-drawer/side-drawer.scss";

const sideDrawer = (props) => {
  const { userInfo, loggedIn } = props;

  let drawerClasses = "side-drawer";
  document.body.classList.remove("stopScroll");

  if (props.show) {
    drawerClasses = "side-drawer open";
    document.body.classList.add("stopScroll");
  }

  return (
    <nav className={drawerClasses}>
      <div className="mb-cancel-menu">
        <img
          src={require("assets/images/cancel-white.png")}
          onClick={props.click}
          alt="Cancel menu"
        />
      </div>

      <ul className="mb-first">
        {loggedIn && userInfo && (
          <div>
            <li className="mb-user-info">
              <Link to="/m/thong-tin-ca-nhan">
                <div className="row no-gutters">
                  <div className="col-2">
                    <img
                      src={
                        getCookies(TokenKey.AvatarUrl) ||
                        require("assets/images/user-filled.svg")
                      }
                      onError={SetDefaultErrorImage}
                      alt="Avatar"
                      className="img-fluid rounded-circle img-user-filled"
                    />
                  </div>
                  <div
                    className={
                      "col-9 pl-2" + (userInfo.houseNumber ? "" : " m-auto")
                    }
                  >
                    <h5>{userInfo.customerName}</h5>
                    {userInfo.houseNumber && (
                      <span>Căn hộ: {userInfo.houseNumber}</span>
                    )}
                  </div>
                  <div className="col-1">
                    <NavLink
                      to="/m/thong-tin-ca-nhan"
                      className="mb-arrow-right"
                    >
                      <img
                        src={require("assets/images/keyboard-arrow-right.svg")}
                        alt="keyboard-arrow-right"
                      />
                    </NavLink>
                  </div>
                </div>
              </Link>
            </li>
            <hr />
          </div>
        )}

        <li onClick={props.click}>
          <img
            src={require("assets/images/hot.svg")}
            alt="HOT"
            className="mb-icon"
          />
          <NavLink to="/m/hot" exact className="mb-text">
            HOT!
          </NavLink>
          <NavLink to="/m/hot" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        <li onClick={props.click}>
          <img
            src={require("assets/images/thanh-toan.svg")}
            alt="Thanh toán"
            className="mb-icon"
          />
          <NavLink to="/m/thanh-toan/tien-dien" exact className="mb-text">
            Thanh toán
          </NavLink>
          <NavLink to="/m/thanh-toan/tien-dien" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        <li onClick={props.click}>
          <img
            src={require("assets/images/tien-ich-filled.svg")}
            alt="Tiện ích"
            className="mb-icon"
          />
          <NavLink to="/m/tien-ich/cafe" className="mb-text">
            Tiện ích
          </NavLink>
          <NavLink to="/m/tien-ich/cafe" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        <li onClick={props.click}>
          <img
            src={require("assets/images/ban-quan-ly-filled-white.svg")}
            alt="Ban quản lý"
            className="mb-icon"
          />
          <NavLink to="/m/thong-tin-bql" className="mb-text">
            Ban quản lý
          </NavLink>
          <NavLink to="/m/thong-tin-bql" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        <li onClick={props.click}>
          <img
            src={require("assets/images/gui-y-kien-filled.svg")}
            alt="Gửi ý kiến"
            className="mb-icon"
          />
          <NavLink to="/m/gui-y-kien" className="mb-text">
            Gửi ý kiến
          </NavLink>
          <NavLink to="/m/gui-y-kien" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        <li onClick={props.click}>
          <img
            src={require("assets/images/rao-vat.svg")}
            alt="Chợ làng"
            className="mb-icon"
          />
          <NavLink to="/m/rao-vat" className="mb-text">
            Chợ làng
          </NavLink>
          <NavLink to="/m/rao-vat" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        <hr />

        <li onClick={props.click}>
          <img
            src={require("assets/images/seting-filled.svg")}
            alt="Cài đặt"
            className="mb-icon"
          />
          <NavLink to="/m/cai-dat" className="mb-text">
            Cài đặt
          </NavLink>
          <NavLink to="/m/cai-dat" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        {loggedIn ? (
          <li onClick={props.logout}>
            <img
              src={require("assets/images/off-button-filled.svg")}
              alt="Đăng xuất"
              className="mb-icon"
            />
            <a className="mb-text">Đăng xuất</a>
            <a className="mb-arrow-right">
              <img
                src={require("assets/images/keyboard-arrow-right.svg")}
                alt="keyboard-arrow-right"
              />
            </a>
          </li>
        ) : (
          <li>
            <img
              src={require("assets/images/log-in.svg")}
              alt="log-in"
              className="mb-icon"
            />
            <NavLink to="/m/dang-nhap" className="mb-text">
              Đăng nhập
            </NavLink>
            <NavLink to="/m/dang-nhap" className="mb-arrow-right">
              <img
                src={require("assets/images/keyboard-arrow-right.svg")}
                alt="keyboard-arrow-right"
              />
            </NavLink>
          </li>
        )}
      </ul>

      <ul className="mb-second">
        <hr />

        <li className="mt-3" onClick={props.click}>
          <img
            src={require("assets/images/project-info.svg")}
            alt="Thông tin về dự án"
            className="mb-icon"
          />
          <NavLink to="/m/thong-tin-ve-du-an" className="mb-text">
            Thông tin về dự án
          </NavLink>
          <NavLink to="/m/thong-tin-ve-du-an" className="mb-arrow-right">
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </NavLink>
        </li>

        <li onClick={props.click}>
          <img
            src={require("assets/images/ilang-info.svg")}
            alt="Về iLang"
            className="mb-icon"
          />
          <a href="http://acudtech.vn/" target="_blank" className="mb-text">
            Về iLang
          </a>
          <a
            href="http://acudtech.vn/"
            target="_blank"
            className="mb-arrow-right"
          >
            <img
              src={require("assets/images/keyboard-arrow-right.svg")}
              alt="keyboard-arrow-right"
            />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default sideDrawer;
