import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import './rent-to-market-mobile.scss';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as rentToMarketActions from 'redux/stores/rent-to-market/rent-to-market.store';
import AddRentToMarketMobileView from './actions/add-rent-to-market-mobile.view';
import RentToMarketItemMobileView from './childs/rent-to-market-item-mobile.view';
import PaginationControl from 'components/pagination/pagination.view';
import { AssistType } from 'utils/configuration';

class RentToMarketMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageIndex: 1,
            isToggleAdding: false
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetRentToMarketList();
    }

    onGetRentToMarketList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = { category_type: AssistType.RentToMarket }) => {
        this.setState({ isLoading: true });
        this.props.getRentToMarketList(pageIndex, size, filterObjs).then(
            res => {
                this.setState({ isLoading: false });
            },
            err => { 
                this.setState({ isLoading: false });
            }
        );
    };

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetRentToMarketList(pageNumber);
        })
    }

    onToggleAddingState = () => {
        this.setState({
            isToggleAdding: !this.state.isToggleAdding
        })
    }

    render() {
        const { rentToMarketList, totalItems } = this.props;
        const { currentPageIndex, isToggleAdding } = this.state;
        return (
            <div className="rent-to-market-mobile p-3">
                <AddRentToMarketMobileView onToggleAddingState={this.onToggleAddingState.bind(this)} callBack={this.onGetRentToMarketList.bind(this)} />
                <RentToMarketItemMobileView match={this.props.match} rentToMarketList={rentToMarketList} isToggleAdding={isToggleAdding} callBack={this.onGetRentToMarketList.bind(this)} />
                <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
            </div>
        );
    }
    // render() {
    //     return (
    //         <div className="rent-to-market-mobile p-3">
    //             <div className="create-rent-to-market rounded p-3">
    //                 <h5>Tạo tin mới</h5>
    //                 <div className="form-group">
    //                     <input type="text" name="txtPhone" id="txtPhone" className="form-control" placeholder="Số điện thoại" />
    //                 </div>
    //                 <div className="form-group">
    //                     <textarea name="txtContent" id="txtContent" rows="5" className="form-control" placeholder="Nội dung"></textarea>
    //                 </div>
    //                 <div className="text-right">
    //                     <button className="btn btn-warning">Đăng tin</button>
    //                 </div>
    //             </div>

    //             <h2 className="mt-3">Tin mới</h2>

    //             <div className="rent-to-market-list">
    //                 <RenderRentToMarketItem />
    //                 <RenderRentToMarketItem />
    //                 <RenderRentToMarketItem />
    //             </div>
    //         </div>
    //     );
    // }
}

function RenderRentToMarketItem() {
    const [showDelete, setShowDelete] = useState(false);
    const handleClose = () => {
        setShowDelete(false);
    };

    return (
        <div className="rent-to-market-item rounded p-2">
            <div className="row no-gutters">
                <div className="col-1">
                    <img src={require('assets/images/29-01.png')} alt="Image" className="img-fluid img-avatar" />
                </div>
                <div className="col-10 pl-2">
                    <h5>Lý Thu Hà</h5>
                    <span className="datetime">1 phút</span>
                </div>
                <div className="col-1 text-right">
                    <button type="button" className="close" aria-label="Close" onClick={() => setShowDelete(true)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <div className="row no-gutters">
                <div className="col-1"></div>
                <div className="col-11 pl-2">
                    <p>
                        Mình ở S1 - 1506<br />
                        Mình cần 0,5kg ba chỉ, 2 quả dứa
                    </p>
                </div>
            </div>

            <div className="row no-gutters">
                <div className="col-1"></div>
                <div className="col-6 pl-2">
                    <a href="tel:0912345678">
                        <img src={require('assets/images/phone-call-round.svg')} alt="phone-call-round" className="img-fluid img-phone-call-round" /><span className="contact">Liên hệ</span>
                    </a>
                </div>
                <div className="col-5 text-right">
                    <Link to="/m/di-cho-thue/sua-tin">
                        <img src={require('assets/images/edit-blue.svg')} alt="edit-blue" className="img-fluid img-edit-blue" />
                    </Link>
                </div>
            </div>

            <Modal show={showDelete} className="modal-delete-mobile" centered onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Xóa tin?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tin này sẽ không thể khôi phục sau khi xóa.
                    <br />
                    Bạn có chắc muốn xóa tin này.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Hủy</Button>
                    <Button variant="success" onClick={handleClose}>Xóa</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    rentToMarketList: state.rentToMarket.rentToMarketList,
    totalItems: state.rentToMarket.totalItems
  })
  
  const mapDispatchToProps = dispatch => bindActionCreators({
    getRentToMarketList: rentToMarketActions.GetRentToMarketList,
  }, dispatch)
  
  export default connect(mapStateToProps, mapDispatchToProps)(RentToMarketMobileView)