import { NewGuid } from 'utils/configuration';

export const Procedures = [
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 1,
    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý quyền sử dụng đất',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 6,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý mua bán nhà',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 5,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý quyền sử dụng đất',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 4,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý thu mua vật tư',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 3,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý chuyển nhượng vợ',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 3,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý bán chồng',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 2,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý bán chồng',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 1,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý bán chồng',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 3,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý bán chồng',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 4,

    },
    {
        id: NewGuid(),
        name: 'Hồ sơ pháp lý bán chồng',
        url: '../../../assets/pdf/pdf-demo.pdf',
        category_id: 3,

    },
]

export const ProcedureCategoris = [
    {
        category_id: 1,
        category_name: 'Thủ tục đăng ký cải tạo, sửa chữa',
    },
    {
        category_id: 2,
        category_name: 'Thủ tục đăng ký thẻ',
    },
    {
        category_id: 3,
        category_name: 'Thủ tục cho thuê thang máy chở hàng',
    },
    {
        category_id: 4,
        category_name: 'Thủ tục ủy quyền',
    }
]