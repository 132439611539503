import '../../neighbourhoods-mobile.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as friendActions from '/redux/stores/friend/friend.store';
import AddFriendActionView from '../add-friend-action';
import AddNotification from 'components/react-notifications/react-notifications';
import * as viVN from 'translation/vi-VN.json';
import { history } from 'redux/store';
import { NotificationMessageType } from '/utils/configuration';
import { JwtHelper } from '/utils/jwt-helper';
import { Link } from 'react-router-dom';

class AddFriendDetailMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            friend: null,
            friendId: this.props.match.params.friendId,
            currentUser: JwtHelper.currentUser()
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetFriendDetail();
    }

    onGetFriendDetail = () => {
        this.props.getFriendDetail(this.state.currentUser.customerId, this.state.friendId).then(res => {
            if (res.content) {
                this.setState({
                    friend: res.content
                })
            } else {
                AddNotification(viVN.Errors[res.errorType], NotificationMessageType.Error);
                history.push('/lang-gieng-gan/ket-ban');
            }
        }, err => {
            console.log(err)
        })
    }

    render() {
        const { friend } = this.state;
        return (
            <div className="add-friend-detail-mobile">
                {friend && <div className="friend-info">
                    <div className="cover">
                        <img src={friend.coverUrl || require('assets/images/splendora-cover.png')} alt={friend.customerName} className="img-fluid img-splendora-cover" />
                        <Link to="/m/lang-gieng-gan/cau-lac-bo/ket-ban">
                            <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-arrow-back" />
                        </Link>
                    </div>

                    <div className="info">
                        <img src={require("assets/images/chi-hang.jpg")} alt="Chi Hang" className="img-fluid img-add-friend-detail rounded-circle" />
                        <h4>{friend.customerName}</h4>
                        <h5>Căn hộ: {friend.apartmentName}</h5>
                        <span>
                            Quê quán:  {friend.provine}<br />
                            Nghề nghiệp: {friend.career}<br />
                            Sở thích: {friend.hobby}
                        </span>
                        <br />
                        <AddFriendActionView cssClass={'btn btn-primary'} friendId={friend.customerId} status={friend.status} callBack={this.onGetFriendDetail.bind(this)} />
                    </div>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getFriendDetail: friendActions.GetFriendDetail,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFriendDetailMobileView);