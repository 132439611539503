//@ts-check
import React from 'react';
import RenderShopListPageViewDesktop from '../child-component/shop-list-page/shop-list-page.view';
import * as PageUrls from '../../PageUrls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiseStore from 'redux/stores/utillities/utilitis-shop.store';
import { useEffect } from 'react';

/**
 * @param {{total:number,GetShopList:Function,restaurantList:{
 * logoUrl: string,
 name: string,
 address: string,
 id: number,
 type: string,}[]}} [props]
*/
function UtilitiesRestaurantListPageDesktop(props) {
    const PAGESIZE = 8;
    const PAGEINDEX = 1;
    useEffect(() => {
        props.GetShopList(PAGEINDEX, PAGESIZE, UtilitiseStore.RESTAURANKEY)
    }, [])
    const getDataByPageIndex = (index) => props.GetShopList(index, PAGESIZE, UtilitiseStore.RESTAURANKEY)
    return <RenderShopListPageViewDesktop
        currentTypeUrl={PageUrls.UtilitiesRestaurantHomeDeskTop}
        pageSize={PAGESIZE}
        pageIndex={1}
        totalItems={props.total}
        getDataByPageIndex={getDataByPageIndex}
        listData={props.restaurantList} detailUrl={PageUrls.UtilitiesRestaurantDetailDeskTop} titleShopType="Nhà hàng" />
}

const mapStateToProps = state => ({
    restaurantList: state.utilitiShop.restaurantList.listShop,
    total: state.utilitiShop.cafeList.total,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopList: UtilitiseStore.GetShopList,
},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesRestaurantListPageDesktop)