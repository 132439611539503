import SocketIoService from '../../services/socket.service';
import React, { useEffect, useState, memo } from "react";
import { Link } from 'react-router-dom';
import * as NotificationStore from "redux/stores/notification/notifications.store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PromiseAllTools } from 'core/app.store';

const socketIoService = new SocketIoService();

const NotificationNumberStyleDesktop = {
    position: "absolute",
    backgroundColor: "#E33922",
    color: "#ffffff",
    fontWeight: "normal",
    fontSize: "10px",
    padding: "1px 5.5px",
    top: "-5px",
    right: "-10px",
    borderRadius: '4px'
}
const NotificationNumberStyleMobile = {
    position: "absolute",
    backgroundColor: "#E33922",
    color: "#ffffff",
    fontWeight: "normal",
    fontSize: "10px",
    padding: "3px 3px",
    lineHeight: '12px',
    borderRadius: '4px',
    top: "-5px",
    right: "-10px",
}

function RenderNotificationBell(props) {
    const [firstTime, setFirstTime] = useState(true);
    const linkNotification = props.forMobile ? '/m/thong-bao' : '/thong-bao';
    const styleNotificationNumber = props.forMobile ? NotificationNumberStyleMobile : NotificationNumberStyleDesktop;
    useEffect(() => {
        if (props.loggedIn && firstTime) {
            setFirstTime(false);
            socketIoService.on('inform', () => props.PromiseAllTools([props.GetNotificationCount()]))
            props.PromiseAllTools([props.GetNotificationCount()])
        }
    })

    return (
        <Link
            exact="true"
            to={linkNotification}
            className="p-0 position-relative"
        >
            {props.forMobile ? <RenderBellImageMobile classImage={props.classImage} /> : <RenderBellImageDesktop />}
            {props.notificationTotal &&
                props.notificationTotal.unread > 0 ? (
                    <span style={styleNotificationNumber}>
                        {props.notificationTotal.unread}
                    </span>
                ) : null}
        </Link>
    )
}

function RenderBellImageMobile(props) {
    return (
        <img
            src={require('assets/images/notifications-white.svg')}
            alt="logo-notifications"
            className={props.classImage || `img-fluid ml-2 logo-notifications`}
        />
    )
}

function RenderBellImageDesktop(props) {
    return (
        <img
            src={require('assets/images/notifications.png')}
            alt="logo-notifications"
            className="ml-2"
        />
    )
}

const mapStateToProps = (state) => ({
    loggedIn: state.auth.isLoggedIn,
    notificationTotal: state.notifications.notificationTotal
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            GetNotificationCount: NotificationStore.GetNotificationCount,
            PromiseAllTools
        },
        dispatch
    );

export default memo(connect(mapStateToProps, mapDispatchToProps)(RenderNotificationBell));