import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from '../../../core/app.store';
import {
    createFormData,
    AppKey,
    compareValues
} from '../../../utils/configuration';
import Service from '../../../services/api.service';
import {
    ApiUrl
} from '../../../services/api-url';

const service = new Service();
/** Action Types */
const BUSSAVELIST = 'BUS/SAVELIST';
const ROUTESAVELIST = 'BUS/SAVELIST';
const AREASAVELIST = 'BUS/SAVELIST';
const BUSGETLIST = 'BUS/GETLIST';

/** Actions */
export const createSaveBusList = (busModel) => ({
    type: BUSSAVELIST,
    payload: {
        bus: busModel.items,
        totalItems: busModel.totalItemCount
    }
});
export const createSaveRouteList = (routeModel) => ({
    type: ROUTESAVELIST,
    payload: {
        routeItems: routeModel.items,
        totalItems: routeModel.totalItemCount
    }
});
export const createSaveAreaList = (areaModel) => ({
    type: AREASAVELIST,
    payload: {
        area: areaModel.items,
        totalItems: areaModel.totalItemCount
    }
});
export const createGetBusList = () => ({
    type: BUSGETLIST
});

export const GetRouteList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        // dispatch(createSaveLoading(true));
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.RouteGetList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveRouteList(res.content));
            // dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetAreaList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        // dispatch(createSaveLoading(true));
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.AreaGetList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveAreaList(res.content));
            // dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetBusList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        // dispatch(createSaveLoading(true));
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.BusGetList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveBusList(res.content));
            // dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetBusDetail = (transportId) => {
    return dispatch => {
        let payLoad = createFormData({
            transportId: transportId
        });
        // dispatch(createSaveLoading(true));
        return service.post(ApiUrl.BusGetDetail, payLoad).then(res => {
            // dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

// Initial state
export const initialState = Map({
    items: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function BusReducer(state = initialState, action) {
    switch (action.type) {
        case BUSSAVELIST:
            return {
                ...state,
                bus: action.payload.bus,
                    totalItems: action.payload.totalItems,
            }
            case ROUTESAVELIST:
                return {
                    ...state,
                    routeItems: action.payload.routeItems,
                        totalItems: action.payload.totalItems,
                }
                case AREASAVELIST:
                    return {
                        ...state,
                        area: action.payload.area,
                            totalItems: action.payload.totalItems,
                    }
                    case BUSGETLIST:
                        return state.bus;
                    default:
                        return state;
    }
}