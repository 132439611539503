/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HeaderView from "../header/header.view.jsx";
import NavigationView from "../navigation/navigation.view.jsx";
import Footer from "../footer/footer.view.jsx";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "../react-notifications/_customTypes.scss";
import { isMobile, isTablet } from "react-device-detect";
import * as projectActions from "redux/stores/project/project.store";
import { isInitSubdomain, domainOrigin, TokenKey, setCookies, getCookies, removeListCookies } from "utils/configuration";
import { JwtHelper } from 'utils/jwt-helper';
import * as authActions from "core/auth.store";
import * as appActions from "core/app.store";
import RedirectAuto from '../redirect-auto/redirect-auto.view';

const domainOriginList = domainOrigin.split(".");
const currentDomain = window.location.host;
const currentDomainList = window.location.host.split(".");
const currentProjectId = JwtHelper.currentProject();

class LayoutUserDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.isLoading || false,
      renderHeaderAgain: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (isInitSubdomain) {
      this.onCheckSubDomain();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isInitSubdomain && getCookies(TokenKey.HostName) && currentDomain != getCookies(TokenKey.HostName)) {
      setCookies(TokenKey.HostName, currentDomain);
      window.location.reload();
    }
  }

  onCheckSubDomain = () => {
    if (isMobile || isTablet) {
    } else if (domainOriginList && domainOriginList.length > 0 && currentDomainList && currentDomainList.length > 0 && domainOriginList[0] != currentDomainList[0]) {
      this.onGetProjectList();
    } else {
      removeListCookies([TokenKey.Project, TokenKey.ProjectName, TokenKey.AuthToken, TokenKey.AvatarUrl, TokenKey.Location, TokenKey.HostName]);
      this.forceUpdateHandler();
    }
  }

  forceUpdateHandler() {
    this.setState({
      renderHeaderAgain: true
    });
    this.props.logout();
  }

  onGetProjectList = () => {
    this.props.showLoading(true);
    this.setState({
      isLoading: true
    });
    this.props.onGetProjectList().then(
      (res) => {
        if (res && res.content && res.content.length > 0) {
          let projectList = res.content;
          let project = projectList.filter(x => x.domain == currentDomain);
          if (currentProjectId && currentProjectId > 0) {
            if (project && project.length > 0 && project[0].domain) {

              if (currentProjectId != project[0].clientId) {
                removeListCookies([TokenKey.Project, TokenKey.ProjectName, TokenKey.AuthToken, TokenKey.AvatarUrl, TokenKey.Location, TokenKey.HostName]);
                this.forceUpdateHandler();
              }

              setCookies(TokenKey.Project, project[0].clientId);
              setCookies(TokenKey.ProjectName, project[0].clientName);
              setCookies(TokenKey.HostName, currentDomain);
            } else {
              removeListCookies([TokenKey.Project, TokenKey.ProjectName, TokenKey.AuthToken, TokenKey.AvatarUrl, TokenKey.Location, TokenKey.HostName]);
              this.forceUpdateHandler();
            }
          } else {
            if (project && project.length > 0) {
              setCookies(TokenKey.Project, project[0].clientId);
              setCookies(TokenKey.ProjectName, project[0].clientName);
              setCookies(TokenKey.HostName, currentDomain);
            } else {
              this.forceUpdateHandler();
            }
          }
        }
        this.props.showLoading(false);
        this.setState({
          isLoading: false
        });
      },
      (err) => {
        this.props.showLoading(false);
        this.setState({
          isLoading: false
        });
      }
    );
  };

  render() {
    if (isMobile || isTablet) return <RedirectAuto currentUrlIsForMobile={false} match={this.props.match} history={this.props.history} />
    if (this.state.isLoading) return null;
    return (
      <div className="page-container">
        <ReactNotification />
        <HeaderView renderHeaderAgain={this.state.renderHeaderAgain} />
        <NavigationView />
        <div className="main-content">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <main>
                  {/* render component content*/}
                  {this.props.children}
                </main>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProjectList: projectActions.GetProjectList,
      showLoading: appActions.ShowLoading,
      logout: authActions.Logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LayoutUserDesktop);
