/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";

import * as forgotActions from "redux/stores/user/forgot-password.store";

import ConfirmCodeDesktopView from "../confirm-code/confirm-code-desktop.view";

import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  TokenKey,
  getCookies,
  setCookies,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";

class ForgotDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isForgot: this.props.isForgot || false,
      isConfirmCode: false,
      projectList: this.props.projectList || [],
    };
  }

  onConfirmCode = () => {
    this.setState({
      isForgot: !this.state.isForgot,
      isConfirmCode: !this.state.isConfirmCode,
    });
  };

  render() {
    return (
      <ForgotDesktopForm
        Forgot={this.props.Forgot}
        username={this.props.username}
        state={this.state}
        onConfirmCode={this.onConfirmCode.bind(this)}
        onShowLogin={this.props.showLogin}
      />
    );
  }
}

function ForgotDesktopForm(props) {
  const isShowSelectProject = getCookies(TokenKey.Project) ? false : true;

  const [tel, setTel] = useState(props.username || null);
  const [isSendCode, setSendCode] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [project, setProject] = useState({
    value: getCookies(TokenKey.Project) || "",
    label: getCookies(TokenKey.ProjectName) || "",
  });

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (data && project.value) {
      let params = {
        clientId: project.value,
        username: data.username.trim(),
        resetUrl: window.location.origin,
      };

      props.Forgot(params).then(
        (res) => {
          if (res && res.content) {
            setTel(res.content.mobileNumber);
            setCookies(TokenKey.Project, project.value);
            props.onConfirmCode();
          } else {
            AddNotification(
              viVN.Errors[(res && res.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    } else {
      AddNotification(
        viVN.Errors["UnknownError"],
        NotificationMessageType.Error
      );
    }
  };

  if (props.username && !isSendCode) {
    setSendCode(true);
    props.onConfirmCode();
  }

  const onChangeProject = (data) => {
    if (data) {
      setProject(data);
      clearError(["project"]);
    } else {
      setError("project", "required", "Trường này là bắt buộc");
    }
  };

  const filterProject = (inputValue) => {
    if (inputValue && inputValue.length > 3) {
      return props.state.projectList.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      return [];
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      inputValue && firstLoad && setFirstLoad(false);
      resolve(filterProject(inputValue));
    });

  return (
    <div>
      <div className="modal-header">
        <div className="pl-3">
          {props.state.isConfirmCode && (
            <img
              src={require("assets/images/arrow-back.svg")}
              alt="Arrow Back"
              onClick={props.onConfirmCode}
              className="img-fluid img-arrow-back"
            />
          )}
          {!props.state.isConfirmCode && (
            <img
              src={require("assets/images/arrow-back.svg")}
              alt="Arrow Back"
              onClick={props.onShowLogin}
              className="img-fluid img-arrow-back"
            />
          )}
        </div>
        <div className="modal-title">Đặt lại mật khẩu</div>
      </div>

      {props.state.isForgot && (
        <form onSubmit={handleSubmit(onSubmit)} className="row form-forgot">
          <div className="col-12">
            <div className="form-group">
              <span>
                Vui lòng nhập địa chỉ email hoặc số điện thoại bạn đã đăng ký
                trên iLang
              </span>
            </div>

            {isShowSelectProject && (
              <div className="form-group">
                <AsyncSelect
                  className={
                    "react-select-container" + (firstLoad ? " first-load" : "")
                  }
                  classNamePrefix="react-select"
                  cacheOptions
                  defaultOptions
                  onChange={(data) => onChangeProject(data)}
                  loadOptions={promiseOptions}
                  noOptionsMessage={() => "Không có kết quả"}
                  placeholder="Chọn dự án (*)"
                />
                <input
                  name="project"
                  type="hidden"
                  value={project && project.value}
                  ref={register({
                    required: "Trường này là bắt buộc",
                  })}
                />
                {errors.project && (
                  <div className="invalid-feedback">
                    {errors.project.message}
                  </div>
                )}
              </div>
            )}

            <div className="form-group">
              <input
                name="username"
                type="text"
                placeholder="Email/Số điện thoại"
                className="form-control"
                ref={register({
                  required: "Trường này là bắt buộc",
                  validate: (value) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ||
                    /(\(?\+[0-9]{2}\)|0)?([0-9]{9,10})/i.test(value) ||
                    "Định dạng phải là email (join@example.com) hoặc số điện thoại",
                })}
              />
              {errors.username && (
                <div className="invalid-feedback">
                  {errors.username.message}
                </div>
              )}
            </div>

            <div className="form-group text-right">
              <button
                type="submit"
                className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
              >
                Tiếp tục
              </button>
            </div>
          </div>
        </form>
      )}
      {props.state.isConfirmCode && (
        <ConfirmCodeDesktopView
          isConfirmCode={props.state.isConfirmCode}
          tel={tel}
          clientId={project.value}
          isSendCode={isSendCode}
          showLogin={props.onShowLogin.bind(this)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  // projectList: state.project.projectList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      Forgot: forgotActions.Forgot,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotDesktopView);
