import { Map } from 'immutable';
import { createFormData, AppKey } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { Schedules } from 'mock-data/schedule.mock';
import { createSaveLoading } from 'core/app.store';
let schedulesTemp = [...Schedules];

const service = new Service();
/** Action Types */
const SCHEDULESAVELIST = 'SCHEDULE/SAVELIST';
const SCHEDULESAVESCHEDULEFORAGENT = 'SCHEDULE/SAVETOPFORAGENT';
const SCHEDULEDETAIL = 'SCHEDULE/DETAIL';

/** Actions */
export const createSaveScheduleList = (scheduleModel) => ({ type: SCHEDULESAVELIST, payload: { scheduleList: scheduleModel.scheduleList, totalItems: scheduleModel.totalItems } });
export const createSaveScheduleDetail = () => ({ type: SCHEDULEDETAIL });
export const createSaveSchedulesForAgent = (schedulesForAgent) => ({ type: SCHEDULESAVESCHEDULEFORAGENT, payload: schedulesForAgent });


/** Action creators */
export const GetScheduleList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date', orderType: 'desc' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ScheduleGetList, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            dispatch(createSaveScheduleList({ scheduleList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetScheduleListForAgent = (pageIndex = 1, size = 4, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.ScheduleGetList, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetScheduleDetail = (scheduleId) => {
    return dispatch => {
        let payLoad = createFormData({ scheduleId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ScheduleGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteSchedule = (scheduleId) => {
    return dispatch => {
        let payLoad = createFormData({ scheduleId: scheduleId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ScheduleDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateSchedule = (scheduleModel) => {
    return dispatch => {
        let payLoad = createFormData(scheduleModel, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ScheduleCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */
export const GetScheduleListFake = (pageIndex, size, filterObjs) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const fromTime = new Date(filterObjs['from']);
            const toTime = new Date(filterObjs['to']);
            const newFromTime = new Date(fromTime.setDate(fromTime.getDate() - 1));
            let scheduleModels = filterObjs ?
                schedulesTemp.filter(item => {
                    return (new Date(item.schedule_time).getTime()) >= newFromTime.getTime() && (new Date(item.schedule_time).getTime()) <= toTime.getTime();
                }) :
                schedulesTemp;
            let scheduleList = scheduleModels.slice(pageIndex * size - size, pageIndex * size);
            if (scheduleList) {
                dispatch(createSaveScheduleList({ scheduleList: scheduleList, totalItems: scheduleModels.length }))
                resolve({
                    content: scheduleList,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const GetScheduleDetailFake = (scheduleId) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let scheduleDetail = schedulesTemp.find(lg => lg.id == scheduleId);
            if (scheduleDetail) {
                resolve({
                    content: scheduleDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    scheduleList: [],
    agentSchedules: [],
    scheduleDetail: {},
    totalItems: 0,
}).toJS();

/** Reducers */
export default function ScheduleReducer(state = initialState, action) {
    switch (action.type) {
        case SCHEDULESAVELIST:
            return {
                ...state,
                scheduleList: [...action.payload.scheduleList],
                totalItems: action.payload.totalItems,
            }
        case SCHEDULESAVESCHEDULEFORAGENT:
            return {
                ...state,
                agentSchedules: action.payload,
            }
        case SCHEDULEDETAIL:
            return {
                ...state,
                scheduleDetail: action.payload,
            }
        default:
            return state;
    }
}