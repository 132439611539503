/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as joinsHandActions from 'redux/stores/join-hand/join-hand.store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType, convertTextareaToHtml } from 'utils/configuration';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SetDefaultErrorImage } from "utils/configuration";
import { JwtHelper } from 'utils/jwt-helper';

class JoinsHandItemMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenEditPopup: false,
            isOpenDeletePopup: false,
            selectedTopicId: null,
        }
    }

    UNSAFE_componentWillMount() { }

    onToggleEditPopup = (isOpen, topicId) => {
        this.setState({
            isOpenEditPopup: isOpen,
            selectedTopicId: topicId
        })
    }

    onToggleDeletePopup = (isOpen, topicId) => {
        this.setState({
            isOpenDeletePopup: isOpen,
            selectedTopicId: topicId
        })
    }

    onDeleteJoinsHandPost = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteJoinsHand(this.state.selectedTopicId).then(res => {
                this.onToggleDeletePopup(false);
                AddNotification('Xoá bài viết thành công!', NotificationMessageType.Success);
                this.props.callBack && this.props.callBack();
            }, err => {
                this.onToggleDeletePopup(false);
                //TODO: show err message
            })
        }
    }

    render() {
        const { joinsHandList, loggedIn } = this.props;
        const { isOpenDeletePopup } = this.state;
        const currentUser = JwtHelper.currentUser();
        return (
            <div className="joins-hand-list">
                {joinsHandList.map(item => <div key={item.topic_id} className="joins-hand-item">
                    <Link to={`/m/lang-gieng-gan/chung-tay/chi-tiet/${item.topic_id}`}>
                        <img onError={SetDefaultErrorImage} src={item.topic_image} alt={item.topic_name} className="img-joins-hand img-fluid" />
                    </Link>
                    <div className="joins-hand-body p-2">
                        <h5>
                            <Link to={`/m/lang-gieng-gan/chung-tay/chi-tiet/${item.topic_id}`}>{item.topic_name}</Link>
                        </h5>
                        {renderText(convertTextareaToHtml(item.content), 200)}
                        <div className="row">
                            <div className="col-6">
                                <img src={require('assets/images/clock-15px.svg')} alt="Clock" className="img-clock" /> <span className="datetime">{item.intend_time}</span>
                            </div>
                            {loggedIn && currentUser.customerId == item.customer_id &&
                                <div className="col-6 text-right">
                                    <a onClick={() => this.onToggleDeletePopup(true, item.topic_id)}>
                                        <img src={require('assets/images/delete-blue.svg')} alt="delete-blue" className="img-delete-blue" />
                                    </a>
                                    <Link to={`/m/lang-gieng-gan/chung-tay/chinh-sua-su-kien/${item.topic_id}`} className="ml-1">
                                        <img src={require('assets/images/edit-blue.svg')} alt="edit-blue" className="img-edit-blue" />
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>)}
                {joinsHandList.length <= 0 && <p>Chưa có sự kiện nào.</p>}

                <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xóa yêu cầu?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Yêu cầu sẽ không thể khôi phục sau khi xóa.
                        <br />
                        Bạn có chắc muốn xóa yêu cầu này.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                        <Button variant="success" onClick={() => this.onDeleteJoinsHandPost(true)}>Xóa</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const renderText = (textOld, length) => {
    let textNew = textOld.slice(0, length);
    if (textOld.length > length) textNew += '...';
    return (
        <p className="text-justify" dangerouslySetInnerHTML={{ __html: textNew }}></p>
    )
}

const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteJoinsHand: joinsHandActions.DeleteJoinsHand,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(JoinsHandItemMobileView)