/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SetDefaultErrorImage } from "utils/configuration";
import "../my-shop/my-shop-desktop.scss";

class MyShopDesktopView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { shopList } = this.props;
    return (
      <div className="bg-white p-3 mt-4">
        <div className="my-shop">
          <div className="my-shop-header">
            <img
              src={require("assets/images/shop-blue.svg")}
              alt="Shop Blue"
              className="img-fluid"
            />{" "}
            Cửa hàng của tôi
          </div>
          {shopList && shopList.length > 0 ? (
            <ul>
              {shopList.map((item, index) => (
                <li key={index} className="text-truncate">
                  <Link
                    to={`/shop/${item.type.toLocaleLowerCase()}/${item.shopId}`}
                  >
                    <img
                      src={item.image || require("assets/images/nha-hang.svg")}
                      onError={SetDefaultErrorImage}
                      alt={item.shopName}
                      className="img-fluid text-truncate"
                    />
                    {item.shopName}
                    {item.isActive && item.isActive.toString() === "0" && (
                      <span className="float-right">Chờ kích hoạt</span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              <li>Bạn chưa có cửa hàng nào</li>
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default MyShopDesktopView;
