/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";

import * as forgotActions from "redux/stores/user/forgot-password.store";
import * as projectActions from "redux/stores/project/project.store";
import * as appActions from "core/app.store";

import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  ScreenTypeMobile,
  TokenKey,
  getCookies,
  setCookies,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";

import "./forgot-password-mobile.scss";

class ForgotMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectList: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProjectList();
  }

  onGetProjectList = () => {
    this.props.showLoading(true);
    this.props.onGetProjectList().then(
      (res) => {
        if (res && res.content && res.content.length > 0) {
          this.setState({
            projectList: res.content.map((item) => {
              return { value: item.clientId, label: item.clientName };
            }),
          });
        }
        this.props.showLoading(false);
      },
      (err) => {
        this.props.showLoading(false);
      }
    );
  };

  render() {
    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/dang-nhap">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Đặt lại mật khẩu</div>
              </div>
            </div>
          </div>
        </header>
        <ForgotDesktopForm
          forgot={this.props.Forgot}
          projectList={this.state.projectList}
        />
      </div>
    );
  }
}

function ForgotDesktopForm(props) {
  const { forgot, projectList } = props;

  const isShowSelectProject = getCookies(TokenKey.Project) ? false : true;

  const [firstLoad, setFirstLoad] = useState(true);
  const [project, setProject] = useState({
    value: getCookies(TokenKey.Project) || "",
    label: getCookies(TokenKey.ProjectName) || "",
  });

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (data && project.value) {
      let params = {
        clientId: project.value,
        username: data.username.trim(),
        resetUrl: window.location.origin,
      };
      forgot(params).then(
        (res) => {
          if (res && res.content) {
            setCookies(TokenKey.Project, project.value);
            history.push(
              "/m/xac-nhan/" +
                ScreenTypeMobile.Forgot +
                "&&" +
                JwtHelper.encodeStringBase64(res.content.mobileNumber.trim())
            );
          } else {
            AddNotification(
              viVN.Errors[(res && res.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    }
  };

  const onChangeProject = (data) => {
    if (data) {
      setProject(data);
      clearError(["project"]);
    } else {
      setError("project", "required", "Trường này là bắt buộc");
    }
  };

  const filterProject = (inputValue) => {
    if (inputValue && inputValue.length > 3) {
      return projectList.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      return [];
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      inputValue && firstLoad && setFirstLoad(false);
      resolve(filterProject(inputValue));
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row form-forgot">
      <div className="col-12">
        <div className="form-group">
          <span>
            Vui lòng nhập địa chỉ email hoặc số điện thoại bạn đã đăng ký trên
            iLang
          </span>
        </div>

        {isShowSelectProject && (
          <div className="form-group">
            <AsyncSelect
              className={
                "react-select-container" + (firstLoad ? " first-load" : "")
              }
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions
              onChange={(data) => onChangeProject(data)}
              loadOptions={promiseOptions}
              noOptionsMessage={() => "Không có kết quả"}
              placeholder="Chọn dự án (*)"
            />
            <input
              name="project"
              type="hidden"
              value={project && project.value}
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            />
            {errors.project && (
              <div className="invalid-feedback">{errors.project.message}</div>
            )}
          </div>
        )}

        <div className="form-group">
          <input
            name="username"
            type="text"
            placeholder="Email/Số điện thoại"
            className="form-control"
            ref={register({
              required: "Trường này là bắt buộc",
              validate: (value) =>
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ||
                /(\(?\+[0-9]{2}\)|0)?([0-9]{9,10})/i.test(value) ||
                "Định dạng phải là email (join@example.com) hoặc số điện thoại",
            })}
          />
          {errors.username && (
            <div className="invalid-feedback">{errors.username.message}</div>
          )}
        </div>

        <div className="form-group text-right">
          <button
            type="submit"
            className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
          >
            Tiếp tục
          </button>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      Forgot: forgotActions.Forgot,
      onGetProjectList: projectActions.GetProjectList,
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotMobileView);
