/* eslint-disable react/prop-types */
import "./club.scss";
import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { history } from "redux/store";

import AddFriendMobileView from "../add-friend/add-friend-mobile.view.jsx";
import GolfMobileView from "./golf/golf-mobile.view.jsx";
import TennisMobileView from "./tennis/tennis-mobile.view.jsx";
import PingPongMobileView from "./ping-pong/ping-pong-mobile.view.jsx";
import BadMintonMobileView from "./badminton/badminton-mobile.view.jsx";

class ClubMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideObj: null,
      firstTime: true,
      typeId: Number.isNaN(Number(this.props.match.params.typeId))
        ? null
        : this.props.match.params.typeId,
    };
  }

  setValue(value) {
    if (this.state.firstTime) {
      this.setState({
        firstTime: false,
        slideObj: value,
      });
    }
  }

  handleClickNav(i) {
    const navList = Array.from(document.getElementsByClassName("m-club-nav"));
    const slideList = document.getElementsByClassName("slick-slide");

    switch (i) {
      case 0:
        history.push("/m/lang-gieng-gan/cau-lac-bo/ket-ban");
        break;
      case 1:
        history.push("/m/lang-gieng-gan/cau-lac-bo/golf");
        break;
      case 2:
        history.push("/m/lang-gieng-gan/cau-lac-bo/tennis");
        break;
      case 3:
        history.push("/m/lang-gieng-gan/cau-lac-bo/cau-long");
        break;
      case 4:
        history.push("/m/lang-gieng-gan/cau-lac-bo/bong-ban");
        break;
      default:
        history.push("/m/lang-gieng-gan/cau-lac-bo/ket-ban");
        break;
    }

    navList.map((navTitle, index) => {
      if (i === index) {
        navTitle.classList.add("disabled");
        slideList[index].classList.add("active");
      } else {
        navTitle.classList.remove("disabled");
        slideList[index].classList.remove("active");
      }
    });
    if (i === 3) {
      this.state.slideObj && this.state.slideObj.slickGoTo(2);
    }
  }

  componentDidMount() {
    this.handleClickNav(+this.state.typeId || 0);
    document
      .getElementsByClassName("slick-slide")
      [this.state.typeId || 0].classList.add("active");
    document
      .getElementsByClassName("m-club-nav")
      [this.state.typeId || 0].classList.add("disabled");
    if (Number.isNaN(Number(this.props.match.params.typeId)))
      SelectTabByUrl(this.props.match.params.typeId);
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
    };

    const { typeId } = this.state;

    return (
      <div className="club-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Câu lạc bộ</div>
              </div>
            </div>
          </div>
        </header>
        <Accordion defaultActiveKey={(typeId || 0).toString()}>
          <div className="club-nav">
            <Slider ref={(slider) => this.setValue(slider)} {...settings}>
              <div className="list-inline-item">
                <Accordion.Toggle
                  as={Button}
                  onClick={() => this.handleClickNav(0)}
                  className="m-club-nav"
                  variant="link"
                  eventKey="0"
                >
                  Kết bạn
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle
                  as={Button}
                  onClick={() => this.handleClickNav(1)}
                  className="m-club-nav"
                  variant="link"
                  eventKey="1"
                >
                  Golf
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle
                  as={Button}
                  onClick={() => this.handleClickNav(2)}
                  className="m-club-nav"
                  variant="link"
                  eventKey="2"
                >
                  Tennis
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle
                  as={Button}
                  onClick={() => this.handleClickNav(3)}
                  className="m-club-nav"
                  variant="link"
                  eventKey="3"
                >
                  Cầu lông
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle
                  as={Button}
                  onClick={() => this.handleClickNav(4)}
                  className="m-club-nav"
                  variant="link"
                  eventKey="4"
                >
                  Bóng bàn
                </Accordion.Toggle>
              </div>
            </Slider>
          </div>

          <div className="mobile-divide"></div>

          <Accordion.Collapse eventKey="0">
            <AddFriendMobileView />
          </Accordion.Collapse>

          <Accordion.Collapse eventKey="1">
            <GolfMobileView />
          </Accordion.Collapse>

          <Accordion.Collapse eventKey="2">
            <TennisMobileView />
          </Accordion.Collapse>

          <Accordion.Collapse eventKey="3">
            <BadMintonMobileView />
          </Accordion.Collapse>

          <Accordion.Collapse eventKey="4">
            <PingPongMobileView />
          </Accordion.Collapse>
        </Accordion>
      </div>
    );
  }
}

function SelectTabByUrl(match) {
  const selectButton = document.getElementsByClassName("m-club-nav");
  switch (match) {
    case "ket-ban":
      break;
    case "golf":
      selectButton[1].click();
      break;
    case "tennis":
      selectButton[2].click();
      break;
    case "cau-long":
      selectButton[3].click();
      break;
    case "bong-ban":
      selectButton[4].click();
      break;
    default:
      break;
  }
}

export default ClubMobileView;
