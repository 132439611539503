/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { RenderInfo } from '../../../payment-desktop/electric/electric-bill-month.view.jsx';
import * as CreateTransactionStores from 'redux/stores/create-transaction/create-transaction.store';
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { NotificationMessageType, TokenKey, getCookies, GetProjectInfo } from "utils/configuration";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export function RenderBillOfMonthly(props) {
    const dataFee = props.dataFee;
    const dataParking = props.dataParking;
    const haveFeeBill = props.haveFeeBill || false;
    const haveParkingBill = props.haveParkingBill || false;
    const haveData = haveParkingBill || haveFeeBill || false;

    const currentDomain = window.location.origin;
    const projectName = getCookies(TokenKey.ProjectName) ? getCookies(TokenKey.ProjectName) : "";
    const projectInfo = GetProjectInfo();
    const isPayment = projectInfo && projectInfo.hasVnpay && projectInfo.hasVnpay == "1" || false;

    const content = (
        <Fragment>
            {haveFeeBill ? <RenderFeeBill isPayment={isPayment} createTransactionVNPay={props.CreateTransactionVNPay} data={dataFee} currentDomain={currentDomain} projectName={projectName} /> : <RenderInfo title="Dịch vụ quản lý" />}
            {haveParkingBill ? <RenderParking isPayment={isPayment} createTransactionVNPay={props.CreateTransactionVNPay} data={dataParking} currentDomain={currentDomain} projectName={projectName} /> : <RenderInfo title="Dịch vụ gửi xe" />}
        </Fragment>
    )
    return (
        <div className="payment-info bg-white p-3">
            {haveData ? content : <RenderInfo />}
        </div>
    )
}

function RenderFeeBill(props) {
    const { data, currentDomain, projectName, isPayment } = props;

    function onCreateTransactionPayment(data) {
        props.createTransactionVNPay({
            return_url: currentDomain + "/m/thanh-toan/ket-qua",
            transaction_name: "Thanh toán tiền phí quản lý tháng " + data.monthly + " " + data.yearly + " căn hộ " + data.houseNumber + " khu " + projectName,
            service_type_code: "fee",
            service_id: data.feeId
        }).then(
            (res) => {
                window.location.replace(res.content && res.content.redirect_url);
            },
            (err) => {
                err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error);
            }
        );
    }

    return (
        <Fragment>
            <h5>Tên khách hàng: <strong>{data.userName}</strong></h5>
            <h5>Căn hộ số: <strong>{data.houseNumber}</strong></h5>
            <h5>Hóa đơn dịch vụ: Tháng {data.monthly}/{data.yearly}</h5>

            <Table size="sm" className="mt-3 mb-0">
                <thead>
                    <tr>
                        <th>Phí quản lý T{data.monthly}/{data.yearly}</th>
                        <th className="text-right">
                            {data.datePay
                                ? <h6 className="text-success mb-0">Đã thanh toán</h6>
                                : <h6 className="text-danger mb-0">Chưa thanh toán</h6>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Số SD:</td>
                        <td className="text-right">{data.acreage}</td>
                    </tr>
                    <tr>
                        <td>Giá:</td>
                        <td className="text-right">{data.feePrice} VNĐ</td>
                    </tr>
                    <tr>
                        <td>VAT:</td>
                        <td className="text-right">{data.tax} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Phụ phí:</td>
                        <td className="text-right">{data.subFee} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Khuyến mãi:</td>
                        <td className="text-right">{data.discount} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Đơn vị tính:</td>
                        <td className="text-right">{data.unitName}</td>
                    </tr>
                    <tr>
                        <td>Tổng cộng:</td>
                        <td className="text-right total-price">{data.total}VNĐ</td>
                    </tr>
                </tbody>
            </Table>

            {
                data && data.datePay == "" && data.total > "0" && (
                    <div className="text-right">
                        <button className="btn btn-success text-uppercase rounded-pill mt-3 mb-3 pl-4 pr-4" disabled={!isPayment} onClick={() => onCreateTransactionPayment(data)}>
                            Thanh toán phí quản lý
                        </button>
                    </div>
                )
            }
        </Fragment>
    )
}

function RenderParking(props) {
    const { data, currentDomain, projectName, isPayment } = props;

    function onCreateTransactionPayment(data) {
        props.createTransactionVNPay({
            return_url: currentDomain + "/m/thanh-toan/ket-qua",
            transaction_name: "Thanh toán tiền phí gửi xe tháng " + data.monthly + " " + data.yearly + " căn hộ " + data.houseNumber + " khu " + projectName,
            service_type_code: "parking",
            service_id: data.parkingId
        }).then(
            (res) => {
                window.location.replace(res.content && res.content.redirect_url);
            },
            (err) => {
                err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error);
            }
        );
    }

    return (
        <div>
            <Table size="sm" className="mt-3 mb-0">
                <thead>
                    <tr>
                        <th>Gửi xe T{data.monthly}/{data.yearly}</th>
                        <th className="text-right">
                            {data.datePay
                                ? <h6 className="text-success mb-0">Đã thanh toán</h6>
                                : <h6 className="text-danger mb-0">Chưa thanh toán</h6>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Số SD:</td>
                        <td className="text-right">{data.amount}</td>
                    </tr>
                    <tr>
                        <td>Giá:</td>
                        <td className="text-right">{data.unitPrice} VNĐ</td>
                    </tr>
                    <tr>
                        <td>VAT:</td>
                        <td className="text-right">{data.tax} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Phụ phí:</td>
                        <td className="text-right">{data.subFee} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Khuyến mãi:</td>
                        <td className="text-right">{data.discount} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Đơn vị tính:</td>
                        <td className="text-right">{data.unitName}</td>
                    </tr>
                    <tr>
                        <td>Tổng cộng:</td>
                        <td className="text-right total-price">{data.total}VNĐ</td>
                    </tr>
                </tbody>
            </Table>

            {
                data && data.datePay == "" && data.total > "0" && (
                    <div className="text-right">
                        <button className="btn btn-success text-uppercase rounded-pill mt-3 mb-3 pl-4 pr-4" disabled={!isPayment} onClick={() => onCreateTransactionPayment(data)}>
                            Thanh toán phí gửi xe
                        </button>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
    CreateTransactionVNPay: CreateTransactionStores.CreateTransactionVNPay,
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(RenderBillOfMonthly)