/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "../slider/slider.scss";
import { isMobile, isTablet } from "react-device-detect";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sliderActions from "redux/stores/slider/slider.store";
import * as advertisingActions from "redux/stores/advertising/advertising.store";
import { SetDefaultErrorImage } from "utils/configuration";

class SliderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    // this.onGetSliderList(1, 3, { orderBy: "update_date", orderType: "DESC" });
  }

  onGetSliderList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getSliderList(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  render() {
    if (isMobile || isTablet) {
      return "";
    }

    const sliderSlick = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 2000,
      autoplay: true,
      autoplaySpeed: 5000,
    };

    // const { sliderList } = this.props;
    const sliderList =
      (this.props.sliderList &&
        this.props.sliderList.length > 0 &&
        this.props.sliderList.filter(
          (item) =>
            item.positionCode == advertisingActions.POSITION_AD.DESKTOP_SLIDER
        )) ||
      [];

    return (
      <div className="container">
        {sliderList && sliderList.length > 0 ? (
          <div className="row">
            <div className="col-12 slider">
              <Slider {...sliderSlick}>
                {sliderList.map((item) => (
                  <div key={item.advertisingId.toString()}>
                    <a href={item.advertisingUrl}>
                      <img
                        src={item.advertisingFile}
                        onError={SetDefaultErrorImage}
                        alt={item.advertisingName}
                        className="img-fluid w-100"
                      />
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        ) : (
          <div style={{ height: 15 }}></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // sliderList: state.slider.sliderList,
  // totalItems: state.slider.totalItems,
  sliderList: state.homeMobile.advertising,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSliderList: sliderActions.GetSliderList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SliderView);
