import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import * as helpingHandActions from 'redux/stores/help/helping-hand.store';
import RenderHelpingHandContent from './helping-hand-content.view';
import { Modal, Button } from 'react-bootstrap';
import EditHelpingHandDesktopView from '../helping-hand-actions/edit-helping-hand-desktop.view';
import "../../../neighbourhoods-desktop/neighbourhoods-desktop.scss";
import { history } from 'redux/store';
import { JwtHelper } from "utils/jwt-helper";

class HelpingHandDetailDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: JwtHelper.currentUser(),
            helpingHandPost: null,
            helpId: this.props.match.params.helpId,
            isOpenEditPopup: false,
            isOpenDeletePopup: false,
            selectedHelpPostId: null,
        }
    }

    UNSAFE_componentWillMount() {
        this.onGetHelpingHandPostDetail();
    }

    onGetHelpingHandPostDetail = () => {
        if (this.state.helpId) {
            this.props.getHelpingHandDetail(this.state.helpId).then(res => {
                if (res.content) {
                    this.setState({
                        helpingHandPost: res.content
                    })
                } else {
                    history.push('/lang-gieng-gan/giup-mot-tay');
                }
            }, err => {
                // console.log(err);
            })
        } else {
            history.push('/lang-gieng-gan/giup-mot-tay');
        }
    }

    onToggleEditPopup = (isOpen, helpId) => {
        this.setState({
            isOpenEditPopup: isOpen,
            selectedHelpPostId: helpId
        })
    }

    onToggleDeletePopup = (isOpen, helpId) => {
        this.setState({
            isOpenDeletePopup: isOpen,
            selectedHelpPostId: helpId
        })
    }

    onDeleteHelpingHandPost = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteHelpingHand(this.state.selectedHelpPostId).then(res => {
                this.onToggleDeletePopup(false);
                AddNotification('Xoá bài viết thành công!', NotificationMessageType.Success);
                history.push('/lang-gieng-gan/giup-mot-tay');
            }, err => {
                this.onToggleDeletePopup(false);
                //TODO: show err message
            })
        }
    }

    render() {
        const { helpingHandPost, currentUser, isOpenDeletePopup, isOpenEditPopup, selectedHelpPostId } = this.state;

        return (
            <div className="helping-hand-desktop">
                <h2 className="title-lg">Giúp một tay</h2>
                {helpingHandPost && <RenderHelpingHandContent data={helpingHandPost} currentUserId={currentUser.customerId} onToggleDeletePopup={this.onToggleDeletePopup.bind(this)} onToggleEditPopup={this.onToggleEditPopup.bind(this)} callBack={this.onGetHelpingHandPostDetail.bind(this)} />}
                <Modal show={isOpenEditPopup} className="modal-edit" centered onHide={() => this.onToggleEditPopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chỉnh sửa yêu cầu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditHelpingHandDesktopView helpId={selectedHelpPostId} onToggleEditPopup={this.onToggleEditPopup.bind(this)} callBack={this.onGetHelpingHandPostDetail.bind(this)} />
                    </Modal.Body>
                </Modal>

                <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xóa yêu cầu?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Yêu cầu sẽ không thể khôi phục sau khi xóa.
                        <br />
                        Bạn có chắc muốn xóa yêu cầu này.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                        <Button variant="success" onClick={() => this.onDeleteHelpingHandPost(true)}>Xóa</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    createOrUpdateHelpingHand: helpingHandActions.CreateOrUpdateHelpingHand,
    getHelpingHandDetail: helpingHandActions.GetHelpingHandDetail,
    deleteHelpingHand: helpingHandActions.DeleteHelpingHand,
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(HelpingHandDetailDesktopView)