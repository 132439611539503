import React, { useState } from 'react'
import * as Gmap from '@react-google-maps/api'
export const GoogleMapAPI = "AIzaSyDWwoJJa55rOg2D7o-30ISBWZMvzUPvT3Q";
import { JwtHelper } from 'utils/jwt-helper';
import { getThisProjectLocation } from './project-location';
const mapStyleDefault = {
    height: "400px",
    width: "100%"
}
const defaultPosition = new JwtHelper.currentLocation();
const zoomDefault = 12;
const centerDefault = (defaultPosition.lat) ? defaultPosition : getThisProjectLocation()

const idGoogleMapDefault = "googleMapDefault";

/**
 * @param {{
 * listMarkerCustom?:{iconUrl:string;listPosition:{lat:number;lng:number;}[];};
 * }} props
*/
export default function GoogleMap(props) {
    const [firstTime, setFirstTime] = useState(true)
    let mapStyle = props.mapStyle || mapStyleDefault;
    const [zoom, setZoom] = useState(zoomDefault);
    const center = props.center || centerDefault;
    const idGoogleMap = props.idGoogleMap || idGoogleMapDefault;
    const listMarkerCustomData = props.listMarkerCustom || { listPosition: [] };
    if (props.dropMarker && firstTime) {
        setFirstTime(false);
        setZoom(15);
    }
    const getLatLng = (event) => {
        if (props.dropMarker) {
            props.click({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            })
        }
    }
    return (

        <Gmap.GoogleMap
            id={idGoogleMap}
            mapContainerStyle={mapStyle}
            zoom={zoom}
            center={center}
            onClick={((MouseEvent) => getLatLng(MouseEvent))}
        >
            <Gmap.Marker position={props.marker} draggable={true} onDragEnd={((MouseEvent) => getLatLng(MouseEvent))} />
            {props.listMarker && <RenderListMarker listPosition={props.listMarker} />}
            {props.listMarkerTwo && <RenderListMarkerTwo listPosition={props.listMarkerTwo} />}
            {props.listMarkerOne && <RenderListMarkerOne listPosition={props.listMarkerOne} />}
            {
                (listMarkerCustomData.listPosition.length > 0) && <RenderListMarkerCustom data={listMarkerCustomData} />
            }
        </Gmap.GoogleMap>

    )
}

/**
 * @param {{
 * data:{iconUrl:string;listPosition:{lat:number;lng:number;}[];}
 * }} props
*/
function RenderListMarkerCustom(props) {
    const iconMarkerUrl = props.data.iconUrl;
    const listPosition = props.data.listPosition;
    return (
        <div>
            {listPosition.map((data, index) => <Gmap.Marker icon={iconMarkerUrl} key={index} position={data} onClick={()=>console.log(data)} />)}
        </div>
    )
}

function RenderListMarker(props) {
    /**
     * @type {{lat:number;lng:number}[]}
    */
    const listPosition = props.listPosition;
    return (
        <div>
            {listPosition.map((data, index) => <Gmap.Marker key={index} position={data} />)}
        </div>
    )
}

function RenderListMarkerOne(props) {
    /**
     * @type {{lat:number;lng:number}[]}
    */
    const listPosition = props.listPosition;
    return (
        <div>
            {listPosition.map((data, index) => <Gmap.Marker icon="../../assets/images/processed.svg" key={index} position={data} />)}
        </div>
    )
}

function RenderListMarkerTwo(props) {
    /**
     * @type {{lat:number;lng:number}[]}
    */
    const listPosition = props.listPosition;
    return (
        <div>
            {listPosition.map((data, index) => <Gmap.Marker icon="../../assets/images/processing.svg" key={index} position={data} />)}
        </div>
    )
}
