import { Map } from 'immutable';
import { createSaveLoading } from "core/app.store";
import AddNotification from 'components/react-notifications/react-notifications';
import Service from 'services/api.service';
import { createFormData, AppKey, ERRORTYPEEMPTYDATAERROR } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';

const service = new Service();

const INITLOCATION = "Bbq/LOCATION";

const InitLocation = data => ({
    type: INITLOCATION, data: data
})

export const GetBbqLocation = (pageIndex = 1, size = 100) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = []
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.BbqList, payLoad).then(res => {
            rawData = res.content.items;
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitLocation(ExchangeBbqListData(rawData)))
    }
}

export const PostBbqRegisterUse = (data) => {
    return async (dispatch) => {
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return await service.post(ApiUrl.BbqRegister, payLoad).then(res => {
            return true;
        }).catch(err => {
            if (err.errorType === ERRORTYPEEMPTYDATAERROR) return true; else AddNotification("Có lỗi xảy ra trong quá trình đăng ký vui lòng thông báo cho BQL để nhận hỗ trợ", "error")
            console.log(err)
            return false
        }).finally(() => {
            dispatch(createSaveLoading(false));
        })
    }
}

const initialState = Map({
    location: []
}).toJS()

export default function BbqReducer(state = initialState, action) {
    switch (action.type) {
        case INITLOCATION: return {
            ...state,
            location: action.data
        }
        default:
            return state
    }
}

function ExchangeBbqListData(rawData) {
    const standartData = [];
    rawData.map((data) => {
        standartData.push({
            bbqId: data.bbqId,
            bbqName: data.bbqId,
            lat: data.latitude,
            lng: data.longitude,
            content: data.content,
            schedule: data.schedule
        })
    })
    return standartData
}