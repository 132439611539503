import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as legalActions from 'redux/stores/management/legal.store';
import { Link } from 'react-router-dom';
import './legal-mobile.scss';

class LegalListMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legalList: []
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetLegalList();
    }

    onGetLegalList = (pageIndex = 1, size = 10) => {
        this.props.getLegalList(pageIndex, size).then(res => {

        }, err => { 
        })
    }

    render() {
        const { legalList } = this.props;
        return (
            <div className="legal-mobile">
                <div className="container">
                    <div className="row">
                        {legalList.map((item) => <div className="col-4 text-center">
                            <Link to={`/m/van-ban-phap-ly/${item.id}`}>
                                <img src={require("assets/images/pdf-icon.png")} className="img-fluid" alt="pdf-icon"></img>
                            </Link>
                            <h5>
                                <Link to={`/m/van-ban-phap-ly/${item.id}`}>
                                    <span> {item.name}</span>
                                </Link>
                            </h5>
                        </div>)}
                        {!legalList.length && <div className="col-12">Không có bản ghi</div>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    legalList: state.legal.legalList
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getLegalList: legalActions.GetLegalList,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LegalListMobileView);
