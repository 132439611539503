import { createSaveLoading } from 'core/app.store';
import AddNotification from 'components/react-notifications/react-notifications'
import Service from 'services/api.service';
import { createFormData, AppKey } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';
import { Map } from 'immutable';

const service = new Service();
const INITTIMEOPEN = "POOL/TIMEOPEN"
const INITLOCATIONLIST = "POOL/LOCATIONLIST";
const INITPOOLLIST = "POOL/LIST";

const InitPoolList = data => ({
    type: INITPOOLLIST, data: data
})

export const InitTimeOpen = data => ({
    type: INITTIMEOPEN, data: data
})

export const InitLocationList = data => ({
    type: INITLOCATIONLIST, data: data
})

export const GetPoolList = (pageIndex = 1, size = 100) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = null
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.PoolList, payLoad).then(res => {
            if (res.content && (res.content.items.length > 0)) rawData = res.content; else return false
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitPoolList(ExchangePoolListData(rawData)))
    }
}

export const PostRegisterPool = (registerData) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(registerData, AppKey.PostPayloadPrefix);
        return await service.post(ApiUrl.PoolRegister, payLoad).then(res => {
            return true;
        }).catch(err => {
            if (err.errorType === "DuplicateDataError") return true; else AddNotification("Có lỗi xảy ra trong quá trình đăng ký vui lòng thông báo cho BQL để nhận hỗ trợ", "error")
            console.log(err)
            return false;
        }).finally(() => {
            dispatch(createSaveLoading(false));
        })
    }
}

const initState = Map({
    timeOpent: {},
    location: {},
    poolList: [],
}).toJS()

export default function PoolReducer(state = initState, action) {
    switch (action.type) {
        case INITPOOLLIST:
            return {
                ...state,
                poolList: action.data
            }
        case INITTIMEOPEN:
            return {
                ...state,
                timeOpent: action.data
            }
        case INITLOCATIONLIST:
            return {
                ...state,
                location: action.data
            }
        default:
            return state
    }
}

function ExchangePoolListData(rawData) {
    const standardData = {
        haveData: false,
        poolList: []
    }

    if (rawData) {
        standardData.haveData = true,
            rawData.items.map((data) => {
                standardData.poolList.push({
                    poolId: data.poolId,
                    poolName: data.poolName,
                    lng: data.longitude,
                    lat: data.latitude,
                })
            })
        standardData.paymentGuide = rawData.items[0].content;
        standardData.timeOpen = rawData.items[0].schedule;
    }

    return standardData
}