import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as joinHandActions from 'redux/stores/join-hand/join-hand.store';
import JoinsHandItemMobileView from './childs/joins-hand-item-mobile.view';
import PaginationControl from 'components/pagination/pagination.view';
import { JwtHelper } from "utils/jwt-helper";
import { Link } from 'react-router-dom';
import '../neighbourhoods-mobile.scss';

class JoinsHandMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageIndex: 1,
            isToggleAdding: false,
            currentUser: JwtHelper.currentUser()
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetJoinHandList();
    }

    onGetJoinHandList = (pageIndex = this.state.currentPageIndex, size = 10) => {
        this.setState({ isLoading: true });
        this.props.getJoinHandList(pageIndex, size, (this.state.currentUser && this.state.currentUser.customerId) || 1014).then(
            res => {
                this.setState({ isLoading: false });
            },
            err => {
                // console.log(err);
                this.setState({ isLoading: false });
            }
        );
    };

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetJoinHandList(pageNumber);
        })
    }

    onToggleAddingState = () => {
        this.setState({
            isToggleAdding: !this.state.isToggleAdding
        })
    }

    render() {
        const { joinsHandList, totalItems } = this.props;
        const { currentPageIndex, isToggleAdding } = this.state;
        return (
            <div className="neighbourhoods-desktop joins-hand-desktop">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Chung tay</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="joins-hand-mobile p-3">
                    <div className="add-joins-hand rounded p-2">
                        <Link to="/m/lang-gieng-gan/chung-tay/tao-su-kien">
                            <div className="row align-items-center no-gutters">
                                <div className="col-1">
                                    <img src={require("assets/images/event-calendar.svg")} alt="Cần giúp đỡ" className="img-event-calendar" />
                                </div>
                                <div className="col-10">
                                    <h5 style={{ color: "#fff" }}>
                                        Chung tay tạo sự kiện mới
                                </h5>
                                </div>
                                <div className="col-1 text-right">
                                    <img src={require("assets/images/plus-white.svg")} alt="plus" className="img-plus" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <h2 className="mt-3">Sự kiện trước đây</h2>
                    <JoinsHandItemMobileView joinsHandList={joinsHandList} isToggleAdding={isToggleAdding} callBack={this.onGetJoinHandList.bind(this)} />
                    <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    joinsHandList: state.joinsHand.joinsHandList,
    totalItems: state.joinsHand.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getJoinHandList: joinHandActions.GetJoinsHandList,
    deleteJoinHand: joinHandActions.DeleteJoinsHand,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(JoinsHandMobileView)