/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { _range } from "utils/configuration";

export default class PaginationControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _currentTotalItems: 0,
      _currentPageIndex: 1,
      pageSize: this.props.pageSize || 10,
      pager: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.setState(
      {
        _currentPageIndex: this.props.currentPageIndex,
        _currentTotalItems: this.props.totalItems,
      },
      () => {
        this.writeValue(this.props.currentPageIndex);
      }
    );
  }

  componentDidUpdate() {
    if (
      this.state._currentTotalItems !== this.props.totalItems ||
      this.state._currentPageIndex !== this.props.currentPageIndex
    ) {
      this.setState(
        {
          _currentPageIndex: this.props.currentPageIndex,
          _currentTotalItems: this.props.totalItems,
        },
        () => {
          this.writeValue(this.props.currentPageIndex);
        }
      );
    }
  }

  selectPage = (pageIndex, event) => {
    if (event) {
      event.preventDefault();
    }

    if (event && event.target) {
      let target = event.target;
      target.blur();
    }

    if (pageIndex < 1 || pageIndex > this.state.pager.totalPages) {
      return;
    }

    this.writeValue(pageIndex, true);
  };

  writeValue = (pageIndex, emit = false) => {
    let pager = this.getPager(this.props.totalItems, pageIndex);
    this.setState(
      {
        pager: pager,
      },
      () => {
        if (!emit) return;
        this.props.pageChanged(this.state.pager.currentPage);
      }
    );
  };

  noPrevious = () => {
    return this.state.pager && this.state.pager.currentPage === 1;
  };

  noNext = () => {
    return (
      this.state.pager &&
      this.state.pager.currentPage === this.state.pager.totalPages
    );
  };

  getPager = (totalItems, currentPage = 1) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / this.state.pageSize);
    let startPage, endPage;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * this.state.pageSize;
    let endIndex = Math.min(
      startIndex + this.state.pageSize - 1,
      totalItems - 1
    );
    // create an array of pages to ng-repeat in the pager control
    let pages = _range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: this.pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      isLastPage: currentPage == totalPages,
      pages: pages,
    };
  };

  render() {
    const { style } = this.props;
    const borderTop = style && style.includes('border');
    return (
      <div className={(style ? style + " " : "") + "pagination" + (borderTop ? " border-top" : "")}>
        {this.state.pager.pages && this.state.pager.pages.length > 0 && (
          <ul className="list-inline">
            <li
              className={`list-inline-item fist-page ${
                this.noPrevious() ? "disabled" : ""
              }`}
            >
              <a
                onClick={(e) =>
                  this.selectPage(this.state.pager.currentPage - 1, e)
                }
              >
                <img
                  src={require("assets/images/last-page.svg")}
                  alt="Clock"
                  className="img-fluid"
                />
              </a>
            </li>
            {this.state.pager.pages.map((page) => (
              <li className={`list-inline-item`} key={page}>
                <a
                  className={
                    this.state.pager.currentPage === page ? "active" : ""
                  }
                  onClick={(e) => this.selectPage(page, e)}
                >
                  {page}
                </a>
              </li>
            ))}
            <li
              className={`list-inline-item last-page ${
                this.noNext() ? "disabled" : ""
              }`}
            >
              <a
                onClick={(e) =>
                  this.selectPage(this.state.pager.currentPage + 1, e)
                }
              >
                <img
                  src={require("assets/images/last-page.svg")}
                  alt="Clock"
                  className="img-fluid"
                />
              </a>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

PaginationControl.propTypes = {
  totalItems: PropTypes.number.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  pageChanged: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
};
