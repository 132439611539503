import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as resetPasswordActions from "redux/stores/user/reset-password.store";
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from "utils/configuration";
import * as viVN from 'translation/vi-VN.json';

class ResetPasswordDesktopView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isResetPassword: this.props.isResetPassword || false,
            isSuccess: this.props.activeSuccess || false,
            isRegistration: this.props.isRegistration || false,
        };

    }

    onShowLogin = () => {
        this.props.showLogin();
    }

    onSuccess = () => {
        const state = this.state;
        this.setState({
            isSuccess: !state.isSuccess,
            isResetPassword: !state.isResetPassword,
        });
    }

    render() {
        const state = this.state;
        return (
            <ResetPasswordDesktopForm
                state={state}
                has={this.props.has}
                tel={this.props.tel}
                ConfirmCode={this.props.ConfirmCode}
                ResetPassword={this.props.ResetPassword}
                onSuccess={this.onSuccess}
                onShowLogin={this.onShowLogin.bind(this)} />
        );
    }
}

function ResetPasswordDesktopForm(props) {

    const { register, errors, handleSubmit, watch, setError, clearError, getValues } = useForm({
        mode: "onChange"
    });

    const password = useRef({});
    password.current = watch("password", "");

    const onChangePassword = e => {
        const { password, confirmPassword } = getValues();
        password == confirmPassword ? clearError(["confirmPassword"]) : setError("confirmPassword", "validate", "Mật khẩu không trùng khớp");
    }

    const onSubmit = data => {
        if (data) {
            props.ResetPassword({
                resetCode: props.has,
                mobileNumber: props.tel,
                password: data.password,
            }).then(res => {
                if (res && res.content) {
                    props.onSuccess();
                } else {
                    res && res.errorType && AddNotification(viVN.Errors[res && res.errorType], NotificationMessageType.Error)
                }
            }, err => {
                err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error)
                // console.log(err);
            });
        }
    };

    return (
        <div>
            {!props.state.isRegistration && props.state.isResetPassword &&
                <form onSubmit={handleSubmit(onSubmit)} className="row form-forgot">
                    <div className="col-12">
                        <div className="form-group">
                            <input
                                name="password"
                                type="password"
                                placeholder="Nhập mật khẩu"
                                className="form-control"
                                onChange={() => onChangePassword()}
                                ref={register({
                                    required: "Trường này là bắt buộc",
                                    minLength: {
                                        value: 6,
                                        message: "Mật khẩu phải có ít nhất 6 ký tự"
                                    }
                                })}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                        </div>

                        <div className="form-group">
                            <input
                                name="confirmPassword"
                                type="password"
                                placeholder="Xác nhận mật khẩu"
                                className="form-control"
                                ref={register({
                                    validate: value =>
                                        value === password.current || "Mật khẩu không trùng khớp"
                                })}
                            />
                            {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                        </div>

                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-success text-uppercase rounded-pill pl-4 pr-4">Tiếp tục</button>
                        </div>

                    </div>
                </form>
            }
            {!props.state.isRegistration && props.state.isSuccess &&
                <div className="change-password-success text-center">
                    <img src={require('assets/images/success-filled.svg')} className="img-fluid mb-3" alt="Success" />
                    <h5>Mật khẩu của bạn đã được đặt lại thành công!</h5>
                    <div className="mt-4">
                        <button type="submit" className="btn btn-success text-uppercase rounded-pill pl-4 pr-4 w-75" onClick={props.onShowLogin.bind(this)}>Đăng nhập ngay</button>
                    </div>
                </div>
            }
            {props.state.isRegistration &&
                <div className="change-password-success text-center">
                    <img src={require('assets/images/success-filled.svg')} className="img-fluid mb-3" alt="Success" />
                    <h5>Đăng ký tài khoản thành công!</h5>
                    <div className="mt-4">
                        <button type="submit" className="btn btn-success text-uppercase rounded-pill pl-4 pr-4 w-75" onClick={props.onShowLogin.bind(this)}>Đăng nhập ngay</button>
                    </div>
                </div>
            }
        </div >
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ResetPassword: resetPasswordActions.ResetPassword
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordDesktopView);
