//@ts-check
import React, { useEffect } from 'react';
import ShopHomePageDesktop from '../child-component/shop-home-page/shop-home-page.view'
import * as PageUrls from '../../PageUrls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiseStore from '../../../redux/stores/utillities/utilitis-shop.store';

/**
 * @param {{GetShopList:Function,cafeList:{
 * address: string,
 logoUrl: string,
 lat: number,
 lng: number,
 id: number,
 name: string,}[]}} [props]
*/
function CafeHomeViewDesktop(props) {
    useEffect(() => {
        props.GetShopList(1, 9, UtilitiseStore.CAFEKEY)
    }, [])
    return <ShopHomePageDesktop currentTypeUrl={PageUrls.UtilitiesCafeHomeDeskTop} viewMoreUrl={PageUrls.UtilitiesCafeListPageDeskTop} detailUrl={PageUrls.UtilitiesCafeDetailDeskTop} listData={props.cafeList} shopTypeTile="Cafe" />
}

const mapStateToProps = state => ({
    cafeList: state.utilitiShop.cafeList.listShop,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopList: UtilitiseStore.GetShopList,
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(CafeHomeViewDesktop);