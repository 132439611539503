/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import "./add-resident-market-mobile.scss";
import * as residentMarketActions from "redux/stores/resident/resident-market.store";
import { JwtHelper } from "utils/jwt-helper";
import {
  NewGuid,
  ValidImageTypes,
  convertTextareaToHtml,
  validateFile,
  CheckMaxFile,
  NotificationMessageType,
} from "utils/configuration";
import { history } from "redux/store";
import { Error } from "components/support-create-form/validate-style";
import AddNotification from "components/react-notifications/react-notifications";

function CreateOrUpdateResidentMarketForm(props) {
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [previewAttachments, setPreviewAttachment] = useState([]);
  const [adFiles, setAdFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const onSubmit = (data) => {
    if (!adFiles.length) {
      return;
    }

    data.ad_file = [...adFiles];
    data.content = convertTextareaToHtml(data.content);
    props.onCreateOrUpdateResidentMarket &&
      props.onCreateOrUpdateResidentMarket(data);
  };

  const onFileChange = (event) => {
    let files = Array.from(event.target.files);
    if (files && files.length) {
      // validate maximum length
      var validateMsg = CheckMaxFile(adFiles.length + files.length);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      // validate file
      files.map((file) => {
        if (validateFile(file)) {
          validateMsg = validateFile(file);
        }
      });
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage("");

      var previewImgs = [];
      var imgFiles = [];
      files.map((file) => {
        var reader = new FileReader();
        reader.onload = (e) => {
          previewImgs = [
            ...previewImgs,
            {
              attachment_url: e.target.result,
              attachment_title: file.name,
              attactment_id: NewGuid(),
              fileType: ValidImageTypes.includes(file.type)
                ? "image"
                : file.type,
            },
          ];
          setPreviewAttachment([...previewAttachments, ...previewImgs]);
        };
        reader.readAsDataURL(file);
        imgFiles = [...imgFiles, file];
        setAdFiles([...adFiles, ...imgFiles]);
      });
    }
  };

  const onRemovedAttachment = (acttactmentId) => {
    setPreviewAttachment(
      previewAttachments.filter((att) => att.attactment_id != acttactmentId)
    );
  };

  return (
    <div className="advertising-desktop">
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m/rao-vat">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">Thêm sản phẩm</div>
            </div>
          </div>
        </div>
      </header>

      <form
        className="add-resident-market-mobile p-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-group">
          <textarea
            name="content"
            className="form-control"
            placeholder="Mô tả sản phẩm"
            rows="5"
            ref={register({
              required: "Trường bắt buộc",
              minLength: {
                value: 6,
                message: "Nội dung phải có ít nhất 6 ký tự",
              },
            })}
          ></textarea>
          {errors.content && <Error content={errors.content.message} />}
        </div>
        <div className="resident-market-upload">
          <div className="row">
            {previewAttachments.map((att) => (
              <div
                key={att.attactment_id}
                className="col-4 position-relative mb-4 attachment-wrapper"
              >
                <img
                  src={att.attachment_url}
                  alt={att.attachment_title}
                  className="img-resident-market img-fluid"
                />
                <div className="overlay"></div>
                <img
                  onClick={() => onRemovedAttachment(att.attactment_id)}
                  src={require("assets/images/close-white.svg")}
                  alt="close-white"
                  className="img-close-white"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 file-upload">
            <div className="title-upload">
              <img
                src={require("assets/images/collections.png")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <span>Upload ảnh</span>
            </div>
            <input
              type="file"
              name="ad_file"
              accept=".png, .jpg, .jpeg"
              multiple
              className="media-file"
              onChange={onFileChange.bind(this)}
              ref={register({
                required: { value: true, message: "Trường bắt buộc" },
              })}
            />
            {errors.ad_file && <Error content={errors.ad_file.message} />}
            {validateImgMessage && <Error content={validateImgMessage} />}
          </div>
        </div>

        <div className="form-group">
          <button type="submit" className="btn btn-success rounded-pill w-100">
            Thêm sản phẩm
          </button>
        </div>
      </form>
    </div>
  );
}

class AddResidentMarketMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
    };
  }

  UNSAFE_componentWillMount() {}

  onCreateOrUpdateResidentMarket = (data) => {
    let submitedData = {
      ...data,
      customer_id: this.state.currentUser.customerId,
      ad_name: "",
      comments: [],
    };
    this.props.createOrUpdateResidentMarket(submitedData).then(
      () => {
        AddNotification(
          "Tạo bài viết thành công, vui lòng chờ phê duyệt",
          NotificationMessageType.Success
        );
        history.push("/m/rao-vat/cho-cu-dan");
      },
      () => {}
    );
  };
  render() {
    return (
      <CreateOrUpdateResidentMarketForm
        onCreateOrUpdateResidentMarket={this.onCreateOrUpdateResidentMarket.bind(
          this
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateResidentMarket:
        residentMarketActions.CreateOrUpdateResidentMarket,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddResidentMarketMobileView);
