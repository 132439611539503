import "../../safe-desktop.scss";

import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as IncidentAreaStore from 'redux/stores/safe/incidents-area.store';
import Slider from "react-slick";
import "./incidents-area-detail-desktop.scss";
import { SetDefaultErrorImage } from "utils/configuration";
import { PromiseAllTools } from 'core/app.store';

class IncidentsAreaDetailDesktopView extends Component {

    UNSAFE_componentWillMount() {
        this.props.PromiseAllTools([this.props.GetIncidentsAreaDetail(Number(this.props.match.params.incedentid))])
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (this.props.incidentDetail && this.props.incidentDetail.feedbackId && (this.props.incidentDetail.feedbackId != Number(this.props.match.params.incedentid))) this.props.PromiseAllTools([this.props.GetIncidentsAreaDetail(Number(this.props.match.params.incedentid))])
    }

    render() {
        const data = this.props.incidentDetail || {};
        const slideFiles = data.files || [];
        const settingsSlider = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        }
        return (
            <div className="safe-desktop">
                <div className="incidents-area-detail">
                    <Slider {...settingsSlider} className="slider-detail">
                        {slideFiles.map((data, index) => <RenderSlide data={data} key={index} />)}
                        {(slideFiles.length === 0) && <img src="img-fluid w-100" className="" onError={SetDefaultErrorImage} />}
                    </Slider>
                    <div className="title row mt-3">
                        <div className="col-6">
                            <div className="h-100 float-left">
                                <img src={require('assets/images/place-red.svg')} alt="Image" className="img-fluid pl-2 pr-2" />
                            </div>
                            <div>
                                <span className="fake-link">{data.address}</span>
                                <br />
                                <span className="time">{data.createDate}</span>
                            </div>
                        </div>
                        <div className="col-6 text-right">
                            {!data.status ? <span className="pr-2 status text-primary">Đang xử lý</span> : <span className="pr-2 status text-success">Đã xử lý</span>}
                        </div>
                    </div>
                    <div className="description mt-3">
                        <p>
                            {data.content}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

function RenderVideo(props) {
    const [isVideoError, setVideoError] = useState(false);
    const [isFirtTime, setFirstTime] = useState(true);
    function changeState() {
        if (isFirtTime) {
            setFirstTime(false)
            setVideoError(true)
        }
    }
    return (
        <Fragment>
            {(!isVideoError) && <video className="w-100" controls>
                <source src={props.urlVideo} type="video/mp4" onError={changeState} />
        Your browser does not support the video tag.
        </video>}
            {isVideoError && <img src="" className="w-100" onError={SetDefaultErrorImage} />}
        </Fragment>

    )
}
function RenderSlide(props) {
    const data = props.data;
    console.log(data)
    const content = (data.fileType === "image") ? <img src={data.fileUrl} className="img-fluid w-100" onError={SetDefaultErrorImage} /> : <RenderVideo urlVideo={data.fileUrl} />;
    return (
        <div>
            {content}
        </div>
    )
}

const mapStateToProps = state => ({
    incidentDetail: state.incidentsArea.incidentDetail
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetIncidentsAreaDetail: IncidentAreaStore.GetIncidentsAreaDetail,
            PromiseAllTools
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsAreaDetailDesktopView);
