/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "redux/store";
import { Dropdown } from "react-bootstrap";
import ReactStarsRating from "react-awesome-stars-rating";
import AddNotification from "components/react-notifications/react-notifications";
import * as repairingHouseholdActions from "redux/stores/utilities-plus/repairing-household.store";
import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  SetDefaultErrorImage,
} from "utils/configuration";
import RenderModel from "./popup-desktop";
import { JwtHelper } from "utils/jwt-helper";

import "../../utilities-plus-desktop.scss";

class RepairingHouseholdDetailDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      repairingHouseholdId: this.props.match.params.id,
      evaluate: null,
      showEdit: false,
      showDelete: false,
      score: 0,
      vote: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetRepairingHouseholdDetail();
  }

  onGetRepairingHouseholdDetail = () => {
    this.props
      .getRepairingHouseholdDetail(this.state.repairingHouseholdId)
      .then(
        (res) => {
          if (res.content) {
            let score = 0;
            if (res.content.listVote && res.content.listVote.length > 0) {
              res.content.listVote.map((item) => {
                score += parseFloat(item.starNumber);
              });
              score = score / res.content.listVote.length;
            }
            this.setState({
              item: res.content,
              evaluate: res.content.listVote,
              score: score,
            });
          } else {
            AddNotification(
              viVN.Errors[res.errorType],
              NotificationMessageType.Error
            );
            history.push("/sua-chua-lap-dat");
          }
        },
        (err) => {}
      );
  };

  onDeleteVote = (id) => {
    this.props.deleteVote(id).then(
      (res) => {
        if (res && res.content) {
          AddNotification(viVN.Success.Delete, NotificationMessageType.Success);
          this.onGetRepairingHouseholdDetail();
          this.handleClose();
        }
      },
      (err) => {}
    );
  };

  onUpdateEvaluate = (data) => {
    if (data) {
      this.props.addOrUpdateEvaluate(data).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.CreateEvaluate,
              NotificationMessageType.Success
            );
            this.onGetRepairingHouseholdDetail();
            this.handleClose();
          }
        },
        (err) => {}
      );
    }
  };

  setShowEdit = (data) => {
    this.setState({
      showEdit: true,
      showDelete: false,
      vote: data,
    });
  };

  setShowDelete = (data) => {
    this.setState({
      showEdit: false,
      showDelete: true,
      vote: data,
    });
  };

  handleClose = () => {
    this.setState({
      showEdit: false,
      showDelete: false,
    });
  };

  render() {
    const { item, evaluate, score, showEdit, showDelete, vote } = this.state;
    const { isLoggedIn } = this.props;
    const userInfo = JwtHelper.currentUser();
    return (
      <div className="utilities-plus-desktop">
        {item && (
          <div>
            <img
              src={
                item.coverImage || require("assets/images/splendora-cover.png")
              }
              onError={SetDefaultErrorImage}
              alt={item.providerName}
              className="img-fluid w-100 image-cover"
            />
            <div className="box-body p-4">
              <div className="row">
                <div className="col-2">
                  <img
                    src={
                      item.avatar ||
                      require("assets/images/repairman-avatar.svg")
                    }
                    alt={item.providerName}
                    onError={SetDefaultErrorImage}
                    width="100"
                    height="100"
                    className="avatar"
                  />
                </div>
                <div className="col-10">
                  <span className="fullname-lg">{item.providerName}</span>
                  <br />
                  <span className="job">{item.serviceName}</span>
                  <br />
                  <span className="phone">SDT: {item.mobile_number}</span>
                  <br />
                  <span className="address">Địa chỉ: {item.address}</span>
                  <br />
                  {/* <span className="star-number">{item.vote.star}/{item.vote.totalVote}</span> */}
                  <span className="star-number">{item.vote.star}/5</span>
                  <br />
                  <ReactStarsRating
                    value={item.vote.star}
                    isEdit={false}
                    size={20}
                  />
                  <br />
                  <a
                    href={"tel:" + item.mobile_number}
                    className="btn btn-success mt-3"
                  >
                    <img
                      src={require("assets/images/phone-call.svg")}
                      alt="Phone Call"
                      width="15"
                    />{" "}
                    Liên hệ
                  </a>
                </div>
              </div>
            </div>
            {evaluate && (
              <div className="box-body p-4 mt-4">
                <div className="row">
                  <div className="col-12">
                    <h2 className="title-sm">Đánh giá ({evaluate.length})</h2>
                  </div>
                </div>
                <hr></hr>
                <div className="evaluate-information">
                  {evaluate.map((item) => (
                    <RenderEvaluateInformation
                      key={item.voteId}
                      user={userInfo}
                      data={item}
                      showEdit={showEdit}
                      showDelete={showDelete}
                      setShowEdit={this.setShowEdit.bind(this)}
                      setShowDelete={this.setShowDelete.bind(this)}
                      closeModal={this.handleClose.bind(this)}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="box-body p-4 mt-4">
              <EvaluateForm
                user={userInfo}
                isLoggedIn={isLoggedIn}
                serviceId={item.serviceId}
                onAddOrUpdateEvaluate={this.props.addOrUpdateEvaluate}
                onGetRepairingHouseholdDetail={this.onGetRepairingHouseholdDetail.bind(
                  this
                )}
              />
            </div>

            {vote && (
              <div>
                <RenderModel
                  isEdit={true}
                  show={showEdit}
                  vote={vote}
                  setShow={this.handleClose.bind(this)}
                  onUpdateEvaluate={this.onUpdateEvaluate.bind(this)}
                  serviceId={item.serviceId}
                />

                <RenderModel
                  isEdit={false}
                  show={showDelete}
                  vote={vote}
                  setShow={this.handleClose.bind(this)}
                  onDeleteVote={this.onDeleteVote.bind(this)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function RenderEvaluateInformation(props) {
  const item = props.data;
  return (
    <div className="row no-gutters evaluate-information-item">
      <div className="col-1">
        <img
          src={item.avatar || require("assets/images/29-12.png")}
          alt={item.customerName}
          onError={SetDefaultErrorImage}
          className="img-fluid avatar"
          width="40"
          height="40"
        />
      </div>
      <div className="col-11">
        <div className="row">
          <div className="col-9">
            <span className="fullname-md">{item.customerName}</span>
            <br />
            <span className="datetime">{item.voteDate}</span>
            <br />
            <ReactStarsRating
              value={parseFloat(item.starNumber.toString())}
              isEdit={false}
              size={20}
            />
            <br />
            <p className="comment mt-2 mb-0">{item.content}</p>
          </div>

          {props.user && props.user.customerId == item.customerId && (
            <div className="col-3 text-right">
              <Dropdown alignRight>
                <Dropdown.Toggle id="dropdown-basic">
                  <img
                    src={require("assets/images/more.svg")}
                    className="img-fluid img-more"
                    alt="more"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => props.setShowEdit(item)}>
                    <img
                      src={require("assets/images/pen.svg")}
                      className="img-fluid img-pen"
                      alt="pen"
                    />{" "}
                    Chỉnh sửa đánh giá
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => props.setShowDelete(item)}>
                    <img
                      src={require("assets/images/delete.svg")}
                      className="img-fluid img-delete"
                      alt="pen"
                    />{" "}
                    Xóa
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const EvaluateForm = (props) => {
  const [rating, setRating] = useState(5);

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (data) {
      let dataItem = {
        customer_id: props.user.customerId,
        service_id: props.serviceId,
        star_number: rating,
        content: data.evaluate,
      };
      props.onAddOrUpdateEvaluate(dataItem).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.CreateEvaluate,
              NotificationMessageType.Success
            );
            props.onGetRepairingHouseholdDetail();
            document.getElementById("addEvaluate").reset();
          }
        },
        (err) => {}
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="addEvaluate">
      <h2 className="title-sm">Xếp hạng</h2>
      <ReactStarsRating
        value={rating}
        onChange={(value) => setRating(value)}
        isEdit={props.isLoggedIn}
        size={20}
      />
      <h2 className="title-sm mt-2">Đánh giá</h2>
      <div className="form-group">
        <textarea
          name="evaluate"
          id="evaluate"
          className={"form-control " + (errors.evaluate ? "is-invalid" : "")}
          placeholder="Nội dung"
          rows="5"
          disabled={!props.isLoggedIn}
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        ></textarea>
        {errors.evaluate && (
          <div className="invalid-feedback">{errors.evaluate.message}</div>
        )}
      </div>
      <div className="text-right">
        <button
          type="submit"
          className="btn btn-success pl-4 pr-4"
          disabled={!props.isLoggedIn}
        >
          Gửi đi
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRepairingHouseholdDetail:
        repairingHouseholdActions.GetRepairingHouseholdDetail,
      addOrUpdateEvaluate: repairingHouseholdActions.AddOrUpdateEvaluate,
      deleteVote: repairingHouseholdActions.DeleteVote,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepairingHouseholdDetailDesktopView);
