//@ts-check


export function ExchangeShopDetail(rawData) {
    if (rawData) return {
        category: rawData.category,
        id: rawData.shopId,
        name: rawData.shopName,
        faceBook: rawData.fanpageUrl || "",
        introduce: rawData.content,
        timeOpen: rawData.openTime + ' - ' + rawData.closeTime,
        address: rawData.shopAddress,
        lat: rawData.latitude,
        lng: rawData.longitude,
        phone: rawData.hotline,
        imagesSlider: ExchangeimagesSlider(rawData.shopSlide || []),
        listImage: ExchangeListImage(rawData.shopImage || []),
    }
}

/**
 * @param {object} rawData - res.content
*/
export function ExchangeShopDetailWithCalendar(rawData) {
    if (rawData) return {
        id: rawData.tennisId || rawData.footballId || rawData.beautyId,
        name: rawData.tennisName || rawData.footballName || rawData.beautyName,
        faceBook: rawData.facebookUrl,
        introduce: rawData.content,
        timeOpen: rawData.openTime + " - " + rawData.closeTime,
        address: rawData.address,
        lat: rawData.latitude,
        lng: rawData.longitude,
        phone: rawData.mobileNumber,
        imagesSlider: ExchangeimagesSlider(rawData.slideImages || []),
        listImage: ExchangeListImage(rawData.albumImages || []),
        slotList: ExchangeSlotListData(rawData.slots)
    }
}

/**
 * @param {Array} listRaw - res.content.slots
 * @return {{
 * slotName: string,
 * slotId: number,
 * isFull: boolean,
 * }[]}
*/
export function ExchangeSlotListData(listRaw) {
    const result = [];
    if (listRaw) {
        listRaw.map(rawData => result.push({
            slotName: rawData.slotName,
            slotId: rawData.slotId,
            isFull: (!rawData.isReady) || (!rawData.isActive),
        }))
    }
    return result
}

/**
 * @param {{imageId:string,imageName:string}[]} listRaw
 * @returns {{src: string,
 *  thumbnail: string}[]}
*/
export function ExchangeListImage(listRaw) {
    const result = [];
    listRaw.map(data => result.push({ src: data.imageName, thumbnail: data.imageName }))
    return result
}

/**
 * @param {{imageId:string,imageName:string}[]} listRaw
 * @returns {{url:string}[]}
*/
export function ExchangeimagesSlider(listRaw) {
    const result = []
    listRaw.map(data => result.push({ url: data.imageName }))
    return result
}

export function ExchangeListSport(rawData) {
    const standardData = {
        haveData: false,
        listShop: [],
        total: rawData && rawData.totalItemCount || 0
    };

    if (rawData) {
        standardData.haveData = true;
        rawData.items.map((data) => {
            standardData.listShop.push({
                address: data.address,
                logoUrl: data.image || "",
                lat: Number(data.latitude) || 0,
                lng: Number(data.longitude) || 0,
                id: data.sportId,
                name: data.sportName,
                type: data.type
            })
        })
    }

    return standardData;
}

/**
 * @param {object} rawData - res.content
*/
export function ExchangeListSportTennis(rawData) {
    const standardData = {
        haveData: false,
        listShop: []
    };

    if (rawData.items) {
        standardData.haveData = true;
        standardData.total = rawData.totalItemCount;
        rawData.items.map((data) => {
            standardData.listShop.push({
                address: data.address,
                logoUrl: data.image || "",
                lat: Number(data.latitude) || 0,
                lng: Number(data.longitude) || 0,
                id: data.tennisId || data.gymId || data.footballId || data.poolId || data.beautyId,
                name: data.tennisName || data.gymName || data.poolName || data.footballName || data.beautyName,
            })
        })
    }

    return standardData;
}

export function ExchangeListShop(rawData) {
    const standardData = {
        haveData: false,
        listShop: []
    };

    if (rawData.items) {
        standardData.haveData = true;
        standardData.total = rawData.totalItemCount;
        rawData.items.map((data) => {
            standardData.listShop.push({
                address: data.shopAddress,
                logoUrl: data.logoUrl || "",
                lat: Number(data.latitude) || 0,
                lng: Number(data.longitude) || 0,
                id: data.shopId,
                name: data.shopName,
                category: data.category
            })
        })
    }

    return standardData;
}

/**
 * @param {any} rawData - res.content
*/
export function ExchangeSportGymPoolDetail(rawData) {
    if (rawData) {
        let from7to1 = [];
        let from2to6 = [];
        if (rawData.schedule) {
            const scheduleObject = JSON.parse(rawData.schedule);
            from7to1 = scheduleObject.from7to1;
            from2to6 = scheduleObject.from7to1;
        }
        return {
            id: rawData.gymId || rawData.poolId,
            name: rawData.gymName || rawData.poolName,
            faceBook: rawData.facebookUrl,
            introduce: rawData.content,
            timeOpen: rawData.openTime + " - " + rawData.closeTime,
            address: rawData.address,
            lat: rawData.latitude,
            lng: rawData.longitude,
            phone: rawData.mobileNumber,
            imagesSlider: ExchangeimagesSlider(rawData.slideImages || []),
            listImage: ExchangeListImage(rawData.albumImages || []),
            slotList: ExchangeSlotListData(rawData.slots || []),
            paymentGuide: rawData.paymentNote || '',
            from7to1: from7to1,
            from2to6: from2to6,
        }
    }
}