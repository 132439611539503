import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Viewer, { Worker, SpecialZoomLevel } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import "../management-desktop.scss";
import * as procedureActions from "redux/stores/management/procedure.store";
import * as viVN from "translation/vi-VN.json";
import { history } from "redux/store";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType } from "utils/configuration";
import { renderError } from "components/pdf-error-rendering/pdf-error-rendering";

import { browserVersion, isSafari } from "react-device-detect";

if (isSafari === true && browserVersion <= 11) {
  require("intersection-observer");
}

class ProcedureDetailDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procedure: null,
      procedureId: this.props.match.params.procedureId,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProcedureDetail(this.state.procedureId);
  }

  onGetProcedureDetail = (procedureId) => {
    this.props.getProcedureDetail(procedureId).then(
      (res) => {
        if (res.content) {
          this.setState({
            procedure: res.content,
          });
        } else {
          AddNotification(
            viVN.Errors[res.errorType],
            NotificationMessageType.Error
          );
          history.push("/danh-muc/thu-tuc-bql");
        }
      },
      (err) => {}
    );
  };

  render() {
    const { procedure } = this.state;
    const fileExtension =
      procedure && procedure.url && procedure.url.split(".");
    const errorFile =
      fileExtension &&
      fileExtension.length > 0 &&
      fileExtension[fileExtension.length - 1] === "pdf"
        ? false
        : true;
    return (
      <div className="management-desktop">
        <h2 className="title-lg mb-0">Thủ tục BQL</h2>
        <hr className="mt-2" />

        {procedure && <h4 className="title-sm">{procedure.name}</h4>}

        {/* <div className="management-tags mt-3 mb-4">
          <ul>
            <li>
              <a href="#">
                <img src={require("assets/images/tag.svg")} alt="tag" className="img-tag" /> Tags
              </a>
            </li>
            <li>
              <a href="#">
                thủ tục BQL
              </a>
            </li>
            <li>
              <a href="#">
                biểu mẫu
              </a>
            </li>
            <li>
              <a href="#">
                biểu mẫu đăng ký
              </a>
            </li>
            <li>
              <a href="#">
                đăng ký cải tạo
              </a>
            </li>
            <li>
              <a href="#">
                phiếu đăng ký
              </a>
            </li>
            <li>
              <a href="#">
                thủ tục
              </a>
            </li>
          </ul>
        </div> */}
        {errorFile && <p>Không đúng định dạng</p>}

        {!errorFile && procedure && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div className="pdf-view-container">
              <Viewer
                fileUrl={procedure.url}
                defaultScale={SpecialZoomLevel.PageFit}
                renderError={renderError}
              />
            </div>
          </Worker>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProcedureDetail: procedureActions.GetProcedureDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcedureDetailDesktopView);
