import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../payment-mobile.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OtherBillStores from '../../../../redux/stores/payment/other-services/other-service.store';
import { PromiseAllTools } from 'core/app.store';
import { convertTextareaToHtml } from "utils/configuration";

function PaymentOtherServiceGuideMobileView(props) {

    const FeePaymentGuideDocument = { __html: convertTextareaToHtml(props.feePaymentGuide) || "Không có dữ liệu để hiển thị" };
    const ParkingPaymentGuideDocument = { __html: convertTextareaToHtml(props.parkingPaymentGuide) || "Không có dữ liệu để hiển thị" };

    useEffect(() => {
        props.PromiseAllTools([
            props.GetParkingPaymentGuide(),
            props.GetFeePaymentGuide(),
        ])
    }, [])

    return (
        <div className="payment-mobile">
            <header className="header-mobile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/m/thanh-toan/dich-vu-khac">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                            <div className="header-title text-center">Thanh toán hóa đơn</div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="payment-guide-detail p-3" dangerouslySetInnerHTML={FeePaymentGuideDocument}>
            </div>
            <div className="payment-guide-detail p-3" dangerouslySetInnerHTML={ParkingPaymentGuideDocument}>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    feePaymentGuide: state.otherBill.feePaymentGuide,
    parkingPaymentGuide: state.otherBill.parkingPaymentGuide,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetFeePaymentGuide: OtherBillStores.GetFeePaymentGuide,
    GetParkingPaymentGuide: OtherBillStores.GetParkingPaymentGuide,
    PromiseAllTools
},
    dispatch
)
export default connect(mapStateToProps, mapDispatchToProps)(PaymentOtherServiceGuideMobileView)