/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Error } from 'components/support-create-form/validate-style';
import AddNotification from 'components/react-notifications/react-notifications';
import * as rentToMarketActions from 'redux/stores/rent-to-market/rent-to-market.store';
import { convertTextareaToHtml, convertHtmlToTextarea, NotificationMessageType } from 'utils/configuration';
import { JwtHelper } from "utils/jwt-helper";
import { Link } from 'react-router-dom';
import './edit-rent-to-market-mobile.scss';
import { history } from 'redux/store';

function UpdateRentToMarketForm(props) {
    const { register, errors, handleSubmit } = useForm({
        mode: "onChange"
    });

    const intialValues = {
        content: convertHtmlToTextarea(props.model.content),
        mobile: props.model.mobileNumber,
    };

    const onSubmit = data => {
        data.content = convertTextareaToHtml(data.content);
        props.onUpdateRentToMarket && props.onUpdateRentToMarket(data);
    };

    const onFormatNumber = (event) => {
        return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57
    }

    return (
        <div className="edit-rent-to-market-mobile p-3">
            <form className="form-edit-rent-to-market-mobile" onSubmit={handleSubmit(onSubmit)} >
                <div className="form-group">
                    <label htmlFor="mobile_number">Số điện thoại</label>
                    <input defaultValue={intialValues.mobile} min='0' onKeyPress={(e) => onFormatNumber(e)} type="number" name="mobile_number" className="form-control" placeholder="Số điện thoại"
                        ref={register({
                            required: 'Trường bắt buộc',
                            minLength: {
                                value: 10,
                                message: "Số điện thoại ít nhất 10 ký tự"
                            }
                        })} />
                    {errors.mobile_number && <Error content={errors.mobile_number.message} />}
                </div>
                <div className="form-group">
                    <label htmlFor="content">Nội dung</label>
                    <textarea defaultValue={intialValues.content} name="content" className="form-control" rows="5" placeholder="Nội dung"
                        ref={
                            register({
                                required: 'Trường bắt buộc',
                                minLength: {
                                    value: 6,
                                    message: "Nội dung phải có ít nhất 6 ký tự"
                                }
                            })}></textarea>
                    {errors.content && <Error content={errors.content.message} />}
                </div>
                <div className="row mt-3 align-items-center">
                    <div className="col-6"></div>
                    <div className="col-6 text-right">
                        <button type="submit" className="btn btn-success">Lưu chỉnh sửa</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
class EditRentToMarketMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: JwtHelper.currentUser(),
            rentToMarketPost: null,
            assistId: this.props.match.params.assistId,
        }
    }

    UNSAFE_componentWillMount() {
        this.onGetRentToMarketPostDetail();
    }

    onGetRentToMarketPostDetail = () => {
        if (this.state.assistId) {
            this.props.getRentToMarketDetail(this.state.assistId).then(res => {
                this.setState({
                    rentToMarketPost: res.content
                })
            }, err => { 
            })
        }
    }

    onUpdateRentToMarket = (data) => {
        let submitedData = {
            ...data,
            assist_id: this.state.assistId,
            customer_id: this.state.currentUser.customerId,
        }
        this.props.createOrUpdateRentToMarket(submitedData).then(res => {
            AddNotification('Cập nhật bài viết thành công!', NotificationMessageType.Success);
            history.push('/m/rao-vat/di-cho-thue')
        }, err => { 
            this.props.onToggleEditPopup(false);
        })
    }

    render() {
        const { rentToMarketPost } = this.state;

        return (
            <div className="resident-editting">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/rao-vat/di-cho-thue">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Chỉnh sửa tin</div>
                            </div>
                        </div>
                    </div>
                </header>
                {rentToMarketPost && <UpdateRentToMarketForm onUpdateRentToMarket={this.onUpdateRentToMarket.bind(this)} model={rentToMarketPost} />}
            </div>
        )
    }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    createOrUpdateRentToMarket: rentToMarketActions.CreateOrUpdateRentToMarket,
    getRentToMarketDetail: rentToMarketActions.GetRentToMarketDetail,
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(EditRentToMarketMobileView)