import axios from 'axios';
import * as apiConfig from './api.config';
import * as configuration from '../utils/configuration';

configuration.setConfiguration(configuration.ApiServerKey.APP_API_ROOT, apiConfig.api);
const apiRoot = configuration.getConfiguration(configuration.ApiServerKey.APP_API_ROOT);
const isHandlerEnabled = true;

const requestHandler = (request) => {
    if (isHandlerEnabled) {
        request.headers.common['Accept'] = 'application/json';
    }
    
    return request;
}

const successHandler = (response, isHandlerEnabled) => {
    if (isHandlerEnabled) {
        //TODO: Do Success Handler
    }

    return response;
}

const errorHandler = (error, isHandlerEnabled) => {
    if (isHandlerEnabled) {
        //TODO: Do Error Handler
    }

    return Promise.reject({ ...(error && error.response && error.response.data ? error.response.data : { errorType: 'UnknownError', errorMessage: "UnknownError" }) });
}

export default class Service {
    constructor(namespace, socketOpts) {
        this.namespace = namespace;
        this.axios = axios.create({
            baseURL: apiRoot,
            responseType: "json"
        });

        //Enable request interceptor
        this.axios.interceptors.request.use(
            request => requestHandler(request, isHandlerEnabled),
            error => errorHandler(error, isHandlerEnabled));

        //Response and Error handler
        this.axios.interceptors.response.use(
            response => successHandler(response, isHandlerEnabled),
            error => errorHandler(error, isHandlerEnabled));
    }

    /**
     * Get Http Request
     * @param {any} action 
     */
    get(action) {
        return new Promise((resolve, reject) => {
            this.axios.request(action, {
                method: 'GET',
            }).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.error) {
                    console.error("REST request error!", error.response.data.error);
                    reject(error.response.data.error);
                } else
                    reject(error);
            })
        })
    }

    /**
     * Post Http Request
     * @param {any} action 
     * @param {any} parrams 
     */
    post(action, parrams) {
        return new Promise((resolve, reject) => {
            this.axios.request(action, {
                method: 'POST',
                data: parrams
            }).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.error) {
                    console.error("REST request error!", error.response.data.error);
                    reject(error.response.data.error);
                } else
                    reject(error);
            })
        })
    }

    /**
     * Put Http Request
     * @param {any} action 
     * @param {any} parrams 
     */
    put(action, parrams) {
        return new Promise((resolve, reject) => {
            this.axios.request(action, {
                method: 'PUT',
                data: parrams
            }).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.error) {
                    console.error("REST request error!", error.response.data.error);
                    reject(error.response.data.error);
                } else
                    reject(error);
            })
        })
    }
}