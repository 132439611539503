import React, { Component } from 'react';
import './not-found-desktop.scss';

class NotFoundDesktopView extends Component {
    render() {
        return (
            <div className="not-found-desktop">
                <div className="not-found-content">
                    <h1>404</h1>
                    <p>
                        WE are sorry, but the page you requested was not found
                    </p>
                    <a href="/" className="btn btn-success rounded-pill">Trang chủ</a>
                </div>
            </div>
        );
    }
}

export default NotFoundDesktopView;