/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Error } from 'components/support-create-form/validate-style';
import AddNotification from 'components/react-notifications/react-notifications';
import * as hourlyMaidActions from 'redux/stores/hourly-maid/hourly-maid.store';
import { convertTextareaToHtml, convertHtmlToTextarea, NotificationMessageType } from 'utils/configuration';
import { JwtHelper } from "utils/jwt-helper";
import { Link } from 'react-router-dom';
import './edit-hourly-maid-mobile.scss';
import { history } from 'redux/store';

function UpdateHourlyMaidForm(props) {
    const { register, errors, handleSubmit } = useForm({
        mode: "onChange"
    });

    const initialValues = {
        content: convertHtmlToTextarea(props.model.content),
        mobile: props.model.mobileNumber,
    };

    const onSubmit = data => {
        data.content = convertTextareaToHtml(data.content);
        props.onUpdateHourlyMaid && props.onUpdateHourlyMaid(data);
    };

    return (
        <div className="edit-hourly-maid-mobile p-3">
            <form className="form-add-resident-market" onSubmit={handleSubmit(onSubmit)} >
                <div className="form-group">
                    <label htmlFor="mobile_number">Số điện thoại</label>
                    <input defaultValue={initialValues.mobile} type="text" name="mobile_number" className="form-control" placeholder="Số điện thoại"
                        ref={register({
                            required: 'Trường bắt buộc',
                            pattern: { value: /(\(?\+[0-9]{2}\)|0)?([0-9]{9,10})/i, message: "Số điện thoại không hợp lệ" }
                        })} />
                    {errors.mobile_number && <Error content={errors.mobile_number.message} />}
                </div>
                <div className="form-group">
                    <label htmlFor="content">Đánh giá</label>
                    <textarea defaultValue={initialValues.content} name="content" className="form-control" rows="5" placeholder="Nội dung"
                        ref={register({
                            required: 'Trường bắt buộc',
                            minLength: { value: 6, message: "Nội dung phải có ít nhất 6 ký tự" }
                        })}></textarea>
                    {errors.content && <Error content={errors.content.message} />}
                </div>
                <div className="row mt-3 align-items-center">
                    <div className="col-12 text-right">
                        <Link to="/m/rao-vat/giup-viec-theo-gio" className="btn btn-secondary rounded-pill pl-3 pr-3" >Hủy</Link>
                        <button type="submit" className="btn btn-success ml-2">Lưu chỉnh sửa</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

class EditHourlyMailMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: JwtHelper.currentUser(),
            hourlyMaidPost: null,
            assistId: this.props.match.params.assistId,
        }
    }

    UNSAFE_componentWillMount() {
        this.onGetHourlyMaidPostDetail();
    }

    onGetHourlyMaidPostDetail = () => {
        if (this.state.assistId) {
            this.props.getHourlyMaidDetail(this.state.assistId).then(res => {
                this.setState({
                    hourlyMaidPost: res.content
                })
            }, () => {

            })
        }
    }

    onUpdateHourlyMaid = (data) => {
        let submitedData = {
            ...data,
            assist_id: this.state.assistId,
            customer_id: this.state.currentUser.customerId,
        }
        this.props.createOrUpdateHourlyMaid(submitedData).then(() => {
            AddNotification('Cập nhật bài viết thành công!', NotificationMessageType.Success);
            history.push('/m/rao-vat/giup-viec-theo-gio')
        }, () => {
            this.props.onToggleEditPopup(false);
        })
    }

    render() {
        const { hourlyMaidPost } = this.state;

        return (
            <div className="advertising-mobile">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/rao-vat/giup-viec-theo-gio">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Chỉnh sửa tin</div>
                            </div>
                        </div>
                    </div>
                </header>
                {hourlyMaidPost && <UpdateHourlyMaidForm onUpdateHourlyMaid={this.onUpdateHourlyMaid.bind(this)} model={hourlyMaidPost} />}
            </div>
        )
    }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    createOrUpdateHourlyMaid: hourlyMaidActions.CreateOrUpdateHourlyMaid,
    getHourlyMaidDetail: hourlyMaidActions.GetHourlyMaidDetail,
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(EditHourlyMailMobileView)