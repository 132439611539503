import { createFormData, AppKey } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();

export const GetProductList = (pageIndex, size, filter) => {
  return () => {
    let formData = new FormData();
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    let payLoad = createFormData(filter, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.GetListProduct, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetProductDetail = (productId) => {
  return () => {
    let payLoad = createFormData({
      productId: productId,
    });
    return service
      .post(ApiUrl.GetProduct, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateProduct = (data) => {
  return () => {
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
    return service
      .post(ApiUrl.InsertRental, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetContentByKeyword = (
  keyword = "chinh_sach_cho_thue_muon_do"
) => {
  return () => {
    let formData = new FormData();
    formData.append("keyword", keyword);
    let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.GetContentByKeyword, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};
