/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Table } from 'react-bootstrap';
import './on-duty-schedule-mobile.scss';
import * as scheduleActions from 'redux/stores/management/schedule.store';
import PaginationControl from 'components/pagination/pagination.view';
import moment from 'moment';
import { isTablet } from "react-device-detect";

moment.locale('vi');

class OnDutyScheduleMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            currentPageIndex: 1
        };
    }

    UNSAFE_componentWillMount() {
        const filterObject = {
            from: this.state.startDate,
            to: this.state.endDate,
        };
        this.onGetScheduleList(1, 10, filterObject);
    }

    onGetScheduleList = (pageIndex = this.state.currentPageIndex, size = 10, filterObject = null) => {
        this.props.getScheduleList(pageIndex, size, filterObject).then(res => {
        }, err => {
        })
    }

    onFilterChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, currentPageIndex: 1 },
            () => {
                this.onGetScheduleList(1, 10);
            });
    }

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetScheduleList(pageNumber);
        })
    }

    handleChangeStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    handleChangeEndDate = date => {
        this.setState({
            endDate: date
        });
    };

    handleChangeScheduleList = () => {
        const filterObject = {
            from_date: moment(this.state.startDate).format('YYYY-MM-DD'),
            to_date: moment(this.state.endDate).format('YYYY-MM-DD'),
        };
        this.onGetScheduleList(1, 10, filterObject);
    }

    render() {
        const { scheduleList, totalItems } = this.props;
        const { currentPageIndex } = this.state;

        return (
            <div className="on-duty-schedule-mobile">
                <div className="container">
                    <div className="row ml-n1 mr-n1">
                        {isTablet && <div className="col-2"></div>}
                        <div className={"position-relative" + (isTablet ? " col-4 pl-1 pr-1" : " col-5 pl-1 pr-1")}>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                className="form-control rounded-pill"
                                dateFormat="dd/MM/yyyy"
                                popperPlacement="bottom-end"
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: '5px, 10px'
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: 'viewport'
                                    }
                                }} />
                            <img src={require("assets/images/calendar.svg")} alt="calendar" className={isTablet ? "img-calendar-tablets" : "img-calendar"} />
                        </div>
                        <div className={"position-relative" + (isTablet ? " col-4 pl-1 pr-1" : " col-5 pl-1 pr-1")}>
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                className="form-control rounded-pill"
                                dateFormat="dd/MM/yyyy"
                                popperPlacement="bottom-end" />
                            <img src={require("assets/images/calendar.svg")} alt="calendar" className={isTablet ? "img-calendar-tablets" : "img-calendar"} />
                        </div>
                        <div className="col-2 text-right pl-1 pr-1">
                            <button className="btn btn-success rounded-pill w-100" onClick={this.handleChangeScheduleList.bind(this)}>
                                <img src={require("assets/images/search-white.svg")} alt="Search White" className="img-search-white" />
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Người trực</th>
                                        <th>Thời gian trực</th>
                                        <th>Nhiệm vụ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scheduleList.map(item => <tr key={item.agentId}>
                                        <td>
                                            <h5 className="mb-0 align-middle text-capitalize">{item.agentName}</h5>
                                            <span>{item.mobileNumber}</span>
                                        </td>
                                        <td className="align-middle">{item.shiftName} - {item.workDate}</td>
                                        <td className="align-middle">{item.mission}</td>
                                    </tr>)}
                                    {!scheduleList.length && <tr><td className="text-center" colSpan="3">Không tìm thấy dữ liệu</td></tr>}
                                </tbody>
                            </Table>
                            <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    scheduleList: state.schedule.scheduleList,
    totalItems: state.schedule.totalItems,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getScheduleList: scheduleActions.GetScheduleList,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(OnDutyScheduleMobileView);