/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import "../footer/footer-desktop.scss";
import { Link } from "react-router-dom";
class FooterDesktopView extends Component {
  render() {
    return (
      <footer className="mt-5">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="text-uppercase mb-4">
                  Công ty cổ phần công nghệ cao CTECH
                </h3>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                <div className="address">
                  <img
                    src={require("assets/images/place-red.svg")}
                    alt="Place"
                    className="img-fluid"
                  />{" "}
                  Tầng 1 tòa E, chung cư Vinaconex 1, 289A Khuất Duy Tiến, Trung Hòa, Cầu Giấy, Hà Nội
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                <div className="contact">
                  <h5 className="text-uppercase mb-3">Liên hệ với chúng tôi</h5>
                  <ul>
                    <li>
                      <img
                        src={require("assets/images/phone-call-round.svg")}
                        alt="Place"
                        className="img-fluid"
                      />{" "}
                      <span>
                        Tel: <a href="tel:02462943030">02462943030</a>
                      </span>
                    </li>
                    <li>
                      <img
                        src={require("assets/images/fax.svg")}
                        alt="Place"
                        className="img-fluid"
                      />{" "}
                      <span>Fax: 02462943030</span>
                    </li>
                    <li>
                      <img
                        src={require("assets/images/email.svg")}
                        alt="Place"
                        className="img-fluid"
                      />{" "}
                      <span>
                        E-mail:{" "}
                        <a href="mailto:contact@ilang.vn">contact@ilang.vn</a>
                      </span>
                    </li>
                    <li>
                      <img
                        src={require("assets/images/globe.svg")}
                        alt="Place"
                        className="img-fluid"
                      />{" "}
                      <span>
                        Website:{" "}
                        <a href="https://iLang.vn" target="_blank">
                          https://iLang.vn
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                <div className="follow-us">
                  <h5 className="text-uppercase mb-3">Theo dõi chúng tôi</h5>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="#">
                        <img
                          src={require("assets/images/facebook.svg")}
                          alt="Facebook"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <img
                          src={require("assets/images/google.svg")}
                          alt="Google"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <img
                          src={require("assets/images/youtube.svg")}
                          alt="Youtube"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <img
                          src={require("assets/images/in.svg")}
                          alt="Linkedin"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                <div className="fanpage">
                  <h5 className="text-uppercase mb-3">FanPage Facebook</h5>
                  <a
                    href="https://www.facebook.com/www.iLang.vn"
                    target="_blank"
                  >
                    <img
                      src={require("assets/images/fanpage.png")}
                      alt="Fanpage"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>

              <div className="col-12 mt-5">
                <div className="menu-footer">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <Link to="/">Trang chủ</Link>
                    </li>
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <Link to="/thong-tin-bql">BQL</Link>
                    </li>
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <Link to="/hot">HOT!</Link>
                    </li>
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <Link to="/thanh-toan/tien-dien">Thanh toán</Link>
                    </li>
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <Link to="/tien-ich/cafe">Tiện ích</Link>
                    </li>
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <Link to="/sua-chua-lap-dat">Tiện ích +</Link>
                    </li>
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <Link to="/lang-gieng-gan/ket-ban">Láng giềng gần</Link>
                    </li>
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <Link to="/rao-vat/cho-cu-dan">Chợ làng</Link>
                    </li>
                    {/* <li className="list-inline-item">|</li> */}
                    {/* <li className="list-inline-item">
                                            <Link to="/an-toan">An toàn</Link>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <img
                    src={require("assets/images/project-info.svg")}
                    alt="Fanpage"
                    className="img-fluid"
                  />{" "}
                  <Link to={`/thong-tin-ve-du-an`}>Thông tin dự án</Link>
                  <img
                    src={require("assets/images/ilang-info.svg")}
                    alt="Fanpage"
                    className="img-fluid ml-4"
                  />{" "}
                  <a href="http://acudtech.vn/" target="_blank">
                    Về iLang
                  </a>
                  <img
                    src={require("assets/images/ban-quan-ly-filled-white.svg")}
                    alt="Fanpage"
                    className="img-fluid ml-4"
                  />{" "}
                  <a href="http://acudtech.vn/" target="_blank">
                    BQL
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-support">
          <a href="tel:02462943030">
            <img
              src={require("assets/images/phone-call-effect.svg")}
              alt="Phone Call"
            />
          </a>
        </div>
        <div className="messenger-support">
          <a href="https://www.messenger.com/t/www.iLang.vn" target="_blank">
            <img src={require("assets/images/messenger.svg")} alt="Messenger" />
          </a>
        </div>
      </footer>
    );
  }
}

export default FooterDesktopView;
