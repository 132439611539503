import { getCookies } from "utils/configuration";
export function getThisProjectLocation() {
  let result = {
    lat: 21,
    lng: 106,
  };
  if (getCookies("Location project")) {
    let projectsLocation = getCookies("Location project");
    let projectId = Number(getCookies("project"));
    projectsLocation.map((data) => {
      if (data.id == projectId) {
        result = {
          lat: Number(data.location.lat),
          lng: Number(data.location.lng),
        };
        return true;
      }
    });
  }
  return result;
}
