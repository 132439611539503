//@ts-check
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PaginationControl from '../../../../components/pagination/paging.view';
import * as PageUrls from '../../../PageUrls';
import * as CommonClass from '../../../../utils/css-common-class';
import UtilitiesListOther from '../../child-component/utilities-list-other/utilities-list-other.view';
import SportListView from '../childs/sport-list.view';
import FakeGymList from '../../../../mock-data/gym-list-data';
import FakePoolList from '../../../../mock-data/pool-list-data';
import FakeSoccerList from '../../../../mock-data/soccer-list-data';
import { bindActionCreators } from 'redux';
import * as UtilitiesStore from '../../../../redux/stores/utillities/utilitis-shop.store';

/**
 * @param {{
 * listGym:any[],
 * detailUrl:string,
 * totalGym?:number,
 * listData?:{
 * logoUrl: string;
 * name: string;
 * address: string;
 * id: number;
 * type: string;}[],
 * GetListGym?:(index:number,size:number)=>any,
 * }} [props]
*/
function GymListPageView(props) {
    const pageSize = 8;
    const pageIndex = 1;

    useEffect(() => {
        props.GetListGym(pageIndex, pageSize);
    }, [])
    return (
        <Fragment>
            <SportListView detailUrl={props.detailUrl} listData={props.listGym} />
            <PaginationControl goTo={(index) => props.GetListGym(index, pageSize)} totalItems={props.totalGym} index={pageIndex} size={pageSize} />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    listGym: state.utilitiShop.gymList.listShop,
    totalGym: state.utilitiShop.gymList.total,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListGym: UtilitiesStore.GetListGym,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GymListPageView)