import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { JwtHelper } from '../utils/jwt-helper';

const userInfo = JwtHelper.currentUser() || {};

const AppRoute = ({ component: Component, layout: Layout, authenRequired, needLoginToSee, needGoogleMapScript, ...rest }) => {
    return <Route
        {...rest}
        render={props => (
            authenRequired ? (JwtHelper.isAuthenticated() ?
                <Layout match={props.match} history={props.history} needLogin={needLoginToSee} needGoogleMapScript={needGoogleMapScript}>
                    <Component {...props} userInfo={userInfo} />
                </Layout>
                : <Redirect to='/login' />)
                : <Layout match={props.match} history={props.history} needLogin={needLoginToSee} needGoogleMapScript={needGoogleMapScript}>
                    <Component {...props} userInfo={userInfo} />
                </Layout>
        )}
    />
}

export default AppRoute;