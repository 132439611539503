/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
//---Css
import "../home/home-desktop.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as hotActions from "redux/stores/hot/hot.store";
import * as newActions from "redux/stores/news/news.store";
import * as advertisingActions from "redux/stores/advertising/advertising.store";

import {
  TokenKey,
  SetDefaultErrorImage,
  getCookies,
} from "utils/configuration";

class HomeDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      newList: [],
    };

    this.onGetHotList = this.onGetHotList.bind(this);
    this.onGetNewList = this.onGetNewList.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.onGetHotList(1, 3, { orderBy: "update_date", orderType: "DESC" });
    this.onGetNewList(1, 3, { orderBy: "update_date", orderType: "DESC" });
  }

  onGetHotList = (pageIndex, size, filterObjs) => {
    this.props.getHotList(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  onGetNewList = (pageIndex, size, filterObjs) => {
    this.props.getNewList(pageIndex, size, filterObjs).then(
      (res) => {
        if (res && res.content) {
          this.setState({
            newList: res.content.items,
          });
        }
      },
      (err) => {}
    );
  };

  render() {
    const settingsLife = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const { hotList, advertising } = this.props;
    const { newList } = this.state;
    const projectName = getCookies(TokenKey.ProjectName)
      ? getCookies(TokenKey.ProjectName)
      : "";

    const advertisingHomeDesktop =
      (advertising &&
        advertising.length > 0 &&
        advertising.filter(
          (item) =>
            item.positionCode == advertisingActions.POSITION_AD.DESKTOP_HOME_TOP
        )) ||
      [];

    return (
      <section className="home-desktop">
        {/*--- Section 1 ---*/}
        {newList && newList.length > 0 && (
          <div className="cuoc-song-splendora mb-4">
            <h2 className="title-header">Cuộc sống {projectName}</h2>
            <Slider {...settingsLife}>
              {newList.map((item) => (
                <div key={item.newsId} className="item">
                  <img
                    src={item.imageUrl}
                    onError={SetDefaultErrorImage}
                    alt={item.title}
                    className="img-fluid w-100"
                  />
                  <h5>
                    <Link to={`/cuoc-song/${item.newsId}`}>{item.title}</Link>
                  </h5>
                </div>
              ))}
            </Slider>
          </div>
        )}
        {/*--- /Section 1 ---*/}

        {advertisingHomeDesktop &&
          advertisingHomeDesktop.length > 0 &&
          advertisingHomeDesktop.map((item) => (
            <div
              key={`home-top-${item.advertisingId}`}
              className="mt-5 mb-4 advertising"
            >
              <a
                target="_blank"
                href={item.advertisingUrl}
                rel="noopener noreferrer"
              >
                <img
                  src={
                    item.advertisingFile ||
                    require("/assets/images/quang-cao.png")
                  }
                  alt={item.advertisingName}
                  className="object-fit-cover"
                />
              </a>
            </div>
          ))}

        {/*--- Section 2 ---*/}
        {hotList && hotList.length > 0 && (
          <div className="hot mb-4">
            <div className="row">
              <div className="col-6">
                <h2 className="title-header">HOT!</h2>
              </div>
              <div className="col-6 text-right">
                <Link to="/hot" className="discovery-link">
                  Khám phá
                </Link>
              </div>
            </div>

            {hotList && (
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    {hotList.map((item) => (
                      <RenderListHot key={item.hotId} data={item} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {/*--- /Section 2 ---*/}

        {/*--- Section 3 ---*/}
        <div className="tien-ich mb-4">
          <div className="row">
            <div className="col-6">
              <h2 className="title-header">Tiện ích</h2>
            </div>
            <div className="col-6 text-right">
              <Link to="/danh-sach-tien-ich" className="discovery-link">
                Khám phá
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <div className="item">
                <Link to="/tien-ich/the-thao">
                  <img src={require("assets/images/gym.svg")} alt="Thể thao" />
                </Link>
                <h5>
                  <Link to="/tien-ich/the-thao">Thể thao</Link>
                </h5>
              </div>
            </div>

            {/* <div className="col-3">
              <div className="item">
                <Link to="/tien-ich/phong-kham">
                  <img src={require("assets/images/clinic.svg")} alt="Phòng phám" />
                </Link>
                <h5>
                  <Link to="/tien-ich/phong-kham">Phòng phám</Link>
                </h5>
              </div>
            </div> */}

            <div className="col-3">
              <div className="item">
                <Link to="/tien-ich/lam-dep">
                  <img src={require("assets/images/spa.svg")} alt="Làm đẹp" />
                </Link>
                <h5>
                  <Link to="/tien-ich/lam-dep">Làm đẹp</Link>
                </h5>
              </div>
            </div>

            <div className="col-3">
              <div className="item">
                <Link to="/tien-ich/nha-hang">
                  <img
                    src={require("assets/images/nha-hang.svg")}
                    alt="Nhà hàng"
                  />
                </Link>
                <h5>
                  <Link to="/tien-ich/nha-hang">Nhà hàng</Link>
                </h5>
              </div>
            </div>

            <div className="col-3">
              <div className="item">
                <Link to="/tien-ich/cafe">
                  <img src={require("assets/images/cafe.svg")} alt="Cafe" />
                </Link>
                <h5>
                  <Link to="/tien-ich/cafe">Cafe</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/*--- /Section 3 ---*/}

        {/*--- Section 4 ---*/}
        <div className="thanh-toan">
          <h2 className="title-header">Thanh toán</h2>
          <div className="row">
            <div className="col-3">
              <div className="tien-dien text-center p-2">
                <Link to="/thanh-toan/tien-dien">
                  <img
                    src={require("assets/images/tien-dien.svg")}
                    alt="Tiền điện"
                  />
                </Link>
                <h5>
                  <Link to="/thanh-toan/tien-dien">Tiền điện</Link>
                </h5>
              </div>
            </div>
            <div className="col-3">
              <div className="tien-nuoc text-center p-2">
                <Link to="/thanh-toan/tien-nuoc">
                  <img
                    src={require("/assets/images/tien-nuoc.svg")}
                    alt="Tiền nước"
                  />
                </Link>

                <h5>
                  <Link to="/thanh-toan/tien-nuoc">Tiền nước</Link>
                </h5>
              </div>
            </div>
            <div className="col-3">
              <div className="internet text-center p-2">
                <Link to="/thanh-toan/internet">
                  <img
                    src={require("assets/images/internet.svg")}
                    alt="Internet"
                  />
                </Link>
                <h5>
                  <Link to="/thanh-toan/internet">Internet</Link>
                </h5>
              </div>
            </div>
            <div className="col-3">
              <div className="dich-vu-khac text-center p-2">
                <Link to="/thanh-toan/dich-vu-khac">
                  <img
                    src={require("assets/images/dich-vu-khac.svg")}
                    alt="Dịch vụ khác"
                  />
                </Link>
                <h5>
                  <Link to="/thanh-toan/dich-vu-khac">Dịch vụ khác</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/*--- /Section 4 ---*/}
      </section>
    );
  }
}

function RenderListHot(props) {
  const data = props.data;
  return (
    <div className="col-4">
      <div className="item">
        <Link to={`/hot/${data.hotId}`}>
          <img
            src={data.imageUrl}
            onError={SetDefaultErrorImage}
            alt={data.title}
            className="img-fluid img-avatar w-100"
          />
        </Link>
        <div className="content">
          <h5>
            <Link to={`/hot/${data.hotId}`}>{RenderText(data.title, 49)}</Link>
          </h5>

          <div className="row no-gutters align-items-center">
            <div className="col-1">
              <img src={require("assets/images/clock-7px.svg")} alt="Clock" />
            </div>
            <div className="col-11">
              <span className="time">
                Từ {data.fromDate} đến {data.toDate}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const RenderText = (textOld, length) => {
  let textNew = textOld.slice(0, length);
  if (textOld.length > length) textNew += "...";
  return textNew;
};

const mapStateToProps = (state) => ({
  hotList: state.hot.hotList,
  newList: state.new.newList,
  advertising: state.homeMobile.advertising,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHotList: hotActions.GetHotList,
      getNewList: newActions.GetNewList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeDesktopView);
