import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as joinsHandActions from "redux/stores/join-hand/join-hand.store";
import { convertTextareaToHtml, NewGuid, validateFile, NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import { Error, Success } from "components/support-create-form/validate-style";
import { JwtHelper } from "/utils/jwt-helper";
import { Link } from "react-router-dom";
import { history } from "redux/store";
import "../../neighbourhoods-mobile.scss";
import HandleErrorMessaget from '../../../../utils/error-message';

function CreateJoinsHandForm(props) {
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  const [previewImg, setPreviewImg] = useState("");
  const [isShowUpload, setShowUpload] = useState(true);
  const [isToggleVote, setToggleVote] = useState(false);
  const [voteOptions, setVoteOptions] = useState([]);
  const [topicImage, setTopicImage] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const onSubmit = async (data) => {
    if (!topicImage) {
      return;
    }
    data.content = data.content;
    data.options = [
      ...voteOptions.map((item) => {
        return { option_name: item.option_name };
      }),
    ];
    data.topic_image = topicImage;
    const clearAllFormField = () => {
      setValue("topic_name", null);
      setValue("location", null);
      setValue("responsible", null);
      setValue("content", null);
      setValue("poll_event_name", null);
      setValue("intend_time", null);
      setValue("topic_image", []);
      setVoteOptions([]);
      setPreviewImg("");
      setTopicImage(null);
    }
    props.onCreateOrUpdateJoinsHand && props.onCreateOrUpdateJoinsHand(data, clearAllFormField);
  };

  const onFileChange = (event) => {
    let file = event.target.files && event.target.files[0];

    if (file) {
      const validateMsg = validateFile(file);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }
      setValidateImgMessage('');

      var reader = new FileReader();
      reader.onload = (e) => {
        setValue("attachmentUrl", e.target.result);
        setPreviewImg(e.target.result);
        setShowUpload(false);
      };
      reader.readAsDataURL(file);
      setTopicImage(file);
    }
  };

  const onAddMoreVoteOption = () => {
    setVoteOptions([
      ...voteOptions,
      {
        poll_id: NewGuid(),
        option_name: "",
        voted_people: [],
      },
    ]);
  };

  const onRemovedVoteOption = (voteOptionId) => {
    setVoteOptions([
      ...voteOptions.filter((vote) => vote.poll_id != voteOptionId),
    ]);
  };

  const onChangeVoteName = (voteOptionId, e) => {
    const optionIndex = voteOptions.findIndex(
      (vote) => vote.poll_id == voteOptionId
    );
    if (optionIndex >= 0 && !!e.target.value) {
      let newOptions = [...voteOptions];
      newOptions[optionIndex].option_name = e.target.value.trim();
      setVoteOptions([...newOptions]);
    }
  };

  const onDeleteImg = () => {
    setShowUpload(true);
    setPreviewImg(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="create-joins-hand-mobile p-3"
    >
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <input
              type="text"
              name="topic_name"
              placeholder="Tên sự kiện..."
              className="form-control"
              disabled={!props.loggedIn}
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Tên sự kiện phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.topic_name && (
              <div className="mt-1">
                <Error content={errors.topic_name.message} />
              </div>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <input
              type="text"
              name="location"
              placeholder="Địa điểm tổ chức"
              className="form-control"
              disabled={!props.loggedIn}
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Địa điểm tổ chức sự kiện phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.location && (
              <div className="mt-1">
                <Error content={errors.location.message} />
              </div>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <input
              type="text"
              name="intend_time"
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setShowSuggestions(false)}
              placeholder="Thời gian diễn ra (Ví dụ: 08/05/2020 - 10/05/2020)"
              disabled={!props.loggedIn}
              className="form-control"
              ref={register({
                required: "Trường bắt buộc",
              })}
            />
            {errors.intend_time && (
              <div className="mt-1">
                <Error content={errors.intend_time.message} />
              </div>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <input
              type="text"
              name="responsible"
              placeholder="Người chịu trách nhiệm"
              className="form-control"
              disabled={!props.loggedIn}
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Tên người chịu trách nhiệm phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.responsible && (
              <div className="mt-1">
                <Error content={errors.responsible.message} />
              </div>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <textarea
              name="content"
              className="form-control"
              placeholder="Thông tin sự kiện..."
              disabled={!props.loggedIn}
              rows="5"
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Thông tin sự kiện phải có ít nhất 6 ký tự",
                },
              })}
            ></textarea>
            {errors.content && (
              <div className="mt-1">
                <Error content={errors.content.message} />
              </div>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <div className="row">
              {!!previewImg && (
                <div className="preview-img col-4">
                  <img
                    src={previewImg}
                    alt="Mua bán nhà đất"
                    className="img-attachment"
                  />
                  <div className="overlay"></div>
                  <img
                    onClick={onDeleteImg}
                    src={require("assets/images/close-white.svg")}
                    alt="close-white"
                    className="img-close-white"
                  />
                </div>
              )}
            </div>

            <div>
              {isShowUpload && (
                <div className="file-upload">
                  <input
                    type="text"
                    className="hidden-text-field"
                    id="attachmentUrl"
                    name="attachmentUrl"
                    ref={register}
                  />
                  <div className="title-upload">
                    <img
                      src={require("assets/images/collections.png")}
                      alt="Image"
                      className="img-fluid mr-2 mb-1"
                    />{" "}
                    <span>Upload ảnh</span>
                  </div>
                  <input
                    type="file"
                    name="topic_image"
                    accept=".png, .jpg, .jpeg"
                    disabled={!props.loggedIn}
                    multiple
                    className="media-file"
                    onChange={onFileChange.bind(this)}
                    ref={register({
                      required: { value: true, message: "Trường bắt buộc" },
                    })}
                  />
                  {errors.topic_image && (
                    <div className="mt-1">
                      <Error content={errors.topic_image.message} />
                    </div>
                  )}
                  {validateImgMessage && (
                    <div className="mt-1">
                      <Error content={validateImgMessage} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="render-vote">
            <div className="form-group position-relative">
              <div
                className={isToggleVote ? "create-vote form-control active" : "create-vote form-control"}
                onClick={() => props.loggedIn && setToggleVote(!isToggleVote)}>
                <span>Tạo bình chọn</span>
                <img src={require("assets/images/arrow-up.svg")}
                  className={`img-fluid ${isToggleVote ? "img-arrow-down" : "img-arrow-up"}`} />
              </div>

              {isToggleVote && (
                <div className="form-create-vote rounded-bottom">
                  <hr></hr>
                  {voteOptions.map((vote, index) => (
                    <div key={vote.poll_id} className="form-group">
                      <input
                        type="text"
                        name="option_name"
                        placeholder={`Phương án ${index + 1}`}
                        className="form-control"
                        onChange={(e) => onChangeVoteName(vote.poll_id, e)}
                      />
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={() => onRemovedVoteOption(vote.poll_id)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ))}
                  <div className="text-right">
                    <a
                      onClick={() => onAddMoreVoteOption()}
                      className="add-vote"
                    >
                      Thêm phương án +
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 position-relative text-right">
          <button
            type="submit"
            className="btn btn-success"
            disabled={!props.loggedIn}
          >
            Tạo sự kiện{" "}
            <img
              src={require("assets/images/plus-white.svg")}
              alt="plus"
              className="img-plus"
            />
          </button>
        </div>
      </div>
    </form>
  );
}

class CreateJoinsHandMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
    };
  }

  UNSAFE_componentWillMount() { }

  onCreateOrUpdateJoinsHand = (data, successCallBack = null) => {
    if (!this.props.loggedIn) return;

    let submitedData = {
      ...data,
      customer_id: this.state.currentUser && this.state.currentUser.customerId,
      topic_id: null,
    };
    this.props.createOrUpdateJoinsHand(submitedData).then(
      (res) => {
        AddNotification(
          "Tạo bài viết thành công, vui lòng chờ phê duyệt",
          NotificationMessageType.Success
        );
        successCallBack && successCallBack();
        history.push("/m/lang-gieng-gan/chung-tay");
      },
      (err) => {
        HandleErrorMessaget(err)
      }
    );
  };

  render() {
    const { loggedIn } = this.props;
    return (
      <div className="create-join-hand">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/lang-gieng-gan/chung-tay">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Tạo sự kiện</div>
              </div>
            </div>
          </div>
        </header>
        <CreateJoinsHandForm
          loggedIn={loggedIn}
          onCreateOrUpdateJoinsHand={this.onCreateOrUpdateJoinsHand.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateJoinsHand: joinsHandActions.CreateOrUpdateJoinsHand,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateJoinsHandMobileView);
