//@ts-check
import React, { useEffect } from 'react';
import RenderHeaderDetailView from '../childs/header-detail/header-detail.view';
import RenderFooterDetailView from '../childs/footer-detail/footer-detail.view';
import FakeGymDetail from '../../../../mock-data/gym-detail';
import RenderBodyDetailGymPool from '../childs/body-detail-gym-pool/body-detail-gym-pool.view';
import UtilitiesListOther from '../../child-component/utilities-list-other/utilities-list-other.view';
import * as PageUrls from '../../../PageUrls';
import * as UtilitiesStore from '../../../../redux/stores/utillities/utilitis-shop.store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * @param {{
 * GetShopDetail: Function,
 * PostRegisterUseGym:Function,
 * match:any,
 * shopDetail:any,
 * }} [props]
*/
function GymDetailViewDesktop(props) {

    /**
     * @param {{shopId: number,phoneNumber: string,userName: string,registerMonth: number}} paramsObject
    */
    const registerFunction = (paramsObject) => {
        const params = new FormData();
        params.append('data[register_name]', paramsObject.userName)
        params.append('data[gym_id]', String(paramsObject.shopId))
        params.append('data[mobile_number]', paramsObject.phoneNumber)
        params.append('data[month]', String(paramsObject.registerMonth))
        return props.PostRegisterUseGym(params)
    }

    useEffect(() => {
        props.GetShopDetail(props.match.params.id)
    }, [])

    return (<div>
        <RenderHeaderDetailView listPageUrl={PageUrls.UtilitiesGymDeskTop} data={props.shopDetail} />
        <RenderBodyDetailGymPool registerFunction={registerFunction} shopId={props.shopDetail.id} isGymBody={true} from2to6={props.shopDetail.from2to6} from7to1={props.shopDetail.from7to1} paymentGuide={props.shopDetail.paymentGuide} />
        <RenderFooterDetailView listImage={props.shopDetail.listImage} />
        <UtilitiesListOther currentTypeUrl={PageUrls.UtilitiesSportHomeDesktop} />
    </div>)
}

const mapStateToProps = state => ({
    shopDetail: state.utilitiShop.shopDetailWidthActiveCalendar,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiesStore.GetGymDetail,
    PostRegisterUseGym: UtilitiesStore.PostRegisterUseGym,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GymDetailViewDesktop)