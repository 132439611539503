import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "../search-desktop/search-result-desktop.scss";
import { GetSearchResulList } from 'redux/stores/search/search.store';
import Pagination from 'components/pagination/paging.view.jsx';

function SearchResultDesktopView(props) {
  const size = 8;
  const [pageIndex, setPageIndex] = useState(1);
  const [keyword, setKeyword] = useState("");

  function goToPage(i) {
    props.GetSearchResulList(i, size, keyword)
    setPageIndex(1);
  }

  useEffect(() => {
    setKeyword(props.match.params.keyword)
    props.GetSearchResulList(1, size, props.match.params.keyword)
  }, [props.match])

  const listResult = props.resultList || [];
  return (
    <div className="search-result-desktop">
      <div className="row">
        <div className="col-12 position-relative">
          <h2 className="title-lg">Kết quả tìm kiếm</h2>
          <div className="search-result-border"></div>
        </div>
      </div>

      <div className="mt-3 search-result-list">
        <ul>
          {listResult.map((data, index) => <RenderItem data={data} index={(pageIndex - 1) * size + index + 1} key={index} />)}
        </ul>
        {(listResult.length === 0) && <p>Không tìm thấy kết quả cho: {props.match.params.keyword}</p>}
      </div>
      <Pagination goTo={(i) => goToPage(i)} size={size} totalItems={props.totalItems} />
    </div>
  )
}

function RenderItem(props) {
  const data = props.data;
  return (
    <li>
      <a href={data.url} target="_blank">
        {props.index}. {data.title}
      </a>
    </li>
  )
}

const mapStateToProps = state => ({
  resultList: state.search.resultList,
  totalItems: state.search.totalResult
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetSearchResulList
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultDesktopView)