/* eslint-disable react/prop-types */
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import HelpingHandCommentMobileView from "./helping-hand-comment-mobile.view.jsx";

export default function RenderHelpingHandMobileContent(props) {
  const { data, currentUserId, loggedIn } = props;
  const [toggleContent, setToggleContent] = useState(false);

  return (
    <div className="row no-gutters">
      <div className="col-1">
        <img
          src={data.profileUrl || require("assets/images/29-01.png")}
          alt={data.customerName}
          className="avatar rounded-circle img-fluid"
        />
      </div>
      <div className="col-9 pl-2">
        {!toggleContent && (
          <div>
            {loggedIn && (
              <h5>
                <Link
                  to={`/m/lang-gieng-gan/giup-mot-tay/chi-tiet/${data.helpId}`}
                >
                  <p
                    dangerouslySetInnerHTML={{ __html: data.content }}
                    className="mb-0"
                  ></p>
                </Link>
              </h5>
            )}
            {!loggedIn && (
              <h5>
                <p
                  dangerouslySetInnerHTML={{ __html: data.content }}
                  className="mb-0"
                ></p>
              </h5>
            )}
            <div className="row no-gutters align-items-center">
              <div className="col-4">
                <span className="number-answer">
                  {loggedIn && (
                    <a onClick={() => setToggleContent(true)}>
                      {data.countComment} Trả lời{" "}
                      <img
                        src={require("assets/images/arrow-right-blue.svg")}
                        alt="arrow-right-blue"
                        className="img-arrow-right-blue"
                      />
                    </a>
                  )}
                </span>
              </div>
              {loggedIn && currentUserId == data.customerId && (
                <div className="col-8">
                  <a
                    onClick={() => props.onToggleDeletePopup(true, data.helpId)}
                  >
                    <img
                      src={require("assets/images/delete-blue.svg")}
                      alt="delete-blue"
                      className="img-delete-blue"
                    />
                  </a>
                  <Link
                    to={`/m/lang-gieng-gan/giup-mot-tay/chinh-sua-yeu-cau/${data.helpId}`}
                  >
                    <img
                      src={require("assets/images/edit-blue.svg")}
                      alt="edit-blue"
                      className="img-edit-blue"
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
        {toggleContent && (
          <div className="content">
            <h5 className="fullname">{data.customerName}</h5>
            <span className="datetime">
              {moment(data.createdDate).fromNow()}
            </span>
            <p
              className="content-html"
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></p>
            <HelpingHandCommentMobileView
              comments={data.comments || []}
              helpId={data.helpId}
              callBack={props.callBack}
            />
          </div>
        )}
      </div>
      <div className="col-2 text-right">
        {data.isUrgent == 1 && (
          <img
            src={require("assets/images/emergency.svg")}
            alt="emergency"
            className="img-emergency"
          />
        )}
        <img
          onClick={() => setToggleContent(!toggleContent)}
          src={
            toggleContent
              ? require("assets/images/down.png")
              : require("assets/images/arrow-up.svg")
          }
          alt={toggleContent ? "arrow-down" : "arrow-up"}
          className="img-arrow-down"
        />
      </div>
    </div>
  );
}
