/* eslint-disable react/prop-types */
import "../safe-desktop.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as IncidenAreaStore from "redux/stores/safe/incidents-area.store";
import {
  SetDefaultErrorImage,
  RemoveNoneNumberFromString,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";
import * as buidingManagementActions from "redux/stores/management/building-management.store";
import * as GetcontentByKeywordActions from "redux/stores/get-content-by-keyword/getContentByKeyword.store";
import { PromiseAllTools } from "core/app.store";

const project = JwtHelper.currentProject() || 1;
class SafeMainDesktopView extends Component {
  componentDidMount() {
    this.props.PromiseAllTools([
      this.props.GetIncidentAreasDesktopNew(),
      this.props.getBuildingManagementDetail(project),
      this.props.GetUBNDPhone(),
    ]);
  }

  render() {
    const listData =
      this.props.incidentNews && this.props.incidentNews.haveData
        ? this.props.incidentNews.listData
        : [];
    const buildingManagementModel = this.props.buildingManagementModel || {};
    const ubndPhone =
      +this.props.ubndPhone != NaN
        ? RemoveNoneNumberFromString(this.props.ubndPhone)
        : null;

    return (
      <div className="safe-desktop">
        <div className="safe-main-desktop">
          <h2 className="title-lg">An toàn</h2>

          <div className="an-toan">
            <div className="row">
              <div className="col-6">
                <Link to={`/xem-chat-luong-nuoc`}>
                  <div className="item">
                    <img
                      src={require("assets/images/chat-luong-nuoc.jpg")}
                      alt="Image"
                      className="img-fluid"
                    />
                    <div className="overlay"></div>
                    <p>Xem chất lượng nước</p>
                  </div>
                </Link>
              </div>
              <div className="col-6">
                <Link to={`/xem-chat-luong-khong-khi`}>
                  <div className="item">
                    <img
                      src={require("assets/images/cloud-blue-sky.jpg")}
                      alt="Image"
                      className="img-fluid"
                    />
                    <div className="overlay"></div>
                    <p>Xem chất lượng không khí</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="contact mt-4">
            <div>
              {buildingManagementModel.hotline && (
                <a
                  href={`tel:${RemoveNoneNumberFromString(
                    buildingManagementModel.hotline
                  )}`}
                >
                  <img
                    src={require("assets/images/Hot-BQL.svg")}
                    className="img-fluid"
                    alt="BQL"
                  />
                </a>
              )}
            </div>
            <div>
              <a href="tel:113">
                <img
                  src={require("assets/images/Hot-113.svg")}
                  className="img-fluid"
                  alt="113"
                />
              </a>
            </div>
            <div>
              <a href="tel:114">
                <img
                  src={require("assets/images/Hot-114.svg")}
                  className="img-fluid"
                  alt="114"
                />
              </a>
            </div>
            <div>
              <a href="tel:115">
                <img
                  src={require("assets/images/Hot-115.svg")}
                  className="img-fluid"
                  alt="115"
                />
              </a>
            </div>
            <div>
              {ubndPhone && (
                <a href={`tel:${ubndPhone}`}>
                  <img
                    src={require("assets/images/Hot-UBND_phuong.svg")}
                    className="img-fluid"
                    alt="UBND Phuong"
                  />
                </a>
              )}
            </div>
          </div>

          <div className="incidents-area mt-4">
            <div className="row">
              <div className="col-6">
                <h4 className="title-sm">Sự cố trong khu</h4>
              </div>
              <div className="col-6 text-right">
                {listData.length > 0 && (
                  <Link to="/su-co-trong-khu" className="read-more">
                    Xem thêm
                  </Link>
                )}
              </div>
            </div>
            <div className="row">
              {listData.length > 0 ? (
                listData.map((data, index) => (
                  <Incidents data={data} key={index} />
                ))
              ) : (
                <p>Không có dữ liệu để hiển thị</p>
              )}
            </div>
          </div>
          <div className="mt-4">
            <a href="https://moh.gov.vn/" target="_blank">
              <img
                src={require("assets/images/theo-doi-dich-benh.png")}
                alt="Theo dõi tình hình dịch bệnh"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const Incidents = (props) => {
  const data = props.data;
  return (
    <div className="col-4">
      <Link to={`/su-co-trong-khu/chi-tiet/${data.id}`}>
        <div className="item">
          <img
            src={data.url}
            alt="Image"
            onError={SetDefaultErrorImage}
            className="img-fluid"
          />
          <div className="item-body">
            <h5 className="text-truncate">{data.content}</h5>
            <div className="row no-gutters">
              <div className="col-6">
                <span className="datetime">{data.time}</span>
              </div>
              <div className="col-6 text-right">
                {!data.solved ? (
                  <p className="mb-0 text-primary status">Đang xử lý</p>
                ) : (
                  <p className=" status mb-0 text-success">Đã xử lý</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  incidentNews: state.incidentsArea.incidentNews,
  buildingManagementModel: state.buildingManagement.buildingManagementModel,
  ubndPhone: state.contentByKeyword.ubndPhoneNumber,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetIncidentAreasDesktopNew: IncidenAreaStore.GetIncidentAreasDesktopNew,
      getBuildingManagementDetail:
        buidingManagementActions.GetBuildingManagementDetail,
      GetUBNDPhone: GetcontentByKeywordActions.GetUBNDPhone,
      PromiseAllTools,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafeMainDesktopView);
