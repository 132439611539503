/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as userActions from "redux/stores/user/user-information.store";
import {
  NotificationMessageType,
  TokenKey,
  CheckFileSize,
  CheckFileTypeImage,
  SetDefaultErrorImage,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import Select from "react-select";
import SharedService from "services/share.service.js";
import { JwtHelper } from "utils/jwt-helper";
import * as viVN from "translation/vi-VN.json";
import RenderNotificationBell from "components/header/notification-bell.view.jsx";

import "../../user-information/information/information-mobile.scss";

const sharedService = new SharedService();

class UserInformationFormMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      careerList: [],
      homeTownList: [],
      hobbyList: [],
      districtList: [],
    };
  }

  UNSAFE_componentWillMount() {
    const userInfo = JwtHelper.currentUser();
    if (this.props.loggedIn && userInfo) {
      this.onGetUserInformation(userInfo.customerId);
    } else {
      window.location.replace(window.location.origin + "/m");
    }
  }

  onGetUserInformation(userId) {
    this.props.GetUserInformation(userId).then(
      (res) => {
        if (res && res.content) {
          this.setState({
            user: res.content,
          });
          this.onGetDropdownData();
        } else {
          AddNotification(
            viVN.Errors[res && res.errorType],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[err && err.errorType],
          NotificationMessageType.Error
        );
        window.location.replace(window.location.origin + "/m/dang-nhap");
      }
    );
  }

  onGetDropdownData = () => {
    Promise.all([
      sharedService.getCareerCategories(),
      sharedService.getHomeTowns(),
      sharedService.getHobbyCategoris(),
      this.state.user &&
        parseInt(this.state.user.homelandProvinceId) > 0 &&
        sharedService.getDistricts(this.state.user.homelandProvinceId),
    ])
      .then((res) => {
        const [careerModels, homeTownModels, hobbyModels, districtModels] = res;
        this.setState({
          careerList:
            (careerModels.content &&
              careerModels.content.length > 0 &&
              careerModels.content.map((item) => {
                return { label: item.careerName, value: item.careerId };
              })) ||
            [],
          homeTownList:
            (homeTownModels.content &&
              homeTownModels.content.length > 0 &&
              homeTownModels.content.map((item) => {
                return { label: item.provinceName, value: item.provinceId };
              })) ||
            [],
          hobbyList:
            (hobbyModels.content &&
              hobbyModels.content.length > 0 &&
              hobbyModels.content.map((item) => {
                return { label: item.hobbyName, value: item.hobbyId };
              })) ||
            [],
          districtList:
            (districtModels.content &&
              districtModels.content.length > 0 &&
              districtModels.content.map((item) => {
                return { label: item.districName, value: item.districId };
              })) ||
            [],
        });
      })
      .catch((err) => {});
  };

  onGetDropdownDistrics = (id) => {
    Promise.all([sharedService.getDistricts(id)])
      .then((res) => {
        const [districtModels] = res;
        this.setState({
          districtList:
            (districtModels.content &&
              districtModels.content.length > 0 &&
              districtModels.content.map((item) => {
                return { label: item.districName, value: item.districId };
              })) ||
            [],
        });
      })
      .catch((err) => {});
  };

  render() {
    const { onUpdateProfile } = this.props;
    const {
      user,
      careerList,
      homeTownList,
      hobbyList,
      districtList,
    } = this.state;
    const careerDefault =
      careerList.length > 0 &&
      careerList.filter((x) => x.value == user.careerId);
    const homeTownDefault =
      homeTownList.length > 0 &&
      homeTownList.filter((x) => x.value == user.homelandProvinceId);
    const hobbyDefault =
      hobbyList.length > 0 &&
      hobbyList.filter((x) => {
        if (user.hobbyIds.includes(x.value)) {
          return x;
        }
      });
    const districtDefault =
      districtList.length > 0 &&
      districtList.filter((x) => x.value == user.homelandDistrictId);
    return (
      <div className="user-info">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <Link to="/m/thong-tin-ca-nhan">
                      <img
                        src={require("assets/images/arrow-back.svg")}
                        alt="Arrow Back"
                        className="img-fluid img-arrow-back"
                      />
                    </Link>
                  </div>
                  <div className="col-4 header-title text-center">
                    Thông tin cá nhân
                  </div>
                  <div className="col-4 text-right">
                    <RenderNotificationBell forMobile={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {user && (
          <UserInformation
            careerList={careerList}
            careerDefault={careerDefault && careerDefault[0]}
            homeTownList={homeTownList}
            homeTownDefault={homeTownDefault && homeTownDefault[0]}
            hobbyList={hobbyList}
            hobbyDefault={hobbyDefault}
            districtList={districtList}
            districtDefault={districtDefault && districtDefault[0]}
            onGetDropdownDistrics={this.onGetDropdownDistrics}
            user={user}
            onUpdateProfile={onUpdateProfile}
          />
        )}
      </div>
    );
  }
}

function UserInformation(props) {
  const {
    careerList,
    careerDefault,
    homeTownList,
    homeTownDefault,
    hobbyList,
    hobbyDefault,
    user,
    onUpdateProfile,
    districtList,
    districtDefault,
    onGetDropdownDistrics,
  } = props;

  const [avatar, setAvatar] = useState("");
  const [fileError, setFileError] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);

  const [career, setCareer] = useState(careerDefault);
  const [hobby, setHobby] = useState(hobbyDefault);
  const [homeTown, setHomeTown] = useState(homeTownDefault);
  const [district, setDistrict] = useState(districtDefault);

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (
      firstLoad &&
      (careerDefault || hobbyDefault || homeTownDefault || districtDefault)
    ) {
      setCareer(careerDefault);
      setHobby(hobbyDefault);
      setHomeTown(homeTownDefault);
      setDistrict(districtDefault);
      setFirstLoad(false);
    }
  });

  const changeAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (CheckFileSize(file.size)) {
        setFileError(CheckFileSize(file.size));
        return;
      } else if (CheckFileTypeImage(file.type)) {
        setFileError(CheckFileTypeImage(file.type));
        return;
      } else {
        setFileError("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        setAvatar(event.target.result);
        setAvatarFile(file);
      };
    }
  };

  const deleteAvatar = () => {
    setAvatar("");
    setAvatarFile(null);
  };

  const onChangeHomeTown = (data) => {
    setHomeTown(data);
    setDistrict("");
    onGetDropdownDistrics(data.value);
  };

  const onSubmit = (data) => {
    if (data) {
      const formData = new FormData();
      avatarFile && formData.append("avatar", avatarFile);
      let hobbyIds = "";
      hobby &&
        hobby.map((item, index) => {
          if (index === 0) {
            hobbyIds = item.value;
          } else {
            hobbyIds = hobbyIds + "," + item.value;
          }
        });
      onUpdateProfile(
        {
          customer_id: user.customerId,
          first_name: data.firstName,
          last_name: data.lastName,
          mobile_number: data.tel,
          homeland_province_id: homeTown && homeTown.value,
          homeland_district_id: district && district.value,
          career_id: career && career.value,
          hobby_id: hobbyIds,
        },
        formData
      ).then(
        (res) => {
          if (res && res.content) {
            res.content.jwt &&
            setCookies(TokenKey.AuthToken, res.content.jwt);
            AddNotification(
              "Cập nhật thông tin thành công",
              NotificationMessageType.Success
            );
            window.location.replace(
              window.location.origin + "/m/thong-tin-ca-nhan"
            );
          } else {
            res &&
              res.errorType &&
              AddNotification(
                viVN.Errors[res && res.errorType],
                NotificationMessageType.Error
              );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="row p-3" id="addForm">
        <div className="col-12">
          <div className="form-group">
            <div>
              <label>Ảnh đại diện</label>
            </div>
            <div className="personal-logo">
              <div className="personal-logo-holder">
                <img
                  src={
                    avatar ||
                    user.avatarUrl ||
                    require("assets/images/user-info-avatar.png")
                  }
                  onError={SetDefaultErrorImage}
                  alt="Avatar"
                  className="img-fluid img-avatar"
                />
              </div>
              {!avatar && (
                <label className="personal-logo-upload">
                  <div>
                    <img
                      src={require("assets/images/edit_white.svg")}
                      className="img-fluid img-edit"
                      alt="Edit avatar"
                    />
                    <input
                      type="file"
                      name="avatar"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => changeAvatar(e)}
                    />
                  </div>
                </label>
              )}
              {avatar && (
                <label className="personal-logo-upload">
                  <img
                    src={require("assets/images/cancel-white.png")}
                    className="img-fluid img-delete"
                    alt="Delete avatar"
                    onClick={() => deleteAvatar()}
                  />
                </label>
              )}
              {fileError && <div className="invalid-feedback">{fileError}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-8 form-group">
              <label>
                Họ và Tên đệm<span className="required"></span>
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className={
                  "form-control " + (errors.firstName ? "is-invalid" : "")
                }
                placeholder="Họ và Tên đệm"
                defaultValue={user.firstName}
                ref={register({
                  required: "Trường này là bắt buộc",
                })}
              />
              {errors.firstName && (
                <div className="invalid-feedback">
                  {errors.firstName.message}
                </div>
              )}
            </div>
            <div className="col-4 form-group">
              <label>
                Tên<span className="required"></span>
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className={
                  "form-control " + (errors.lastName ? "is-invalid" : "")
                }
                placeholder="Tên"
                defaultValue={user.lastName}
                ref={register({
                  required: "Trường này là bắt buộc",
                })}
              />
              {errors.lastName && (
                <div className="invalid-feedback">
                  {errors.lastName.message}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>
              Số điện thoại<span className="required"></span>
            </label>
            <input
              type="text"
              name="tel"
              id="tel"
              className={"form-control " + (errors.tel ? "is-invalid" : "")}
              placeholder="0986123456"
              defaultValue={user.mobileNumber}
              ref={register({
                required: "Trường này là bắt buộc",
                pattern: {
                  value: /(\(?\+[0-9]{2}\)|0)?([0-9]{9,10})/i,
                  message: "Số điện thoại không hợp lệ",
                },
              })}
            />
            {errors.tel && (
              <div className="invalid-feedback">{errors.tel.message}</div>
            )}
          </div>
          <div className="form-group">
            <label>Tỉnh thành</label>
            <Select
              onChange={(data) => data && onChangeHomeTown(data)}
              value={homeTown}
              options={homeTownList}
              noOptionsMessage={() => "Không có dữ liệu"}
              className="slAutocomplete"
              placeholder="Chọn tỉnh thành"
            />
          </div>
          {homeTown && homeTown.value != 0 && (
            <div className="form-group">
              <label>Tỉnh thành</label>
              <Select
                onChange={(data) => data && setDistrict(data)}
                value={district}
                options={districtList}
                noOptionsMessage={() => "Không có dữ liệu"}
                className="slAutocomplete"
                placeholder="Chọn tỉnh thành"
              />
            </div>
          )}
          <div className="form-group">
            <label>Quận/Huyện</label>
            <Select
              onChange={(data) => data && setCareer(data)}
              value={career}
              options={careerList}
              noOptionsMessage={() => "Không có dữ liệu"}
              className="slAutocomplete"
              placeholder="Chọn quận/huyện"
            />
          </div>
          <div className="form-group">
            <label>Sở thích</label>
            <Select
              onChange={(data) => data && setHobby(data)}
              value={hobby}
              isMulti
              options={hobbyList}
              noOptionsMessage={() => "Không có dữ liệu"}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Chọn sở thích"
            />
          </div>
          <div className="row">
            <div className="col-6">
              <Link to="/m/thong-tin-ca-nhan">
                <button
                  type="reset"
                  onClick={() => window.location.reload()}
                  className="btn btn-secondary w-100 text-uppercase"
                >
                  Hủy
                </button>
              </Link>
            </div>
            <div className="col-6">
              <button
                type="submit"
                className="btn btn-success w-100 text-uppercase"
              >
                Lưu thay đổi
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetUserInformation: userActions.GetUserInformation,
      onUpdateProfile: userActions.UpdateUserInformation,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformationFormMobileView);
