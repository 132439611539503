//@ts-check
import Service from 'services/api.service';
import { createFormData, AppKey } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';
const service = new Service();

export const CreateTransactionVNPay = (filterObjs) => {
    return (dispatch) => {
        let payLoad = createFormData(filterObjs, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.CreateTransactionVNPay, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}