/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { ConvertNumberToString } from "utils/configuration";
import '../payment-desktop.scss';

export default function PaymentResultDesktopView(props) {
    const vnpResponseCode = new URLSearchParams(props.location.search).get(
        "vnp_ResponseCode"
    );

    const vnpAmount = new URLSearchParams(props.location.search).get(
        "vnp_Amount"
    );

    return (
        <div className="payment-result-desktop">
            {
                vnpResponseCode && vnpResponseCode == '00' ? (
                    <div className="payment-success-desktop text-center bg-white p-4 shadow-sm">
                        <img src={require("/assets/images/success.svg")} alt="Success" className="img-success mb-4" />

                        <h5 className="text-uppercase mb-3">Giao dịch thành công</h5>

                        <h6 className="mb-2">Hóa đơn đã được thanh toán qua VNPay</h6>

                        <h6 className="mb-3">Số tiền thanh toán <b>{ConvertNumberToString(vnpAmount / 100 || 0)} VNĐ</b></h6>

                        <Link to="/" className="btn btn-success rounded-pill text-uppercase pl-4 pr-4 pt-2 pb-2">Về trang chủ</Link>
                    </div>
                ) : (
                        <div className="payment-fail-desktop text-center bg-white p-4 shadow-sm">
                            <img src={require("/assets/images/fail.svg")} alt="Fail" className="img-fail mb-4" />

                            <h5 className="text-uppercase mb-3">GIAO DỊCH KHÔNG THÀNH CÔNG</h5>

                            <h6 className="mb-3">Vui lòng thanh toán lại hoặc liên hệ với chúng tôi!</h6>

                            <Link to="/" className="btn btn-success rounded-pill text-uppercase pl-4 pr-4 pt-2 pb-2">Về trang chủ</Link>
                        </div>
                    )
            }
        </div>
    )
}