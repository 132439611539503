/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as incidentAreaStore from "redux/stores/safe/incidents-area.store";
import * as newActions from "redux/stores/news/news.store";
import { PromiseAllTools } from "core/app.store";
import * as advertisingActions from "redux/stores/advertising/advertising.store";

import { SetDefaultErrorImage, StripHtmlTag } from "utils/configuration";

import "../widget/widget.scss";

export class WidgetView extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.props.PromiseAllTools([this.props.GetIncidentAreasDesktopNew()]);
  }

  renderContent = () => {
    if (isMobile || isTablet) {
      return "";
    }
    const { incidentNews, advertising } = this.props;
    const listData =
      incidentNews && incidentNews.haveData ? incidentNews.listData : [];
    const advertisingHomeDesktop =
      (advertising &&
        advertising.length > 0 &&
        advertising.filter(
          (item) =>
            item.positionCode ==
            advertisingActions.POSITION_AD.DESKTOP_RIGHT_MENU
        )) ||
      [];

    return (
      <aside className="widget">
        <div className="tien-ich-plus">
          <div className="widget-title">
            <h5>Tiện ích +</h5>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="item">
                <div className="row no-gutters">
                  <div className="col-1 col-sm-12 col-md-1 col-lg-1">
                    <img
                      src={require("assets/images/electricity-and-water.svg")}
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-10 col-sm-12 col-md-10 col-lg-10 pl-3">
                    <Link to="/sua-chua-lap-dat">
                      Sửa chữa, lắp đặt đồ gia dụng
                    </Link>
                  </div>
                  <div className="col-1 col-sm-12 col-md-1 col-lg-1 text-right">
                    <Link to="/sua-chua-lap-dat">
                      <img
                        src={require("assets/images/keyboard-arrow-right.svg")}
                        alt="Image"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="item">
                <div className="row no-gutters">
                  <div className="col-1 col-sm-12 col-md-1 col-lg-1">
                    <img
                      src={require("assets/images/home-decor.svg")}
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-10 col-sm-12 col-md-10 col-lg-10 pl-3">
                    <a href="https://homeid.vn" target="_blank">
                      Cải tạo - trang trí nhà
                    </a>
                  </div>
                  <div className="col-1 col-sm-12 col-md-1 col-lg-1 text-right">
                    <a href="https://homeid.vn" target="_blank">
                      <img
                        src={require("assets/images/keyboard-arrow-right.svg")}
                        alt="Image"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="item">
                <div className="row no-gutters">
                  <div className="col-1 col-sm-12 col-md-1 col-lg-1">
                    <img
                      src={require("assets/images/rent.svg")}
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-10 col-sm-12 col-md-10 col-lg-10 pl-3">
                    <Link to="/cho-thue-muon-do">Cho thuê, mượn đồ</Link>
                  </div>
                  <div className="col-1 col-sm-12 col-md-1 col-lg-1 text-right">
                    <Link to="/cho-thue-muon-do">
                      <img
                        src={require("assets/images/keyboard-arrow-right.svg")}
                        alt="Image"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-12">
                            <div className="item">
                                <div className="row no-gutters">
                                    <div className="col-1 col-sm-12 col-md-1 col-lg-1">
                                        <img src={require('assets/images/hitchhiking.svg')} alt="Image" className="img-fluid" />
                                    </div>
                                    <div className="col-10 col-sm-12 col-md-10 col-lg-10 pl-3">
                                        <Link to="/di-nho-xe">Đi nhờ xe</Link>
                                    </div>
                                    <div className="col-1 col-sm-12 col-md-1 col-lg-1 text-right">
                                        <Link to="/di-nho-xe">
                                            <img src={require('assets/images/keyboard-arrow-right.svg')} alt="Image" className="img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            {/* <div className="col-12">
                            <div className="item">
                                <div className="row no-gutters">
                                    <div className="col-1 col-sm-12 col-md-1 col-lg-1">
                                        <img src={require('assets/images/bus.svg')} alt="Image" className="img-fluid" />
                                    </div>
                                    <div className="col-10 col-sm-12 col-md-10 col-lg-10 pl-3">
                                        <Link to="/lich-trinh-xe-buyt-tau-dien">Lịch trình xe buýt, tàu điện</Link>
                                    </div>
                                    <div className="col-1 col-sm-12 col-md-1 col-lg-1 text-right">
                                        <Link to="/lich-trinh-xe-buyt-tau-dien">
                                            <img src={require('assets/images/keyboard-arrow-right.svg')} alt="Image" className="img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>

        <div className="rao-vat mt-4">
          <div className="widget-title">
            <div className="row align-items-center">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <h5>Chợ làng</h5>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
                <Link to="/rao-vat/cho-cu-dan">Xem thêm</Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="item">
                <div className="row no-gutters">
                  <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                    <Link to="/rao-vat/cho-cu-dan">Rao vặt</Link>
                  </div>
                  <div className="col-1 col-sm-1 col-md-1 col-lg-1 text-right">
                    <Link to="/rao-vat/cho-cu-dan">
                      <img
                        src={require("assets/images/keyboard-arrow-right.svg")}
                        alt="Image"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="item">
                <div className="row no-gutters">
                  <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                    <Link to="/rao-vat/giup-viec-theo-gio">
                      Giúp việc theo giờ
                    </Link>
                  </div>
                  <div className="col-1 col-sm-1 col-md-1 col-lg-1 text-right">
                    <Link to="/rao-vat/giup-viec-theo-gio">
                      <img
                        src={require("assets/images/keyboard-arrow-right.svg")}
                        alt="Image"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-12">
                            <div className="item">
                                <div className="row no-gutters">
                                    <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                                        <Link to="/rao-vat/di-cho-thue">Đi chợ thuê</Link>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 text-right">
                                        <Link to="/rao-vat/di-cho-thue">
                                            <img src={require('assets/images/keyboard-arrow-right.svg')} alt="Image" className="img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            <div className="col-12">
              <div className="item">
                <div className="row no-gutters">
                  <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                    <Link to="/rao-vat/mua-ban-nha">
                      {`Mua, bán & cho thuê nhà`}
                    </Link>
                  </div>
                  <div className="col-1 col-sm-1 col-md-1 col-lg-1 text-right">
                    <Link to="/rao-vat/mua-ban-nha">
                      <img
                        src={require("assets/images/keyboard-arrow-right.svg")}
                        alt="Image"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lang-gieng-gan mt-4">
          <div className="widget-title">
            <h5>Láng giềng gần</h5>
          </div>
          <div className="row no-gutters">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 pr-2">
              <div className="item">
                {/* <img src={require('assets/images/ket-ban.svg')} alt="Image" className="img-fluid" /> <Link to="/lang-gieng-gan/ket-ban">Kết bạn</Link> */}
                <img
                  src={require("assets/images/club.svg")}
                  alt="Image"
                  className="img-fluid"
                />{" "}
                <Link to="/lang-gieng-gan/cau-lac-bo">Câu lạc bộ</Link>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
              <div className="item">
                <img
                  src={require("assets/images/giup-mot-tay.svg")}
                  alt="Image"
                  className="img-fluid"
                />{" "}
                <Link to="/lang-gieng-gan/giup-mot-tay">Giúp một tay</Link>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 pl-2">
              <div className="item">
                <img
                  src={require("assets/images/chung-tay-15px.svg")}
                  alt="Image"
                  className="img-fluid"
                />{" "}
                <Link to="/lang-gieng-gan/chung-tay">Chung tay</Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="an-toan mt-4">
                    <div className="widget-title">
                        <h5>An toàn</h5>
                    </div>
                    <div className="mt-2">
                        <div className="item">
                            <img src={require('assets/images/chat-luong-nuoc.jpg')} alt="Image" className="img-fluid" />
                            <div className="overlay"></div>
                            <Link to="/xem-chat-luong-nuoc">Xem chất lượng nước</Link>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="item">
                            <img src={require('assets/images/cloud-blue-sky.jpg')} alt="Image" className="img-fluid" />
                            <div className="overlay"></div>
                            <Link to="/xem-chat-luong-khong-khi">Xem chất lượng không khí</Link>
                        </div>
                    </div>
                </div> */}

        <div className="su-co-trong-khu mt-4">
          <div className="row">
            <div className="col-6 col-sm-12 col-md-6 col-lg-6">
              <h5>Sự cố trong khu</h5>
            </div>
            <div className="col-6 col-sm-12 col-md-6 col-lg-6 text-right">
              {listData.length > 0 && (
                <Link to="/su-co-trong-khu">Xem thêm</Link>
              )}
            </div>
          </div>
          <div className="row mt-2 content">
            <div className="col-6 col-sm-12 col-md-12 col-lg-12">
              {listData.length > 0 ? (
                listData.map((data, index) => (
                  <RenderFieldIncident data={data} key={index} />
                ))
              ) : (
                <p>Không có sự cố nào</p>
              )}
            </div>
          </div>
        </div>

        {advertisingHomeDesktop &&
          advertisingHomeDesktop.length > 0 &&
          advertisingHomeDesktop.map((item) => (
            <div
              key={`home-top-${item.advertisingId}`}
              className="mt-4 quang-cao"
            >
              <a target="_blank" href={item.advertisingUrl}>
                <img
                  src={
                    item.advertisingFile ||
                    require("/assets/images/quang-cao-1.png")
                  }
                  alt={item.advertisingName}
                  className="object-fit-cover"
                />
              </a>
            </div>
          ))}
      </aside>
    );
  };

  render() {
    return this.renderContent();
  }
}

function RenderFieldIncident(props) {
  const data = props.data;
  return (
    <div className="item">
      <div className="row no-gutters">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <Link to={`/su-co-trong-khu/chi-tiet/${data.id}`}>
            <img
              src={data.url}
              alt="Image"
              onError={SetDefaultErrorImage}
              className="img-fluid w-100"
            />
          </Link>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8">
          <div className="item-content">
            <h5>
              <Link to={`/su-co-trong-khu/chi-tiet/${data.id}`}>
                {renderText(StripHtmlTag(data.content))}
              </Link>
            </h5>
            <span className="time">{data.time}</span>
            {!data.solved ? (
              <p className="mb-0 text-primary">Đang xử lý</p>
            ) : (
              <p className="mb-0 text-success">Đã xử lý</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function renderText(value) {
  const size = 82;
  if (value.length < size) return value;
  return value.slice(0, size) + "...";
}

const mapStateToProps = (state) => ({
  incidentNews: state.incidentsArea.incidentNews,
  advertising: state.homeMobile.advertising,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetIncidentAreasDesktopNew: incidentAreaStore.GetIncidentAreasDesktopNew,
      PromiseAllTools,
      getNewList: newActions.GetNewList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WidgetView);
