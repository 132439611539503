import "../neighbourhoods-desktop.scss";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as joinHandActions from 'redux/stores/join-hand/join-hand.store';
import AddJoinsHandDesktopView from './joins-hand-actions/add-joins-hand-desktop.view';
import JoinsHandItemDesktopView from './childs/joins-hand-item.view';
import PaginationControl from 'components/pagination/pagination.view';
import { JwtHelper } from "utils/jwt-helper";

class JoinsHandDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
      isToggleAdding: false,
      currentUser: JwtHelper.currentUser()
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetJoinHandList();
  }

  onGetJoinHandList = (pageIndex = this.state.currentPageIndex, size = 10) => {
    this.setState({ isLoading: true });
    this.props.getJoinHandList(pageIndex, size, (this.state.currentUser && this.state.currentUser.customerId) || 1014).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => {
        // console.log(err);
        this.setState({ isLoading: false });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetJoinHandList(pageNumber);
    })
  }

  onToggleAddingState = () => {
    this.setState({
      isToggleAdding: !this.state.isToggleAdding
    })
  }

  render() {
    const { joinsHandList, totalItems } = this.props;
    const { currentPageIndex, isToggleAdding } = this.state;
    return (
      <div className="neighbourhoods-desktop joins-hand-desktop">
        <h2 className="title-lg">Chung tay</h2>
        <AddJoinsHandDesktopView onToggleAddingState={this.onToggleAddingState.bind(this)} callBack={this.onGetJoinHandList.bind(this)} />
        <h4 className="title-sm mt-4">Sự kiện trước đây</h4>
        <div className="events">
          <JoinsHandItemDesktopView joinsHandList={joinsHandList} isToggleAdding={isToggleAdding} callBack={this.onGetJoinHandList.bind(this)} />
        </div>
        <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  joinsHandList: state.joinsHand.joinsHandList,
  totalItems: state.joinsHand.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getJoinHandList: joinHandActions.GetJoinsHandList,
  deleteJoinHand: joinHandActions.DeleteJoinsHand,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(JoinsHandDesktopView)