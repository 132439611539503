/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType } from "utils/configuration";
import * as helpingHandActions from "redux/stores/help/helping-hand.store";
import RenderHelpingHandMobileContent from "../childs/helping-hand-content-mobile.view.jsx";
import { Modal, Button } from "react-bootstrap";
import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";
import { Link } from "react-router-dom";
import "../../neighbourhoods-mobile.scss";

class HelpingHandDetailMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      helpingHandPost: null,
      helpId: this.props.match.params.helpId,
      isOpenEditPopup: false,
      isOpenDeletePopup: false,
      selectedHelpPostId: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHelpingHandPostDetail();
  }

  onGetHelpingHandPostDetail = () => {
    console.log(this.state.helpId);
    if (this.state.helpId) {
      this.props.getHelpingHandDetail(this.state.helpId).then(
        (res) => {
          if (res.content) {
            this.setState({
              helpingHandPost: res.content,
            });
          } else {
            history.push("/m/lang-gieng-gan/giup-mot-tay");
          }
        },
        (err) => {}
      );
    } else {
      history.push("/m/lang-gieng-gan/giup-mot-tay");
    }
  };

  onToggleEditPopup = (isOpen, helpId) => {
    this.setState({
      isOpenEditPopup: isOpen,
      selectedHelpPostId: helpId,
    });
  };

  onToggleDeletePopup = (isOpen, helpId) => {
    this.setState({
      isOpenDeletePopup: isOpen,
      selectedHelpPostId: helpId,
    });
  };

  onDeleteHelpingHandPost = (isConfirm) => {
    if (isConfirm) {
      this.props.deleteHelpingHand(this.state.selectedHelpPostId).then(
        (res) => {
          this.onToggleDeletePopup(false);
          AddNotification(
            "Xoá bài viết thành công!",
            NotificationMessageType.Success
          );
          history.push("/m/lang-gieng-gan/giup-mot-tay");
        },
        (err) => {
          this.onToggleDeletePopup(false);
        }
      );
    }
  };

  render() {
    const { helpingHandPost, currentUser, isOpenDeletePopup } = this.state;
    const { loggedIn } = this.props;
    return (
      <div className="helping-hand-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/lang-gieng-gan/giup-mot-tay">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Giúp một tay</div>
              </div>
            </div>
          </div>
        </header>
        <div className="p-3">
          <div className="helping-hand-item p-2">
            {helpingHandPost && (
              <RenderHelpingHandMobileContent
                loggedIn={loggedIn}
                data={helpingHandPost}
                currentUserId={currentUser.customerId}
                onToggleDeletePopup={this.onToggleDeletePopup.bind(this)}
                onToggleEditPopup={this.onToggleEditPopup.bind(this)}
                callBack={this.onGetHelpingHandPostDetail.bind(this)}
              />
            )}
          </div>
        </div>
        <Modal
          show={isOpenDeletePopup}
          className="modal-delete"
          centered
          onHide={() => this.onToggleDeletePopup(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Xóa yêu cầu?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Yêu cầu sẽ không thể khôi phục sau khi xóa.
            <br />
            Bạn có chắc muốn xóa yêu cầu này.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.onToggleDeletePopup(false)}
            >
              Hủy
            </Button>
            <Button
              variant="success"
              onClick={() => this.onDeleteHelpingHandPost(true)}
            >
              Xóa
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateHelpingHand: helpingHandActions.CreateOrUpdateHelpingHand,
      getHelpingHandDetail: helpingHandActions.GetHelpingHandDetail,
      deleteHelpingHand: helpingHandActions.DeleteHelpingHand,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpingHandDetailMobileView);
