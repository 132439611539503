import React from 'react';
import { Link } from 'react-router-dom';
import * as NotificationStore from 'redux/stores/notification/notifications.store';
function NotificationManagementDesktopView(props) {
    const listData = props.listData
    return (
        <div className="bg-white p-4">
            <h4 className="title-sm">Thông báo từ BQL</h4>
            <hr className="mb-0"></hr>
            {listData.map((data, index) => <RenderNotificationFromManagement key={index} data={data} />)}
        </div>
    )
}

function RenderNotificationFromManagement(props) {
    const data = props.data;
    return (
        <Link to={`/thong-bao/chi-tiet/${data.informId}`}>
            <div className={`notification-admin ${data.backGroundClass}`} onClick={() => NotificationStore.MaskNotificationIsRead(data.notificationId)}>
                <h5> {data.informName}</h5>
                <div className="datetime">
                    <img src={require('assets/images/calendar.svg')} alt="Calendar" />{data.createDate}
                </div>
                <p className="text-truncate mb-0">{data.description}</p>
            </div>
        </Link>
    )
}

export default NotificationManagementDesktopView;