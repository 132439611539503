import React, { useEffect, useState } from 'react';
import NotificationResidentMobileView from './notification-resident/notification-resident-mobile.view.jsx';
import { Accordion, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './notification-mobile.scss';
import { Link } from 'react-router-dom';
import NotificationManagementMobileView from './notification-management/notification-management-mobile.view.jsx';
import * as NotificationStore from "redux/stores/notification/notifications.store";
import AuthenticationWarningMobileView from "components/authentication-warning/authentication-warning-mobile.view.jsx";

function NotificationMobileView(props) {

    const [hasRenderNotificationCustomer, setHasRenderNotificationCustomer] = useState(false);


    useEffect(() => {
        if (!props.notificationTotal.bqlUnread&&props.isLogin) {
            props.GetNotificationCount()
            console.log("bug")
        }
    }, [])


    function handleClickChangeButton(i) {
        let buttons = Array.from(document.getElementsByClassName("notification-mobile-button-header"))
        buttons.map((button, index) => {
            if (index === i) {
                button.classList.add("disabled")
            } else button.classList.remove("disabled")
        })
        if (((!hasRenderNotificationCustomer) && i === 1)) setHasRenderNotificationCustomer(true)
    }

    if (!props.isLogin) return <AuthenticationWarningMobileView />

    return (
        <div className="notification-mobile">
            <header className="header-mobile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/m">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                            <div className="header-title text-center">Thông báo</div>
                        </div>
                    </div>
                </div>
            </header>

            <Accordion defaultActiveKey="0">
                <Accordion.Toggle as={Button} onClick={() => handleClickChangeButton(0)} className="w-50 notification-mobile-button-header disabled" variant="link" eventKey="0">
                    Thông báo từ BQL<span className="notification-count">{props.notificationTotal.bqlUnread}</span>
                </Accordion.Toggle>
                <Accordion.Toggle as={Button} onClick={() => handleClickChangeButton(1)} className="w-50 notification-mobile-button-header" variant="link" eventKey="1">
                    Thông báo từ cư dân<span className="notification-count">{props.notificationTotal.residentUnread}</span>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                    <NotificationManagementMobileView />
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="1">
                    <NotificationResidentMobileView />
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}

const mapStateToProps = state => ({
    notificationTotal: state.notifications.notificationTotal,
    neightborNotification: state.notifications.neightborNotification,
    isLogin: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetNotificationCount: NotificationStore.GetNotificationCount,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMobileView);