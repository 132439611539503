import { createSaveLoading } from '../../../core/app.store';
import AddNotification from '../../../components/react-notifications/react-notifications'
import Service from '../../../services/api.service';
import { createFormData, AppKey } from '../../../utils/configuration';
import { ApiUrl } from '../../../services/api-url';
import { Map } from 'immutable';

const service = new Service();
const INITGYMLIST = 'GYM/LIST';

const InitGymList = data => ({
    type: INITGYMLIST, data: data
})

export const GetGymList = (pageIndex = 1, size = 100) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = null
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.GymList, payLoad).then(res => {
            if (res.content && (res.content.items.length > 0)) rawData = res.content; else return false
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitGymList(ExchangeGymListData(rawData)))
    }
}

export const PostRegisterGym = (registerData) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(registerData, AppKey.PostPayloadPrefix);
        return await service.post(ApiUrl.GymRegister, payLoad).then(res => {
            return true;
        }).catch(err => {
            if (err.errorType === "DuplicateDataError") return true; else AddNotification("Có lỗi xảy ra trong quá trình đăng ký vui lòng thông báo cho BQL để nhận hỗ trợ", "error")
            console.log(err)
            return false;
        }).finally(() => {
            dispatch(createSaveLoading(false));
        })
    }
}

const initState = Map({
    gymList: {}
}).toJS()

export default function GymReducer(state = initState, action) {
    switch (action.type) {
        case INITGYMLIST:
            return {
                ...state,
                gymList: action.data
            }
        default:
            return state
    }
}

function ExchangeGymListData(rawData) {
    const standardData = {
        haveData: false,
        gymList: []
    }

    if (rawData) {
        standardData.haveData = true,
            rawData.items.map((data) => {
                standardData.gymList.push({
                    gymId: data.gymId,
                    gymName: data.gymName,
                    lng: data.longitude,
                    lat: data.latitude,
                })
            })
        standardData.paymentGuide = rawData.items[0].content;
        standardData.timeOpen = rawData.items[0].schedule;
    }

    return standardData
}