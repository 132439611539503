import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as hourlyMaidActions from 'redux/stores/hourly-maid/hourly-maid.store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType, SetDefaultErrorImage } from 'utils/configuration';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import EditHourlyMaidDesktopView from '../hourly-maid-actions/edit-hourly-maid-desktop.view';
import { JwtHelper } from "utils/jwt-helper";
import ReactTooltip from "react-tooltip";

class HourlyMaidItemDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenEditPopup: false,
            isOpenDeletePopup: false,
            selectedAssistId: null,
            currentUser: JwtHelper.currentUser(),
        }
    }

    UNSAFE_componentWillMount() {

    }

    onToggleEditPopup = (isOpen, assistId) => {
        this.setState({
            isOpenEditPopup: isOpen,
            selectedAssistId: assistId
        })
    }

    onToggleDeletePopup = (isOpen, assistId) => {
        this.setState({
            isOpenDeletePopup: isOpen,
            selectedAssistId: assistId
        })
    }

    onDeleteHourlyMaidPost = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteHourlyMaid(this.state.selectedAssistId).then(res => {
                this.onToggleDeletePopup(false);
                AddNotification('Xoá tin thành công!', NotificationMessageType.Success);
                this.props.callBack && this.props.callBack();
            }, err => {
                this.onToggleDeletePopup(false);
                //TODO: show err message
            })
        }
    }

    render() {
        const { hourlyMaidList, loggedIn } = this.props;
        const { isOpenDeletePopup, isOpenEditPopup, currentUser, selectedAssistId } = this.state;
        return (
            <div className="news-hourly-maid">
                <h4 className="title-md mt-4">Tin mới</h4>
                {hourlyMaidList.map(item =>
                    <div key={item.assistId} className="news-hourly-maid-item bg-white p-3">
                        <div className="row no-gutters">
                            <div className="col-1">
                                <img src={item.profileUrl || require('assets/images/29-12.png')} onError={SetDefaultErrorImage} className="img-fluid avatar" />
                            </div>
                            <div className="col-11 pl-3">
                                <h5 className="fullname mb-0">{item.customerName}</h5>
                                <span className="time-create">{moment(item.createDate).fromNow()}</span>
                                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                <a href={`tel:${item.mobileNumber}`} className="contact" data-tip={`SĐT: +${item.mobileNumber}`}>
                                    <img src={require('assets/images/phone-call-round.svg')} className="img-fluid" alt="Phone Call" />Liên hệ
                                </a>
                                <ReactTooltip place="bottom" type="dark" effect="float" />
                            </div>
                        </div>
                        {loggedIn && item.customerId == currentUser.customerId &&
                            <div className="hourly-maid-actions text-right">
                                <a className="text-primary" onClick={() => this.onToggleEditPopup(true, item.assistId)}>Chỉnh sửa</a>
                                <button type="button" className="close" aria-label="Close" onClick={() => this.onToggleDeletePopup(true, item.assistId)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                    </div>)}

                <Modal show={isOpenEditPopup} className="modal-edit" centered onHide={() => this.onToggleEditPopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chỉnh sửa tin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditHourlyMaidDesktopView assistId={selectedAssistId} onToggleEditPopup={this.onToggleEditPopup.bind(this)} callBack={this.props.callBack} />
                    </Modal.Body>
                </Modal>

                <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xóa tin?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Tin đã đăng sẽ không thể khôi phục sau khi xóa.
                        <br />
                        Bạn có chắc muốn xóa tin này.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                        <Button variant="success" onClick={() => this.onDeleteHourlyMaidPost(true)}>Xóa</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteHourlyMaid: hourlyMaidActions.DeleteHourlyMaid,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HourlyMaidItemDesktopView)