import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleMap from '../../gmap/googlemap.view';
import "../utilities-desktop.scss"
import { Form, Button, Col, Row } from 'react-bootstrap';
import { GetBbqLocation, PostBbqRegisterUse } from 'redux/stores/utillities/bbq.store';
import { Success, Error } from 'components/support-create-form/validate-style';
import { checkDateInput, getDateWithFormat, ConverTimeToMinutes } from 'utils/configuration';
import { PromiseAllTools } from 'core/app.store';
import { RenderServiceIsNotReady } from 'components/warning-component/not-have-data';

class UtilitiesBbqDesktopView extends Component {
  componentDidMount() {
    this.props.PromiseAllTools([this.props.GetBbqLocation()])
  }

  render() {
    const mapStyle = {
      width: '100%',
      height: '400px'
    }

    const locationList = this.props.location || [];
    if (locationList.length == 0) return <RenderServiceIsNotReady />
    return (
      <div className="utilities-desktop">
        <h2 className="title-lg">BBQ</h2>

        <div className="utilities-map">
          <GoogleMap listMarker={locationList} mapStyle={mapStyle} />
        </div>

        <h3 className="title-sm mt-4">Đăng kí sử dụng</h3>
        <RenderForm isLogin={this.props.isLogin} userInfo={this.props.userInfo} onSubmit={this.props.PostBbqRegisterUse} />
      </div>
    )
  }
}

function RenderForm(props) {
  const userInfo = props.userInfo;
  const [timeWrong, setTimeWrong] = useState(false);
  const [dateWrong, setDateWrong] = useState(false);
  const [registed, setRegisted] = useState(false);
  const { register, errors, handleSubmit } = useForm()
  const [date, setDate] = useState(new Date())
  const [valueDate, setValueDate] = useState(getDateWithFormat(date))
  const [hoursFrom, setHoursFrom] = useState(new Date());
  const [hoursTo, setHoursTo] = useState(new Date());

  let content = null;
  if (registed) { content = (<Success content="Bạn đã gửi đăng kí sử dụng thành công" />) } else content = (<Form onSubmit={handleSubmit(onSubmit)} className="utilities-form">
    <div className="row no-gutters">
      <div className="col-5 pr-2">
        <Form.Group>
          <Form.Control type="text" name={`data[register_name]`} defaultValue={userInfo.customerName || ""} ref={register({ required: true })} placeholder="Họ tên" />
          {errors.data && errors.data.register_name && <Error content="Không được để trống mục này" />}
        </Form.Group>
      </div>
      <div className="col-3 pr-2">
        <Form.Group>
          <Form.Control name={`data[from_hour]`} className="d-none" onChange={(e) => { }} value={hoursFrom.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} ref={register({ required: true })} type="time">
          </Form.Control>
          <DatePicker
            selected={hoursFrom}
            onChange={date => checkDateInput(date, setHoursFrom)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            className="form-control"
          />
          {errors.data && errors.data.from_hour && <Error content="Không được để trống mục này" />}
          {timeWrong && <Error content="Khoảng thời gian chưa chính xác, vui lòng kiểm tra lại" />}
        </Form.Group>
      </div>
      <div className="col-4">
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Form.Label column sm="2">Đến</Form.Label>
          <Col sm="10">
            <Form.Control name={`data[to_hour]`} className="d-none" onChange={(e) => { }} value={hoursTo.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} ref={register({ required: true })} type="time">
            </Form.Control>
            <DatePicker
              selected={hoursTo}
              onChange={date => checkDateInput(date, setHoursTo)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              className="form-control"
            />
            {errors.data && errors.data.to_hour && <Error content="Không được để trống mục này" />}
            {timeWrong && <Error content="Khoảng thời gian chưa chính xác, vui lòng kiểm tra lại" />}
          </Col>
        </Form.Group>
      </div>
    </div>

    <div className="row no-gutters">
      <div className="col-5 pr-2">
        <Form.Group>
          <Form.Control type="text" name={`data[house_number]`} defaultValue={userInfo.houseNumber || "DDB-BRO"} ref={register({ required: true })} disabled placeholder="Nhập số căn hộ" />
        </Form.Group>
      </div>
      <div className="col-7">
        <Form.Group className="d-none">
          <Form.Control type="text" name={`data[register_date]`} value={valueDate} onChange={(e) => { }} ref={register({ required: true })} placeholder="Chọn ngày đăng ký" />
        </Form.Group>
        <DatePicker
          selected={date}
          onChange={handleChangeDate} className="form-control" dateFormat="dd/MM/yyyy"
          minDate={new Date()}
        />
        {errors.data && errors.data.register_date && <div><Error content="Không được để trống mục này" /></div>}
        {dateWrong && <div><Error content="Ngày đăng kí không được nhỏ hơn ngày hiện tại, vui lòng kiểm tra lại" /></div>}
      </div>
    </div>

    <div className="row">
      <div className="col-5">
        {props.isLogin ? <Button variant="success" type="submit" className="form-control">Đăng ký</Button> : <Button variant="success" title="Vui lòng đăng nhập để sử dụng tính năng này" className="form-control disabled">Đăng ký</Button>}
      </div>
    </div>
  </Form>)
  async function onSubmit(e) {
    let nowDate = new Date((new Date()).toDateString())
    if (ConverTimeToMinutes(e.data.from_hour) >= ConverTimeToMinutes(e.data.to_hour)) {
      setDateWrong(false)
      setTimeWrong(true)
    } else if (date.getTime() < nowDate.getTime()) {
      setTimeWrong(false)
      setDateWrong(true)
    } else {
      e.data.apartment_id = userInfo.apartmentId;
      if (props.onSubmit(e.data)) setRegisted(true)
      setTimeWrong(false)
      setDateWrong(false)
    }
  }
  function handleChangeDate(e) {
    setDate(e);
    setValueDate(getDateWithFormat(e))
  }
  return content
}

const mapStateToProps = state => ({
  location: state.bbq.location,
  isLogin: state.auth.isLoggedIn
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetBbqLocation, PostBbqRegisterUse,
  PromiseAllTools
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesBbqDesktopView)