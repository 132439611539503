/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MetaTags from "react-meta-tags";
import { FacebookShareButton } from "react-share";
import moment from "moment";
import { Dropdown, Modal, Button } from "react-bootstrap";

import * as appActions from "core/app.store";
import * as hotActions from "redux/stores/hot/hot.store";

import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { history } from "redux/store";
import {
  NotificationMessageType,
  SetDefaultErrorImage,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";

import "../hot-detail/hot-detail-desktop.scss";

moment.locale("vi");

class HotDetailDesktopView extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      hot: null,
      hotId: this.props.match.params.hotId,

      peopleTargetId: null,
      peopleTargetName: "",
      selectedCommentId: null,
      isOpenDeletePopup: false,
      deletedCommentModel: null,
      loading: false,
      comments: [],
      totalItems: 0,
      currentPageIndex: 1,
      currentSize: 5,
      currentUser: JwtHelper.currentUser(),
      loadMoreStep: 5,
      currentOpenSubComments: [],

      isLike: false,
    };

    this.onGetHotDetail = this.onGetHotDetail.bind(this);
    this.onGetComments = this.onGetComments.bind(this);
    this.onGetCommentModel = this.onGetCommentModel.bind(this);
    this.onGetReplyModel = this.onGetReplyModel.bind(this);
    this.onTargetCommentPeople = this.onTargetCommentPeople.bind(this);
    this.onPostComment = this.onPostComment.bind(this);
    this.onPostReply = this.onPostReply.bind(this);
    this.onToggleDeletePopup = this.onToggleDeletePopup.bind(this);
    this.onDeleteComment = this.onDeleteComment.bind(this);
    this.onEditComment = this.onEditComment.bind(this);
    this.onGetMoreComments = this.onGetMoreComments.bind(this);
    this.renderTotalComment = this.renderTotalComment.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.onGetHotDetail();
    this.state.currentUser && this.onGetComments();
    this.state.currentUser && this.IsLike(this.state.hotId);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate() {
    this.props.match.params.hotId &&
      this.state.hotId != this.props.match.params.hotId &&
      this.onGetHotDetail();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  IsLike = (hotId) => {
    this.props.isLike(hotId).then(
      (res) => {
        if (res && res.content) {
          this.setState({
            isLike: res.content.isDislike == 0 ? true : false,
          });
        }
      },
      (err) => {}
    );
  };

  UpdateLike = (hotId = this.state.hotId) => {
    this.props.updateLike(hotId).then(
      (res) => {
        this.IsLike(hotId);
        this.onGetHotDetail();
      },
      (err) => {}
    );
  };

  onGetHotDetail = () => {
    this.props.showLoading(true);
    this.props.getHotDetail(this.state.hotId).then(
      (res) => {
        if (res && res.content) {
          this.setState(
            {
              hot: res.content,
            },
            () => {
              this.props.showLoading(false);
            }
          );
        } else {
          this.props.showLoading(false);
          history.push("/hot");
          AddNotification(
            viVN.Errors[(res && res.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        this.props.showLoading(false);
        history.push("/hot");
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onGetComments = (
    pageIndex = this.state.currentPageIndex,
    size = this.state.currentSize
  ) => {
    if (this.state.hotId) {
      this.props.showLoading(true);
      this.props
        .getListComment(pageIndex, size, {}, this.state.hotId)
        .then((res) => {
          var items = (res && res.content && res.content.items) || [];
          items.map((item) => {
            if (
              this.state.currentOpenSubComments.some(
                (id) => id == item.commentId
              )
            ) {
              item.isShowSubComments = true;
            }
          });
          if (this._isMounted) {
            this.setState({
              comments: items,
              totalItems:
                (res && res.content && res.content.totalItemCount) || 0,
            });
          }
          this.onGetHotDetail();
        })
        .catch((err) => {
          this.props.showLoading(false);
        });
    }
  };

  onGetCommentModel = (comment) => {
    return {
      id: comment.commentId,
      profileUrl: comment.profileUrl,
      content: comment.content,
      createdBy: comment.customerName,
      createdById: comment.customerId,
      createdDate: comment.createdDate,
      replyData: comment.replyData,
      isAllowEdit:
        this.state.currentUser &&
        comment.customerId == this.state.currentUser.customerId,
      adId: this.state.hotId,
      totalSubComments: (comment.replyData && comment.replyData.length) || 0,
      type: "comment",
    };
  };

  onGetReplyModel = (reply, commentId) => {
    return {
      id: reply.commentId || 0,
      parent_id: commentId,
      profileUrl: reply.profileUrl,
      content: reply.content,
      createdBy: reply.customerName,
      createdById: reply.customerId,
      createdDate: reply.createdDate,
      isAllowEdit:
        this.state.currentUser &&
        reply.customerId == this.state.currentUser.customerId,
      adId: this.state.hotId,
      type: "reply",
    };
  };

  onTargetCommentPeople = (
    selectCommentId,
    peopleTargetId,
    peopleTargetName,
    isShowSubComments
  ) => {
    this.setState(
      {
        peopleTargetId: peopleTargetId,
        peopleTargetName: peopleTargetName,
        selectCommentId: selectCommentId,
      },
      () => {
        var commentReferences = [...this.state.comments];
        var commentReferenceIndex = commentReferences.findIndex(
          (cm) => cm.commentId == selectCommentId
        );
        if (commentReferenceIndex >= 0) {
          commentReferences[
            commentReferenceIndex
          ].isShowSubComments = isShowSubComments;
          this.setState(
            {
              comments: [...commentReferences],
            },
            () => {
              if (isShowSubComments) {
                this.setState({
                  currentOpenSubComments: [
                    ...this.state.currentOpenSubComments,
                    selectCommentId,
                  ],
                });
                this["replyInput" + selectCommentId] &&
                  this["replyInput" + selectCommentId].focus();
              } else {
                this.setState({
                  currentOpenSubComments: [
                    ...this.state.currentOpenSubComments.filter(
                      (id) => id != selectCommentId
                    ),
                  ],
                });
              }
            }
          );
        }
      }
    );
  };

  onPostComment = (e) => {
    e.preventDefault();
    if (!this.commentInput.value.trim()) {
      return;
    }
    this.props.showLoading(true);
    this.props
      .createOrUpdateComment({
        content: this.commentInput.value && this.commentInput.value.trim(),
        parent_id: 0,
        hot_id: this.state.hotId,
        level: 1,
      })
      .then(
        (res) => {
          this.commentInput.value = "";
          this.onGetComments();
          AddNotification(
            "Gửi bình luận thành công!",
            NotificationMessageType.Success
          );
        },
        (err) => {
          this.props.showLoading(false);
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
  };

  onPostReply = (commentId, e) => {
    e.preventDefault();
    if (!this["replyInput" + commentId].value.trim()) {
      return;
    }
    this.props.showLoading(true);
    this.props
      .createOrUpdateComment({
        content:
          this["replyInput" + commentId].value &&
          this["replyInput" + commentId].value.trim(),
        parent_id: commentId,
        hot_id: this.state.hotId,
        level: 2,
      })
      .then(
        (res) => {
          this["replyInput" + commentId].value = "";
          this.onGetComments();
          AddNotification(
            "Gửi phản hồi thành công!",
            NotificationMessageType.Success
          );
        },
        (err) => {
          this.props.showLoading(false);
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
  };

  onToggleDeletePopup = (isOpenDeletePopup, commentId) => {
    this.setState({
      isOpenDeletePopup: isOpenDeletePopup,
      deletedCommentId: commentId,
    });
  };

  onDeleteComment = (isConfirm) => {
    if (isConfirm) {
      this.props.showLoading(true);
      this.props.deleteComment(this.state.deletedCommentId).then(
        (res) => {
          AddNotification(
            "Xoá bình luận thành công!",
            NotificationMessageType.Success
          );
          this.setState({
            isOpenDeletePopup: false,
          });
          this.onGetComments();
        },
        (err) => {
          this.props.showLoading(false);
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
          this.setState({
            isOpenDeletePopup: false,
          });
        }
      );
    }
  };

  onEditComment = async (editingModel) => {
    let modelToUpdate = {
      comment_id: editingModel.commentId,
      content: editingModel.content,
      customer_id: this.state.currentUser.customerId,
      parent_id: editingModel.parent_id,
      ad_id: this.props.adId,
      level: editingModel.type == "reply" ? 2 : 1,
    };
    this.props.showLoading(true);
    return this.props.createOrUpdateComment(modelToUpdate).then(
      (res) => {
        AddNotification(
          "Cập nhật bình luận thành công!",
          NotificationMessageType.Success
        );
        this.onGetComments();
      },
      (err) => {
        this.props.showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onGetMoreComments = () => {
    this.setState(
      {
        currentSize: this.state.currentSize + this.state.loadMoreStep,
      },
      () => {
        this.onGetComments();
      }
    );
  };

  renderTotalComment = () => {
    let { comments } = this.state;

    if (comments && comments.length <= 0) return 0;

    let totalComment = comments.length;
    comments.map((item) => {
      if (item.replyData) totalComment = totalComment + item.replyData.length;
    });

    return totalComment;
  };

  render() {
    const { loggedIn } = this.props;
    const {
      hot,
      comments,
      totalItems,
      isOpenDeletePopup,
      loadMoreStep,
      isLike,
    } = this.state;

    return (
      <div>
        {hot && (
          <div className="hot-detail-desktop">
            <MetaTags>
              <meta property="og:title" content={hot.title} />
              <meta property="og:site_name" content="iLang" />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:description" content={hot.title} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={hot.imageUrl} />
            </MetaTags>
            <img
              src={hot.imageUrl}
              alt={hot.title}
              onError={SetDefaultErrorImage}
              className="img-fluid hot-detail-img"
            />
            <div className="hot-title">
              <div className="row no-gutters">
                <div className="col-3 pr-3">
                  <img
                    src={hot.avatarUrl}
                    alt={hot.title}
                    onError={SetDefaultErrorImage}
                    className="img-fluid img-logo"
                  />
                </div>
                <div className="col-9">
                  <h5>{hot.title}</h5>
                  <span>
                    <img
                      src={require("assets/images/clock-15px.svg")}
                      alt="Clock"
                      className="img-fluid mb-1 mr-1"
                    />
                    Từ {hot.fromDate} đến {hot.toDate}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="hot-body text-justify"
              dangerouslySetInnerHTML={{ __html: hot.content }}
            ></div>

            <div className="comment-like-share mt-3">
              <div className="d-flex comment-like-quantity align-items-center justify-content-between pb-2">
                <div className="like-quantity">
                  <img
                    src={require("/assets/images/like-quantity.svg")}
                    alt="Like"
                  />{" "}
                  {hot.likeCount || 0} Thích
                </div>
                <div className="share-quantity">
                  {hot.commentCount || 0} Bình Luận
                </div>
              </div>

              <div className="d-flex comment-like-share-action align-items-center pt-2 pb-2">
                <div
                  className={`like-action d-flex align-items-center ${
                    !loggedIn ? "not-allowed" : ""
                  }`}
                  onClick={() => loggedIn && this.UpdateLike()}
                >
                  <img
                    src={
                      isLike
                        ? require("/assets/images/like-quantity.svg")
                        : require("/assets/images/like-action.svg")
                    }
                    alt="Like"
                    className="mr-1"
                  />{" "}
                  Thích
                </div>
                <div
                  className={`comment-action ml-4 d-flex align-items-center ${
                    !loggedIn ? "not-allowed" : ""
                  }`}
                  onClick={() =>
                    loggedIn && this.commentInput && this.commentInput.focus()
                  }
                >
                  <img
                    src={require("/assets/images/comment-action.svg")}
                    alt="Like"
                    className="mr-1"
                  />{" "}
                  Bình Luận
                </div>
                <div className="share-action ml-4 d-flex align-items-center">
                  <FacebookShareButton url={window.location.href}>
                    <img
                      src={require("/assets/images/share-action.svg")}
                      alt="Like"
                      className="mr-1"
                    />{" "}
                    Chia Sẻ
                  </FacebookShareButton>
                </div>
              </div>

              {comments.map((cm, indexCm) => (
                <div key={"indexCm" + indexCm} className="comment-wrapper">
                  <div className="comment-parent">
                    <RenderComment
                      loggedIn={loggedIn}
                      data={this.onGetCommentModel(cm)}
                      onTargetCommentPeople={this.onTargetCommentPeople.bind(
                        this
                      )}
                      onToggleDeletePopup={this.onToggleDeletePopup.bind(this)}
                      onEditComment={this.onEditComment.bind(this)}
                    />
                    {cm.isShowSubComments && (
                      <div className="sub-comment">
                        {cm.replyData.map((rp, indexRp) => (
                          <div
                            key={"indexRp" + indexRp}
                            className="comment-child"
                          >
                            <RenderComment
                              loggedIn={loggedIn}
                              data={this.onGetReplyModel(rp, cm.commentId)}
                              onTargetCommentPeople={this.onTargetCommentPeople.bind(
                                this
                              )}
                              onToggleDeletePopup={this.onToggleDeletePopup.bind(
                                this
                              )}
                              onEditComment={this.onEditComment.bind(this)}
                            />
                            {/* <RenderSubComment /> */}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {loggedIn && cm.isShowSubComments && (
                    <form
                      onSubmit={(e) => this.onPostReply(cm.commentId, e)}
                      className="comment-child"
                    >
                      <div className="form-group position-relative">
                        <input
                          name="reply_content"
                          type="text"
                          placeholder="Viết phản hồi"
                          autoComplete="off"
                          className="form-control rounded-pill"
                          ref={(input) => {
                            this["replyInput" + cm.commentId] = input;
                          }}
                        />
                        <img
                          src={require("assets/images/send-gray.svg")}
                          alt="Send"
                          className="img-fluid img-send"
                          onClick={(e) => this.onPostReply(cm.commentId, e)}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ))}

              {totalItems > comments.length && (
                <a onClick={this.onGetMoreComments.bind(this)}>
                  Xem thêm{" "}
                  {totalItems - comments.length >= loadMoreStep
                    ? loadMoreStep
                    : totalItems - comments.length}{" "}
                  bình luận
                </a>
              )}

              <form onSubmit={this.onPostComment}>
                <div className="comment-box mt-3 form-group position-relative">
                  <input
                    name="content"
                    type="text"
                    placeholder={
                      loggedIn
                        ? "Nhập bình luận..."
                        : "Bạn cần đăng nhập để bình luận..."
                    }
                    autoComplete="off"
                    className="form-control rounded-pill"
                    disabled={!loggedIn}
                    ref={(input) => {
                      this.commentInput = input;
                    }}
                  />
                  <img
                    src={require("/assets/images/send-gray.svg")}
                    alt="Send"
                    className="img-fluid img-send"
                    onClick={(e) => loggedIn && this.onPostComment(e)}
                  />
                </div>
              </form>

              <Modal
                show={isOpenDeletePopup}
                className="modal-delete"
                centered
                onHide={() => this.onToggleDeletePopup(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Xóa bình luận?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Bình luận sẽ không thể khôi phục sau khi xóa.
                  <br />
                  Bạn có chắc muốn xóa bình luận này.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.onToggleDeletePopup(false)}
                  >
                    Hủy
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => this.onDeleteComment(true)}
                  >
                    Xóa
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function RenderComment(props) {
  const { data, loggedIn } = props;
  let commentInput = null;
  const [isShowEditForm, setShowEditForm] = useState(false);
  const [isShowSubComments, setShowSubComments] = useState(false);

  const onShowDeletePopup = (deletingCommentId) => {
    props.onToggleDeletePopup(true, deletingCommentId);
  };

  const onEditComment = async (e) => {
    e.preventDefault();
    if (!commentInput.value.trim()) {
      return;
    }
    let editedData = { ...data };
    editedData.commentId = data.id;
    editedData.content = commentInput.value && commentInput.value.trim();
    await props.onEditComment(editedData);
    setShowEditForm(false);
  };

  const onToggleSubComments = () => {
    props.onTargetCommentPeople(
      data.commentId || data.id,
      data.createdById,
      data.createdBy,
      !isShowSubComments
    );
    setShowSubComments(!isShowSubComments);
  };

  return (
    <div className="form-comment row no-gutters">
      <div className="col-1">
        <img
          src={data.profileUrl || require("assets/images/29-04.png")}
          alt="Avatar"
          onError={SetDefaultErrorImage}
          className="img-fluid img-avatar"
        />
      </div>
      {!isShowEditForm && (
        <div className="col-11 pl-2">
          <div>
            <h5 className="full-name d-flex mb-0">
              {data.createdBy}
              {loggedIn && data.isAllowEdit && (
                <Dropdown>
                  <Dropdown.Toggle>
                    <img
                      src={require("assets/images/more.svg")}
                      className="img-fluid img-more"
                      alt="more"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShowEditForm(true)}>
                      <img
                        src={require("assets/images/pen.svg")}
                        className="img-fluid img-pen"
                        alt="pen"
                      />
                      <span>Chỉnh sửa bình luận</span>
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => setShowSubComments(true)}> */}
                    <Dropdown.Item onClick={() => onShowDeletePopup(data.id)}>
                      <img
                        src={require("assets/images/delete.svg")}
                        className="img-fluid img-delete"
                        alt="pen"
                      />
                      <span>Xóa</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </h5>
            <p className="text-break mb-0">{data.content}</p>
            <a onClick={onToggleSubComments.bind(this)} className="reply">
              Trả lời
            </a>
            <span className="comment-time">
              {" "}
              {moment(data.createdDate).fromNow()}{" "}
            </span>
            {data.type == "comment" && (
              <a onClick={onToggleSubComments.bind(this)} className="reply">
                <span></span>
                {data.totalSubComments} phản hồi
              </a>
            )}
          </div>
        </div>
      )}
      {isShowEditForm && (
        <form className="col-11 pl-2" onSubmit={onEditComment}>
          <div className="form-group position-relative">
            <input
              name="content"
              defaultValue={data.content}
              type="text"
              placeholder="Viết phản hồi..."
              className="form-control rounded-pill"
              ref={(input) => {
                commentInput = input;
              }}
            />
            <img
              src={require("/assets/images/send-gray.svg")}
              alt="Send"
              className="img-fluid img-send"
              onClick={onEditComment.bind(this)}
            />
            <a className="cancel-edit" onClick={() => setShowEditForm(false)}>
              Huỷ
            </a>
          </div>
        </form>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getHotDetail: hotActions.GetHotDetail,
      createOrUpdateComment: hotActions.AddOrUpdateComment,
      deleteComment: hotActions.CommentDelete,
      getListComment: hotActions.GetListComment,
      updateLike: hotActions.UpdateLike,
      isLike: hotActions.IsLike,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotDetailDesktopView);
