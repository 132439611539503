import { Map } from 'immutable';
import Service from 'services/api.service';
import { createFormData, AppKey, ConvertNumberToString, ERRORTYPEEMPTYDATAERROR } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';
import { createSaveLoading } from 'core/app.store';
import { WaterPaymentGuideKeyword, GetContentByKeyword } from '../../get-content-by-keyword/getContentByKeyword.store';
import { JwtHelper } from 'utils/jwt-helper';

const userInfor = JwtHelper.currentUser();
const service = new Service();
const INITBILLTHISMONTH = 'WATERBILL/INITBILLTHISMONTH';
const INITPAYMENTHISTORY = 'WATERBILL/PAYMENTHISTORY';
const INITBILLDETAIL = 'WATERBILL/BILLDETAIL';
const INITPAYMENTGUIDE = 'WATERBILL/GUIDEPAYMENT';

export const InitForm = data => ({
    type: INITBILLTHISMONTH, data: data
})

export const InitHistory = data => ({
    type: INITPAYMENTHISTORY, data: data
})

export const InitDetail = data => ({
    type: INITBILLDETAIL, data: data
})

export const InitGuide = data => ({
    type: INITPAYMENTGUIDE, data: data
})

export const ResetHistoryList = () => {
    return dispatch => {
        dispatch(InitHistory([]))
    }
}

export const GetPaymentGuide = () => {
    return GetContentByKeyword(WaterPaymentGuideKeyword, InitGuide)
}

export const GetWaterBillThisMonth = (apartmentId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = null;
        let formData = new FormData();
        formData.append('apartmentId', apartmentId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.WaterBillThisMonth, payLoad).then(res => {
            if (res.errorType === ERRORTYPEEMPTYDATAERROR) return false
            rawData = res.content;
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            dispatch(InitForm(ExchangeDataBillThisMonth(rawData)))
        })

    }
}

export const GetWaterList = (pageIndex = 1, size = 10, filterObjs, apartmentId) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let rawData = null;
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        formData.append('apartmentId', apartmentId);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.ListWaterBillByTime, payLoad).then(res => {
            rawData = res.content.items;
            return true;
        }).catch(err => {
            console.log(err)
            return false
        }).finally(() => {
            dispatch(InitHistory(ExchangeDataBillList(rawData)))
        })
    }
}

export const GetListWaterDetail = (waterId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = null;
        let formData = new FormData();
        formData.append('waterId', waterId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.WaterBillDetail, payLoad).then(res => {
            if (res.errorType === ERRORTYPEEMPTYDATAERROR) return false
            rawData = res.content
            return true
        }).catch(err => {
            console.log(err)
            return false
        }).finally(() => {
            dispatch(InitDetail(ExchangeDataBillThisMonth(rawData)))
        })

    }
}

function ExchangeDataBillThisMonth(rawData) {
    if (rawData != null) return {
        id: rawData.waterId,
        haveData: rawData.total > 0 ? true : false,
        userName: userInfor.customerName,
        houseNumber: rawData.houseNumber,
        monthly: rawData.monthly,
        yearly: rawData.yearly,
        newIndex: ConvertNumberToString(rawData.newIndex),
        oldIndex: ConvertNumberToString(rawData.oldIndex),
        usedIndex: ConvertNumberToString(rawData.newIndex - rawData.oldIndex),
        unitPrice: ConvertNumberToString(rawData.unnitPrice),
        intoMoney: ConvertNumberToString(rawData.intoMoney),
        tax: rawData.vatPercent,
        taxMoney: ConvertNumberToString(rawData.tax),
        total: ConvertNumberToString(rawData.total),
        datePay: rawData.datePay,
    }; else return {
        haveData: false
    }
}

function ExchangeDataBillList(rawData) {
    const standardData = [];

    if (rawData) {
        rawData.map((data) => {
            standardData.push({
                monthly: data.monthly,
                yearly: data.yearly,
                datePay: data.datePay,
                total: ConvertNumberToString(data.total),
                billId: data.waterId
            })
        })
    }

    return standardData
}

const initialState = Map({
    billThisMonth: {},
    paymentHistory: {},
    billDetail: {},
    paymentGuide: ""
}).toJS()

export default function WaterBillReducer(state = initialState, action) {
    switch (action.type) {
        case INITBILLTHISMONTH:
            return {
                ...state,
                billThisMonth: action.data
            }
        case INITPAYMENTHISTORY: {
            return {
                ...state,
                paymentHistory: action.data
            }
        }
        case INITBILLDETAIL:
            return {
                ...state,
                billDetail: action.data
            }
        case INITPAYMENTGUIDE:
            return {
                ...state,
                paymentGuide: action.data
            }
        default:
            return state
    }
}