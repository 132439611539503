import { Map } from 'immutable';
import { createFormData, AppKey } from '../../../utils/configuration';
import Service from '../../../services/api.service';
import { ApiUrl } from '../../../services/api-url';
import { createSaveLoading } from '../../../core/app.store';
import { Friends } from '../../../mock-data/friend';

const service = new Service();

export const FriendStatus = {
    NotFriend: 1,
    ConfirmingFriend: 2,
    RequestingFriend: 3,
    Friend: 4
}

/** Action Types */
const FRIENDSAVESUGGESTIONLIST = 'FRIEND/SUGGESTIONLIST';
const FRIENDGETLIST = 'FRIEND/GETLIST';
const FRIENDSAVELIST = 'FRIEND/SAVELIST';

/** Actions */
export const createSaveSuggestionFriends = (friendModel) => ({ type: FRIENDSAVESUGGESTIONLIST, payload: { friends: friendModel.friends, totalItems: friendModel.totalItems } });
export const createSaveFriends = (friendModel) => ({ type: FRIENDSAVELIST, payload: { friends: friendModel.friends, totalItems: friendModel.totalItems } });
export const createGetFriendList = () => ({ type: FRIENDGETLIST });


/** Action creators */
export const GetFriendSuggestionList = (pageIndex, size, customerId, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        formData.append('customerId', customerId);

        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.FriendGetSuggestionList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveSuggestionFriends({ friends: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetFriends = (pageIndex, size, customerId, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        formData.append('customerId', customerId);

        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.FriendGetList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveFriends({ friends: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetFriendDetail = (customerId, friendCustomerId) => {
    return dispatch => {
        let payLoad = createFormData({ customerId: customerId, friendCustomerId: friendCustomerId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.FriendGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteFriend = (customerId, friendCustomerId) => {
    return dispatch => {
        let payLoad = createFormData({ customerId: customerId, friendCustomerId: friendCustomerId });
        return service.post(ApiUrl.FriendCancel, payLoad).then(res => {
            //TODO: do something 
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const AcceptFriend = (customerId, friendCustomerId) => {
    return dispatch => {
        let payLoad = createFormData({ customerId: customerId, friendCustomerId: friendCustomerId });
        return service.post(ApiUrl.FriendAcceptRequest, payLoad).then(res => {
            //TODO: do something 
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const CancelFriend = (customerId, friendCustomerId) => {
    return dispatch => {
        let payLoad = createFormData({ customerId: customerId, friendCustomerId: friendCustomerId });
        return service.post(ApiUrl.FriendRemove, payLoad).then(res => {
            //TODO: do something 
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const CreateOrUpdateFriend = (friendModel) => {
    return dispatch => {
        let payLoad = createFormData(friendModel, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.FriendCreateOrUpdate, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}
/**
 * 
 * @param {*} notificationModel 
 * inform_name: 'Yêu Cầu Kết Bạn'
 * inform_type: 'private'
 * sender_type: 'customer'
 * sender_id: currentUser.id
 * receiver_type: 'customer',
 * receiver_id: customer.id
 * description: 'Bạn có yêu cầu kết bạn mới'
 * content: ''
 */
export const CreateAddFriendNotification = (notificationModel) => {
    return dispatch => {
        let payLoad = createFormData(notificationModel, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.NotificationPushMessage, payLoad).then(res => {
            //TODO: do something 
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

// Initial state
export const initialState = Map({
    suggestionFriends: [],
    totalItems: 0,
    friends: [],
    friendTotalItems: 0,
}).toJS();

/** Reducers */
export default function FriendReducer(state = initialState, action) {
    switch (action.type) {
        case FRIENDSAVESUGGESTIONLIST:
            return {
                ...state,
                suggestionFriends: [...action.payload.friends],
                totalItems: action.payload.totalItems,
            }
        case FRIENDSAVELIST:
            return {
                ...state,
                friends: [...action.payload.friends],
                friendTotalItems: action.payload.totalItems,
            }
        case FRIENDGETLIST:
            return state.friendList;
        default:
            return state;
    }
}
