import React, { useState, useEffect, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Tab, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as IncidentAreaStore from 'redux/stores/safe/incidents-area.store';
import GoogleMapMobile from '../../gmap/googlemap-mobile.view';
import Pagination from 'components/pagination/paging.view.jsx';
import ReportIncidentsFormMobile from '../../feedback-mobile/report-incidents.form.jsx';
import { StripHtmlTag, CutTextByLength, SetDefaultErrorImage } from 'utils/configuration';
import "../safe-mobile.scss";
import Slider from "react-slick";
import { PromiseAllTools } from 'core/app.store';

function IncidentsAreaMobileView(props) {
    const isLogin = props.loggedIn;
    const PageSize = 10;
    const [isSolved, setSolved] = useState(false);
    const listData = (props.incidentAreasMobile && props.incidentAreasMobile.haveData) ? props.incidentAreasMobile.listData : [];
    const totalItems = (props.incidentAreasMobile && props.incidentAreasMobile.haveData) ? props.incidentAreasMobile.total : 0;
    const listIncidentsAreaNotSolved = [];
    const listIncidentsAreaSolved = [];
    const [showReport, setShowReport] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    function handleShowReport() {
        props.PromiseAllTools([props.GetIncidentAreasMobile(1, 100, false)])
        setShowReport(true)
    }

    function handleShowDetail(id) {
        props.PromiseAllTools([props.GetIncidentsAreaDetail(id)]);
        setShowDetail(true)
    }

    function handleChangeSolved() {
        setSolved(true);
        setShowDetail(false);
        setShowReport(false)
        props.PromiseAllTools([props.GetIncidentAreasMobile(1, PageSize, true)])
    }

    function handleChangeNotSolved() {
        setSolved(false)
        setShowDetail(false);
        setShowReport(false)
        props.PromiseAllTools([props.GetIncidentAreasMobile(1, PageSize, false)])
    }

    function goTo(id) {
        props.PromiseAllTools([props.GetIncidentAreasMobile(id, PageSize, isSolved)])
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (props.match.params.idIncident) {
            handleShowDetail(Number(props.match.params.idIncident));
        } else props.PromiseAllTools([props.GetIncidentAreasMobile(1, PageSize, isSolved)])
    }, [])

    listData.map((data) => {
        if (!data.solved) {
            listIncidentsAreaNotSolved.push({
                lat: data.lat,
                lng: data.lng
            })
        } else {
            listIncidentsAreaSolved.push({
                lat: data.lat,
                lng: data.lng
            })
        }
    })

    return (
        <div className="incidents-area-mobile">
            <header className="header-mobile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/m/an-toan">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                            <div className="header-title text-center">Sự cố trong khu</div>
                        </div>
                    </div>
                </div>
            </header>

            {
                (!showReport) && <GoogleMapMobile listMarkerTwo={listIncidentsAreaNotSolved} listMarkerOne={listIncidentsAreaSolved} />
            }

            <Tab.Container defaultActiveKey="dang-xu-ly">
                <Nav variant="tabs" className="tab-control">
                    <Nav.Item>
                        <Nav.Link eventKey="dang-xu-ly" className="rounded-pill" onClick={() => handleChangeNotSolved()}>
                            <img src={require('assets/images/processing-green.svg')} alt="Processing" className="img-fluid img-inactive" />
                            <img src={require('assets/images/processing-white.svg')} alt="Processing" className="img-fluid img-active" />
                            <span className="ml-2">Đang xử lý</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="da-xu-ly" className="rounded-pill" onClick={() => handleChangeSolved()}>
                            <img src={require('assets/images/processed-green.svg')} alt="Processed" className="img-fluid img-inactive" />
                            <img src={require('assets/images/processed-white.svg')} alt="Processed" className="img-fluid img-active" />
                            <span className="ml-2">Đã xử lý</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="bao-su-co" className="report-problem rounded-pill" onClick={() => handleShowReport()}>
                            <img src={require('assets/images/report-problem-yellow.svg')} alt="Report Problem" className="img-fluid img-inactive" />
                            <img src={require('assets/images/report-problem-white.svg')} alt="Report Problem" className="img-fluid img-active" />
                            <span className="ml-2">Báo sự cố</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="dang-xu-ly">
                        <div className="tab-xu-ly p-3">
                            {(!showDetail) ? listData.map((data, index) => <IncidentsProcess data={data} key={index} click={() => handleShowDetail(data.id)} />) : <IncidentsProcessDetail data={props.incidentDetail} />}
                            {(!showDetail) && <Pagination totalItems={totalItems} goTo={goTo} size={PageSize} />}
                            {(listData.length===0)&&<p>Không có dữ liệu để hiển thị</p>}
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="da-xu-ly">
                        <div className="tab-xu-ly p-3">
                            {(!showDetail) ? listData.map((data, index) => <IncidentsProcess data={data} key={index} click={() => handleShowDetail(data.id)} />) : <IncidentsProcessDetail data={props.incidentDetail} />}
                            {(!showDetail) && <Pagination totalItems={totalItems} goTo={goTo} size={PageSize} />}
                            {(listData.length===0)&&<p>Không có dữ liệu để hiển thị</p>}
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="bao-su-co" title="Báo sự cố">
                        <div className="tab-bao-su-co">
                            {(showReport) && <ReportIncidentsFormMobile isLogin={isLogin} />}
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
}

function IncidentsProcess(props) {
    const data = props.data
    return (
        <div className="item mb-3" onClick={props.click}>
            <div className="row no-gutters">
                <div className="col-12">
                    <img src={data.url} onError={SetDefaultErrorImage} alt={data.content} className="img-fluid w-100 rounded-top" />
                </div>
                <div className="col-12">
                    <div className="item-body p-2">
                        <h5>
                            {CutTextByLength(StripHtmlTag(data.content), 236)}
                        </h5>
                        <div className="row">
                            <div className="col-6">
                                <span className="datetime">{data.time}</span>
                            </div>
                            <div className="col-6 text-right">
                                {!data.solved ? <span className="text-primary">Đang xử lý</span> : <span className="text-success">Đã xử lý</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function IncidentsProcessDetail(props) {
    const data = props.data || {};
    const slideFiles = data.files || [];
    const settingsSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    }
    const contentObj = { __html: data.content || "" }
    return (
        <div className="item-detail">
            <Slider {...settingsSlider} className="slider-detail">
                {slideFiles.map((data, index) => <RenderSlide data={data} key={index} />)}
                {(slideFiles.length === 0) && <img src="img-fluid w-100" className="" onError={SetDefaultErrorImage} />}
            </Slider>
            <div className="item-detail-header mt-2">
                <div className="row no-gutters">
                    <div className="col-9">
                        <div className="float-left">
                            <img src={require('assets/images/place-red.svg')} alt="Image" className="img-fluid pr-2" />
                        </div>
                        <div>
                            <h5>{data.address}</h5>
                            <span className="time">{data.time}</span>
                        </div>
                    </div>
                    <div className="col-3 text-right">
                        {!data.solved ? <span className="text-primary">Đang xử lý</span> : <span className="text-success">Đã xử lý</span>}

                    </div>
                </div>
            </div>
            <div className="item-detail-content mt-2" dangerouslySetInnerHTML={contentObj}>
            </div>
        </div>
    )
}

function RenderVideo(props) {
    const [isVideoError, setVideoError] = useState(false);
    const [isFirtTime, setFirstTime] = useState(true);
    function changeState() {
        if (isFirtTime) {
            setFirstTime(false)
            setVideoError(true)
        }
    }
    return (
        <Fragment>
            {(!isVideoError) && <video className="w-100" controls>
                <source src={props.urlVideo} type="video/mp4" onError={changeState} />
        Your browser does not support the video tag.
        </video>}
            {isVideoError && <img src="" className="w-100  img-incident-detail " onError={SetDefaultErrorImage} />}
        </Fragment>

    )
}
function RenderSlide(props) {
    const data = props.data;
    console.log(data)
    const content = (data.fileType === "image") ? <img src={data.fileUrl} className="img-fluid img-incident-detail w-100" onError={SetDefaultErrorImage} /> : <RenderVideo urlVideo={data.fileUrl} />;
    return (
        <div>
            {content}
        </div>
    )
}

const mapStateToProps = state => ({
    incidentAreasMobile: state.incidentsArea.incidentAreasMobile,
    incidentDetail: state.incidentsArea.incidentDetail,
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetIncidentAreasMobile: IncidentAreaStore.GetIncidentAreasMobile,
    GetIncidentsAreaDetail: IncidentAreaStore.GetIncidentsAreaDetail,
    PromiseAllTools
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsAreaMobileView)