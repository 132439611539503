import "./management-mobile.scss";
import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import ManagementInfoMobileView from "./management-info/management-info-mobile.view";
import OnDutyScheduleMobileView from './on-duty-schedule/on-duty-schedule-mobile.view.jsx';
import LegalListMobileView from './legal/legal-list-mobile.view.jsx';
import TemporaryAbsenceDeclarationMobileView from './temporary-absence-declaration/temporary-absence-declaration-mobile.view.jsx';
import ProcedureCategoryMobileView from './procedure-category/procedure-category-mobile.view.jsx';
import SecurityCameraMobileView from './security-camera/security-camera.view.jsx';

class ManagementMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideObj: null,
      firstTime: true,
      typeId: this.props.match.params.typeId,
    }
  }

  setValue(value) {
    if (this.state.firstTime) {
      this.setState({
        firstTime: false,
        slideObj: value
      })
    }
  }

  handleClickNav(i) {
    const navList = Array.from(document.getElementsByClassName("m-management-nav"));
    const slideList = document.getElementsByClassName("slick-slide");

    navList.map((navTitle, index) => {
      if (i === index) {
        navTitle.classList.add("disabled");
        slideList[index].classList.add("active");
      } else {
        navTitle.classList.remove("disabled");
        slideList[index].classList.remove("active");
      }
    })
    if (i === 3) {
      this.state.slideObj && this.state.slideObj.slickGoTo(2)
    }
  }

  componentDidMount() {
    this.handleClickNav(+this.state.typeId || 0);
    document.getElementsByClassName("slick-slide")[+this.state.typeId || 0].classList.add("active");
    document.getElementsByClassName("m-management-nav")[+this.state.typeId || 0].classList.add("disabled");
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2,
      arrows: false,
    }
    const { typeId } = this.state;

    return (
      <div className="management-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m">
                  <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                </Link>
                <div className="header-title text-center">BQL</div>
              </div>
            </div>
          </div>
        </header>

        <Accordion defaultActiveKey={(typeId || 0).toString()}>
          <div className="management-nav">
            <Slider ref={slider => this.setValue(slider)} {...settings}>
              <div className="list-inline-item">
                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(0)} variant="link" className="m-management-nav"  eventKey="0">
                  Thông tin BQL
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(1)} variant="link"className="m-management-nav"  eventKey="1">
                  Văn bản pháp lý
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(2)} variant="link" className="m-management-nav"  eventKey="2">
                  Lịch trực BQL
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(3)} variant="link" className="m-management-nav"  eventKey="3">
                  Thủ tục BQL
                </Accordion.Toggle>
              </div>
              <div className="list-inline-item">
                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(4)} variant="link" className="m-management-nav"  eventKey="4">
                  Khai báo tạm trú
                </Accordion.Toggle>
              </div>
              {/* <div className="list-inline-item">
                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(5)} variant="link" className="m-management-nav"  eventKey="5">
                  Camera An Ninh
                </Accordion.Toggle>
              </div> */}
            </Slider>
          </div>
          <div className="mobile-divide"></div>

          <Accordion.Collapse eventKey="0">
            <ManagementInfoMobileView />
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <LegalListMobileView />
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="2">
            <OnDutyScheduleMobileView />
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="3">
            <ProcedureCategoryMobileView />
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="4">
            <TemporaryAbsenceDeclarationMobileView />
          </Accordion.Collapse>
          {/* <Accordion.Collapse eventKey="5">
            <SecurityCameraMobileView />
          </Accordion.Collapse> */}
        </Accordion>
      </div>
    );
  }
}

export default ManagementMobileView;