/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as hotActions from "redux/stores/hot/hot.store";
import { Link } from "react-router-dom";
import "../hot-desktop/hot-desktop.scss";
import PaginationControl from "components/pagination/pagination.view";
import { SetDefaultErrorImage } from "utils/configuration";

class HotDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHotList(1, 10, { orderBy: "update_date", orderType: "DESC" });
  }

  onGetHotList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getHotList(pageIndex, size, filterObjs).then(
      () => {},
      () => {}
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetHotList(e, 10, { orderBy: "update_date", orderType: "DESC" });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  render() {
    const { currentPageIndex } = this.state;
    const { hotList, totalItems } = this.props;
    return (
      <div className="hot-desktop">
        <div className="hot-title">
          <h5>HOT!</h5>
        </div>
        {hotList && hotList.length > 0 ? (
          <div className="row mt-3">
            {hotList.map((item) => (
              <RenderHotItem key={item.hotId} data={item} />
            ))}
          </div>
        ) : (
          <div className="row mt-3">
            <p className="col-12">Không có dữ liệu.</p>
          </div>
        )}

        {hotList && hotList.length > 0 && (
          <PaginationControl
            pageChanged={this.onSelectPage.bind(this)}
            totalItems={totalItems}
            currentPageIndex={currentPageIndex}
          />
        )}
      </div>
    );
  }
}

function RenderHotItem(props) {
  const data = props.data;
  return (
    <div className="col-12">
      <div className="item">
        <div className="row no-gutters">
          <div className="col-6">
            <Link to={`/hot/${data.hotId}`}>
              <img src={data.imageUrl} alt={data.title} onError={SetDefaultErrorImage} className="hot-image" />
            </Link>
          </div>
          <div className="col-6">
            <div className="item-content">
              <h6>
                <Link to={`/hot/${data.hotId}`}>{data.title}</Link>
              </h6>
              <span>
                <img
                  src={require("assets/images/clock-15px.svg")}
                  alt="Clock"
                  className="img-fluid mr-1 mb-1"
                />
                Từ {data.fromDate} đến {data.toDate}
              </span>
              {RenderText(data.description, 200)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const RenderText = (textOld, length) => {
  let textNew = textOld.slice(0, length);
  if (textOld.length > length) textNew += "...";
  return <p>{textNew}</p>;
};

const mapStateToProps = (state) => ({
  hotList: state.hot.hotList,
  totalItems: state.hot.totalItems
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHotList: hotActions.GetHotList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HotDesktopView);
