import { Map } from 'immutable';
import { createFormData, AppKey } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { Members } from 'mock-data/member.mock';
import { createSaveLoading } from 'core/app.store';
let membersTemp = [...Members];
const service = new Service();
/** Action Types */
const MEMBERSAVELIST = 'MEMBER/SAVELIST';
const MEMBERSAVETOP4MEMBER = 'MEMBER/SAVETOP4PMEMBER';
const MEMBERSAVEDETAIL = 'MEMBER/MEMBERSAVEDETAIL';

/** Actions */
export const createSaveMemberList = (memberModel) => ({ type: MEMBERSAVELIST, payload: { memberList: memberModel.memberList, totalItems: memberModel.totalItems } });
export const createSaveTop4Members = (top4Members) => ({ type: MEMBERSAVETOP4MEMBER, payload: top4Members });
export const createSaveMemberDetail = (memberModel) => ({ type: MEMBERSAVEDETAIL, payload: memberModel });

/** Action creators */
export const GetMemberList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'role_name' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.MemberGetList, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            dispatch(createSaveMemberList({ memberList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetTop4MemberList = (pageIndex = 1, size = 4, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.MemberGetList, payLoad).then(res => {
            dispatch(createSaveTop4Members(res.content && res.content.items || []))
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetMemberDetail = (agentId) => {
    return dispatch => {
        let payLoad = createFormData({ agentId: agentId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.MemberGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteMember = (agentId) => {
    return dispatch => {
        let payLoad = createFormData({ agentId: agentId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.MemberDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateMember = (memberModel) => {
    return dispatch => {
        let payLoad = createFormData(memberModel, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.MemberCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */
export const GetMemberListFake = (pageIndex, size, filterObjs) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let MemberModels = filterObjs ?
                membersTemp.filter(item => {
                    return item.category_id == filterObjs['category_id'];
                }) :
                membersTemp;
            let memberList = membersTemp.slice(pageIndex * size - size, pageIndex * size);
            if (memberList) {
                dispatch(createSaveMemberList({ memberList: memberList, totalItems: membersTemp.length }))
                resolve({
                    content: memberList,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const GetTop4MembersFake = (pageIndex, size = 4, filterObjs) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let MemberList = Members.slice(pageIndex * size - size, (pageIndex - 1) + size);
            if (MemberList) {
                dispatch(createSaveTop4Members(MemberList))
                resolve({
                    content: MemberList,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const GetMemberDetailFake = (agentId, filterObjs = null) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let memberDetail = membersTemp.find(lg => lg.id == agentId);
            if (memberDetail) {
                let newMemberDetail = {...memberDetail };
                if (filterObjs) {
                    const fromTime = new Date(filterObjs['from']);
                    const toTime = new Date(filterObjs['to']);
                    const newFromTime = new Date(fromTime.setDate(fromTime.getDate() - 1));
                    newMemberDetail.schedules = newMemberDetail.schedules.filter(item => {
                        return (new Date(item.schedule_time).getTime()) >= newFromTime.getTime() && (new Date(item.schedule_time).getTime()) <= toTime.getTime();
                    })
                }
                dispatch(createSaveMemberDetail(newMemberDetail));
                resolve({
                    content: newMemberDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    memberList: [],
    memberDetail: {},
    top4Members: [],
    totalItems: 0,
}).toJS();

/** Reducers */
export default function MemberReducer(state = initialState, action) {
    switch (action.type) {
        case MEMBERSAVELIST:
            return {
                ...state,
                memberList: [...action.payload.memberList],
                totalItems: action.payload.totalItems,
            }
        case MEMBERSAVETOP4MEMBER:
            return {
                ...state,
                top4Members: [...action.payload],
            }
        case MEMBERSAVEDETAIL:
            return {
                ...state,
                memberDetail: action.payload,
            }
        default:
            return state;
    }
}