export const AppUrls = {
	home: {
		desktop: '/',
		mobile: '/m',
	},
	searchResults: {
		desktop: '/ket-qua-tim-kiem/s=:keyword',
		mobile: '/m/ket-qua-tim-kiem/s=:keyword',
	},
	search: {
		desktop: '/',
		mobile: '/m/tim-kiemm',
	},
	login: {
		desktop: '/',
		mobile: '/m/dang-nhap',
	},
	register: {
		desktop: '/',
		mobile: '/m/dang-ky',
	},
	termsOfService: {
		desktop: '/',
		mobile: '/m/dieu-khoan-su-dung',
	},
	forgotPassword: {
		desktop: '/',
		mobile: '/m/quen-mat-khau',
	},
	confirmCode: {
		desktop: '/',
		mobile: '/m/xac-nhan/:type',
	},
	resetPassword: {
		desktop: '/',
		mobile: '/m/dat-lai-mat-khau/:type',
	},
	notification: {
		desktop: '/thong-bao',
		mobile: '/m/thong-bao',
	},
	notificationDetail: {
		desktop: '/thong-bao/chi-tiet/:idNotification',
		mobile: '/m/thong-bao/chi-tiet/:idNotification',
	},
	notificationDetailForcusMode: {
		desktop: '/thong-bao/chi-tiet/:idNotification/view=:forcusCode',
		mobile: '/m',
	},
	userInformation: {
		desktop: '/thong-tin-ca-nhan',
		mobile: '/m/thong-tin-ca-nhan',
	},
	userInformationWithId: {
		desktop: '/thong-tin-ca-nhan/:id',
		mobile: '/m/thong-tin-ca-nhan/:tag',
	},
	documentManager: {
		desktop: '/quan-ly-giay-to/danh-sach',
		mobile: '/m/quan-ly-giay-to',
	},
	userEditInformation: {
		desktop: '/',
		mobile: '/m/chinh-sua-thong-tin-ca-nhan',
	},
	resetPassword: {
		desktop: '/',
		mobile: '/m/dat-lai-mat-khau',
	},
	uploadDocument: {
		desktop: '/',
		mobile: '/m/them-giay-to',
	},
	editDocument: {
		desktop: '/',
		mobile: '/m/chinh-sua-giay-to/:id',
	},
	documentDetail: {
		desktop: '/',
		mobile: '/m/chi-tiet-giay-to/:id',
	},
	messageBox: {
		desktop: '/',
		mobile: '/m/tin-nhan',
	},
	registerShop: {
		desktop: '/',
		mobile: '/m/dang-ky-ban-hang',
	},
	userShop: {
		desktop: '/shop/:type/:id',
		mobile: '/m/shop/:type/:id',
	},
	shopInformation: {
		desktop: '/',
		mobile: '/m/shop/thong-tin-gian-hang/:type/:id',
	},
	shopPromotion: {
		desktop: '/',
		mobile: '/m/tin-khuyen-mai/:type/:id',
	},
	shopAddPromotion: {
		desktop: '/',
		mobile: '/m/dang-tin-khuyen-mai/:type/:id',
	},
	shopEditPromotion: {
		desktop: '/',
		mobile: '/m/chinh-sua-khuyen-mai/:type/:shopId/:id',
	},
	shopSetupCalendar: {
		desktop: '/',
		mobile: '/m/setup-lich/:type/:shopId',
	},
	hot: {
		desktop: '/hot',
		mobile: '/m/hot',
	},
	hotDetail: {
		desktop: '/hot/:hotId',
		mobile: '/m/hot/:hotId',
	},
	utilitiesList: {
		desktop: '/danh-sach-tien-ich',
		mobile: '/m/danh-sach-tien-ich',
	},
	utilitiesCafe: {
		desktop: '/tien-ich/cafe',
		mobile: '/m/tien-ich/cafe',
	},
	utilitiesCafeList: {
		desktop: '/tien-ich/danh-sach-cafe',
		mobile: '/m',
	},
	utilitiesCafeDetail: {
		desktop: '/tien-ich/cafe/:id',
		mobile: '/m/tien-ich/cafe/:id',
	},
	utilitiesSchoolList: {
		desktop: '/tien-ich/danh-sach-truong-hoc',
		mobile: '/m',
	},
	utilitiesSchoolDetail: {
		desktop: '/tien-ich/truong-hoc/:id',
		mobile: '/m/tien-ich/truong-hoc/:id',
	},
	utilitiesSchool: {
		desktop: '/tien-ich/truong-hoc',
		mobile: '/m/tien-ich/truong-hoc',
	},
	utilitiesSuperMarket: {
		desktop: '/tien-ich/sieu-thi',
		mobile: '/m/tien-ich/sieu-thi',
	},
	utilitiesSuperMarketList: {
		desktop: '/tien-ich/danh-sach-sieu-thi',
		mobile: '/m',
	},
	utilitiesSuperMarketDetail: {
		desktop: '/tien-ich/sieu-thi/:id',
		mobile: '/m/tien-ich/sieu-thi/:id',
	},
	utilitiesRestaurant: {
		desktop: '/tien-ich/nha-hang',
		mobile: '/m/tien-ich/nha-hang',
	},
	utilitiesRestaurantDetail: {
		desktop: '/tien-ich/nha-hang/:id',
		mobile: '/m/tien-ich/nha-hang/:id',
	},
	utilitiesBbq: {
		desktop: '/tien-ich/bbq',
		mobile: '/m',
	},
	utilitiesRestaurantList: {
		desktop: '/tien-ich/danh-sach-nha-hang',
		mobile: '/m',
	},
	utilitiesGymDetail: {
		desktop: '/tien-ich/the-thao/gym/:id',
		mobile: '/m/tien-ich/the-thao/gym/:id',
	},
	utilitiesGym: {
		desktop: '/tien-ich/the-thao/gym',
		mobile: '/m',
	},
	utilitiesSport: {
		desktop: '/tien-ich/the-thao',
		mobile: '/m/tien-ich/the-thao',
	},
	utilitiesPool: {
		desktop: '/tien-ich/the-thao/be-boi',
		mobile: '/m',
	},
	utilitiesPoolDetail: {
		desktop: '/tien-ich/the-thao/be-boi/:id',
		mobile: '/m/tien-ich/the-thao/be-boi/:id',
	},
	utilitiesSoccer: {
		desktop: '/tien-ich/the-thao/san-bong',
		mobile: '/m',
	},
	utilitiesSoccerDetail: {
		desktop: '/tien-ich/the-thao/san-bong/:id',
		mobile: '/m/tien-ich/the-thao/bong-da/:id',
	},
	utilitiesTennisDetail: {
		desktop: '/tien-ich/the-thao/tennis/:id',
		mobile: '/m/tien-ich/the-thao/tennis/:id',
	},
	utilitiesTennis: {
		desktop: '/tien-ich/the-thao/tennis',
		mobile: '/m',
	},
	utilitiesBeauty: {
		desktop: '/tien-ich/tien-ich-lam-dep',
		mobile: '/m/tien-ich/tien-ich-lam-dep',
	},
	utilitiesBeautyList: {
		desktop: '/tien-ich/lam-dep',
		mobile: '/m',
	},
	utilitiesBeautyDetail: {
		desktop: '/tien-ich/lam-dep/:id',
		mobile: '/m/tien-ich/lam-dep/:id',
	},
	utilitiesSportList: {
		desktop: '/',
		mobile: '/m/tien-ich/the-thao/danh-sach',
	},
	repairingHousehold: {
		desktop: '/sua-chua-lap-dat',
		mobile: '/m/sua-chua-lap-dat',
	},
	repairingHouseholdDetail: {
		desktop: '/sua-chua-lap-dat/:id',
		mobile: '/m/sua-chua-lap-dat/:id',
	},
	utilitiesPlus: {
		desktop: '/',
		mobile: '/m/tien-ich-plus',
	},
	paymentElectric: {
		desktop: '/thanh-toan/tien-dien',
		mobile: '/m/thanh-toan/tien-dien',
	},
	paymentElectricDetail: {
		desktop: '/',
		mobile: '/m/thanh-toan/tien-dien/chi-tiet/:electricId',
	},
	paymentWater: {
		desktop: '/thanh-toan/tien-nuoc',
		mobile: '/m/thanh-toan/tien-nuoc',
	},
	paymentInternet: {
		desktop: '/thanh-toan/internet',
		mobile: '/m/thanh-toan/internet',
	},
	paymentOther: {
		desktop: '/thanh-toan/dich-vu-khac',
		mobile: '/m/thanh-toan/dich-vu-khac',
	},
	paymentResult: {
		desktop: '/thanh-toan/ket-qua',
		mobile: '/m/thanh-toan/ket-qua',
	},
	paumentElectricGuide: {
		desktop: '/',
		mobile: '/m/thanh-toan/tien-dien/huong-dan-thanh-toan',
	},
	paymentWaterDetail: {
		desktop: '/',
		mobile: '/m/thanh-toan/tien-nuoc/chi-tiet/:waterId',
	},
	paymentWaterGuide: {
		desktop: '/',
		mobile: '/m/thanh-toan/tien-nuoc/huong-dan-thanh-toan',
	},
	paymentOtherDetail: {
		desktop: '/',
		mobile: '/m/thanh-toan/dich-vu-khac/chi-tiet/:otherId',
	},
	paymentOtherGuide: {
		desktop: '/',
		mobile: '/m/thanh-toan/dich-vu-khac/huong-dan-thanh-toan',
	},
	managerInformation: {
		desktop: '/thong-tin-bql',
		mobile: '/m/thong-tin-bql',
	},
	managerMemberList: {
		desktop: '/thanh-vien-bql',
		mobile: '/m',
	},
	managerMemberDetail: {
		desktop: '/thanh-vien-bql/chi-tiet/:agentId',
		mobile: '/m/thanh-vien-bql/:agentId',
	},
	legalDocumentList: {
		desktop: '/van-ban-phap-ly',
		mobile: '/m',
	},
	legalDocumentDetail: {
		desktop: '/van-ban-phap-ly/:legalId',
		mobile: '/m/van-ban-phap-ly/:legalId',
	},
	procedureCategory: {
		desktop: '/danh-muc/thu-tuc-bql',
		mobile: '/m',
	},
	procedureList: {
		desktop: '/thu-tuc-bql/:categoryId',
		mobile: '/m/thu-tuc-bql/:categoryId',
	},
	procedureDetail: {
		desktop: '/thu-tuc-bql/chi-tiet/:procedureId',
		mobile: '/m/thu-tuc-bql/van-ban/:procedureId',
	},
	securityCamera: {
		desktop: '/camera-an-ninh',
		mobile: '/m',
	},
	managerInformationWithTypeId: {
		desktop: '/',
		mobile: '/m/thong-tin-bql/:typeId',
	},
	residentMarket: {
		desktop: '/rao-vat/cho-cu-dan',
		mobile: '/m',
	},
	residentMarketDetail: {
		desktop: '/rao-vat/cho-cu-dan/chi-tiet/:residentMarketId',
		mobile: '/m/cho-cu-dan/chi-tiet/:residentMarketId',
	},
	residentMarketAddNew: {
		desktop: '/rao-vat/cho-cu-dan/dang-tin',
		mobile: '/m/cho-cu-dan/them-san-pham',
	},
	hourlyMaid: {
		desktop: '/rao-vat/giup-viec-theo-gio',
		mobile: '/m',
	},
	buyAndRentHouses: {
		desktop: '/rao-vat/mua-ban-nha',
		mobile: '/m',
	},
	addBuyAndRentHouses: {
		desktop: '/rao-vat/mua-ban-nha/dang-tin',
		mobile: '/m/mua-ban-nha/dang-tin',
	},
	addBuyAndRentHousesWithLandId: {
		desktop: '/rao-vat/mua-ban-nha/dang-tin/:landId',
		mobile: '/m/mua-ban-nha/dang-tin/:landId',
	},
	buyAndRentHousesDetail: {
		desktop: '/rao-vat/mua-ban-nha/:landId',
		mobile: '/m/mua-ban-nha/:landId',
	},
	advertising: {
		desktop: '/',
		mobile: '/m/rao-vat',
	},
	advertisingWithTypeId: {
		desktop: '/',
		mobile: '/m/rao-vat/:typeId',
	},
	advertisingDetail: {
		desktop: '/',
		mobile: '/m/rao-vat/:typeId/:idPost',
	},
	residentMarketEdit: {
		desktop: '/',
		mobile: '/m/cho-cu-dan/sua-san-pham/:residentMarketId',
	},
	hourlyMaidEdit: {
		desktop: '/',
		mobile: '/m/giup-viec-theo-gio/sua-tin/:assistId',
	},
	incidentArea: {
		desktop: '/su-co-trong-khu',
		mobile: '/m/an-toan/su-co-trong-khu',
	},
	incidentAreaDetail: {
		desktop: '/su-co-trong-khu/chi-tiet/:incedentid',
		mobile: '/m/an-toan/su-co-trong-khu/:idIncident',
	},
	neightborAddFriend: {
		desktop: '/lang-gieng-gan/ket-ban',
		mobile: '/m',
	},
	neightborAddFriendDetail: {
		desktop: '/lang-gieng-gan/ket-ban/:friendId',
		mobile: '/m',
	},
	helpingHand: {
		desktop: '/lang-gieng-gan/giup-mot-tay',
		mobile: '/m/lang-gieng-gan/giup-mot-tay',
	},
	helpingHandDetail: {
		desktop: '/lang-gieng-gan/giup-mot-tay/chi-tiet/:helpId',
		mobile: '/m/lang-gieng-gan/giup-mot-tay/chi-tiet/:helpId',
	},
	helpingHandAddNew: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/giup-mot-tay/gui-yeu-cau',
	},
	helpingHandEdit: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/giup-mot-tay/chinh-sua-yeu-cau/:helpId',
	},
	joinHands: {
		desktop: '/lang-gieng-gan/chung-tay',
		mobile: '/m/lang-gieng-gan/chung-tay',
	},
	joinHandsAddNew: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/chung-tay/tao-su-kien',
	},
	joinHandsDetail: {
		desktop: '/lang-gieng-gan/chung-tay/:topicId',
		mobile: '/m/lang-gieng-gan/chung-tay/chi-tiet/:topicId',
	},
	joinHandsEdit: {
		desktop: '/lang-gieng-gan/chung-tay/cap-nhat/:topicId',
		mobile: '/m/lang-gieng-gan/chung-tay/chinh-sua-su-kien/:topicId',
	},
	club: {
		desktop: '/lang-gieng-gan/cau-lac-bo',
		mobile: '/m',
	},
	golfClub: {
		desktop: '/lang-gieng-gan/cau-lac-bo/golf',
		mobile: '/m',
	},
	golfClubAddNew: {
		desktop: '/lang-gieng-gan/cau-lac-bo/golf/dang-tin',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/golf/them-bai-viet',
	},
	golfClubEdit: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/golf/chinh-sua-bai-viet/:topicId',
	},
	tennisClub: {
		desktop: '/lang-gieng-gan/cau-lac-bo/tennis',
		mobile: '/m',
	},
	tennisClubEdit: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/tennis/chinh-sua-bai-viet/:topicId',
	},
	tennisClubAddNew: {
		desktop: '/lang-gieng-gan/cau-lac-bo/tennis/dang-tin',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/tennis/them-bai-viet',
	},
	pingPongClub: {
		desktop: '/lang-gieng-gan/cau-lac-bo/bong-ban',
		mobile: '/m',
	},
	pingPongClubEdit: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/bong-ban/chinh-sua-bai-viet/:topicId',
	},
	pingPongClubAddNew: {
		desktop: '/lang-gieng-gan/cau-lac-bo/bong-ban/dang-tin',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/bong-ban/them-bai-viet',
	},
	badMintonClub: {
		desktop: '/lang-gieng-gan/cau-lac-bo/cau-long',
		mobile: '/m',
	},
	badMintonClubEdit: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/cau-long/chinh-sua-bai-viet/:topicId',
	},
	badMintonClubAddNew: {
		desktop: '/lang-gieng-gan/cau-lac-bo/cau-long/dang-tin',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/cau-long/them-bai-viet',
	},
	clubWithTypeId: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/:typeId',
	},
	clubAddFriendWithFriendId: {
		desktop: '/',
		mobile: '/m/lang-gieng-gan/cau-lac-bo/ket-ban/:friendId',
	},
	projectInformation: {
		desktop: '/thong-tin-ve-du-an',
		mobile: '/m/thong-tin-ve-du-an',
	},
	lifeDetail: {
		desktop: '/cuoc-song/:newId',
		mobile: '/m/cuoc-song/:newId',
	},
	sendFeedBack: {
		desktop: '/',
		mobile: '/m/gui-y-kien',
	},
	setting: {
		desktop: '/',
		mobile: '/m/cai-dat',
	},
	lendingEquipment: {
		desktop: '/cho-thue-muon-do',
		mobile: '/m/cho-thue-muon-do',
	},
	lendingEquipmentDetail: {
		desktop: '/cho-thue-muon-do/:equipmentId',
		mobile: '/m/cho-thue-muon-do/:equipmentId',
	},
	lendingEquipmentPolicy: {
		desktop: '/',
		mobile: '/m/chinh-sach-cho-thue-muon-do',
	},
	registerLendingEquipment: {
		desktop: '/',
		mobile: '/m/dang-ky-thue-do/:equipmentId',
	},
	privacyPolicy: {
		desktop: '/privacy-policy',
		mobile: '/m',
	},
	notFound: {
		desktop: '/not-found',
		mobile: '/m/not-found',
	},
	crashError: {
		desktop: '/crash-error',
		mobile: '/m/crash-error',
	},
}