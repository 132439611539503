import React, { Component } from 'react';
import GoogleMap from '../../gmap/googlemap.view';
import { connect } from 'react-redux';
import SportListSlider from './childs/list-slider.view';
import FakeListSport from '../../../mock-data/sport-index';
import UtilitiesListOther from '../child-component/utilities-list-other/utilities-list-other.view';
import * as UtilitiesStore from '../../../redux/stores/utillities/utilitis-shop.store';
import { bindActionCreators } from 'redux';
import { useEffect } from 'react';
import * as PageUrls from './../../PageUrls';
/**
 * @param {{
 * GetListSportNew:Function,
 * listData:{
 * logoUrl: string;
 name: string;
 address: string;
 id: number;
 type: string;
 * }[],
 * }} [props]
*/
function UtilitiesSportDesktopView(props) {
    useEffect(() => {
        props.GetListSportNew()
    }, [])
    return (
        <div className="utilities-desktop">
            <h2 className="title-lg">Thể thao</h2>
            <div className="utilities-map">
                <GoogleMap />
            </div>

            <SportListSlider listData={props.listData} />

            <UtilitiesListOther currentTypeUrl={PageUrls.UtilitiesSportHomeDesktop} />
        </div>
    )
}

const mapStateToProps = state => ({
    listData: state.utilitiShop.sportList.listShop,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetListSportNew: UtilitiesStore.GetListSportNew
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesSportDesktopView)