import React, { Component } from 'react';
import './setup-mobile.scss';
import { Link } from 'react-router-dom';

class SetupMobileView extends Component {
    render() {
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Cài đặt</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="setup-mobile p-3">
                    <div className="row no-gutters align-items-center">
                        <div className="col-1">
                            <a href="https://bql.ilang.vn/" target="_blank">
                                <img src={require('assets/images/cooperative.svg')} alt="cooperative" className="img-fluid img-cooperative" />
                            </a>
                        </div>
                        <div className="col-10 pl-3">
                            <h5>
                                <a href="https://bql.ilang.vn/" target="_blank">
                                    BQL
                                </a>
                            </h5>
                        </div>
                        <div className="col-1 text-right">
                            <a href="https://bql.ilang.vn/" target="_blank">
                                <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-fluid img-keyboard-arrow-right" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetupMobileView;