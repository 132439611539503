//@ts-check
import { Map } from 'immutable';
// @ts-ignore
import { createSaveLoading } from 'core/app.store';
// @ts-ignore
import Service from 'services/api.service';
// @ts-ignore
import { createFormData, AppKey, ConvertNumberToString, ERRORTYPEEMPTYDATAERROR } from 'utils/configuration';
// @ts-ignore
import { ApiUrl } from 'services/api-url';
import { ElectricPaymentGuideKeyword, GetContentByKeyword } from '../../get-content-by-keyword/getContentByKeyword.store';
// @ts-ignore
import { JwtHelper } from 'utils/jwt-helper';

const userInfor = JwtHelper.currentUser();
const service = new Service();
const INITBILLTHISMONTH = 'ELECTRICBILL/INITBILLTHISMONTH';
const INITPAYMENTHISTORY = 'ELECTRICBILL/PAYMENTHISTORY';
const INITBILLDETAIL = 'ELECTRICBILL/BILLDETAIL';
const INITPAYMENTGUIDE = 'ELECTRICBILL/GUIDEPAYMENT';

export const InitForm = data => ({
    type: INITBILLTHISMONTH, data: data
})

export const InitHistory = data => ({
    type: INITPAYMENTHISTORY, data: data
})

export const InitDetail = data => ({
    type: INITBILLDETAIL, data: data
})

export const InitGuide = data => ({
    type: INITPAYMENTGUIDE, data: data
})

export const ResetHistoryList = () => {
    return dispatch => {
        dispatch(InitHistory([]))
    }
}

export const GetPaymentGuide = () => {
    return GetContentByKeyword(ElectricPaymentGuideKeyword, InitGuide)
}

export const GetElectricBillThisMonth = (apartmentId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = null;
        let formData = new FormData();
        formData.append('apartmentId', apartmentId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.ElectricBillThisMonth, payLoad).then(res => {
            if (res.errorType === ERRORTYPEEMPTYDATAERROR) return false
            rawData = res.content;
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitForm(ExchangeDataBillThisMonth(rawData)))
    }
}

export const GetElectricList = (pageIndex = 1, size = 10, filterObjs = null, apartmentId) => {
    return async dispatch => {
        dispatch(createSaveLoading(true));
        let formData = new FormData();
        formData.append('pageIndex', String(pageIndex));
        formData.append('size', String(size));
        formData.append('apartmentId', apartmentId);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.ElectricBillByTime, payLoad).then(res => {
            dispatch(InitHistory(ExchangeDataBillList(res.content.items)))
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetListElectricDetail = (electricId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let rawData = null;
        let formData = new FormData();
        formData.append('electricId', electricId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.ElectricDetailById, payLoad).then(res => {
            if (res.errorType === ERRORTYPEEMPTYDATAERROR) return false
            rawData = res.content;
        }).catch(err => {
            throw (err);
        })
        dispatch(InitDetail(ExchangeDataBillThisMonth(rawData)))
    }
}

function ExchangeDataBillThisMonth(rawData) {
    if (rawData != null) return ({
        id: rawData.electricId,
        haveData: rawData.total > 0 ? true : false,
        userName: userInfor.customerName,
        houseNumber: rawData.houseNumber,
        monthly: rawData.monthly,
        yearly: rawData.yearly,
        newIndex: ConvertNumberToString(rawData.newIndex),
        oldIndex: ConvertNumberToString(rawData.oldIndex),
        usedIndex: ConvertNumberToString(rawData.newIndex - rawData.oldIndex),
        unitPrice: ConvertNumberToString(rawData.unnitPrice),
        intoMoney: ConvertNumberToString(rawData.intoMoney),
        tax: rawData.vatPercent,
        taxMoney: ConvertNumberToString(rawData.tax),
        total: ConvertNumberToString(rawData.total),
        datePay: rawData.datePay,
    }); else return {
        haveData: false
    }
}

function ExchangeDataBillList(rawData) {
    const standardData = [];

    if (rawData) {
        rawData.map((data) => {
            standardData.push({
                monthly: data.monthly,
                yearly: data.yearly,
                datePay: data.datePay,
                total: ConvertNumberToString(data.total),
                billId: data.electricId
            })
        })
    }
    return standardData
}

const initialState = Map({
    billThisMonth: {},
    paymentHistory: {},
    billDetail: {},
    paymentGuide: ""
}).toJS()

export default function ElectricBillReducer(state = initialState, action) {
    switch (action.type) {
        case INITBILLTHISMONTH:
            return {
                ...state,
                billThisMonth: action.data
            }
        case INITPAYMENTHISTORY: {
            return {
                ...state,
                paymentHistory: action.data
            }
        }
        case INITBILLDETAIL:
            return {
                ...state,
                billDetail: action.data
            }
        case INITPAYMENTGUIDE:
            return {
                ...state,
                paymentGuide: action.data
            }
        default:
            return state
    }
}