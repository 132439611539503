import { Map } from "immutable";
import { createFormData, AppKey, NewGuid } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";
import { createSaveLoading } from "core/app.store";
import { JoinsHandList } from "mock-data/join-hand.mock";
let joinsHandListTemp = JoinsHandList;

const service = new Service();
/** Action Types */
const JOINSHANDSAVELIST = "JOINSHAND/SAVELIST";

/** Actions */
export const createJoinsHandList = (joinsHandListModel) => ({
  type: JOINSHANDSAVELIST,
  payload: {
    joinsHandList: joinsHandListModel.joinsHandList,
    totalItems: joinsHandListModel.totalItems,
  },
});

/** Action creators */
export const GetJoinsHandList = (pageIndex, size, customerId) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    // formData.append('customerId', customerId);

    let filterObjDatas = { orderBy: "create_date", orderType: "desc" };
    let payLoad = createFormData(
      filterObjDatas,
      AppKey.GetPayloadPrefix,
      formData
    );
    return service
      .post(ApiUrl.JoinsHandGetList, payLoad)
      .then((res) => {
        dispatch(
          createJoinsHandList({
            joinsHandList: (res.content && res.content.items) || [],
            totalItems: (res.content && res.content.totalItemCount) || 0,
          })
        );
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetJoinsHandDetail = (topicId) => {
  return (dispatch) => {
    let payLoad = createFormData({ topicId: topicId });
    dispatch(createSaveLoading(true));
    return service
      .post(ApiUrl.JoinsHandGetDetail, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        //TODO: do something
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

export const DeleteJoinsHand = (topicId) => {
  return (dispatch) => {
    let payLoad = createFormData({ topicId: topicId });
    dispatch(createSaveLoading(true));
    return service
      .post(ApiUrl.JoinsHandDelete, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        //TODO: do something
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

export const CreateOrUpdateJoinsHand = (joinsHandModel) => {
  return (dispatch) => {
    let formData = new FormData();
    if (!joinsHandModel.options.length) {
      formData.append("data[options]", []);
    }
    const { topic_image, ...model } = joinsHandModel;
    formData.append("topic_image", joinsHandModel.topic_image);

    let payLoad = createFormData(model, AppKey.PostPayloadPrefix, formData);
    dispatch(createSaveLoading(true));
    return service
      .post(ApiUrl.JoinsHandCreateOrUpdate, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

export const CreateOrUpdateVotedEvent = (votedEventModel) => {
  return (dispatch) => {
    let payLoad = createFormData(votedEventModel, AppKey.PostPayloadPrefix);
    dispatch(createSaveLoading(true));
    return service
      .post(ApiUrl.JoinsHandUpdateVote, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        //TODO: do something
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

export const DeleteVotedEvent = (votedEventModel) => {
  return (dispatch) => {
    let payLoad = createFormData(votedEventModel);
    dispatch(createSaveLoading(true));
    return service
      .post(ApiUrl.JoinsHandDeleteVote, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        //TODO: do something
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

// Initial state
export const initialState = Map({
  joinsHandList: [],
  totalItems: 0,
}).toJS();

/** Reducers */
export default function JoinsHandReducer(state = initialState, action) {
  switch (action.type) {
    case JOINSHANDSAVELIST:
      return {
        ...state,
        joinsHandList: [...action.payload.joinsHandList],
        totalItems: action.payload.totalItems,
      };
    default:
      return state;
  }
}
