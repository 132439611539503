import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as helpingHandActions from 'redux/stores/help/helping-hand.store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import { Modal, Button } from 'react-bootstrap';
import EditHelpingHandDesktopView from '../helping-hand-actions/edit-helping-hand-desktop.view';
import RenderHelpingHandContent from './helping-hand-content.view';
import { JwtHelper } from "utils/jwt-helper";

class HelpingHandItemDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenEditPopup: false,
            isOpenDeletePopup: false,
            selectedHelpId: null,
            currentUser: JwtHelper.currentUser(),
        }
    }

    UNSAFE_componentWillMount() {

    }

    onToggleEditPopup = (isOpen, helpId) => {
        this.setState({
            isOpenEditPopup: isOpen,
            selectedHelpId: helpId
        })
    }

    onToggleDeletePopup = (isOpen, helpId) => {
        this.setState({
            isOpenDeletePopup: isOpen,
            selectedHelpId: helpId
        })
    }

    onDeleteHelpingHandPost = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteHelpingHand(this.state.selectedHelpId).then(res => {
                this.onToggleDeletePopup(false);
                AddNotification('Xoá bài viết thành công!', NotificationMessageType.Success);
                this.props.callBack && this.props.callBack();
            }, err => {
                this.onToggleDeletePopup(false);
            })
        }
    }

    render() {
        const { helpingHandList, loggedIn } = this.props;
        const { isOpenDeletePopup, isOpenEditPopup, currentUser, selectedHelpId } = this.state;
        return (
            <div className="helping-hand-container">
                {helpingHandList.map(item => <div key={item.helpId} className="item">
                    <div className="row no-gutters">
                        <RenderHelpingHandContent data={item} loggedIn={loggedIn} currentUserId={currentUser && currentUser.customerId} onToggleDeletePopup={this.onToggleDeletePopup.bind(this)} onToggleEditPopup={this.onToggleEditPopup.bind(this)} callBack={this.props.callBack} />
                    </div>
                </div>)}
                <Modal show={isOpenEditPopup} className="modal-edit" centered onHide={() => this.onToggleEditPopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chỉnh sửa yêu cầu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditHelpingHandDesktopView helpId={selectedHelpId} onToggleEditPopup={this.onToggleEditPopup.bind(this)} callBack={this.props.callBack} />
                    </Modal.Body>
                </Modal>

                <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xóa yêu cầu?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Yêu cầu sẽ không thể khôi phục sau khi xóa.
                        <br />
                        Bạn có chắc muốn xóa yêu cầu này.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                        <Button variant="success" onClick={() => this.onDeleteHelpingHandPost(true)}>Xóa</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteHelpingHand: helpingHandActions.DeleteHelpingHand,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HelpingHandItemDesktopView)