//@ts-check
import React from 'react';
import Gallery from 'react-grid-gallery';
import * as CommonClass from 'utils/css-common-class';

/**
 * @param {{listImage:{
            src: string,
            thumbnail: string,
            thumbnailWidth: number,
            thumbnailHeight: number,
        }[]}} [props]
*/
function FooterDetailView(props) {
    const listImage = props.listImage;
    listImage.map(imageObject => {
        imageObject.thumbnailHeight = 128;
        imageObject.thumbnailWidth = 128;
    })
    if (props.listImage.length===0) return null
    return (<div className="p-3" hidden={props.listImage.length === 0}>
        <h3 className={`title-sm mt-4 ${CommonClass.TextSizeSubTitle}`}>Hình ảnh</h3>
        <Gallery images={props.listImage} backdropClosesModal={true} />
        <div>&nbsp;</div>
    </div>)
}

export default FooterDetailView