import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            if (this.state.error) localStorage.setItem("error-log-1", this.state.error.toString())
            localStorage.setItem("error-log-2", this.state.errorInfo.componentStack)
            // if (isMobile) return window.location='/m/crash-error'; else return window.location='/crash-error';
        }
        // Normally, just render children
        return this.props.children;
    }
}