//@ts-check
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './list-slider.scss';
import { Link } from 'react-router-dom';
import ItemOfSliderView from './item-in-slide.view';

/**
 * @param {{
 * listData:{
    logoUrl: string;
    name: string;
    address: string;
    id: number;
}[],
 * detailUrl:string,
 viewMoreUrl:string,
 * }} [props] - init props
*/

function SliderShopHomePageViewDesktop(props) {
    const settingsSlide = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: '24px'
    };
    const listData = props.listData;
    return (
        <div className="mt-4 slider-container">
            <div className="row align-items-center">
                <div className="col-6">
                    <h2 className="title-lg">Khám phá</h2>
                </div>
                <div className="col-6 text-right">
                    <Link to={props.viewMoreUrl} className="read-more">Xem thêm</Link>
                </div>
                <div className="col-12">
                    {(listData && (listData.length > 0)) ? <Slider {...settingsSlide}>
                        {
                            listData.map((data, index) => <ItemOfSliderView key={index} data={data} detailUrl={props.detailUrl} />)
                        }
                    </Slider> : <div>Không có dữ liệu để hiển thị</div>}
                </div>
            </div>
        </div>
    )
}

export default SliderShopHomePageViewDesktop