//@ts-check
import React from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import GmapDirect from '../../../../gmap/link-direct';
import ReactTooltip from "react-tooltip";
import './header-detail.scss';
import { SetDefaultErrorImage } from "utils/configuration";
/**
 * @param {{data:{name:string,address:string,id:number,timeOpen:string,faceBook:string,phone:string,lat:number,lng:number,introduce:string,imagesSlider:{url:string}[]},
 * comeBackLink:string,}} [props]
*/
function HeaderDetailView(props) {
    const settingsSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }
    return (
        <div className="header-container-detail">
            <div className="position-relative slide-container">
                <Link to={props.comeBackLink}><img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-arrow-back" /></Link>
            </div>

            <Slider {...settingsSlider}>
                {
                    props.data.imagesSlider.map((imageSlider, index) => <div key={index}>
                        <img className="img-fluid w-100" src={imageSlider.url} onError={SetDefaultErrorImage} />
                    </div>)
                }
                {
                    (props.data.imagesSlider.length === 0) && <div>
                        <img className="img-fluid w-100" src="" onError={SetDefaultErrorImage} />
                    </div>
                }
            </Slider>

            <div className="p-3">
                <h2>{props.data.name}</h2>
                <div className="row no-gutters">
                    <div className="col-12 mb-3">
                        <div className="address">
                            <img src={require('assets/images/place-red.svg')} alt="Place" className="img-fluid" /> <span className="text-gray">{props.data.address}</span>
                        </div>
                        <div className="open-time text-gray">
                            <img src={require('assets/images/clock-yellow.svg')} alt="Clock Yellow" className="img-fluid" /> Mở cửa: <span>{props.data.timeOpen}</span>
                        </div>
                    </div>

                    <div className="col-4 text-center">
                        <div className="social-icon">
                            <a href={props.data.faceBook} target="_blank"><img src={require('assets/images/facebook.svg')} alt="Place" className="img-fluid" /></a>
                            <br></br>
                            <a href={props.data.faceBook} target="_blank"><span>Facebook</span></a>
                        </div>
                    </div>
                    <div className="col-4 text-center">
                        <div className="social-icon">
                            <a href={`tel:${props.data.phone}`}>
                                <img src={require('assets/images/phone-call-round.svg')} alt="Place" className="img-fluid" />
                            </a>
                            <br></br>
                            <a href={`tel:${props.data.phone}`}><span>Liên hệ</span></a>
                            <ReactTooltip place="bottom" type="dark" effect="float" />
                        </div>
                    </div>
                    <div className="col-4 text-center">
                        <div className="social-icon">
                            <GmapDirect daddr_lat={props.data.lat} daddr_lng={props.data.lng} content={<img src={require('assets/images/right-turn.svg')} alt="Place" className="img-fluid" />} />
                            <br></br>
                            <GmapDirect daddr_lat={props.data.lat} daddr_lng={props.data.lng} content={<span>Chỉ đường</span>} />
                        </div>
                    </div>
                </div>
                <hr />

                <h3>Giới thiệu</h3>
                <div className="utilities-intro-mobile content-shop" dangerouslySetInnerHTML={{ __html: props.data.introduce }}>

                </div>
            </div>
        </div>
    )
}

export default HeaderDetailView