import '../../neighbourhoods-desktop.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as friendActions from '/redux/stores/friend/friend.store';
import AddFriendActionView from '../add-friend-action';
import AddNotification from 'components/react-notifications/react-notifications';
import * as viVN from 'translation/vi-VN.json';
import { history } from 'redux/store';
import { NotificationMessageType, SetDefaultErrorImage } from '/utils/configuration';
import { JwtHelper } from '/utils/jwt-helper';

class AddFriendDetailDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      friend: null,
      friendId: this.props.match.params.friendId,
      currentUser: JwtHelper.currentUser()
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetFriendDetail();
  }

  onGetFriendDetail = () => {
    this.props.getFriendDetail(this.state.currentUser.customerId, this.state.friendId).then(res => {
      if (res.content) {
        this.setState({
          friend: res.content
        })
      } else {
        AddNotification(viVN.Errors[res.errorType], NotificationMessageType.Error);
        history.push('/lang-gieng-gan/ket-ban');
      }
    }, err => { 
    })
  }

  render() {
    const { friend } = this.state;
    return (
      <div className="add-friend-detail-desktop">
        {
          friend && <div className="friend-detail-wrapper"> <div className="row">
            <div className="col-12">
              <img src={friend.coverUrl || require('assets/images/splendora-cover.png')} onError={SetDefaultErrorImage} alt={friend.customerName} className="w-100 cover-image" />
            </div>
          </div>

            <div className="bg-white p-4 rounded-bottom">
              <div className="row">
                <div className="col-3">
                  <img src={friend.avatarUrl || require('assets/images/29-01.png') } onError={SetDefaultErrorImage} className="avatar img-fluid rounded-circle" />
                </div>
                <div className="col-6">
                  <div className="detail">
                    <h5 className="mb-0">{friend.customerName}</h5>
                    <span>Căn hộ: {friend.apartmentName}</span>
                    <p>
                      Quê quán: {friend.provine}<br />
                      Nghề nghiệp: {friend.career}<br />
                      Sở thích: {friend.hobby}<br />
                    </p>
                  </div>
                </div>
                <div className="col-3 text-right">
                  <AddFriendActionView cssClass={'btn btn-primary'} friendId={friend.customerId} status={friend.status} callBack={this.onGetFriendDetail.bind(this)}/>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFriendDetail: friendActions.GetFriendDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFriendDetailDesktopView);
