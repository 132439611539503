/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "core/app.store";
import * as clubActions from "redux/stores/club/club.store";

import { Error } from "components/support-create-form/validate-style";
import {
  NewGuid,
  ValidImageTypes,
  validateFile,
  CheckMaxFile,
  NotificationMessageType,
  convertTextareaToHtml,
  ImageExtensions,
} from "utils/configuration";
import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";

import "../../club.scss";

function CreateBadMinionForm(props) {
  const { user, loggingIn } = props;
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [previewAttachments, setPreviewAttachment] = useState([]);
  const [adFiles, setAdFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const onSubmit = (data) => {

    let submittedData = {
      category_id: clubActions.clubType.CAU_LONG,
      content: convertTextareaToHtml(data.content),
    };

    let formData = new FormData();
    adFiles &&
      adFiles.length > 0 &&
      adFiles.map((file) => formData.append("club_file[]", file));

    props.showLoading(true);
    props.addOrUpdatePost(submittedData, formData).then(
      (res) => {
        props.showLoading(false);
        history.push("/lang-gieng-gan/cau-lac-bo/cau-long");
      },
      (err) => {
        props.showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const onFileChange = (event) => {
    let files = Array.from(event.target.files);
    if (files && files.length) {
      // validate maximum length
      var validateMsg = CheckMaxFile(adFiles.length + files.length);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      // validate file
      files.map((file) => {
        if (validateFile(file, ImageExtensions)) {
          validateMsg = validateFile(file, ImageExtensions);
        }
      });
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage("");

      var previewImgs = [];
      var imgFiles = [];
      files.map((file) => {
        var reader = new FileReader();
        reader.onload = (e) => {
          previewImgs = [
            ...previewImgs,
            {
              fileUrl: e.target.result,
              fileName: file.name,
              fileId: NewGuid(),
              fileType: ValidImageTypes.includes(file.type)
                ? "image"
                : file.type,
            },
          ];
          setPreviewAttachment([...previewAttachments, ...previewImgs]);
        };
        reader.readAsDataURL(file);
        imgFiles = [...imgFiles, file];
        setAdFiles([...adFiles, ...imgFiles]);
      });
    }
  };

  const onRemovedAttachment = (attachmentId) => {
    setPreviewAttachment(
      previewAttachments.filter((att) => att.fileId != attachmentId)
    );
  };
  return (
    <div className="club-desktop">
      <h2 className="title-lg">Tạo bài viết mới</h2>
      <form className="form-create-post-club" onSubmit={handleSubmit(onSubmit)}>
        <textarea
          name="content"
          className="form-control"
          placeholder="Nội dung"
          disabled={!loggingIn}
          rows="5"
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Nội dung phải có ít nhất 6 ký tự",
            },
          })}
        ></textarea>
        {errors.content && <Error content={errors.content.message} />}
        <div className="row">
          <div className="col-12 preview-img">
            {previewAttachments.map((att) => (
              <div key={att.fileId} className="attachment-wrapper">
                <img
                  src={att.fileUrl}
                  alt={att.fileName}
                  className="img-attachment img-fluid"
                />
                <div className="overlay"></div>
                <img
                  onClick={() => onRemovedAttachment(att.fileId)}
                  src={require("assets/images/close-white.svg")}
                  alt="close-white"
                  className="img-close-white"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-6 file-upload">
            <div className="title-upload">
              <img
                src={require("assets/images/collections.png")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <span>Upload ảnh</span>
            </div>
            <input
              type="file"
              name="ad_file"
              accept=".png, .jpg, .jpeg"
              multiple
              className="media-file"
              onChange={onFileChange.bind(this)}
              disabled={!loggingIn}
            />
            {errors.ad_file && <Error content={errors.ad_file.message} />}
            {validateImgMessage && <Error content={validateImgMessage} />}
          </div>
          <div className="col-6 text-right">
            <button
              type="submit"
              className="btn btn-success w-50"
              disabled={!loggingIn}
            >
              Tạo bài viết
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

class AddBadMinionDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JwtHelper.currentUser(),
    };
  }

  render() {
    return <CreateBadMinionForm {...this.props} {...this.state} />;
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      addOrUpdatePost: clubActions.AddOrUpdatePost,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBadMinionDesktopView);
