import React, { Component } from "react";
import "../notification-desktop/notification-desktop.scss"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotificationManagementDesktopView from './notice-from-management/notice-from-management.view.jsx';
import NoticeFromResidentsDesktopView from './notice-from-residents/notice-from-residents.view.jsx';
import * as NotificationStore from 'redux/stores/notification/notifications.store';
import AuthenticationWarningDesktopView from "components/authentication-warning/authentication-warning.view.jsx";
import Pagination from 'components/pagination/paging.view.jsx';
import { PromiseAllTools } from 'core/app.store';

class NotificationDesktopView extends Component {

    componentDidMount() {
        this.props.isLogin && this.props.PromiseAllTools([this.props.GetAgentNotificationList(), this.props.GetNeightborNotificationList()])
    }

    render() {
        if (!this.props.isLogin) return <AuthenticationWarningDesktopView />
        return (
            <div className="notification-desktop mt-4">
                <h2 className="title-lg bg-white p-4">
                    Thông báo
                </h2>
                <div className="row mt-4">
                    <div className="col-6">
                        {(this.props.agentNotification.haveData) ? <NotificationManagementDesktopView listData={this.props.agentNotification.listData} /> : <p className="text-center">Hiện không có thông báo nào</p>}
                        {(this.props.agentNotification.haveData) && <Pagination totalItems={this.props.agentNotification.total} size={this.props.agentNotification.pageSize} goTo={(i) => this.props.PromiseAllTools([this.props.GetAgentNotificationList(i)])} />}
                    </div>
                    <div className="col-6">
                        {(this.props.neightborNotification.haveData) ? <NoticeFromResidentsDesktopView listData={this.props.neightborNotification.listData} /> : <p className="text-center">Hiện không có thông báo nào</p>}
                        {(this.props.neightborNotification.haveData) && <Pagination totalItems={this.props.neightborNotification.total} size={this.props.neightborNotification.pageSize} goTo={(i) => this.props.PromiseAllTools([this.props.GetNeightborNotificationList(i)])} />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    agentNotification: state.notifications.agentNotification,
    isLogin: state.auth.isLoggedIn,
    neightborNotification: state.notifications.neightborNotification,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetAgentNotificationList: NotificationStore.GetAgentNotificationList,
    GetNeightborNotificationList: NotificationStore.GetNeightborNotificationList,
    PromiseAllTools
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(NotificationDesktopView);
