import "../../neighbourhoods-desktop/neighbourhoods-desktop.scss";

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as helpingHandActions from 'redux/stores/help/helping-hand.store';
import AddHelpingHandDesktopView from './helping-hand-actions/add-helping-hand-desktop.view';
import HelpingHandItemDesktopView from './childs/helping-hand-item.view';
import PaginationControl from 'components/pagination/pagination.view';

class HelpingHandDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
      isToggleAdding: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHelpingHandList();
  }

  onGetHelpingHandList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = null) => {
    this.setState({ isLoading: true });
    this.props.getHelpingHandList(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => { 
        this.setState({ isLoading: false });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetHelpingHandList(pageNumber);
    })
  }

  onToggleAddingState = () => {
    this.setState({
      isToggleAdding: !this.state.isToggleAdding
    })
  }

  render() {
    const { helpingHandList, totalItems } = this.props;
    const { currentPageIndex, isToggleAdding } = this.state;
    return (
      <div className="neighbourhoods-desktop helping-hand-desktop">
        <AddHelpingHandDesktopView onToggleAddingState={this.onToggleAddingState.bind(this)} callBack={this.onGetHelpingHandList.bind(this)} />
        <HelpingHandItemDesktopView helpingHandList={helpingHandList} isToggleAdding={isToggleAdding} callBack={this.onGetHelpingHandList.bind(this)} />
        <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  helpingHandList: state.helpingHand.helpingHandList,
  totalItems: state.helpingHand.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getHelpingHandList: helpingHandActions.GetHelpingHandList,
  deleteHelpingHand: helpingHandActions.DeleteHelpingHand,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HelpingHandDesktopView)