/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment";

import * as appActions from "core/app.store";
import * as shopActions from "redux/stores/shop/shop.store";

import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./booking-mobile.scss";

const StatusType = {
  ALL: "ALL",
  BOOKING: "BOOKING",
  PENDING: "PENDING",
};

function BookingView(props) {
  const {
    showLoading,
    type,
    shopId,
    onGetShopBooking,
    onAddOrUpdateBooking,
  } = props;

  const [booking, setBooking] = useState([]);
  const [pending, setPending] = useState([]);

  const [bookingDate, setBookingDate] = useState(new Date());
  const [pendingDate, setPendingDate] = useState(new Date());

  useEffect(() => {
    onGetList(
      {
        shopId: shopId,
        pageIndex: 1,
        size: 999,
        orderBy: "create_date",
        orderType: "DESC",
      },
      StatusType.ALL
    );
  }, [shopId]);

  const onGetList = (filterObj, statusType = StatusType.ALL) => {
    showLoading(true);
    onGetShopBooking(filterObj, type).then(
      (res) => {
        switch (statusType) {
          case StatusType.ALL:
            setBooking(
              res && res.length > 0
                ? res.filter((item) => {
                    return (
                      item.statusName != "Chờ xác nhận" &&
                      item.statusId != 4290 &&
                      item
                    );
                  })
                : []
            );
            setPending(
              res && res.length > 0
                ? res.filter((item) => {
                    return (
                      (item.statusName == "Chờ xác nhận" ||
                        item.statusId == 4290) &&
                      item
                    );
                  })
                : []
            );
            break;
          case StatusType.BOOKING:
            setBooking(
              res && res.length > 0
                ? res.filter((item) => {
                    return (
                      item.statusName != "Chờ xác nhận" &&
                      item.statusId != 4290 &&
                      item
                    );
                  })
                : []
            );
            break;
          case StatusType.PENDING:
            setPending(
              res && res.length > 0
                ? res.filter((item) => {
                    return (
                      (item.statusName == "Chờ xác nhận" ||
                        item.statusId == 4290) &&
                      item
                    );
                  })
                : []
            );
            break;
          default:
            break;
        }
        showLoading(false);
      },
      (err) => {
        showLoading(false);
      }
    );
  };

  const onConfirm = (data) => {
    if (!data) return;
    showLoading(true);
    onAddOrUpdateBooking(
      {
        cart_id: data.cartId,
        status: "accept",
        shop_id: data.shopId,
        customer_name: data.customerName,
        mobile_number: data.mobileNumber,
        quantity: data.quantity,
        intend_date: data.intendDate,
        intend_time: data.intendTime,
      },
      type
    ).then(
      (res) => {
        AddNotification("Xác nhận thành công", NotificationMessageType.Success);
        onGetList(
          {
            shopId: shopId,
            pageIndex: 1,
            size: 999,
            orderBy: "create_date",
            orderType: "DESC",
          },
          StatusType.ALL
        );
      },
      (err) => {
        showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const onCancel = (data) => {
    if (!data) return;
    showLoading(true);
    onAddOrUpdateBooking(
      {
        cart_id: data.cartId,
        status: "cancel",
        shop_id: data.shopId,
        customer_name: data.customerName,
        mobile_number: data.mobileNumber,
        quantity: data.quantity,
        intend_date: data.intendDate,
        intend_time: data.intendTime,
      },
      type
    ).then(
      (res) => {
        AddNotification("Hủy thành công", NotificationMessageType.Success);
        onGetList(
          {
            shopId: shopId,
            pageIndex: 1,
            size: 999,
            orderBy: "create_date",
            orderType: "DESC",
          },
          StatusType.ALL
        );
      },
      (err) => {
        showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const FormatDateTime = (date) => {
    const listDate = date.split("-");
    return (
      listDate.length == 3 &&
      listDate[2] + "/" + listDate[1] + "/" + listDate[0]
    );
  };

  return (
    <div className="bg-white booking-mobile">
      <Tab.Container defaultActiveKey="nhan-dat-ban">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="nhan-dat-ban">
              <span>Nhận đặt bàn</span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="cho-xu-ly">
              <span>Chờ xử lý</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="nhan-dat-ban">
            <table className="table table-borderless table-striped nhan-dat-ban">
              <thead>
                <tr>
                  <th>Khách hàng</th>
                  <th className="text-center">Số người</th>
                  <th className="text-right">Trạng thái</th>
                </tr>
              </thead>
              <tbody>
                {booking && booking.length > 0 ? (
                  booking.map((item) => (
                    <tr key={item.cartId}>
                      <td>
                        <h5>{item.customerName}</h5>
                        <span>{item.mobileNumber}</span>
                      </td>
                      <td className="text-center align-middle">
                        {item.quantity}
                      </td>
                      <td className="text-right align-middle">
                        {item.statusName}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center align-middle">
                      <span>Không có dữ liệu</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Tab.Pane>
          <Tab.Pane eventKey="cho-xu-ly">
            <table className="table table-borderless table-striped cho-xu-ly p-3">
              <tbody>
                {pending && pending.length > 0 ? (
                  pending.map((item) => (
                    <tr key={item.cartId}>
                      <td>
                        <h5>{item.customerName}</h5>
                        <span>Số điện thoại: {item.mobileNumber}</span>
                        <br />
                        <span>Số người: {item.quantity}</span>
                        <br />
                        <span>
                          Thời gian:{" "}
                          {item.intendTime +
                            " - " +
                            FormatDateTime(item.intendDate)}
                        </span>
                      </td>
                      <td className="text-center align-middle">
                        <button
                          className="btn btn-secondary mr-2"
                          onClick={() => onCancel(item)}
                        >
                          Hết bàn
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() => onConfirm(item)}
                        >
                          Xác nhận
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">
                      <span>Không có dữ liệu</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      onGetShopBooking: shopActions.GetCartList,
      onAddOrUpdateBooking: shopActions.AddOrUpdateBooking,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookingView);
