/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Error } from "components/support-create-form/validate-style";
import AddNotification from "components/react-notifications/react-notifications";
import * as helpingHandActions from "redux/stores/help/helping-hand.store";
import {
  convertTextareaToHtml,
  convertHtmlToTextarea,
  NotificationMessageType,
} from "utils/configuration";
import * as viVN from "translation/vi-VN.json";
import { Form } from "react-bootstrap";
import { JwtHelper } from "utils/jwt-helper";

function UpdateHelpingHandForm(props) {
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [isUrgency, setIsUrgency] = useState(
    props.model.isUrgent && props.model.isUrgent === 1 ? true : false
  );

  const initialValues = {
    content: convertHtmlToTextarea(props.model.content),
    is_urgent:
      props.model.isUrgent && props.model.isUrgent === 1 ? true : false,
  };

  const onSubmit = (data) => {
    props.onUpdateHelpingHand &&
      props.onUpdateHelpingHand({
        content: convertTextareaToHtml(data.content),
        is_urgent: isUrgency ? 1 : 0,
      });
  };

  return (
    <div className="advertising-desktop">
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          defaultValue={initialValues.content}
          name="content"
          placeholder="Nội dung"
          className="form-control"
          rows="5"
          ref={register({
            required: "Trường bắt buộc",
            minLength: {
              value: 6,
              message: "Nội dung phải có ít nhất 6 ký tự",
            },
          })}
        ></textarea>
        {errors.content && <Error content={errors.content.message} />}
        <div className="row mt-3 align-items-center">
          <div className="col-6">
            <Form.Group
              controlId="editUrgencyCheckbox"
              className="mb-0 helping-hand-urgency"
            >
              <Form.Check
                custom
                label="Khẩn cấp"
                type="checkbox"
                name="is_urgent_editing"
                defaultChecked={initialValues.is_urgent}
                checked={isUrgency}
                onChange={() => setIsUrgency(!isUrgency)}
              />
            </Form.Group>
          </div>
          <div className="col-6 text-right">
            <button type="submit" className="btn btn-success text-uppercase">
              Lưu chỉnh sửa
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

class EditHelpingHandDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      helpingHandPost: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHelpingHandPostDetail();
  }

  onGetHelpingHandPostDetail = () => {
    if (this.props.helpId) {
      this.props.getHelpingHandDetail(this.props.helpId).then(
        (res) => {
          this.setState({
            helpingHandPost: res.content,
          });
        },
        (err) => {
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    }
  };

  onUpdateHelpingHand = (data) => {
    let submitedData = {
      ...data,
      help_id: this.props.helpId,
      customer_id: this.state.currentUser.customerId,
      comments: this.state.helpingHandPost.comments || [],
    };
    this.props.createOrUpdateHelpingHand(submitedData).then(
      (res) => {
        AddNotification(
          "Cập nhật bài viết thành công!",
          NotificationMessageType.Success
        );
        this.props.onToggleEditPopup(false);
        this.props.callBack && this.props.callBack();
      },
      (err) => {
        this.props.onToggleEditPopup(false);
      }
    );
  };

  render() {
    const { helpingHandPost } = this.state;

    return (
      <div className="resident-editting">
        {helpingHandPost && (
          <UpdateHelpingHandForm
            onUpdateHelpingHand={this.onUpdateHelpingHand.bind(this)}
            model={helpingHandPost}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateHelpingHand: helpingHandActions.CreateOrUpdateHelpingHand,
      getHelpingHandDetail: helpingHandActions.GetHelpingHandDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditHelpingHandDesktopView);
