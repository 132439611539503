import { Map } from 'immutable';
import { createFormData, AppKey, NewGuid } from '../../../utils/configuration';
import Service from '../../../services/api.service';
import { ApiUrl } from '../../../services/api-url';
import { createSaveLoading } from '../../../core/app.store';
import { AdHouses } from '../../../mock-data/buy-and-rent-house.mock';
let adHousesTemp = AdHouses;

const service = new Service();
/** Action Types */
const ADHOUSESAVELIST = 'ADHOUSE/SAVELIST';
const ADHOUSEGETLIST = 'ADHOUSE/GETLIST';

/** Actions */
export const createSaveAdHouseList = (adHouseModel) => ({ type: ADHOUSESAVELIST, payload: { landList: adHouseModel.landList, totalItems: adHouseModel.totalItems } });
export const createGetAdHouseList = () => ({ type: ADHOUSEGETLIST });


/** Action creators */
export const GetAdHouseList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AdHouseGetList, payLoad).then(res => {
            dispatch(createSaveAdHouseList({ landList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetAdHouseDetail = (landId) => {
    return dispatch => {
        let payLoad = createFormData({ landId: landId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AdHouseGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteAdHouse = (landId) => {
    return dispatch => {
        let payLoad = createFormData({ landId: landId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AdHouseDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateLand = (landModel) => {
    return dispatch => {
        const { land_file, ...model } = landModel;
        const { delete_file, ...newModel } = model;
        let formFileData = createFormData({ 'land_file': land_file });
        let newFormFileData = createFormData({ 'delete_file': delete_file }, null, formFileData);
        let payLoad = createFormData(newModel, AppKey.PostPayloadPrefix, newFormFileData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AdHouseCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */

export const GetAdHouseListFake = (pageIndex, size, filterObjs) => {
    return (dispatch, state) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let adHouseModels = filterObjs ?
                adHousesTemp.filter(item => {
                    return item.category_id == filterObjs['category_id'];
                })
                :
                adHousesTemp;
            let adHouseList = adHouseModels.slice(pageIndex * size - size, pageIndex * size);
            if (adHouseList) {
                dispatch(createSaveAdHouseList({ adHouses: adHouseList, totalItems: adHouseModels.length }))
                resolve({
                    content: {
                        items: adHouseList,
                        totalItems: adHouseModels.length
                    },
                    errorType: "",
                    errorMessage: "",
                });
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                });
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetAdHouseDetailFake = (adHouseId) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let adHouseDetail = adHousesTemp.find(fr => fr.ad_id == adHouseId);
            if (adHouseDetail) {
                resolve({
                    content: adHouseDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const CreateOrUpdateAdHouseFake = (adHouseModel) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                let newModel;
                let currentIndex = adHousesTemp.findIndex(fr => fr.ad_id == adHouseModel.ad_id);
                if (currentIndex >= 0) {
                    adHousesTemp[currentIndex] = adHouseModel;
                    newModel = { ...adHouseModel };
                } else {
                    newModel = { ...adHouseModel, ad_id: NewGuid() };
                    adHousesTemp.push(newModel);
                }
                dispatch(createSaveAdHouseList({ adHouses: adHousesTemp, totalItems: adHousesTemp.length }))
                resolve({
                    content: newModel,
                    errorType: "",
                    errorMessage: "",
                })
            }, 1000)
        })
    }
}

export const DeleteAdHouseFake = (adHouseId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let currentAdhouse = adHousesTemp.find(item => item.ad_id == adHouseId);
            let newAdHouseList = adHousesTemp.filter(item => item.ad_id != adHouseId);
            adHousesTemp = newAdHouseList;
            dispatch(createSaveAdHouseList({ adHouses: adHousesTemp.slice(0, 10), totalItems: adHousesTemp.length }))
            if (currentAdhouse) {
                resolve({
                    content: {
                        adHouseId: adHouseId,
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                reject({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    landList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function AdHouseRentReducer(state = initialState, action) {
    switch (action.type) {
        case ADHOUSESAVELIST:
            return {
                ...state,
                landList: action.payload.landList,
                totalItems: action.payload.totalItems,
            }
        case ADHOUSEGETLIST:
            return state.adHouseList;
        default:
            return state;
    }
}
