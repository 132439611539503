import { NewGuid } from '../utils/configuration';

export const Friends = [
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Nguyễn Vĩnh Ân',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 2,
        homeTownName: 'Hà Nội',
        careerId: 2,
        careerName: 'CNTT',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'),
        coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'), coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'), coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'), coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'), coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
    {
        customer_id: NewGuid(),
        friend_id: NewGuid(),
        make_friend_date: '',
        is_unfriend: true,
        unfriend_date: '',
        name: 'Trần Xuân Hương',
        apartment: 'S2-' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        homeTownId: 1,
        homeTownName: 'Hà Nội',
        careerId: 1,
        careerName: 'Thám tử',
        hobbyId: [1, 2, 3],
        hobbyName: 'Chém gió, Bơi lội, Sexy dance',
        profileUrl: require('assets/images/29-01.png'), coverUrl: require('assets/images/splendora-cover.png'),
        friendStatus: 1,
    },
];

export const CareerModels = [
    {
        id: 1,
        name: 'Thám tử'
    },
    {
        id: 2,
        name: 'CNTT'
    },
    {
        id: 3,
        name: 'Startup vốn tự có'
    },
    {
        id: 4,
        name: 'Phục vụ quý bà'
    },
];

export const HomeTownModels = [
    {
        id: 1,
        name: 'Hà Nội'
    },
    {
        id: 2,
        name: 'Hải Phòng'
    },
    {
        id: 3,
        name: 'Quảng Ninh'
    },
    {
        id: 4,
        name: 'TP HCM'
    },
];

export const HobbyModels = [
    {
        id: 1,
        name: 'Chém gió'
    },
    {
        id: 2,
        name: 'Bơi lội'
    },
    {
        id: 3,
        name: 'Sexy dance'
    },
    {
        id: 4,
        name: 'Múa lửa'
    },
];