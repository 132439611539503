/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as hitchhikeActions from "redux/stores/utilities-plus/hitchhike.store";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { TokenKey } from 'utils/configuration';
import { history } from 'redux/store';
import { JwtHelper } from 'utils/jwt-helper';

import '../../utilities-plus-mobile.scss';

class HitchhikeCreateMobileView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    render() {
        const { isLoggedIn } = this.props;
        const userInfo = JwtHelper.currentUser();
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/di-nho-xe">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Đi nhờ xe</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="p-3">
                    <div className="hitchhike-create-mobile bg-white p-3">
                        <h5>Tạo lộ trình mới</h5>
                        <AddHitchhikeForm
                            addOrUpdateHitchhike={this.props.addOrUpdateHitchhike}
                            isLogin={isLoggedIn}
                            user={userInfo} />
                    </div>
                </div>
            </div>
        );
    }
}

function AddHitchhikeForm(props) {
    const [startDate, setStartDate] = useState(new Date());

    const { register, errors, handleSubmit } = useForm({
        mode: "onChange"
    });

    const onSubmit = data => {
        if (data) {
            let hitchhike = {
                maker_id: props.user.customerId,
                route: data.title,
                departure_date: startDate,
                quantity_allow: data.peopleNumber
            }
            props.addOrUpdateHitchhike(hitchhike).then(
                res => {
                    if (res && res.content) {
                        AddNotification("Tạo lộ trình thành công", NotificationMessageType.Success);
                        history.push('/m/di-nho-xe');
                    }
                },
                err => {}
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group position-relative">
                <input name="title" type="text" placeholder="Nhập lộ trình" className={"form-control " + (errors.title ? "is-invalid" : "")} disabled={!props.isLogin}
                    ref={register({ required: "Trường này là bắt buộc" })} />
                {errors.title && <div className="invalid-feedback">{errors.title.message}</div>}
                <img src={require('assets/images/place-gray.svg')} alt="Place" className="img-fluid img-place" />
            </div>

            <div className="form-group position-relative">
                <DatePicker dateFormat="dd/MM/yyyy" minDate={new Date()} selected={startDate} onChange={data => data && setStartDate(data)} className="form-control" disabled={!props.isLogin} />
                <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
            </div>

            <div className="form-group">
                <input type="number" name="peopleNumber" id="peopleNumber" min="0" className={"form-control " + (errors.peopleNumber ? "is-invalid" : "")} placeholder="Nhập số người cho đi nhờ" ref={register({
                    required: "Trường này là bắt buộc"
                })} disabled={!props.isLogin} />
                {errors.peopleNumber && <div className="invalid-feedback">{errors.peopleNumber.message}</div>}
            </div>

            <div className="text-right">
                <button type="submit" className="btn btn-warning" disabled={!props.isLogin}>
                    Tạo lộ trình <img src={require('assets/images/plus-white.svg')} alt="Plus" className="img-fluid img-plus" />
                </button>
            </div>
        </form>
    );
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addOrUpdateHitchhike: hitchhikeActions.AddOrUpdateHitchhike
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HitchhikeCreateMobileView);