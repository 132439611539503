/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import * as documentActions from "redux/stores/document/document.store";
import { Table, Modal, Button } from "react-bootstrap";
import PaginationControl from "components/pagination/pagination.view";
import { Link } from "react-router-dom";
import { JwtHelper } from "utils/jwt-helper";

import "../document-management/document-management-mobile.scss";

class DocumentManagementMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPageIndex: 1,
      documentItem: null,
      isShowModal: false,
    };
  }

  UNSAFE_componentWillMount() {
    const userInfo = JwtHelper.currentUser();
    userInfo &&
      this.onGetDocumentList(1, 10, {
        customer_id: userInfo.customerId,
        orderBy: "update_date",
        orderType: "DESC",
      });
  }

  onGetDocumentList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.onGetListDocument(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  onDeleteDocument = (id) => {
    this.props.onDeleteDocument(id).then(
      (res) => {
        if (res && res.content) {
          AddNotification(viVN.Success.Delete, NotificationMessageType.Success);
          this.onGetDocumentList(1, 10, {
            customer_id: JwtHelper.currentUser().customerId,
            orderBy: "update_date",
            orderType: "DESC",
          });
          this.onCloseModal();
        } else {
          res &&
            res.errorType &&
            AddNotification(
              viVN.Errors[res && res.errorType],
              NotificationMessageType.Error
            );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetDocumentList(e, 10, {
          customer_id: JwtHelper.currentUser().customerId,
          orderBy: "update_date",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  onShowModal = (item) => {
    this.setState({
      isShowModal: true,
      documentItem: item,
    });
  };

  onCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    const { currentPageIndex, documentItem } = this.state;
    const { documentList, totalItems } = this.props;
    return (
      <div className="document-management-list-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/thong-tin-ca-nhan">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Quản lý giấy tờ</div>
              </div>
            </div>
          </div>
        </header>

        <div className="body p-3">
          <Table>
            <thead>
              <tr>
                <th>Tiêu đề</th>
                <th>Ngày tải lên</th>
                <th></th>
              </tr>
            </thead>
            {documentList && documentList.length > 0 ? (
              <tbody>
                {documentList.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Link to={`/m/chi-tiet-giay-to/${item.id}`}>
                        <h5>{item.documentName}</h5>
                      </Link>
                    </td>
                    <td>{item.uploadDate}</td>
                    <td className="text-right">
                      <Link to={`/m/chinh-sua-giay-to/${item.id}`}>
                        <img
                          src={require("assets/images/edit-blue.svg")}
                          alt="Edit"
                          className="img-fluid img-edit"
                        />
                      </Link>
                      <img
                        src={require("assets/images/delete-blue.svg")}
                        alt="Delete"
                        className="img-fluid img-delete ml-2"
                        onClick={() => this.onShowModal(item)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="3" className="text-center">
                    Không có dữ liệu
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          <div className="row no-gutters">
            <div className="col-7">
              {documentList && documentList.length > 0 && (
                <PaginationControl
                  style="pt-0 mt-1"
                  pageChanged={this.onSelectPage.bind(this)}
                  totalItems={totalItems}
                  currentPageIndex={currentPageIndex}
                />
              )}
            </div>
            <div className="col-5 text-right">
              <Link
                to="/m/them-giay-to"
                className="btn btn-success rounded-pill btn-upload"
              >
                Tải lên
                <img
                  src={require("assets/images/upload-file.svg")}
                  alt="Upload File"
                />
              </Link>
            </div>
          </div>

          <Modal
            show={this.state.isShowModal}
            onHide={() => this.onCloseModal()}
            className="modal-delete-mobile"
            centered
          >
            <Modal.Header>
              <Modal.Title>Xóa giấy tờ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Giấy tờ sẽ không thể khôi phục sau khi xóa.
              <br />
              Bạn có chắc muốn xóa giấy tờ này.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.onCloseModal()}>
                Hủy
              </Button>
              <Button
                variant="success"
                onClick={() =>
                  documentItem && this.onDeleteDocument(documentItem.id)
                }
              >
                Xóa
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  documentList: state.document.documentList,
  totalItems: state.document.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetListDocument: documentActions.GetDocumentList,
      onDeleteDocument: documentActions.DocumentDelete,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentManagementMobileView);
