import "../safe-desktop.scss";

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from '../../../components/pagination/paging.view';
import { RenderNotHaveDataText } from '../../../components/warning-component/not-have-data';
import { PromiseAllTools } from 'core/app.store';
import * as QualityDocumentStore from 'redux/stores/safe/quality-document.store';

export function WaterQualityDesktopView(props) {

    const [notCallApi, setNotCallApi] = useState(true);

    useEffect(() => {
        props.PromiseAllTools([props.GetWaterDocumentList()])
    }, [])

    function gotoPage(i) {
        props.PromiseAllTools([props.GetWaterDocumentList(i)])
    }

    const listData = props.list ? props.list.data : [];
    const totalItems = props.list ? props.list.totalItems : 1;
    const pageSize = props.list ? props.list.pageSize : 10;

    if (notCallApi && props.list && props.list.data) setNotCallApi(false);

    return (
        <div className="safe-desktop">
            <div className="water-quality-desktop">
                <h2 className="title-lg">Xem chất lượng nước</h2>
                <div className="an-toan">
                    <div className="item">
                        <img src={require('assets/images/chat-luong-nuoc.jpg')} alt="Image" className="img-fluid" />
                        <div className="overlay"></div>
                        <span>Xem chất lượng nước</span>
                    </div>
                </div>
                <div className="list-item">
                    <div className="row align-items-center pt-2 pb-2 pl-4 pr-4">
                        {(listData.length > 0) ? listData.map((data, index) => <WaterQuality data={data} key={index} />) : <RenderNotHaveDataText notCallApi={notCallApi} />}
                    </div>
                </div>
                {(listData.length > 0) && <Pagination totalItems={totalItems} size={pageSize} goTo={(i) => gotoPage(i)} />}
            </div>
        </div>
    );
}

const WaterQuality = (props) => {

    const data = props.data

    return (
        <div className="col-12">
            <div className="item">
                <div className="row no-gutters">
                    <div className="col-11">
                        <Link to={`/xem-chat-luong-nuoc/chi-tiet/${data.id}`}>{data.documentName}</Link>
                    </div>
                    <div className="col-1 text-right">
                        <Link to={`/xem-chat-luong-nuoc/chi-tiet/${data.id}`}>
                            <img src={require('assets/images/keyboard-arrow-right.svg')} alt="Image" className="img-fluid" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    list: state.qualityDocument.list
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetWaterDocumentList: QualityDocumentStore.GetWaterDocumentList,
            PromiseAllTools
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(WaterQualityDesktopView);
