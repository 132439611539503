/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import "./detail-buy-and-rent-houses-mobile.scss";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as landModelActions from "modules/advertising-desktop/buy-and-rent-houses/buy-and-rent-houses.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { history } from "redux/store";
import { transformCurrency, SetDefaultErrorImage, NotificationMessageType } from "utils/configuration";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";

class DetailBuyAndRentHousesMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landModel: null,
      selectedLandId: null,
      landId: this.props.match.params.landId,
      visibleModal: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetlandModelDetail();
  }

  onGetlandModelDetail = () => {
    this.props.getAdHouseDetail(this.state.landId).then(
      (res) => {
        if (res.content) {
          this.setState({
            landModel: res.content,
          });
        } else {
          AddNotification(
            viVN.Errors[res.errorType],
            NotificationMessageType.Error
          );
          history.push("/m/rao-vat");
        }
      },
      (err) => { }
    );
  };

  onDeleteAdHouse = (confirm) => {
    if (confirm) {
      this.props.deleteAdHouse(this.state.selectedLandId).then(
        (res) => {
          this.onToggleDeleteModal(false);
          history.push("/m/rao-vat/mua-ban-thue-nha");
        },
        (err) => { }
      );
    } else {
      this.onToggleDeleteModal(false);
    }
  };

  onToggleDeleteModal = (visibleModal, landId) => {
    this.setState({
      visibleModal: visibleModal,
      selectedLandId: landId,
    });
  };

  render() {
    const landModel = this.state.landModel || {};
    const customerId = this.props.userInfo
      ? this.props.userInfo.customerId
      : -1;
    return (
      <div className="detail-buy-and-rent-houses-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center no-gutters">
              <div className="col-1">
                <Link to="/m/rao-vat/mua-ban-thue-nha">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
              </div>
              <div className="col-11 pl-2">
                <div className="header-title text-center text-truncate">
                  <span className="text-uppercase">
                    [{landModel.categoryName}]
                  </span>{" "}
                  {landModel.title}
                </div>
              </div>
            </div>
          </div>
        </header>

        <RenderDetailItem
          landModel={landModel}
          customerId={customerId}
          loggedIn={this.props.loggedIn}
          openDeleteModal={this.onToggleDeleteModal.bind(this)}
        />
        <DeleteHouseModal
          showDeleteModal={this.state.visibleModal}
          deleteAdHouse={this.onDeleteAdHouse}
        />
      </div>
    );
  }
}

function RenderDetailItem(props) {
  const landModel = props.landModel;
  const onOpenDeleteModal = (landId) => {
    props.openDeleteModal(true, landId);
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const settingNav1 = {
    asNavFor: nav2,
    ref: (slider) => setNav1(slider),
    arrows: false,
  };

  const settingNav2 = {
    asNavFor: nav1,
    ref: (slider) => setNav2(slider),
    slidesToShow:
      landModel && landModel.landFile
        ? landModel.landFile.length > 2
          ? 3
          : landModel.landFile.length
        : 0,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  return (
    <div className="detail-buy-and-rent-houses-item">
      <div className="img-wrapper">
        <div className="main-img mb-2">
          <Slider {...settingNav1}>
            {landModel &&
              landModel.landFile &&
              landModel.landFile.length > 0 &&
              landModel.landFile.map((item) => (
                <div key={item.fileId}>
                  <img
                    src={item.fileUrl}
                    alt={item.fileUrl}
                    onError={SetDefaultErrorImage}
                    className="img-fluid w-100"
                  />
                </div>
              ))}
          </Slider>
        </div>
        <div className="thumbnail-img">
          <Slider {...settingNav2}>
            {landModel &&
              landModel.landFile &&
              landModel.landFile.length > 0 &&
              landModel.landFile.map((item, index) => (
                <div key={item.fileId}>
                  <img
                    src={item.fileUrl}
                    onError={SetDefaultErrorImage}
                    alt={item.fileUrl}
                    className={
                      "img-fluid w-100" +
                      (landModel.landFile.length > 3
                        ? " padding-2"
                        : landModel.landFile.length > 1
                          ? landModel.landFile.length == 2
                            ? index % 2 == 0
                              ? " pull-right w-165 padding-2"
                              : " pull-left w-165 padding-2"
                            : " w-165 padding-2"
                          : " w-165")
                    }
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
      <div className="p-2">
        <h4>
          <div className="d-inline text-uppercase">
            [{landModel.categoryName}]
          </div>{" "}
          {landModel.title}
        </h4>
        <div className="row no-gutters">
          <div className="col-4">
            <h5>
              Giá: <span>{transformCurrency(landModel.price)} VNĐ</span>
            </h5>
            <span>Phòng ngủ: {landModel.bedroom}</span>
            <br />
            <span>Phòng khách: {landModel.livingRoom}</span>
          </div>
          <div className="col-5">
            <h5>
              Diện tích: <span>{landModel.size}m²</span>
            </h5>
            <span>Vệ sinh: {landModel.wc}</span>
            <br />
            <span>Bếp: {landModel.kitchen}</span>
          </div>
          <div className="col-3 align-self-end">
            <a
              href={"tel:" + landModel.mobileNumber}
              className="btn btn-success"
            >
              <img
                src={require("assets/images/phone-call.svg")}
                alt="phone-call"
                className="img-phone-call"
              />{" "}
              Liên hệ
            </a>
          </div>
        </div>
        {props.loggedIn && landModel.customerId == props.customerId && (
          <div className="mt-2">
            <Link
              to={`/m/mua-ban-nha/dang-tin/${landModel.landId}`}
              className="text-primary mr-4"
            >
              Chỉnh sửa
            </Link>
            <a
              className="text-danger"
              onClick={() => onOpenDeleteModal(landModel.landId)}
            >
              Xóa
            </a>
          </div>
        )}

        <hr />

        <label>Thông tin mô tả</label>
        <p
          dangerouslySetInnerHTML={{
            __html: landModel.content || "Không có dữ liệu",
          }}
        ></p>
      </div>
    </div>
  );
}

function DeleteHouseModal(props) {
  return (
    <Modal
      show={props.showDeleteModal}
      className="modal-delete"
      centered
      onHide={() => props.deleteAdHouse(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Xóa bài viết?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Bài viết sẽ không thể khôi phục sau khi xóa.
        <br />
        Bạn có chắc muốn xóa bài viết này.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.deleteAdHouse(false)}>
          Hủy
        </Button>
        <Button variant="success" onClick={() => props.deleteAdHouse(true)}>
          Xóa
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdHouseDetail: landModelActions.GetAdHouseDetail,
      deleteAdHouse: landModelActions.DeleteAdHouse,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailBuyAndRentHousesMobileView);
