/* eslint-disable react/prop-types */
import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../payment-mobile.scss';
import { Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from 'redux/store';
import * as OtherBillStores from 'redux/stores/payment/other-services/other-service.store';
import { ToolSelectPeriodTime } from "utils/configuration";
import { Error } from 'components/support-create-form/validate-style';
import RenderBillOfMonthly from './other-bill-detail/other-bill-detail-mobile.view.jsx';
import RenderNavigationBar from '../nav-header/nav-header.view.jsx';
import { handleChangeListHistory } from "../../payment-desktop/logic-payment/handle-change-list-history";
import { PromiseAllTools } from 'core/app.store';
import { useRenderPaymentHistoryList } from '../../../components/custom-hook/render-payment-history-list/render-payment-history-list';
import Pagination from '../../../components/pagination/paging.view';

function PaymentOtherServiceMobileView(props) {
    const { endDate, startDate, handleChangeStartDate, handleChangeEndDate, resetChangeDateTime, filterObj } = new ToolSelectPeriodTime()
    const [showWarning, setShowWarning] = useState(false);

    function ControlChangeListHistory() {
        handleChangeListHistory(
            startDate,
            endDate,
            () => props.PromiseAllTools([props.GetOtherBillList(1, 100, filterObj, props.userInfo.apartmentId)]),
            resetChangeDateTime,
            setShowWarning,
            props.ResetHistoryList
        )
    }

    function handleDirectionPageDetail(feeId, parkingId) {
        let otherId = feeId;
        if (parkingId != null) otherId += "&" + parkingId;
        history.push(`/m/thanh-toan/dich-vu-khac/chi-tiet/${otherId}`)

    }

    useEffect(() => {
        props.PromiseAllTools([
            props.GetOtherBillThisMonth(props.userInfo.apartmentId),
            props.GetOtherBillList(1, 100, filterObj, props.userInfo.apartmentId),
        ])
    }, [])

    const listData = Array.from(props.listPay);

    return (
        <div className="payment-mobile">
            <RenderNavigationBar type="other" />

            <div className="mobile-divide"></div>

            <RenderBillOfMonthly dataFee={props.monthlyBill.fee} haveFeeBill={props.monthlyBill.haveFee} haveParkingBill={props.monthlyBill.haveParking} dataParking={props.monthlyBill.parking} />

            <div className="mobile-divide"></div>

            <div className="payment-history bg-white p-3">
                <h2>Lịch sử thanh toán</h2>
                <div className="row no-gutters">
                    <div className="col-6 position-relative pr-1">
                        <DatePicker selected={startDate} onChange={handleChangeStartDate} className="form-control rounded-pill" dateFormat="dd/MM/yyyy" />
                        <img src={require("assets/images/calendar.svg")} alt="Calendar" className="img-calendar" />
                    </div>
                    <div className="col-6 position-relative pl-1 pr-1">
                        <DatePicker selected={endDate} onChange={handleChangeEndDate} className="form-control rounded-pill" dateFormat="dd/MM/yyyy" popperPlacement="bottom-end" />
                        <img src={require("assets/images/calendar.svg")} alt="Calendar" className="img-calendar" />
                    </div>
                    <div className="col-6">{showWarning && <Error content="Khoảng thời gian không đúng, vui lòng kiểm tra lại." />}</div>
                    <div className="col-12 text-right search-box mt-2 pr-1">
                        <button className="btn btn-success rounded-pill" onClick={ControlChangeListHistory}>
                            <img src={require("assets/images/search-white.svg")} alt="Search White" className="img-search-white" /> Tìm kiếm
                  </button>
                    </div>
                </div>
                {
                    listData.length > 0 ?
                        <RenderListHistory listData={listData} handleDirectionPageDetail={handleDirectionPageDetail} /> : (
                            <div className="border-top mt-2 pt-3">
                                <p className="text-center mb-0">Không có dữ liệu để hiển thị</p>
                            </div>
                        )
                }
            </div>

            <div className="payment-guide">
                <Link to="/m/thanh-toan/dich-vu-khac/huong-dan-thanh-toan">
                    Hướng dẫn thanh toán <img src={require("assets/images/right-arrow-blue.svg")} alt="right-arrow-blue" className="img-right-arrow-blue" />
                </Link>
            </div>
        </div>
    );
}

function RenderListHistory(props) {
    const {
        listData,
        Page_Size,
        displayList,
        setCurrentIndex
    } = useRenderPaymentHistoryList(props.listData)
    return (
        <Fragment>
            <Table hover className="mt-1 mb-0">
                <tbody>
                    {displayList.map((data, index) => <RenderItemOfList click={() => props.handleDirectionPageDetail(data.feeId, data.parkingId)} data={data} key={index} />)}
                </tbody>
            </Table>
            {
                (listData.length > Page_Size) && <Pagination totalItems={listData.length} size={Page_Size} goTo={setCurrentIndex} />
            }
        </Fragment>
    )
}

function RenderItemOfList(props) {
    const data = props.data;
    return (
        <Fragment>
            <tr onClick={props.click}>
                <th colSpan="2" className="pl-0 pt-2 pb-2">
                    Hóa đơn dịch vụ tháng {data.monthly}/{data.yearly}
                </th>
            </tr>
            <tr onClick={props.click}>
                <td>
                    Phí quản lý:<br />
                                Gửi xe:
                            </td>
                <td className="text-right total-price">
                    {data.feeTotal}VNĐ<br />
                    {data.parkingTotal || "----"}VNĐ
                            </td>
            </tr>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    monthlyBill: state.otherBill.billThisMonth,
    listPay: state.otherBill.listPay,
    otherDetail: state.otherBill.otherDetail
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetOtherBillThisMonth: OtherBillStores.GetOtherBillThisMonth,
    GetOtherBillList: OtherBillStores.GetOtherBillList,
    ResetHistoryList: OtherBillStores.ResetHistoryList,
    PromiseAllTools
},
    dispatch
)
export default connect(mapStateToProps, mapDispatchToProps)(PaymentOtherServiceMobileView)