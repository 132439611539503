/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as buidingManagementActions from 'redux/stores/management/building-management.store';
import * as memberActions from 'redux/stores/management/member.store';
import * as viVN from 'translation/vi-VN.json';
import { history } from 'redux/store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType, SetDefaultErrorImage } from 'utils/configuration';
import { JwtHelper } from 'utils/jwt-helper';
import { Link } from 'react-router-dom';
import './management-info-mobile.scss';
import PaginationControl from 'components/pagination/pagination.view';

class ManagementInfoMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: JwtHelper.currentProject() || 1,
            currentPageIndex: 1
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetBuildingManagementDetail(this.state.project);
        this.onGetMemberList(this.state.currentPageIndex, 12);
    }

    onGetBuildingManagementDetail = (project) => {
        this.props.getBuildingManagementDetail(project).then(res => {

        }, err => { 
        })
    }

    onGetMemberList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getMemberList(pageIndex, size, filterObjs).then(res => {

        }, err => { 
        })
    }

    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            currentPageIndex: 1
        }, () => {
            this.onGetMemberList(1, 10);
        });
    }

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetMemberList(pageNumber);
        })
    }

    render() {
        const { buildingManagementModel, totalItems, memberList } = this.props;
        const { currentPageIndex } = this.state;
        return (
            <div className="management-info">
                {buildingManagementModel && <div className="container">
                    <h2>Công ty quản lý vận hành</h2>
                    <table className="table table-borderless">
                        <tbody>
                            <tr>
                                <td>Tên công ty:</td>
                                <td>{buildingManagementModel.cityName}</td>
                            </tr>
                            <tr>
                                <td>Địa chỉ:</td>
                                <td>{buildingManagementModel.address}</td>
                            </tr>
                            <tr>
                                <td>Hotline:</td>
                                <td><b>{buildingManagementModel.hotline}</b></td>
                            </tr>
                            <tr>
                                <td>Email BQL:</td>
                                <td>{buildingManagementModel.email}</td>
                            </tr>
                            <tr>
                                <td>Website:</td>
                                <td>
                                    <a href={buildingManagementModel.website} target="_blank">
                                        {buildingManagementModel.website}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Thành viên BQL</h2>
                    <div className="member-list">
                        <div className="row">
                            {
                                memberList.map(item => <div className="col-4 col-md-3 text-center mb-3">
                                    <Link to={`/m/thanh-vien-bql/${item.agentId}`}>
                                        <img src={item.avatarUrl || require("assets/images/29-12.png")} onError={SetDefaultErrorImage} className="img-fluid" alt="Avatar"></img>
                                    </Link>
                                    <h5>
                                        <Link to={`/m/thanh-vien-bql/${item.agentId}`}>
                                            <span>{item.agentName}</span>
                                        </Link>
                                    </h5>
                                    {item.roleName && <span>[{item.roleName}]</span>}
                                    <br />
                                    <span>SĐT: {item.mobileNumber}</span>
                                </div>
                                )}
                        </div>
                    </div>
                    <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    buildingManagementModel: state.buildingManagement.buildingManagementModel,
    memberList: state.member.memberList,
    totalItems: state.member.totalItems
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBuildingManagementDetail: buidingManagementActions.GetBuildingManagementDetail,
            getMemberList: memberActions.GetMemberList,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagementInfoMobileView);