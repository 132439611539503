// link url utiliti = will delete later
export const CAFELINK = 'cafe';
export const RESTAURANTLINK = 'nha-hang';
export const SUPERMARKETLINK = 'sieu-thi';
export const SCHOOLLINK = 'truong-hoc';
export const TENNISLINK = 'tennis';
export const BBQLINK = 'bbq';
export const GYMLINK = 'gym';
export const POOLLINK = 'be-boi';

// keyword use in api utilitisshopList
export const CAFEKEY = "CAFE";
export const RESTAURANKEY = "NHA_HANG";
export const SCHOOLKEY = "TRUONG_HOC";
export const SUPERMARKETKEY = "SIEU_THI";

// sport type

export const POOLTYPE = 'pool';
export const GYMTYPE = 'gym';
export const TENNISTYPE = 'tennis';
export const SOCCERTYPE = 'football';

// action type - utilities shop store
export const INITDETAIL = "UTILITISHOP/DETAIL";
export const INITSHOPDETAILWITHACTIVECALENDAR = "UTILITISHOP/INITSHOPDETAILWITHACTIVECALENDAR";
export const INITDETAILWITHCALENDAR = "UTILITISHOP/DETAILCALENDAR";
export const INITCAFELIST = "UTILITISHOP/CAFESHOPLIST";
export const INITRESTAURANTLIST = "UTILITISHOP/RESTAURANTSHOPLIST";
export const INITSCHOOLLIST = "UTILITISHOP/SCHOOLLIST";
export const INITSUPERMARKETLIST = "UTILITISHOP/SUPERMARKETSHOPLIST";
export const INITSPORTLIST = "UTILITISHOP/SPORTS";
export const INITTENNISLIST = "UTILITISHOP/TENNIS";
export const INITSOCCERLIST = "UTILITISHOP/SOCCERLIST";
export const INITGYMLIST = "UTILITISHOP/INITGYMLIST";
export const INITPOOLLIST = "UTILITISHOP/INITPOOLLIST";
export const INITSPALIST = "UTILITISHOP/INITSPALIST";

