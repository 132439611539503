//@ts-check
import React, { useEffect } from 'react';
import RenderHeaderDetailView from '../childs/header-detail/header-detail.view';
import RenderFooterDetailView from '../childs/footer-detail/footer-detail.view';
import UtilitiesListOther from '../../child-component/utilities-list-other/utilities-list-other.view';
import * as PageUrls from '../../../PageUrls';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UtilitiesStore from '../../../../redux/stores/utillities/utilitis-shop.store';

function PoolDetailViewDesktop(props) {
    useEffect(() => {
        props.GetShopDetail(props.match.params.id, new Date())
    }, [])
    return (<div>
        <RenderHeaderDetailView listPageUrl={PageUrls.UtilitiesPoolDeskTop} data={props.shopDetail} />
        <RenderFooterDetailView listImage={props.shopDetail.listImage} />
        <UtilitiesListOther currentTypeUrl={PageUrls.UtilitiesSportHomeDesktop} />
    </div>)
}

const mapStateToProps = state => ({
    shopDetail: state.utilitiShop.shopDetail,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiesStore.GetPoolDetail,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PoolDetailViewDesktop)