import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../management-desktop.scss';
import * as procedureActions from 'redux/stores/management/procedure.store';
import { Link } from 'react-router-dom';
import DocumentRendering from 'components/document-rendering/document-rendering';

class ProcedureSectionDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProcedureList();
  }

  onGetProcedureList = (pageIndex = 1, size = 4, filterObjs = null) => {
    this.setState({ isLoading: true });
    this.props.getTop4ProcedureList(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => { 
        this.setState({ isLoading: false });
      }
    );
  };

  render() {
    const { isLoading } = this.state;
    const { top4Procedures } = this.props;
    return (
      <div className="procedure-section">
        {isLoading && <div className="loading-icon"></div>}
        <div className="row no-gutters">
          <div className="col-3">
            <h2 className="title-lg mt-4">Thủ tục BQL</h2>
          </div>
          <div className="col-9 position-relative text-right pt-3">
            <Link to="/danh-muc/thu-tuc-bql">Xem thêm</Link>
            <div className="management-border w-100"></div>
          </div>
        </div>

        <div className="row">
          {top4Procedures.map(item => (
            <div className="col-3" key={item.id}>
              <DocumentRendering
                file={item}
                iconUrl={require('assets/images/thu-tuc-bql.png')}
                redirectUrl={`/thu-tuc-bql/chi-tiet/${item.id}`}
              />
            </div>
          ))}
          {!top4Procedures.length && <div className="col-12">Không có bản ghi</div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  top4Procedures: state.procedure.top4Procedures
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTop4ProcedureList: procedureActions.GetTop4ProcedureList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcedureSectionDesktopView);
