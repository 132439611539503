//@ts-check
import React, { useEffect } from 'react';
import HeaderDetailView from '../../child-component/detail-page-childs/header-detail/header-detail.view';
import FooterDetailView from '../../child-component/detail-page-childs/footer-detail/footer-detail.view';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PageUrls from '../../../PageUrls';
import * as UtilitiesStore from '../../../../redux/stores/utillities/utilitis-shop.store';

/**
 * @param {{isLoggedIn?:boolean
 * shopDetail:any,
 * GetShopDetail:Function,
 * match:any,
 * }} [props]
*/
function PoolDetailPageMobile(props) {
    useEffect(() => {
        props.GetShopDetail(props.match.params.id, new Date())
    }, [])
    return (
        <div>
            <HeaderDetailView data={props.shopDetail} comeBackLink={PageUrls.UtilitiesSportHomeMobile} />
            <FooterDetailView listImage={props.shopDetail.listImage} />
        </div>
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    shopDetail: state.utilitiShop.shopDetail,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiesStore.GetPoolDetail,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PoolDetailPageMobile)
