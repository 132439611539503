import React, { Component } from 'react';
import './not-found-mobile.scss';
import { Link } from 'react-router-dom';

class NotFoundMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageHeight: window.innerHeight - 64 - 50,
        };
    }

    render() {
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Not Found</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="not-found-mobile" style={{ height: this.state.pageHeight }}>
                    <div className="not-found-content">
                        <h1>404</h1>
                        <p>
                            WE are sorry, but the page you requested was not found
                    </p>
                        <a href="/" className="btn btn-success rounded-pill">Trang chủ</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFoundMobileView;