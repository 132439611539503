import "../safe-desktop.scss";

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Tab, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as IncidentAreaStore from 'redux/stores/safe/incidents-area.store';
import Pagination from 'components/pagination/paging.view.jsx';
import ReportIncidentsForm from 'components/feedback/report-incidents.form';
import { CutTextByLength, StripHtmlTag, SetDefaultErrorImage } from 'utils/configuration';
import { PromiseAllTools } from 'core/app.store';

function IncidentsAreaDesktopView(props) {
    const isLogin = props.loggedIn;
    const PageSize = 8;
    const [isSolved, setSolved] = useState(false);
    const listData = (props.incidentAreas && props.incidentAreas.haveData) ? props.incidentAreas.listData : [];
    const totalItems = (props.incidentAreas && props.incidentAreas.haveData) ? props.incidentAreas.total : 0;

    function handleChangeSolved() {
        setSolved(true)
        props.PromiseAllTools([props.GetIncidentAreasDesktop(1, PageSize, true)])
    }

    function handleChangeNotSolved() {
        setSolved(false)
        props.PromiseAllTools([props.GetIncidentAreasDesktop(1, PageSize, false)])
    }

    function goTo(id) {
        props.PromiseAllTools([props.GetIncidentAreasDesktop(id, PageSize, isSolved)])
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        props.PromiseAllTools([props.GetIncidentAreasDesktop(1, PageSize, isSolved)])
    }, [])

    return (
        <div className="safe-desktop">
            <div className="incidents-area-desktop">
                <h2 className="title-lg">Sự cố trong khu</h2>
                <Tab.Container defaultActiveKey="dang-xu-ly">
                    <Nav variant="tabs" className="justify-content-end tab-control">
                        <Nav.Item>
                            <div onClick={() => handleChangeNotSolved()}>
                                <Nav.Link eventKey="dang-xu-ly">
                                    <img src={require('assets/images/processing-green.svg')} alt="Processing" className="img-fluid img-inactive" />
                                    <img src={require('assets/images/processing-white.svg')} alt="Processing" className="img-fluid img-active" />
                                    <span className="ml-2">Đang xử lý</span>
                                </Nav.Link>
                            </div>
                        </Nav.Item>
                        <Nav.Item>
                            <div onClick={() => handleChangeSolved()}>
                                <Nav.Link eventKey="da-xu-ly">
                                    <img src={require('assets/images/processed-green.svg')} alt="Processed" className="img-fluid img-inactive" />
                                    <img src={require('assets/images/processed-white.svg')} alt="Processed" className="img-fluid img-active" />
                                    <span className="ml-2">Đã xử lý</span>
                                </Nav.Link>
                            </div>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="bao-su-co" className="report-problem">
                                <img src={require('assets/images/report-problem-yellow.svg')} alt="Report Problem" className="img-fluid img-inactive" />
                                <img src={require('assets/images/report-problem-white.svg')} alt="Report Problem" className="img-fluid img-active" />
                                <span className="ml-2">Báo sự cố</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="dang-xu-ly">
                            <div className="content mt-3">
                                {listData.map((data, index) => <IncidentsProcess data={data} key={index} />)}
                                {listData.length===0&&<p>Không có dữ liệu để hiển thị</p>}
                            </div>
                            <Pagination totalItems={totalItems} goTo={goTo} size={PageSize} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="da-xu-ly">
                            <div className="content mt-3">
                                {listData.map((data, index) => <IncidentsProcess data={data} key={index} />)}
                                {listData.length===0&&<p>Không có dữ liệu để hiển thị</p>}
                            </div>
                            <Pagination totalItems={totalItems} goTo={goTo} size={PageSize} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="bao-su-co" title="Báo sự cố">
                            <ReportIncidentsForm isLogin={isLogin} />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}

function IncidentsProcess(props) {
    const data = props.data
    const style = {
        maxHeight: "192px",
        minHeight: "192px"
    }
    return (
        <div className="item mb-3">
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <Link to={`/su-co-trong-khu/chi-tiet/${data.id}`}>
                        <img src={data.url} onError={SetDefaultErrorImage} style={style} alt="image" className="img-fluid w-100 h-100" />
                    </Link>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                    <div className="item-content">
                        <h5>
                            <Link to={`/su-co-trong-khu/chi-tiet/${data.id}`}>{CutTextByLength(StripHtmlTag(data.content), 236)}</Link>
                        </h5>
                        <span className="datetime">{data.time}</span>
                        {!data.solved ? <p className="mb-0 text-primary">Đang xử lý</p> : <p className="mb-0 text-success">Đã xử lý</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    incidentAreas: state.incidentsArea.incidentAreas,
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetIncidentAreasDesktop: IncidentAreaStore.GetIncidentAreasDesktop,
    PromiseAllTools
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsAreaDesktopView)