/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../advertising-desktop.scss";
import * as landModelActions from "../buy-and-rent-houses.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { NotificationMessageType } from "utils/configuration";
import { history } from "redux/store";
import { transformCurrency, SetDefaultErrorImage } from "utils/configuration";
import Slider from "react-slick";

export class BuyAndRentHousesDetailDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landModel: null,
      landId: this.props.match.params.landId,
      nav1: null,
      nav2: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetlandModelDetail();
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  onGetlandModelDetail = () => {
    this.props.getAdHouseDetail(this.state.landId).then(
      (res) => {
        if (res.content) {
          this.setState({
            landModel: res.content,
          });
        } else {
          AddNotification(
            viVN.Errors[res.errorType],
            NotificationMessageType.Error
          );
          history.push("/rao-vat/mua-ban-nha");
        }
      },
      (err) => {
      }
    );
  };

  render() {
    const { landModel } = this.state;

    const settingNav1 = {
      asNavFor: this.state.nav2,
      ref: (slider) => (this.slider1 = slider),
      arrows: false,
    };

    const settingNav2 = {
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider),
      slidesToShow:
        landModel && landModel.landFile
          ? landModel.landFile.length > 3
            ? 4
            : landModel.landFile.length
          : 0,
      swipeToSlide: true,
      focusOnSelect: true,
    };

    return (
      <div className="advertising-desktop">
        <h2 className="title-lg">Mua, bán & cho thuê nhà</h2>
        <div className="house-detail bg-white">
          <div className="img-wrapper">
            <div className="main-img mb-3">
              <Slider {...settingNav1}>
                {landModel &&
                  landModel.landFile &&
                  landModel.landFile.length > 0 &&
                  landModel.landFile.map((item) => (
                    <div key={item.fileId}>
                      <img
                        src={item.fileUrl}
                        alt={item.fileUrl}
                        onError={SetDefaultErrorImage}
                        className="img-fluid w-100"
                      />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="thumbnail-img">
              <Slider {...settingNav2}>
                {landModel &&
                  landModel.landFile &&
                  landModel.landFile.length > 0 &&
                  landModel.landFile.map((item, index) => (
                    <div key={item.fileId}>
                      <img
                        src={item.fileUrl}
                        onError={SetDefaultErrorImage}
                        alt={item.fileUrl}
                        className={
                          "img-fluid w-100" +
                          (landModel.landFile.length > 3
                            ? " padding-2"
                            : landModel.landFile.length > 1
                            ? landModel.landFile.length == 2
                              ? index % 2 == 0
                                ? " pull-right w-165 padding-2"
                                : " pull-left w-165 padding-2"
                              : " w-165 padding-2"
                            : " w-165")
                        }
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          {landModel && (
            <div className="house-detail-body p-3">
              <div className="row">
                <div className="col-12">
                  <h5 className="house-title">
                    <span className="text-uppercase">
                      [{landModel.categoryName}]
                    </span>{" "}
                    {landModel.title}
                  </h5>
                </div>

                <div className="col-4">
                  <span className="price">
                    Giá: <span>{transformCurrency(landModel.price)} VNĐ</span>
                  </span>
                </div>
                <div className="col-8">
                  <span className="square">
                    Diện tích: <span>{landModel.size} m2</span>
                  </span>
                </div>

                <div className="col-4 house-general">
                  <span>Phòng ngủ: {landModel.bedroom}</span>
                  <br></br>
                  <span>Phòng khách: {landModel.livingRoom}</span>
                </div>
                <div className="col-4 house-general">
                  <span>Vệ sinh: {landModel.wc}</span>
                  <br></br>
                  <span>Bếp: {landModel.kitchen}</span>
                </div>
                <div className="col-4 text-right">
                  <a
                    href={"tel:" + landModel.mobileNumber}
                    className="btn btn-success mt-2"
                  >
                    <img
                      src={require("assets/images/phone-call.svg")}
                      alt="Phone Call"
                    />{" "}
                    Liên hệ
                  </a>
                </div>
              </div>
              <hr />
              <div
                className="house-detail-content"
                dangerouslySetInnerHTML={{
                  __html: landModel.content || "Không có dữ liệu",
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdHouseDetail: landModelActions.GetAdHouseDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyAndRentHousesDetailDesktopView);
