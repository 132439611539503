import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../management-desktop.scss";
import * as legalActions from '../../../redux/stores/management/legal.store';
import DocumentRendering from 'components/document-rendering/document-rendering';

class LegalListDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalList: []
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetLegalList();
  }

  onGetLegalList = (pageIndex = 1, size = 10) => {
    this.props.getLegalList(pageIndex, size).then(res => {

    }, err => { 
    })
  }

  render() {
    const { legalList } = this.props;
    return (
      <div className="management-desktop">
        <h2 className="title-lg mb-0">Văn bản pháp lý</h2>
        <hr className="mt-2" />
        <div className="row">
          {
            legalList.map((item) =>
              <div className="col-3" key={item.id}>
                <DocumentRendering file={item} iconUrl={require('assets/images/pdf-icon.png')} redirectUrl={`/van-ban-phap-ly/${item.id}`} />
              </div>)
          }
          {!legalList.length && <div className="col-12">Không có bản ghi</div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  legalList: state.legal.legalList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLegalList: legalActions.GetLegalList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LegalListDesktopView);