import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as friendActions from 'redux/stores/friend/friend.store';
import SocketService from "services/socket.service";
import { JwtHelper } from "utils/jwt-helper";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType } from "utils/configuration";
const socketService = new SocketService();

class AddFriendActionView extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            currentUser: JwtHelper.currentUser()
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onAddFriend = () => {
        this.setState({ isLoading: true });
        this.props.createOrUpdateFriend({ maker_id: this.state.currentUser.customerId, accepter_id: this.props.friendId }).then(res => {
            if (this._isMounted) {
                this.props.callBack && this.props.callBack();
                this.setState({ isLoading: false });
                AddNotification('Gửi yêu cầu kết bạn thành công!', NotificationMessageType.Success);
            }
        }).catch(err => {
            this.setState({ isLoading: false });
        })
    }

    onCancelFriend = () => {
        this.setState({ isLoading: true });
        this.props.cancelFriend(this.state.currentUser.customerId, this.props.friendId).then(res => {
            if (this._isMounted) {
                this.props.callBack && this.props.callBack();
                this.setState({ isLoading: false });
                AddNotification('Huỷ yêu cầu kết bạn thành công!', NotificationMessageType.Success);
            }
        }).catch(err => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className="add-friend-action">
                {this.props.status == friendActions.FriendStatus.NotFriend && <button disabled={isLoading} className={this.props.cssClass ? this.props.cssClass : 'btn btn-primary'} onClick={this.onAddFriend.bind(this)}>
                    <span>Thêm bạn bè</span>
                    {isLoading && <i className="fa fa-spinner"></i>}
                </button>}
                {this.props.status == friendActions.FriendStatus.RequestingFriend &&
                    <button disabled={isLoading} className={'btn btn-secondary'} onClick={this.onCancelFriend.bind(this)}>
                        <span>Hủy kết bạn</span>
                        {isLoading && <i className="fa fa-spinner"></i>}
                    </button>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createOrUpdateFriend: friendActions.CreateOrUpdateFriend,
            cancelFriend: friendActions.CancelFriend,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFriendActionView);