//@ts-check
import { Map } from 'immutable';
// @ts-ignore
import Service from 'services/api.service';
// @ts-ignore
import { createFormData, AppKey, ConvertNumberToString, ERRORTYPEEMPTYDATAERROR } from 'utils/configuration';
// @ts-ignore
import { ApiUrl } from 'services/api-url';
// @ts-ignore
import { createSaveLoading } from 'core/app.store';
import { ParkingPaymentGuideKeyword, FeePaymenGuideKeyword, GetContentByKeyword } from '../../get-content-by-keyword/getContentByKeyword.store';
// @ts-ignore
import { JwtHelper } from 'utils/jwt-helper';

const userInfor = JwtHelper.currentUser();
const service = new Service();
const INITBILLTHISMONTH = 'OTHERBILL/BILLTHISMONTH';
const INITLISTPAY = "OTHERBILL/INITLISTPAY";
const INITOTHERDETAIL = 'OTHERBILL/INITDETAI';
const INITPARKINGPAYMENTGUIDE = 'OTHERBILL/PARKINGGUIDEPAYMENT';
const INITFEEPAYMENTGUIDE = 'OTHERBILL/FEEGUIDEPAYMENT';

const InitBillThisMonth = data => ({
    type: INITBILLTHISMONTH, data: data
})

const InitParkingPaymentGuide = data => ({
    type: INITPARKINGPAYMENTGUIDE, data: data
})

const InitFeePaymentGuide = data => ({
    type: INITFEEPAYMENTGUIDE, data: data
})

const InitListPay = data => ({
    type: INITLISTPAY, data: data
})

const InitOtherDetail = data => ({
    type: INITOTHERDETAIL, data: data
})

export const ResetHistoryList = () => {
    return dispatch => {
        dispatch(InitListPay([]))
    }
}

export const GetOtherBillDetail = (feeId, parkingId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let promiseArray = [];
        let dataFee = null;
        let dataParking = null;
        let formDataFee = new FormData();
        formDataFee.append('feeId', feeId);
        let payLoadFee = createFormData(null, AppKey.GetPayloadPrefix, formDataFee);
        let formDataParking = new FormData();
        formDataParking.append('parkingId', parkingId);
        let payLoadParking = createFormData(null, AppKey.GetPayloadPrefix, formDataParking);
        if (feeId) promiseArray.push(service.post(ApiUrl.FeeBillById, payLoadFee))
        if (parkingId) promiseArray.push(service.post(ApiUrl.ParkingBillById, payLoadParking))
        Promise.all(promiseArray).then((res) => {
            if (res.length === 1) {
                if (feeId) dataFee = res[0].content; else dataParking = res[0].content;
            } else {
                if (res[0].errorType != ERRORTYPEEMPTYDATAERROR) dataFee = res[0].content;
                if (res[1].errorType != ERRORTYPEEMPTYDATAERROR) dataParking = res[1].content
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            dispatch(InitOtherDetail(ExchangeOtherBIllDetail(dataFee, dataParking)))
        });
    }
}

export const GetOtherBillList = (pageIndex = 1, size = 10, filterObjs = null, apartmentId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let formData = new FormData();
        let listDataFee = [];
        let listDataParking = [];
        formData.append('pageIndex', String(pageIndex));
        formData.append('size', String(size));
        formData.append('apartmentId', apartmentId);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        Promise.all([service.post(ApiUrl.FeeBillByTime, payLoad), service.post(ApiUrl.ParkingBillByTime, payLoad)]).then((result) => {
            if (result[0].errorType != ERRORTYPEEMPTYDATAERROR) listDataFee = result[0].content.items;
            if (result[1].errorType != ERRORTYPEEMPTYDATAERROR) listDataParking = result[1].content.items;
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            dispatch(InitListPay(ExchangeOtherBillListFormatData(sortDesc(listDataFee, isBNewThanA), sortDesc(listDataParking, isBNewThanA))))
        })
    }
}

export const GetParkingPaymentGuide = () => {
    return GetContentByKeyword(ParkingPaymentGuideKeyword, InitParkingPaymentGuide)
}

export const GetFeePaymentGuide = () => {
    return GetContentByKeyword(FeePaymenGuideKeyword, InitFeePaymentGuide)
}

export const GetOtherBillThisMonth = (apartmentId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let dataFee = null;
        let dataParking = null;
        let formData = new FormData();
        formData.append('apartmentId', apartmentId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        Promise.all([service.post(ApiUrl.FeeBillThisMonth, payLoad), service.post(ApiUrl.ParkingBillThisMonth, payLoad)]).then((res) => {
            if (res[0].errorType != ERRORTYPEEMPTYDATAERROR) dataFee = res[0].content;
            if (res[1].errorType != ERRORTYPEEMPTYDATAERROR) dataParking = res[1].content;
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            dispatch(InitBillThisMonth(ExchangeOtherBIllDetail(dataFee, dataParking)))
        })
    }
}


const initialState = Map({
    billThisMonth: {},
    parkingPaymentGuide: "",
    feePaymentGuide: "",
    listPay: {},
    otherDetail: {}
}).toJS()

export default function OtherBillReducer(state = initialState, action) {
    switch (action.type) {
        case INITFEEPAYMENTGUIDE:
            return {
                ...state,
                feePaymentGuide: action.data
            }
        case INITOTHERDETAIL:
            return {
                ...state,
                otherDetail: action.data
            }
        case INITLISTPAY:
            return {
                ...state,
                listPay: action.data
            }
        case INITBILLTHISMONTH:
            return {
                ...state,
                billThisMonth: action.data
            }
        case INITPARKINGPAYMENTGUIDE:
            return {
                ...state,
                parkingPaymentGuide: action.data
            }
        default:
            return state
    }
}

function ExchangeOtherBIllDetail(feeData, parkingData) {
    let standardData = {
        haveFee: false,
        haveParking: false,
        fee: {},
        parking: {},
    }

    if (feeData != null) {
        standardData.haveFee = feeData.total > 0 ? true : false;
        standardData.fee.feeId = feeData.feeId;
        standardData.fee.userName = userInfor.customerName;
        standardData.fee.houseNumber = feeData.houseNumber;
        standardData.fee.monthly = feeData.monthly;
        standardData.fee.yearly = feeData.yearly;
        standardData.fee.acreage = ConvertNumberToString(feeData.area);
        standardData.fee.feePrice = ConvertNumberToString(feeData.fee);
        standardData.fee.tax = ConvertNumberToString(feeData.tax);
        standardData.fee.subFee = ConvertNumberToString(feeData.subFee);
        standardData.fee.discount = ConvertNumberToString(feeData.discount);
        standardData.fee.unitName = feeData.unitName;
        standardData.fee.total = ConvertNumberToString(feeData.total);
        standardData.fee.datePay = feeData.datePay;
    }
    if (parkingData != null) {
        standardData.haveParking = parkingData.total > 0 ? true : false;
        standardData.parking.parkingId = parkingData.parkingId;
        standardData.parking.userName = userInfor.customerName;
        standardData.parking.houseNumber = parkingData.houseNumber;
        standardData.parking.monthly = parkingData.monthly;
        standardData.parking.yearly = parkingData.yearly;
        standardData.parking.amount = ConvertNumberToString(parkingData.quantity);
        standardData.parking.unitPrice = ConvertNumberToString(parkingData.fee);
        standardData.parking.tax = ConvertNumberToString(parkingData.tax);
        standardData.parking.subFee = ConvertNumberToString(parkingData.subFee);
        standardData.parking.discount = ConvertNumberToString(parkingData.discount);
        standardData.parking.unitName = parkingData.unitName;
        standardData.parking.total = ConvertNumberToString(parkingData.total);
        standardData.parking.datePay = parkingData.datePay;
    }

    return standardData
}

const DefaultParkingObj = {
    datePay: null,
    houseNumber: null,
    monthly: null,
    parkingId: null,
    parkingName: null,
    total: null,
    yearly: null,
}

const DefaultFeeObj = {
    datePay: null,
    feeId: null,
    feeName: null,
    houseNumber: null,
    monthly: null,
    total: null,
    yearly: null,
}

function ExchangeOtherBillListFormatData(listFeeRaw, listParkingRaw) {
    const standartData = []
    const listFee = (listFeeRaw.length > 0) ? sortDesc(listFeeRaw, isBNewThanA) : [];
    const listParking = (listParkingRaw.length > 0) ? sortDesc(listParkingRaw, isBNewThanA) : [];
    const feeLength = listFee.length;
    const parkingLength = listParking.length;
    let feeIndex = 0;
    let parkingIndex = 0;
    const isEndOfArray = () => {
        if ((feeIndex < feeLength) || (parkingIndex < parkingLength)) return false; else return true;
    }

    while (!isEndOfArray()) {
        const FeeA = (feeIndex < feeLength) ? {
            datePay: listFee[feeIndex].datePay,
            feeId: listFee[feeIndex].feeId,
            feeName: listFee[feeIndex].feeName,
            houseNumber: listFee[feeIndex].houseNumber,
            monthly: listFee[feeIndex].monthly,
            total: listFee[feeIndex].total,
            yearly: listFee[feeIndex].yearly,
        } : DefaultFeeObj;

        const ParkingB = (parkingIndex < parkingLength) ? {
            datePay: listParking[parkingIndex].datePay,
            houseNumber: listParking[parkingIndex].houseNumber,
            monthly: listParking[parkingIndex].monthly,
            parkingId: listParking[parkingIndex].parkingId,
            parkingName: listParking[parkingIndex].parkingName,
            total: listParking[parkingIndex].total,
            yearly: listParking[parkingIndex].yearly,
        } : DefaultParkingObj;

        switch (compare2DatePeriod(FeeA, ParkingB)) {
            case -1: {
                standartData.push(createOtherBillData(null, ParkingB))
                parkingIndex++;
            } break;
            case 0: {
                standartData.push(createOtherBillData(FeeA, ParkingB))
                parkingIndex++;
                feeIndex++;
            } break;
            case 1: {
                standartData.push(createOtherBillData(FeeA, null))
                feeIndex++;
            } break;
        }

    }

    return standartData;
}

function createOtherBillData(feeData = null, parkingData = null) {
    const fee = (feeData) ? feeData : DefaultFeeObj;
    const parking = (parkingData) ? parkingData : DefaultParkingObj;
    return {
        monthly: fee.monthly || parking.monthly,
        total: ConvertNumberToString(fee.total + parking.total),
        feeId: fee.feeId,
        parkingId: parking.parkingId,
        yearly: fee.yearly || parking.yearly,
        feeDatePay: fee.datePay,
        parkingDatePay: parking.datePay,
        feeTotal: fee.total && ConvertNumberToString(fee.total),
        parkingTotal: parking.total && ConvertNumberToString(parking.total),
        houseNumber: fee.houseNumber || parking.houseNumber,
        datePay: fee.datePay || parking.datePay
    }
}

function compare2DatePeriod(dateObjA, dateObjB) {
    if (convertDateToMonth(dateObjA.yearly, dateObjA.monthly) < convertDateToMonth(dateObjB.yearly, dateObjB.monthly)) return -1;
    if (convertDateToMonth(dateObjA.yearly, dateObjA.monthly) === convertDateToMonth(dateObjB.yearly, dateObjB.monthly)) return 0;
    if (convertDateToMonth(dateObjA.yearly, dateObjA.monthly) > convertDateToMonth(dateObjB.yearly, dateObjB.monthly)) return 1;
}

function isBNewThanA(dateObjA, dateObjB) {
    return (convertDateToMonth(dateObjA.yearly, dateObjA.monthly) < convertDateToMonth(dateObjB.yearly, dateObjB.monthly))
}

function convertDateToMonth(year, month) {
    return (year * 12 + month)
}

function sortDesc(listRaw, isBBiggerThanA) {
    const list = listRaw.slice();
    let index = 1;
    const length = listRaw.length;
    while (index < length) {
        for (let i = 0; i < index; i++) {
            if (isBBiggerThanA(list[i], list[index])) {
                let save = list[index];
                list.splice(index, 1)
                list.splice(i, 0, save)
                break;
            }
        }
        index++;
    }
    return list;
}