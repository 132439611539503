/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile, isTablet } from "react-device-detect";
import { LoadScript } from "@react-google-maps/api";

import HeaderView from "../header/header.view.jsx";
import NavigationView from "../navigation/navigation.view.jsx";
import SliderView from "../slider/slider.view.jsx";
import Footer from "../footer/footer.view.jsx";
import WidgetView from "../widget/widget.view.jsx";
import FeedbackView from "../feedback/feedback.view.jsx";
import AuthenticationWarningDesktopView from "../authentication-warning/authentication-warning.view.jsx";
import AppLoadingStack from "../loading/loading-stack.view.jsx";
import ScrollToTopWhenPathNameChange from '../scroll-to-top-when-path-name-change/scroll-to-top-when-path-name-change.view';
import RedirectAuto from '../redirect-auto/redirect-auto.view';

import * as authActions from "core/auth.store";
import * as appActions from "core/app.store";
import * as projectActions from "redux/stores/project/project.store";
import * as advertisingActions from "redux/stores/advertising/advertising.store";

import ReactNotification from "react-notifications-component";
import ScrollToTop from "../scroll-to-top/scroll-to-top.jsx";
import { GoogleMapAPI } from "modules/gmap/googlemap.view";
import {
  isInitSubdomain,
  domainOrigin,
  TokenKey,
  setCookies,
  getCookies,
  removeListCookies,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";

import "react-notifications-component/dist/theme.css";
import "../react-notifications/_customTypes.scss";

const domainOriginList = domainOrigin.split(".");
const currentDomain = window.location.host;
const currentDomainList = window.location.host.split(".");
const currentProjectId = JwtHelper.currentProject();

class LayoutDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.isLoading || false,
      showMainContent: null,
      renderHeaderAgain: false,
    };

    this.onCheckSubDomain = this.onCheckSubDomain.bind(this);
    this.onGetProjectList = this.onGetProjectList.bind(this);
  }

  UNSAFE_componentWillMount() {
    //--- Get Advertising
    this.props.onGetListAdvertising();

    //--- Check domain
    if (isInitSubdomain) {
      this.onCheckSubDomain();
    } else if (!isMobile && !isTablet) {
      this.setState({
        showMainContent: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      isInitSubdomain &&
      getCookies(TokenKey.HostName) &&
      currentDomain != getCookies(TokenKey.HostName)
    ) {
      setCookies(TokenKey.HostName, currentDomain);
      window.location.reload();
    }
  }

  onCheckSubDomain = () => {
    if (!isMobile && !isTablet) {
      if (
        domainOriginList &&
        domainOriginList.length > 0 &&
        currentDomainList &&
        currentDomainList.length > 0 &&
        domainOriginList[0] != currentDomainList[0]
      ) {
        this.onGetProjectList();
      } else {
        removeListCookies([
          TokenKey.Project,
          TokenKey.ProjectName,
          TokenKey.AuthToken,
          TokenKey.AvatarUrl,
          TokenKey.Location,
          TokenKey.HostName,
        ]);
        this.forceUpdateHandler();
      }
      this.setState({
        showMainContent: true,
      });
    }
  };

  forceUpdateHandler() {
    this.setState({
      renderHeaderAgain: true,
    });
    this.props.logout();
  }

  onGetProjectList = () => {
    this.props.showLoading(true);
    this.setState({
      isLoading: true,
    });
    this.props.onGetProjectList().then(
      (res) => {
        if (res && res.content && res.content.length > 0) {
          let projectList = res.content;
          let project = projectList.filter((x) => x.domain == currentDomain);
          if (currentProjectId && currentProjectId > 0) {
            if (project && project.length > 0 && project[0].domain) {
              if (currentProjectId != project[0].clientId) {
                removeListCookies([
                  TokenKey.Project,
                  TokenKey.ProjectName,
                  TokenKey.AuthToken,
                  TokenKey.AvatarUrl,
                  TokenKey.Location,
                  TokenKey.HostName,
                ]);
                this.forceUpdateHandler();
              }

              setCookies(TokenKey.Project, project[0].clientId);
              setCookies(TokenKey.ProjectName, project[0].clientName);
              setCookies(TokenKey.HostName, currentDomain);
            } else {
              removeListCookies([
                TokenKey.Project,
                TokenKey.ProjectName,
                TokenKey.AuthToken,
                TokenKey.AvatarUrl,
                TokenKey.Location,
                TokenKey.HostName,
              ]);
              this.forceUpdateHandler();
            }
          } else {
            if (project && project.length > 0) {
              setCookies(TokenKey.Project, project[0].clientId);
              setCookies(TokenKey.ProjectName, project[0].clientName);
              setCookies(TokenKey.HostName, currentDomain);
            } else {
              this.forceUpdateHandler();
            }
          }
        }
        this.props.showLoading(false);
        this.setState({
          isLoading: false,
        });
      },
      (err) => {
        this.props.showLoading(false);
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  render() {
    if (isMobile || isTablet) return <RedirectAuto currentUrlIsForMobile={false} match={this.props.match} history={this.props.history} />
    let componentContent = this.props.children;
    if (this.props.needLogin && !this.props.isLoggedIn)
      componentContent = <AuthenticationWarningDesktopView />;
    if (this.state.isLoading) return null;
    return (
      <div className="page-container">
        <ReactNotification />
        <HeaderView renderHeaderAgain={this.state.renderHeaderAgain} />
        <NavigationView />
        <SliderView />
        <div className="main-content">
          <div className="container">
            <div className="row">
              <div
                className={"col-8" + (!this.props.isLoggedIn ? " mt-4" : "")}
              >
                <main>
                  {this.state.showMainContent && (
                    <LoadScript
                      id="script-loader"
                      googleMapsApiKey={GoogleMapAPI}
                      language="vi"
                    >
                      {componentContent}
                      <FeedbackView></FeedbackView>
                    </LoadScript>
                  )}
                </main>
              </div>
              <div
                className={"col-4" + (!this.props.isLoggedIn ? " mt-4" : "")}
              >
                <WidgetView />
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
        <Footer />
        <AppLoadingStack />
        <ScrollToTopWhenPathNameChange />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
  advertising: state.homeMobile.advertising,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProjectList: projectActions.GetProjectList,
      onGetListAdvertising: advertisingActions.GetListAdvertising,
      showLoading: appActions.ShowLoading,
      logout: authActions.Logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LayoutDesktop);
