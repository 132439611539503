import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Error, Success } from 'components/support-create-form/validate-style';
import AddNotification from 'components/react-notifications/react-notifications';
import * as viVN from 'translation/vi-VN.json';
import "../../advertising-desktop.scss";
import "../../resident-market/resident-market-desktop.scss";
import * as residentMarketActions from 'redux/stores/resident/resident-market.store';
import { NotificationMessageType, NewGuid, ValidImageTypes, convertTextareaToHtml, convertHtmlToTextarea, validateFile, CheckFileSize, CheckMaxFile, CheckFileTypeImage } from 'utils/configuration';
import { history } from 'redux/store';
import { JwtHelper } from "utils/jwt-helper";

function UpdateResidentMarketForm(props) {
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange"
  });

  const [previewActtachments, setPreviewActtachment] = useState(props.model.adFile || []);
  const [adFiles, setAdFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const [errorFile, setErrorFile] = useState({
    status: false,
    maxFile: "",
    maxSize: "",
    typeError: "",
  });

  let intialValues = {
    content: convertHtmlToTextarea(props.model.content),
  };

  const onSubmit = data => {
    if (!adFiles.length && !previewActtachments.length) {
      return;
    }
    
    data.ad_file = [...adFiles];
    data.delete_file = [...deleteFiles];
    data.content = convertTextareaToHtml(data.content);
    props.onUpdateResidentMarket && props.onUpdateResidentMarket(data);
    props.onCloseEditPopup && props.onCloseEditPopup(false);
  };

  const onFileChange = (event) => {
    let files = Array.from(event.target.files);
    if (files && files.length) {
      // validate maximum length
      var validateMsg = CheckMaxFile(adFiles.length + files.length);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      // validate file
      files.map(file => {
        if (validateFile(file)) {
          validateMsg = validateFile(file);
        }
      });
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage('');

      var previewImgs = [];
      var imgFiles = [];
      files.map(file => {
        var reader = new FileReader();
        reader.onload = (e) => {
          previewImgs = [...previewImgs, {
            fileUrl: e.target.result,
            fileName: file.name,
            fileId: NewGuid(),
            fileType: ValidImageTypes.includes(file.type) ? 'image' : file.type,
          }];
          setPreviewActtachment([...previewActtachments, ...previewImgs]);
        };
        reader.readAsDataURL(file);
        imgFiles = [...imgFiles, file];
        setAdFiles([...adFiles, ...imgFiles]);
      });
    }
  };

  // function onFileChange(e) {
  //   if (e.target.files) {
  //     const listFile = Array.from(e.target.files);
  //     let fileCount = adFiles.length;
  //     Promise.all(
  //       listFile.map((file) => {
  //         return new Promise((resolve, reject) => {
  //           if (CheckMaxFile(fileCount)) {
  //             setErrorFile({
  //               status: true,
  //               maxFile: CheckMaxFile(fileCount),
  //             });
  //             resolve({
  //               err: true,
  //             });
  //           } else if (
  //             !errorFile.maxFile &&
  //             !errorFile.maxSize &&
  //             CheckFileSize(file.size)
  //           ) {
  //             setErrorFile({ status: true, maxSize: CheckFileSize(file.size) });
  //             resolve({
  //               err: true,
  //             });
  //           } else if (
  //             !errorFile.maxFile &&
  //             !errorFile.typeError &&
  //             CheckFileTypeImage(file.type)
  //           ) {
  //             setErrorFile({
  //               status: true,
  //               typeError: CheckFileTypeImage(file.type),
  //             });
  //             resolve({
  //               err: true,
  //             });
  //           } else {
  //             const reader = new FileReader();
  //             if (file.type.match("image")) {
  //               fileCount++;
  //               reader.addEventListener("load", (ev) => {
  //                 resolve({
  //                   attactment_id: NewGuid(),
  //                   attachment_title: file.name,
  //                   attachment_url: ev.target.result,
  //                   file: file,
  //                   err: false,
  //                   attachment_type: "image",
  //                 });
  //               });
  //               reader.addEventListener("error", reject);
  //               reader.readAsDataURL(file);
  //             } else {
  //               setErrorFile({
  //                 status: true,
  //                 typeError: CheckFileTypeImage(file.type),
  //               });
  //               resolve({
  //                 err: true,
  //               });
  //             }
  //           }
  //         });
  //       })
  //     ).then(
  //       (images) => {
  //         let result = [];
  //         images.map((image) => {
  //           !image.err && result.push(image);
  //         });
  //         const fileResult = result.map(file => { return file.file });
  //         setAdFiles([...adFiles, ...fileResult]);
  //         setPreviewActtachment([...previewActtachments, ...result]);
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   }
  // }

  const onRemovedAttachment = (fileId) => {
    setPreviewActtachment(previewActtachments.filter(att => att.fileId != fileId));
    setDeleteFiles([...deleteFiles, fileId]);
  }

  return (
    <div className="advertising-desktop">
      <form className="form-add-resident-market" onSubmit={handleSubmit(onSubmit)} >
        <textarea defaultValue={intialValues.content} name="content" className="form-control" placeholder="Mô tả sản phẩm" rows="5"
          ref={register({
            required: 'Trường bắt buộc',
            minLength: {
              value: 6,
              message: "Nội dung phải có ít nhất 6 ký tự"
            }
          })} ></textarea>
        {errors.content && <Error content={errors.content.message} />}
        <div className="row">
          <div className="col-12 preview-img">
            {
              previewActtachments.map(att =>
                <div key={att.fileId} className="attachment-wrapper">
                  <img src={att.fileUrl} alt={att.fileName} className="img-attachment" />
                  <div className="overlay"></div>
                  <img onClick={(e) => onRemovedAttachment(att.fileId)} src={require("assets/images/close-white.svg")} alt="close-white" className="img-close-white" />
                </div>)
            }
          </div>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-6 file-upload">
            <div className="title-upload">
              <img src={require('assets/images/collections.png')} alt="Image" className="img-fluid" /> <span>Upload ảnh</span>
            </div>
            <input type="file" name="ad_file" accept="image/*" multiple className="media-file" onChange={onFileChange.bind(this)} ref={register({ required: { value: !previewActtachments.length, message: 'Trường bắt buộc' } })} />
            {errors.ad_file && <Error content={errors.ad_file.message} />}
            {validateImgMessage && <Error content={validateImgMessage} />}
            {/* {errorFile.status && (
              <div>
                <Error content={errorFile.maxFile} />
                {!errorFile.maxFile && errorFile.maxSize && <Error content={errorFile.maxSize} />}
                {!errorFile.maxFile && errorFile.typeError && <Error content={errorFile.typeError} />}
              </div>
            )} */}
          </div>
          <div className="col-6 text-right">
            <button type="submit" className="btn btn-success w-50">Lưu chỉnh sửa</button>
          </div>
        </div>
      </form>
    </div>
  );
}

class EditResidentMarketDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      redientMarketPost: null,
    }
  }

  UNSAFE_componentWillMount() {
    this.onGetResidentMarketPostDetail();
  }

  onGetResidentMarketPostDetail = () => {
    if (this.props.adId) {
      this.props.getResidentMarketDetail(this.props.adId).then(res => {
        if (res.content) {
          this.setState({
            redientMarketPost: res.content
          })
        } else {
          history.push('/rao-vat/cho-cu-dan');
        }
      }, err => { 
      })
    }
  }

  onUpdateResidentMarket = (data) => {
    let submitedData = {
      ...data,
      ad_id: this.props.adId,
      customer_id: this.state.currentUser.customerId,
      comments: this.state.redientMarketPost.comments
    }
    this.props.createOrUpdateResidentMarket(submitedData).then(res => {
      this.props.onToggleEditPopup(false);
      AddNotification('Cập nhật bài viết thành công!', NotificationMessageType.Success);
      this.props.callBack && this.props.callBack();
    }, err => { 
      this.props.onToggleEditPopup(false);
    })
  }

  render() {
    const { redientMarketPost } = this.state;

    return (
      <div className="resident-editting">
        {redientMarketPost && <UpdateResidentMarketForm onUpdateResidentMarket={this.onUpdateResidentMarket.bind(this)} model={redientMarketPost} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createOrUpdateResidentMarket: residentMarketActions.CreateOrUpdateResidentMarket,
  getResidentMarketDetail: residentMarketActions.GetResidentMarketDetail,
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(EditResidentMarketDesktopView)