import "../../safe-desktop.scss";
import React, { Component } from "react";
import Viewer, { Worker, SpecialZoomLevel } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RenderNotHaveDataImage } from 'components/warning-component/not-have-data';
import { renderError } from 'components/pdf-error-rendering/pdf-error-rendering';
import * as QualityDocumentStore from 'redux/stores/safe/quality-document.store';
import { PromiseAllTools } from 'core/app.store';

import { browserVersion, isSafari } from "react-device-detect";

if (isSafari === true && browserVersion <= 11) {
    require('intersection-observer');
}
class AirQualityDetailDesktopView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            notCallApi: true
        }
    }


    componentDidUpdate(){
        if (this.state.notCallApi&&(this.props.detail)) this.setState({
            notCallApi: false
        })
    }

    componentDidMount() {
        this.props.PromiseAllTools([this.props.GetQualityDocumentDetail(Number(this.props.match.params.airSafeId))])
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const data = this.props.detail || {};
        return (
            <div className="safe-desktop">
                <h2 className="title-lg">Xem chất lượng không khí</h2>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <div className="pdf-view-container">
                        {(data.haveData) ? <Viewer fileUrl={data.fileUrl} defaultScale={SpecialZoomLevel.PageFit} renderError={renderError}/> : <RenderNotHaveDataImage notCallApi={this.state.notCallApi}/>}
                    </div>
                </Worker>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    detail: state.qualityDocument.detail
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetQualityDocumentDetail: QualityDocumentStore.GetQualityDocumentDetail,
            PromiseAllTools
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AirQualityDetailDesktopView);
