/* eslint-disable no-unused-vars */
import { Map } from "immutable";
import { createFormData, AppKey } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();

export const RESIDENTMARKETKEYWORD = "CHO_CU_DAN";
export const GOTOMARKETKEYWORD = "DI_CHO_THUE";
export const HOURMAIDKEYWORD = "GIUP_VIEC_THEO_GIO";
export const RENTANDSALEHOUSEKEYWORD = "MUA_BAN_CHO_THUE_NHA";

export const POSITION_AD = {
  DESKTOP_SLIDER: "DESKTOP_SLIDER",
  DESKTOP_HOME_TOP: "DESKTOP_HOME_TOP",
  DESKTOP_BOTTOM: "DESKTOP_BOTTOM",
  DESKTOP_RIGHT_MENU: "DESKTOP_RIGHT_MENU",
  MOBILE_HOME_TOP: "MOBILE_HOME_TOP",
  MOBILE_HOME_BOTTOM: "MOBILE_HOME_BOTTOM",
};

/** Action Types */
const RESIDENTMARKETSAVELIST = "RESIDENTMARKET/SAVELIST";
const ADMOBILEHOMEPAGE = "RESIDENTMARKET/ADMOBILEHOMEPAGE";
const SAVE_ADVERTISING = "ADVERTISING/SAVE_ADVERTISING";

/** Actions */
export const createSaveAdMobileHomePage = (data) => ({
  type: ADMOBILEHOMEPAGE,
  payload: data,
});

export const createSaveResidentMarketList = (residentMarketModel) => ({
  type: RESIDENTMARKETSAVELIST,
  payload: {
    residentMarkets: residentMarketModel.residentMarkets,
    totalItems: residentMarketModel.totalItems,
  },
});

export const createSaveAdvertising = (data) => ({
  type: SAVE_ADVERTISING,
  payload: {
    advertising: data.items,
    totalItems: data.totalItemCount,
  },
});

/** Service */
export const GetAdMobileHomePageList = (pageIndex = 1, size = 5) => {
  return async (dispatch) => {
    let rawData = null;
    let formData = new FormData();
    formData.append("size", size);
    formData.append("pageIndex", pageIndex);
    let payload = createFormData(null, AppKey.GetPayloadPrefix, formData);
    await service
      .post(ApiUrl.AdListMobileHomePage, payload)
      .then((res) => {
        if (res.errorType === "EmptyDataError") return false;
        rawData = res.content.items;
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(createSaveAdMobileHomePage(ExchangeAdMobileHomePageData(rawData)));
  };
};

export const GetListAdvertising = (pageIndex = 1, size = 9999, filterObj) => {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    let payload = createFormData(filterObj, AppKey.GetPayloadPrefix, formData);
    await service
      .post(ApiUrl.GetListAdvertising, payload)
      .then((res) => {
        dispatch(createSaveAdvertising(res && res.content));
      })
      .catch((err) => {});
  };
};

// Initial state
export const initialState = Map({
  residentMarketList: [],
  totalItems: 0,
  adMobileHomePage: [],
}).toJS();

/** Reducers */
export default function AdvertisingReducer(state = initialState, action) {
  switch (action.type) {
    case RESIDENTMARKETSAVELIST:
      return {
        ...state,
        residentMarketList: action.payload.residentMarkets,
        totalItems: action.payload.totalItems,
      };
    case ADMOBILEHOMEPAGE:
      return {
        ...state,
        adMobileHomePage: action.payload,
      };
    case SAVE_ADVERTISING:
      return {
        ...state,
        advertising: action.payload.advertising,
      };
    default:
      return state;
  }
}

/* convert data */
function ExchangeAdMobileHomePageData(rawData) {
  // rawData is list items
  let starndardData = [];

  if (rawData) {
    rawData.map((data) => {
      starndardData.push({
        itemId: data.itemId,
        content: data.content,
        createDate: data.createDate,
        type: data.type,
        categoryType: data.categoryType,
      });
    });
  }

  return starndardData;
}
