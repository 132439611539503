//@ts-check
import React, { Component, } from 'react';
import { connect } from "react-redux";

class AppLoadingStack extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="pre-loading">
                {
                    (this.props.loadingStack.length > 0) && <div id="loading" className="loading-app">
                        <div className="canvas">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loadingStack: state.loadingStack.loadingStack,
})

export default connect(mapStateToProps, null)(AppLoadingStack);
