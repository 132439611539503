/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../my-shop/my-shop-mobile.scss";

class MyShopDesktopView extends Component {
  render() {
    const { shopList } = this.props;
    return (
      <div className="bg-white p-3">
        <div className="my-shop-mobile">
          <div className="my-shop-header">
            <div className="row">
              <div className="col-6">
                <img
                  src={require("assets/images/shop-blue.svg")}
                  alt="Shop Blue"
                  className="img-shop-blue"
                />
                Cửa hàng của tôi
              </div>
              <div className="col-6 text-right">
                <Link to="/m/dang-ky-ban-hang">
                  Đăng ký bán hàng
                  <img
                    src={require("assets/images/edit-blue.svg")}
                    alt="Register shop"
                    className="img-fluid img-edit-blue ml-1"
                  />
                </Link>
              </div>
            </div>
          </div>

          <hr />

          {shopList && shopList.length > 0 ? (
            <ul>
              {shopList.map((item, index) => (
                <li key={"shop" + index}>
                  <Link
                    to={`/m/shop/${item.type.toLocaleLowerCase()}/${
                      item.shopId
                    }`}
                  >
                    <img
                      src={item.image || require("assets/images/nha-hang.svg")}
                      alt={item.shopName}
                      className="img-fluid text-truncate"
                    />
                    {item.shopName}
                    {item.isActive && item.isActive.toString() === "0" && (
                      <span className="float-right">Chờ kích hoạt</span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              <li>Bạn chưa có cửa hàng nào</li>
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default MyShopDesktopView;
