
/* with :id for detail page - doducbinh . need refactor with config-redirect.js */

//--- utilities - desktop
export const UtilitiesSportHomeDesktop = '/tien-ich/the-thao';

export const UtilitiesSoccerDetailDeskTop = '/tien-ich/the-thao/san-bong/'
export const UtilitiesSoccerDeskTop = '/tien-ich/the-thao/san-bong'

export const UtilitiesTennisDetailDeskTop = '/tien-ich/the-thao/tennis/'
export const UtilitiesTennisDeskTop = '/tien-ich/the-thao/tennis'

export const UtilitiesGymDetailDeskTop = '/tien-ich/the-thao/gym/'
export const UtilitiesGymDeskTop = '/tien-ich/the-thao/gym'

export const UtilitiesPoolDetailDeskTop = '/tien-ich/the-thao/be-boi/'
export const UtilitiesPoolDeskTop = '/tien-ich/the-thao/be-boi'

export const UtilitiesSpaDeskTop = '/tien-ich/lam-dep';
export const UtilitiesSpaHomeDeskTop = '/tien-ich/tien-ich-lam-dep';
export const UtilitiesSpaDetailDeskTop = '/tien-ich/lam-dep/';

export const UtilitiesRestaurantHomeDeskTop = '/tien-ich/nha-hang';
export const UtilitiesRestaurantListPageDeskTop = '/tien-ich/danh-sach-nha-hang';
export const UtilitiesRestaurantDetailDeskTop = '/tien-ich/nha-hang/';

export const UtilitiesCafeHomeDeskTop = '/tien-ich/cafe';
export const UtilitiesCafeListPageDeskTop = '/tien-ich/danh-sach-cafe';
export const UtilitiesCafeDetailDeskTop = '/tien-ich/cafe/';

export const UtilitiesSchoolHomeDeskTop = '/tien-ich/truong-hoc';
export const UtilitiesSchoolListPageDeskTop = '/tien-ich/danh-sach-truong-hoc';
export const UtilitiesSchoolDetailDeskTop = '/tien-ich/truong-hoc/';

export const UtilitiesSuperMarketHomeDeskTop = '/tien-ich/sieu-thi';
export const UtilitiesSuperMarketListPageDeskTop = '/tien-ich/danh-sach-sieu-thi';
export const UtilitiesSuperMarketDetailDeskTop = '/tien-ich/sieu-thi/';

//--- utilities - mobile

export const UtilitiesSportHomeMobile = '/m/tien-ich/the-thao';
export const UtilitiesSportListPageMobile = '/m/tien-ich/the-thao/danh-sach';

export const UtilitiesSportGymDetailMobile = '/m/tien-ich/the-thao/gym/';

export const UtilitiesSportTennisDetailMobile = '/m/tien-ich/the-thao/tennis/';

export const UtilitiesSportPoolDetailMobile = '/m/tien-ich/the-thao/be-boi/';

export const UtilitiesSportSoccerDetailMobile = '/m/tien-ich/the-thao/bong-da/';

export const UtilitiesSpaHomeMobile = '/m/tien-ich/tien-ich-lam-dep';
export const UtilitiesSpaDetailMobile = '/m/tien-ich/lam-dep/';

export const UtilitiesCafeHomeMobile = '/m/tien-ich/cafe';
export const UtilitiesCafeDetailMobile = '/m/tien-ich/cafe/';

export const UtilitiesRestaurantHomeMobile = '/m/tien-ich/nha-hang';
export const UtilitiesRestaurantDetailMobile = '/m/tien-ich/nha-hang/';

export const UtilitiesSuperMarketDetailMobile = '/m/tien-ich/sieu-thi/';
export const UtilitiesSuperMarketHomeMobile = '/m/tien-ich/sieu-thi';

export const UtilitiesSchoolHomeMobile = '/m/tien-ich/truong-hoc';
export const UtilitiesSchoolDetailMobile = '/m/tien-ich/truong-hoc/';

export const UtilitiesListMobile = '/m/danh-sach-tien-ich';


