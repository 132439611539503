import { NewGuid, randomDate } from 'utils/configuration';

export const HourlyMaidList = [
    {
        hourly_maid_id: NewGuid(),
        created_by: 'Tài Nguyễn',
        created_by_id: 1,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        hourly_maid_content: `Cần giúp đưa vợ tôi đi đẻ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1250',
        contact_phone: '0923129278'
    },
    {
        hourly_maid_id: NewGuid(),
        created_by: 'Tài Nguyễn',
        created_by_id: 1,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        hourly_maid_content: `Mình ở S1 - 1102. Cần thuê người vệ sinh căn hộ từ 9h - 11h (07/03/2020) Ai làm được liên hệ mình nhé`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1250',
        contact_phone: '0923129278'
    },
    {
        hourly_maid_id: NewGuid(),
        created_by: 'Việt Nguyễn',
        created_by_id: 2,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        hourly_maid_content: `Cần giúp đưa vợ tôi đi đẻ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1250',
        contact_phone: '0923129278'
    },
    {
        hourly_maid_id: NewGuid(),
        created_by: 'Mão Nguyễn',
        created_by_id: 3,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        hourly_maid_content: `Cần giúp đưa vợ tôi đi đẻ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1250',
        contact_phone: '0923129278'
    },
    {
        hourly_maid_id: NewGuid(),
        created_by: 'Hậu Nguyễn',
        created_by_id: 4,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        hourly_maid_content: `Cần giúp đưa vợ tôi đi đẻ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1250',
        contact_phone: '0923129278'
    },
];
