const ADDSTACK = "LOADINGSTACK/ADD";
const POPSTACK = "LOADINGSTACK/POP";

export const AddOneStackToState = {
    type: ADDSTACK
}

export const PopOneStackToState = {
    type: POPSTACK
}

const InitState = {
    loadingStack: []
}

export default function LoadingStackReducer(state = InitState, action) {
    switch (action.type) {
        case ADDSTACK: {
            const NewStack = [...state.loadingStack, 1]
            return { ...state, loadingStack: NewStack }
        };
        case POPSTACK: {
            const NewStack = [...state.loadingStack]
            NewStack.pop();
            return { ...state, loadingStack: NewStack }
        };
        default: return state
    }
}