/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import Paper from "@material-ui/core/Paper";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  Toolbar,
  DateNavigator,
  TodayButton,
  ViewSwitcher,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
} from "@devexpress/dx-react-scheduler-material-ui";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "core/app.store";
import * as shopBbqActions from "redux/stores/shop/bbq.store";

import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  ConvertTimeToNumber,
} from "utils/configuration";

function getMondayAndSundayByCurrentDate(currentDate = new Date()) {
  let today, todayNumber, mondayNumber, sundayNumber, monday, sunday;

  today = currentDate || new Date();
  todayNumber = today.getDay();
  mondayNumber = 0 - todayNumber;
  sundayNumber = 6 - todayNumber;
  monday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + mondayNumber
  );
  sunday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + sundayNumber
  );

  return {
    monday: monday,
    sunday: sunday,
  };
}

class CalendarSchedulerBbqDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopId: this.props.shopId,
      shopType: this.props.type,

      data: [],
      currentDate: new Date(),

      dateSelected: new Date(),

      appointmentData: {},

      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,

      showAppointmentTooltip: false,
    };

    this.onShowAddModel = this.onShowAddModel.bind(this);
    this.onShowEditModel = this.onShowEditModel.bind(this);
    this.onShowDeleteModal = this.onShowDeleteModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    let { shopId, shopType } = this.state;
    if (shopId && shopType) this.onGetListSlot();
  }

  onGetListSlot = (date) => {
    this.props.showLoading(true);
    let { shopId, dateSelected } = this.state;
    this.setState({ dateSelected: date, currentDate: date });
    this.props
      .onGetListSlot({
        bbq_id: shopId,
        start_date: moment(
          getMondayAndSundayByCurrentDate(date || dateSelected).monday
        ).format("YYYY-MM-DD"),
        end_date: moment(
          getMondayAndSundayByCurrentDate(date || dateSelected).sunday
        ).format("YYYY-MM-DD"),
      })
      .then(
        (res) => {
          this.setState({
            data:
              res && res.content && res.content.length > 0
                ? res.content.map((item) => {
                    return {
                      id: item.slotId,
                      date: new Date(item.startDate),
                      startDate: new Date(item.startDate),
                      endDate: new Date(item.endDate),
                      status: item.isReady && item.isReady == 1 ? true : false,
                      description: item.description,
                    };
                  })
                : [],
          });
          this.props.showLoading(false);
        },
        (err) => {
          this.props.showLoading(false);
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  };

  onShowAddModel(appointmentData) {
    this.setState({ appointmentData: appointmentData, showAddModal: true });
  }

  onShowEditModel(appointmentData) {
    document.body.removeAttribute("style");
    this.setState({
      appointmentData: appointmentData,
      showEditModal: true,
    });
  }

  onShowDeleteModal(appointmentData) {
    document.getElementsByClassName("MuiPopover-root")[0].remove();
    document.body.removeAttribute("style");
    this.setState({
      appointmentData: appointmentData,
      showDeleteModal: true,
    });
  }

  onCreateCalendarScheduler(data) {
    this.props.showLoading(true);
    this.props
      .onAddOrUpdateSlot({
        bbq_id: this.state.shopId,
        slot_date: moment(data.date).format("YYYY-MM-DD"),
        from_hour: moment(data.startDate).format("HH:mm:ss"),
        to_hour: moment(data.endDate).format("HH:mm:ss"),
        description: data.description,
        is_ready: data.status ? 1 : 0,
      })
      .then(
        (res) => {
          AddNotification(
            "Tạo lịch thành công",
            NotificationMessageType.Success
          );
          this.setState({ showAddModal: false });
          this.onGetListSlot();
        },
        (err) => {
          this.props.showLoading(false);
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  }

  onEditCalendarScheduler(data) {
    this.props.showLoading(true);
    this.props
      .onAddOrUpdateSlot({
        bbq_id: this.state.shopId,
        slot_id: data.id,
        slot_date: moment(data.date).format("YYYY-MM-DD"),
        from_hour: moment(data.startDate).format("HH:mm:ss"),
        to_hour: moment(data.endDate).format("HH:mm:ss"),
        description: data.description,
        is_ready: data.status ? 1 : 0,
      })
      .then(
        (res) => {
          AddNotification(
            "Thay đổi thông tin lịch thành công",
            NotificationMessageType.Success
          );
          this.setState({ showEditModal: false });
          this.onGetListSlot();
        },
        (err) => {
          this.props.showLoading(false);
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  }

  onDeleteCalendarScheduler(data) {
    this.props.showLoading(true);
    this.props.onDeleteSlot(data.id).then(
      (res) => {
        AddNotification("Xóa lịch thành công", NotificationMessageType.Success);
        this.setState({ showDeleteModal: false });
        this.onGetListSlot();
      },
      (err) => {
        this.props.showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  }

  onSetCurrentDate = (date) => {
    this.onGetListSlot(date);
  };

  render() {
    const {
      currentDate,
      data,
      appointmentData,
      showAddModal,
      showEditModal,
      showDeleteModal,
    } = this.state;

    const Appointment = ({ children, style, ...restProps }) => (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor:
            restProps.data.status == true ? "#00b14f" : "#e33922",
          borderRadius: "6px",
        }}
      >
        {children}
      </Appointments.Appointment>
    );

    const AppointmentContent = ({ style, ...restProps }) => {
      return (
        <Appointments.AppointmentContent {...restProps}>
          <div>
            <strong>
              {moment(restProps.data.startDate).format("HH:mm")} -{" "}
              {moment(restProps.data.endDate).format("HH:mm")}
            </strong>
            <div>
              {restProps.data && restProps.data.status == true
                ? "Còn chỗ"
                : "Hết chỗ"}
            </div>
            <div>{restProps.data && restProps.data.description}</div>
          </div>
        </Appointments.AppointmentContent>
      );
    };

    return (
      <div>
        <Paper>
          <Scheduler locale={"vi-VN"} data={data}>
            <ViewState
              currentDate={moment(currentDate).format("YYYY-MM-DD")}
              defaultCurrentDate={moment(new Date()).format("YYYY-MM-DD")}
              onCurrentDateChange={this.onSetCurrentDate}
            />

            <EditingState onAddedAppointmentChange={this.onShowAddModel} />

            <WeekView displayName="Tuần" startDayHour={0} endDayHour={24} />

            <DayView displayName="Ngày" startDayHour={0} endDayHour={24} />

            <Toolbar />

            <DateNavigator />

            <TodayButton
              messages={{ today: "Hôm nay" }}
              buttonComponent={(props) =>
                ButtonComponent(props, this.onSetCurrentDate.bind(this))
              }
            />

            <ViewSwitcher />

            <Appointments
              appointmentComponent={Appointment}
              appointmentContentComponent={AppointmentContent}
            />

            <AppointmentTooltip
              showOpenButton
              showDeleteButton
              headerComponent={({ ...props }) =>
                AppointmentTooltipHeader({
                  ...props,
                  onShowEditModel: this.onShowEditModel.bind(this),
                  onShowDeleteModal: this.onShowDeleteModal.bind(this),
                })
              }
            />

            <AppointmentForm visible={false} />
          </Scheduler>
        </Paper>

        {showAddModal && (
          <CreateCalendarScheduler
            isShow={showAddModal}
            onHide={() => this.setState({ showAddModal: false })}
            appointmentData={appointmentData}
            submit={(data) => this.onCreateCalendarScheduler(data)}
            onSetData={(appointmentData) => this.setState({ appointmentData })}
          />
        )}

        {showEditModal && (
          <EditCalendarScheduler
            isShow={showEditModal}
            onHide={() => this.setState({ showEditModal: false })}
            appointmentData={appointmentData}
            submit={(data) => this.onEditCalendarScheduler(data)}
            onSetData={(appointmentData) => this.setState({ appointmentData })}
          />
        )}

        {showDeleteModal && (
          <DeleteCalendarScheduler
            isShow={showDeleteModal}
            onHide={() => this.setState({ showDeleteModal: false })}
            appointmentData={appointmentData}
            submit={(data) => this.onDeleteCalendarScheduler(data)}
            onSetData={(appointmentData) => this.setState({ appointmentData })}
          />
        )}
      </div>
    );
  }
}

const ButtonComponent = (props, onSetCurrentDate) => {
  return (
    <TodayButton.Button
      {...props}
      setCurrentDate={onSetCurrentDate}
    ></TodayButton.Button>
  );
};

const AppointmentTooltipHeader = (props) => {
  return (
    <AppointmentTooltip.Header
      {...props}
      onOpenButtonClick={() => props.onShowEditModel(props.appointmentData)}
      onDeleteButtonClick={() => props.onShowDeleteModal(props.appointmentData)}
    ></AppointmentTooltip.Header>
  );
};

function CreateCalendarScheduler(props) {
  const { isShow, onHide, appointmentData, submit, onSetData } = props;

  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [errorTime, setErrorTime] = useState(false);

  //--- Validate for open - close time
  const validateTime = (openTime, closeTime) => {
    ConvertTimeToNumber(openTime) < ConvertTimeToNumber(closeTime)
      ? setErrorTime(false)
      : setErrorTime(true);
  };

  const onSubmit = (data) => {
    if (errorTime) return;
    submit({
      startDate: appointmentData.startDate,
      endDate: appointmentData.endDate,
      status: data.isYard,
      description: data.description,
      date: appointmentData.date || appointmentData.startDate,
    });
  };

  return (
    <Modal show={isShow} onHide={onHide} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Tạo lịch cho
            <DatePicker
              name="date"
              selected={appointmentData.date || appointmentData.startDate}
              onChange={(date) =>
                date &&
                onSetData({
                  ...appointmentData,
                  date: date,
                })
              }
              dateFormat="dd/MM/yyyy"
              className="ml-2 form-control"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-6">
              <div className="row">
                <label className="col-sm-2 col-form-label">Từ:</label>
                <div className="col-sm-10">
                  <DatePicker
                    name="openTime"
                    selected={appointmentData.startDate}
                    onChange={(date) => {
                      date &&
                        onSetData({
                          ...appointmentData,
                          startDate: date,
                        });
                      date && validateTime(date, appointmentData.endDate);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Đến"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    className={"form-control"}
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="row">
                <label className="col-sm-2 col-form-label">Đến:</label>
                <div className="col-sm-10">
                  <DatePicker
                    name="closeTime"
                    selected={appointmentData.endDate}
                    onChange={(date) => {
                      date &&
                        onSetData({
                          ...appointmentData,
                          endDate: date,
                        });
                      date && validateTime(appointmentData.startDate, date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Đến"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    className={"form-control"}
                  />
                </div>
              </div>
            </div>
          </div>

          {errorTime && (
            <div className="col-12 form-group">
              <span className="invalid-feedback">
                Thời gian kết thúc phải lớn hơn thời gian bắt đầu
              </span>
            </div>
          )}

          <div className="form-group">
            <Form.Group controlId="addUrgencyCheckbox">
              <Form.Check
                custom
                label="Còn sân"
                type="checkbox"
                name="isYard"
                defaultChecked={appointmentData.status}
                ref={register()}
              />
            </Form.Group>
          </div>

          <div className="form-group">
            <label>Mô tả:</label>
            <textarea
              name="description"
              className={"form-control"}
              rows="6"
              defaultValue={appointmentData.description}
              ref={register()}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group">
            <a className="btn btn-secondary text-uppercase" onClick={onHide}>
              Hủy
            </a>
            <button
              type="submit"
              className="btn btn-success text-uppercase ml-2"
            >
              Tạo
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function EditCalendarScheduler(props) {
  const { isShow, onHide, appointmentData, submit, onSetData } = props;

  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [errorTime, setErrorTime] = useState(false);

  //--- Validate for open - close time
  const validateTime = (openTime, closeTime) => {
    ConvertTimeToNumber(openTime) < ConvertTimeToNumber(closeTime)
      ? setErrorTime(false)
      : setErrorTime(true);
  };

  const onSubmit = (data) => {
    if (errorTime) return;
    submit({
      ...appointmentData,
      startDate: appointmentData.startDate,
      endDate: appointmentData.endDate,
      status: data.isYard,
      description: data.description,
      date: appointmentData.date,
    });
  };

  return (
    <Modal show={isShow} onHide={onHide} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Sửa lịch cho{" "}
            <DatePicker
              name="date"
              selected={appointmentData.date || appointmentData.startDate}
              onChange={(date) =>
                date &&
                onSetData({
                  ...appointmentData,
                  date: date,
                })
              }
              dateFormat="dd/MM/yyyy"
              disabled
              className="ml-2 form-control"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-6">
              <div className="row">
                <label className="col-sm-2 col-form-label">Từ:</label>
                <div className="col-sm-10">
                  <DatePicker
                    name="openTime"
                    selected={appointmentData.startDate}
                    onChange={(date) => {
                      date &&
                        onSetData({
                          ...appointmentData,
                          startDate: date,
                        });
                      date && validateTime(date, appointmentData.endDate);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Đến"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    className={"form-control"}
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="row">
                <label className="col-sm-2 col-form-label">Đến:</label>
                <div className="col-sm-10">
                  <DatePicker
                    name="closeTime"
                    selected={appointmentData.endDate}
                    onChange={(date) => {
                      date &&
                        onSetData({
                          ...appointmentData,
                          endDate: date,
                        });
                      date && validateTime(appointmentData.startDate, date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Đến"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    className={"form-control"}
                  />
                </div>
              </div>
            </div>
          </div>

          {errorTime && (
            <div className="col-12 form-group">
              <span className="invalid-feedback">
                Thời gian kết thúc phải lớn hơn thời gian bắt đầu
              </span>
            </div>
          )}

          <div className="form-group">
            <Form.Group controlId="addUrgencyCheckbox">
              <Form.Check
                custom
                label="Còn sân"
                type="checkbox"
                name="isYard"
                defaultChecked={appointmentData.status}
                ref={register()}
              />
            </Form.Group>
          </div>

          <div className="form-group">
            <label>Mô tả:</label>
            <textarea
              name="description"
              className={"form-control"}
              rows="6"
              defaultValue={appointmentData.description}
              ref={register()}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group">
            <a className="btn btn-secondary text-uppercase" onClick={onHide}>
              Hủy
            </a>
            <button
              type="submit"
              className="btn btn-success text-uppercase ml-2"
            >
              Sửa
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function DeleteCalendarScheduler(props) {
  const { isShow, onHide, appointmentData, submit } = props;

  const { handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    submit(appointmentData);
  };

  return (
    <Modal show={isShow} onHide={onHide} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Xóa lịch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">Bạn có muốn xóa không?</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group">
            <a className="btn btn-secondary text-uppercase" onClick={onHide}>
              Hủy
            </a>
            <button
              type="submit"
              className="btn btn-danger text-uppercase ml-2"
            >
              Xóa
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      onGetListSlot: shopBbqActions.GetCalendarSlots,
      onAddOrUpdateSlot: shopBbqActions.AddOrUpdateCalendarSlot,
      onDeleteSlot: shopBbqActions.DeleteCalendarSlot,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarSchedulerBbqDesktopView);
