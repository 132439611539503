import { NewGuid, randomDate } from 'utils/configuration';

export const HelpingHandList = [
    {
        help_post_id: NewGuid(),
        created_by: 'Tài Nguyễn',
        is_urgency: true,
        created_by_id: 1,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        help_post_content: `Cần giúp đưa vợ tôi đi đẻ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1250',
        attachments: [
           
        ],
        comments: [
            {
                comment_id: NewGuid(),
                comment_content: 'Tôi sẽ đến ngay',
                comment_by: 'Việt Nguyễn',
                comment_by_id: 2,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2020, 0, 1), new Date()),
            },
        ],
    },
    {
        help_post_id: NewGuid(),
        created_by: 'Việt Nguyễn',
        is_urgency: true,
        created_by_id: 2,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        help_post_content: `Cần giúp thịt chó`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1205',
        attachments: [
           
        ],
        comments: [
            {
                comment_id: NewGuid(),
                comment_content: 'Tôi sẽ đến ngay',
                comment_by: 'Mão Nguyễn',
                comment_by_id: 3,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2020, 0, 1), new Date()),
            },
            {
                comment_id: NewGuid(),
                comment_content: 'Xong thì gọi nhé',
                comment_by: 'Tài Nguyễn',
                comment_by_id: 1,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2020, 0, 1), new Date()),
            },
        ],
    },
    {
        help_post_id: NewGuid(),
        created_by: 'Mão Nguyễn',
        is_urgency: true,
        created_by_id: 3,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        help_post_content: `Cần giúp chia ba kích`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        creator_room_name: 'S3-1210',
        attachments: [
           
        ],
        comments: [
            {
                comment_id: NewGuid(),
                comment_content: 'Tôi sẽ đến ngay',
                comment_by: 'Việt Nguyễn',
                comment_by_id: 2,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2020, 0, 1), new Date()),
            },
        ],
    },
];
