import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "../../advertising-desktop.scss";
import * as residentMarketActions from 'redux/stores/resident/resident-market.store';
import AddNotification from 'components/react-notifications/react-notifications';
import * as viVN from 'translation/vi-VN.json';
import { NotificationMessageType } from 'utils/configuration';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import ResidentMarketAttachmentDesktopView from './resident-market-attachment.view';
import ResidentMarketCommentDesktopView from './resident-market-comment.view';
import EditResidentMarketDesktopView from '../resident-market-actions/edit-resident-market-desktop.view';
import "../../advertising-desktop.scss";
import { SetDefaultErrorImage } from "utils/configuration";
import { history } from 'redux/store';
import { JwtHelper } from "utils/jwt-helper";
import { Link } from 'react-router-dom';

class ResidentMarketDetailDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: JwtHelper.currentUser(),
            redientMarketPost: null,
            residentMarketId: this.props.match.params.residentMarketId,
        }
    }

    UNSAFE_componentWillMount() {
        this.onGetResidentMarketPostDetail();
    }

    onGetResidentMarketPostDetail = () => {
        if (this.state.residentMarketId) {
            this.props.getResidentMarketDetail(this.state.residentMarketId).then(res => {
                if (res.content) {
                    this.setState({
                        redientMarketPost: res.content
                    })
                } else {
                    history.push('/rao-vat/cho-cu-dan');
                }
            }, err => {
            })
        }
    }

    onToggleEditPopup = (isOpen, post_id) => {
        this.setState({
            isOpenEditPopup: isOpen,
            selectedPostId: post_id
        })
    }

    onToggleDeletePopup = (isOpen, post_id) => {
        this.setState({
            isOpenDeletePopup: isOpen,
            selectedPostId: post_id
        })
    }

    onDeleteResidentMarketPost = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteResidentMarket(this.state.selectedPostId).then(res => {
                this.onToggleDeletePopup(false);
                AddNotification('Xoá bài viết thành công!', NotificationMessageType.Success);
            }, err => {
                this.onToggleDeletePopup(false);
                //TODO: show err message
            })
        }
    }

    render() {
        const { loggedIn } = this.props;
        const { redientMarketPost, isOpenDeletePopup, isOpenEditPopup, currentUser, selectedPostId } = this.state;
        return (
            <div className="resident-market resident-market-container">
                {redientMarketPost && <div key={redientMarketPost.adId} className="item bg-white p-3">
                    <div className="row no-gutters">
                        <div className="col-1">
                            <img src={redientMarketPost.profileUrl || require('assets/images/29-01.png')} onError={SetDefaultErrorImage} className="img-fluid img-avatar" alt="Avatar" />
                        </div>
                        <div className="col-10 pl-3">
                            <h5 className="fullname mb-0 mt-1"><Link to={`/rao-vat/cho-cu-dan/chi-tiet/${redientMarketPost.adId}`}> {redientMarketPost.customerName}</Link></h5>
                            <span className="datetime">{moment(redientMarketPost.createDate).fromNow()}</span>
                        </div>
                        {loggedIn && redientMarketPost.customerId == currentUser.customerId && <div className="col-1 text-right">
                            <Dropdown alignRight>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <img src={require('assets/images/more.svg')} className="img-fluid img-more" alt="more" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.onToggleEditPopup(true, redientMarketPost.adId)}>
                                        <img src={require('assets/images/pen.svg')} className="img-fluid img-pen" alt="pen" />
                                        <span>Chỉnh sửa bài viết</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.onToggleDeletePopup(true, redientMarketPost.adId)}>
                                        <img src={require('assets/images/delete.svg')} className="img-fluid img-delete" alt="pen" />
                                        <span>Xóa</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}
                    </div>
                    <p className="description" dangerouslySetInnerHTML={{ __html: redientMarketPost.content }}></p>
                    {/* Render images */}
                    <ResidentMarketAttachmentDesktopView attachments={redientMarketPost.adFile || []} adId={redientMarketPost.adId} />

                    <div className="comments">
                        {/* Comments */}
                        <ResidentMarketCommentDesktopView comments={redientMarketPost.comments || []} adId={redientMarketPost.adId} />
                    </div>
                </div>}
                <Modal show={isOpenEditPopup} className="modal-edit" centered size="lg" onHide={() => this.onToggleEditPopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chỉnh sửa bài viết</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditResidentMarketDesktopView postId={selectedPostId} onToggleEditPopup={this.onToggleEditPopup.bind(this)} callBack={this.onGetResidentMarketPostDetail.bind(this)} />
                    </Modal.Body>
                </Modal>

                <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xóa bài viết?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Bài viết sẽ không thể khôi phục sau khi xóa.
                        <br />
                        Bạn có chắc muốn xóa bài viết này.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                        <Button variant="success" onClick={() => this.onDeleteResidentMarketPost(true)}>Xóa</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getResidentMarketDetail: residentMarketActions.GetResidentMarketDetail,
    deleteResidentMarket: residentMarketActions.DeleteResidentMarket,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ResidentMarketDetailDesktopView)