import { createSaveLoading } from 'core/app.store';
import { Map } from 'immutable';
import Service from 'services/api.service';
import { createFormData, AppKey } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';

const QUALITYDOCUMENTDETAIL = "QUALITYDOCUMENT/INITDETAIL";
const QUALITYDOCUMENTLIST = "QUALITYDOCUMENT/INITLIST";
const AIRFOLDERID = 2;
const WATERFOLDERID = 1;

const service = new Service();

const InitDetail = data => ({
    type: QUALITYDOCUMENTDETAIL, data: data
})

const InitList = data => ({
    type: QUALITYDOCUMENTLIST, data: data
})

export const GetAirDocumentList = (pageIndex = 1, size = 10) => {
   return GetQualityDocumentList(pageIndex, size, AIRFOLDERID);
}

export const GetWaterDocumentList = (pageIndex = 1, size = 10) => {
    return GetQualityDocumentList(pageIndex, size, WATERFOLDERID);
 }

export const GetQualityDocumentDetail = (qualityId) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true))
        let rawData = null;
        let formData = new FormData();
        formData.append(`qualityId`, qualityId);
        let payload = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.GetDetailQuality, payload).then(res => {
            if (res.errorType === "EmptyDataError") return false
            rawData = res.content;
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitDetail(ExchangeQualityDetail(rawData)))
    }
}


export const GetQualityDocumentList = (pageIndex, size, FOLDERID) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true))
        let rawData = null;
        let formData = new FormData();
        formData.append(`filter[orderBy]`, "update_date");
        formData.append(`filter[orderType]`, "DESC");
        formData.append(`filter[folder_id]`, FOLDERID)
        formData.append('size', size);
        formData.append('pageIndex', pageIndex);
        let payload = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.GetListQuality, payload).then(res => {
            if (res.errorType === "EmptyDataError") return false
            rawData = res.content;
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitList(ExchangeQualityDataList(rawData)))
    }
}

const initailState = Map({
    detail: {},
    list: {}
})

export default function QualityDocumentReducer(state = initailState, action) {
    switch (action.type) {
        case QUALITYDOCUMENTLIST:
            return {
                ...state,
                list: action.data
            }
        case QUALITYDOCUMENTDETAIL:
            return {
                ...state,
                detail: action.data
            }
        default:
            return state
    }
}

export function ExchangeQualityDetail(rawData) {
    const standartData = {
        haveData: false
    }

    if (rawData) {
        standartData.haveData = true;
        standartData.id = rawData.id;
        standartData.documentName = rawData.documentName || "Không có dữ liệu";
        standartData.fileUrl = rawData.fileUrl;
        standartData.description = rawData.description;
        standartData.content = rawData.content;
    }

    return standartData
}

export function ExchangeQualityDataList(rawData) {
    const standartData = {
        haveData: false,
        data: []
    }

    if (rawData) {
        standartData.totalItems = rawData.totalItemCount;
        standartData.pageSize = rawData.pageSize;
        standartData.pageCount = rawData.pageCount
        standartData.haveData = true;
        rawData.items.map((data) => {
            standartData.data.push({
                documentName: data.documentName || "Không có dữ liệu",
                description: data.description,
                content: data.content,
                fileUrl: data.fileUrl,
                id: data.id
            })
        })
    }

    return standartData
}