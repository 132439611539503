import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../safe-mobile.scss";
import { RenderNotHaveDataText } from 'components/warning-component/not-have-data';
import Pagination from 'components/pagination/paging.view';
import * as QualityDocumentStore from 'redux/stores/safe/quality-document.store';
import { PromiseAllTools } from 'core/app.store';
class AirQualityMobileView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notCallApi: true
        }
    }

    componentDidUpdate() {
        if (this.state.notCallApi && this.props.list && this.props.list.data) this.setState({
            notCallApi: false
        })
    }

    componentDidMount() {
        this.props.PromiseAllTools([this.props.GetAirDocumentList()])
    }

    gotoPage(i) {
        this.props.PromiseAllTools([this.props.GetAirDocumentList(i)])
    }

    render() {
        const listData = this.props.list ? this.props.list.data : [];
        const totalItems = this.props.list ? this.props.list.totalItems : 1;
        const pageSize = this.props.list ? this.props.list.pageSize : 10;

        return (
            <div className="air-quality-mobile">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/an-toan">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">An toàn</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="air-quality-header">
                    <img src={require('assets/images/cloud-blue-sky.jpg')} alt="Image" className="img-fluid" />
                    <div className="overlay"></div>
                    <span>Xem chất lượng không khí</span>
                </div>

                <div className="air-quality-list pl-3 pr-3">
                    <div className="row align-items-center">
                        {(listData.length > 0) ? listData.map((data, index) => <AirQuality data={data} key={index} />) : <RenderNotHaveDataText notCallApi={this.state.notCallApi} />}
                    </div>
                </div>
                {(listData.length > 0) && <Pagination totalItems={totalItems} size={pageSize} goTo={(i) => this.gotoPage(i)} />}
            </div>
        );
    }
}

const AirQuality = (props) => {
    const data = props.data

    return (
        <div className="col-12">
            <div className="air-quality-item">
                <div className="row no-gutters">
                    <div className="col-11">
                        <Link to={`/m/an-toan/chat-luong-khong-khi/chi-tiet/${data.id}`}>{data.documentName}</Link>
                    </div>
                    <div className="col-1 text-right">
                        <Link to={`/m/an-toan/chat-luong-khong-khi/chi-tiet/${data.id}`}>
                            <img src={require("assets/images/keyboard-arrow-right.svg")} alt="Image" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    list: state.qualityDocument.list
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetAirDocumentList: QualityDocumentStore.GetAirDocumentList,
            PromiseAllTools
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AirQualityMobileView);
