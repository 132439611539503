/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shopActions from "redux/stores/shop/shop.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { NotificationMessageType } from "utils/configuration";
import { LoadScript } from "@react-google-maps/api";
import { GoogleMapAPI } from "modules/gmap/googlemap.view";
import { JwtHelper } from "utils/jwt-helper";
import { history } from "redux/store";

import "../../user-desktop/user-desktop.scss";
import "../user-shop/user-shop-desktop.scss";

import BookingView from "./booking/booking-desktop.view";
import BookingTennisView from "./booking/tennis-desktop.view";
import BookingBbqView from "./booking/bbq-desktop.view";
import BookingBeautyView from "./booking/beauty-desktop.view";
import BookingClinicView from "./booking/clinic-desktop.view";
import BookingCommunityView from "./booking/community-desktop.view";
import BookingFootballView from "./booking/football-desktop.view";
import BookingGarageView from "./booking/garage-desktop.view";
import BookingGymView from "./booking/gym-desktop.view";
import BookingOfficeView from "./booking/office-desktop.view";
import BookingPoolView from "./booking/pool-desktop.view";

import CalendarSchedulerDesktopView from "./calendar-scheduler/calendar-scheduler-desktop.view";
import CalendarSchedulerTennisDesktopView from "./calendar-scheduler/calendar-scheduler-tennis-desktop.view";
import CalendarSchedulerBbqDesktopView from "./calendar-scheduler/calendar-scheduler-bbq-desktop.view";
import CalendarSchedulerBeautyDesktopView from "./calendar-scheduler/calendar-scheduler-beauty-desktop.view";
import CalendarSchedulerClinicDesktopView from "./calendar-scheduler/calendar-scheduler-clinic-desktop.view";
import CalendarSchedulerCommunityDesktopView from "./calendar-scheduler/calendar-scheduler-community-desktop.view";
import CalendarSchedulerFootballDesktopView from "./calendar-scheduler/calendar-scheduler-football-desktop.view";
import CalendarSchedulerGarageDesktopView from "./calendar-scheduler/calendar-scheduler-garage-desktop.view";
import CalendarSchedulerGymDesktopView from "./calendar-scheduler/calendar-scheduler-gym-desktop.view";
import CalendarSchedulerOfficeDesktopView from "./calendar-scheduler/calendar-scheduler-office-desktop.view";
import CalendarSchedulerPoolDesktopView from "./calendar-scheduler/calendar-scheduler-pool-desktop.view";

import ShopInformation from "./information/shop-information-desktop.view";
import PromotionDesktopView from "./promotion/promotion-desktop.view";
import EditPromotion from "./promotion/edit-promotion/edit-promotion-desktop.view";

const screen = {
  main: 1,
  information: 2,
  promotion: 3,
  editPromotion: 4,
  setupCalendar: 5,
};

function RenderBookingView(props) {
  switch (props.type) {
    case shopActions.SHOP_TYPE.CAFE:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.NHA_HANG:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.SIEU_THI:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.TRUONG_HOC:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.TENNIS:
      return <BookingTennisView {...props} />;
    case shopActions.SHOP_TYPE.POOL:
      return <BookingPoolView {...props} />;
    case shopActions.SHOP_TYPE.FOOTBALL:
      return <BookingFootballView {...props} />;
    case shopActions.SHOP_TYPE.BBQ:
      return <BookingBbqView {...props} />;
    case shopActions.SHOP_TYPE.BEAUTY:
      return <BookingBeautyView {...props} />;
    case shopActions.SHOP_TYPE.OFFICE:
      return <BookingOfficeView {...props} />;
    case shopActions.SHOP_TYPE.GARAGE:
      return <BookingGarageView {...props} />;
    case shopActions.SHOP_TYPE.COMMUNITY:
      return <BookingCommunityView {...props} />;
    case shopActions.SHOP_TYPE.GYM:
      return <BookingGymView {...props} />;
    case shopActions.SHOP_TYPE.CLINIC:
      return <BookingClinicView {...props} />;
    default:
      return null;
  }
}

function RenderCalendarSchedulerView(props) {
  switch (props.type) {
    case shopActions.SHOP_TYPE.CAFE:
      return <CalendarSchedulerDesktopView {...props} />;
    case shopActions.SHOP_TYPE.NHA_HANG:
      return <CalendarSchedulerDesktopView {...props} />;
    case shopActions.SHOP_TYPE.SIEU_THI:
      return <CalendarSchedulerDesktopView {...props} />;
    case shopActions.SHOP_TYPE.TRUONG_HOC:
      return <CalendarSchedulerDesktopView {...props} />;
    case shopActions.SHOP_TYPE.TENNIS:
      return <CalendarSchedulerTennisDesktopView {...props} />;
    case shopActions.SHOP_TYPE.POOL:
      return <CalendarSchedulerPoolDesktopView {...props} />;
    case shopActions.SHOP_TYPE.FOOTBALL:
      return <CalendarSchedulerFootballDesktopView {...props} />;
    case shopActions.SHOP_TYPE.BBQ:
      return <CalendarSchedulerBbqDesktopView {...props} />;
    case shopActions.SHOP_TYPE.BEAUTY:
      return <CalendarSchedulerBeautyDesktopView {...props} />;
    case shopActions.SHOP_TYPE.OFFICE:
      return <CalendarSchedulerOfficeDesktopView {...props} />;
    case shopActions.SHOP_TYPE.GARAGE:
      return <CalendarSchedulerGarageDesktopView {...props} />;
    case shopActions.SHOP_TYPE.COMMUNITY:
      return <CalendarSchedulerCommunityDesktopView {...props} />;
    case shopActions.SHOP_TYPE.GYM:
      return <CalendarSchedulerGymDesktopView {...props} />;
    case shopActions.SHOP_TYPE.CLINIC:
      return <CalendarSchedulerClinicDesktopView {...props} />;
    default:
      return null;
  }
}

class UserShopDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shopId: this.props.match.params.id,
      shopType:
        (this.props.match.params.type &&
          this.props.match.params.type.toUpperCase()) ||
        "",

      isShopMain: this.props.isShopMain || true,
      isShopInformation: this.props.isShopInformation || false,
      isShopPromotion: this.props.isShopPromotion || false,
      isEditPromotion: this.props.isEditPromotion || false,
      isShopSetupCalendar: this.props.isShopSetupCalendar || false,

      shop: null,
      hotId: null,
    };
  }

  componentDidMount() {
    let { shopId, shopType } = this.state;
    if (shopId && shopType) this.onGetShopInformation(shopId, shopType);
    else history.push("/thong-tin-ca-nhan");
  }

  getCurrentShopInformation = () => {
    let { shopId, shopType } = this.state;
    if (shopId && shopType) this.onGetShopInformation(shopId, shopType);
  }

  refreshPage = () => {
    this.getCurrentShopInformation();
    this.setState({
      isShopMain: true,
      isShopInformation: false,
      isShopPromotion: false,
      isEditPromotion: false,
      isShopSetupCalendar: false,
    })
    window.scrollTo(0, 0)
  }

  onGetShopInformation = (shopId, shopType) => {
    this.props.onGetShopDetail(shopId, shopType).then(
      (res) => {
        if (!res) {
          AddNotification(
            viVN.Errors[(res && res.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
          history.push("/thong-tin-ca-nhan");
          return;
        }
        this.setState({
          shop: res,
        });
        if (!res.isBooking || res.isBooking == 0)
          this.onShowScreen(screen.information);
      },
      (err) => {
        history.push("/thong-tin-ca-nhan");
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onShowScreen = (key, id) => {
    switch (key) {
      case screen.isShopMain:
        this.setState({
          isShopMain: true,
          isShopInformation: false,
          isShopPromotion: false,
          isEditPromotion: false,
          isShopSetupCalendar: false,
        });
        break;
      case screen.information:
        this.setState({
          isShopMain: false,
          isShopInformation: true,
          isShopPromotion: false,
          isEditPromotion: false,
          isShopSetupCalendar: false,
        });
        break;
      case screen.promotion:
        this.setState({
          isShopMain: false,
          isShopInformation: false,
          isShopPromotion: true,
          isEditPromotion: false,
          isShopSetupCalendar: false,
        });
        break;
      case screen.editPromotion:
        this.setState({
          isShopMain: false,
          isShopInformation: false,
          isShopPromotion: false,
          isEditPromotion: true,
          isShopSetupCalendar: false,
          hotId: id || null,
        });
        break;
      case screen.setupCalendar:
        this.setState({
          isShopMain: false,
          isShopInformation: false,
          isShopPromotion: false,
          isEditPromotion: false,
          isShopSetupCalendar: true,
        });
        break;
      default:
        this.setState({
          isShopMain: true,
          isShopInformation: false,
          isShopPromotion: false,
          isEditPromotion: false,
          isShopSetupCalendar: false,
        });
        break;
    }
  };

  render() {
    const state = this.state;
    const userInfo = JwtHelper.currentUser();
    return (
      <div className="user-desktop">
        {state.shop && (
          <div>
            <div className="bg-white p-3 mt-4 user-shop-header">
              <div className="row align-items-center no-gutters">
                <div className="col-1">
                  <img
                    src={
                      state.shop.logoUrl ||
                      require("assets/images/highlight-restaurant-avatar.png")
                    }
                    className="img-avatar"
                    alt={state.shop.shopName}
                    onClick={() =>
                      state.shop.isBooking &&
                      state.shop.isBooking == 1 &&
                      this.onShowScreen(screen.isShopMain)
                    }
                  />
                </div>
                <div className="col-4 pl-3">
                  <h5
                    className="title-md mb-0"
                    onClick={() =>
                      state.shop.isBooking &&
                      state.shop.isBooking == 1 &&
                      this.onShowScreen(screen.isShopMain)
                    }
                  >
                    {state.shop.shopName}
                  </h5>
                </div>
                <div className="col-7 text-right">
                  {state.shop.hasSlots && state.shop.hasSlots == 1 && (
                    <a
                      onClick={() => this.onShowScreen(screen.setupCalendar)}
                      className={state.isShopSetupCalendar ? "active" : ""}
                    >
                      <img
                        src={require("assets/images/calendar-blue.svg")}
                        className="img-fluid img-setup-calendar"
                        alt="Calendar"
                      />
                      Setup lịch sân
                    </a>
                  )}
                  <a
                    onClick={() => this.onShowScreen(screen.information)}
                    className={
                      "ml-4" + (state.isShopInformation ? " active" : "")
                    }
                  >
                    <img
                      src={require("assets/images/edit-blue.svg")}
                      className="img-fluid"
                      alt="Edit"
                    />
                    Thông tin gian hàng
                  </a>
                  <a
                    onClick={() => this.onShowScreen(screen.promotion)}
                    className={
                      "ml-4 btn btn-warning" +
                      (state.isShopPromotion ? " active" : "")
                    }
                  >
                    <img
                      src={require("assets/images/edit_white.svg")}
                      className="img-fluid"
                      alt="Tin khuyến mãi"
                    />
                    Tin khuyến mãi
                  </a>
                </div>
              </div>
            </div>

            {state.isShopMain && (
              <div className="shop-main">
                <RenderBookingView
                  type={state.shopType}
                  shopId={state.shopId}
                />
              </div>
            )}

            {state.isShopInformation && (
              <div className="bg-white p-3 mt-4 shop-info">
                <h4 className="title-sm">Thông tin gian hàng</h4>
                <hr></hr>
                <LoadScript
                  id="script-loader"
                  googleMapsApiKey={GoogleMapAPI}
                  libraries={["places"]}
                >
                  <ShopInformation
                    type={state.shopType}
                    data={state.shop}
                    userInfo={userInfo}
                    refreshPage={this.refreshPage}
                  />
                </LoadScript>
              </div>
            )}

            {state.isShopPromotion && (
              <PromotionDesktopView
                shop={state.shop}
                shopType={state.shopType}
                userInfo={userInfo}
                onShowScreen={this.onShowScreen.bind(this)}
              />
            )}

            {state.isEditPromotion && (
              <div className="bg-white p-3 mt-4 add-promotion">
                <h4 className="title-sm">Chỉnh sửa tin khuyến mãi</h4>
                <hr></hr>
                <EditPromotion
                  hotId={state.hotId}
                  shopType={state.shopType}
                  onShowScreen={this.onShowScreen.bind(this)}
                />
              </div>
            )}

            {state.isShopSetupCalendar && (
              <div className="bg-white p-3 mt-4">
                <RenderCalendarSchedulerView
                  type={state.shopType}
                  shopId={state.shopId}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetShopDetail: shopActions.GetShopDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserShopDesktopView);
