//@ts-check
import React, { useEffect } from 'react';
import HeaderDetailView from '../../child-component/detail-page-childs/header-detail/header-detail.view';
import FooterDetailView from '../../child-component/detail-page-childs/footer-detail/footer-detail.view';
import RegisterFormView from '../childs/detail-page-childs/register-form/register-form.view';
import FakeData from '../../../../mock-data/gym-detail';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PageUrls from '../../../PageUrls';
import * as UtilitiesStore from '../../../../redux/stores/utillities/utilitis-shop.store';
/**
 * @param {{isLoggedIn?:boolean,
 * PostRegisterUseGym:Function,
 * GetShopDetail:Function,
 * match:any,
 * shopDetail:any,
 * }} [props]
*/
function GymDetailPageMobile(props) {
    /**
     * @param {{shopId: number,phoneNumber: string,userName: string,registerMonth: number}} paramsObject
    */
    const registerFunction = (paramsObject) => {
        const params = new FormData();
        params.append('data[register_name]', paramsObject.userName)
        params.append('data[gym_id]', String(paramsObject.shopId))
        params.append('data[mobile_number]', paramsObject.phoneNumber)
        params.append('data[month]', String(paramsObject.registerMonth)) // need edit
        return props.PostRegisterUseGym(params)
    }

    useEffect(() => {
        props.GetShopDetail(props.match.params.id)
    }, [])
    return (
        <div>
            <HeaderDetailView data={props.shopDetail} comeBackLink={PageUrls.UtilitiesSportHomeMobile} />
            <RegisterFormView registerFunction={registerFunction} isLoggedIn={props.isLoggedIn} shopId={props.shopDetail.id} paymentGuide={props.shopDetail.paymentGuide} from2to6={props.shopDetail.from2to6}
                from7to1={props.shopDetail.from7to1} isGymBody={true} />
            <FooterDetailView listImage={props.shopDetail.listImage} />
        </div>
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    shopDetail: state.utilitiShop.shopDetailWidthActiveCalendar,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiesStore.GetGymDetail,
    PostRegisterUseGym: UtilitiesStore.PostRegisterUseGym,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GymDetailPageMobile)
