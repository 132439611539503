//@ts-check
import React, { useEffect, useRef, useState } from 'react';
import GoogleMap from '../../../gmap/googlemap.view';
import SliderShopHomePageViewDesktop from './home-slider.view';
import UtilitiesListOther from '../utilities-list-other/utilities-list-other.view';
import DefaultIconMarker from '../../../../assets/images/processing.svg';
/**
     * @param {{
     * IconMarkerCustom?:string,
     * currentTypeUrl:string,
     * shopTypeTile:string,
     * listData:{
     * logoUrl: string;
     * name: string;
     * address: string;
     * id: number;
     * lat:number;
     * lng:number;
     * }[],detailUrl:string,viewMoreUrl:string}} [props]
    */
function ShopHomePageDesktop(props) {
    const [listMarkerCustomData, setListMarkerCustomData] = useState({
        iconUrl: DefaultIconMarker || props.IconMarkerCustom,
        listPosition: [],
    })
    useEffect(() => {
        const newListShop = []
        props.listData.map(shopData => newListShop.push({
            lat: shopData.lat,
            lng: shopData.lng,
        }))
        setListMarkerCustomData({
            iconUrl: DefaultIconMarker || props.IconMarkerCustom,
            listPosition: newListShop,
        })
    }, [props.listData])
    return (
        <div className="utilities-desktop">
            <h2 className="title-lg">{props.shopTypeTile}</h2>
            <div className="utilities-map">
                <GoogleMap listMarkerCustom={listMarkerCustomData} />
            </div>

            <SliderShopHomePageViewDesktop viewMoreUrl={props.viewMoreUrl} detailUrl={props.detailUrl} listData={props.listData} />

            <UtilitiesListOther currentTypeUrl={props.currentTypeUrl} />
        </div>
    )
}

export default ShopHomePageDesktop