/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { FacebookShareButton } from "react-share";
import moment from "moment";
import { Dropdown, Modal, Button } from "react-bootstrap";

import * as appActions from "core/app.store";
import * as newActions from "redux/stores/news/news.store";

import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { history } from "redux/store";
import {
  NotificationMessageType,
  SetDefaultErrorImage,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";

import "../life-detail-desktop/life-detail-desktop.scss";
moment.locale("vi");

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="next-arrow">
        <img
          src={require("assets/images/keyboard-arrow-right.svg")}
          alt="aaa"
        />
      </div>
    </div>
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="prev-arrow">
        <img
          src={require("assets/images/keyboard-arrow-right.svg")}
          alt="aaa"
        />
      </div>
    </div>
  );
}

class LifeDetailDesktopView extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      news: null,
      newId: this.props.match.params.newId,
      relatedNewModel: {},

      peopleTargetId: null,
      peopleTargetName: "",
      selectedCommentId: null,
      isOpenDeletePopup: false,
      deletedCommentModel: null,
      loading: false,
      comments: [],
      totalItems: 0,
      currentPageIndex: 1,
      currentSize: 5,
      currentUser: JwtHelper.currentUser(),
      loadMoreStep: 5,
      currentOpenSubComments: [],

      isLike: false,
    };

    this.onGetNewDetail = this.onGetNewDetail.bind(this);
    this.onGetRelatedNewList = this.onGetRelatedNewList.bind(this);
    this.onGetComments = this.onGetComments.bind(this);
    this.onGetCommentModel = this.onGetCommentModel.bind(this);
    this.onGetReplyModel = this.onGetReplyModel.bind(this);
    this.onTargetCommentPeople = this.onTargetCommentPeople.bind(this);
    this.onPostComment = this.onPostComment.bind(this);
    this.onPostReply = this.onPostReply.bind(this);
    this.onToggleDeletePopup = this.onToggleDeletePopup.bind(this);
    this.onDeleteComment = this.onDeleteComment.bind(this);
    this.onEditComment = this.onEditComment.bind(this);
    this.onGetMoreComments = this.onGetMoreComments.bind(this);
    this.renderTotalComment = this.renderTotalComment.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.onGetNewDetail(this.state.newId);
    this.state.currentUser && this.onGetComments();
    this.state.currentUser && this.IsLike(this.state.newId);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  IsLike = (newId) => {
    this.props.isLike(newId).then(
      (res) => {
        if (res && res.content) {
          this.setState(
            {
              isLike: res.content.isDislike == 0 ? true : false,
            },
            () => this.onGetNewDetail(this.state.newId, false)
          );
        }
      },
      (err) => {}
    );
  };

  UpdateLike = (newId = this.state.newId) => {
    this.props.updateLike(newId).then(
      (res) => {
        this.IsLike(newId);
        this.onGetNewDetail(this.state.newId, false);
      },
      (err) => {}
    );
  };

  onGetNewDetail = (newId, isGetList = true) => {
    this.props.showLoading(true);
    this.props.getNewDetail(newId).then(
      (res) => {
        if (res && res.content) {
          this.setState(
            {
              news: res.content,
              newId: newId,
            },
            () => {
              this.props.showLoading(false);
              isGetList &&
                this.onGetRelatedNewList(1, 9999, {
                  reject_id: res.content.newsId,
                  orderBy: "update_date",
                  orderType: "DESC",
                });
            }
          );
        } else {
          this.props.showLoading(false);
          AddNotification(
            viVN.Errors[(res && res.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
          history.push("/");
        }
      },
      (err) => {
        this.props.showLoading(false);
        history.push("/");
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onGetRelatedNewList = (
    pageIndex = 1,
    size = 9999,
    filterObj = {
      reject_id: this.state.newId,
      orderBy: "update_date",
      orderType: "DESC",
    }
  ) => {
    this.props.showLoading(true);
    this.props.getNewList(pageIndex, size, filterObj).then(
      (res) => {
        if (res && res.content) {
          this.setState({
            relatedNewModel: {
              ...res.content,
              items: res.content.items
                ? res.content.items.filter(
                    (item) => item.newsId != this.state.newId
                  )
                : [],
            },
          });
        }
        this.props.showLoading(false);
      },
      (err) => {
        this.props.showLoading(false);
      }
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  onGetComments = (
    pageIndex = this.state.currentPageIndex,
    size = this.state.currentSize
  ) => {
    if (this.state.newId) {
      this.props.showLoading(true);
      this.props
        .getListComment(pageIndex, size, {}, this.state.newId)
        .then((res) => {
          var items = (res && res.content && res.content.items) || [];
          items.map((item) => {
            if (
              this.state.currentOpenSubComments.some(
                (id) => id == item.commentId
              )
            ) {
              item.isShowSubComments = true;
            }
          });
          if (this._isMounted) {
            this.setState({
              comments: items,
              totalItems:
                (res && res.content && res.content.totalItemCount) || 0,
            });
          }
          this.onGetNewDetail(this.state.newId, false);
        })
        .catch((err) => {
          this.props.showLoading(false);
        });
    }
  };

  onGetCommentModel = (comment) => {
    return {
      id: comment.commentId,
      profileUrl: comment.profileUrl,
      content: comment.content,
      createdBy: comment.customerName,
      createdById: comment.customerId,
      createdDate: comment.createdDate,
      replyData: comment.replyData,
      isAllowEdit:
        this.state.currentUser &&
        comment.customerId == this.state.currentUser.customerId,
      adId: this.state.newId,
      totalSubComments: (comment.replyData && comment.replyData.length) || 0,
      type: "comment",
    };
  };

  onGetReplyModel = (reply, commentId) => {
    return {
      id: reply.commentId || 0,
      parent_id: commentId,
      profileUrl: reply.profileUrl,
      content: reply.content,
      createdBy: reply.customerName,
      createdById: reply.customerId,
      createdDate: reply.createdDate,
      isAllowEdit:
        this.state.currentUser &&
        reply.customerId == this.state.currentUser.customerId,
      adId: this.state.newId,
      type: "reply",
    };
  };

  onTargetCommentPeople = (
    selectCommentId,
    peopleTargetId,
    peopleTargetName,
    isShowSubComments
  ) => {
    this.setState(
      {
        peopleTargetId: peopleTargetId,
        peopleTargetName: peopleTargetName,
        selectCommentId: selectCommentId,
      },
      () => {
        var commentReferences = [...this.state.comments];
        var commentReferenceIndex = commentReferences.findIndex(
          (cm) => cm.commentId == selectCommentId
        );
        if (commentReferenceIndex >= 0) {
          commentReferences[
            commentReferenceIndex
          ].isShowSubComments = isShowSubComments;
          this.setState(
            {
              comments: [...commentReferences],
            },
            () => {
              if (isShowSubComments) {
                this.setState({
                  currentOpenSubComments: [
                    ...this.state.currentOpenSubComments,
                    selectCommentId,
                  ],
                });
                this["replyInput" + selectCommentId] &&
                  this["replyInput" + selectCommentId].focus();
              } else {
                this.setState({
                  currentOpenSubComments: [
                    ...this.state.currentOpenSubComments.filter(
                      (id) => id != selectCommentId
                    ),
                  ],
                });
              }
            }
          );
        }
      }
    );
  };

  onPostComment = (e) => {
    e.preventDefault();
    if (!this.commentInput.value.trim()) {
      return;
    }
    this.props.showLoading(true);
    this.props
      .createOrUpdateComment({
        content: this.commentInput.value && this.commentInput.value.trim(),
        parent_id: 0,
        news_id: this.state.newId,
        level: 1,
      })
      .then(
        (res) => {
          this.commentInput.value = "";
          this.onGetComments();
          AddNotification(
            "Gửi bình luận thành công!",
            NotificationMessageType.Success
          );
        },
        (err) => {
          this.props.showLoading(false);
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
  };

  onPostReply = (commentId, e) => {
    e.preventDefault();
    if (!this["replyInput" + commentId].value.trim()) {
      return;
    }
    this.props.showLoading(true);
    this.props
      .createOrUpdateComment({
        content:
          this["replyInput" + commentId].value &&
          this["replyInput" + commentId].value.trim(),
        parent_id: commentId,
        news_id: this.state.newId,
        level: 2,
      })
      .then(
        (res) => {
          this["replyInput" + commentId].value = "";
          this.onGetComments();
          AddNotification(
            "Gửi phản hồi thành công!",
            NotificationMessageType.Success
          );
        },
        (err) => {
          this.props.showLoading(false);
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
  };

  onToggleDeletePopup = (isOpenDeletePopup, commentId) => {
    this.setState({
      isOpenDeletePopup: isOpenDeletePopup,
      deletedCommentId: commentId,
    });
  };

  onDeleteComment = (isConfirm) => {
    if (isConfirm) {
      this.props.showLoading(true);
      this.props.deleteComment(this.state.deletedCommentId).then(
        (res) => {
          AddNotification(
            "Xoá bình luận thành công!",
            NotificationMessageType.Success
          );
          this.setState({
            isOpenDeletePopup: false,
          });
          this.onGetComments();
        },
        (err) => {
          this.props.showLoading(false);
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
          this.setState({
            isOpenDeletePopup: false,
          });
        }
      );
    }
  };

  onEditComment = async (editingModel) => {
    let modelToUpdate = {
      comment_id: editingModel.commentId,
      content: editingModel.content,
      customer_id: this.state.currentUser.customerId,
      parent_id: editingModel.parent_id,
      ad_id: this.props.adId,
      level: editingModel.type == "reply" ? 2 : 1,
    };
    this.props.showLoading(true);
    return this.props.createOrUpdateComment(modelToUpdate).then(
      (res) => {
        AddNotification(
          "Cập nhật bình luận thành công!",
          NotificationMessageType.Success
        );
        this.onGetComments();
      },
      (err) => {
        this.props.showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onGetMoreComments = () => {
    this.setState(
      {
        currentSize: this.state.currentSize + this.state.loadMoreStep,
      },
      () => {
        this.onGetComments();
      }
    );
  };

  renderTotalComment = () => {
    let { comments } = this.state;

    if (comments && comments.length <= 0) return 0;

    let totalComment = comments.length;
    comments.map((item) => {
      if (item.replyData) totalComment = totalComment + item.replyData.length;
    });

    return totalComment;
  };

  render() {
    const { loggedIn } = this.props;
    const {
      news,
      comments,
      totalItems,
      isOpenDeletePopup,
      loadMoreStep,
      relatedNewModel,
      isLike,
    } = this.state;

    const settingsRelatedNew = {
      infinite: false,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
    };

    return (
      <div className="life-detail-desktop">
        {news && (
          <div>
            <MetaTags>
              <meta property="og:title" content={news.title} />
              <meta property="og:site_name" content="iLang" />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:description" content={news.title} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={news.imageUrl} />
            </MetaTags>
            <img
              src={news.imageUrl}
              alt={news.title}
              className="img-fluid w-100 img-life"
            />
            <h5 className="mt-2 mb-1">{news.title}</h5>
            <span className="datetime">
              <img
                src={require("assets/images/calendar.svg")}
                alt="calendar"
                className="img-calendar"
              />{" "}
              {news.createDate}
            </span>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: news.content }}
            ></div>

            <div className="comment-like-share">
              <div className="d-flex comment-like-quantity align-items-center justify-content-between pb-2">
                <div className="like-quantity">
                  <img
                    src={require("/assets/images/like-quantity.svg")}
                    alt="Like"
                  />{" "}
                  {news.likeCount || 0} Thích
                </div>
                <div className="share-quantity">
                  {news.commentCount || 0} Bình Luận
                </div>
              </div>

              <div className="d-flex comment-like-share-action align-items-center pt-2 pb-2">
                <div
                  className={`like-action d-flex align-items-center ${
                    !loggedIn ? "not-allowed" : ""
                  }`}
                  onClick={() => loggedIn && this.UpdateLike()}
                >
                  <img
                    src={
                      isLike
                        ? require("/assets/images/like-quantity.svg")
                        : require("/assets/images/like-action.svg")
                    }
                    alt="Like"
                    className="mr-1"
                  />{" "}
                  Thích
                </div>
                <div
                  className={`comment-action ml-4 d-flex align-items-center ${
                    !loggedIn ? "not-allowed" : ""
                  }`}
                  onClick={() =>
                    loggedIn && this.commentInput && this.commentInput.focus()
                  }
                >
                  <img
                    src={require("/assets/images/comment-action.svg")}
                    alt="Like"
                    className="mr-1"
                  />{" "}
                  Bình Luận
                </div>
                <div className="share-action ml-4 d-flex align-items-center">
                  <FacebookShareButton
                    url={window.location.href}
                    quote={"iLang"}
                  >
                    <img
                      src={require("/assets/images/share-action.svg")}
                      alt="Like"
                      className="mr-1"
                    />{" "}
                    Chia Sẻ
                  </FacebookShareButton>
                </div>
              </div>

              {comments.map((cm, indexCm) => (
                <div key={"indexCm" + indexCm} className="comment-wrapper">
                  <div className="comment-parent">
                    <RenderComment
                      loggedIn={loggedIn}
                      data={this.onGetCommentModel(cm)}
                      onTargetCommentPeople={this.onTargetCommentPeople.bind(
                        this
                      )}
                      onToggleDeletePopup={this.onToggleDeletePopup.bind(this)}
                      onEditComment={this.onEditComment.bind(this)}
                    />
                    {cm.isShowSubComments && (
                      <div className="sub-comment">
                        {cm.replyData.map((rp, indexRp) => (
                          <div
                            key={"indexRp" + indexRp}
                            className="comment-child"
                          >
                            <RenderComment
                              loggedIn={loggedIn}
                              data={this.onGetReplyModel(rp, cm.commentId)}
                              onTargetCommentPeople={this.onTargetCommentPeople.bind(
                                this
                              )}
                              onToggleDeletePopup={this.onToggleDeletePopup.bind(
                                this
                              )}
                              onEditComment={this.onEditComment.bind(this)}
                            />
                            {/* <RenderSubComment /> */}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {loggedIn && cm.isShowSubComments && (
                    <form
                      onSubmit={(e) => this.onPostReply(cm.commentId, e)}
                      className="comment-child"
                    >
                      <div className="form-group position-relative">
                        <input
                          name="reply_content"
                          type="text"
                          placeholder="Viết phản hồi"
                          autoComplete="off"
                          className="form-control rounded-pill"
                          ref={(input) => {
                            this["replyInput" + cm.commentId] = input;
                          }}
                        />
                        <img
                          src={require("assets/images/send-gray.svg")}
                          alt="Send"
                          className="img-fluid img-send"
                          onClick={(e) => this.onPostReply(cm.commentId, e)}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ))}

              {totalItems > comments.length && (
                <a onClick={this.onGetMoreComments.bind(this)}>
                  Xem thêm{" "}
                  {totalItems - comments.length >= loadMoreStep
                    ? loadMoreStep
                    : totalItems - comments.length}{" "}
                  bình luận
                </a>
              )}

              <Modal
                show={isOpenDeletePopup}
                className="modal-delete"
                centered
                onHide={() => this.onToggleDeletePopup(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Xóa bình luận?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Bình luận sẽ không thể khôi phục sau khi xóa.
                  <br />
                  Bạn có chắc muốn xóa bình luận này.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.onToggleDeletePopup(false)}
                  >
                    Hủy
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => this.onDeleteComment(true)}
                  >
                    Xóa
                  </Button>
                </Modal.Footer>
              </Modal>

              <form onSubmit={this.onPostComment}>
                <div className="comment-box mt-3 form-group position-relative">
                  <input
                    name="content"
                    type="text"
                    placeholder={
                      loggedIn
                        ? "Nhập bình luận..."
                        : "Bạn cần đăng nhập để bình luận..."
                    }
                    disabled={!loggedIn}
                    autoComplete="off"
                    className="form-control rounded-pill"
                    ref={(input) => {
                      this.commentInput = input;
                    }}
                  />
                  <img
                    src={require("/assets/images/send-gray.svg")}
                    alt="Send"
                    className="img-fluid img-send"
                    onClick={(e) => loggedIn && this.onPostComment(e)}
                  />
                </div>
              </form>
            </div>
          </div>
        )}

        {relatedNewModel && (
          <div className="tin-lien-quan mb-3 mt-5">
            <div className="widget-title">
              <h5>Tin liên quan</h5>
            </div>
            {relatedNewModel.items && relatedNewModel.items.length > 0 ? (
              <div className="body mt-3">
                <Slider {...settingsRelatedNew}>
                  {relatedNewModel.items.map((item, index) => (
                    <div
                      className={"item " + (index % 3 !== 0 ? "pl-3" : "")}
                      key={item.newsId}
                    >
                      <Link
                        to={`/cuoc-song/${item.newsId}`}
                        onClick={() => {
                          this.setState(
                            {
                              newId: item.newsId,
                            },
                            () => {
                              if (this.state.currentUser) {
                                this.onGetComments();
                                this.IsLike(item.newsId);
                              } else {
                                this.onGetNewDetail(this.state.newId);
                              }
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            item.imageUrl ||
                            require("assets/images/related-news.png")
                          }
                          alt={item.title || "Related News"}
                          className="img-fluid w-100"
                        />
                      </Link>
                      <h5>
                        <Link
                          to={`/cuoc-song/${item.newsId}`}
                          onClick={() => {
                            this.setState(
                              {
                                newId: item.newsId,
                              },
                              () => {
                                if (this.state.currentUser) {
                                  this.onGetComments();
                                  this.IsLike(item.newsId);
                                } else {
                                  this.onGetNewDetail(this.state.newId);
                                }
                              }
                            );
                          }}
                        >
                          {item.title}
                        </Link>
                      </h5>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="pt-3 pl-3 pr-3 pb-2 bg-white rounded-bottom body">
                Không có dữ liệu
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function RenderComment(props) {
  const { data, loggedIn } = props;
  let commentInput = null;
  const [isShowEditForm, setShowEditForm] = useState(false);
  const [isShowSubComments, setShowSubComments] = useState(false);

  const onShowDeletePopup = (deletingCommentId) => {
    props.onToggleDeletePopup(true, deletingCommentId);
  };

  const onEditComment = async (e) => {
    e.preventDefault();
    if (!commentInput.value.trim()) {
      return;
    }
    let editedData = { ...data };
    editedData.commentId = data.id;
    editedData.content = commentInput.value && commentInput.value.trim();
    await props.onEditComment(editedData);
    setShowEditForm(false);
  };

  const onToggleSubComments = () => {
    props.onTargetCommentPeople(
      data.commentId || data.id,
      data.createdById,
      data.createdBy,
      !isShowSubComments
    );
    setShowSubComments(!isShowSubComments);
  };

  return (
    <div className="form-comment row no-gutters">
      <div className="col-1">
        <img
          src={data.profileUrl || require("assets/images/29-04.png")}
          alt="Avatar"
          onError={SetDefaultErrorImage}
          className="img-fluid img-avatar"
        />
      </div>
      {!isShowEditForm && (
        <div className="col-11 pl-2">
          <div>
            <h5 className="full-name d-flex mb-0">
              {data.createdBy}
              {loggedIn && data.isAllowEdit && (
                <Dropdown>
                  <Dropdown.Toggle>
                    <img
                      src={require("assets/images/more.svg")}
                      className="img-fluid img-more"
                      alt="more"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShowEditForm(true)}>
                      <img
                        src={require("assets/images/pen.svg")}
                        className="img-fluid img-pen"
                        alt="pen"
                      />
                      <span>Chỉnh sửa bình luận</span>
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => setShowSubComments(true)}> */}
                    <Dropdown.Item onClick={() => onShowDeletePopup(data.id)}>
                      <img
                        src={require("assets/images/delete.svg")}
                        className="img-fluid img-delete"
                        alt="pen"
                      />
                      <span>Xóa</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </h5>
            <p className="text-break mb-0">{data.content}</p>
            <a onClick={onToggleSubComments.bind(this)} className="reply">
              Trả lời
            </a>
            <span className="comment-time">
              {" "}
              {moment(data.createdDate).fromNow()}{" "}
            </span>
            {data.type == "comment" && (
              <a onClick={onToggleSubComments.bind(this)} className="reply">
                <span></span>
                {data.totalSubComments} phản hồi
              </a>
            )}
          </div>
        </div>
      )}
      {isShowEditForm && (
        <form className="col-11 pl-2" onSubmit={onEditComment}>
          <div className="form-group position-relative">
            <input
              name="content"
              defaultValue={data.content}
              type="text"
              placeholder="Viết phản hồi..."
              className="form-control rounded-pill"
              ref={(input) => {
                commentInput = input;
              }}
            />
            <img
              src={require("/assets/images/send-gray.svg")}
              alt="Send"
              className="img-fluid img-send"
              onClick={onEditComment.bind(this)}
            />
            <a className="cancel-edit" onClick={() => setShowEditForm(false)}>
              Huỷ
            </a>
          </div>
        </form>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getNewDetail: newActions.GetNewDetail,
      getNewList: newActions.GetNewList,
      createOrUpdateComment: newActions.AddOrUpdateComment,
      deleteComment: newActions.CommentDelete,
      getListComment: newActions.GetListComment,
      updateLike: newActions.UpdateLike,
      isLike: newActions.IsLike,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifeDetailDesktopView);
