//@ts-check
import { useState, useEffect } from 'react';

export function useRenderPaymentHistoryList(props_listData) {
    const Page_Size = 6;
    const [listData, setListData] = useState(props_listData);
    const [displayList, setDisplayList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    useEffect(() => {
        setListData(props_listData)
    }, [props_listData])
    useEffect(() => {
        const newDisplayList = listData.slice((currentIndex - 1) * Page_Size, Page_Size * currentIndex);
        setDisplayList(newDisplayList)
    }, [currentIndex, listData])
    return {
        listData,
        Page_Size,
        displayList,
        setCurrentIndex
    }
}
