import React from 'react'
import * as Gmap from '@react-google-maps/api'
import GoogleMap, { GoogleMapAPI } from './googlemap.view';

export default function GoogleMapMobile(props) {
    return (
        <Gmap.LoadScript id="script-loader" googleMapsApiKey={GoogleMapAPI} language="vi">
            <GoogleMap {...props} />
        </Gmap.LoadScript>

    )
}