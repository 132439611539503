import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../management-desktop.scss';
import * as memberActions from 'redux/stores/management/member.store';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { SetDefaultErrorImage } from "utils/configuration";

class MemberSectionDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetMemberList();
  }

  onGetMemberList = (pageIndex = 1, size = 10) => {
    this.setState({ isLoading: true });
    this.props.getMemberList(pageIndex, size).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => { 
        this.setState({ isLoading: false });
      }
    );
  };

  render() {
    const { memberList } = this.props;
    const sliderSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: memberList && memberList.length > 4 ? 4 : memberList.length,
      slidesToScroll: memberList && memberList.length > 4 ? 4 : memberList.length,
    }
    return (
      <div className="management-desktop">
        <div className="row no-gutters align-items-center">
          <div className="col-6">
            <h2 className="title-lg mt-4 mb-3">Thành viên BQL</h2>
          </div>
          <div className="col-6 text-right">
            <Link to="/thanh-vien-bql">Xem thêm</Link>
          </div>
        </div>
        <Slider {...sliderSetting}>
          {memberList.map(item => <div key={item.agentId} className="member-info">
            <div className="row">
              <div className="col-12">
                <Link to={`/thanh-vien-bql/chi-tiet/${item.agentId}`}>
                  <img
                    src={item.avatarUrl || require('assets/images/29-01.png')}
                    onError={SetDefaultErrorImage}
                    alt="Avatar"
                    className="img-fluid avatar"
                  />
                </Link>
              </div>
              <div className="col-12">
                <h5 className="mb-0">
                  <Link to={`/thanh-vien-bql/chi-tiet/${item.agentId}`}>{item.agentName}</Link>
                </h5>
              </div>
              {item.roleName && <div className="col-12">
                <span>[{item.roleName}]</span>
              </div>}
              <div className="col-12">
                <a href={`tel:${item.mobileNumber}`}>SĐT: {item.mobileNumber}</a>
              </div>
            </div>
          </div>)}
        </Slider>
        {!memberList.length && <div className="col-12">Không có bản ghi</div>}
      </div >
    );
  }
}

const mapStateToProps = state => ({
  memberList: state.member.memberList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMemberList: memberActions.GetMemberList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberSectionDesktopView);
