import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from '../../../core/app.store';
import {
    createFormData,
    AppKey,
    compareValues
} from '../../../utils/configuration';
import Service from '../../../services/api.service';
import {
    ApiUrl
} from '../../../services/api-url';

//--- Fake data
import {
    RepairingHouseholdFake,
    EvaluateFake
} from '../../../mock-data/utilities-plus';

const service = new Service();
/** Action Types */
const REPAIRINGHOUSEHOLDSAVELIST = 'REPAIRINGHOUSEHOLD/SAVELIST';
const REPAIRINGHOUSEHOLDGETLIST = 'REPAIRINGHOUSEHOLD/GETLIST';

/** Actions */
export const createSaveRepairingHouseholdList = (repairingHouseholdModel) => ({
    type: REPAIRINGHOUSEHOLDSAVELIST,
    payload: {
        repairingHouseholds: repairingHouseholdModel.items,
        totalItems: repairingHouseholdModel.totalItems
    }
});
export const createGetRepairingHouseholdList = () => ({
    type: REPAIRINGHOUSEHOLDGETLIST
});

export const GetRepairingHouseholdList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        // dispatch(createSaveLoading(true));
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.RepairingHouseholdGetList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveRepairingHouseholdList(res.content));
            // dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetRepairingHouseholdDetail = (serviceId) => {
    return dispatch => {
        let payLoad = createFormData({
            serviceId: serviceId
        });
        // dispatch(createSaveLoading(true));
        return service.post(ApiUrl.RepairingHouseholdGetDetail, payLoad).then(res => {
            // dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteVote = (voteId) => {
    return dispatch => {
        let payLoad = createFormData({
            voteId: voteId
        });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.EvaluateRepairingHouseholdDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const AddOrUpdateEvaluate = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.EvaluateRepairingHouseholdAddOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

/** Fake service */
export const GetRepairingHouseholdListFake = (pageIndex, size, filterObjs) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let repairingHouseholdModels = filterObjs ?
                RepairingHouseholdFake.sort(compareValues(filterObjs['orderBy'], filterObjs['orderType'])) : RepairingHouseholdFake;
            let repairingHouseholdList = repairingHouseholdModels.slice(pageIndex * size - size, pageIndex * size);
            if (repairingHouseholdList) {
                dispatch(createSaveRepairingHouseholdList({
                    repairingHouseholds: repairingHouseholdList,
                    totalItems: repairingHouseholdModels.length
                }))
                resolve({
                    content: {
                        items: repairingHouseholdList,
                        totalItems: repairingHouseholdModels.length
                    },
                    errorType: "",
                    errorMessage: "",
                });
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                });
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetRepairingHouseholdDetailFake = (id) => {
    return (dispatch, state) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let currentRepairingHouseholds = state().repairingHousehold.repairingHouseholdList;
            let repairingHouseholdDetail = currentRepairingHouseholds && currentRepairingHouseholds.length > 0 ? currentRepairingHouseholds.find(x => x.id == id) : RepairingHouseholdFake.find(x => x.id == id);
            if (repairingHouseholdDetail) {
                resolve({
                    content: {
                        data: repairingHouseholdDetail,
                        evaluate: EvaluateFake
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

// Initial state
export const initialState = Map({
    repairingHouseholdList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function RepairingHouseholdReducer(state = initialState, action) {
    switch (action.type) {
        case REPAIRINGHOUSEHOLDSAVELIST:
            return {
                ...state,
                repairingHouseholdList: action.payload.repairingHouseholds,
                    totalItems: action.payload.totalItems,
            }
            case REPAIRINGHOUSEHOLDGETLIST:
                return state.repairingHouseholdList;
            default:
                return state;
    }
}