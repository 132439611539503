/* eslint-disable react/prop-types */
//@ts-check
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PaginationControl from "../../../../components/pagination/paging.view.jsx";
import AllListView from "./all-list.view.jsx";

import * as UtilitiesStore from "../../../../redux/stores/utillities/utilitis-shop.store";

function AllTabListPageView(props) {
  const pageSize = 8;
  const pageIndex = 1;

  useEffect(() => {
    props.GetListSportNew(pageIndex, pageSize);
  }, []);

  return (
    <Fragment>
      <AllListView listData={props.listData} />
      <PaginationControl
        goTo={(index) => props.GetListSportNew(index, pageSize)}
        totalItems={props.total || 0}
        index={pageIndex}
        size={pageSize}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  listData: state.utilitiShop.sportList.listShop,
  total: state.utilitiShop.sportList.total,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListSportNew: UtilitiesStore.GetListSportNew,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AllTabListPageView);
