/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddNotification from "components/react-notifications/react-notifications";
import * as joinHandActions from "redux/stores/join-hand/join-hand.store";
import * as appActions from "core/app.store";
import { history } from "redux/store";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { JwtHelper } from "utils/jwt-helper";
import {
  SetDefaultErrorImage,
  NotificationMessageType,
  convertTextareaToHtml,
} from "utils/configuration";
import "../../neighbourhoods-mobile.scss";

class JoinsHandDetailMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      joinHandPost: null,
      topicId: this.props.match.params.topicId,
      votedCount: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetJoinHandPostDetail();
  }

  onGetJoinHandPostDetail = () => {
    if (this.state.topicId) {
      this.props.showLoading(true);
      this.props.getJoinHandDetail(this.state.topicId).then(
        (res) => {
          if (res.content) {
            this.setState(
              {
                joinHandPost: res.content,
              },
              () => {
                this.onCountVotedPeople();
              }
            );
          } else {
            history.push("/m/lang-gieng-gan/chung-tay");
          }
          this.props.showLoading(false);
        },
        (err) => {
          this.props.showLoading(false);
        }
      );
    }
  };

  onCountVotedPeople = () => {
    let count = 0;
    this.state.joinHandPost.options.map((jp) => {
      count += (jp.customerVote || []).length;
    });
    this.setState({
      votedCount: count,
    });
  };

  isVotedByCurrentUser = (vote) => {
    return (vote.customerVote || []).some(
      (p) => p.customerId == this.state.currentUser.customerId
    );
  };

  voteId = (customerVote) => {
    return (customerVote || []).filter(
      (p) => p.customerId == this.state.currentUser.customerId
    );
  };

  getCurrentUserVotedIdInListOptions = () => {
    let votedId = 0;
    this.state.joinHandPost.options.map(vote => {
      const voteSelected = this.voteId(vote.customerVote)[0];
      if (voteSelected) {
        votedId = voteSelected.voteId;
        return;
      }
    })
    return votedId;
  }

  onSelectVote = (vote, isVoted) => {
    const deleteVotedByVoteId = (voteId, isShowSuccessNotification = true, successCallBack = null) => {
      this.props
        .deleteVotedEvent({
          voteId: [voteId],
        })
        .then(
          (res) => {
            this.onGetJoinHandPostDetail();
            isShowSuccessNotification && AddNotification(
              "Hủy bình chọn thành công",
              NotificationMessageType.Success
            );
            successCallBack && successCallBack();
          },
          (err) => { }
        );
    }
    if (isVoted) {
      let voteId = this.voteId(vote.customerVote);
      deleteVotedByVoteId(voteId && voteId.length > 0 && voteId[0].voteId)
    } else {
      const oldVoteSelectedId = this.getCurrentUserVotedIdInListOptions();
      const updateSelectedVote = () => {
        this.props
          .createOrUpdateVotedEvent({
            topic_id: this.state.joinHandPost.topic_id,
            option_id: [vote.option_id],
          })
          .then(
            (res) => {
              this.onGetJoinHandPostDetail();
              AddNotification(
                "Bình chọn thành công",
                NotificationMessageType.Success
              );
            },
            (err) => { }
          );
      }

      if (oldVoteSelectedId) {
        deleteVotedByVoteId(oldVoteSelectedId, false, updateSelectedVote)
      } else updateSelectedVote()

    }
  };

  render() {
    const { joinHandPost, votedCount, currentUser } = this.state;

    return (
      <div className="joins-hand-detail-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center no-gutters">
              <div className="col-1">
                <Link to="/m/lang-gieng-gan/chung-tay">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-arrow-back"
                  />
                </Link>
              </div>
              <div className="col-11">
                <div className="header-title text-center text-truncate">
                  Chi tiết sự kiện
                </div>
              </div>
            </div>
          </div>
        </header>
        {joinHandPost && (
          <div className="joins-hand-detai-content">
            <img
              onError={SetDefaultErrorImage}
              src={joinHandPost.topic_image}
              alt={joinHandPost.topic_name}
              className="img-fluid w-100 img-joins-hand-detail"
            />
            <div className="p-3">
              <h4>{joinHandPost.topic_name}</h4>
              <span>
                <b>Thời gian:</b> {joinHandPost.intend_time}
              </span>
              <br />
              <span>
                <b>Địa điểm tổ chức:</b> {joinHandPost.location}
              </span>
              <br />
              <span>
                <b>Người chịu trách nhiệm:</b> {joinHandPost.responsible}
              </span>
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html: convertTextareaToHtml(joinHandPost.content),
                }}
              ></p>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-vote rounded p-3">
                  <h5>{joinHandPost.topic_name}</h5>
                  {(joinHandPost.options || []).map((vote, index) => (
                    <div key={vote.option_id} className="form-group">
                      <div className="row no-gutters align-items-center">
                        <div className="col-1">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              disabled={!this.props.isLoggedIn}
                              checked={
                                currentUser
                                  ? this.isVotedByCurrentUser(vote)
                                  : false
                              }
                              onChange={() =>
                                this.onSelectVote(
                                  vote,
                                  this.isVotedByCurrentUser(vote)
                                )
                              }
                              id={`vote${index}`}
                              name={`vote${index}`}
                              className="custom-control-input"
                            />
                            <label
                              title={vote.option_name}
                              htmlFor={`vote${index}`}
                              className="custom-control-label"
                            ></label>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="poll-name">{vote.option_name}</div>
                        </div>
                        {(vote.customerVote || []).length <= 3 && (
                          <div className="col-3 pre-avatar">
                            {(vote.customerVote || []).map((p) => (
                              <img
                                key={p.customerId}
                                src={
                                  p.avatarUrl ||
                                  require("assets/images/29-01.png")
                                }
                                title={p.customerName}
                                alt={p.customerName}
                                className="img-fluid mr-1"
                              />
                            ))}
                          </div>
                        )}
                        {(vote.customerVote || []).length > 3 && (
                          <div className="col-3 pre-avatar">
                            <img
                              src={
                                vote.customerVote[0].avatarUrl ||
                                require("assets/images/29-01.png")
                              }
                              title={vote.customerVote[0].customerName}
                              alt={vote.customerVote[0].customerName}
                              className="img-fluid mr-1"
                            />
                            <img
                              src={
                                vote.customerVote[1].avatarUrl ||
                                require("assets/images/29-01.png")
                              }
                              title={vote.customerVote[1].customerName}
                              alt={vote.customerVote[1].customerName}
                              className="img-fluid mr-1"
                            />
                            <RenderOverlay
                              votedMoreCount={vote.customerVote.length - 2}
                              items={vote.customerVote}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {
                    (joinHandPost.options.length > 0) &&
                    <div className="vote-count">
                      <span>{votedCount} lượt bình chọn</span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function RenderOverlay(props) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Người bình chọn</Popover.Title>
      <Popover.Content>
        {props.items.map((p) => (
          <div key={p.customerId} className="mt-2 mb-2">
            <img
              src={p.avatarUrl || require("assets/images/29-01.png")}
              title={p.customerName}
              alt={p.customerName}
              className="img-fluid avatar mr-2"
            />
            <span className="customerName">{p.customerName}</span>
          </div>
        ))}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <span className="vote-more-count">+{props.votedMoreCount}</span>
    </OverlayTrigger>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateVotedEvent: joinHandActions.CreateOrUpdateVotedEvent,
      deleteVotedEvent: joinHandActions.DeleteVotedEvent,
      createOrUpdateJoinHand: joinHandActions.CreateOrUpdateJoinsHand,
      getJoinHandDetail: joinHandActions.GetJoinsHandDetail,
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinsHandDetailMobileView);
