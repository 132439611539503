import App from "./modules/App.jsx";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "./redux/store";
import { CookiesProvider } from "react-cookie";
import "bootstrap/scss/bootstrap.scss";
import "font-awesome/scss/font-awesome.scss";
import "./styles/styles.scss";
import "./styles/suneditor.min.css";
import "suneditor/dist/css/suneditor.min.css";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Roboto:300,400,500,700", "sans-serif"],
  },
});

const target = document.querySelector("#root");
export const reduxStore = store;

render(
  <CookiesProvider>
    <Provider store={reduxStore}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>,
  target
);
