import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../management-desktop.scss";
import * as securityCameraActions from 'redux/stores/management/security-camera.store';
import "../management-desktop.scss";
import { Link } from 'react-router-dom';

class SecurityCameraSectionDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetSecurityCamera();
  }

  onGetSecurityCamera = (pageIndex = 1, size = 3) => {
    this.setState({ isLoading: true });
    this.props.getTop3SecurityCameras(pageIndex, size).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => { 
        this.setState({ isLoading: false });
      }
    );
  };

  render() {
    const { top3SecurityCameras } = this.props;

    return (
      <div className="management-desktop security-camera">
        {/* <div className="row no-gutters">
          <div className="col-3">
            <h2 className="title-lg mt-4">Camera an ninh</h2>
          </div>
          <div className="col-9 position-relative text-right pt-3">
            <Link to="/camera-an-ninh">Xem thêm</Link>
            <div className="management-border w-100"></div>
          </div>
        </div>

        <div className="row"> */}
          {/* {top3SecurityCameras.map(item => <div key={item.cameraId} className="col-4">
            {!!item.streamUrl ? <iframe width="223" height="124" name={item.cameraName} src={`${item.streamUrl}?&autoplay=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : <img src={item.thumbnail_url} className="img-fluid" alt={item.cameraName} />}
          </div>)}
          {!top3SecurityCameras.length && <div className="col-12">Không có bản ghi</div>} */}
           {/* <div className="col-6 mb-2">
            <iframe width="223" height="124" name="camera" src={`https://www.youtube.com/embed/DMDbtNrmaZA?&autoplay=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  top3SecurityCameras: state.securityCamera.top3SecurityCameras
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTop3SecurityCameras: securityCameraActions.GetTop3securityCameraList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityCameraSectionDesktopView);