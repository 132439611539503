/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import * as appActions from "core/app.store";
import * as clubActions from "redux/stores/club/club.store";

import { Error } from "components/support-create-form/validate-style";
import {
  NewGuid,
  ValidImageTypes,
  validateFile,
  CheckMaxFile,
  NotificationMessageType,
  convertTextareaToHtml,
  ImageExtensions,
} from "utils/configuration";
import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";

import "../../club.scss";

function CreatePingPongForm(props) {
  const { user, loggingIn } = props;
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [previewAttachments, setPreviewAttachment] = useState([]);
  const [adFiles, setAdFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const onSubmit = (data) => {

    let submittedData = {
      category_id: clubActions.clubType.BONG_BAN,
      content: convertTextareaToHtml(data.content),
    };

    let formData = new FormData();
    previewAttachments &&
      previewAttachments.length > 0 &&
      previewAttachments.map((file) =>
        formData.append("club_file[]", file.file)
      );

    props.showLoading(true);
    props.addOrUpdatePost(submittedData, formData).then(
      (res) => {
        props.showLoading(false);
        history.push("/m/lang-gieng-gan/cau-lac-bo/bong-ban");
      },
      (err) => {
        props.showLoading(false);
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const onFileChange = (event) => {
    let files = Array.from(event.target.files);
    if (files && files.length) {
      // validate maximum length
      var validateMsg = CheckMaxFile(adFiles.length + files.length);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      // validate file
      files.map((file) => {
        if (validateFile(file, ImageExtensions)) {
          validateMsg = validateFile(file, ImageExtensions);
        }
      });
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage("");

      var previewImg = [];
      var imgFiles = [];
      files.map((file) => {
        var reader = new FileReader();
        reader.onload = (e) => {
          previewImg = [
            ...previewImg,
            {
              fileUrl: e.target.result,
              fileName: file.name,
              fileId: NewGuid(),
              file: file,
              fileType: ValidImageTypes.includes(file.type)
                ? "image"
                : file.type,
            },
          ];
          setPreviewAttachment([...previewAttachments, ...previewImg]);
        };
        reader.readAsDataURL(file);
        imgFiles = [...imgFiles, file];
        setAdFiles([...adFiles, ...imgFiles]);
      });
    }
  };

  const onRemovedAttachment = (attachmentId) => {
    setPreviewAttachment(
      previewAttachments.filter((att) => att.fileId != attachmentId)
    );
  };

  return (
    <div className="club-mobile">
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m/lang-gieng-gan/cau-lac-bo/bong-ban">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">Tạo bài viết mới</div>
            </div>
          </div>
        </div>
      </header>

      <form className="add-post-mobile p-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <textarea
            name="content"
            className="form-control"
            placeholder="Nội dung"
            rows="5"
            ref={register({
              required: "Trường bắt buộc",
            })}
          ></textarea>
          {errors.content && <Error content={errors.content.message} />}
        </div>
        <div className="golf-upload">
          <div className="row">
            {previewAttachments.map((att) => (
              <div
                key={att.fileId}
                className="col-4 position-relative mb-4 attachment-wrapper"
              >
                <img
                  src={att.fileUrl}
                  alt={att.fileName}
                  className="img-golf img-fluid"
                />
                <div className="overlay"></div>
                <img
                  onClick={() => onRemovedAttachment(att.fileId)}
                  src={require("assets/images/close-white.svg")}
                  alt="close-white"
                  className="img-close-white"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 file-upload pl-0">
            <div className="title-upload">
              <img
                src={require("assets/images/collections.png")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <span>Upload ảnh/video</span>
            </div>
            <input
              type="file"
              name="ad_file"
              accept=".png, .jpg, .jpeg"
              multiple
              className="media-file"
              onChange={onFileChange.bind(this)}
            />
            {errors.ad_file && <Error content={errors.ad_file.message} />}
            {validateImgMessage && <Error content={validateImgMessage} />}
          </div>
        </div>

        <div className="form-group mt-5">
          <button type="submit" className="btn btn-success rounded-pill w-100">
            Tạo bài viết
          </button>
        </div>
      </form>
    </div>
  );
}

class AddPingPongMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JwtHelper.currentUser(),
    };
  }

  render() {
    return <CreatePingPongForm {...this.props} {...this.state} />;
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      addOrUpdatePost: clubActions.AddOrUpdatePost,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPingPongMobileView);
