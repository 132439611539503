import { Map } from 'immutable';
import { createFormData, AppKey, NewGuid } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { createSaveLoading } from 'core/app.store';
import { HourlyMaidList } from 'mock-data/hourly-maid.mock';
import { AssistType } from 'utils/configuration';
let hourlyMaidListTemp = HourlyMaidList;

const service = new Service();
/** Action Types */
const HOURLYMAIDSAVELIST = 'HourlyMaid/SAVELIST';

/** Actions */
export const createHourlyMaidList = (hourlyMaidListModel) => ({ type: HOURLYMAIDSAVELIST, payload: { hourlyMaidList: hourlyMaidListModel.hourlyMaidList, totalItems: hourlyMaidListModel.totalItems } });

/** Action creators */
export const GetHourlyMaidList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = { ...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistGetList, payLoad).then(res => {
            dispatch(createHourlyMaidList({ hourlyMaidList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetHourlyMaidDetail = (assistId) => {
    return dispatch => {
        let payLoad = createFormData({ assistId: assistId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteHourlyMaid = (assistId) => {
    return dispatch => {
        let payLoad = createFormData({ assistId: assistId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateHourlyMaid = (hourlyMaidModel) => {
    return dispatch => {
        const model = { ...hourlyMaidModel, category_type: AssistType.HourlyMaid };
        let payLoad = createFormData(model, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.AssistCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */

export const GetHourlyMaidListFake = (pageIndex, size, filterObjs) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            hourlyMaidListTemp.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            })
            let hourlyMaidList = hourlyMaidListTemp.slice(pageIndex * size - size, pageIndex * size);
            if (hourlyMaidList) {
                dispatch(createHourlyMaidList({ hourlyMaidList: hourlyMaidList, totalItems: hourlyMaidListTemp.length }))
                resolve({
                    content: {
                        items: hourlyMaidList,
                        totalItems: hourlyMaidList.length
                    },
                    errorType: "",
                    errorMessage: "",
                });
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                });
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetHourlyMaidDetailFake = (hourlyMaidId) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let HourlyMaidDetail = hourlyMaidListTemp.find(fr => fr.hourly_maid_id == hourlyMaidId);
            if (HourlyMaidDetail) {
                resolve({
                    content: HourlyMaidDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const CreateOrUpdateHourlyMaidFake = (hourlyMaidModel) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let newModel;
                let currentIndex = hourlyMaidListTemp.findIndex(fr => fr.hourly_maid_id == hourlyMaidModel.hourly_maid_id);
                if (currentIndex >= 0) {
                    hourlyMaidListTemp[currentIndex] = hourlyMaidModel;
                } else {
                    newModel = { ...hourlyMaidModel, hourly_maid_id: NewGuid() };
                    hourlyMaidListTemp.push(newModel);
                }
                hourlyMaidListTemp.sort((a, b) => {
                    return new Date(b.created_date) - new Date(a.created_date);
                });
                dispatch(createHourlyMaidList({ hourlyMaidList: hourlyMaidListTemp, totalItems: hourlyMaidListTemp.length }))
                resolve({
                    content: newModel,
                    errorType: "",
                    errorMessage: "",
                })
                dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const DeleteHourlyMaidFake = (hourlyMaidId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let currentHourlyMaid = hourlyMaidListTemp.find(item => item.hourly_maid_id == hourlyMaidId);
            let newHourlyMaidList = hourlyMaidListTemp.filter(item => item.hourly_maid_id != hourlyMaidId);
            newHourlyMaidList.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            });
            hourlyMaidListTemp = newHourlyMaidList;
            dispatch(createHourlyMaidList({ hourlyMaidList: hourlyMaidListTemp.slice(0, 10), totalItems: hourlyMaidListTemp.length }))
            if (currentHourlyMaid) {
                resolve({
                    content: {
                        HourlyMaidId: hourlyMaidId,
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                reject({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    hourlyMaidList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function HourlyMaidReducer(state = initialState, action) {
    switch (action.type) {
        case HOURLYMAIDSAVELIST:
            return {
                ...state,
                hourlyMaidList: [...action.payload.hourlyMaidList],
                totalItems: action.payload.totalItems,
            }
        default:
            return state;
    }
}
