import { Map } from 'immutable';
import { createFormData, AppKey, NewGuid } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { createSaveLoading } from 'core/app.store';
import { HelpingHandList } from 'mock-data/helping-hand.mock';
let helpingHandListTemp = HelpingHandList;

const service = new Service();
/** Action Types */
const HELPINGHANDSAVELIST = 'HELPINGHAND/SAVELIST';

/** Actions */
export const createHelpingHandList = (helpingHandListModel) => ({ type: HELPINGHANDSAVELIST, payload: { helpingHandList: helpingHandListModel.helpingHandList, totalItems: helpingHandListModel.totalItems } });

/** Action creators */
export const GetHelpingHandList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = { ...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.HelpingHandGetList, payLoad).then(res => {
            dispatch(createHelpingHandList({ helpingHandList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }));
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetHelpingHandDetail = (helpId) => {
    return dispatch => {
        let payLoad = createFormData({ helpId: helpId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.HelpingHandGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteHelpingHand = (helpId) => {
    return dispatch => {
        let payLoad = createFormData({ helpId: helpId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.HelpingHandDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateHelpingHand = (helpingHandModel) => {
    return dispatch => {
        let payLoad = createFormData(helpingHandModel, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.HelpingHandCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetHelpComments = (pageIndex, size, filterObjs) => {
    return dispatch => {
        const { helpId, ...filter } = filterObjs;
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        formData.append('helpId', helpId);

        let filterObjDatas = { ...filter };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.HelpingHandGetComments, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}


export const CreateOrUpdateComment = (commentModel) => {
    return dispatch => {
        let payLoad = createFormData(commentModel, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.HelpingHandUpdateComment, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateReply = (replyModel) => {
    return dispatch => {
        let payLoad = createFormData(replyModel, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.helpingHandCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteComment = (commentId) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('commentId', commentId);
        let payLoad = createFormData(null, '', formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.HelpingHandDeleteComment, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */

export const GetHelpingHandListFake = (pageIndex, size, filterObjs) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            helpingHandListTemp.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            })
            let helpingHandList = helpingHandListTemp.slice(pageIndex * size - size, pageIndex * size);
            if (helpingHandList) {
                dispatch(createHelpingHandList({ helpingHandList: helpingHandList, totalItems: helpingHandListTemp.length }))
                resolve({
                    content: {
                        items: helpingHandList,
                        totalItems: helpingHandList.length
                    },
                    errorType: "",
                    errorMessage: "",
                });
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                });
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetHelpingHandDetailFake = (helpingHandId) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let helpingHandDetail = helpingHandListTemp.find(fr => fr.help_post_id == helpingHandId);
            if (helpingHandDetail) {
                resolve({
                    content: helpingHandDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetHelpingHandInfoFake = (helpingHandId) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let helpingHandDetail = helpingHandListTemp.find(fr => fr.help_post_id == helpingHandId);
            if (helpingHandDetail) {
                resolve({
                    content: {
                        help_post_id: helpingHandDetail.help_post_id,
                        created_by: helpingHandDetail.created_by,
                        created_by_id: helpingHandDetail.created_by_id,
                        created_date: helpingHandDetail.created_date,
                        post_content: helpingHandDetail.post_content,
                        is_delete: helpingHandDetail.is_delete,
                        profile_url: helpingHandDetail.profile_url,
                        attachments: helpingHandDetail.attachments
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const CreateOrUpdateHelpingHandFake = (helpingHandModel) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let newModel;
                let currentIndex = helpingHandListTemp.findIndex(fr => fr.help_post_id == helpingHandModel.help_post_id);
                if (currentIndex >= 0) {
                    helpingHandListTemp[currentIndex] = helpingHandModel;
                } else {
                    newModel = { ...helpingHandModel, help_post_id: NewGuid() };
                    helpingHandListTemp.push(newModel);
                }
                helpingHandListTemp.sort((a, b) => {
                    return new Date(b.created_date) - new Date(a.created_date);
                });
                dispatch(createHelpingHandList({ helpingHandList: helpingHandListTemp, totalItems: helpingHandListTemp.length }))
                resolve({
                    content: newModel,
                    errorType: "",
                    errorMessage: "",
                })
                dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const DeleteHelpingHandFake = (helpingHandId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let currenthelpingHand = helpingHandListTemp.find(item => item.help_post_id == helpingHandId);
            let newhelpingHandList = helpingHandListTemp.filter(item => item.help_post_id != helpingHandId);
            newhelpingHandList.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            });
            helpingHandListTemp = newhelpingHandList;
            dispatch(createHelpingHandList({ helpingHandList: helpingHandListTemp.slice(0, 10), totalItems: helpingHandListTemp.length }))
            if (currenthelpingHand) {
                resolve({
                    content: {
                        helpingHandId: helpingHandId,
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                reject({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const CreateOrUpdateCommentFake = (commentModel) => {
    return (dispatch) => {
        // dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let currentPostIndex = helpingHandListTemp.findIndex(res => res.help_post_id == commentModel.help_post_id);
                if (currentPostIndex >= 0) {
                    let newComments = [];
                    let currentCommentIndex = helpingHandListTemp[currentPostIndex].comments.findIndex(cm => cm.comment_id == commentModel.id);
                    if (currentCommentIndex >= 0) {
                        let updatedComment = { ...commentModel, created_date: new Date() };
                        newComments = [...helpingHandListTemp[currentPostIndex].comments]
                        newComments[currentCommentIndex] = updatedComment;
                    } else {
                        newComments = [
                            ...helpingHandListTemp[currentPostIndex].comments,
                            {
                                ...commentModel,
                                comment_id: NewGuid(),
                                comment_date: new Date()
                            }];
                    }
                    helpingHandListTemp[currentPostIndex].comments = newComments;
                    dispatch(createHelpingHandList({ helpingHandList: helpingHandListTemp.slice(0, 10), totalItems: helpingHandListTemp.length }))
                    resolve({
                        content: {
                            help_post_id: commentModel.help_post_id,
                        },
                        errorType: "",
                        errorMessage: "",
                    })
                } else {
                    reject({
                        content: null,
                        errorType: "NotFound",
                        errorMessage: "Empty",
                    })
                }
                // dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const CreateOrUpdateReplyFake = (replyModel) => {
    return (dispatch) => {
        // dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let currentPostIndex = helpingHandListTemp.findIndex(res => res.help_post_id == replyModel.help_post_id);
                if (currentPostIndex >= 0) {
                    let currentComments = helpingHandListTemp[currentPostIndex].comments.find(cm => cm.comment_id == replyModel.comment_id);

                    if (!currentComments) {
                        reject({
                            content: null,
                            errorType: "NotFound",
                            errorMessage: "Empty",
                        })
                        return;
                    }
                    let commentIndex = helpingHandListTemp[currentPostIndex].comments.findIndex(cm => cm.comment_id == replyModel.comment_id);
                    let newComments, newReplies = [];
                    let currentReplyIndex = currentComments.replies.findIndex(rp => rp.reply_id == replyModel.id);
                    if (currentReplyIndex >= 0) {
                        let updatedReply = { ...replyModel, created_date: new Date() };
                        newComments = [...helpingHandListTemp[currentPostIndex].comments]
                        newComments[commentIndex].replies[currentReplyIndex] = updatedReply;
                    } else {
                        newReplies = [
                            ...helpingHandListTemp[currentPostIndex].comments.find(cm => cm.comment_id == replyModel.comment_id).replies,
                            {
                                ...replyModel,
                                reply_id: NewGuid(),
                                reply_date: new Date()
                            }];
                        newComments = [...helpingHandListTemp[currentPostIndex].comments];
                        newComments[commentIndex].replies = [...newReplies];
                    }
                    helpingHandListTemp[currentPostIndex].comments = newComments;
                    dispatch(createHelpingHandList({ helpingHandList: helpingHandListTemp.slice(0, 10), totalItems: helpingHandListTemp.length }))
                    resolve({
                        content: {
                            help_post_id: replyModel.help_post_id,
                        },
                        errorType: "",
                        errorMessage: "",
                    })
                } else {
                    reject({
                        content: null,
                        errorType: "NotFound",
                        errorMessage: "Empty",
                    })
                }
                // dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const DeleteCommentFake = (commendModel) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let currentPostIndex = helpingHandListTemp.findIndex(res => res.help_post_id == commendModel.help_post_id);
            if (currentPostIndex >= 0) {
                let newComments = [];
                if (commendModel.type == 'comment') {
                    newComments = [...helpingHandListTemp[currentPostIndex].comments.filter(cm => cm.comment_id != commendModel.id)];
                } else {
                    let currentCommentIndex = helpingHandListTemp[currentPostIndex].comments.findIndex(cm => cm.comment_id == commendModel.comment_id);
                    let newReplies = [...helpingHandListTemp[currentPostIndex].comments.find(cm => cm.comment_id == commendModel.comment_id).replies.filter(rp => rp.reply_id != commendModel.id)];
                    newComments = [...helpingHandListTemp[currentPostIndex].comments];
                    newComments[currentCommentIndex].replies = [...newReplies];
                }
                helpingHandListTemp[currentPostIndex].comments = newComments;
                dispatch(createHelpingHandList({ helpingHandList: helpingHandListTemp.slice(0, 10), totalItems: helpingHandListTemp.length }))
                resolve({
                    content: {
                        help_post_id: commendModel.help_post_id,
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                reject({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    helpingHandList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function HelpingHandReducer(state = initialState, action) {
    switch (action.type) {
        case HELPINGHANDSAVELIST:
            return {
                ...state,
                helpingHandList: [...action.payload.helpingHandList],
                totalItems: action.payload.totalItems,
            }
        default:
            return state;
    }
}
