import React, { Component } from "react";
import Viewer, { Worker, SpecialZoomLevel, defaultLayout } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import { RenderNotHaveDataImage } from '../../../components/warning-component/not-have-data';
import { renderError } from 'components/pdf-error-rendering/pdf-error-rendering';
import * as QualityDocumentStore from 'redux/stores/safe/quality-document.store';
import { PromiseAllTools } from 'core/app.store';

import { browserVersion, isSafari } from "react-device-detect";

if (isSafari === true && browserVersion <= 11) {
    require('intersection-observer');
}

class WaterQualityDetailMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            notCallApi: true
        }
    }

    componentDidUpdate(){
        if (this.state.notCallApi&&(this.props.detail)) this.setState({
            notCallApi: false
        })
    }

    componentDidMount() {
        this.props.PromiseAllTools([this.props.GetQualityDocumentDetail(Number(this.props.match.params.waterSafeId))])
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const data = this.props.detail || {};

        const renderToolbar = (toolbarSlot) => {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                        justifyContent: 'center',
                    }}>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                    </div>

                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        marginLeft: 'auto',
                    }}>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.printButton}
                        </div>
                    </div>
                </div>
            );
        };

        const layoutPDFView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        return (
            <div className="water-quality-detail-mobile">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/an-toan/chat-luong-nuoc">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Chất lượng nước</div>
                            </div>
                        </div>
                    </div>
                </header>

                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <div className="pdf-view-container">
                        {(data.haveData) ? <Viewer fileUrl={data.fileUrl} defaultScale={SpecialZoomLevel.PageFit} layout={layoutPDFView} renderError={renderError} /> : <RenderNotHaveDataImage notCallApi={this.state.notCallApi}/>}
                    </div>
                </Worker>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    detail: state.qualityDocument.detail
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetQualityDocumentDetail: QualityDocumentStore.GetQualityDocumentDetail,
            PromiseAllTools
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(WaterQualityDetailMobileView);
