import React, { Component } from 'react';
import '../message-box/message-box-desktop.scss';

class MessageBoxDesktopView extends Component {
  render() {
    return (
      <div className="col-8">
        <div className="bg-white mt-4">
          <div className="message-box">
            <div className="message-header">
              <div className="row align-items-center">
                <div className="col-6">
                  <img src={require('assets/images/chi-hang.jpg')} alt="Message" className="img-fluid rounded-circle" /> Trần Thu Hương
                </div>
                <div className="col-6 text-right">
                  <button className="btn btn-success">Cho đi nhờ</button> <button className="btn btn-primary ml-3">Thêm bạn bè</button>
                </div>
              </div>
            </div>
            <div className="message-body">
              <div className="message-from-friend">
                <ul>
                  <li>
                    <span>Chào Hương</span>
                  </li>
                </ul>
              </div>
              <div className="message-from-me">
                <img src={require('assets/images/chi-hang.jpg')} alt="Message" className="img-fluid rounded-circle" />
                <ul>
                  <li>
                    <span>Chào bạn</span>
                  </li>
                  <li>
                    <span>Mình có thể giúp gì cho bạn</span>
                  </li>
                </ul>
              </div>
              <div className="message-from-friend">
                <ul>
                  <li>
                    <span>Bạn còn cà chua không</span>
                  </li>
                </ul>
              </div>
              <div className="message-from-me">
                <img src={require('assets/images/chi-hang.jpg')} alt="Message" className="img-fluid rounded-circle" />
                <ul>
                  <li>
                    <span>Bạn muốn lấy  bao nhiêu kg</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="message-footer">
              <div className="row align-items-center">
                <div className="col-1">
                  <img src={require('assets/images/insert-photo-gray.svg')} alt="insert-photo-gray" className="img-fluid" />
                </div>
                <div className="col-10">
                  <input type="text" className="form-control rounded-pill" placeholder="Aa" />
                </div>
                <div className="col-1">
                  <img src={require('/assets/images/send-gray.svg')} alt="send" className="img-fluid img-send" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageBoxDesktopView;