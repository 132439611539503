import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";

import AppReducer from "../core/app.store";
import AuthReducer from "../core/auth.store";

//--- User
import LoginReducer from "./stores/user/login.store";

//--- Shop
import ShopReducer from "./stores/shop/shop.store";

//--- Home
import HomeMobileReducer from "./stores/advertising/advertising.store";

//--- Notification
import NotificationsReducer from "./stores/notification/notifications.store";

//--- Reducer Pay
import ElectricBillReducer from "./stores/payment/electric/electric.store";
import WaterBillReducer from "./stores/payment/water/water.store";
import OtherBillReducer from "./stores/payment/other-services/other-service.store";
import InternetBillReducer from "./stores/payment/internet/internet.store";

//--- Hot
import HotReducer from "./stores/hot/hot.store";

//--- Slider
import SliderReducer from "./stores/slider/slider.store";

//--- New
import NewReducer from "./stores/news/news.store";

//--- Safe
import IncidentsAreaReducer from "../redux/stores/safe/incidents-area.store";
import QualityDocumentReducer from "../redux/stores/safe/quality-document.store";

//-- Utilities desktop
import PoolReducer from "./stores/utillities/pool.store";
import GymReducer from "./stores/utillities/gym-desktop.store";
import TennisReducer from "./stores/utillities/tennis.store";
import BbqReducer from "./stores/utillities/bbq.store";
import UtilitisShopReducer from "./stores/utillities/utilitis-shop.store";

//--- House rent and sale reduce
import AdHouseRentReducer from "../modules/advertising-desktop/buy-and-rent-houses/buy-and-rent-houses.store";
import RentToMarketReducer from "./stores/rent-to-market/rent-to-market.store";
import HourlyMaidReducer from "./stores/hourly-maid/hourly-maid.store";

//--- Utilities Plus
import RepairingHouseholdReducer from "./stores/utilities-plus/repairing-household.store";
import HitchhikeReducer from "./stores/utilities-plus/hitchhike.store";
import BusScheduleReducer from "./stores/utilities-plus/bus-schedule.store";

//--- Legal
import LegalReducer from "../redux/stores/management/legal.store";

//--- Friend
import FriendReducer from "./stores/friend/friend.store";

//--- Building Management
import BuildingManagementReducer from "./stores/management/building-management.store";

//--- Search
import SearchReducer from "./stores/search/search.store";

//--- Resident Market
import ResidentMarketReducer from "./stores/resident/resident-market.store";

//--- Helping Hand
import HelpingHandReducer from "./stores/help/helping-hand.store";

//--- Joins Hand
import JoinsHandReducer from "./stores/join-hand/join-hand.store";

//--- Procedure
import ProcedureReducer from "../redux/stores/management/procedure.store";

//--- Schedule
import ScheduleReducer from "./stores/management/schedule.store";

//--- Security Camera
import SecurityCameraReducer from "./stores/management/security-camera.store";

//--- Member
import MemberReducer from "./stores/management/member.store";

//--- Document
import DocumentReducer from "./stores/document/document.store";

//-- Get content by keyword
import ReducerContentByKeyWord from "./stores/get-content-by-keyword/getContentByKeyword.store";

//-- Loading stack
import LoadingStackReducer from "./stores/loading-stack/loading-stack.store";

//-- Project
import ProjectReducer from "./stores/project/project.store";

export default combineReducers({
  form: formReducer, // redux form,
  router: routerReducer,
  app: AppReducer,
  auth: AuthReducer,
  login: LoginReducer,

  //-- shop
  shop: ShopReducer,

  //-- home mobile
  homeMobile: HomeMobileReducer,

  //-- notification
  notifications: NotificationsReducer,

  //-- utilities destop
  pool: PoolReducer,
  gym: GymReducer,
  tennis: TennisReducer,
  bbq: BbqReducer,
  utilitiShop: UtilitisShopReducer,

  //--safe
  incidentsArea: IncidentsAreaReducer,
  qualityDocument: QualityDocumentReducer,

  //--- Pay
  electricBill: ElectricBillReducer,
  waterBill: WaterBillReducer,
  otherBill: OtherBillReducer,
  internetBill: InternetBillReducer,

  //--- Hot
  hot: HotReducer,

  //--- Slider
  slider: SliderReducer,

  //--- News
  new: NewReducer,

  // house rent sale ,rent to market
  rentToMarket: RentToMarketReducer,
  hourlyMaid: HourlyMaidReducer,
  adHouseRent: AdHouseRentReducer,

  //--- Utilities Plus
  repairingHousehold: RepairingHouseholdReducer,
  hitchhike: HitchhikeReducer,
  bus: BusScheduleReducer,

  //---- Legal
  legal: LegalReducer,

  //---- Friend
  friend: FriendReducer,

  //--- Building Management
  buildingManagement: BuildingManagementReducer,
  //--- search
  search: SearchReducer,

  //--- search
  residentMarket: ResidentMarketReducer,

  //--- helping hand
  helpingHand: HelpingHandReducer,

  //--- helping hand
  joinsHand: JoinsHandReducer,

  //---- Procedure
  procedure: ProcedureReducer,

  //---- Schedule
  schedule: ScheduleReducer,

  //---- Security Camera
  securityCamera: SecurityCameraReducer,

  //---- Member
  member: MemberReducer,

  //--- Document
  document: DocumentReducer,
  //-- Get content by keyword
  contentByKeyword: ReducerContentByKeyWord,

  //-- Loading stack
  loadingStack: LoadingStackReducer,

  //-- Project
  project: ProjectReducer,
});
