import { Map } from 'immutable';
import { createSaveLoading } from 'core/app.store';
import Service from 'services/api.service';
import { isMobile } from "react-device-detect";
import { createFormData, AppKey } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';

// const type search
export const NEWSTYPE = "NEWS";
export const HOTTYPE = "hot";
export const BQLDOCUMENTTYPE = "BQLDOCUMENT";
export const BQLPROCEDURETYPE = "BQLPROCEDURE";
export const CAFEUTILITYTYPE = "CAFEUTILITY";
export const RESTAURANTUTILITYTYPE = "RESTAURANTUTILITY";
export const SCHOOLUTILITYTYPE = "SCHOOLUTILITY";
export const SUPERMARKETUTILITYTYPE = "SUPERMARKETUTILITY";
export const JOINHANDSTYPE = "JOINHANDS";
export const RESIDENTMARKETTYPE = "RESIDENTMARKET";
export const SALEANDRENTHOUSETYPE = "SALEANDRENTHOUSE";
export const SAFETYMALFUNCTIONTYPE = "SAFETYMALFUNCTION";
export const SAFEAIRQUALITYTYPE = "SAFEAIRQUALITY";
export const SAFEWATERQUALITYTYPE = "SAFEWATERQUALITY";

const service = new Service();
const INITSEARCHLISTRESULT = "SEARCH/SEARCHRESULTLIST"
const INITTOTALITEMS = "SEARCH/TOTALITEMS";
const INITHOTSEARCHKEYWORDS = "SEARCH/HOTKEYWORDS";

const InitSearchListResult = data => ({
    type: INITSEARCHLISTRESULT, data: data
})

const InitTotalItems = data => ({
    type: INITTOTALITEMS, data: data
})

const InitHotSearchKeyword = data => ({
    type: INITHOTSEARCHKEYWORDS, data: data
})

export const GetListHotSearchKeyword = (size = 8) => {
    return dispatch => {
        let rawData = null;
        let formData = new FormData();
        formData.append('pageIndex', 1);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.listHotSearchKeyword, payLoad).then(res => {
            rawData = res.content;
            return true;
        }).catch(err => {
            // console.log(err);
        }).finally(() => {
            dispatch(createSaveLoading(false));
            if (rawData) {
                dispatch(InitHotSearchKeyword(ExchangeRawDataGetListHotSearchKeyword(rawData)))
            }
        })
    }
}

export const GetSearchResulList = (start = 1, size = 10, keyWord = "") => {
    return dispatch => {
        let rawData = null;
        let formData = new FormData();
        formData.append('pageIndex', start);
        formData.append('size', size);
        formData.append('filter[text]', keyWord);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.searchContent, payLoad).then(res => {
            rawData = res.content;
            return true;
        }).catch(err => {
            // console.log(err);
        }).finally(() => {
            dispatch(createSaveLoading(false));
            if (rawData) {
                dispatch(InitSearchListResult(ExchangeSearchResult(rawData.items)))
                dispatch(InitTotalItems(rawData.totalItemCount))
            }
        })
    }
}

const initState = Map({
    resultList: [],
    totalResult: 0,
    hotSearchKeywords: [],
})

export default function SearchReducer(state = initState, action) {
    switch (action.type) {
        case INITTOTALITEMS:
            return {
                ...state,
                totalResult: action.data
            }
        case INITSEARCHLISTRESULT:
            return {
                ...state,
                resultList: action.data
            }
        case INITHOTSEARCHKEYWORDS:
            return {
                ...state,
                hotSearchKeywords: action.data
            }
        default:
            return state
    }
}

function GetBaseUrl(type, isMobilePhone = false) {
    if (isMobilePhone) {
        switch (type) {
            case NEWSTYPE: return "/m/cuoc-song/";
            case HOTTYPE: return "/m/hot/";
            case BQLDOCUMENTTYPE: return "/m/van-ban-phap-ly/";
            case BQLPROCEDURETYPE: return "/m/thu-tuc-bql/van-ban/";
            case CAFEUTILITYTYPE: return "/m/tien-ich/cafe/";
            case RESTAURANTUTILITYTYPE: return "/m/tien-ich/nha-hang/";
            case SCHOOLUTILITYTYPE: return "/m/tien-ich/truong-hoc/";
            case SUPERMARKETUTILITYTYPE: return "/m/tien-ich/sieu-thi/";
            case JOINHANDSTYPE: return "/m/lang-gieng-gan/chung-tay/chi-tiet/";
            case RESIDENTMARKETTYPE: return "/m/cho-cu-dan/chi-tiet/";
            case SALEANDRENTHOUSETYPE: return "/m/mua-ban-nha/";
            case SAFETYMALFUNCTIONTYPE: return "/m/an-toan/su-co-trong-khu/";
            case SAFEAIRQUALITYTYPE: return "/m/an-toan/chat-luong-nuoc/chi-tiet/";
            case SAFEWATERQUALITYTYPE: return "/m/an-toan/chat-luong-nuoc/chi-tiet/";
        }
    } else {
        switch (type) {
            case NEWSTYPE: return "/cuoc-song/";
            case HOTTYPE: return "/hot/";
            case BQLDOCUMENTTYPE: return "/van-ban-phap-ly/";
            case BQLPROCEDURETYPE: return "/thu-tuc-bql/chi-tiet/";
            case CAFEUTILITYTYPE: return "/tien-ich/cafe/";
            case RESTAURANTUTILITYTYPE: return "/tien-ich/nha-hang/";
            case SCHOOLUTILITYTYPE: return "/tien-ich/truong-hoc/";
            case SUPERMARKETUTILITYTYPE: return "/tien-ich/sieu-thi/";
            case JOINHANDSTYPE: return "/lang-gieng-gan/chung-tay/";
            case RESIDENTMARKETTYPE: return "/rao-vat/cho-cu-dan/chi-tiet/";
            case SALEANDRENTHOUSETYPE: return "/rao-vat/mua-ban-nha/";
            case SAFETYMALFUNCTIONTYPE: return "/su-co-trong-khu/chi-tiet/";
            case SAFEAIRQUALITYTYPE: return "/xem-chat-luong-khong-khi/chi-tiet/";
            case SAFEWATERQUALITYTYPE: return "/xem-chat-luong-nuoc/chi-tiet/";
        }
    }
}

function ExchangeRawDataGetListHotSearchKeyword(rawData) {
    let standardData = [];
    rawData.items && rawData.items.map((data) => {
        standardData.push({
            keywordId: data.keywordId,
            keywordName: data.keywordName
        })
    })
    return standardData
}

function ExchangeSearchResult(rawData) {
    const isMobilePhone = isMobile;
    if (rawData) {
        const result = [];
        rawData.map((data) => {
            result.push({
                url: `${GetBaseUrl(data.moduleName, isMobilePhone) + data.recordId}`,
                title: data.title
            })
        })
        return result;
    } else return []
}