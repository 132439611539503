/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shopActions from "redux/stores/shop/shop.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { NotificationMessageType } from "utils/configuration";
import { history } from "redux/store";
import { Link } from "react-router-dom";
import RenderNotificationBell from "components/header/notification-bell.view.jsx";

import "./user-shop-mobile.scss";

import BookingView from "./booking/booking-mobile.view";
import BookingTennisView from "./booking/tennis-mobile.view";
import BookingBbqView from "./booking/bbq-mobile.view";
import BookingBeautyView from "./booking/beauty-mobile.view";
import BookingClinicView from "./booking/clinic-mobile.view";
import BookingCommunityView from "./booking/community-mobile.view";
import BookingFootballView from "./booking/football-mobile.view";
import BookingGarageView from "./booking/garage-mobile.view";
import BookingGymView from "./booking/gym-mobile.view";
import BookingOfficeView from "./booking/office-mobile.view";
import BookingPoolView from "./booking/pool-mobile.view";

function RenderBookingView(props) {
  switch (props.type) {
    case shopActions.SHOP_TYPE.CAFE:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.NHA_HANG:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.SIEU_THI:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.TRUONG_HOC:
      return <BookingView {...props} />;
    case shopActions.SHOP_TYPE.TENNIS:
      return <BookingTennisView {...props} />;
    case shopActions.SHOP_TYPE.POOL:
      return <BookingPoolView {...props} />;
    case shopActions.SHOP_TYPE.FOOTBALL:
      return <BookingFootballView {...props} />;
    case shopActions.SHOP_TYPE.BBQ:
      return <BookingBbqView {...props} />;
    case shopActions.SHOP_TYPE.BEAUTY:
      return <BookingBeautyView {...props} />;
    case shopActions.SHOP_TYPE.OFFICE:
      return <BookingOfficeView {...props} />;
    case shopActions.SHOP_TYPE.GARAGE:
      return <BookingGarageView {...props} />;
    case shopActions.SHOP_TYPE.COMMUNITY:
      return <BookingCommunityView {...props} />;
    case shopActions.SHOP_TYPE.GYM:
      return <BookingGymView {...props} />;
    case shopActions.SHOP_TYPE.CLINIC:
      return <BookingClinicView {...props} />;
    default:
      return null;
  }
}

class UserShopMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shopId: this.props.match.params.id,
      shopType:
        (this.props.match.params.type &&
          this.props.match.params.type.toUpperCase()) ||
        "",

      shop: null,
      booking: null,
    };
  }

  UNSAFE_componentWillMount() {
    let { shopId, shopType } = this.state;
    if (shopId && shopType) this.onGetShopInformation(shopId, shopType);
    else history.push("/m/thong-tin-ca-nhan");
  }

  onGetShopInformation = (shopId, shopType) => {
    this.props.onGetShopDetail(shopId, shopType).then(
      (res) => {
        if (!res) {
          AddNotification(
            viVN.Errors[(res && res.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
          history.push("/m/thong-tin-ca-nhan");
          return;
        }
        this.setState({
          shop: res,
        });
      },
      (err) => {
        history.push("/m/thong-tin-ca-nhan");
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  render() {
    const state = this.state;
    return (
      <div className="user-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <Link to="/m/thong-tin-ca-nhan">
                      <img
                        src={require("assets/images/arrow-back.svg")}
                        alt="Arrow Back"
                        className="img-fluid img-arrow-back"
                      />
                    </Link>
                  </div>
                  <div className="col-4 header-title text-center">User</div>
                  <div className="col-4 text-right">
                    <RenderNotificationBell forMobile={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {state.shop && (
          <div>
            <div className="bg-white p-3 user-shop-header">
              <div className="row align-items-center no-gutters">
                <div className="col-2 col-sm-1">
                  <img
                    src={
                      state.shop.avatarUrl ||
                      require("assets/images/highlight-restaurant-avatar.png")
                    }
                    className="img-fluid img-avatar"
                    alt={state.shop.shopName}
                  />
                </div>
                <div className="col-10 col-sm-11">
                  <h5 className="title-md mb-2 mt-1">{state.shop.shopName}</h5>
                  <div>
                    <Link
                      to={`/m/shop/thong-tin-gian-hang/${state.shopType.toLocaleLowerCase()}/${
                        state.shopId
                      }`}
                      className="mr-4"
                    >
                      <img
                        src={require("assets/images/edit-blue.svg")}
                        className="img-fluid"
                        alt="Thông tin gian hàng"
                      />
                      Thông tin gian hàng
                    </Link>
                    <Link
                      to={`/m/tin-khuyen-mai/${state.shopType.toLocaleLowerCase()}/${
                        state.shopId
                      }`}
                    >
                      <img
                        src={require("assets/images/edit-blue.svg")}
                        className="img-fluid"
                        alt="Tin khuyến mãi"
                      />
                      Tin khuyến mãi
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {state.shop.hasSlots && state.shop.hasSlots == 1 && (
              <div>
                <div className="mobile-divide"></div>

                <Link
                  to={`/m/setup-lich/${state.shopType.toLowerCase()}/${
                    state.shopId
                  }`}
                  className="calendar-scheduler p-3"
                >
                  <img
                    src={require("assets/images/calendar-blue.svg")}
                    className="img-fluid ic-calendar mr-2"
                  />
                  Setup lịch sân
                  <img
                    src={require("assets/images/right-arrow-blue.svg")}
                    className="img-fluid ic-arrow float-right"
                  />
                </Link>
              </div>
            )}

            {state.shop.isBooking && state.shop.isBooking == 1 && (
              <div>
                <div className="mobile-divide"></div>
                <RenderBookingView
                  type={state.shopType}
                  shopId={state.shopId}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetShopDetail: shopActions.GetShopDetail,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserShopMobileView);
