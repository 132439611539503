import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../management-desktop.scss";
import * as procedureActions from 'redux/stores/management/procedure.store';
import DocumentRendering from 'components/document-rendering/document-rendering';
import PaginationControl from 'components/pagination/pagination.view';

class ProcedureListDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: this.props.match.params.categoryId,
      procedureCategories: [],
      isLoading: false,
      currentPageIndex: 1
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProcedureList(this.state.currentPageIndex, 10, { category_id: this.state.categoryId });
    this.onGetDropdownData();
  }

  onGetProcedureList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getProcedureList(pageIndex, size, filterObjs).then(res => {

    }, err => { 
    })
  }

  onGetDropdownData = () => {
    this.props.getProcedureCategories(1, 10, {}).then(res => {
      this.setState({
        procedureCategories: res.content,
      })
    }).catch(err => { 
    })
  };

  onFilterChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      currentPageIndex: 1
    }, () => {
      this.onGetProcedureList(1, 10, this.state.categoryId ? { category_id: this.state.categoryId } : null);
    });
  }

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetProcedureList(pageNumber);
    })
  }


  render() {
    const { procedureList, totalItems } = this.props;
    const { procedureCategories, currentPageIndex, isLoading, categoryId } = this.state;

    return (
      <div className="management-desktop">
        <h2 className="title-lg mb-0">Văn bản, biểu mẫu quản lý</h2>
        <hr className="mt-2" />

        <div className="row">
          <div className="col-6"></div>
          <div className="col-6 text-right">
            <form>
              <select className="form-control" disabled={isLoading} name="categoryId" onChange={this.onFilterChange.bind(this)} value={categoryId}>
                <option value="">Chọn loại biểu mẫu</option>
                {procedureCategories.map(item =>
                  <option key={item.categoryId} value={item.categoryId}>{item.categoryName}</option>
                )}
              </select>
            </form>
          </div>
        </div>

        <div className="row mt-3">
          {
            procedureList.map((item) =>
              <div key={item.id} className="col-3">
                <DocumentRendering
                  file={item}
                  iconUrl={require('assets/images/thu-tuc-bql.png')}
                  redirectUrl={`/thu-tuc-bql/chi-tiet/${item.id}`}
                />
              </div>)
          }
          {!procedureList.length && <div className="col-12">Không có bản ghi</div>}
        </div>
        <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  procedureList: state.procedure.procedureList,
  totalItems: state.procedure.totalItems
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProcedureList: procedureActions.GetProcedureList,
      getProcedureCategories: procedureActions.GetProcedureCategories,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureListDesktopView);