import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import './feedback-mobile.scss';
import ReportIncidentsFormMobile from './report-incidents.form.jsx';
import GeneralSuggestionsFormMobile from './general-suggestions.form.jsx';
import { TokenKey, getCookies } from 'utils/configuration';

class FeedbackMobileView extends Component {
    render() {
        const isLogin = getCookies(TokenKey.AuthToken) ? true : false;
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Gửi ý kiến</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="feedback-mobile">
                    <Tab.Container defaultActiveKey="gop-y-chung">
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="gop-y-chung">Góp ý chung</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link eventKey="bao-su-co">Báo sự cố</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="gop-y-chung">
                                <GeneralSuggestionsFormMobile isLogin={isLogin} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="bao-su-co">
                                <ReportIncidentsFormMobile isLogin={isLogin} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        );
    }
}

export default FeedbackMobileView;