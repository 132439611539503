/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-bootstrap/Modal";
import * as registrationActions from "redux/stores/user/registration.store";
import "../terms-of-service/terms-of-service.scss";

function TermsOfServiceDesktopView(props) {
  const [show, setShow] = useState(false);
  const [policy, setPolicy] = useState("");

  useEffect(() => {
    props.onGetPolicy().then((res) => {
      if (res && res.content && res.content.policy) {
        setPolicy(res.content.policy);
      }
    });
  }, []);

  const handleClose = () => setShow(false);

  return (
    <div>
      <h5 className="term-title" onClick={() => setShow(true)}>
        Điều khoản & Điều kiện sử dụng
      </h5>
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          className="modal-term modal-dialog-scrollable"
        >
          <Modal.Header>
            <div className="pl-3">
              <img
                src={require("/assets/images/arrow-back.svg")}
                alt="Arrow Back"
                className="img-fluid img-arrow-back"
                onClick={handleClose}
              />
            </div>
            <Modal.Title>Điều khoản sử dụng</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p dangerouslySetInnerHTML={{ __html: policy }}></p>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetPolicy: registrationActions.GetPolicy,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsOfServiceDesktopView);
