import React, { Component } from "react";
import * as documentActions from "redux/stores/document/document.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "redux/store";
import { Link } from "react-router-dom";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import "../../document-management/document-management-mobile.scss";

class DocumentDetailMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      id: this.props.match.params.id,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.loggedIn && this.state.id) {
      this.onGetDocument(this.state.id);
    } else {
      history.push("/m/quan-ly-giay-to");
    }
  }

  onGetDocument(id) {
    this.props.onGetDocumentDetail(id).then(
      (res) => {
        if (res && res.content) {
          this.setState({
            data: res.content,
          });
        } else {
          AddNotification(
            viVN.Errors[res && res.errorType],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[err && err.errorType],
          NotificationMessageType.Error
        );
        history.push("/m/quan-ly-giay-to");
      }
    );
  }

  render() {
    const { data } = this.state;
    console.log(data);
    return <div>{data && <Detail data={data} />}</div>;
  }
}

function Detail(props) {
  const { data } = props.data;
  return (
    <div>
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m/quan-ly-giay-to">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">
                {props.data.documentName}
              </div>
            </div>
          </div>
        </div>
      </header>

      {props.data && (
        <div className="document-management-detail p-3">
          <span>{props.data.uploadDate}</span>

          <img
            src={
              props.data.fileUrl || require("assets/images/quan-ly-giay-to.png")
            }
            alt="Quan ly giay to"
            className="img-fluid"
          />

          <p className="mt-2 mb-0">{props.data.content}</p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetDocumentDetail: documentActions.GetDocumentDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDetailMobileView);
