/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as residentMarketActions from 'redux/stores/resident/resident-market.store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import ResidentMarketAttachmentMobileView from '../childs/resident-market-attachment-mobile.view.jsx';
import ResidentMarketCommentMobileView from '../childs/resident-market-comment-mobile.view.jsx';
import { Link } from 'react-router-dom';
import { JwtHelper } from "utils/jwt-helper";
import SelectableContext from "react-bootstrap/SelectableContext";
import './detail-resident-market-mobile.scss';
import '../resident-market-mobile.scss';

class DetailResidentMarketMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: JwtHelper.currentUser(),
            redientMarketPost: null,
            residentMarketId: this.props.match.params.residentMarketId,
        }
    }

    UNSAFE_componentWillMount() {
        this.onGetResidentMarketPostDetail();
    }

    onGetResidentMarketPostDetail = () => {
        if (this.state.residentMarketId) {
            this.props.getResidentMarketDetail(this.state.residentMarketId).then(res => {
                if (res.content) {
                    this.setState({
                        redientMarketPost: res.content
                    })
                } else {
                    history.push('/rao-vat/cho-cu-dan');
                }
            }, () => {
            })
        }
    }

    onToggleEditPopup = (isOpen, post_id) => {
        this.setState({
            isOpenEditPopup: isOpen,
            selectedPostId: post_id
        })
    }

    onToggleDeletePopup = (isOpen, post_id) => {
        this.setState({
            isOpenDeletePopup: isOpen,
            selectedPostId: post_id
        })
    }

    onDeleteResidentMarketPost = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteResidentMarket(this.state.selectedPostId).then(() => {
                this.onToggleDeletePopup(false);
                AddNotification('Xoá bài viết thành công!', NotificationMessageType.Success);
            }, () => {
                this.onToggleDeletePopup(false);
            })
        }
    }

    render() {
        const { loggedIn } = this.props;
        const { redientMarketPost, isOpenDeletePopup, currentUser } = this.state;
        return (
            <div className="advertising-mobile">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/rao-vat/cho-cu-dan">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Rao vặt</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="resident-market-mobile detail-resident-market-mobile p-3">
                    {redientMarketPost && <div className="resident-market-item p-2 rounded">
                        <div className="item-header">
                            <div className="row no-gutters">
                                <div className="col-1">
                                    <img src={redientMarketPost.profileUrl || require('assets/images/29-01.png')} className="img-fluid img-avatar" alt="Avatar" />
                                </div>
                                <div className="col-10 pl-3">
                                    <h5 className="fullname mb-0 mt-1"><Link to={`/rao-vat/cho-cu-dan/chi-tiet/${redientMarketPost.adId}`}> {redientMarketPost.customerName}</Link></h5>
                                    <span className="datetime">{moment(redientMarketPost.createDate).fromNow()}</span>
                                </div>
                                {loggedIn && redientMarketPost.customerId == currentUser.customerId && <div className="col-1 text-right">
                                    <SelectableContext.Provider value={false}>
                                        <Dropdown alignRight>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                <img src={require('assets/images/more.svg')} className="img-fluid img-more" alt="more" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <div>
                                                        <Link to={`/m/cho-cu-dan/sua-san-pham/${redientMarketPost.adId}`}>
                                                            <img src={require('assets/images/pen.svg')} className="img-fluid img-pen" alt="pen" />
                                                            <span> Chỉnh sửa bài viết</span>
                                                        </Link>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => this.onToggleDeletePopup(e, true, redientMarketPost.adId)}>
                                                    <img src={require('assets/images/delete.svg')} className="img-fluid img-delete" alt="pen" />
                                                    <span>Xóa</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </SelectableContext.Provider>
                                </div>}
                            </div>
                        </div>
                        <div className="item-body">
                            <p className="description" dangerouslySetInnerHTML={{ __html: redientMarketPost.content }}></p>
                            {/* Render images */}
                            <ResidentMarketAttachmentMobileView attachments={redientMarketPost.adFile || []} adId={redientMarketPost.adId} />

                            <div className="resident-market-comments">
                                {/* Comments */}
                                <ResidentMarketCommentMobileView comments={redientMarketPost.comments || []} adId={redientMarketPost.adId} />
                            </div>
                        </div>
                    </div>}

                    <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Xóa bài viết?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Bài viết sẽ không thể khôi phục sau khi xóa.
                    <br />
                            Bạn có chắc muốn xóa bài viết này.
                </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                            <Button variant="success" onClick={() => this.onDeleteResidentMarketPost(true)}>Xóa</Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getResidentMarketDetail: residentMarketActions.GetResidentMarketDetail,
    deleteResidentMarket: residentMarketActions.DeleteResidentMarket,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DetailResidentMarketMobileView)