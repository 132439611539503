import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './utilities-list-desktop.scss';
import * as PageUrls from '../../PageUrls';
class UtilitiesListDesktopView extends Component {
    render() {
        return (
            <div className="utilities-list-desktop">
                <h2 className="mb-4">Tiện ích</h2>

                <div className="row">
                    <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/the-thao">
                            <img src={require('assets/images/gym.svg')} alt="Thể thao" />
                        </Link>
                        <h5>
                            <Link to={PageUrls.UtilitiesSportHomeDesktop}>Thể thao</Link>
                        </h5>
                    </div>

                    {/* <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/phong-kham">
                            <img src={require('assets/images/clinic.svg')} alt="Phòng khám" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/phong-kham">Phòng khám</Link>
                        </h5>
                    </div> */}

                    <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/lam-dep">
                            <img src={require('assets/images/spa.svg')} alt="Làm đẹp" />
                        </Link>
                        <h5>
                            <Link to={PageUrls.UtilitiesSpaHomeDeskTop}>Làm đẹp</Link>
                        </h5>
                    </div>

                    <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/nha-hang">
                            <img src={require('assets/images/nha-hang.svg')} alt="Nhà hàng" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/nha-hang">Nhà hàng</Link>
                        </h5>
                    </div>

                    <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/cafe">
                            <img src={require('assets/images/cafe.svg')} alt="Cafe" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/cafe">Cafe</Link>
                        </h5>
                    </div>

                    <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/sieu-thi">
                            <img src={require('/assets/images/sieu-thi.svg')} alt="Siêu thị" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/sieu-thi">Siêu thị</Link>
                        </h5>
                    </div>

                    <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/truong-hoc">
                            <img src={require('assets/images/truong-hoc.svg')} alt="Trường học" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/truong-hoc">Trường học</Link>
                        </h5>
                    </div>

                    {/* <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/nha-cong-dong">
                            <img src={require('assets/images/community-house.svg')} alt="Nhà cộng đồng" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/nha-cong-dong">Nhà cộng đồng</Link>
                        </h5>
                    </div> */}

                    {/* <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/van-phong">
                            <img src={require('assets/images/office-building.svg')} alt="Văn phòng" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/van-phong">Văn phòng</Link>
                        </h5>
                    </div> */}

                    {/* <div className="col-3 text-center mb-4">
                        <Link to="/tien-ich/tien-ich-khac">
                            <img src={require('assets/images/plus-green.svg')} alt="Tiện ích khác" />
                        </Link>
                        <h5>
                            <Link to="/tien-ich/tien-ich-khac">Tiện ích khác</Link>
                        </h5>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default UtilitiesListDesktopView;