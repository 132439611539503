/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import { Map } from "immutable";
import { createSaveLoading } from "core/app.store";
import { createFormData, AppKey } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";
import moment from "moment";

const service = new Service();

export const SHOP_TYPE = {
  CAFE: "CAFE",
  NHA_HANG: "NHA_HANG",
  SIEU_THI: "SIEU_THI",
  TRUONG_HOC: "TRUONG_HOC",
  TENNIS: "TENNIS",
  POOL: "POOL",
  FOOTBALL: "FOOTBALL",
  BBQ: "BBQ",
  BEAUTY: "BEAUTY",
  OFFICE: "OFFICE",
  GARAGE: "GARAGE",
  COMMUNITY: "COMMUNITY",
  GYM: "GYM",
  CLINIC: "CLINIC",
};

export const SHOP_TYPE_ID = {
  CAFE: "3",
  NHA_HANG: "2",
  SIEU_THI: "1",
  TRUONG_HOC: "4",
  TENNIS: "5",
  POOL: "7",
  FOOTBALL: "8",
  BBQ: "0",
  BEAUTY: "10",
  OFFICE: "0",
  GARAGE: "0",
  COMMUNITY: "0",
  GYM: "6",
  CLINIC: "0",
};

const CATEGORY_API = {
  ADD_OR_UPDATE_SHOP: "ADD_OR_UPDATE_SHOP",
  GET_DETAIL_SHOP: "GET_DETAIL_SHOP",
  GET_LIST_SHOP: "GET_LIST_SHOP",
  GET_LIST_BOOKING: "GET_LIST_BOOKING",
  UPDATE_BOOKING: "UPDATE_BOOKING",
};

/** Action Types */
const SHOPSAVELIST = "SHOP/SAVELIST";
const SHOPGETLIST = "SHOP/GETLIST";

/** Actions */
export const createSaveShopList = (shopModel) => ({
  type: SHOPSAVELIST,
  payload: {
    shops: shopModel.items,
    totalItems: shopModel.totalItemCount,
  },
});
export const createGetShopList = () => ({
  type: SHOPGETLIST,
});

/** Action creators */
export const GetShopList = () => {
  return (dispatch) => {
    let payLoad = createFormData();
    return service
      .post(ApiUrl.ShopGetList, payLoad)
      .then((res) => {
        dispatch(createSaveShopList(res.content));
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetShopDetail = (shopId, shopType) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("filter[get_my_shop]", 1);
    // formData.append("filter[is_active]", 0);
    let payLoad = createFormData(
      convertModelFromShopType(
        shopType,
        { shopId: shopId },
        CATEGORY_API.GET_DETAIL_SHOP
      ),
      null,
      formData
    );
    return service
      .post(apiUrlFromShopType(shopType, CATEGORY_API.GET_DETAIL_SHOP), payLoad)
      .then((res) => {
        return res && res.content
          ? convertResponseModelToCustomModel(
              shopType,
              res.content,
              CATEGORY_API.GET_DETAIL_SHOP
            )
          : null;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateShop = (data, formData = new FormData()) => {
  return (dispatch) => {
    dispatch(createSaveLoading(true));
    let payLoad = createFormData(
      convertModelFromShopType(
        data.category_type.toUpperCase(),
        data,
        CATEGORY_API.ADD_OR_UPDATE_SHOP
      ),
      AppKey.PostPayloadPrefix,
      formData
    );
    return service
      .post(
        apiUrlFromShopType(
          data.category_type.toUpperCase(),
          CATEGORY_API.ADD_OR_UPDATE_SHOP
        ),
        payLoad
      )
      .then((res) => {
        dispatch(createSaveLoading(false));
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

export const GetCartList = (model, shopType) => {
  return (dispatch) => {
    let payLoad = convertModelFromShopType(
      shopType,
      model,
      CATEGORY_API.GET_LIST_BOOKING
    );
    return service
      .post(
        apiUrlFromShopType(shopType, CATEGORY_API.GET_LIST_BOOKING),
        payLoad
      )
      .then((res) => {
        return res && res.content && res.content.items
          ? convertResponseModelToCustomModel(
              shopType,
              res.content.items,
              CATEGORY_API.GET_LIST_BOOKING
            )
          : res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateBooking = (data, shopType) => {
  return (dispatch) => {
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
    return service
      .post(apiUrlFromShopType(shopType, CATEGORY_API.UPDATE_BOOKING), payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

function apiUrlFromShopType(
  type,
  categoryApi = CATEGORY_API.ADD_OR_UPDATE_SHOP
) {
  switch (type) {
    case SHOP_TYPE.CAFE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.ShopAddOrEdit;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.ShopGetList;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.ShopGetDetail;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.CartGetList;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.PostRegisterCart;
        default:
          return ApiUrl.ShopAddOrEdit;
      }
    case SHOP_TYPE.NHA_HANG:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.ShopAddOrEdit;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.ShopGetList;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.ShopGetDetail;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.CartGetList;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.PostRegisterCart;
        default:
          return ApiUrl.ShopAddOrEdit;
      }
    case SHOP_TYPE.SIEU_THI:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.ShopAddOrEdit;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.ShopGetList;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.ShopGetDetail;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.CartGetList;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.PostRegisterCart;
        default:
          return ApiUrl.ShopAddOrEdit;
      }
    case SHOP_TYPE.TRUONG_HOC:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.ShopAddOrEdit;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.ShopGetList;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.ShopGetDetail;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.CartGetList;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.PostRegisterCart;
        default:
          return ApiUrl.ShopAddOrEdit;
      }
    case SHOP_TYPE.TENNIS:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateTennis;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListTennis;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetTennisById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterTennis;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseTennis;
        default:
          return ApiUrl.AddOrUpdateTennis;
      }
    case SHOP_TYPE.POOL:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdatePool;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListPool;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetPoolById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterPool;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUsePool;
        default:
          return ApiUrl.AddOrUpdatePool;
      }
    case SHOP_TYPE.FOOTBALL:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateFootball;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListFootball;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetFootballById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterFootball;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseFootball;
        default:
          return ApiUrl.AddOrUpdateFootball;
      }
    case SHOP_TYPE.BBQ:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateBbq;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListBbq;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetBbqById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterBbq;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseBbq;
        default:
          return ApiUrl.AddOrUpdateBbq;
      }
    case SHOP_TYPE.BEAUTY:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateBeauty;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListBeauty;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetBeautyById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterBeauty;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseBeauty;
        default:
          return ApiUrl.AddOrUpdateBeauty;
      }
    case SHOP_TYPE.OFFICE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateOffice;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListOffice;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetOfficeById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterOffice;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseOffice;
        default:
          return ApiUrl.AddOrUpdateOffice;
      }
    case SHOP_TYPE.GARAGE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateGarage;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListGarage;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetGarageById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterGarage;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseGarage;
        default:
          return ApiUrl.AddOrUpdateGarage;
      }
    case SHOP_TYPE.COMMUNITY:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateCommunity;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListCommunity;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetCommunityById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterCommunity;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseCommunity;
        default:
          return ApiUrl.AddOrUpdateCommunity;
      }
    case SHOP_TYPE.GYM:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateGym;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListGym;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetGymById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterGym;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseGym;
        default:
          return ApiUrl.AddOrUpdateGym;
      }
    case SHOP_TYPE.CLINIC:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.AddOrUpdateClinic;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.GetListClinic;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.GetClinicById;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.GetListRegisterClinic;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.RegisterUseClinic;
        default:
          return ApiUrl.AddOrUpdateClinic;
      }
    default:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return ApiUrl.ShopAddOrEdit;
        case CATEGORY_API.GET_LIST_SHOP:
          return ApiUrl.ShopGetList;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return ApiUrl.ShopGetDetail;
        case CATEGORY_API.GET_LIST_BOOKING:
          return ApiUrl.CartGetList;
        case CATEGORY_API.UPDATE_BOOKING:
          return ApiUrl.PostRegisterCart;
        default:
          return ApiUrl.ShopAddOrEdit;
      }
  }
}

function convertResponseModelToCustomModel(
  type,
  model,
  categoryApi = CATEGORY_API.ADD_OR_UPDATE_SHOP
) {
  switch (type) {
    case SHOP_TYPE.CAFE:
      return model;
    case SHOP_TYPE.NHA_HANG:
      return model;
    case SHOP_TYPE.SIEU_THI:
      return model;
    case SHOP_TYPE.TRUONG_HOC:
      return model;
    case SHOP_TYPE.TENNIS:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              tennis_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, tennis_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.tennisId,
            shopName: model.tennisName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.POOL:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              pool_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, pool_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.poolId,
            shopName: model.poolName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.FOOTBALL:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              football_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, football_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.footballId,
            shopName: model.footballName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.BBQ:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              bbq_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, bbq_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.bbqId,
            shopName: model.bbqName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.BEAUTY:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              beauty_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, beauty_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.beautyId,
            shopName: model.beautyName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.OFFICE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              office_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, office_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.officeId,
            shopName: model.officeName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.GARAGE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              garage_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, garage_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.garageId,
            shopName: model.garageName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.COMMUNITY:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              community_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, community_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.communityId,
            shopName: model.communityName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.GYM:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              gym_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, gym_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.gymId,
            shopName: model.gymName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.CLINIC:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              clinic_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };

            if (model.shop_id) {
              dataModel = { ...dataModel, clinic_id: model.shop_id };
            }

            return dataModel;
          }

          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            ...model,
            shopId: model.clinicId,
            shopName: model.clinicName,
            logoUrl: model.image,
            content: model.content,
            longitude: model.longitude,
            latitude: model.latitude,
            shopAddress: model.address,
            fanpageUrl: model.facebookUrl,
            hotline: model.mobileNumber,
            openTime: model.openTime,
            closeTime: model.closeTime,
            date: model.date,
            category: model.category,
            slots: model.slots,
            shopSlide: model.slideImages,
            shopImage: model.albumImages,
            isBooking: model.hasSlots,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          return model && model.length > 0
            ? {
                booking: model,
                pending: model,
              }
            : model;
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    default:
      return model;
  }
}

export function convertModelFromShopType(
  type,
  model,
  categoryApi = CATEGORY_API.ADD_OR_UPDATE_SHOP
) {
  switch (type) {
    case SHOP_TYPE.CAFE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_BOOKING:
          return createFormData(model);
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.NHA_HANG:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_BOOKING:
          return createFormData(model);
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.SIEU_THI:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_BOOKING:
          return createFormData(model);
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.TRUONG_HOC:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return model;
        case CATEGORY_API.GET_LIST_BOOKING:
          return createFormData(model);
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.TENNIS:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              tennis_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, tennis_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            tennisId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              tennis_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.POOL:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              pool_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, pool_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            poolId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              pool_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.FOOTBALL:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              football_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, football_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            footballId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              football_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              date: model.date,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.BBQ:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              bbq_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, bbq_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            bbqId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              bbq_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.BEAUTY:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              beauty_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, beauty_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            beautyId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              beauty_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.OFFICE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              office_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, office_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            officeId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              office_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.GARAGE:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              garage_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, garage_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            garageId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              garage_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.COMMUNITY:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              community_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, community_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            communityId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              community_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.GYM:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              gym_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, gym_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            gymId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              gym_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    case SHOP_TYPE.CLINIC:
      switch (categoryApi) {
        case CATEGORY_API.ADD_OR_UPDATE_SHOP:
          if (model) {
            let dataModel = {
              clinic_name: model.shop_name,
              address: model.shop_address,
              mobile_number: model.shop_hotline,
              facebook_url: model.shop_fanpage_url,
              content: model.content,
              longitude: model.longitude,
              latitude: model.latitude,
              open_time: model.open_time,
              close_time: model.close_time,
              // customer_id: model.customer_id,
              // category_type: model.category_type,
            };
            if (model.shop_id)
              dataModel = { ...dataModel, clinic_id: model.shop_id };
            return dataModel;
          }
          return model;
        case CATEGORY_API.GET_LIST_SHOP:
          return model;
        case CATEGORY_API.GET_DETAIL_SHOP:
          return {
            clinicId: model.shopId,
            "filter[is_active]": 0,
          };
        case CATEGORY_API.GET_LIST_BOOKING:
          let formData = new FormData();
          formData.append("pageIndex", model.pageIndex);
          formData.append("size", model.size);
          return createFormData(
            {
              clinic_id: model.shopId,
              orderBy: "register_date",
              orderType: "DESC",
              register_date: model.date,
              status_id: model.statusId,
            },
            AppKey.GetPayloadPrefix,
            formData
          );
        case CATEGORY_API.UPDATE_BOOKING:
          return model;
        default:
          return model;
      }
    default:
      return model;
  }
}

export function convertFormDataFromShopType(
  type,
  avatarFile = null,
  slideFiles = [],
  albumFiles = [],
  hasSlot = 0,
  calendarSchedule = [],
  fileDelete = []
) {
  const formData = new FormData();
  switch (type) {
    case SHOP_TYPE.CAFE:
      avatarFile && formData.append("shop_logo", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("shop_slide[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("shop_image[]", file.file)
        );
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.NHA_HANG:
      avatarFile && formData.append("shop_logo", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("shop_slide[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("shop_image[]", file.file)
        );
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.SIEU_THI:
      avatarFile && formData.append("shop_logo", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("shop_slide[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("shop_image[]", file.file)
        );
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.TRUONG_HOC:
      avatarFile && formData.append("shop_logo", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("shop_slide[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("shop_image[]", file.file)
        );
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.TENNIS:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.POOL:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.FOOTBALL:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.BBQ:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.BEAUTY:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.OFFICE:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.GARAGE:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.COMMUNITY:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.GYM:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    case SHOP_TYPE.CLINIC:
      avatarFile && formData.append("image", avatarFile);
      slideFiles &&
        slideFiles.length > 0 &&
        slideFiles.map(
          (file) => file.file && formData.append("slide_images[]", file.file)
        );
      albumFiles &&
        albumFiles.length > 0 &&
        albumFiles.map(
          (file) => file.file && formData.append("album_images[]", file.file)
        );
      hasSlot == 1 &&
        calendarSchedule &&
        calendarSchedule.length > 0 &&
        calendarSchedule.map((item, index) => {
          if (item.openTime && item.closeTime) {
            formData.append(
              "data[slots][" + index + "][from_hour]",
              moment(item.openTime).format("HH:mm")
            );
            formData.append(
              "data[slots][" + index + "][to_hour]",
              moment(item.closeTime).format("HH:mm")
            );
          }
        });
      fileDelete &&
        fileDelete.length > 0 &&
        fileDelete.map((item) => formData.append("delete_file[]", item));
      return formData;
    default:
      return formData;
  }
}

// Initial state
export const initialState = Map({
  shopList: [],
  totalItems: 0,
}).toJS();

/** Reducers */
export default function ShopReducer(state = initialState, action) {
  switch (action.type) {
    case SHOPSAVELIST:
      return {
        ...state,
        shopList: action.payload.shops,
        totalItems: action.payload.totalItems,
      };
    case SHOPGETLIST:
      return state.shopList;
    default:
      return state;
  }
}
