//@ts-check
import React from 'react';
import Gallery from 'react-grid-gallery';
import * as CommonClass from 'utils/css-common-class';

/**
 * @param {{listImage:{
            src: string,
            thumbnail: string,
            thumbnailWidth: number,
            thumbnailHeight: number,
        }[]}} [props]
*/
function RenderFooterDetailView(props) {
    const listImage = props.listImage;
    listImage.map(imageObject => {
        imageObject.thumbnailWidth = 320;
        imageObject.thumbnailHeight = 240;
    })
    if (props.listImage.length===0) return null
    return (<div>
        <h3 className={`title-sm mt-4 ${CommonClass.TextSizeSubTitle}`}>Hình ảnh</h3>
        <Gallery images={listImage} backdropClosesModal={true} />
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>)
}

export default RenderFooterDetailView