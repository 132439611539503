/* eslint-disable react/prop-types */
import React, { Component, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as registrationActions from "redux/stores/user/registration.store";
import * as projectActions from "redux/stores/project/project.store";
import { useForm } from "react-hook-form";
import AddNotification from "components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  ScreenTypeMobile,
  TokenKey,
  setCookies
} from "utils/configuration";
import * as viVN from "translation/vi-VN.json";
import { JwtHelper } from "utils/jwt-helper";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { history } from "redux/store";
import AsyncSelect from 'react-select/async';
import AsyncPaginate from "react-select-async-paginate";

import "./registration-mobile.scss";

class RegistrationMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableBtn: true,
      projectList: this.props.projectList || [],
      houseList: []
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProjectList();
  }

  onGetProjectList = () => {
    this.props.onGetProjectList().then(
      (res) => {
        if (res && res.content && res.content.length > 0) {
          let options = [];
          res.content.map((item) => {
            options.push({ value: item.clientId, label: item.clientName });
          });
          this.setState({ projectList: options });
        }
      },
      (err) => { }
    );
  };

  onGetApartmentList = (id) => {
    this.props.onGetApartmentList(id).then(
      (res) => {
        if (res && res.content && res.content.length > 0) {
          let options = [];
          res.content.map((item) => {
            options.push({
              value: item.apartmentId,
              label: item.apartmentName,
            });
          });
          this.setState({ houseList: options });
        } else {
          this.setState({ houseList: [] });
        }
      },
      (err) => { }
    );
  };

  onChangeCheckBox = () => {
    let { disableBtn } = this.state;
    this.setState({ disableBtn: !disableBtn });
  };

  render() {
    let { disableBtn, projectList, houseList } = this.state;
    return (
      <div className="text-center pb-4">
        <img
          src={require("assets/images/logo.png")}
          alt="Logo"
          className="img-fluid img-logo"
        />
        <RegistrationForm
          projectList={projectList}
          houseList={houseList}
          onRegistration={this.props.onRegistration}
          onChangeCheckBox={this.onChangeCheckBox}
          onGetApartmentList={this.onGetApartmentList.bind(this)}
          disableBtn={disableBtn}
        />
        <div className="info row">
          <div className="col-12">
            <Link to="/m/dang-nhap" className="arrow-back">
              <img
                src={require("assets/images/arrow-back.svg")}
                alt="arrow-back"
                className="img-fluid"
              />{" "}
              Quay lại
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function RegistrationForm(props) {
  const {
    onRegistration,
    onChangeCheckBox,
    onGetApartmentList,
    disableBtn,
    projectList,
    houseList,
  } = props;

  const [house, setHouse] = useState("");
  const [project, setProject] = useState("");
  const [type, setType] = useState("is_resident");
  const [firstLoad, setFirstLoad] = useState(true);

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearError,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  const onChangeProject = (data) => {
    if (data) {
      onGetApartmentList(data.value);
      setProject(data);
      onChangeHouse();
      clearError(["project"]);
    } else {
      setError("project", "required", "Trường này là bắt buộc");
    }
  };

  const filterProject = (inputValue) => {
    if (inputValue && inputValue.length > 3) {
      return projectList.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      return [];
    }
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      inputValue && firstLoad && setFirstLoad(false);
      resolve(filterProject(inputValue));
    });

  const onChangeHouse = (data) => {
    if (data) {
      setHouse(data);
      clearError(["houseNumber"]);
    } else {
      setHouse("");
      setError("houseNumber", "required", "Trường này là bắt buộc");
    }
  };

  const onSetType = (userType) => {
    setType(userType);
    if (userType == "is_shop") {
      clearError(["houseNumber"]);
    } else {
      !house && setError("houseNumber", "required", "Trường này là bắt buộc");
    }
  };

  const loadOptions = (search, prevOptions) => {
    house
      ? clearError(["houseNumber"])
      : setError("houseNumber", "required", "Trường này là bắt buộc");
    let filteredOptions;
    if (!search) {
      filteredOptions = houseList;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = houseList.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const onChangePassword = (e) => {
    const { password, confirmPassword } = getValues();
    password == confirmPassword
      ? clearError(["confirmPassword"])
      : setError("confirmPassword", "validate", "Mật khẩu không trùng khớp");
  };

  const onSubmit = (data) => {
    if (data) {
      let formData = new FormData();
      formData.append("clientId", data.project);
      onRegistration(
        {
          mobile_number: data.tel,
          password: data.password,
          email: data.email,
          apartment_id: data.houseNumber || (house && house.value),
          is_resident: type == "is_resident" ? 1 : 0,
          is_shop: type == "is_shop" ? 1 : 0,
          first_name: data.firstName,
          last_name: data.lastName,
        },
        formData
      ).then(
        (res) => {
          if (res && res.content) {
            setCookies(TokenKey.Project, project.value);
            setCookies(TokenKey.ProjectName, project.label);
            history.push(
              "/m/xac-nhan/" +
              ScreenTypeMobile.Register +
              "&&" +
              JwtHelper.encodeStringBase64(data.tel.trim())
            );
          } else {
            res &&
              res.errorType &&
              AddNotification(
                viVN.Errors[res && res.errorType],
                NotificationMessageType.Error
              );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-register">
      <div className="row">
        <div className="col-12">
          <h5>Đăng ký tài khoản</h5>
          <div className="mt-3 form-group">
            <input
              name="email"
              type="text"
              placeholder="Nhập địa chỉ email (*)"
              className="form-control"
              autoComplete="off"
              ref={register({
                required: "Trường này là bắt buộc",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Địa chỉ email không hợp lệ",
                },
              })}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email.message}</div>
            )}
          </div>
          <div className="form-group">
            <input
              name="tel"
              type="text"
              placeholder="Nhập số điện thoại (*)"
              className="form-control"
              autoComplete="off"
              ref={register({
                required: "Trường này là bắt buộc",
                pattern: {
                  value: /(\(?\+[0-9]{2}\)|0)?([0-9]{9,10})/i,
                  message: "Số điện thoại không hợp lệ",
                },
              })}
            />
            {errors.tel && (
              <div className="invalid-feedback">{errors.tel.message}</div>
            )}
          </div>
          <div className="form-group">
            <AsyncSelect
              className={"react-select-container" + (firstLoad ? " first-load" : "")}
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions
              onChange={(data) => onChangeProject(data)}
              loadOptions={promiseOptions}
              noOptionsMessage={() => "Không có kết quả"}
              placeholder="Chọn dự án (*)" />
            <input
              name="project"
              type="hidden"
              value={(project && project.value) || ""}
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            />
            {errors.project && (
              <div className="invalid-feedback">{errors.project.message}</div>
            )}
          </div>
          {project && (
            <div className="form-group">
              <AsyncPaginate
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={
                  type == "is_resident" ? "Số căn hộ (*)" : "Số căn hộ"
                }
                noOptionsMessage={() => "Không có dữ liệu"}
                value={house}
                isClearable={true}
                cacheUniq={project && project.value}
                loadOptions={loadOptions}
                onChange={(data) => onChangeHouse(data)}
              />
              {type == "is_resident" ? (
                <div>
                  <input
                    name="houseNumber"
                    type="hidden"
                    value={house.value || ""}
                    ref={register({
                      required: "Trường này là bắt buộc",
                    })}
                  />
                  {errors.houseNumber && (
                    <div className="invalid-feedback">
                      {errors.houseNumber.message}
                    </div>
                  )}
                </div>
              ) : (
                  <input
                    name="houseNumber"
                    type="hidden"
                    value={house.value || ""}
                  />
                )}
            </div>
          )}
          <div className="form-group pl-2 text-left">
            <div key={`custom-inline-radio`}>
              <Form.Check
                custom
                inline
                defaultChecked
                label="Cư dân"
                type="radio"
                id={`custom-inline-register-resident`}
                value="is_resident"
                name="type"
                onClick={() => onSetType("is_resident")}
              />
              <Form.Check
                custom
                inline
                label="Chủ nhà hàng"
                type="radio"
                id={`custom-inline-register-shop`}
                value="is_shop"
                name="type"
                onClick={() => onSetType("is_shop")}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className={window.innerWidth > 400 ? "col-6" : "col-12"}>
              <input
                name="firstName"
                type="text"
                placeholder="Họ và Tên đệm (*)"
                className="form-control"
                autoComplete="off"
                ref={register({
                  required: "Trường này là bắt buộc",
                })}
              />
              {errors.firstName && (
                <div className="invalid-feedback">
                  {errors.firstName.message}
                </div>
              )}
            </div>
            <div className={window.innerWidth > 400 ? "col-6" : "col-12 mt-3"}>
              <input
                name="lastName"
                type="text"
                placeholder="Tên (*)"
                className="form-control"
                autoComplete="off"
                ref={register({
                  required: "Trường này là bắt buộc",
                })}
              />
              {errors.lastName && (
                <div className="invalid-feedback">
                  {errors.lastName.message}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <input
              name="password"
              type="password"
              placeholder="Nhập mật khẩu (*)"
              className="form-control"
              autoComplete="off"
              onChange={() => onChangePassword()}
              ref={register({
                required: "Trường này là bắt buộc",
                minLength: {
                  value: 6,
                  message: "Mật khẩu phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password.message}</div>
            )}
          </div>
          <div className="form-group">
            <input
              name="confirmPassword"
              type="password"
              placeholder="Xác nhận mật khẩu (*)"
              className="form-control"
              autoComplete="off"
              ref={register({
                validate: (value) =>
                  value === password.current || "Mật khẩu không trùng khớp",
              })}
            />
            {errors.confirmPassword && (
              <div className="invalid-feedback">
                {errors.confirmPassword.message}
              </div>
            )}
          </div>
          <div className="form-group pl-2 pr-2 text-left">
            <div className="term-of-service">
              <div key={`custom-radio`}>
                <Form.Check
                  custom
                  type="checkbox"
                  id={`custom-term`}
                  name="term"
                >
                  <Form.Check.Input
                    type="checkbox"
                    onClick={onChangeCheckBox.bind(this)}
                  />
                  <Form.Check.Label>
                    <span>Tôi đã đọc và đồng ý với</span>{" "}
                    <Link to="/m/dieu-khoan-su-dung" className="term-title">
                      Điều khoản & Điều kiện sử dụng
                    </Link>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-register"
              disabled={disableBtn}
            >
              Đăng ký
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onRegistration: registrationActions.Registration,
      onGetProjectList: projectActions.GetProjectList,
      onGetApartmentList: projectActions.GetApartmentList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationMobileView);
