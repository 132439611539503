/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as lendingEquipmentActions from "redux/stores/lending-equipment/lending-equipment.store";
import * as appActions from "core/app.store";

import { convertTextareaToHtml } from "utils/configuration";

import "../lending-equipment-mobile.scss";

class LendingEquipmentPolicyMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyPolicy: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetContentByKeyword();
  }

  onGetContentByKeyword = () => {
    this.props.showLoading(true);
    this.props.onGetContentByKeyword().then(
      (res) => {
        this.setState({
          privacyPolicy: res.content,
        });
        this.props.showLoading(false);
      },
      (err) => {
        this.props.showLoading(false);
      }
    );
  };

  render() {
    const { privacyPolicy } = this.state;

    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/cho-thue-muon-do">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">
                  {privacyPolicy && privacyPolicy.contentName}
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="lending-equipment-policy-mobile p-3">
          {privacyPolicy && (
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: convertTextareaToHtml(privacyPolicy.content),
              }}
            ></div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetContentByKeyword: lendingEquipmentActions.GetContentByKeyword,
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LendingEquipmentPolicyMobileView);
