/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment } from 'react';
import "../payment-desktop.scss";
import { Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OtherBillStores from 'redux/stores/payment/other-services/other-service.store';
import PayDetail from './other-services-pay-detail.view.jsx';
import RenderBillOfMonthly from './other-service-bill-month.view.jsx';
import { ToolSelectPeriodTime, convertTextareaToHtml } from "utils/configuration";
import { Error } from 'components/support-create-form/validate-style';
import { handleChangeListHistory } from '../logic-payment/handle-change-list-history';
import { PromiseAllTools } from 'core/app.store';
import { useRenderPaymentHistoryList } from '../../../components/custom-hook/render-payment-history-list/render-payment-history-list';
import Pagination from '../../../components/pagination/paging.view';

export function PaymentOtherServiceDesktopView(props) {

  const { endDate, startDate, handleChangeStartDate, handleChangeEndDate, resetChangeDateTime, filterObj } = new ToolSelectPeriodTime()
  const [showPayDetail, setShowPayDetail] = useState(false)
  const [showWarning, setShowWarning] = useState(false);
  const FeePaymentGuideDocument = { __html: convertTextareaToHtml(props.feePaymentGuide) || "Không có dữ liệu để hiển thị" };
  const ParkingPaymentGuideDocument = { __html: convertTextareaToHtml(props.parkingPaymentGuide) || "Không có dữ liệu để hiển thị" };

  function handleChangePayDetail(feeId, parkingId) {
    setShowPayDetail(!showPayDetail)
    props.PromiseAllTools([props.GetOtherBillDetail(feeId, parkingId)])
  }

  function ControlChangeListHistory() {
    handleChangeListHistory(
      startDate,
      endDate,
      () => props.PromiseAllTools([props.GetOtherBillList(1, 100, filterObj, props.userInfo.apartmentId)]),
      resetChangeDateTime,
      setShowWarning,
      props.ResetHistoryList
    )
  }

  function PayHistory() {
    const listData = Array.from(props.listPay);

    return (
      <div className="body mt-4">
        <div className="row align-items-center">
          <div className="col-3">
            <h3 className="body-title"> Lịch sử thanh toán</h3>
          </div>
          <div className="col-9">
            <div className="row no-gutters align-items-center">
              <div className="col-1"></div>
              <div className="col-4 text-right position-relative pr-1">
                <DatePicker selected={startDate} onChange={handleChangeStartDate} className="rounded-pill" dateFormat="dd/MM/yyyy" disabled={listData.length === 0} />
                <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
              </div>
              <div className="col-4 text-right position-relative pr-1">
                <DatePicker selected={endDate} onChange={handleChangeEndDate} className="rounded-pill" dateFormat="dd/MM/yyyy" disabled={listData.length === 0} />
                <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
              </div>
              <div className="col-3 text-right">
                <button className="btn btn-success rounded-pill" onClick={ControlChangeListHistory} disabled={listData.length === 0}>
                  <img src={require('assets/images/search-white.svg')} alt="Search White" className="img-search-white" /> Tìm kiếm
                  </button>
              </div>
            </div>
            <div className='text-center'>
              {showWarning && <Error content="Khoảng thời gian không đúng, vui lòng kiểm tra lại." />}
            </div>
          </div>
        </div>

        {listData.length > 0 ? <RenderHistoryList listData={listData} handleChangePayDetail={handleChangePayDetail} /> : (
          <div className="border-top mt-2 pt-3">
            <p className="text-center mb-0">Không có dữ liệu để hiển thị</p>
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    props.PromiseAllTools([
      props.GetOtherBillThisMonth(props.userInfo.apartmentId),
      props.GetParkingPaymentGuide(),
      props.GetFeePaymentGuide(),
      props.GetOtherBillList(1, 100, filterObj, props.userInfo.apartmentId),
    ])
  }, [])

  return (
    <div className="payment-desktop">
      <h2 className="title">Dịch vụ khác</h2>
      <RenderBillOfMonthly dataFee={props.monthlyBill.fee} haveFeeBill={props.monthlyBill.haveFee} haveParkingBill={props.monthlyBill.haveParking} dataParking={props.monthlyBill.parking} />

      {showPayDetail ? <PayDetail dataFee={props.otherDetail.fee} haveFeeBill={props.otherDetail.haveFee} haveParkingBill={props.otherDetail.haveParking} dataParking={props.otherDetail.parking} click={() => setShowPayDetail(!showPayDetail)} /> : PayHistory()}

      <div className="row">
        <div className="col-6">
          <div className="payment-guide" dangerouslySetInnerHTML={FeePaymentGuideDocument}>

          </div>
        </div>
        <div className="col-6">
          <div className="payment-guide" dangerouslySetInnerHTML={ParkingPaymentGuideDocument}>
          </div>
        </div>
      </div>
    </div>
  );

}

function RenderHistoryList(props) {
  const {
    listData,
    Page_Size,
    displayList,
    setCurrentIndex
  } = useRenderPaymentHistoryList(props.listData)
  return (
    <Fragment>
      <Table hover className="mt-1 mb-0">
        <tbody>
          {displayList.map((data, index) => <RenderFieldOfList data={data} key={index} click={() => props.handleChangePayDetail(data.feeId, data.parkingId)} />)}
        </tbody>
      </Table>
      {
        (listData.length > Page_Size) && <Pagination totalItems={listData.length} size={Page_Size} goTo={setCurrentIndex} />
      }
    </Fragment>
  )
}

function RenderFieldOfList(props) {
  const data = props.data
  return (
    <tr className="pointer" onClick={props.click}>
      <td>
        <h5>Hóa dịch vụ khác tháng {data.monthly}/{data.yearly}</h5>
        <span>Ngày thanh toán: {data.datePay ? data.datePay : "Chưa thanh toán"}</span>
      </td>
      <td className="text-right total-price">{data.total}VNĐ</td>
    </tr>
  )
}

const mapStateToProps = state => ({
  monthlyBill: state.otherBill.billThisMonth,
  parkingPaymentGuide: state.otherBill.parkingPaymentGuide,
  listPay: state.otherBill.listPay,
  otherDetail: state.otherBill.otherDetail,
  feePaymentGuide: state.otherBill.feePaymentGuide,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetOtherBillThisMonth: OtherBillStores.GetOtherBillThisMonth,
  GetParkingPaymentGuide: OtherBillStores.GetParkingPaymentGuide,
  GetOtherBillList: OtherBillStores.GetOtherBillList,
  GetOtherBillDetail: OtherBillStores.GetOtherBillDetail,
  ResetHistoryList: OtherBillStores.ResetHistoryList,
  GetFeePaymentGuide: OtherBillStores.GetFeePaymentGuide,
  PromiseAllTools
},
  dispatch
)
export default connect(mapStateToProps, mapDispatchToProps)(PaymentOtherServiceDesktopView)
