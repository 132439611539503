/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as authActions from "core/auth.store";
import * as hotActions from "redux/stores/hot/hot.store";
import * as newActions from "redux/stores/news/news.store";
import * as advertisingActions from "redux/stores/advertising/advertising.store";

import NavigationView from "components/navigation/navigation.view.jsx";
import SideDrawer from "components/side-drawer/side-drawer.js";
import Backdrop from "components/back-drop/back-drop";

import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";
import {
  TokenKey,
  SetDefaultErrorImage,
  StripHtmlTag,
  getCookies,
} from "utils/configuration";
import * as PageUrls from "../PageUrls";

import "../home/home-mobile.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class HomeMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: false,
      isLoading: false,
      isShowQuangCaoTop: false,
      isShowQuangCaoBottom: false,
    };

    this.onGetHotList = this.onGetHotList.bind(this);
    this.onGetNewList = this.onGetNewList.bind(this);
    this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this);
    this.backdropClickHandler = this.backdropClickHandler.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onHideQuangCaoTop = this.onHideQuangCaoTop.bind(this);
    this.onHideQuangCaoBottom = this.onHideQuangCaoBottom.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.onGetHotList(1, 4, { orderBy: "update_date", orderType: "DESC" });
    this.onGetNewList(1, 3, { orderBy: "update_date", orderType: "DESC" });
    this.props.GetAdMobileHomePageList();
  }

  onGetHotList = (pageIndex, size, filterObjs) => {
    this.props.getHotList(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  onGetNewList = (pageIndex, size, filterObjs) => {
    this.props.getNewList(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  onLogout = () => {
    this.props.logout();
    this.backdropClickHandler();
    history.push("/m/dang-nhap");
  };

  onHideQuangCaoTop = () => {
    this.setState({
      isShowQuangCaoTop: false,
    });
  };

  onHideQuangCaoBottom = () => {
    this.setState({
      isShowQuangCaoBottom: false,
    });
  };

  render() {
    const settingsLife = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const settingsHot = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
    };

    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    const loggedIn = this.props.loggedIn;
    const userInfo = JwtHelper.currentUser();

    const projectName = getCookies(TokenKey.ProjectName)
      ? getCookies(TokenKey.ProjectName)
      : "";

    const { hotList, newList, advertising } = this.props;

    const adMobileHomePage =
      this.props.adMobileHomePage && this.props.adMobileHomePage.length > 0
        ? this.props.adMobileHomePage.filter(
            (item) => item.categoryType !== advertisingActions.GOTOMARKETKEYWORD
          )
        : [];

    const advertisingHomeMobileTop =
      (advertising &&
        advertising.length > 0 &&
        advertising.filter(
          (item) =>
            item.positionCode == advertisingActions.POSITION_AD.MOBILE_HOME_TOP
        )) ||
      [];

    const advertisingHomeMobileBottom =
      (advertising &&
        advertising.length > 0 &&
        advertising.filter(
          (item) =>
            item.positionCode ==
            advertisingActions.POSITION_AD.MOBILE_HOME_BOTTOM
        )) ||
      [];

    return (
      <section className="home-mobile">
        <NavigationView drawerClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer
          click={this.backdropClickHandler}
          logout={this.onLogout}
          show={this.state.sideDrawerOpen}
          userInfo={userInfo}
          loggedIn={loggedIn}
        />
        {backdrop}

        {hotList && hotList.length > 0 && (
          <div className="hot mt-3">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <h5 className="title-header">HOT!</h5>
                </div>
                <div className="col-6 text-right">
                  <Link to="/m/hot" className="discovery-link">
                    Xem thêm
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Slider {...settingsHot}>
                    {hotList.map((item, index) => (
                      <RenderListHost key={index} data={item} />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="thanh-toan mt-4">
          <div className="container">
            <h5 className="title-header">Thanh toán</h5>
            <div className="row no-gutters">
              <div className="col-3">
                <div className="tien-dien text-center p-1">
                  <Link to="/m/thanh-toan/tien-dien">
                    <img
                      src={require("assets/images/tien-dien.svg")}
                      alt="Tiền điện"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="/m/thanh-toan/tien-dien">
                    <h5>Tiền điện</h5>
                  </Link>
                </div>
              </div>
              <div className="col-3">
                <div className="tien-nuoc text-center p-1">
                  <Link to="/m/thanh-toan/tien-nuoc">
                    <img
                      src={require("/assets/images/tien-nuoc.svg")}
                      alt="Tiền nước"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="/m/thanh-toan/tien-nuoc">
                    <h5>Tiền nước</h5>
                  </Link>
                </div>
              </div>
              <div className="col-3">
                <div className="internet text-center p-1">
                  <Link to="/m/thanh-toan/internet">
                    <img
                      src={require("assets/images/internet.svg")}
                      alt="Internet"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="/m/thanh-toan/internet">
                    <h5>Internet</h5>
                  </Link>
                </div>
              </div>
              <div className="col-3">
                <div className="dich-vu-khac text-center p-1">
                  <Link to="/m/thanh-toan/dich-vu-khac">
                    <img
                      src={require("assets/images/dich-vu-khac.svg")}
                      alt="Dịch vụ khác"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="/m/thanh-toan/dich-vu-khac">
                    <h5>Dịch vụ khác</h5>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tien-ich mt-4">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h5 className="title-header">Tiện ích</h5>
              </div>
              <div className="col-6 text-right">
                <Link
                  to={PageUrls.UtilitiesListMobile}
                  className="discovery-link"
                >
                  Khám phá
                </Link>
              </div>
            </div>

            <div className="row mt-3 pb-3">
              <div className="col-3">
                <div className="item">
                  <Link to={PageUrls.UtilitiesSportHomeMobile}>
                    <img
                      src={require("assets/images/gym.svg")}
                      alt="Thể thao"
                      className="img-fluid"
                    />
                  </Link>

                  <h5>
                    <Link to={PageUrls.UtilitiesSportHomeMobile}>Thể thao</Link>
                  </h5>
                </div>
              </div>

              <div className="col-3">
                <div className="item">
                  <Link to={PageUrls.UtilitiesSpaHomeMobile}>
                    <img
                      src={require("assets/images/spa.svg")}
                      alt="Làm đẹp"
                      className="img-fluid"
                    />
                  </Link>
                  <h5>
                    <Link to={PageUrls.UtilitiesSpaHomeMobile}>Làm đẹp</Link>
                  </h5>
                </div>
              </div>

              <div className="col-3">
                <div className="item">
                  <Link to={PageUrls.UtilitiesRestaurantHomeMobile}>
                    <img
                      src={require("assets/images/nha-hang.svg")}
                      alt="Nhà hàng"
                      className="img-fluid"
                    />
                  </Link>
                  <h5>
                    <Link to={PageUrls.UtilitiesRestaurantHomeMobile}>
                      Nhà hàng
                    </Link>
                  </h5>
                </div>
              </div>

              <div className="col-3">
                <div className="item">
                  <Link to={PageUrls.UtilitiesCafeHomeMobile}>
                    <img
                      src={require("assets/images/cafe.svg")}
                      alt="Cafe"
                      className="img-fluid"
                    />
                  </Link>
                  <h5>
                    <Link to={PageUrls.UtilitiesCafeHomeMobile}>Cafe</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {newList && newList.length > 0 && (
          <div className="cuoc-song-splendora mt-4">
            <div className="container">
              <h5 className="title-header text-center">
                Cuộc sống {projectName}
              </h5>
              <Slider {...settingsLife}>
                {newList.map((item) => (
                  <RenderLife key={item.newsId.toString()} data={item} />
                ))}
              </Slider>
            </div>
          </div>
        )}

        <div className="lang-gieng-gan mt-4">
          <h5 className="title-header text-center">Láng giềng gần</h5>
          <div className="container">
            <div className="row no-gutters">
              <div className="col-4 pr-2">
                <div className="item">
                  <img
                    src={require("assets/images/club.svg")}
                    alt="Image"
                    className="img-fluid"
                  />{" "}
                  <Link to="/m/lang-gieng-gan/cau-lac-bo/ket-ban">
                    Câu lạc bộ
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="item">
                  <img
                    src={require("assets/images/giup-mot-tay.svg")}
                    alt="Image"
                    className="img-fluid"
                  />{" "}
                  <Link to="/m/lang-gieng-gan/giup-mot-tay">Giúp một tay</Link>
                </div>
              </div>
              <div className="col-4 pl-2">
                <div className="item">
                  <img
                    src={require("assets/images/chung-tay-15px.svg")}
                    alt="Image"
                    className="img-fluid"
                  />{" "}
                  <Link to="/m/lang-gieng-gan/chung-tay">Chung tay</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rao-vat mt-4 mb-3">
          <div className="container">
            <div className="border-bottom mb-2">
              <div className="row">
                <div className="col-6">
                  <h5 className="title-header">Chợ làng</h5>
                </div>
                <div className="col-6 text-right">
                  <Link to="/m/rao-vat" className="discovery-link">
                    Xem thêm
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {adMobileHomePage && adMobileHomePage.length > 0 ? (
                  adMobileHomePage.map((item, index) => (
                    <RenderAdvertising key={index} data={item} />
                  ))
                ) : (
                  <p>Không có dữ liệu để hiển thị</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.isShowQuangCaoTop &&
          advertisingHomeMobileTop &&
          advertisingHomeMobileTop.length > 0 && (
            <div className="quang-cao-mobile-top">
              <a
                href={advertisingHomeMobileTop[0].advertisingUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    advertisingHomeMobileTop[0].advertisingFile ||
                    require("/assets/images/quang-cao-mobile-1.png")
                  }
                  alt={advertisingHomeMobileTop[0].advertisingName}
                  className="img-quang-cao img-fluid object-fit-cover"
                />
              </a>
              <img
                src={require("/assets/images/close-gray.svg")}
                alt="Close"
                className="img-close"
                onClick={this.onHideQuangCaoTop}
              />
            </div>
          )}

        {this.state.isShowQuangCaoBottom &&
          advertisingHomeMobileBottom &&
          advertisingHomeMobileBottom.length > 0 && (
            <div className="quang-cao-mobile-bottom">
              <a
                href={advertisingHomeMobileBottom[0].advertisingUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    advertisingHomeMobileBottom[0].advertisingFile ||
                    require("/assets/images/quang-cao-mobile.png")
                  }
                  alt={advertisingHomeMobileBottom[0].advertisingName}
                  className="img-quang-cao img-fluid object-fit-cover"
                />
              </a>
              <img
                src={require("/assets/images/close-gray.svg")}
                alt="Close"
                className="img-close"
                onClick={this.onHideQuangCaoBottom}
              />
            </div>
          )}

        <div className="contact-support-mobile">
          <a href="tel:02462943030">
            <img
              src={require("assets/images/phone-call-effect.svg")}
              alt="Phone Call"
              className="img-phone-call"
            />
          </a>
        </div>
        <div className="messenger-support-mobile">
          <a href="https://www.messenger.com/t/www.iLang.vn" target="_blank">
            <img src={require("assets/images/messenger.svg")} alt="Messenger" />
          </a>
        </div>
      </section>
    );
  }
}

function RenderLife(props) {
  const item = props.data;
  return (
    <div className="item">
      <Link to={`/m/cuoc-song/${item.newsId}`}>
        <img
          src={item.imageUrl}
          alt={item.title}
          onError={SetDefaultErrorImage}
          className="img-fluid w-100"
        />
      </Link>
      <h5>
        <Link to={`/m/cuoc-song/${item.newsId}`}>{item.title}</Link>
      </h5>
    </div>
  );
}

function RenderListHost(props) {
  const data = props.data;

  return (
    <div className="item">
      <Link to={`/m/hot/${data.hotId}`}>
        <img
          src={data.imageUrl}
          onError={SetDefaultErrorImage}
          alt={data.title}
          className="img-fluid img-avatar w-100"
        />
      </Link>
      <div className="content">
        <h5>
          <Link to={`/m/hot/${data.hotId}`}>{RenderText(data.title, 49)}</Link>
        </h5>

        <div className="row no-gutters align-items-center">
          <div className="col-1">
            <img
              src={require("assets/images/clock-7px.svg")}
              alt="Clock"
              className="img-fluid"
            />
          </div>
          <div className="col-11">
            <span className="time">
              Từ {data.fromDate} đến {data.toDate}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function RenderAdvertising(props) {
  const { data } = props;
  let TypeName = "Chợ làng";
  let TypeLink = "rao-vat";
  switch (data.categoryType) {
    case advertisingActions.HOURMAIDKEYWORD:
      {
        TypeName = "Giúp việc theo giờ";
        TypeLink = `rao-vat/giup-viec-theo-gio/${data.itemId}`;
      }
      break;
    case advertisingActions.GOTOMARKETKEYWORD:
      {
        TypeName = "Đi chợ thuê";
        TypeLink = `rao-vat/di-cho-thue/${data.itemId}`;
      }
      break;
    case advertisingActions.RESIDENTMARKETKEYWORD:
      {
        TypeName = "Rao vặt";
        TypeLink = `cho-cu-dan/chi-tiet/${data.itemId}`;
      }
      break;
    case advertisingActions.RENTANDSALEHOUSEKEYWORD: {
      TypeName = "Mua,Bán nhà";
      TypeLink = `mua-ban-nha/${data.itemId}`;
    }
  }

  return (
    <div className="item border-bottom pb-2">
      <div className="row no-gutters">
        <div className="col-8 text-truncate">
          <Link to={`/m/${TypeLink}`}>{StripHtmlTag(data.content)}</Link>
        </div>
        <div className="col-4 text-right">[{TypeName}]</div>
      </div>
    </div>
  );
}

const RenderText = (textOld, length) => {
  let textNew = textOld.slice(0, length);
  if (textOld.length > length) textNew += "...";
  return textNew;
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
  hotList: state.hot.hotList,
  newList: state.new.newList,
  adMobileHomePage: state.homeMobile.adMobileHomePage,
  advertising: state.homeMobile.advertising,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: authActions.Logout,
      getHotList: hotActions.GetHotList,
      getNewList: newActions.GetNewList,
      GetAdMobileHomePageList: advertisingActions.GetAdMobileHomePageList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeMobileView);
