/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import "../management-desktop.scss";
import * as scheduleActions from "redux/stores/management/schedule.store";
import PaginationControl from "components/pagination/pagination.view";
import moment from "moment";

moment.locale("vi");

class ScheduleListDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      currentPageIndex: 1,
      showWarning: false,
    };
  }

  UNSAFE_componentWillMount() {
    const filterObject = {
      from: this.state.startDate,
      to: this.state.endDate,
    };
    this.onGetScheduleList(1, 10, filterObject);
  }

  onGetScheduleList = (
    pageIndex = this.state.currentPageIndex,
    size = 10,
    filterObject = null
  ) => {
    this.props.getScheduleList(pageIndex, size, filterObject).then(
      (res) => { },
      (err) => { 
      }
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        currentPageIndex: 1,
      },
      () => {
        this.onGetScheduleList(1, 10);
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onGetScheduleList(pageNumber);
      }
    );
  };

  handleChangeStartDate = (date) => {
    if (date) this.setState({
      startDate: date,
    });
  };

  handleChangeEndDate = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleChangeScheduleList = () => {
    if (this.state.endDate && (this.state.startDate.getTime() > this.state.endDate.getTime())) {
      this.setState({
        showWarning: true,
      })
      return false;
    }
    const filterObject = {
      from_date: moment(this.state.startDate).format("YYYY-MM-DD"),
      to_date: (this.state.endDate) ? moment(this.state.endDate).format("YYYY-MM-DD") : "",
    };

    this.setState({
      showWarning: false,
    })

    this.onGetScheduleList(1, 10, filterObject);
  };

  render() {
    const { scheduleList, totalItems, isLoggedIn } = this.props;
    const { startDate, endDate, currentPageIndex } = this.state;
    return (
      <div className="management-desktop schedule-list">
        <div className="row no-gutters">
          <div className="col-3">
            <h2 className="title-lg mt-4">Lịch trực BQL</h2>
          </div>
          <div className="col-9 position-relative">
            <div className="management-border w-100"></div>
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-4">
          </div>
          <div className="col-3 text-right position-relative pr-1">
            <DatePicker
              selected={startDate}
              onChange={this.handleChangeStartDate}
              className="rounded-pill"
              dateFormat="dd/MM/yyyy"
            />
            <img
              src={require("assets/images/calendar.svg")}
              alt="Calendar"
              className="img-fluid img-calendar"
            />
          </div>
          <div className="col-3 text-right position-relative pl-1">
            <DatePicker
              selected={endDate}
              onChange={this.handleChangeEndDate}
              className="rounded-pill"
              dateFormat="dd/MM/yyyy"
            />
            <img
              src={require("assets/images/calendar.svg")}
              alt="Calendar"
              className="img-fluid img-calendar"
            />
          </div>
          <div className="col-2 text-right">
            <button
              className="btn btn-success rounded-pill"
              onClick={this.handleChangeScheduleList.bind(this)}
            >
              <img
                src={require("assets/images/search-white.svg")}
                alt="Search White"
                className="img-search-white"
              />{" "}
              Tìm kiếm
            </button>
          </div>
          <div className="col-12 text-center">
            {this.state.showWarning && <p style={{ color: "red" }}>Khoảng thời gian không đúng</p>}
          </div>
        </div>

        <div className="bg-white pt-3 pl-3 pr-3 pb-2 mt-3">
          <Table hover size="sm" className="mb-0">
            <thead>
              <tr>
                <th className="border-top-0">Người trực</th>
                <th className="border-top-0">Thời gian trực</th>
                <th className="border-top-0">Nhiệm vụ</th>
              </tr>
            </thead>
            <tbody>
              {scheduleList.map((item) => (
                <tr key={item.scheduleId}>
                  <td>
                    <h5 className="mb-0 align-middle text-capitalize">
                      {item.agentName}
                    </h5>
                    <span>{item.mobileNumber}</span>
                  </td>
                  <td className="align-middle">
                    {item.shiftName} - {item.workDate}
                  </td>
                  <td className="align-middle">{item.mission}</td>
                </tr>
              ))}
              {!scheduleList.length && (
                <tr>
                  <td className="text-center" colSpan="3">
                    Không tìm thấy dữ liệu
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <PaginationControl
            pageChanged={this.onSelectPage.bind(this)}
            totalItems={totalItems}
            currentPageIndex={currentPageIndex}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  scheduleList: state.schedule.scheduleList,
  totalItems: state.schedule.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getScheduleList: scheduleActions.GetScheduleList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleListDesktopView);
