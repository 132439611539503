//@ts-check
import { Map } from 'immutable';
import { createFormData, AppKey, StripEnterSymbolHtmlTagAndConverTextToHtmlView, ReplaceImgTag } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { createSaveLoading } from 'core/app.store';
import store from 'redux/store';
import { isMobile } from "react-device-detect";
import iconHitchHike from 'assets/images/hitchhiking.svg';
import iconNeedHelp from 'assets/images/giup-mot-tay.svg';
import iconJoinhands from 'assets/images/chung-tay-15px.svg';
import * as PageUrls from '../../../modules/PageUrls'

export const DEFAULTPAGESIZE = 1000; // sau này chuyển thành 10
const service = new Service();
const isMobileDevice = isMobile;

/* const type neightbor notification */
export const WANTTOHITCHHIKETYPE = "WANTTOHITCHHIKE";
export const AGREEHITCHHIKETYPE = "AGREE_HITCHHIKE";
export const UNAGREEHITCHHIKETYPE = "UNAGREE_HITCHHIKE";
export const NEWHITCHHIKETYPE = "NEWHITCHHIKE";
export const NEEDYOURHELPTYPE = "NEEDYOURHELP";
export const RESIDENTMARKETTYPE = "RESIDENTMARKET";
export const NEWCOMMENTONPOSTINRESIDENTMARKET = "COMMENT_POST_MARKET";
export const EDITPOSTINRESIDENTMARKET = "EDIT_POST_MARKET";
export const DELETEPOSTINRESIDENTMARKET = "DELETE_POST_MARKET";
export const CREATEPOSTINRESIDENTMARKET = "CREATE_POST_MARKET";
export const JOINHANDSTYPE = "JOINHANDS";
export const CREATEEVENTJOINHANDSUCCESS = "CREATE_EVENT";
export const SUGESTIONFROMNEIGHBORSTYPE = "SUGESTIONFROMNEIGHBORS";
export const FEEDBACKSUGESTIONFROMNEIGHBORSTYPE = "FEEDBACK_SUGESTIONFROMNEIGHBORS";
export const CREATEEVENTHELPSUCCESSTYPE = "CREATE_HELP";
export const EDITHELPSUCCESSTYPE = "EDIT_HELP";
export const DELETEHELPSUCCESSTYPE = "DELETE_HELP";
export const NEWCOMMENTHELPTYPE = "COMMENT_HELP";
export const REQUESTADDNEWFRIENDTYPE = "REQUEST_ADD_FRIEND";
export const ACCEPTADDFRIENDTYPE = "ACCEPT_ADD_FRIEND";
export const DECLINEADDFRIENDTYPE = "DECLINE_ADD_FRIEND";
export const BOOKINGRESTAURANTREQUESTTYPE = "BOOKING_REQUESR";
export const BOOKINGRESTAURANTACCEPTTYPE = "BOOKING_ACCEPT";
export const BOOKINGRESTAURANTDECILINETYPE = "BOOKING_DECLINE";
export const CREATEHOTSUCCESSTYPE = "CREATE_HOT";
export const EDITHOTSUCCESSTYPE = "EDIT_HOT";
export const DELETEHOTSUCCESSTYPE = "DELETE_HOT";
export const EDITSHOPSUCCESSTYPE = "EDIT_SHOP";
export const CREATESHOPSUCCESSTYPE = "CREATE_SHOP";
export const CREATE_RENTAL = "CREATE_RENTAL";
export const RENTAL_ACCEPT = "RENTAL_ACCEPT";
export const RENTAL_DECLINE = "RENTAL_DECLINE";
export const RENTAL_EXPIRED = "RENTAL_EXPIRED";

/** Action Types */
const NOTIFICATIONFORMAGENT = 'NOTIFICATION/AGENT';
const NOTIFICATIONDETAIL = "NOTIFICATION/DETAIL";
const NOTTIFICATIONCOUNTALL = "NOTIFICATION/ALLCOUNT";
const NOTIFICATIONCOMMENTLIST = 'NOTIFICATION/COMMENTLIST';
const NOTIFICATIONFROMNEIGHTBOR = 'NOTIFICATION/NEIGHTBOR';

/** Actions */
const createNotificationFromAgent = (data) => ({ type: NOTIFICATIONFORMAGENT, payload: data })
const createNotificationDetail = (data) => ({ type: NOTIFICATIONDETAIL, payload: data })
const createNotificationAllCount = (data) => ({ type: NOTTIFICATIONCOUNTALL, payload: data })
const createNotificationCommentList = (data) => ({ type: NOTIFICATIONCOMMENTLIST, payload: data })
const createNotificationFromNeightbor = (data) => ({ type: NOTIFICATIONFROMNEIGHTBOR, payload: data })

/* call api */

export const MaskNotificationIsRead = (notificationId) => {
    let formData = new FormData();
    formData.append('notificationId', notificationId);
    let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
    service.post(ApiUrl.MaskNotificationIsRead, payLoad).then(res => {
        let payLoad1 = createFormData(null, AppKey.GetPayloadPrefix, new FormData());
        service.post(ApiUrl.GetAllNotificationCount, payLoad1).then(res => {
            store.dispatch(createNotificationAllCount(ExchangeNotificationCountData(res.content)))
        }).catch(err => {
            // console.log(err);
        })
        return true
    }).catch(err => {
        // console.log(err);
        return false
    })
}

export const PostNotificationComment = (registerData) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(registerData, AppKey.PostPayloadPrefix);
        return await service.post(ApiUrl.PostCommentNotitifcation, payLoad).then(res => {
            return true;
        }).catch(err => {
            console.log(err)
            return false;
        })
    }
}

export const DeleteNotificationComments = (feedbackId) => {
    return async dispatch => {
        let formData = new FormData();
        formData.append('feedbackId', feedbackId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return await service.post(ApiUrl.DeletetNotificationComment, payLoad).then(res => {
            return true
        }).catch(err => {
            // console.log(err);
            return false
        })
    }
}

export const GetNotificationComments = (informId, pageIndex = 1, size = DEFAULTPAGESIZE) => {
    return async dispatch => {
        let formData = new FormData();
        formData.append('informId', informId);
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        await service.post(ApiUrl.GetListCommentNotification, payLoad).then(res => {
            dispatch(createNotificationCommentList(ExchangeCommentListData(res.content)))
        }).catch(err => {
            // console.log(err);
        })
    }
}


export const GetNotificationCount = () => {
    return dispatch => {
        let formData = new FormData();
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.GetAllNotificationCount, payLoad).then(res => {
            res.content && dispatch(createNotificationAllCount(ExchangeNotificationCountData(res.content)))
        }).catch(err => {
            throw (err)
        })
    }
}

export const GetNotificationDetail = (informId) => {
    return async dispatch => {
        let formData = new FormData();
        formData.append('informId', informId);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        await service.post(ApiUrl.GetNotificationDetail, payLoad).then(res => {
            dispatch(createNotificationDetail(ExchangeNotificationDetailData(res.content)))
        }).catch(err => {
            // console.log(err);
        })
    }
}

export const GetAgentNotificationList = (pageIndex = 1, size = 10) => {
    return async dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        await service.post(ApiUrl.GetListNotificationFromAgent, payLoad).then(res => {
            dispatch(createNotificationFromAgent(ExchangeAgentNotificationData(res.content)))
        }).catch(err => {
            // console.log(err);

        })
        return true
    }
}

export const GetNeightborNotificationList = (pageIndex = 1, size = 10) => {
    return async dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        await service.post(ApiUrl.GetListNotificationFromNeightbor, payLoad).then(res => {
            dispatch(createNotificationFromNeightbor(ExchangeNeightborNotificationData(res.content)))
        }).catch(err => {
            // console.log(err);
        })
        return true
    }
}

// Initial state
export const initialState = Map({
    agentNotification: {},
    neightborNotification: {},
    notificationDetail: {},
    notificationTotal: {},
    notificationComments: {},
}).toJS();

/** Reducers */
export default function NotificationsReducer(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONFROMNEIGHTBOR: return {
            ...state,
            neightborNotification: action.payload
        }
        case NOTIFICATIONCOMMENTLIST:
            return {
                ...state,
                notificationComments: action.payload
            }
        case NOTTIFICATIONCOUNTALL:
            return {
                ...state,
                notificationTotal: action.payload
            }
        case NOTIFICATIONDETAIL:
            return {
                ...state,
                notificationDetail: action.payload
            }
        case NOTIFICATIONFORMAGENT:
            return {
                ...state,
                agentNotification: action.payload
            }
        default:
            return state;
    }
}

function ExchangeAgentNotificationData(rawData) {
    const standardData = {
        haveData: false,
        listData: [],
        total: 0,
    };
    if (rawData && rawData.items) {
        standardData.haveData = true;
        standardData.total = rawData.totalItemCount;
        standardData.pageSize = rawData.pageSize;
        rawData.items.map((data) => {
            standardData.listData.push({
                informId: data.informId,
                informName: data.title,
                createDate: data.createDate,
                description: StripEnterSymbolHtmlTagAndConverTextToHtmlView(data.description),
                notificationId: data.notificationId,
                backGroundClass: (data.isRead != 0) ? "bg-white" : "",
            })
        })
    }
    return standardData;
}

function ExchangeNeightborNotificationData(rawData) {
    const standardData = {
        haveData: false,
        listData: [],
        total: 0,
    };
    if (rawData && rawData.items) {
        standardData.haveData = true;
        standardData.total = rawData.totalItemCount;
        standardData.pageSize = rawData.pageSize;
        rawData.items.map((data) => {
            standardData.listData.push({
                notificationId: data.notificationId,
                title: data.title,
                senderId: data.senderId,
                createDate: data.createDate,
                type: data.type,
                // type: "WANTTOHITCHHIKE",
                tableName: data.tableName,
                recordId: (data.recordId === "0") ? "" : data.recordId,
                senderName: data.senderName,
                avatarUrl: data.avatarUrl,
                subRecordId: data.subRecordId,
                customData: data.customData,
                isRead: data.isRead,
                // isRead: false,
                backGroundClass: (data.isRead != 0) ? "bg-white" : "",
            })
        })
    }
    return standardData;
}

function ExchangeNotificationDetailData(rawData) {
    if (rawData) {

        const listImage = [];
        const listFileDocument = [];

        rawData.informFile.map((file) => {
            if (file.fileType === "image") listImage.push(file); else listFileDocument.push(file)
        })

        return {
            haveData: true,
            createDate: rawData.createDate,
            description: rawData.description,
            informId: rawData.informId,
            title: rawData.title,
            type: rawData.type,
            imageFiles: listImage,
            documentFiles: listFileDocument,
            senderName: rawData.senderName,
            senderId: rawData.senderId,
            informFile: rawData.informFile,
            avatarUrl: rawData.avatarUrl,
            content: ReplaceImgTag(rawData.content),
        }
    } else return {
        haveData: false
    }
}

function ExchangeNotificationCountData(rawData) {
    if (rawData) return {
        haveData: true,
        total: rawData.total,
        read: rawData.read,
        unread: rawData.unread,
        bqlUnread: rawData.bqlUnread,
        residentUnread: rawData.residentUnread,
    }; else return {
        haveData: false
    }
}

function ExchangeCommentListData(rawData) {

    if (rawData) {
        let haveData = true;
        let total = rawData.totalItemCount;
        let pageSize = rawData.pageSize;
        let pageIndex = rawData.pageIndex;
        let maxPage = Math.ceil(total / pageSize);
        let listComment = [];
        rawData.items.map((data) => {
            let willSaveData = {
                comment: data.comment,
                customerId: data.customerId,
                customerName: data.customerName,
                date: data.date,
                feedbackId: data.feedbackId,
                type: 'comment',
            }
            let replyData = [];
            data.replyData.map((reply) => {
                replyData.push({ ...reply, type: "reply" })
            })
            willSaveData.replyData = replyData;
            listComment.push(willSaveData)
        })
        return { total, haveData, pageIndex, pageSize, listComment, maxPage }
    }

    return {
        haveData: false,
        pageSize: 1,
        pageIndex: 1,
        total: 0,
        listComment: [],
    };
}

export function isWantoHitchHike(type) {
    if (type === WANTTOHITCHHIKETYPE) return true; else return false
}

export function isNeedHelpType(type) {
    switch (type) {
        case NEEDYOURHELPTYPE:
        case CREATEEVENTHELPSUCCESSTYPE:
        case EDITHELPSUCCESSTYPE:
        case DELETEHELPSUCCESSTYPE:
        case NEWCOMMENTHELPTYPE: return true
        default: return false
    }
}

export function getMessageNotification(notificationType) {
    switch (notificationType) {
        case WANTTOHITCHHIKETYPE: return "muốn đi nhờ xe của bạn";
        case AGREEHITCHHIKETYPE: return "đồng ý cho bạn đi nhờ xe";
        case UNAGREEHITCHHIKETYPE: return "từ chối cho bạn đi nhờ xe";
        case NEWHITCHHIKETYPE: return "đã tạo một lộ trình mới trong <b>Đi nhờ xe</b>";
        case NEEDYOURHELPTYPE: return "cần bạn giúp một tay";
        case RESIDENTMARKETTYPE: return "thêm một mặt hàng mới trong <b>Rao vặt</b>";
        case NEWCOMMENTONPOSTINRESIDENTMARKET: return "đã bình luân về bài viết của bạn trong <b>Rao vặt</b>";
        case EDITPOSTINRESIDENTMARKET: return "bài viết của bạn trong <b>Rao vặt</b> đã được sửa";
        case CREATEPOSTINRESIDENTMARKET: return "Bạn đã tạo mới một mặt hàng trong <b>Rao vặt</b>";
        case DELETEPOSTINRESIDENTMARKET: return "bài viết của bạn trong <b>Rao vặt</b> đã được xóa";
        case JOINHANDSTYPE: return "đã tạo một sự kiện mới trong <b>Chung tay</b>";
        case CREATEEVENTJOINHANDSUCCESS: return "Bạn đã tạo một sự kiện trong <b>Chung tay</b>";
        case SUGESTIONFROMNEIGHBORSTYPE: return "gửi góp ý cho bạn";
        case FEEDBACKSUGESTIONFROMNEIGHBORSTYPE: return "đã bình luận trong góp ý";
        case CREATEEVENTHELPSUCCESSTYPE: return "Yêu cầu giúp đỡ của bạn đã được phê duyệt";
        case EDITHELPSUCCESSTYPE: return "Yêu cầu giúp đỡ của bạn đã được thay đổi";
        case DELETEHELPSUCCESSTYPE: return "Yêu cầu giúp đỡ của bạn đã được xóa khỏi hệ thống";
        case NEWCOMMENTHELPTYPE: return "đã trả lời yêu cầu giúp đỡ của bạn";
        case REQUESTADDNEWFRIENDTYPE: return "đã gửi yêu cầu kết bạn";
        case BOOKINGRESTAURANTREQUESTTYPE: return "Có một yêu cầu đặt bàn mới";
        case CREATEHOTSUCCESSTYPE: return "Tin khuyến mãi của bạn đã được đăng";
        case EDITHOTSUCCESSTYPE: return "Bạn đã chỉnh sửa tin khuyến mãi";
        case DELETEHOTSUCCESSTYPE: return "Bạn đã xóa tin khuyên mãi thành công";
        case EDITSHOPSUCCESSTYPE: return "Chỉnh sửa thông tin shop thành công";
        case CREATESHOPSUCCESSTYPE: return "Đơn đăng kí shop đã được phê duyệt";
        case ACCEPTADDFRIENDTYPE: return "đã đồng ý kết bạn";
        case DECLINEADDFRIENDTYPE: return "đã từ chối yêu cầu kết bạn";
        case BOOKINGRESTAURANTACCEPTTYPE: return "Yêu cầu đặt bàn thành công";
        case BOOKINGRESTAURANTDECILINETYPE: return "Yêu cầu đặt bàn của bạn đã bị từ chối";
        case CREATE_RENTAL: return "Bạn đã tạo yêu cầu thuê đồ. Vui lòng đợi xác nhận từ admin.";
        case RENTAL_ACCEPT: return "Admin đã đồng ý cho bạn thuê sản phẩm. Vui lòng đợi nhận hàng.";
        case RENTAL_DECLINE: return "Yêu cầu thuê đồ của bạn đã bị hủy.";
        case RENTAL_EXPIRED: return "Bạn đã quá hạn thuê đồ.";
        default: return "";
    }
}

export function getIconUrl(notificationType) {
    switch (notificationType) {
        case NEWCOMMENTHELPTYPE: case EDITHELPSUCCESSTYPE: case CREATEEVENTHELPSUCCESSTYPE: case NEEDYOURHELPTYPE: case DELETEHELPSUCCESSTYPE:
            return iconNeedHelp;
        case CREATEEVENTJOINHANDSUCCESS: case JOINHANDSTYPE:
            return iconJoinhands;
        case WANTTOHITCHHIKETYPE: case AGREEHITCHHIKETYPE: case UNAGREEHITCHHIKETYPE: case NEWHITCHHIKETYPE:
            return iconHitchHike;
        default: return ""
    }
}

export function getBaseUrl(notificationType, customData) {
    if (isMobileDevice) {
        switch (notificationType) {
            case CREATESHOPSUCCESSTYPE: case EDITSHOPSUCCESSTYPE: case BOOKINGRESTAURANTREQUESTTYPE:
                if (customData && customData.shop_type) {
                    return "/m/shop/" + customData.shop_type + '/';
                }
                return "/m/shop/";
            case EDITHOTSUCCESSTYPE: case CREATEHOTSUCCESSTYPE:
                return "/m/hot/";
            case REQUESTADDNEWFRIENDTYPE:
                return "/m/thong-tin-ca-nhan/ban-be";
            case NEWCOMMENTHELPTYPE: case EDITHELPSUCCESSTYPE: case CREATEEVENTHELPSUCCESSTYPE: case NEEDYOURHELPTYPE:
                return "/m/lang-gieng-gan/giup-mot-tay/chi-tiet/";
            case ACCEPTADDFRIENDTYPE:
                return "/m/thong-tin-ca-nhan/";
            case CREATEEVENTJOINHANDSUCCESS: case JOINHANDSTYPE:
                return "/m/lang-gieng-gan/chung-tay/chi-tiet/";
            case NEWHITCHHIKETYPE:
                return "/m/di-nho-xe";
            case RESIDENTMARKETTYPE: case NEWCOMMENTONPOSTINRESIDENTMARKET: case EDITPOSTINRESIDENTMARKET: case CREATEPOSTINRESIDENTMARKET:
                return "/m/cho-cu-dan/chi-tiet/";
            case SUGESTIONFROMNEIGHBORSTYPE: case FEEDBACKSUGESTIONFROMNEIGHBORSTYPE:
                return "/m/thong-bao/chi-tiet/"
            case CREATE_RENTAL: case RENTAL_ACCEPT: case RENTAL_DECLINE: case RENTAL_EXPIRED:
                return "/m/cho-thue-muon-do";
            default: return ""
        }
    } else {
        switch (notificationType) {
            case CREATESHOPSUCCESSTYPE: case EDITSHOPSUCCESSTYPE: case BOOKINGRESTAURANTREQUESTTYPE:
                if (customData && customData.shop_type) {
                    return "/shop/" + customData.shop_type + '/';
                }
                return "/shop/";
            case EDITHOTSUCCESSTYPE: case CREATEHOTSUCCESSTYPE:
                return "/hot/";
            case REQUESTADDNEWFRIENDTYPE:
                return "/thong-tin-ca-nhan/ban-be";
            case NEWCOMMENTHELPTYPE: case EDITHELPSUCCESSTYPE: case CREATEEVENTHELPSUCCESSTYPE: case NEEDYOURHELPTYPE:
                return "/lang-gieng-gan/giup-mot-tay/chi-tiet/";
            case CREATEEVENTJOINHANDSUCCESS: case JOINHANDSTYPE:
                return "/lang-gieng-gan/chung-tay/";
            case ACCEPTADDFRIENDTYPE:
                return "/thong-tin-ca-nhan/";
            case SUGESTIONFROMNEIGHBORSTYPE: case FEEDBACKSUGESTIONFROMNEIGHBORSTYPE:
                return "/thong-bao/chi-tiet/";
            case NEWHITCHHIKETYPE:
                return "/di-nho-xe";
            case RESIDENTMARKETTYPE: case NEWCOMMENTONPOSTINRESIDENTMARKET: case EDITPOSTINRESIDENTMARKET: case CREATEPOSTINRESIDENTMARKET:
                return "/rao-vat/cho-cu-dan/chi-tiet/";
            case CREATE_RENTAL: case RENTAL_ACCEPT: case RENTAL_DECLINE: case RENTAL_EXPIRED:
                return "/cho-thue-muon-do";
            default: return ""
        }
    }
}