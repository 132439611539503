import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../message-box/message-box-mobile.scss';

class MessageBoxMobileView extends Component {
  render() {
    return (
      <div>
        <div className="bg-white">
          <div className="message-box">
            <div className="message-header">
              <div className="row align-items-center pl-3 pr-3">
                <div className="col-12">
                  <Link to="/m/thong-tin-ca-nhan">
                    <img src={require('assets/images/back-green.svg')} alt="Arrow Back" className="img-fluid arrow-back" />
                  </Link>
                  <img src={require('assets/images/chi-hang.jpg')} alt="Message" className="img-fluid rounded-circle" /> Trần Thu Hương
                </div>
              </div>
            </div>
            <div className="message-body">
              <div className="message-from-friend">
                <ul>
                  <li>
                    <span>Chào Hương</span>
                  </li>
                </ul>
              </div>
              <div className="message-from-me">
                <img src={require('assets/images/chi-hang.jpg')} alt="Message" className="img-fluid rounded-circle" />
                <ul>
                  <li>
                    <span>Chào bạn</span>
                  </li>
                  <li>
                    <span>Mình có thể giúp gì cho bạn</span>
                  </li>
                </ul>
              </div>
              <div className="message-from-friend">
                <ul>
                  <li>
                    <span>Bạn còn cà chua không</span>
                  </li>
                </ul>
              </div>
              <div className="message-from-me">
                <img src={require('assets/images/chi-hang.jpg')} alt="Message" className="img-fluid rounded-circle" />
                <ul>
                  <li>
                    <span>Bạn muốn lấy  bao nhiêu kg</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="message-footer">
              <div className="row align-items-center no-gutters">
                <div className="col-1">
                  <img src={require('assets/images/insert-photo-gray.svg')} alt="insert-photo-gray" />
                </div>
                <div className="col-10">
                  <input type="text" className="form-control rounded-pill" placeholder="Aa" />
                </div>
                <div className="col-1 text-right">
                  <img src={require('/assets/images/send-gray.svg')} alt="send" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageBoxMobileView;