//@ts-check

import React, { lazy } from 'react'
import { Link } from 'react-router-dom'
import './utilities-list-other.scss';
import * as PageUrls from '../../../PageUrls';
import * as ImageCollects from '../../../../utils/image';
/**
 * @param {{currentTypeUrl:string}} [props]
*/
function UtilitiesListOther(props) {
    return (
        <div className="mt-5 utilities-list-other-container">
            <div className="row align-items-center">
                <div className="col-6">
                    <h2 className="title-lg text-size-title">Tiện ích</h2>
                </div>
                <div className="col-6 text-right">
                    <Link to="/danh-sach-tien-ich" className="read-more text-size-sub-title">Khám phá</Link>
                </div>
            </div>

            <div className="row mt-3">
                <RenderChildrend homeUrl={PageUrls.UtilitiesSportHomeDesktop} currentTypeUrl={props.currentTypeUrl} label="Thể thao" iconPath={ImageCollects.IconSport} />
                <RenderChildrend homeUrl={PageUrls.UtilitiesSpaHomeDeskTop} currentTypeUrl={props.currentTypeUrl} label={'Làm đẹp'} iconPath={ImageCollects.IconSpa} />
                <RenderChildrend homeUrl={PageUrls.UtilitiesRestaurantHomeDeskTop} currentTypeUrl={props.currentTypeUrl} label={'Nhà hàng'} iconPath={ImageCollects.IconRestaurant} />
                <RenderChildrend homeUrl={PageUrls.UtilitiesCafeHomeDeskTop} currentTypeUrl={props.currentTypeUrl} label={'Cafe'} iconPath={ImageCollects.IconCafe} />
                <RenderChildrend homeUrl={PageUrls.UtilitiesSchoolHomeDeskTop} currentTypeUrl={props.currentTypeUrl} label={'Trường học'} iconPath={ImageCollects.IconSchool} />
                <RenderChildrend homeUrl={PageUrls.UtilitiesSuperMarketHomeDeskTop} currentTypeUrl={props.currentTypeUrl} label={'Siêu thị'} iconPath={ImageCollects.IconSupermarket} />
            </div>
        </div>
    )
}
/**
 * @param {{homeUrl:string,label:string,iconPath:string,currentTypeUrl:string}} [props]
*/
function RenderChildrend(props) {
    if (props.currentTypeUrl === props.homeUrl) return null
    return (
        <div className="col-3 text-center mb-4 last-child-cut">
            <Link to={props.homeUrl}>
                <img src={props.iconPath} className="img-icon" alt={props.label} />
            </Link>
            <h5>
                <Link to={props.homeUrl} className="text-black">{props.label}</Link>
            </h5>
        </div>
    )
}

export default UtilitiesListOther