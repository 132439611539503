import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from 'core/app.store';
import {
    createFormData,
    AppKey
} from 'utils/configuration';
import Service from 'services/api.service';
import {
    ApiUrl
} from 'services/api-url';

const service = new Service();
/** Action Types */
const DOCUMENTSAVELIST = 'DOCUMENT/SAVELIST';
const DOCUMENTGETLIST = 'DOCUMENT/GETLIST';

/** Actions */
export const createSaveDocumentList = (documentModel) => ({
    type: DOCUMENTSAVELIST,
    payload: {
        documents: documentModel.items,
        totalItems: documentModel.totalItemCount
    }
});

export const createGetDocumentList = () => ({
    type: DOCUMENTGETLIST
});

/** Action creators */
export const GetDocumentList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.DocumentGetList, payLoad).then(res => {
            dispatch(createSaveDocumentList(res.content));
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetDocumentDetail = (documentId) => {
    return dispatch => {
        let payLoad = createFormData({
            paperId: documentId
        });
        return service.post(ApiUrl.DocumentGetDetail, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const DocumentAddOrUpdate = (data, formData = new FormData()) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix, formData);
        return service.post(ApiUrl.DocumentAddOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

export const DocumentDelete = (documentId) => {
    return dispatch => {
        let payLoad = createFormData({
            paperId: documentId
        });
        return service.post(ApiUrl.DocumentDetele, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

// Initial state
export const initialState = Map({
    documentList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function DocumentReducer(state = initialState, action) {
    switch (action.type) {
        case DOCUMENTSAVELIST:
            return {
                ...state,
                documentList: action.payload.documents,
                    totalItems: action.payload.totalItems,
            }
            case DOCUMENTGETLIST:
                return state.documentList;
            default:
                return state;
    }
}