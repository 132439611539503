import React, { Component, } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import HeaderDesktopView from './header-desktop.view';

class HeaderView extends Component {
  renderContent = () => {
    if (isMobile || isTablet) {
      return "";
    }
    return <HeaderDesktopView></HeaderDesktopView>
  }

  render() {
    return this.renderContent();
  }
}

export default HeaderView;
