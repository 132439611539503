import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './utilities-plus-mobile.scss';

class UtilitiesPlusMobileView extends Component {
    render() {
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Tiện ích +</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="utilities-plus-mobile p-3">
                    <div className="item">
                        <div className="row align-items-center">
                            <div className="col-2 text-center">
                                <Link to="/m/sua-chua-lap-dat">
                                    <img src={require("assets/images/electricity-and-water.svg")} alt="electricity-and-water" />
                                </Link>
                            </div>
                            <div className="col-8">
                                <Link to="/m/sua-chua-lap-dat">
                                    Sửa chữa, lắp đặt đồ gia dụng
                                </Link>
                            </div>
                            <div className="col-2 text-right">
                                <Link to="/m/sua-chua-lap-dat">
                                    <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="row align-items-center">
                            <div className="col-2 text-center">
                                <Link to="/m/cho-thue-muon-do">
                                    <img src={require("assets/images/home-decor.svg")} alt="Cho thuê, mượn đồ" />
                                </Link>
                            </div>
                            <div className="col-8">
                                <a href="https://homeid.vn/" target="_blank">
                                    Cải tạo - Trang trí nhà
                                </a>
                            </div>
                            <div className="col-2 text-right">
                                <a href="https://homeid.vn/" target="_blank">
                                    <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="row align-items-center">
                            <div className="col-2 text-center">
                                <Link to="/m/cho-thue-muon-do">
                                    <img src={require("assets/images/rent.svg")} alt="Cho thuê, mượn đồ" />
                                </Link>
                            </div>
                            <div className="col-8">
                                <Link to="/m/cho-thue-muon-do">Cho thuê, mượn đồ</Link>
                            </div>
                            <div className="col-2 text-right">
                                <Link to="/m/cho-thue-muon-do">
                                    <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* <div className="item">
                        <div className="row align-items-center">
                            <div className="col-2 text-center">
                                <Link to="/m/di-nho-xe">
                                    <img src={require("assets/images/hitchhiking.svg")} alt="electricity-and-water" />
                                </Link>
                            </div>
                            <div className="col-8">
                                <Link to="/m/di-nho-xe">
                                    Đi nhờ xe
                                </Link>
                            </div>
                            <div className="col-2 text-right">
                                <Link to="/m/di-nho-xe">
                                    <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="item">
                        <div className="row align-items-center">
                            <div className="col-2 text-center">
                                <Link to="/m/lich-trinh-xe-buyt-tau-dien">
                                    <img src={require("assets/images/bus.svg")} alt="electricity-and-water" />
                                </Link>
                            </div>
                            <div className="col-8">
                                <Link to="/m/lich-trinh-xe-buyt-tau-dien">
                                    Lịch trình xe buýt, tàu điện
                                </Link>
                            </div>
                            <div className="col-2 text-right">
                                <Link to="/m/lich-trinh-xe-buyt-tau-dien">
                                    <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default UtilitiesPlusMobileView;