//@ts-check
import React, { Component } from "react";
import GoogleMapMobile from '../../gmap/googlemap.view';
import Slider from "react-slick";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ItemOfSlider from '../child-component/item-of-list-shop-slide-mobile.view';
import * as PageUrls from '../../PageUrls';
import * as UtilitiesStore from '../../../redux/stores/utillities/utilitis-shop.store';
import DefaultMarkerIcon from 'assets/images/processing.svg';

class UtilitiesSpaMobileView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listMarkerCustom: {
                iconUrl: DefaultMarkerIcon,
                listPosition: []
            }
        }
    }
    componentDidMount() {
        const pageIndex = 1;
        const pageSize = 100;
        this.props.GetListSpa(pageIndex, pageSize);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.listSpa.length != this.props.listSpa.length) {
            const newListMarker = [];
            nextProps.listSpa.map(shop => {
                newListMarker.push({
                    lng: shop.lng,
                    lat: shop.lat
                })
            })
            this.setState({
                listMarkerCustom: {
                    iconUrl: DefaultMarkerIcon,
                    listPosition: newListMarker
                }
            })
        }
        return true;
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2.3,
            slidesToScroll: 2,
            arrows: false,
        }

        return (
            <div>
                <GoogleMapMobile listMarkerCustom={this.state.listMarkerCustom}/>
                <div className="utilities-recovery-mobile p-3">
                    <h2 className="text-center">Khám phá</h2>

                    <div className="row">
                        <div className="col-12">
                            <Slider {...settings}>
                                {
                                    this.props.listSpa.map((data, index) => <ItemOfSlider detailUrl={PageUrls.UtilitiesSpaDetailMobile} key={index} data={data} />)
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    listSpa: state.utilitiShop.spaList.listShop,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListSpa: UtilitiesStore.GetListSpa,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesSpaMobileView)