import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import HelpingHandCommentDesktopView from './helping-hand-comment.view';

export default function RenderHelpingHandContent(props) {
    const { data, currentUserId, loggedIn } = props;
    const [toggleContent, setToggleContent] = useState(false);

    return (
        <div className="col-12 comment-container">
            <div className={`comment-thumbnail ${toggleContent && 'full-detail'}`} >
                <div className="row no-gutters">
                    <div className="col-1 text-center">
                        <img src={data.profile_url || require('assets/images/29-01.png')} alt={data.customerName} className="img-avatar rounded-circle img-fluid" />
                    </div>
                    <div className="col-8">
                        {!toggleContent && <div className="detail">
                            {loggedIn && <h5 className="mb-0">
                                <Link to={`/lang-gieng-gan/giup-mot-tay/chi-tiet/${data.helpId}`}>
                                    <p className="mb-2" dangerouslySetInnerHTML={{ __html: data.content }}></p>
                                </Link>
                            </h5>}
                            {!loggedIn && (<h5 className="mb-0">
                                <p className="mb-2" dangerouslySetInnerHTML={{ __html: data.content }}></p>
                            </h5>)}
                            {loggedIn && <a className="reply-comment" onClick={() => setToggleContent(true)}>{data.countComment} Trả lời <img src={require('assets/images/arrow-right-blue.svg')} alt="Arrow Right Blue" /></a>}
                        </div>}
                        {toggleContent &&
                            <div className="content">
                                <h5 className="fullname">{data.customerName}</h5>
                                <span className="datetime">{moment(data.createdDate).fromNow()}</span>
                                <p><b dangerouslySetInnerHTML={{ __html: data.content }}></b></p>
                                <HelpingHandCommentDesktopView comments={data.comments || []} helpId={data.helpId} callBack={props.callBack} />
                            </div>
                        }
                    </div>
                    <div className="col-3 text-right">
                        <div className="helping-hand-actions">
                            {data.customerId == currentUserId && <div className="actions">
                                <a onClick={() => props.onToggleDeletePopup(true, data.helpId)}>
                                    <img src={require("assets/images/delete-gray.svg")} alt="delete-gray" className="mr-3" />
                                </a>
                                <a onClick={() => props.onToggleEditPopup(true, data.helpId)}>
                                    <img src={require("assets/images/edit-gray.svg")} alt="edit-gray" className="mr-3" />
                                </a>
                            </div>}
                            {data.isUrgent == 1 && <a><img src={require('assets/images/emergency.svg')} className="img-fluid" alt="emergency" /></a>}
                            <a onClick={() => setToggleContent(!toggleContent)}>{
                                <img src={require('assets/images/arrow-up.svg')} className={`icon-down ${toggleContent && 'icon-up'}`} />}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}