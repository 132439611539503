import "./advertising-mobile.scss";
import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import ResidentMarketMobileView from "./resident-market/resident-market-mobile.view";
import HourlyMaidMobileView from "./hourly-maid/hourly-maid-mobile.view";
import RentToMarketMobileView from "./rent-to-market/rent-to-market-mobile.view";
import BuyAndRentHousesMobileView from "./buy-and-rent-houses/buy-and-rent-houses-mobile.view";

class AdvertisingMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideObj: null,
            firstTime: true,
            typeId: Number.isNaN(Number(this.props.match.params.typeId)) ? null : this.props.match.params.typeId,
        }
    }

    setValue(value) {
        if (this.state.firstTime) {
            this.setState({
                firstTime: false,
                slideObj: value
            })
        }
    }

    handleClickNav(i) {
        const navList = Array.from(document.getElementsByClassName("m-advertising-nav"));
        const slideList = document.getElementsByClassName("slick-slide");

        navList.map((navTitle, index) => {
            if (i === index) {
                navTitle.classList.add("disabled");
                slideList[index].classList.add("active");
            } else {
                navTitle.classList.remove("disabled");
                slideList[index].classList.remove("active");
            }
        })
        // if (i === 3) {
        //     this.state.slideObj && this.state.slideObj.slickGoTo(2)
        // }
    }

    componentDidMount() {
        this.handleClickNav(+this.state.typeId || 0);
        document.getElementsByClassName("slick-slide")[this.state.typeId || 0].classList.add("active");
        document.getElementsByClassName("m-advertising-nav")[this.state.typeId || 0].classList.add("disabled");
        if (Number.isNaN(Number(this.props.match.params.typeId))) SelectTabByUrl(this.props.match.params.typeId)
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
        }

        const { typeId } = this.state;

        return (
            <div className="advertising-mobile">
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Chợ làng</div>
                            </div>
                        </div>
                    </div>
                </header>
                <Accordion defaultActiveKey={(typeId || 0).toString()}>
                    <div className="advertising-nav">
                        <Slider ref={slider => this.setValue(slider)} {...settings}>
                            <div className="list-inline-item">
                                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(0)} className="m-advertising-nav" variant="link" eventKey="0">
                                    Rao vặt
                                </Accordion.Toggle>
                            </div>
                            <div className="list-inline-item">
                                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(1)} className="m-advertising-nav" variant="link" eventKey="1">
                                    Giúp việc theo giờ
                                </Accordion.Toggle>
                            </div>
                            {/* <div className="list-inline-item">
                                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(2)} className="m-advertising-nav" variant="link" eventKey="2">
                                    Đi chợ thuê
                                </Accordion.Toggle>
                            </div> */}
                            <div className="list-inline-item">
                                <Accordion.Toggle as={Button} onClick={() => this.handleClickNav(2)} className="m-advertising-nav" variant="link" eventKey="2">
                                    Mua, bán & cho thuê nhà
                                </Accordion.Toggle>
                            </div>
                        </Slider>
                    </div>

                    <div className="mobile-divide"></div>

                    <Accordion.Collapse eventKey="0">
                        <ResidentMarketMobileView />
                    </Accordion.Collapse>

                    <Accordion.Collapse eventKey="1">
                        <HourlyMaidMobileView match={this.props.match} />
                    </Accordion.Collapse>

                    {/* <Accordion.Collapse eventKey="2">
                        <RentToMarketMobileView match={this.props.match} />
                    </Accordion.Collapse> */}

                    <Accordion.Collapse eventKey="2">
                        <BuyAndRentHousesMobileView />
                    </Accordion.Collapse>
                </Accordion>
            </div>
        );
    }
}

function SelectTabByUrl(match) {
    let selectButton = document.getElementsByClassName('m-advertising-nav');
    switch (match) {
        case 'cho-cu-dan': break;
        case 'giup-viec-theo-gio': selectButton[1].click(); break;
        // case 'di-cho-thue': selectButton[2].click(); break;
        case 'mua-ban-thue-nha': selectButton[2].click(); break;
        default: break;
    }
}

export default AdvertisingMobileView;