/* eslint-disable react/prop-types */
//@ts-check

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as PageUrls from "../../../PageUrls";
import * as CommonClass from "../../../../utils/css-common-class";
import UtilitiesListOther from "../../child-component/utilities-list-other/utilities-list-other.view.jsx";

import * as UtilitiesStore from "../../../../redux/stores/utillities/utilitis-shop.store";

import TennisListPageView from "../tennis/tennis-list-page.view.jsx";
import GymListPageView from "../gym/gym-list-page.view.jsx";
import PoolListPageView from "../pool/pool-list-page.view.jsx";
import SoccerListPageView from "../soccer/pool-list-page.view.jsx";
import AllTabListPageView from "../all/all-list-page.view.jsx";

/**@param {{userInfo:object,history:object,match:{path:string},
 * GetListTennis:(index:number,size:number)=>any,
 * }} [props]*/

function UtilitiesSportListDesktopView(props) {
  const getDetailUrlByMatchPatch = () => {
    switch (props.match.path) {
      case PageUrls.UtilitiesPoolDeskTop:
        return PageUrls.UtilitiesPoolDetailDeskTop;
      case PageUrls.UtilitiesGymDeskTop:
        return PageUrls.UtilitiesGymDetailDeskTop;
      case PageUrls.UtilitiesTennisDeskTop:
        return PageUrls.UtilitiesTennisDetailDeskTop;
      case PageUrls.UtilitiesSoccerDeskTop:
        return PageUrls.UtilitiesSoccerDetailDeskTop;
      default:
        return PageUrls.UtilitiesSportHomeDesktop;
    }
  };

  const renderSportTypeLink = (sportName, sportUrl) => (
    <Link
      to={sportUrl}
      className={
        sportUrl === props.match.path ? "active" : CommonClass.TextColorBlack
      }
    >
      {sportName}
    </Link>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   useEffect(() => {}, [props.match.path]);

  return (
    <div className="utilities-desktop utilities-list">
      <div className="row align-items-center">
        <div className="col-5">
          <h2 className="title-lg mb-0">Thể thao</h2>
        </div>
        <div className="col-7">
          <ul className="utilities-sport-list">
            <li>
              {renderSportTypeLink(
                "Tất cả",
                PageUrls.UtilitiesSportHomeDesktop
              )}
            </li>
            <li>
              {renderSportTypeLink("Bể bơi", PageUrls.UtilitiesPoolDeskTop)}
            </li>
            <li>{renderSportTypeLink("Gym", PageUrls.UtilitiesGymDeskTop)}</li>
            <li>
              {renderSportTypeLink("Tennis", PageUrls.UtilitiesTennisDeskTop)}
            </li>
            <li>
              {renderSportTypeLink("Sân bóng", PageUrls.UtilitiesSoccerDeskTop)}
            </li>
          </ul>
        </div>
      </div>

      <hr />

      {PageUrls.UtilitiesSportHomeDesktop === props.match.path && (
        <AllTabListPageView detailUrl={getDetailUrlByMatchPatch()} />
      )}

      {PageUrls.UtilitiesPoolDeskTop === props.match.path && (
        <PoolListPageView detailUrl={getDetailUrlByMatchPatch()} />
      )}

      {PageUrls.UtilitiesGymDeskTop === props.match.path && (
        <GymListPageView detailUrl={getDetailUrlByMatchPatch()} />
      )}

      {PageUrls.UtilitiesTennisDeskTop === props.match.path && (
        <TennisListPageView detailUrl={getDetailUrlByMatchPatch()} />
      )}

      {PageUrls.UtilitiesSoccerDeskTop === props.match.path && (
        <SoccerListPageView detailUrl={getDetailUrlByMatchPatch()} />
      )}

      <UtilitiesListOther currentTypeUrl={PageUrls.UtilitiesSportHomeDesktop} />
    </div>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListTennis: UtilitiesStore.GetListTennis,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UtilitiesSportListDesktopView);
