import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Error } from 'components/support-create-form/validate-style';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import * as joinHandActions from 'redux/stores/join-hand/join-hand.store';
import { convertTextareaToHtml, NewGuid, validateFile } from 'utils/configuration';
import { history } from 'redux/store';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { JwtHelper } from "utils/jwt-helper";
import "../../neighbourhoods-desktop.scss";
import HandleErrorMessaget from '../../../../utils/error-message';

function UpdateJoinsHandForm(props) {

  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange"
  });

  const [previewImg, setPreviewImg] = useState(null);
  const [isShowUpload, setShowUpload] = useState(false);
  const [topicImage, setTopicImage] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const intialValues = {
    topic_id: props.model.topic_id,
    topic_name: props.model.topic_name,
    content: props.model.content,
    location: props.model.location,
    intend_time: props.model.intend_time,
    topic_image: props.model.topic_image,
    responsible: props.model.responsible,
    options: (props.model.options || []).map(option => { return { ...option, render_id: option.option_id } }),
  };

  const [voteOptions, setVoteOptions] = useState(intialValues.options || []);

  if (!previewImg && intialValues.topic_image) {
    setPreviewImg(intialValues.topic_image);
    setShowUpload(!intialValues.topic_image);
  }

  const onSubmit = async data => {
    if (!topicImage.length && !previewImg) {
      return;
    }
    // data.content = convertTextareaToHtml(data.content);
    data.content = data.content;
    data.options = [...voteOptions.map(item => {
      const { render_id, ...option } = item;
      return option;
    })];
    data.topic_image = topicImage;
    props.onUpdateJoinHand && props.onUpdateJoinHand(data);
  };

  const onFileChange = (event) => {
    let file = event.target.files && event.target.files[0];

    if (file) {

      const validateMsg = validateFile(file);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage('');

      var reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImg(e.target.result);
        setShowUpload(false);
      };
      reader.readAsDataURL(file);
      setTopicImage(file);
    }
  };

  const onAddMoreVoteOption = () => {
    setVoteOptions([
      ...voteOptions,
      {
        option_name: '',
        render_id: NewGuid()
      }
    ])
  }

  const onRemovedVoteOption = (voteOptionId) => {
    let newOptions = [...voteOptions];
    const optionIndex = newOptions.findIndex(vote => vote.option_id == voteOptionId);
    newOptions[optionIndex].has_delete = 1;
    setVoteOptions([
      ...newOptions
    ])
  }

  const onChangeVoteName = (optionRenderId, e) => {
    const optionIndex = voteOptions.findIndex(vote => vote.render_id == optionRenderId);
    if (optionIndex >= 0 && !!e.target.value) {
      let newOptions = [...voteOptions];
      newOptions[optionIndex].option_name = e.target.value.trim();
      setVoteOptions([
        ...newOptions,
      ])
    }
  }

  const onDeleteImg = (e) => {
    e && e.preventDefault();
    setShowUpload(true);
  }

  const isHasVotedPeople = (vote) => {
    // return vote.voted_people || [].length > 0;
    return false;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-join-hand mt-3">
      <div className="form-group">
        <label htmlFor="">Tên sự kiện</label>
        <input type="text" defaultValue={intialValues.topic_name} name="topic_name" placeholder="Tên sự kiện..." className="form-control"
          ref={register({
            required: 'Trường bắt buộc', minLength: {
              value: 6,
              message: "Tên sự kiện phải có ít nhất 6 ký tự"
            }
          })} />
        {errors.topic_name && <Error content={errors.topic_name.message} />}
      </div>

      <div className="form-group">
        <label htmlFor="">Thời gian diễn ra sự kiện</label>
        <input type="text" defaultValue={intialValues.intend_time} name="intend_time" placeholder="Thời gian diễn ra sự kiện" className="form-control"
          ref={register({
            required: 'Trường bắt buộc', minLength: {
              value: 6,
              message: "Địa điểm tổ chức sự kiện phải có ít nhất 6 ký tự"
            }
          })} />
        {errors.intend_time && <Error content={errors.intend_time.message} />}
      </div>

      <div className="form-group">
        <label htmlFor="">Địa điểm tổ chức</label>
        <input type="text" defaultValue={intialValues.location} name="location" placeholder="Địa điểm tổ chức" className="form-control"
          ref={register({
            required: 'Trường bắt buộc', minLength: {
              value: 6,
              message: "Thời gian diễn ra sự kiện phải có ít nhất 6 ký tự"
            }
          })} />
        {errors.location && <Error content={errors.location.message} />}
      </div>

      <div className="form-group">
        <label htmlFor="">Người chịu trách nhiệm</label>
        <input type="text" defaultValue={intialValues.responsible} name="responsible" placeholder="Người chịu trách nhiệm" className="form-control"
          ref={register({
            required: 'Trường bắt buộc', minLength: {
              value: 6,
              message: "Tên người chịu trách nhiệm phải có ít nhất 6 ký tự"
            }
          })} />
        {errors.responsible && <Error content={errors.responsible.message} />}
      </div>

      <div className="form-group">
        <label htmlFor="">Thông tin sự kiện</label>
        <textarea name="content" defaultValue={intialValues.content} className="form-control" placeholder="Thông tin sự kiện..." rows="7"
          ref={register({
            required: 'Trường bắt buộc', minLength: {
              value: 6,
              message: "Thông tin sự kiện phải có ít nhất 6 ký tự"
            }
          })}></textarea>
        {errors.content && <Error content={errors.content.message} />}
      </div>

      <div className="form-group row no-gutters">
        {
          !!previewImg &&
          <div className="preview-img col-2">
            <img src={previewImg} alt="Preview Img" className="img-attachment" />
            <div className="overlay"></div>
            <img onClick={(e) => onDeleteImg(e)} src={require("assets/images/close-white.svg")} alt="close-white" className="img-close-white" />
          </div>
        }
        <div className="file-upload col-12 mt-3">
          <input type="text" className="hidden-text-field" id="topic_image" name="topic_image" ref={register} />
          <div className="title-upload">
            <img src={require('assets/images/collections.png')} alt="Image" className="img-fluid" /> <span>Upload ảnh</span>
          </div>
          <input type="file" name="attachmentFile" accept=".png, .jpg, .jpeg" multiple className="media-file" onChange={onFileChange.bind(this)} ref={register({ required: { value: !previewImg, message: 'Trường bắt buộc' } })} />
          {errors.attachmentFile && <Error content={errors.attachmentFile.message} />}
          {validateImgMessage && <Error content={validateImgMessage} />}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-vote rounded p-3">
            <div className="form-group">
              <input type="text" defaultValue={intialValues.topic_name} name="poll_event_name" className="form-control" placeholder="Tên chương trình" ref={register({ required: { value: true, message: 'Trường bắt buộc' } })} />
              {errors.poll_event_name && <Error content={errors.poll_event_name.message} />}
            </div>
            <h5>{intialValues.topic_name}</h5>
            <hr></hr>
            {voteOptions.map((option, index) =>
              <div className="form-group" key={option.render_id}>
                <div className="row no-gutters align-items-center">
                  {!option.has_delete && <div className="col-8">
                    <input type="text" name="option_name" defaultValue={option.option_name} placeholder={`Phương án ${index}`} className="form-control" onChange={(e) => onChangeVoteName(option.render_id, e)} />

                    {!isHasVotedPeople(option) && <button type="button" className="close" aria-label="Close" onClick={() => onRemovedVoteOption(option.option_id)}>
                      <span aria-hidden="true">&times;</span>
                    </button>}
                  </div>}

                  {(option.voted_people || []).length <= 3 &&
                    <div className="col-4 pre-avatar">
                      {option.voted_people || [].map(p => <img key={p.voted_by_id} src={p.voted_profile_url || require('assets/images/29-01.png')} title={p.voted_by} alt={p.voted_by} className="img-fluid mr-1" />)}
                    </div>
                  }
                  {(option.voted_people || []).length > 3 &&
                    <div className="col-4 pre-avatar">
                      <img src={option.voted_people[0].voted_profile_url || require('assets/images/29-01.png')} title={option.voted_people[0].voted_by} alt={option.voted_people[0].voted_by} className="img-fluid mr-1" />
                      <img src={option.voted_people[1].voted_profile_url || require('assets/images/29-01.png')} title={option.voted_people[1].voted_by} alt={option.voted_people[1].voted_by} className="img-fluid mr-1" />
                      <RenderOverlay votedMoreCount={option.voted_people.length - 2} items={option.voted_people} />
                    </div>}
                </div>
              </div>
            )}

            <div className="vote-count mb-3">
              <div className="row no-gutters">
                <span className="col-6">{props.votedCount} lượt bình chọn</span>
                <div className="col-6 text-right">
                  <a onClick={() => onAddMoreVoteOption()}>Thêm phương án +</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-6">
          <div className="row">
            <div className="col-6">
              <Link to="/lang-gieng-gan/chung-tay" className="btn btn-secondary">HỦY</Link>
            </div>
            <div className="col-6 text-right">
              <button type="submit" className="btn btn-success">LƯU THAY ĐỔI</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

function RenderOverlay(props) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Người bình chọn</Popover.Title>
      <Popover.Content>
        {props.items.map(p =>
          <Link key={p.voted_by_id} to="/">
            <img src={p.voted_profile_url || require('assets/images/29-01.png')} title={p.voted_by} alt={p.voted_by} className="img-fluid mr-1" />
          </Link>
        )}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <span className="vote-more-count">
        +{props.votedMoreCount}
      </span>
    </OverlayTrigger>
  )
}

class EditJoinHandDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      joinHandPost: null,
      topicId: this.props.match.params.topicId
    }
  }

  UNSAFE_componentWillMount() {
    this.onGetJoinHandPostDetail();
  }

  onGetJoinHandPostDetail = () => {
    if (this.state.topicId) {
      this.props.getJoinHandDetail(this.state.topicId).then(res => {
        if (res.content) {
          this.setState({
            joinHandPost: res.content
          }, () => {
            this.onCountVotedPeople();
          })
        } else {
          history.push('/lang-gieng-gan/chung-tay')
        }
      }, err => {
        HandleErrorMessaget(err)
      })
    }
  }

  onCountVotedPeople = () => {
    let count = 0;
    this.state.joinHandPost.options || [].map(jp => {
      count += jp.voted_people.length;
    });
    this.setState({
      votedCount: count
    })
  }

  onUpdateJoinHand = (data) => {
    if (!this.props.loggedIn) return;
    let submitedData = {
      ...data,
      topic_id: this.state.topicId,
      customer_id: this.state.currentUser.customerId,
    }

    this.props.createOrUpdateJoinHand(submitedData).then(res => {
      AddNotification('Cập nhật bài viết thành công!', NotificationMessageType.Success);
      history.push('/lang-gieng-gan/chung-tay')
    }, err => {
      HandleErrorMessaget(err)
    })
  }

  render() {
    const { joinHandPost, votedCount } = this.state;
    const { loggedIn } = this.props;
    return (
      <div className="joins-hand">
        <h2 className="title-md">
          <span><Link to="/lang-gieng-gan/chung-tay"> Chung tay</Link></span>
          <span className="text-primary">
            <img src={require("assets/images/slider-arrow-right.png")} alt="slider-arrow-right" className="img-slider-arrow-right" />
            <span> Chỉnh sửa</span>
          </span>
        </h2>
        {joinHandPost && <UpdateJoinsHandForm loggedIn={loggedIn} onUpdateJoinHand={this.onUpdateJoinHand.bind(this)} model={joinHandPost} votedCount={votedCount} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createOrUpdateJoinHand: joinHandActions.CreateOrUpdateJoinsHand,
  getJoinHandDetail: joinHandActions.GetJoinsHandDetail,
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(EditJoinHandDesktopView)