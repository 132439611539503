//@ts-check
import React from 'react';
import { Link } from 'react-router-dom';
import * as PageUrls from '../../../../PageUrls';
import GmapDirect from '../../../../gmap/link-direct';
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import { SetDefaultErrorImage } from "utils/configuration";
import './header-detail.scss';
import * as CommonClass from 'utils/css-common-class';
/**
 * @param {{
 * listPageUrl:string,
 * data:{name:string,address:string,id:number,timeOpen:string,faceBook:string,phone:string,lat:number,lng:number,introduce:string,imagesSlider:{url:string}[]}}} [props]
*/
function RenderHeaderDetailView(props) {
    const data = props.data;
    const settingsSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }
    console.log(JSON.stringify(props.data))
    return (
        <div className="header-container-ddb">
            <div className="utilities-breadcrumb">
                <h2 className={`title-lg ${CommonClass.TextSizeTitle}`}>
                    <Link to={props.listPageUrl} className="text-black">Thể thao</Link>&nbsp;&nbsp;
                    <img src={require('assets/images/keyboard-arrow-right.svg')} alt="keyboard-arrow-right" />&nbsp;&nbsp;
                    <span style={{ fontWeight: "normal" }}>{data.name}</span>
                </h2>
            </div>

            <div className="utilities-img-detail" >
                <Slider {...settingsSlider} className="slider-detail">
                    {(data.imagesSlider.length > 0) ? data.imagesSlider.map((slider, index) =>
                        <img className="w-100 slider-detail image-object-cover" key={index} alt={data.name} onError={SetDefaultErrorImage} src={slider.url} />
                    ) :
                        <img src="" onError={SetDefaultErrorImage} />}
                </Slider>
            </div>

            <h2 className={`title-lg mt-3 ${CommonClass.TextSizeTitle}`}>{data.name}</h2>

            <div className={`row no-gutters ${CommonClass.TextSizeSuperContent}`} style={{ alignItems: "flex-end" }}>
                <div className="col-6">
                    <div className="address mb-1">
                        <img src={require('assets/images/place-red.svg')} alt="Place" className="img-fluid" /> <span className="text-gray">{data.address}</span>
                    </div>
                    <div className="open-time text-gray">
                        <img src={require('assets/images/clock-yellow.svg')} alt="Clock Yellow" className="img-fluid" /> Mở cửa: <span>{data.timeOpen}</span>
                    </div>
                </div>
                <div className="col-2">
                    <div className="social-icon">
                        <a href={data.faceBook} target="_blank" className="d-block mb-1"><img src={require('assets/images/facebook.svg')} alt="Place" className="img-fluid" /></a>
                        <a href={data.faceBook} target="_blank"><span>Facebook</span></a>
                    </div>
                </div>
                <div className="col-2">
                    <div className="social-icon">
                        <a href={`tel:${data.phone}`} className="d-block mb-1">
                            <img src={require('assets/images/phone-call-round.svg')} alt="Place" className="img-fluid" data-tip={`SĐT: ${data.phone}`} />
                        </a>
                        <a href={`tel:${data.phone}`} data-tip={`SĐT: ${data.phone}`}><span>Liên hệ</span></a>
                        <ReactTooltip place="bottom" type="dark" effect="float" />
                    </div>
                </div>
                <div className="col-2">
                    <div className="social-icon">
                        <GmapDirect daddr_lat={data.lat} daddr_lng={data.lng} content={<img src={require('assets/images/right-turn.svg')} alt="Place" className="img-fluid mb-1" />} />
                        <br />
                        <GmapDirect daddr_lat={data.lat} daddr_lng={data.lng} content={<span>Chỉ đường</span>} />
                    </div>
                </div>
            </div>

            <hr></hr>

            <h3 className={`title-sm ${CommonClass.TextSizeSubTitle}`}>Giới thiệu</h3>
            <div className={`font-weight-light content-shop ${CommonClass.TextSizeSuperContent}`} dangerouslySetInnerHTML={{ __html: data.introduce }}>
            </div>
        </div>
    )
}

export default RenderHeaderDetailView