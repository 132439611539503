/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import AddNotification from "components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  TokenKey,
  getCookies,
  setCookies,
  isInitSubdomain,
  SetProjectInfo,
} from "utils/configuration";
import * as viVN from "translation/vi-VN.json";

//--- Action
import * as authActions from "core/auth.store";
import * as loginActions from "redux/stores/user/login.store";
import * as projectActions from "redux/stores/project/project.store";

//--- Libary
import Modal from "react-bootstrap/Modal";
import AsyncSelect from "react-select/async";

//--- View
import RegistrationDesktopView from "../registration-desktop/registration-desktop.view";
import ForgotDesktopView from "../forgot-password/forgot-password-desktop.view";

//--- Css
import "../login-desktop/login-desktop.scss";

const currentDomainList = window.location.host.split(".");
const HOSTNAME = window.location.host;

const ScreenName = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  ACTIVE_ACCOUNT: "ACTIVE_ACCOUNT",
};

class LoginDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenName: ScreenName.LOGIN,
      isLogin: this.props.isLogin || false,
      isRegistration: this.props.isRegistration || false,
      isForgot: false,
      hiddenButton: this.props.hiddenButton || false,
      showModal: this.props.showModal || false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.projectList.length == 0 && this.onGetProjectList();
  }

  onGetProjectList = () => {
    this.props.onGetProjectList().then(
      (res) => {},
      (err) => {}
    );
  };

  onChangeScreen = (_screenName = ScreenName.LOGIN, _hiddenButton = false) => {
    this.setState({
      screenName: _screenName,
      hiddenButton: _hiddenButton,
    });
  };

  onShowLoginOrRegistration = () => {
    const { isLogin, isRegistration } = this.state;
    this.setState({
      isLogin: !isLogin,
      isRegistration: !isRegistration,
      isForgot: false,
      hiddenButton: false,
    });
  };

  onShowForgot = () => {
    this.setState({
      isForgot: true,
      isLogin: false,
      isRegistration: false,
      hiddenButton: true,
    });
  };

  onHideRegistration = () => {
    this.setState({
      hiddenButton: true,
    });
  };

  onShowLoginForm = () => {
    this.setState({
      isLogin: true,
      isRegistration: false,
      isForgot: false,
      hiddenButton: false,
    });
  };

  onShowRegistrationForm = () => {
    this.setState({
      isLogin: false,
      isRegistration: true,
      isForgot: false,
      hiddenButton: false,
    });
  };

  render() {
    return (
      <LoginDesktopForm
        props={this.props}
        state={this.state}
        onShowLoginOrRegistration={this.onShowLoginOrRegistration}
        onShowForgot={this.onShowForgot}
        onHideRegistration={this.onHideRegistration}
        onShowRegistrationForm={this.onShowRegistrationForm}
        onShowLoginForm={this.onShowLoginForm}
      />
    );
  }
}

function LoginDesktopForm(props) {
  const { onLogin, onSaveUserInfo } = props.props;
  const {
    hiddenButton,
    isLogin,
    isRegistration,
    isForgot,
    showModal,
  } = props.state;

  const projectList =
    (props.props.projectList &&
      props.props.projectList.map((item) => {
        return { ...item, value: item.clientId, label: item.clientName };
      })) ||
    [];

  const projectListTemp = props.props.projectList || [];

  const _project =
    projectList.find((item) => item.value == getCookies(TokenKey.Project)) ||
    null;

  const [show, setShow] = useState(showModal);
  const [project, setProject] = useState("");
  const [username, setUserName] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    !project && setProject(_project);
  }, [_project]);

  const closeModal = () => {
    if (showModal) {
      !project && setError("project", "required", "Trường này là bắt buộc");
      !project && clearError(["username", "password"]);
    } else {
      setShow(false);
      setTimeout(
        function () {
          props.props.isLogin && props.onShowLoginForm();
          props.props.isRegistration && props.onShowRegistrationForm();
        }.bind(this),
        300
      );
    }
  };

  const showForgot = () => {
    setUserName("");
    props.onShowForgot();
  };

  const onChangeProject = (data) => {
    if (data) {
      setProject(data);
      clearError(["project"]);
    } else {
      setError("project", "required", "Trường này là bắt buộc");
    }
  };

  const onSubmit = (data) => {
    if (data) {
      onLogin({
        username: data.username,
        password: data.password,
        clientId: data.project,
      }).then(
        (res) => {
          if (res && res.content) {
            onSaveUserInfo(res.content);
            SetProjectInfo(project);
            setCookies(TokenKey.Project, project.value);
            setCookies(TokenKey.ProjectName, project.label);

            if (isInitSubdomain) {
              let projectTemp = projectListTemp.filter(
                (x) => x.clientId == project.value
              );
              if (
                projectTemp &&
                projectTemp.length > 0 &&
                projectTemp[0].domain &&
                currentDomainList &&
                currentDomainList.length > 0
              ) {
                let projectTempList = projectTemp[0].domain.split(".");
                if (projectTempList[0] != currentDomainList[0]) {
                  setCookies(TokenKey.HostName, projectTemp[0].domain);
                  window.location.replace("http://" + projectTemp[0].domain);
                } else {
                  window.location.replace("http://" + HOSTNAME);
                }
              } else {
                window.location.replace("http://" + HOSTNAME);
              }
            } else {
              window.location.replace("http://" + HOSTNAME);
            }
          }
        },
        (err) => {
          if (err && err.errorType && err.errorType == "NotActiveError") {
            setCookies(TokenKey.Project, project.value);
            setCookies(TokenKey.ProjectName, project.label);
            setCookies(TokenKey.HostName, HOSTNAME);
            setUserName(data.username);
            props.onShowForgot();
          } else {
            AddNotification(
              viVN.Errors[(err && err.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        }
      );
    }
  };

  const onLoginWithoutAccount = (e) => {
    if (project && project.value && project.label) {
      SetProjectInfo(project);
      setCookies(TokenKey.Project, project.value);
      setCookies(TokenKey.ProjectName, project.label);

      if (isInitSubdomain) {
        let projectTemp = projectListTemp.filter(
          (x) => x.clientId == project.value
        );
        if (
          projectTemp &&
          projectTemp.length > 0 &&
          projectTemp[0].domain &&
          currentDomainList &&
          currentDomainList.length > 0
        ) {
          let projectTempList = projectTemp[0].domain.split(".");
          if (projectTempList[0] != currentDomainList[0]) {
            window.location.replace("http://" + projectTemp[0].domain);
          } else {
            window.location.replace("http://" + HOSTNAME);
          }
        } else {
          window.location.replace("http://" + HOSTNAME);
        }
      } else {
        window.location.replace("http://" + HOSTNAME);
      }
    } else {
      setError("project", "required", "Trường này là bắt buộc");
      clearError(["username", "password"]);
    }
  };

  const filterProject = (inputValue) => {
    if (inputValue && inputValue.length > 3) {
      return projectList.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      return [];
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      inputValue && firstLoad && setFirstLoad(false);
      resolve(filterProject(inputValue));
    });

  return (
    <span>
      <li className="list-inline-item">
        {props.props.isRegistration && (
          <Link
            to="#"
            id="buttonRegistration"
            className="text-uppercase"
            onClick={() => setShow(true)}
          >
            Đăng ký
          </Link>
        )}
        {props.props.isLogin && (
          <Link
            to="#"
            id="buttonLogin"
            className="text-uppercase"
            onClick={() => setShow(true)}
          >
            Đăng nhập
          </Link>
        )}
      </li>
      <Modal
        show={show}
        onHide={() => closeModal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={!hiddenButton ? "modal-login" : "modal-forgot"}
      >
        {!hiddenButton && (
          <div className="text-center">
            <img
              src={require("assets/images/logo.png")}
              alt="Logo"
              className="img-fluid img-logo"
            />
          </div>
        )}

        <Modal.Body>
          {isLogin && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="row form-login"
              autoComplete="off"
            >
              <div className="col-12">
                <div className="form-group">
                  <AsyncSelect
                    className={
                      "react-select-container" +
                      (firstLoad ? " first-load" : "")
                    }
                    classNamePrefix="react-select"
                    cacheOptions
                    defaultOptions
                    onChange={(data) => onChangeProject(data)}
                    value={project}
                    loadOptions={promiseOptions}
                    noOptionsMessage={() => "Không có kết quả"}
                    placeholder="Chọn dự án (*)"
                  />
                  <input
                    name="project"
                    type="hidden"
                    value={(project && project.value) || ""}
                    ref={register({
                      required: "Trường này là bắt buộc",
                    })}
                  />
                  {errors.project && (
                    <div className="invalid-feedback">
                      {errors.project.message}
                    </div>
                  )}
                </div>

                <div className="form-group position-relative">
                  <img
                    src={require("assets/images/user-login.svg")}
                    alt="User Login"
                    className="img-user-login"
                  />
                  <input
                    name="username"
                    type="text"
                    placeholder="Email hoặc số điện thoại (*)"
                    className="form-control"
                    autoComplete="off"
                    ref={register({
                      required: "Trường này là bắt buộc",
                    })}
                  />
                  {errors.username && (
                    <div className="invalid-feedback">
                      {errors.username.message}
                    </div>
                  )}
                </div>

                <div className="form-group position-relative">
                  <img
                    src={require("assets/images/password.svg")}
                    alt="User Login"
                    className="img-password"
                  />
                  <input
                    name="password"
                    type="password"
                    placeholder="Mật khẩu (*)"
                    className="form-control"
                    autoComplete="off"
                    ref={register({
                      required: "Trường này là bắt buộc",
                      minLength: {
                        value: 6,
                        message: "Mật khẩu phải có ít nhất 6 ký tự",
                      },
                    })}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">
                      {errors.password.message}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-login text-uppercase"
                  >
                    Đăng nhập
                  </button>
                </div>

                <div className="form-group">
                  <Link
                    to="#"
                    className="btn btn-past-login text-uppercase"
                    onClick={onLoginWithoutAccount.bind(this)}
                  >
                    Truy cập không cần tài khoản
                  </Link>
                </div>

                <div className="form-group text-center">
                  <Link
                    to="/"
                    onClick={() => showForgot()}
                    className="fogot-password"
                  >
                    Quên mật khẩu?
                  </Link>
                </div>
              </div>
            </form>
          )}

          {isRegistration && (
            <RegistrationDesktopView
              projectList={projectList}
              isRegistration={isRegistration}
              onHideRegistration={props.onHideRegistration.bind(this)}
              showRegistration={props.onShowRegistrationForm.bind(this)}
              showLogin={props.onShowLoginForm.bind(this)}
            />
          )}

          {isForgot && (
            <ForgotDesktopView
              isForgot={isForgot}
              username={username}
              projectList={projectList}
              showLogin={props.onShowLoginForm.bind(this)}
            />
          )}
        </Modal.Body>
        {!hiddenButton && (isLogin || isRegistration) && (
          <div className="row no-gutters">
            <div className="col-6">
              <button
                className={"tab-login" + (isLogin ? " active" : "")}
                onClick={props.onShowLoginForm}
              >
                Đăng nhập
              </button>
            </div>
            <div className="col-6">
              <button
                className={"tab-register" + (isRegistration ? " active" : "")}
                onClick={props.onShowRegistrationForm}
              >
                Đăng ký
              </button>
            </div>
          </div>
        )}
      </Modal>
    </span>
  );
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.isLoggedIn,
  projectList: state.project.projectList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onLogin: authActions.Login,
      onSaveUserInfo: loginActions.createSaveLoginInfo,
      onGetProjectList: projectActions.GetProjectList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginDesktopView);
