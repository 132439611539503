/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../neighbourhoods-mobile.scss';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as friendActions from 'redux/stores/friend/friend.store';
import SharedService from 'services/share.service.js';
import AddFriendActionView from './add-friend-action.jsx';
import PaginationControl from 'components/pagination/pagination.view.jsx';
import { JwtHelper } from "utils/jwt-helper";

const sharedService = new SharedService();

class AddFriendMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            careerList: [],
            homeTownList: [],
            hobbyList: [],
            careerId: '',
            homeland_province_id: '',
            hobbyId: '',
            customerName: '',
            isLoading: false,
            currentPageIndex: 1,
            currentUser: JwtHelper.currentUser()
        };
    }
    UNSAFE_componentWillMount() {
        if (this.state.currentUser && this.state.currentUser.customerId) {
            this.onGetSuggestionFriends();
            this.onGetDropdownData();
        }
    }

    onGetSuggestionFriends = (pageIndex = this.state.currentPageIndex, size = 10, customerId = this.state.currentUser && this.state.currentUser.customerId, filterObjs = { customer_name: this.state.customerName, career_id: this.state.careerId, homeland_province_id: this.state.homeland_province_id, hobby_id: this.state.hobbyId }) => {
        if (this.state.currentUser && this.state.currentUser.customerId) {
            this.setState({ isLoading: true });
            this.props.getSuggestionFriends(pageIndex, size, customerId, { ...filterObjs }).then(
                res => {
                    this.setState({ isLoading: false });
                },
                err => {
                    this.setState({ isLoading: false });
                }
            );
        }
    };

    onGetDropdownData = () => {
        Promise.all([
            sharedService.getCareerCategories(),
            sharedService.getHomeTowns(),
            sharedService.getHobbyCategoris(),
        ]).then(res => {
            const [careerModels, homeTownModels, hobbyModels] = res;
            this.setState({
                careerList: careerModels.content,
                homeTownList: homeTownModels.content,
                hobbyList: hobbyModels.content,
            })
        }).catch(err => {
        })
    };

    onFilterChange = (e) => {
        if (this.state.currentUser && this.state.currentUser.customerId) {
            e && e.preventDefault();
            this.setState({
                [e.target.name]: e.target.value,
                currentPageIndex: 1
            }, () => {
                this.onGetSuggestionFriends(1, 10, this.state.currentUser.customerId, { customer_name: this.state.customerName, career_id: this.state.careerId, homeland_province_id: this.state.homeland_province_id, hobby_id: this.state.hobbyId });
            });
        }
    }

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetSuggestionFriends(pageNumber);
        })
    }

    onCustomeNameChange = (e) => {
        e && e.preventDefault();
        this.setState({
            customerName: e.target.value.trim()
        })
    }

    render() {
        const { careerList, homeTownList, hobbyList, currentPageIndex } = this.state;
        const { loggingIn, suggestionFriends, totalItems } = this.props;
        return (
            <div className="add-friend-mobile">
                <form onSubmit={this.onFilterChange.bind(this)}>
                    <div className="input-group p-3">
                        <input name="customer_name" type="text" disabled={!loggingIn} placeholder="Nhập tên cư dân" className="form-control border-right-0" onChange={this.onCustomeNameChange.bind(this)} />
                        <div className="input-group-append" onClick={loggingIn && this.onFilterChange.bind(this)}>
                            <span className="input-group-text">
                                <img src={require('assets/images/search-gray.svg')} alt="search-gray" className="img-search-gray" />
                            </span>
                        </div>
                    </div>

                    <div className="form-group pl-3 pr-3">
                        <div className="row no-gutters">
                            <div className="col-4">
                                <select className="form-control" disabled={!loggingIn || this.state.isLoading} name="careerId" onChange={this.onFilterChange.bind(this)} value={this.state.careerId}>
                                    <option value="">Nghề nghiệp</option>
                                    {careerList.map(item =>
                                        <option key={item.careerId} value={item.careerId}>{item.careerName}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-4 pl-2">
                                <select className="form-control" disabled={!loggingIn || this.state.isLoading} name="homeland_province_id" onChange={this.onFilterChange.bind(this)} value={this.state.homeland_province_id}>
                                    <option value="">Quê quán</option>
                                    {homeTownList.map(item =>
                                        <option key={item.provinceId} value={item.provinceId}>{item.provinceName}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-4 pl-2">
                                <select className="form-control" disabled={!loggingIn || this.state.isLoading} name="hobbyId" onChange={this.onFilterChange.bind(this)} value={this.state.hobbyId}>
                                    <option value="">Sở thích</option>
                                    {hobbyList.map(item =>
                                        <option key={item.hobbyId} value={item.hobbyId}>{item.hobbyName}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                <h2 className="pl-3 pr-3">Gợi ý kết bạn</h2>
                <div className="add-friend-list p-3">
                    {suggestionFriends.map(item =>
                        <div key={item.customerId} className="add-friend-item bg-white p-2 rounded">
                            <div className="row no-gutters align-items-center">
                                <div className="col-2">
                                    <Link to={`/m/lang-gieng-gan/cau-lac-bo/ket-ban/${item.customerId}`}>
                                        <img src={item.avatarUrl || require('assets/images/29-01.png')} alt="Add friend image" className="rounded-circle img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <h5>
                                        <Link to={`/m/lang-gieng-gan/cau-lac-bo/ket-ban/${item.customerId}`}>
                                            {item.customerName}
                                        </Link>
                                    </h5>
                                </div>
                                <div className="col-4 text-right">
                                    {item && <AddFriendActionView friendId={item.customerId} status={item.status} callBack={this.onGetSuggestionFriends.bind(this)} />}
                                </div>
                            </div>
                        </div>
                    )}
                    {suggestionFriends.length <= 0 && <p className="col-12">Không tìm thấy dữ liệu.</p>}
                </div>
                <PaginationControl style={"pl-3 pb-3 mt-0"} pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
            </div >
        );
    }
}

const mapStateToProps = state => ({
    loggingIn: state.auth.isLoggedIn,
    suggestionFriends: state.friend.suggestionFriends,
    totalItems: state.friend.totalItems
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getSuggestionFriends: friendActions.GetFriendSuggestionList,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFriendMobileView);