import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as securityCameraActions from 'redux/stores/management/security-camera.store';
import PaginationControl from 'components/pagination/pagination.view';
import './security-camera-mobile.scss';

class SecurityCameraMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            currentPageIndex: 1
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetSecurityCameraList(1, 10, {});
    }

    onGetSecurityCameraList = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.props.getsecurityCameraList(pageIndex, size, filterObjs).then(res => {

        }, err => { 
        })
    }

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetSecurityCameraList(pageNumber);
        })
    }
    render() {
        const { securityCameraList, totalItems } = this.props;
        const { currentPageIndex } = this.state;

        return (
            <div className="security-camera p-2">
                <div className="row">
                    {/* {securityCameraList.map(item => <div key={item.cameraId} className="col-6 mb-2">
                        {!!item.streamUrl ? <iframe width="223" height="124" name={item.cameraName} src={`${item.streamUrl}?&autoplay=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : <img src={item.thumbnail_url} className="img-fluid" alt={item.cameraName} />}
                    </div>)}
                    {!securityCameraList.length && <div className="col-12">Không có bản ghi</div>} */}
                    <div className="col-6 mb-2">
                        <iframe width="223" height="124" name="camera" src={`https://www.youtube.com/embed/DMDbtNrmaZA?&autoplay=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
                <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    securityCameraList: state.securityCamera.securityCameraList,
    totalItems: state.securityCamera.totalItems
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getsecurityCameraList: securityCameraActions.GetsecurityCameraList,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityCameraMobileView);