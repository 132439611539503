//@ts-check
import React,{ useEffect } from 'react';
import ShopHomePageDesktop from '../child-component/shop-home-page/shop-home-page.view'
import * as PageUrls from '../../PageUrls';
import FakeData from '../../../mock-data/spa-list-data';
import { RenderFeatureIsNotReady } from '../../../components/warning-component/not-have-data'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiesStore from '../../../redux/stores/utillities/utilitis-shop.store';
function SpaHomeViewDesktop(props) {
    const pageSize = 8;
    const pageIndex = 1;

    useEffect(() => {
        props.GetListSpa(pageIndex, pageSize);
    }, [])
    return <ShopHomePageDesktop currentTypeUrl={PageUrls.UtilitiesSpaHomeDeskTop} viewMoreUrl={PageUrls.UtilitiesSpaDeskTop} detailUrl={PageUrls.UtilitiesSpaDetailDeskTop} listData={props.listSpa} shopTypeTile="Làm đẹp" />
}

const mapStateToProps = state => ({
    listSpa: state.utilitiShop.spaList.listShop,
    totalSpa: state.utilitiShop.spaList.total,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListSpa: UtilitiesStore.GetListSpa,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SpaHomeViewDesktop)