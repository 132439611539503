import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function DocumentRendering(props) {
  return (
    <div className="van-ban-phap-ly mt-2">
      <Link to={props.redirectUrl}>
        <img src={props.iconUrl} className="img-fluid" />
      </Link>
      <h5 className="mt-2">
        <Link to={props.redirectUrl}>{props.file.name}</Link>
      </h5>
    </div>
  );
}

DocumentRendering.prototype = {
  iconUrl: PropTypes.string,
  redirectUrl: PropTypes.string,
  file: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string
  })
};
