import { Map } from 'immutable';
import { createFormData } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { createSaveLoading } from 'core/app.store';
import { BuildingManagements } from 'mock-data/building-management.mock';

const service = new Service();
/** Action Types */
const BUILDINGMANAGEMENTDETAIL = 'BUILDINGMANAGEMENT/DETAIL';

/** Actions */
export const createSaveBuildingManagementDetail = (buildingManagementDetail) => ({ type: BUILDINGMANAGEMENTDETAIL, payload: buildingManagementDetail });

/** Action creators */
export const GetBuildingManagementDetail = (clientId) => {
    return dispatch => {
        let payLoad = createFormData({ companyId: clientId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.BuildingManagementGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            dispatch(createSaveBuildingManagementDetail(res.content))
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */
export const GetBuildingManagementDetailFake = (customerId) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let buildingManagementDetail = BuildingManagements.find(item => item.customer_id == customerId);
            if (buildingManagementDetail) {
                dispatch(createSaveBuildingManagementDetail(buildingManagementDetail))
                resolve({
                    content: buildingManagementDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    buildingManagementModel: {},
}).toJS();

/** Reducers */
export default function BuildingManagementReducer(state = initialState, action) {
    switch (action.type) {
        case BUILDINGMANAGEMENTDETAIL:
            return {
                ...state,
                buildingManagementModel: action.payload,
            }
        default:
            return state;
    }
}
