/* eslint-disable react/prop-types */
import React, { Component } from "react";

import { SetDefaultErrorImage } from "utils/configuration";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import "../../club.scss";

class BadMinionAttachmentDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpenAttachmentPopup: false,
    };
  }

  render() {
    const { attachments } = this.props;
    const { photoIndex, isOpenAttachmentPopup } = this.state;
    const renderType =
      attachments.length >= 3 ? 3 : attachments.length == 2 ? 2 : 1;

    return (
      <div className="club-post-attachments">
        {attachments && attachments.length > 0 && (
          <div className="golf-images">
            {renderType == 1 && (
              <div className="img-single">
                <img
                  onError={SetDefaultErrorImage}
                  src={attachments[0] && attachments[0].fileUrl}
                  className="img-fluid w-100"
                  onClick={() => this.setState({ isOpenAttachmentPopup: true })}
                />
              </div>
            )}

            {renderType == 2 && (
              <div className="img-double">
                <div className="row">
                  <div className="col-6 pr-1">
                    <img
                      onError={SetDefaultErrorImage}
                      src={attachments[0] && attachments[0].fileUrl}
                      className="img-fluid w-100"
                      onClick={() =>
                        this.setState({ isOpenAttachmentPopup: true })
                      }
                    />
                  </div>
                  <div className="col-6 pl-1">
                    <img
                      onError={SetDefaultErrorImage}
                      src={attachments[1] && attachments[1].fileUrl}
                      className="img-fluid w-100"
                      onClick={() =>
                        this.setState({ isOpenAttachmentPopup: true })
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            {renderType == 3 && (
              <div className="img-multiple">
                <div className="row">
                  <div className="col-7 img-multiple-left pr-1">
                    <img
                      onError={SetDefaultErrorImage}
                      src={attachments[0] && attachments[0].fileUrl}
                      className="img-fluid w-100"
                      onClick={() =>
                        this.setState({ isOpenAttachmentPopup: true })
                      }
                    />
                  </div>
                  <div className="col-5 img-multiple-right pl-1">
                    <div className="row no-gutters">
                      <div className="col-12">
                        <img
                          onError={SetDefaultErrorImage}
                          src={attachments[1] && attachments[1].fileUrl}
                          className="img-fluid w-100"
                          onClick={() =>
                            this.setState({ isOpenAttachmentPopup: true })
                          }
                        />
                      </div>
                      <div className="col-12">
                        <img
                          onError={SetDefaultErrorImage}
                          src={attachments[2] && attachments[2].fileUrl}
                          className="img-fluid w-100"
                          onClick={() =>
                            this.setState({ isOpenAttachmentPopup: true })
                          }
                        />
                        {attachments.length > 3 && (
                          <div
                            className="has-more"
                            onClick={() =>
                              this.setState({ isOpenAttachmentPopup: true })
                            }
                          >
                            +{attachments.length - 3}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {isOpenAttachmentPopup && (
          <Lightbox
            mainSrc={attachments[photoIndex].fileUrl}
            nextSrc={attachments[(photoIndex + 1) % attachments.length].fileUrl}
            prevSrc={
              attachments[
                (photoIndex + attachments.length - 1) % attachments.length
              ].fileUrl
            }
            onCloseRequest={() =>
              this.setState({ isOpenAttachmentPopup: false })
            }
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + attachments.length - 1) % attachments.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % attachments.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default BadMinionAttachmentDesktopView;
