/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import * as shopActions from "redux/stores/shop/shop.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  NewGuid,
  CheckMaxFile,
  CheckFileSize,
  CheckFileTypeImage,
  SetDefaultErrorImage,
} from "utils/configuration";
import { history } from "redux/store";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

class ShopInformationDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shop: this.props.data || null,
      shopType: this.props.type || null,
      userInfo: this.props.userInfo || null,
      isDisplayFirstComponent: false,
    };
  }

  UNSAFE_componentWillMount() {
    let { shop, userInfo, shopType } = this.state;
    if (!shop || !userInfo || !shopType) history.push("/thong-tin-ca-nhan");
  }

  render() {
    const { onCreateShop } = this.props;
    const { shop, userInfo, shopType } = this.state;
    return (
      <Fragment>
        {this.state.isDisplayFirstComponent && (
          <ShopInformation
            shop={shop}
            shopType={shopType}
            user={userInfo}
            onCreateShop={onCreateShop}
            convertFormDataFromShopType={
              shopActions.convertFormDataFromShopType
            }
            handleResset={() =>
              this.setState({
                isDisplayFirstComponent: !this.state.isDisplayFirstComponent,
              })
            }
            refreshPage={this.props.refreshPage}
          />
        )}
        {!this.state.isDisplayFirstComponent && (
          <ShopInformation
            shop={shop}
            shopType={shopType}
            user={userInfo}
            onCreateShop={onCreateShop}
            convertFormDataFromShopType={
              shopActions.convertFormDataFromShopType
            }
            handleResset={() =>
              this.setState({
                isDisplayFirstComponent: !this.state.isDisplayFirstComponent,
              })
            }
            refreshPage={this.props.refreshPage}
          />
        )}
      </Fragment>
    );
  }
}

function ShopInformation(props) {
  const {
    user,
    shop,
    shopType,
    onCreateShop,
    convertFormDataFromShopType,
  } = props;

  const today = new Date();
  const openTimeList = shop && shop.openTime.split(":");
  const closeTimeList = shop && shop.closeTime.split(":");

  const openTime = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth() + 1,
    today.getDate(),
    (openTimeList && openTimeList.length == 2 && parseInt(openTimeList[0])) ||
    8,
    (openTimeList && openTimeList.length == 2 && parseInt(openTimeList[1])) ||
    0,
    0,
    0
  );
  const closeTime = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth() + 1,
    today.getDate(),
    (closeTimeList &&
      closeTimeList.length == 2 &&
      parseInt(closeTimeList[0])) ||
    17,
    (closeTimeList &&
      closeTimeList.length == 2 &&
      parseInt(closeTimeList[1])) ||
    0,
    0,
    0
  );

  const [avatar, setAvatar] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const [address, setAddress] = useState(shop && shop.shopAddress);
  const [location, setLocation] = useState(
    shop && { lat: shop.latitude, lng: shop.longidude }
  );

  const [startDate, setStartDate] = useState(openTime);
  const [endDate, setEndDate] = useState(closeTime);

  const [slides, setSlides] = useState(
    (shop &&
      shop.shopSlide &&
      shop.shopSlide.map((item) => {
        return {
          id: item.imageId,
          name: item.imageId,
          url: item.imageName || "assets/images/shop-avatar-default.png",
          err: false,
          type: "image",
          file: null,
        };
      })) ||
    []
  );
  const [slidesError, setSlidesError] = useState("");
  const [album, setAlbum] = useState(
    (shop &&
      shop.shopImage &&
      shop.shopImage.map((item) => {
        return {
          id: item.imageId,
          name: item.imageId,
          url: item.imageName || "assets/images/shop-avatar-default.png",
          err: false,
          type: "image",
          file: null,
        };
      })) ||
    []
  );
  const [albumError, setAlbumError] = useState("");
  const [fileDelete, setFileDelete] = useState([]);
  const [content, setContent] = useState(shop && shop.content);
  const [contentError, setContentError] = useState("");

  const onChangeContent = (value) => {
    setContent(value);
    value && value !== "<p><br></p>"
      ? setContentError("")
      : setContentError("Trường này là bắt buộc");
  };

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    slidesError &&
      setTimeout(() => {
        setSlidesError("");
      }, 3000);
    albumError &&
      setTimeout(() => {
        setAlbumError("");
      }, 3000);
  });

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (CheckFileSize(file.size)) {
        setFileError(CheckFileSize(file.size));
        return;
      } else if (CheckFileTypeImage(file.type)) {
        setFileError(CheckFileTypeImage(file.type));
        return;
      } else {
        setFileError("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        setAvatar(event.target.result);
        setAvatarFile(file);
      };
    }
    if (avatarFile && errors.avatar) {
      clearError(["avatar"]);
    }
  };

  const onSetOpenTime = (time) => {
    setStartDate(time);
    if (time.getTime() < endDate.getTime()) {
      clearError(["openTime", "closeTime"]);
    } else {
      setError("openTime", "required", "Phải nhỏ hơn thời gian đóng");
    }
  };

  const onSetCloseTime = (time) => {
    setEndDate(time);
    if (time.getTime() > startDate.getTime()) {
      clearError(["openTime", "closeTime"]);
    } else {
      setError("closeTime", "required", "Phải lớn hơn thời gian mở");
    }
  };

  function ReadUri(slide, e) {
    if (e.target.files) {
      const listFile = Array.from(e.target.files);
      let slidesCount = slides.length;
      let albumCount = album.length;
      Promise.all(
        listFile.map((file) => {
          return new Promise((resolve, reject) => {
            if (
              (slide && CheckMaxFile(slidesCount)) ||
              (!slide && CheckMaxFile(albumCount))
            ) {
              slide
                ? setSlidesError(CheckMaxFile(slidesCount))
                : setAlbumError(CheckMaxFile(albumCount));
              resolve({ err: true });
            } else if (CheckFileSize(file.size)) {
              slide
                ? setSlidesError(CheckFileSize(file.size))
                : setAlbumError(CheckFileSize(file.size));
              resolve({ err: true });
            } else if (CheckFileTypeImage(file.type)) {
              slide
                ? setSlidesError(CheckFileTypeImage(file.type))
                : setAlbumError(CheckFileTypeImage(file.type));
              resolve({ err: true });
            } else {
              slide ? slidesCount++ : albumCount++;
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  id: NewGuid(),
                  name: file.name,
                  url: ev.target.result,
                  err: false,
                  type: "image",
                  file: file,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            }
          });
        })
      ).then(
        (images) => {
          let result = [];
          images.map((image) => {
            if (!image.err) {
              result.push(image);
            }
          });
          if (slide) {
            setSlides([...slides, ...result]);
          } else {
            setAlbum([...album, ...result]);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  function DeleteFile(slide, id) {
    if (id && slide) {
      const slidesTemp = slides.filter((x) => x.id != id);
      shop &&
        shop.shopSlide &&
        shop.shopSlide.map(
          (item) => item.imageId == id && setFileDelete([...fileDelete, id])
        );
      setSlides(slidesTemp);
      const isErr = slidesTemp.filter((x) => x.err);
      (!isErr || isErr.length == 0) && setSlidesError("");
    }
    if (id && !slide) {
      const albumTemp = album.filter((x) => x.id != id);
      shop &&
        shop.shopImage &&
        shop.shopImage.map(
          (item) => item.imageId == id && setFileDelete([...fileDelete, id])
        );
      setAlbum(albumTemp);
      const isErr = albumTemp.filter((x) => x.err);
      (!isErr || isErr.length == 0) && setAlbumError("");
    }
  }

  const onSelectAddress = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setLocation(latLng);

    return latLng;
  };

  const onSubmit = async (data) => {
    if (
      (errors && errors.length > 0) ||
      slidesError ||
      albumError ||
      !shop.shopId ||
      !data
    ) {
      return;
    }

    let locationTemp = await onSelectAddress(address);

    onCreateShop(
      {
        customer_id: user.customerId,
        shop_id: shop.shopId,
        category_type: shopType,
        shop_name: data.restaurantName,
        shop_address: data.address,
        shop_hotline: data.tel,
        longitude: locationTemp.lng,
        latitude: locationTemp.lat,
        open_time: moment(startDate).format("HH:mm"),
        close_time: moment(endDate).format("HH:mm"),
        shop_fanpage_url: data.fanpage,
        content: content,
      },
      convertFormDataFromShopType(
        shopType,
        avatarFile,
        slides,
        album,
        0,
        [],
        fileDelete
      )
    ).then(
      (res) => {
        AddNotification(
          "Cập nhật thông tin thành công",
          NotificationMessageType.Success
        );
        props.refreshPage && props.refreshPage()
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const classnames = (...args) => {
    const classes = [];
    args.forEach((arg) => {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key);
          }
        });
      } else {
        throw new Error(
          "`classnames` only accepts string or object as arguments"
        );
      }
    });

    return classes.join(" ");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="row shop-info"
      id="addForm"
    >
      <div className="col-2">
        <div className="form-group position-relative">
          <label>
            Ảnh đại diện<span className="required"></span>
          </label>
          <div className="personal-logo">
            <div className="personal-logo-holder">
              <img
                src={
                  avatar ||
                  shop.logoUrl ||
                  require("assets/images/shop-avatar-default.png")
                }
                onError={SetDefaultErrorImage}
                alt="shop-avatar-default"
                className="img-fluid img-avatar"
              />
            </div>
            {!avatar && (
              <label className="personal-logo-upload">
                <div>
                  <img
                    src={require("assets/images/edit_white.svg")}
                    className="img-fluid img-edit"
                    alt="Edit avatar"
                  />
                  <input
                    type="file"
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => onChangeAvatar(e)}
                    onBlur={(e) => onChangeAvatar(e)}
                    disabled={!(shop.isActive && shop.isActive == 1)}
                  />
                </div>
              </label>
            )}
            {avatar && (
              <label className="personal-logo-upload">
                <img
                  src={require("assets/images/cancel-white.png")}
                  className="img-fluid img-delete"
                  alt="Delete avatar"
                  onClick={() => setAvatar()}
                />
              </label>
            )}
          </div>
          {fileError && <div className="invalid-feedback">{fileError}</div>}
        </div>
      </div>
      <div className="col-10">
        <div className="row">
          <div className="col-6 form-group">
            <label>
              Tên gian hàng<span className="required"></span>
            </label>
            <input
              type="text"
              name="restaurantName"
              defaultValue={shop.shopName}
              className={
                "form-control " + (errors.restaurantName ? "is-invalid" : "")
              }
              placeholder="Nhập tên gian hàng"
              ref={register({
                required: "Trường này là bắt buộc",
              })}
              disabled={!(shop.isActive && shop.isActive == 1)}
            />
            {errors.restaurantName && (
              <div className="invalid-feedback">
                {errors.restaurantName.message}
              </div>
            )}
          </div>
          <div className="col-3 form-group">
            <label>
              Số điện thoại<span className="required"></span>
            </label>
            <input
              type="text"
              name="tel"
              defaultValue={shop.hotline}
              className={"form-control " + (errors.tel ? "is-invalid" : "")}
              placeholder="Nhập số điện thoại"
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 11,
              })}
              disabled={!(shop.isActive && shop.isActive == 1)}
            />
            {errors.tel && errors.tel.type === "required" && (
              <div className="invalid-feedback">Trường này là bắt buộc</div>
            )}
            {errors.tel && errors.tel.type === "minLength" && (
              <div className="invalid-feedback">Tối thiểu 8 số</div>
            )}
            {errors.tel && errors.tel.type === "maxLength" && (
              <div className="invalid-feedback">Tối đa 11 số</div>
            )}
          </div>
          <div className="col-3 form-group">
            <label>
              Thời gian mở cửa<span className="required"></span>
            </label>
            <div className="row">
              <div className="col-6 pr-1">
                <DatePicker
                  name="openTime"
                  selected={startDate}
                  onChange={(date) => date && onSetOpenTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption="Từ"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  className={
                    "form-control " + (errors.openTime ? "is-invalid" : "")
                  }
                  disabled={!(shop.isActive && shop.isActive == 1)}
                />
                {errors.openTime && (
                  <div className="invalid-feedback">
                    {errors.openTime.message}
                  </div>
                )}
              </div>
              <div className="col-6 pl-1">
                <DatePicker
                  name="closeTime"
                  selected={endDate}
                  onChange={(date) => date && onSetCloseTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption="Đến"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  className={
                    "form-control " + (errors.closeTime ? "is-invalid" : "")
                  }
                  disabled={!(shop.isActive && shop.isActive == 1)}
                />
                {errors.closeTime && (
                  <div className="invalid-feedback">
                    {errors.closeTime.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 form-group">
            <label>
              Địa chỉ<span className="required"></span>
            </label>
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={onSelectAddress}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                return (
                  <div className="map__search-bar-container">
                    <div className="map__search-input-container">
                      <input
                        {...getInputProps({
                          placeholder: "Nhập địa chỉ",
                          className:
                            "form-control " +
                            (errors.address ? "is-invalid" : ""),
                        })}
                        disabled={!(shop.isActive && shop.isActive == 1)}
                      />
                    </div>
                    {suggestions.length > 0 && (
                      <div className="map__autocomplete-container">
                        {suggestions.map((suggestion, index) => {
                          const className = classnames("map__suggestion-item", {
                            "map__suggestion-item--active": suggestion.active,
                          });
                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <strong>
                                {suggestion.formattedSuggestion.mainText}
                              </strong>{" "}
                              <small>
                                {suggestion.formattedSuggestion.secondaryText}
                              </small>
                            </div>
                          );
                        })}
                        <div className="map__dropdown-footer">
                          <div>
                            <img
                              src={require("assets/images/powered_by_google_default.png")}
                              className="map__dropdown-footer-image"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
            </PlacesAutocomplete>
            <input
              type="hidden"
              name="address"
              value={address}
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            />
            {errors.address && (
              <div className="invalid-feedback">{errors.address.message}</div>
            )}
          </div>
          <div className="col-6 form-group">
            <label>
              Facebook<span className="required"></span>
            </label>
            <input
              type="text"
              name="fanpage"
              defaultValue={shop.fanpageUrl}
              className={"form-control " + (errors.fanpage ? "is-invalid" : "")}
              placeholder="https://facebook.com/Highlight.Restaurant"
              ref={register({
                required: "Trường này là bắt buộc",
                pattern: {
                  value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
                  message: "Đường dẫn không hợp lệ",
                },
              })}
              disabled={!(shop.isActive && shop.isActive == 1)}
            />
            {errors.fanpage && (
              <div className="invalid-feedback">{errors.fanpage.message}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label>
            Giới thiệu<span className="required"></span>
          </label>
          <SunEditor
            enableToolbar={true}
            showToolbar={true}
            setOptions={{
              height: "auto",
              minHeight: 500,
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "fullScreen",
                  "showBlocks",
                  "codeView",
                ],
              ],
            }}
            setContents={shop.content}
            onChange={onChangeContent}
            onBlur={(event, editorContents) => onChangeContent(editorContents)}
            disable={!(shop.isActive && shop.isActive == 1)}
          />
          {contentError && (
            <div className="invalid-feedback">{contentError}</div>
          )}
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Ảnh slide</label>
              <div className="image-slider pt-2 pr-2 pb-0 pl-2">
                <div className="row no-gutters">
                  {slides &&
                    slides.map((item) => (
                      <div
                        className="col-3 position-relative mb-2"
                        key={item.id}
                      >
                        <img
                          src={item.url}
                          alt={item.name}
                          onError={SetDefaultErrorImage}
                          className={
                            "img-fluid img-slider" +
                            (item.err ? " border border-danger" : "")
                          }
                        />
                        <img
                          src={require("assets/images/close-white.svg")}
                          alt="Close"
                          className="img-close-white d-block"
                          onClick={() => DeleteFile(true, item.id)}
                        />
                      </div>
                    ))}

                  <div className="col-3 mb-2">
                    <div className="empty-item">
                      <img
                        src={require("assets/images/insert-photos.svg")}
                        alt="insert-photos"
                        className="img-plus"
                      />
                      <input
                        type="file"
                        name="slide"
                        accept=".png, .jpg, .jpeg"
                        multiple
                        onChange={(e) => ReadUri(true, e)}
                        disabled={!(shop.isActive && shop.isActive == 1)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {slidesError && (
                <div className="invalid-feedback">{slidesError}</div>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Thư viện ảnh</label>
              <div className="image-slider pt-2 pr-2 pb-0 pl-2">
                <div className="row no-gutters">
                  {album &&
                    album.map((item) => (
                      <div
                        className="col-3 position-relative mb-2"
                        key={item.id}
                      >
                        <img
                          src={item.url}
                          alt={item.name}
                          onError={SetDefaultErrorImage}
                          className={
                            "img-fluid img-slider" +
                            (item.err ? " border border-danger" : "")
                          }
                        />
                        <img
                          src={require("assets/images/close-white.svg")}
                          alt="Close"
                          className="img-close-white d-block"
                          onClick={() => DeleteFile(false, item.id)}
                        />
                      </div>
                    ))}

                  <div className="col-3 position-relative mb-2">
                    <div className="empty-item">
                      <img
                        src={require("assets/images/insert-photos.svg")}
                        alt="insert-photos"
                        className="img-plus"
                      />
                      <input
                        type="file"
                        name="album"
                        accept=".png, .jpg, .jpeg"
                        multiple
                        onChange={(e) => ReadUri(false, e)}
                        disabled={!(shop.isActive && shop.isActive == 1)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {albumError && (
                <div className="invalid-feedback">{albumError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-6"></div>
          <div className="col-3">
            <button
              type="reset"
              className="btn btn-secondary w-100 text-uppercase rounded-pill"
              disabled={!(shop.isActive && shop.isActive == 1)}
              onClick={() => props.handleResset()}
            >
              Hủy
            </button>
          </div>
          <div className="col-3">
            <button
              type="submit"
              className="btn btn-success w-100 text-uppercase rounded-pill"
              disabled={!(shop.isActive && shop.isActive == 1)}
            >
              Lưu thay đổi
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreateShop: shopActions.AddOrUpdateShop,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopInformationDesktopView);
