/* eslint-disable react/prop-types */
import React, { useEffect, useState,Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../payment-mobile.scss';
import { Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as WaterBillStores from 'redux/stores/payment/water/water.store';
import { ToolSelectPeriodTime } from "utils/configuration";
import { RenderInfo } from '../../payment-desktop/electric/electric-bill-month.view.jsx';
import { Error } from 'components/support-create-form/validate-style';
import { RenderMonthlyBill } from './detail/water-detail-mobile.view.jsx';
import RenderNavigationBar from '../nav-header/nav-header.view.jsx';
import { handleChangeListHistory } from "../../payment-desktop/logic-payment/handle-change-list-history";
import { PromiseAllTools } from 'core/app.store';
import { useRenderPaymentHistoryList } from '../../../components/custom-hook/render-payment-history-list/render-payment-history-list';
import Pagination from '../../../components/pagination/paging.view';

function PaymentWaterMobileView(props) {
    const { endDate, startDate, handleChangeStartDate, handleChangeEndDate, resetChangeDateTime, filterObj } = new ToolSelectPeriodTime()
    const [showWarning, setShowWarning] = useState(false);

    function ControlChangeListHistory() {
        handleChangeListHistory(
            startDate,
            endDate,
            () => props.PromiseAllTools([props.GetWaterList(1, 100, filterObj, props.userInfo.apartmentId)]),
            resetChangeDateTime,
            setShowWarning,
            props.ResetHistoryList
        )
    }

    useEffect(() => {
        props.PromiseAllTools([
            props.GetWaterBillThisMonth(props.userInfo.apartmentId),
            props.GetWaterList(1, 100, filterObj, props.userInfo.apartmentId)
        ])
    }, [])

    const listPay = props.listPaid || [];
    return (
        <div className="payment-mobile">
            <RenderNavigationBar type="water" />

            <div className="mobile-divide"></div>
            <div className="payment-info bg-white p-3">
                {props.monthlyBill.haveData ? <RenderMonthlyBill data={props.monthlyBill} /> : <RenderInfo />}

            </div>

            <div className="mobile-divide"></div>

            <div className="payment-history bg-white p-3">
                <h2>Lịch sử thanh toán</h2>
                <div className="row no-gutters">
                    <div className="col-6 position-relative pr-1">
                        <DatePicker selected={startDate} onChange={handleChangeStartDate} className="form-control rounded-pill" dateFormat="dd/MM/yyyy" disabled={listPay.length === 0} />
                        <img src={require("assets/images/calendar.svg")} alt="Calendar" className="img-calendar" />
                    </div>
                    <div className="col-6 position-relative pl-1 pr-1">
                        <DatePicker selected={endDate} onChange={handleChangeEndDate} className="form-control rounded-pill" dateFormat="dd/MM/yyyy" popperPlacement="bottom-end" disabled={listPay.length === 0} />
                        <img src={require("assets/images/calendar.svg")} alt="Calendar" className="img-calendar" />
                    </div>
                    <div className="col-6">{showWarning && <Error content="Khoảng thời gian không đúng, vui lòng kiểm tra lại." />}</div>
                    <div className="col-12 text-right search-box mt-2 pr-1">
                        <button className="btn btn-success rounded-pill" onClick={ControlChangeListHistory} disabled={listPay.length === 0}>
                            <img src={require("assets/images/search-white.svg")} alt="Search White" className="img-search-white" /> Tìm kiếm
                  </button>
                    </div>
                </div>

                {listPay.length > 0
                    ? <RenderListHistory listData={listPay} />
                    : (
                        <div className="border-top mt-2 pt-3">
                            <p className="text-center mb-0">Không có dữ liệu để hiển thị</p>
                        </div>
                    )
                }
            </div>

            <div className="payment-guide">
                <Link to="/m/thanh-toan/tien-nuoc/huong-dan-thanh-toan">
                    Hướng dẫn thanh toán <img src={require("assets/images/right-arrow-blue.svg")} alt="right-arrow-blue" className="img-right-arrow-blue" />
                </Link>
            </div>
        </div>
    );
}

function RenderListHistory(props) {
    const {
        listData,
        Page_Size,
        displayList,
        setCurrentIndex
    } = useRenderPaymentHistoryList(props.listData)
    return (
        <Fragment>
            <Table hover className="mt-1 mb-0">
                <tbody>
                    {displayList.map((data, index) => <RenderItemOfList data={data} key={index} />)}
                </tbody>
            </Table>
            {
                (listData.length > Page_Size) && <Pagination totalItems={listData.length} size={Page_Size} goTo={setCurrentIndex} />
            }
        </Fragment>
    )
}

function RenderItemOfList(props) {
    const data = props.data
    return (
        <tr>
            <td><Link className="item-list-pay" to={`/m/thanh-toan/tien-nuoc/chi-tiet/${data.billId}`}>
                <h5>
                    Hóa đơn nước tháng {data.monthly}/{data.yearly}
                </h5>
                <span>Ngày thanh toán: {data.datePay || "Chưa thanh toán"}</span>
            </Link>

            </td>
            <td className="text-right total-price"><Link to={`/m/thanh-toan/tien-nuoc/chi-tiet/${data.billId}`}>{data.total}VNĐ</Link></td>
        </tr>
    )
}

const mapStateToProps = state => ({
    monthlyBill: state.waterBill.billThisMonth,
    listPaid: state.waterBill.paymentHistory,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetWaterList: WaterBillStores.GetWaterList,
    GetWaterBillThisMonth: WaterBillStores.GetWaterBillThisMonth,
    ResetHistoryList: WaterBillStores.ResetHistoryList,
    PromiseAllTools
},
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWaterMobileView)
