/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as registrationActions from "redux/stores/user/registration.store";
import "../terms-of-service-mobile/terms-of-service-mobile.scss";

class TermsOfServiceMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policy: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.onGetPolicy().then((res) => {
      if (res && res.content && res.content.policy) {
        this.setState({ policy: res.content.policy });
      }
    });
  }
  render() {
    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/dang-ky">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">
                  Điều khoản sử dụng
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="p-3">
          <p dangerouslySetInnerHTML={{ __html: this.state.policy }}></p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetPolicy: registrationActions.GetPolicy,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsOfServiceMobileView);
