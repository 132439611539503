/* eslint-disable no-unused-vars */
import { Map } from "immutable";
import { createFormData, AppKey } from "../../../utils/configuration";
import Service from "../../../services/api.service";
import { ApiUrl } from "../../../services/api-url";

const service = new Service();
/** Action Types */
const NEW_SAVE_LIST = "NEW/SAVE_LIST";
const NEW_GET_LIST = "NEW/GET_LIST";

/** Actions */
export const createSaveNewList = (newModel) => ({
  type: NEW_SAVE_LIST,
  payload: {
    news: newModel.items,
    totalItems: newModel.totalItemCount,
  },
});
export const createGetNewList = () => ({
  type: NEW_GET_LIST,
});

/** Action creators */
export const GetNewList = (pageIndex, size, filterObj) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    let payLoad = createFormData(filterObj, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.NewGetList, payLoad)
      .then((res) => {
        dispatch(createSaveNewList(res.content));
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetNewDetail = (newId) => {
  return (dispatch) => {
    let payLoad = createFormData({
      newsId: newId,
    });
    return service
      .post(ApiUrl.NewGetDetail, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

//--- Comment
export const GetListComment = (pageIndex, size, filterObj, id, parentId) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("newsId", id);
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    parentId && formData.append("parent_id", parentId);
    let payLoad = createFormData(filterObj, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.GetListCommentNew, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetCommentDetail = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      commentId: id,
    });
    return service
      .post(ApiUrl.GetCommentNew, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateComment = (data) => {
  return (dispatch) => {
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
    return service
      .post(ApiUrl.UpdateCommentNew, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const CommentDelete = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      commentId: id,
    });
    return service
      .post(ApiUrl.DeleteCommentNew, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const UpdateLike = (data) => {
  return (dispatch) => {
    let payLoad = createFormData({ news_id: data }, AppKey.PostPayloadPrefix);
    return service
      .post(ApiUrl.UpdateLikeNew, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const IsLike = (data) => {
  return (dispatch) => {
    let payLoad = createFormData({ news_id: data }, AppKey.GetPayloadPrefix);
    return service
      .post(ApiUrl.IsLikeNew, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

// Initial state
export const initialState = Map({
  newList: [],
  totalItems: 0,
}).toJS();

/** Reducers */
export default function NewReducer(state = initialState, action) {
  switch (action.type) {
    case NEW_SAVE_LIST:
      return {
        ...state,
        newList: action.payload.news,
        totalItems: action.payload.totalItems,
      };
    case NEW_GET_LIST:
      return state.newList;
    default:
      return state;
  }
}
