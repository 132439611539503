/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as confirmCodeActions from "redux/stores/user/confirm-code.store";
import * as forgotActions from "redux/stores/user/forgot-password.store";
import ResetPasswordDesktopView from "../reset-password/reset-password-desktop.view";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType } from "utils/configuration";
import * as viVN from "translation/vi-VN.json";

class ConfirmCodeDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmCode: this.props.isConfirmCode || false,
      isResetPassword: this.props.isResetPassword || false,
      isRegistration: this.props.isRegistration || false,
      clientId: this.props.clientId,
      count: 0,
      running: false,
      mobileNumber: this.props.tel.trim(),
    };
  }

  UNSAFE_componentWillMount() {
    const { SendConfirmCode, isSendCode, tel } = this.props;
    const { isRegistration } = this.state;
    if (!isRegistration && isSendCode && tel) {
      SendConfirmCode({
        username: tel.trim(),
      }).then(
        (res) => {
          res &&
            res.content &&
            res.content.mobileNumber &&
            this.setState({
              mobileNumber: res.content.mobileNumber,
            });
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  }

  componentDidMount() {
    this.handleCountdown(60);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.running !== prevState.running) {
      switch (this.state.running) {
        case true:
          this.handleStart();
      }
    }
  }

  handleStart() {
    this.timer = setInterval(() => {
      const newCount = this.state.count - 1;
      this.setState({ count: newCount >= 0 ? newCount : 0 });
    }, 1000);
  }

  handleStop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState({ running: false });
    }
  }

  handleReset() {
    this.setState({ count: 0 });
  }

  handleCountdown(seconds) {
    this.setState({
      count: seconds,
      running: true,
    });
  }

  onResetPassword = () => {
    const { isConfirmCode, isResetPassword } = this.state;
    this.setState({
      isConfirmCode: !isConfirmCode,
      isResetPassword: !isResetPassword,
    });
  };

  render() {
    return (
      <ConfirmCodeDesktopForm
        ConfirmCode={this.props.ConfirmCode}
        state={this.state}
        props={this.props}
        onSetCountdown={this.handleCountdown.bind(this)}
        onResetPassword={this.onResetPassword}
        showRegistration={this.props.onShowRegistration}
        onShowLogin={this.props.showLogin}
      />
    );
  }
}

function ConfirmCodeDesktopForm(props) {
  const { clientId } = props.props;
  const [has, setHas] = useState("");
  const [tel, setTel] = useState("");
  const [activeSuccess, setActive] = useState(false);

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSendCode = (data) => {
    if (props.props.isSendCode) {
      props.props
        .SendConfirmCode({
          clientId: clientId,
          username: props.props.tel.trim(),
        })
        .then(
          (res) => {
            if (res && res.content) {
              AddNotification("Gửi thành công", "success");
              props.onSetCountdown(60);
            } else {
              res &&
                res.errorType &&
                AddNotification(
                  viVN.Errors[res && res.errorType],
                  NotificationMessageType.Error
                );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              AddNotification(
                viVN.Errors[err && err.errorType],
                NotificationMessageType.Error
              );
          }
        );
    } else {
      props.props
        .Forgot({
          clientId: clientId,
          username: props.props.tel.trim(),
          resetUrl: window.location.origin,
        })
        .then(
          (res) => {
            if (res && res.content) {
              AddNotification("Gửi thành công", "success");
              props.onSetCountdown(60);
            } else {
              res &&
                res.errorType &&
                AddNotification(
                  viVN.Errors[res && res.errorType],
                  NotificationMessageType.Error
                );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              AddNotification(
                viVN.Errors[err && err.errorType],
                NotificationMessageType.Error
              );
          }
        );
    }
  };

  const onSubmit = (data) => {
    if (data) {
      if (props.props.isSendCode) {
        props.props
          .ActiveAccount({
            clientId: clientId,
            username: props.props.tel,
            confirmCode: data.code,
          })
          .then(
            (res) => {
              if (res && res.content) {
                setActive(true);
                props.onResetPassword();
              } else {
                res &&
                  res.errorType &&
                  AddNotification(
                    viVN.Errors[res && res.errorType],
                    NotificationMessageType.Error
                  );
              }
            },
            (err) => {
              err &&
                err.errorType &&
                AddNotification(
                  viVN.Errors[err && err.errorType],
                  NotificationMessageType.Error
                );
            }
          );
      } else {
        props.props
          .ConfirmCode({
            clientId: clientId,
            username: props.props.tel,
            resetCode: data.code,
          })
          .then(
            (res) => {
              if (res && res.content) {
                res.content.mobileNumber && setTel(res.content.mobileNumber);
                setHas(data.code);
                props.onResetPassword();
              } else {
                res &&
                  res.errorType &&
                  AddNotification(
                    viVN.Errors[res && res.errorType],
                    NotificationMessageType.Error
                  );
              }
            },
            (err) => {
              err &&
                err.errorType &&
                AddNotification(
                  viVN.Errors[err && err.errorType],
                  NotificationMessageType.Error
                );
            }
          );
      }
    }
  };

  const RenderTel = (data) => {
    let str = "";
    for (let i = 0; i < data.length - 3; i++) str += "*";
    str += data.slice(data.length - 2, data.length);
    return str;
  };

  return (
    <div>
      {props.state.isRegistration && (
        <div className="modal-header">
          <div className="pl-3">
            <img
              src={require("assets/images/arrow-back.svg")}
              alt="Arrow Back"
              onClick={props.showRegistration.bind(this)}
              className="img-fluid img-arrow-back"
            />
          </div>
          <div className="modal-title"></div>
        </div>
      )}
      {props.state.isConfirmCode && (
        <form onSubmit={handleSubmit(onSubmit)} className="row form-forgot">
          <div className="col-12">
            <div className="form-group">
              <label>
                Vui lòng kiểm tra tin nhắn trên điện thoại. Chúng tôi đã gửi mã
                xác nhận đến số:
                {RenderTel(props.state.mobileNumber.toString())}
              </label>
            </div>

            <div className="form-group">
              <input
                name="code"
                type="text"
                placeholder="Mã xác nhận của bạn"
                className="form-control"
                ref={register({
                  required: "Trường này là bắt buộc",
                })}
              />
              {errors.code && (
                <div className="invalid-feedback">{errors.code.message}</div>
              )}
            </div>

            {!props.state.isRegistration && (
              <div className="form-group text-right">
                <button
                  type="button"
                  className="btn btn-light text-uppercase rounded-pill mr-2 pl-4 pr-4"
                  onClick={onSendCode}
                  disabled={props.state.count > 0}
                >
                  {props.state.count > 0 ? (
                    <span>Gửi lại ({props.state.count})</span>
                  ) : (
                      <span>Gửi lại</span>
                    )}
                </button>
                <button
                  type="submit"
                  className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
                >
                  Tiếp tục
                </button>
              </div>
            )}
            {props.state.isRegistration && (
              <div className="form-group text-right">
                <button
                  type="submit"
                  className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
                >
                  Xác nhận
                </button>
              </div>
            )}
          </div>
        </form>
      )}
      {props.state.isResetPassword && (
        <ResetPasswordDesktopView
          isResetPassword={props.state.isResetPassword}
          isRegistration={props.state.isRegistration}
          showLogin={props.onShowLogin.bind(this)}
          has={has}
          tel={tel}
          activeSuccess={activeSuccess}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ConfirmCode: confirmCodeActions.ConfirmCode,
      ActiveAccount: confirmCodeActions.ActiveAccount,
      SendConfirmCode: confirmCodeActions.SendConfirmCode,
      Forgot: forgotActions.Forgot,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmCodeDesktopView);
