/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as NotificationsStore from "../../../redux/stores/notification/notifications.store";
import NotificationCommentDesktopView from "./childs/notification-comment.views.jsx";
import "./notification-detail-mobile.scss";
import { Link } from "react-router-dom";
import { PromiseAllTools } from "core/app.store";
import { SetDefaultErrorImage } from "utils/configuration";

class NotificationDetailMobileView extends Component {
  UNSAFE_componentWillMount() {
    this.props.PromiseAllTools([
      this.props.GetNotificationDetail(
        Number(this.props.match.params.idNotification)
      ),
      this.props.GetNotificationComments(
        Number(this.props.match.params.idNotification),
        1
      ),
    ]);
  }

  render() {
    const detailData = this.props.notificationDetailData || {};
    const content = {
      __html: this.props.notificationDetailData
        ? // ? convertTextareaToHtml(this.props.notificationDetailData.content)
          this.props.notificationDetailData.content
        : "Không có dữ liệu",
    };

    return (
      <div className="notification-detail-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center no-gutters">
              <div className="col-1">
                <Link to="/m">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
              </div>
              <div className="col-11 pl-2">
                <div className="header-title text-center text-truncate">
                  {detailData.title}
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="notification-detail-container bg-white p-3">
          <h5>{detailData.title}</h5>
          <span className="datetime">
            <img
              src={require("assets/images/calendar.svg")}
              alt="Calendar"
              className="img-calendar"
            />{" "}
            {detailData.createDate}
          </span>
          {detailData.type === "SUGESTIONFROMNEIGHBORS" ? (
            <div
              className="text-justify"
              id="content-container-notification-detail"
            >
              {content.__html}
            </div>
          ) : (
            <div
              className="text-justify"
              id="content-container-notification-detail"
              dangerouslySetInnerHTML={content}
            ></div>
          )}

          <div className="row">
            {detailData.haveData &&
              detailData.imageFiles.map((fileImages) => (
                <RenderImage data={fileImages} />
              ))}
          </div>
          <br />
          <br />
          <br />
          {detailData.haveData && detailData.documentFiles.length > 0 && (
            <div className="row">
              <h6 className="col-12">File đính kèm</h6>
              {detailData.documentFiles.map((fileImages) => (
                <RenderAttackFile data={fileImages} />
              ))}
            </div>
          )}

          <hr></hr>

          <div className="notification-comment">
            {this.props.isLogin && (
              <NotificationCommentDesktopView
                userInfo={this.props.userInfo}
                notification_id={Number(this.props.match.params.idNotification)}
                notificationComments={this.props.notificationComments}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function RenderImage(props) {
  const data = props.data;
  return (
    <div className="col-12 col-md-6">
      <img
        src={data.fileUrl}
        alt={data.fileName}
        className="w-100 image-notification-attack-file"
        onError={SetDefaultErrorImage}
      />
    </div>
  );
}

function RenderAttackFile(props) {
  const data = props.data;
  return (
    <a href={data.fileUrl} target="_blank" className="col-4">
      <img
        src={require("assets/images/pdf-icon.png")}
        alt="PDF DOCUMENT"
        className="img-fluid"
      />
      <p className="text-truncate text-center text-dark">{data.fileName}</p>
    </a>
  );
}

const mapStateToProps = (state) => ({
  notificationDetailData: state.notifications.notificationDetail,
  notificationComments: state.notifications.notificationComments,
  isLogin: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetNotificationDetail: NotificationsStore.GetNotificationDetail,
      GetNotificationComments: NotificationsStore.GetNotificationComments,
      PromiseAllTools,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDetailMobileView);
