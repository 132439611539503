/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as hotActions from "redux/stores/hot/hot.store";
import { Link } from "react-router-dom";
import { SetDefaultErrorImage } from "utils/configuration";
import "../hot-mobile/hot-mobile.scss";

class HotMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHotList(1, 10, { orderBy: "update_date", orderType: "DESC" });
  }

  onGetHotList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getHotList(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetHotList(e, 10, { orderBy: "update_date", orderType: "DESC" });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  render() {
    const { currentPageIndex } = this.state;
    const { hotList, totalItems } = this.props;
    return (
      <div className="hot-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">HOT!</div>
              </div>
            </div>
          </div>
        </header>
        <div className="container">
          {hotList && hotList.length > 0 ? (
            <div className="row mt-3">
              {hotList.map((item) => (
                <RenderHotItem key={item.hotId} data={item} />
              ))}
            </div>
          ) : (
            <div className="row mt-3">
              <p className="col-12">Không có dữ liệu.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function RenderHotItem(props) {
  const data = props.data;
  return (
    <div className="col-12">
      <div className="item">
        <Link to={`/m/hot/${data.hotId}`}>
          <img
            src={data.imageUrl}
            alt={data.title}
            onError={SetDefaultErrorImage}
            className="img-fluid w-100 img-hot"
          />
        </Link>
        <div className="item-content">
          <h5>
            <Link to={`/m/hot/${data.hotId}`}>{data.title}</Link>
          </h5>
          <span>
            <img
              src={require("assets/images/clock-15px.svg")}
              alt="Clock"
              className="img-fluid mr-1"
            />{" "}
            Từ {data.fromDate} đến {data.toDate}
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  hotList: state.hot.hotList,
  totalItems: state.hot.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHotList: hotActions.GetHotList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HotMobileView);
