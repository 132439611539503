/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as friendActions from 'redux/stores/friend/friend.store';
import { SetDefaultErrorImage } from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";
import AddFriendActionView from '../add-friend/add-friend-action.jsx';
import './club.scss';
import "../neighbourhoods-desktop.scss";

class ClubDesktopView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            careerId: '',
            homeland_province_id: '',
            hobbyId: '',
            customerName: '',
            isLoading: false,
            currentPageIndex: 1,
            currentUser: JwtHelper.currentUser()
        };
    }

    UNSAFE_componentWillMount() {
        if (this.state.currentUser && this.state.currentUser.customerId) {
            this.onGetSuggestionFriends();
        }
    }

    onGetSuggestionFriends = (pageIndex = this.state.currentPageIndex, size = 8, customerId = this.state.currentUser && this.state.currentUser.customerId, filterObjs = { customer_name: this.state.customerName, career_id: this.state.careerId, homeland_province_id: this.state.homeland_province_id, hobby_id: this.state.hobbyId }) => {
        this.setState({ isLoading: true });
        this.props.getSuggestionFriends(pageIndex, size, customerId, { ...filterObjs }).then(
            res => {
                this.setState({ isLoading: false });
            },
            err => {
                this.setState({ isLoading: false });
            }
        );
    };

    render() {
        const { suggestionFriends } = this.props;
        return (
            <div className="club-list-desktop">
                <h2 className="mb-4">Câu lạc bộ</h2>

                <div className="row">
                    <div className="col-3 text-center mb-4">
                        <Link to="/lang-gieng-gan/cau-lac-bo/golf">
                            <img src={require('assets/images/golf.svg')} alt="Golf" />
                        </Link>
                        <h5>
                            <Link to="/lang-gieng-gan/cau-lac-bo/golf">Golf</Link>
                        </h5>
                    </div>
                    <div className="col-3 text-center mb-4">
                        <Link to="/lang-gieng-gan/cau-lac-bo/tennis">
                            <img src={require('assets/images/clb-tennis.svg')} alt="Tennis" />
                        </Link>
                        <h5>
                            <Link to="/lang-gieng-gan/cau-lac-bo/tennis">Tennis</Link>
                        </h5>
                    </div>
                    <div className="col-3 text-center mb-4">
                        <Link to="/lang-gieng-gan/cau-lac-bo/cau-long">
                            <img src={require('assets/images/badminton.svg')} alt="Cầu lông" />
                        </Link>
                        <h5>
                            <Link to="/lang-gieng-gan/cau-lac-bo/cau-long">Cầu lông</Link>
                        </h5>
                    </div>
                    <div className="col-3 text-center mb-4">
                        <Link to="/lang-gieng-gan/cau-lac-bo/bong-ban">
                            <img src={require('assets/images/ping-pong.svg')} alt="Bóng bàn" />
                        </Link>
                        <h5>
                            <Link to="/lang-gieng-gan/cau-lac-bo/bong-ban">Bóng bàn</Link>
                        </h5>
                    </div>
                    {/* <div className="col-3 text-center mb-4">
                        <Link to="/lang-gieng-gan/ket-ban">
                            <img src={require('assets/images/add-friend.svg')} alt="Kết bạn" />
                        </Link>
                        <h5>
                            <Link to="/lang-gieng-gan/ket-ban">Kết bạn</Link>
                        </h5>
                    </div> */}
                </div>

                <div className="neighbourhoods-desktop add-friend-desktop mt-4">
                    <div className="row">
                        <div className="col-6">
                            <h4 className="title-sm">Kết bạn</h4>
                        </div>
                        <div className="col-6 text-right">
                            <Link to="/lang-gieng-gan/ket-ban">Xem thêm</Link>
                        </div>
                    </div>
                    <div className="row ml-n2 mr-n2 suggestion-friend">
                        {suggestionFriends.map(item => <div key={item.customerId} className="col-3 pl-2 pr-2">
                            <div className="item">
                                <Link to={`/lang-gieng-gan/ket-ban/${item.customerId}`}>
                                    <img src={item.avatarUrl || require('assets/images/29-01.png')} onError={SetDefaultErrorImage} className="avatar rounded-circle" />
                                </Link>
                                <br />
                                <h5 className="text-truncate">
                                    <Link to={`/lang-gieng-gan/ket-ban/${item.customerId}`}>{item.customerName}</Link>
                                </h5>
                                {item && <AddFriendActionView friendId={item.customerId} status={item.status} callBack={this.onGetSuggestionFriends.bind(this)} />}
                            </div>
                        </div>
                        )}
                        {suggestionFriends.length <= 0 && <p className="col-12">Không tìm thấy dữ liệu.</p>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    suggestionFriends: state.friend.suggestionFriends
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getSuggestionFriends: friendActions.GetFriendSuggestionList,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClubDesktopView);