/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import moment from "moment";

import * as lendingEquipmentActions from "redux/stores/lending-equipment/lending-equipment.store";
import * as appActions from "core/app.store";
import {
  NotificationMessageType,
  ConvertNumberToString,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { history } from "redux/store";

import "react-datepicker/dist/react-datepicker.css";
import "../lending-equipment-mobile.scss";

const today = new Date();

function getMinutes(date) {
  if (date.getMinutes() > 45) return 0;
  else if (date.getMinutes() > 30) return 45;
  else if (date.getMinutes() > 15) return 30;
  else return 15;
}

const CustomInputDatePicker = ({ value, onClick }) => (
  <button
    type="button"
    className="form-control rounded-pill text-left"
    onClick={onClick}
  >
    {value}
  </button>
);

class RegisterEquipmentMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoursFrom: new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        today.getMinutes() > 45 ? today.getHours() + 1 : today.getHours(),
        getMinutes(today)
      ),
      hoursTo: new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        today.getMinutes() > 45 ? today.getHours() + 2 : today.getHours() + 1,
        getMinutes(today)
      ),
      dateForHour: new Date(),
      isLendingHour: true,
      isLendingDay: false,
      dateFromForDay: new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate()
      ),
      dateToForDay: new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate() + 1
      ),

      quantity: 1,
      product: null,
      productId: this.props.match.params.equipmentId,
      privacyPolicy: null,

      rental: 0,
      deposit: 0,
      refunds: 0,
      totalPrice: 0,

      errorHours: "",
      errorDay: "",
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.equipmentId) {
      this.onGetProductDetail(this.props.match.params.equipmentId);
    }
  }

  onGetProductDetail = (productId) => {
    this.props.showLoading(true);
    this.props.onGetProductDetail(productId).then(
      (res) => {
        let quantity = new URLSearchParams(this.props.location.search).get(
          "quantity"
        );
        this.setState({
          product: res.content,
          quantity: parseInt(quantity) || 1,
        });
        this.onCalculatorPriceByHours(
          this.state.hoursFrom,
          this.state.hoursTo,
          this.state.quantity,
          res.content.hourPrice,
          res.content.deposit
        );
        this.props.showLoading(false);
      },
      (err) => {
        this.props.showLoading(false);
      }
    );
  };

  onCalculatorPriceByHours = (hoursFrom, hoursTo, quantity, price, deposit) => {
    this.setState({
      hoursFrom: hoursFrom,
      hoursTo: hoursTo,
      quantity: quantity,
      rental:
        Math.round((hoursTo.getTime() - hoursFrom.getTime()) / 60000) *
        (price / 60) *
        quantity,
      deposit: deposit * quantity,
      refunds:
        deposit * quantity -
        Math.round((hoursTo.getTime() - hoursFrom.getTime()) / 60000) *
          (price / 60) *
          quantity,
      totalPrice:
        Math.round((hoursTo.getTime() - hoursFrom.getTime()) / 60000) *
          (price / 60) *
          (quantity || 0) +
        (deposit || 0) * (quantity || 0),
    });
    this.onValidateTime(
      hoursFrom,
      hoursTo,
      this.state.dateFromForDay,
      this.state.dateToForDay
    );
  };

  onCalculatorPriceByDay = (
    dateFromForDay,
    dateToForDay,
    quantity,
    price,
    deposit
  ) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const days = Math.round(Math.abs((dateToForDay - dateFromForDay) / oneDay));

    this.setState({
      dateFromForDay: dateFromForDay,
      dateToForDay: dateToForDay,
      quantity: quantity,
      rental: days * price * quantity,
      deposit: deposit * quantity,
      refunds: deposit * quantity - days * price * quantity,
      totalPrice: days * price * quantity + deposit * quantity,
    });
    this.onValidateTime(
      this.state.hoursFrom,
      this.state.hoursTo,
      dateFromForDay,
      dateToForDay
    );
  };

  onValidateTime = (
    hoursFrom,
    hoursTo,
    dateFromForDay,
    dateToForDay,
    dateForHour = this.state.dateForHour
  ) => {
    let errorHours = "";
    let errorDay = "";
    if (this.state.isLendingHour) {
      if (hoursFrom.getTime() >= hoursTo.getTime()) {
        errorHours =
          "Thời gian kết thúc không thể nhỏ hơn hoặc bằng thời gian bắt đầu.";
      } else if (
        dateForHour.getDate() == today.getDate() &&
        dateForHour.getUTCMonth() == today.getUTCMonth() &&
        dateForHour.getUTCFullYear() == today.getUTCFullYear() &&
        hoursFrom.getTime() < today.getTime()
      ) {
        errorHours = "Thời gian thuê không thể nhỏ hơn thời gian hiện tại.";
      }
    } else if (this.state.isLendingDay) {
      const oneDay = 24 * 60 * 60 * 1000;
      const days = Math.round((dateToForDay - dateFromForDay) / oneDay);
      if (days <= 0) {
        errorDay = "Ngày kết thúc không thể nhỏ hơn hoặc bằng ngày bắt đầu.";
      }
    }

    this.setState({
      errorHours: errorHours,
      errorDay: errorDay,
    });
  };

  handleSubmit = () => {
    if (
      (this.state.isLendingHour && this.state.errorHours) ||
      this.state.quantity > this.state.product.quantityAvailable ||
      (this.state.isLendingDay && this.state.errorDay)
    )
      return;

    let oneDay = 24 * 60 * 60 * 1000;
    let diffDays = Math.round(
      Math.abs((this.state.dateToForDay - this.state.dateFromForDay) / oneDay)
    );

    this.props.showLoading(true);
    this.props
      .onAddOrUpdateProduct({
        product_id: this.state.product.productId,
        apartment_id: this.props.userInfo.apartmentId,
        rental_name: this.state.product.productName,
        rental_type_id: this.state.isLendingHour ? 1 : 2,
        quantity: this.state.quantity,
        rental_date: this.state.isLendingHour
          ? moment(
              new Date(
                this.state.dateForHour.getUTCFullYear(),
                this.state.dateForHour.getUTCMonth(),
                this.state.dateForHour.getDate(),
                this.state.hoursFrom.getHours(),
                this.state.hoursFrom.getMinutes(),
                this.state.hoursFrom.getSeconds(),
                this.state.hoursFrom.getMilliseconds()
              )
            ).format("YYYY-MM-DD HH:mm:ss")
          : moment(this.state.dateFromForDay).format("YYYY-MM-DD HH:mm:ss"),
        return_plan_date: this.state.isLendingHour
          ? moment(
              new Date(
                this.state.dateForHour.getUTCFullYear(),
                this.state.dateForHour.getUTCMonth(),
                this.state.dateForHour.getDate(),
                this.state.hoursTo.getHours(),
                this.state.hoursTo.getMinutes(),
                this.state.hoursTo.getSeconds(),
                this.state.hoursTo.getMilliseconds()
              )
            ).format("YYYY-MM-DD HH:mm:ss")
          : moment(this.state.dateToForDay).format("YYYY-MM-DD HH:mm:ss"),
        booking_day: diffDays,
        booking_hour:
          this.state.hoursTo.getHours() - this.state.hoursFrom.getHours(),
        total: this.state.totalPrice,
      })
      .then(
        (res) => {
          this.props.showLoading(false);
          AddNotification(
            "Đăng ký thuê đồ thành công. Vui lòng đợi admin phê duyệt",
            NotificationMessageType.Success
          );
          history.push("/m/cho-thue-muon-do");
        },
        (err) => {
          this.props.showLoading(false);
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              err.errorType && err.errorType == "RentalExists"
                ? NotificationMessageType.Warning
                : NotificationMessageType.Error
            );
        }
      );
  };

  render() {
    const { quantity, product, totalPrice } = this.state;
    const { isLoggedIn } = this.props;

    return (
      <div className="register-equipment-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/cho-thue-muon-do">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Đăng ký thuê đồ</div>
              </div>
            </div>
          </div>
        </header>
        {product && (
          <div className="p-2">
            <h6>{product.productName}</h6>
            <h6 className="lending-price font-weight-normal">
              {this.state.isLendingHour && (
                <div>
                  {ConvertNumberToString(product.hourPrice || 0)}đ
                  <span>/giờ</span>
                </div>
              )}
              {this.state.isLendingDay && (
                <div>
                  {ConvertNumberToString(product.dayPrice || 0)}đ
                  <span>/ngày</span>
                </div>
              )}
            </h6>
          </div>
        )}

        <div className="mobile-divide"></div>
        {product && (
          <div className="p-2">
            <h6 className="deposit">
              Đặt cọc:{" "}
              <span>{ConvertNumberToString(product.deposit || 0)}đ</span>/sp
            </h6>
            <div className="row no-gutters mt-2 align-items-center">
              <div className="col-3">
                <span className="quantity-title">Số lượng:</span>
              </div>
              <div className="col-9">
                <div className="quantity mt-1">
                  <button
                    className="minus"
                    onClick={() => {
                      {
                        this.state.isLendingHour
                          ? this.onCalculatorPriceByHours(
                              this.state.hoursFrom,
                              this.state.hoursTo,
                              quantity > 1 ? quantity - 1 : quantity,
                              product.hourPrice,
                              product.deposit || 0
                            )
                          : this.onCalculatorPriceByDay(
                              this.state.dateFromForDay,
                              this.state.dateToForDay,
                              quantity > 1 ? quantity - 1 : quantity,
                              product.dayPrice,
                              product.deposit || 0
                            );
                      }
                    }}
                  >
                    <img
                      src={require("/assets/images/minus-black.svg")}
                      alt="Minus"
                    />
                  </button>
                  <div className="quantity-number">
                    <input
                      type="text"
                      value={quantity}
                      onChange={(event) => {
                        this.state.isLendingHour
                          ? this.onCalculatorPriceByHours(
                              this.state.hoursFrom,
                              this.state.hoursTo,
                              event.target.value && event.target.value > 1
                                ? parseInt(
                                    event.target.value.replace(/[^\d]/, "")
                                  )
                                : 1,
                              product.hourPrice,
                              product.deposit || 0
                            )
                          : this.onCalculatorPriceByDay(
                              this.state.dateFromForDay,
                              this.state.dateToForDay,
                              event.target.value && event.target.value > 1
                                ? parseInt(
                                    event.target.value.replace(/[^\d]/, "")
                                  )
                                : 1,
                              product.dayPrice,
                              product.deposit || 0
                            );
                      }}
                      disabled={!isLoggedIn}
                    />
                  </div>
                  <button
                    className="plus"
                    onClick={() => {
                      this.state.isLendingHour
                        ? this.onCalculatorPriceByHours(
                            this.state.hoursFrom,
                            this.state.hoursTo,
                            quantity + 1,
                            product.hourPrice,
                            product.deposit || 0
                          )
                        : this.onCalculatorPriceByDay(
                            this.state.dateFromForDay,
                            this.state.dateToForDay,
                            quantity + 1,
                            product.dayPrice,
                            product.deposit || 0
                          );
                    }}
                  >
                    <img
                      src={require("/assets/images/plus-black.svg")}
                      alt="Plus"
                    />
                  </button>
                </div>
              </div>
              {quantity > product.quantityAvailable && (
                <div className="col-12 text-danger pt-2">
                  Trong kho chỉ còn {product.quantityAvailable} sản phẩm
                </div>
              )}
            </div>
            <h6 className="mt-3">Chọn thời gian thuê:</h6>
            <hr />

            <form className="mt-3 form-register-equipment-mobile">
              <div key={`custom-radio`} className="mb-3">
                <Form.Check
                  custom
                  inline
                  label="Thuê theo giờ"
                  type="radio"
                  id={`custom-equipment-1`}
                  name="cbxLendingEquipment"
                  onClick={() => {
                    this.setState({ isLendingHour: true, isLendingDay: false });
                    this.onCalculatorPriceByHours(
                      this.state.hoursFrom,
                      this.state.hoursTo,
                      quantity,
                      product.hourPrice,
                      product.deposit || 0
                    );
                  }}
                  defaultChecked={this.state.isLendingHour}
                />
                <Form.Check
                  custom
                  inline
                  label="Thuê theo ngày"
                  type="radio"
                  id={`custom-equipment-2`}
                  name="cbxLendingEquipment"
                  onClick={() => {
                    this.setState({ isLendingHour: false, isLendingDay: true });
                    this.onCalculatorPriceByDay(
                      this.state.dateFromForDay,
                      this.state.dateToForDay,
                      quantity,
                      product.dayPrice,
                      product.deposit || 0
                    );
                  }}
                />
              </div>

              {this.state.isLendingHour && (
                <div className="row no-gutters align-items-center ml-n1 mr-n1">
                  <div className="col-3 position-relative pl-1 pr-1">
                    <Form.Group>
                      <DatePicker
                        selected={this.state.hoursFrom}
                        onChange={(date) =>
                          this.onCalculatorPriceByHours(
                            date,
                            this.state.hoursTo,
                            quantity > 1 ? quantity - 1 : quantity,
                            product.hourPrice,
                            product.deposit || 0
                          )
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        className="form-control rounded-pill"
                        customInput={<CustomInputDatePicker />}
                      />
                      <img
                        src={require("/assets/images/clock-15px.svg")}
                        alt="Clock"
                        className="img-clock"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-3 position-relative pl-1 pr-1">
                    <Form.Group>
                      <DatePicker
                        selected={this.state.hoursTo}
                        onChange={(date) =>
                          this.onCalculatorPriceByHours(
                            this.state.hoursFrom,
                            date,
                            quantity,
                            product.hourPrice,
                            product.deposit || 0
                          )
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        className="form-control rounded-pill"
                        customInput={<CustomInputDatePicker />}
                      />
                      <img
                        src={require("/assets/images/clock-15px.svg")}
                        alt="Clock"
                        className="img-clock"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6 position-relative pl-1 pr-1">
                    <Form.Group>
                      <DatePicker
                        selected={this.state.dateForHour}
                        onChange={(date) => {
                          this.setState({ dateForHour: date });
                          this.onValidateTime(
                            this.state.hoursFrom,
                            this.state.hoursTo,
                            this.state.dateFromForDay,
                            this.state.dateToForDay,
                            date
                          );
                        }}
                        popperPlacement="bottom-end"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        className="form-control rounded-pill"
                        customInput={<CustomInputDatePicker />}
                      />
                      <img
                        src={require("/assets/images/calendar.svg")}
                        alt="Calendar"
                        className="img-calendar"
                      />
                    </Form.Group>
                  </div>
                  {this.state.errorHours && (
                    <div className="col-12 pl-2 pr-2 text-danger pb-2">
                      {this.state.errorHours}
                    </div>
                  )}
                </div>
              )}

              {this.state.isLendingDay && (
                <div className="row no-gutters align-items-center ml-n1 mr-n1">
                  <div className="col-6 position-relative pl-1 pr-1">
                    <Form.Group>
                      <DatePicker
                        selected={this.state.dateFromForDay}
                        onChange={(date) =>
                          this.onCalculatorPriceByDay(
                            date,
                            this.state.dateToForDay,
                            quantity,
                            product.dayPrice,
                            product.deposit || 0
                          )
                        }
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        className="form-control rounded-pill"
                        customInput={<CustomInputDatePicker />}
                      />
                      <img
                        src={require("/assets/images/calendar.svg")}
                        alt="Calendar"
                        className="img-calendar"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6 position-relative pl-1 pr-1">
                    <Form.Group>
                      <DatePicker
                        selected={this.state.dateToForDay}
                        onChange={(date) =>
                          this.onCalculatorPriceByDay(
                            this.state.dateFromForDay,
                            date,
                            quantity,
                            product.dayPrice,
                            product.deposit || 0
                          )
                        }
                        popperPlacement="bottom-end"
                        dateFormat="dd/MM/yyyy"
                        minDate={
                          new Date(
                            today.getUTCFullYear(),
                            today.getUTCMonth(),
                            today.getDate() + 1
                          )
                        }
                        className="form-control rounded-pill"
                        customInput={<CustomInputDatePicker />}
                      />
                      <img
                        src={require("/assets/images/calendar.svg")}
                        alt="Calendar"
                        className="img-calendar"
                      />
                    </Form.Group>
                  </div>
                  {this.state.errorDay && (
                    <div className="col-12 pl-2 pr-2 text-danger pb-2">
                      {this.state.errorDay}
                    </div>
                  )}
                </div>
              )}

              <div className="calculate-money text-right mt-3">
                <h6>
                  Tiền thuê:{" "}
                  <span>
                    {ConvertNumberToString(
                      this.state.rental > 0 ? this.state.rental : 0
                    )}{" "}
                    VNĐ
                  </span>
                </h6>
                <h6>
                  Tiền cọc:{" "}
                  <span>
                    {ConvertNumberToString(
                      this.state.deposit > 0 ? this.state.deposit : 0
                    )}{" "}
                    VNĐ
                  </span>
                </h6>
              </div>

              <hr />

              <div className="re-money text-right">
                <h6>
                  Nhận lại khi trả đồ:{" "}
                  <span>
                    {ConvertNumberToString(
                      this.state.refunds > 0 ? this.state.refunds : 0
                    )}{" "}
                    VNĐ
                  </span>
                </h6>

                {this.state.refunds >= 0 ? (
                  <h6 className="font-italic">
                    (Quý khách nhận lại{" "}
                    {ConvertNumberToString(
                      this.state.refunds > 0 ? this.state.refunds : 0
                    )}{" "}
                    khi trả đồ)
                  </h6>
                ) : (
                  <h6 className="font-italic">
                    (Quý khách cần thanh toán thêm{" "}
                    {ConvertNumberToString(Math.abs(this.state.refunds))} khi
                    trả đồ)
                  </h6>
                )}
              </div>

              <button
                type="button"
                className="btn btn-success rounded-pill mt-2 pl-4 pr-4 w-100"
                onClick={this.handleSubmit}
              >
                Đăng ký
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProductDetail: lendingEquipmentActions.GetProductDetail,
      onAddOrUpdateProduct: lendingEquipmentActions.AddOrUpdateProduct,
      onGetContentByKeyword: lendingEquipmentActions.GetContentByKeyword,
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterEquipmentMobileView);
