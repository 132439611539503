import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from 'core/app.store';
import {
    createFormData,
    AppKey
} from 'utils/configuration';
import Service from 'services/api.service';
import {
    ApiUrl
} from 'services/api-url';

const service = new Service();
/** Action Types */

/** Actions */

export const GetUserInformation = (userId) => {
    return (dispatch) => {
        let payLoad = createFormData({
            customerId: userId
        });
        return service.post(ApiUrl.UserGetProfile, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    };
}

export const UpdateUserInformation = (data, formData = new FormData()) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix, formData);
        return service.post(ApiUrl.UpdateUserProfile, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

export const ChangePassword = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data);
        return service.post(ApiUrl.ChangePassword, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

// Initial state
export const initialState = Map({
    isLoading: false
}).toJS();

/** Reducers */
export default function UserInformationReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}