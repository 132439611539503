/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../payment-mobile.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PromiseAllTools } from 'core/app.store';
import { convertTextareaToHtml, NotificationMessageType, TokenKey, getCookies, GetProjectInfo } from "utils/configuration";
import * as ElectricBillStore from 'redux/stores/payment/electric/electric.store';
import * as CreateTransactionStores from 'redux/stores/create-transaction/create-transaction.store';
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";

function PaymentElectricGuideMobileView(props) {
    useEffect(() => {
        props.PromiseAllTools([
            props.GetElectricBillThisMonth(props.userInfo.apartmentId),
            props.GetPaymentGuide()
        ])
    }, [])

    const PaymentGuideDocument = { __html: convertTextareaToHtml(props.guide) || "Không có dữ liệu để hiển thị" };
    const currentDomain = window.location.origin;
    const projectName = getCookies(TokenKey.ProjectName) ? getCookies(TokenKey.ProjectName) : "";
    const projectInfo = GetProjectInfo();
    const isPayment = projectInfo && projectInfo.hasVnpay && projectInfo.hasVnpay == "1" || false;

    function onCreateTransactionPayment(data) {
        props.CreateTransactionVNPay({
            return_url: currentDomain + "/m/thanh-toan/ket-qua",
            transaction_name: "Thanh toán tiền phí điện tháng " + data.monthly + " " + data.yearly + " căn hộ " + data.houseNumber + " khu " + projectName,
            service_type_code: "electric",
            service_id: data.id
        }).then(
            (res) => {
                window.location.replace(res.content && res.content.redirect_url);
            },
            (err) => {
                err && err.errorType && AddNotification(viVN.Errors[err && err.errorType], NotificationMessageType.Error);
            }
        );
    }

    return (
        <div className="payment-mobile">
            <header className="header-mobile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/m/thanh-toan/tien-dien">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                            <div className="header-title text-center">Thanh toán hóa đơn</div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="payment-guide-detail p-3" dangerouslySetInnerHTML={PaymentGuideDocument}></div>
            {
                props.monthlyBill && props.monthlyBill.datePay == "" && props.monthlyBill.total > "0" && (
                    <div className="pl-3 pb-3">
                        <button className="btn btn-success text-uppercase rounded-pill pl-4 pr-4" disabled={!isPayment} onClick={() => onCreateTransactionPayment(props.monthlyBill)}>
                            Thanh toán phí
                        </button>
                    </div>
                )
            }
        </div>
    );
}

const mapStateToProps = state => ({
    monthlyBill: state.electricBill.billThisMonth,
    guide: state.electricBill.paymentGuide,
})

const mapDisPatchToProps = dispatch => bindActionCreators({
    GetElectricBillThisMonth: ElectricBillStore.GetElectricBillThisMonth,
    GetPaymentGuide: ElectricBillStore.GetPaymentGuide,
    CreateTransactionVNPay: CreateTransactionStores.CreateTransactionVNPay,
    PromiseAllTools
}, dispatch)

export default connect(mapStateToProps, mapDisPatchToProps)(PaymentElectricGuideMobileView);