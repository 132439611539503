/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Error } from 'components/support-create-form/validate-style';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import * as hourlyMaidActions from 'redux/stores/hourly-maid/hourly-maid.store';
import { convertTextareaToHtml, convertHtmlToTextarea } from 'utils/configuration';
import { JwtHelper } from "utils/jwt-helper";

function UpdateHourlyMaidForm(props) {
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange"
  });

  const intialValues = {
    content: convertHtmlToTextarea(props.model.content),
    mobile_number: props.model.mobileNumber,
  };

  const onSubmit = data => {
    data.content = convertTextareaToHtml(data.content);
    props.onUpdateHourlyMaid && props.onUpdateHourlyMaid(data);
  };

  return (
    <div className="advertising-desktop">
      <form className="form-add-resident-market" onSubmit={handleSubmit(onSubmit)} >
        <div className="form-group">
          <label htmlFor="mobile_number">Số điện thoại</label>
          <input defaultValue={intialValues.mobile_number} type="text" id="mobile_number" name="mobile_number" className="form-control" placeholder="Số điện thoại"
            ref={register({
              required: 'Trường bắt buộc',
              pattern: { value: /(\(?\+[0-9]{2}\)|0)?([0-9]{9,10})/i, message: "Số điện thoại không hợp lệ" }
            })} />
          {errors.mobile_number && <Error content={errors.mobile_number.message} />}
        </div>
        <div className="form-group">
          <label htmlFor="content">Đánh giá</label>
          <textarea defaultValue={intialValues.content} id="content" name="content" className="form-control" rows="5" placeholder="Nội dung"
            ref={register({
              required: 'Trường bắt buộc',
              minLength: {
                value: 6,
                message: "Nội dung phải có ít nhất 6 ký tự"
              }
            })}></textarea>
          {errors.content && <Error content={errors.content.message} />}
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-6">
          </div>
          <div className="col-6 text-right">
            <button type="button" className="btn btn-secondary mr-3" onClick={props.onClosePopup}>Huỷ</button>
            <button type="submit" className="btn btn-success">Lưu chỉnh sửa</button>
          </div>
        </div>
      </form>
    </div>
  );
}

class EditHourlyMaidDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      hourlyMaidPost: null,
    }
  }

  UNSAFE_componentWillMount() {
    this.onGetHourlyMaidPostDetail();
  }

  onGetHourlyMaidPostDetail = () => {
    if (this.props.assistId) {
      this.props.getHourlyMaidDetail(this.props.assistId).then(res => {
        this.setState({
          hourlyMaidPost: res.content
        })
      }, err => {
      })
    }
  }

  onUpdateHourlyMaid = (data) => {
    let submitedData = {
      ...data,
      assist_id: this.props.assistId,
      customer_id: this.state.currentUser.customerId,
    }
    this.props.createOrUpdateHourlyMaid(submitedData).then(res => {
      AddNotification('Cập nhật bài viết thành công!', NotificationMessageType.Success);
      this.onClosePopup();
      this.props.callBack && this.props.callBack();
    }, err => {
      this.onClosePopup();
    })
  }

  onClosePopup = () => {
    this.props.onToggleEditPopup(false);
  }

  render() {
    const { hourlyMaidPost } = this.state;

    return (
      <div className="resident-editting">
        {hourlyMaidPost && <UpdateHourlyMaidForm onUpdateHourlyMaid={this.onUpdateHourlyMaid.bind(this)} model={hourlyMaidPost} onClosePopup={this.onClosePopup.bind(this)} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createOrUpdateHourlyMaid: hourlyMaidActions.CreateOrUpdateHourlyMaid,
  getHourlyMaidDetail: hourlyMaidActions.GetHourlyMaidDetail,
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(EditHourlyMaidDesktopView)