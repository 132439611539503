import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as procedureActions from 'redux/stores/management/procedure.store';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import './procedure-category-mobile.scss';

class ProcedureCategoryMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            currentPageIndex: 1
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetProcedureCategories(this.state.currentPageIndex, 10, {});
    }

    onGetProcedureCategories = (pageIndex = 1, size = 10, filterObjs = null) => {
        this.setState({ isLoading: true });
        this.props.getProcedureCategories(pageIndex, size, filterObjs).then(
            res => {
                this.setState({ isLoading: false });
            },
            err => { 
                this.setState({ isLoading: false });
            }
        );
    };
    render() {
        const { procedureCategories } = this.props;

        return (
            <div className="procedure-category-mobile p-3">
                <Table size="sm" className="tblProcedureCategory">
                    <thead>
                        <tr>
                            <th colSpan="2">Văn bản, biểu mẫu quản lý</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            procedureCategories.map(item => <tr key={item.categoryId}>
                                <td className="pl-2">
                                    <Link to={`/m/thu-tuc-bql/${item.categoryId}`}>
                                        {item.categoryName}
                                    </Link>
                                </td>
                                <td className="text-right pr-2">
                                    <Link to={`/m/thu-tuc-bql/${item.categoryId}`}>
                                        <img src={require("assets/images/keyboard-arrow-right.svg")} alt="Arrow right" />
                                    </Link>
                                </td>
                            </tr>)
                        }
                        {!procedureCategories.length && <div className="col-12">Không có bản ghi</div>}
                    </tbody>
                </Table>
            </div>
        )
    }
    // return (
    //     <div className="procedure-category-mobile p-3">
    //         <Table>
    // <thead>
    //     <tr>
    //         <th colSpan="2">Văn bản, biểu mẫu quản lý</th>
    //     </tr>
    // </thead>
    //             <tbody>
    //                 <tr>
    //                     <td>
    //                         <Link to={`/m/thu-tuc-bql/${ProceduresRenovationAndRepair}`}>Thủ tục đăng ký cải tạo, sửa chữa</Link>
    //                     </td>
    //                     <td className="text-right">
    //                         <Link to={`/m/thu-tuc-bql/${ProceduresRenovationAndRepair}`}>
    //                             <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
    //                         </Link>
    //                     </td>
    //                 </tr>
    //                 <tr>
    //                     <td>
    //                         <Link to={`/m/thu-tuc-bql/${CardRegistrationRrocedure}`}>Thủ tục đăng ký thẻ</Link>
    //                     </td>
    //                     <td className="text-right">
    //                         <Link to={`/m/thu-tuc-bql/${CardRegistrationRrocedure}`}>
    //                             <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
    //                         </Link>
    //                     </td>
    //                 </tr>
    //                 <tr>
    //                     <td>
    //                         <Link to={`/m/thu-tuc-bql/${ElevatorRentalProcedures}`}>Thủ tục cho thuê thang máy chở hàng</Link>
    //                     </td>
    //                     <td className="text-right">
    //                         <Link to={`/m/thu-tuc-bql/${ElevatorRentalProcedures}`}>
    //                             <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
    //                         </Link>
    //                     </td>
    //                 </tr>
    //                 <tr>
    //                     <td>
    //                         <Link to={`/m/thu-tuc-bql/${AuthorizationProcedure}`}>Thủ tục ủy quyền</Link>
    //                     </td>
    //                     <td className="text-right">
    //                         <Link to={`/m/thu-tuc-bql/${AuthorizationProcedure}`}>
    //                             <img src={require("assets/images/keyboard-arrow-right.svg")} alt="keyboard-arrow-right" className="img-keyboard-arrow-right" />
    //                         </Link>
    //                     </td>
    //                 </tr>
    //             </tbody>
    //         </Table>
    //     </div>
    //   )
}

const mapStateToProps = state => ({
    procedureCategories: state.procedure.procedureCategories
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProcedureCategories: procedureActions.GetProcedureCategories,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureCategoryMobileView);