import { Map } from "immutable";
import { createSaveLoading } from "core/app.store";
import { createFormData } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();

/** Actions */
export const Forgot = (data) => {
  return (dispatch) => {
    dispatch(createSaveLoading(true));
    return service
      .post(
        ApiUrl.ForgotPassword,
        createFormData(data, "", new FormData(), false)
      )
      .then((res) => {
        dispatch(createSaveLoading(false));
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

// Initial state
export const initialState = Map({
  isLoading: false,
}).toJS();

/** Reducers */
export default function ForgotReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
