/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as shopActions from "redux/stores/shop/shop.store";

import RenderNotificationBell from "components/header/notification-bell.view.jsx";
import CalendarSchedulerTennisMobileView from "./calendar-scheduler-tennis-mobile.view.jsx";
import CalendarSchedulerBbqMobileView from "./calendar-scheduler-bbq-mobile.view.jsx";
import CalendarSchedulerBeautyMobileView from "./calendar-scheduler-beauty-mobile.view.jsx";
import CalendarSchedulerClinicMobileView from "./calendar-scheduler-clinic-mobile.view.jsx";
import CalendarSchedulerCommunityMobileView from "./calendar-scheduler-community-mobile.view.jsx";
import CalendarSchedulerFootballMobileView from "./calendar-scheduler-football-mobile.view.jsx";
import CalendarSchedulerGarageMobileView from "./calendar-scheduler-garage-mobile.view.jsx";
import CalendarSchedulerGymMobileView from "./calendar-scheduler-gym-mobile.view.jsx";
import CalendarSchedulerOfficeMobileView from "./calendar-scheduler-office-mobile.view.jsx";
import CalendarSchedulerPoolMobileView from "./calendar-scheduler-pool-mobile.view.jsx";

function RenderCalendarSchedulerView(props) {
  switch (props.type) {
    case shopActions.SHOP_TYPE.CAFE:
      return <CalendarSchedulerTennisMobileView {...props} />;
    case shopActions.SHOP_TYPE.NHA_HANG:
      return <CalendarSchedulerTennisMobileView {...props} />;
    case shopActions.SHOP_TYPE.SIEU_THI:
      return <CalendarSchedulerTennisMobileView {...props} />;
    case shopActions.SHOP_TYPE.TRUONG_HOC:
      return <CalendarSchedulerTennisMobileView {...props} />;
    case shopActions.SHOP_TYPE.TENNIS:
      return <CalendarSchedulerTennisMobileView {...props} />;
    case shopActions.SHOP_TYPE.POOL:
      return <CalendarSchedulerPoolMobileView {...props} />;
    case shopActions.SHOP_TYPE.FOOTBALL:
      return <CalendarSchedulerFootballMobileView {...props} />;
    case shopActions.SHOP_TYPE.BBQ:
      return <CalendarSchedulerBbqMobileView {...props} />;
    case shopActions.SHOP_TYPE.BEAUTY:
      return <CalendarSchedulerBeautyMobileView {...props} />;
    case shopActions.SHOP_TYPE.OFFICE:
      return <CalendarSchedulerOfficeMobileView {...props} />;
    case shopActions.SHOP_TYPE.GARAGE:
      return <CalendarSchedulerGarageMobileView {...props} />;
    case shopActions.SHOP_TYPE.COMMUNITY:
      return <CalendarSchedulerCommunityMobileView {...props} />;
    case shopActions.SHOP_TYPE.GYM:
      return <CalendarSchedulerGymMobileView {...props} />;
    case shopActions.SHOP_TYPE.CLINIC:
      return <CalendarSchedulerClinicMobileView {...props} />;
    default:
      return null;
  }
}

class UserShopCalendarSchedulerMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopId: this.props.match.params.shopId,
      shopType:
        (this.props.match.params.type &&
          this.props.match.params.type.toUpperCase()) ||
        "",
    };
  }

  render() {
    const { shopId, shopType } = this.state;

    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <Link to={`/m/shop/${shopType.toLowerCase()}/${shopId}`}>
                      <img
                        src={require("assets/images/arrow-back.svg")}
                        alt="Arrow Back"
                        className="img-fluid img-arrow-back"
                      />
                    </Link>
                  </div>
                  <div className="col-4 header-title text-center">
                    Setup lịch sân
                  </div>
                  <div className="col-4 text-right">
                    <RenderNotificationBell forMobile={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {shopType && shopId && (
          <RenderCalendarSchedulerView type={shopType} shopId={shopId} />
        )}
      </div>
    );
  }
}

export default UserShopCalendarSchedulerMobileView;
