/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "redux/stores/user/user-information.store";
import * as authActions from "core/auth.store";
import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { JwtHelper } from "utils/jwt-helper";

import "../../user-information/reset-password/reset-password-mobile.scss";

class ResetPasswordFormMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      isSuccess: false,
    };
  }

  UNSAFE_componentWillMount() {
    const userInfo = JwtHelper.currentUser();
    if (this.props.loggedIn && userInfo) {
      this.setState({ user: JwtHelper.currentUser() });
    } else {
      window.location.replace(window.location.origin + "/m");
    }
  }

  onShowSuccess = () => {
    this.setState({ isSuccess: true });
  };

  render() {
    const { onChangePassword, logout } = this.props;
    const { user, isSuccess } = this.state;
    return (
      <div className="user-reset-password">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/thong-tin-ca-nhan">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">Đặt lại mật khẩu</div>
              </div>
            </div>
          </div>
        </header>
        {!isSuccess && (
          <ResetPassword
            user={user}
            onChangePassword={onChangePassword}
            logout={logout}
            onShowSuccess={this.onShowSuccess.bind(this)}
          />
        )}

        {isSuccess && (
          <div className="change-password-success text-center mt-5 p-3">
            <img
              src={require("assets/images/success-filled.svg")}
              className="img-fluid mb-3"
              alt="Success"
            />
            <h5 className="pl-5 pr-5 ml-3 mr-3">
              Mật khẩu đã được đặt lại thành công!
            </h5>
            <div className="mt-5">
              <Link to="/m/dang-nhap">
                <button
                  type="button"
                  className="btn btn-success text-uppercase rounded-pill pl-4 pr-4 w-100"
                >
                  Đăng nhập ngay
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function ResetPassword(props) {
  const { user, onChangePassword } = props;

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearError,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  const onChangeInputPassword = (e) => {
    const { password, confirmPassword } = getValues();
    console.log(confirmPassword)(
      confirmPassword != "" && password == confirmPassword
    )
      ? clearError(["confirmPassword"])
      : setError("confirmPassword", "validate", "Mật khẩu không trùng khớp");
  };

  const onSubmit = (data) => {
    if (data) {
      onChangePassword({
        customerId: user.customerId,
        oldPassword: data.currentPassword,
        newPassword: data.password,
      }).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.ChangePassword,
              NotificationMessageType.Success
            );
            props.onShowSuccess();
            props.logout();
            document.getElementById("changePasswordForm").reset();
          } else {
            AddNotification(
              viVN.Errors[res && res.errorType],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="row p-3"
      id="changePasswordForm"
    >
      <div className="col-12">
        <div className="form-group">
          <label className="col-form-label">
            Mật khẩu hiện tại<span className="required"></span>
          </label>
          <input
            type="password"
            name="currentPassword"
            id="currentPassword"
            className={
              "form-control " + (errors.currentPassword ? "is-invalid" : "")
            }
            placeholder="Mật khẩu cũ"
            ref={register({ required: "Trường này là bắt buộc" })}
          />
          {errors.currentPassword && (
            <div className="invalid-feedback">
              {errors.currentPassword.message}
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="col-form-label">
            Mật khẩu mới<span className="required"></span>
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className={"form-control " + (errors.password ? "is-invalid" : "")}
            placeholder="Mật khẩu mới"
            onChange={() => onChangeInputPassword()}
            ref={register({
              required: "Trường này là bắt buộc",
              minLength: {
                value: 6,
                message: "Mật khẩu phải có ít nhất 6 ký tự",
              },
            })}
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password.message}</div>
          )}
        </div>
        <div className="form-group">
          <label className="col-form-label">
            Xác nhận mật khẩu<span className="required"></span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            className={
              "form-control " + (errors.confirmPassword ? "is-invalid" : "")
            }
            placeholder="Nhập lại mật khẩu"
            ref={register({
              validate: (value) =>
                value === password.current || "Mật khẩu không trùng khớp",
            })}
          />
          {errors.confirmPassword && (
            <div className="invalid-feedback">
              {errors.confirmPassword.message}
            </div>
          )}
        </div>
        <div className="row form-group">
          <div className="col-6 form-group">
            <Link to="/m/thong-tin-ca-nhan">
              <button
                type="reset"
                className="btn btn-secondary w-100 text-uppercase"
              >
                Hủy
              </button>
            </Link>
          </div>
          <div className="col-6 form-group">
            <button
              type="submit"
              className="btn btn-success w-100 text-uppercase"
            >
              Đặt lại mật khẩu
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onChangePassword: userActions.ChangePassword,
      logout: authActions.Logout,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordFormMobileView);
