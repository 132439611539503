/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as repairingHouseholdActions from "redux/stores/utilities-plus/repairing-household.store";

import "../utilities-plus-mobile.scss";

class RepairingHouseholdMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetRepairingHouseholdList(1, 10, {
      orderBy: "update_date",
      orderType: "DESC",
    });
  }

  onGetRepairingHouseholdList = (
    pageIndex = 1,
    size = 10,
    filterObjs = null
  ) => {
    this.props.getRepairingHouseholdList(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetRepairingHouseholdList(e, 10, {
          orderBy: "update_date",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  render() {
    const { currentPageIndex } = this.state;
    const { repairingHouseholdList, totalItems } = this.props;
    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/tien-ich-plus">
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">
                  Sửa chữa, lắp đặt đồ gia dụng
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="repairing-household-mobile p-3">
          {repairingHouseholdList && repairingHouseholdList.length > 0 ? (
            repairingHouseholdList.map((item) => (
              <RenderItem key={item.serviceId.toString()} item={item} />
            ))
          ) : (
            <div>Không có dữ liệu</div>
          )}
        </div>
      </div>
    );
  }
}

function RenderItem(props) {
  const { item } = props;
  return (
    <div className="item">
      <div className="row align-items-center no-gutters">
        <div className="col-2">
          <Link to={`/m/sua-chua-lap-dat/${item.serviceId}`}>
            <img
              src={item.avatar || require("assets/images/repairman-avatar.svg")}
              alt={item.providerName}
              className="img-fluid img-repairman-avatar"
            />
          </Link>
        </div>
        <div className="col-6 pl-3">
          <h4>
            <Link to={`/m/sua-chua-lap-dat/${item.serviceId}`}>
              {item.providerName}
            </Link>
          </h4>
          <span>{item.serviceName}</span>
          <h5>SĐT: {item.mobile_number}</h5>
        </div>
        <div className="col-4 text-right">
          <a href={"tel:" + item.mobile_number} className="btn btn-success">
            <img
              src={require("assets/images/phone-call.svg")}
              alt="phone-call"
              className="img-phone-call"
            />{" "}
            Liên hệ
          </a>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  repairingHouseholdList: state.repairingHousehold.repairingHouseholdList,
  totalItems: state.repairingHousehold.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRepairingHouseholdList:
        repairingHouseholdActions.GetRepairingHouseholdList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepairingHouseholdMobileView);
