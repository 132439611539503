/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../header/header-desktop.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "core/auth.store";
import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";
import {
  CutTextByLength,
  domainOrigin,
  isInitSubdomain,
  getCookies,
  TokenKey,
} from "utils/configuration";
import LoginDesktopView from "modules/login-desktop/login-desktop.view.jsx";
import RenderNotificationBell from "./notification-bell.view.jsx";

class HeaderDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: "",
      renderHeaderAgain: this.props.renderHeaderAgain || false,
      showModal: false,
    };

    this.onChangSearchKey = this.onChangSearchKey.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (isInitSubdomain) {
      this.setState({
        showModal: domainOrigin == window.location.host ? true : false,
      });
    } else {
      this.setState({
        showModal: getCookies(TokenKey.Project) ? false : true,
      });
    }
  }

  onLogoutAndReload() {
    this.props.logout();
    window.location.replace(window.location.origin);
  }

  onSearchDesktop = (e) => {
    if (e.key === "Enter") {
      history.push(`/ket-qua-tim-kiem/s=${this.state.searchKey}`);
      this.setState({
        searchKey: "",
      });
    }
  };

  onChangSearchKey(e) {
    this.setState({
      searchKey: e.target.value,
    });
  }

  render() {
    const loggedIn = this.props.loggedIn;
    const userInfo = JwtHelper.currentUser();
    return (
      <header className="header-desktop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-3 col-lg-3">
              <Link exact="true" to="/">
                <img src={require("assets/images/logo.png")} alt="logo-ilang" />
              </Link>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-center position-relative search-control">
              <input
                type="text"
                className="form-control"
                placeholder="Tìm kiếm"
                onChange={this.onChangSearchKey}
                value={this.state.searchKey}
                onKeyDown={this.onSearchDesktop}
              />
              <img
                src={require("assets/images/search-gray.svg")}
                alt="Tìm kiếm"
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 text-right">
              {!loggedIn ? (
                <ul className="list-inline">
                  <LoginDesktopView isRegistration={true} showModal={false} />
                  <li className="list-inline-item mr-0">
                    <span className="pl-2 pr-2">|</span>
                  </li>
                  <LoginDesktopView
                    isLogin={true}
                    showModal={this.state.showModal}
                  />
                  <li className="list-inline-item">
                    <Link exact="true" to="/thong-bao">
                      <img
                        src={require("assets/images/notifications.png")}
                        alt="logo-notifications"
                        className="ml-2"
                      />
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="user-dropdow">
                  <li className="list-inline-item">
                    {loggedIn && userInfo && (
                      <span className="username">
                        {CutTextByLength(userInfo.customerName, 16)}{" "}
                        <img
                          src={require("assets/images/user-green.svg")}
                          alt={userInfo.customerName}
                          className="ml-1 img-user-green"
                        />
                      </span>
                    )}
                    <ul className="child-menu">
                      <li className="text-right">
                        <Link exact="true" to="/thong-tin-ca-nhan">
                          Tài khoản của tôi
                        </Link>
                      </li>
                      <li className="text-right">
                        <a onClick={this.onLogoutAndReload.bind(this)}>
                          Đăng xuất
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="list-inline-item">
                    <RenderNotificationBell />
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: authActions.Logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDesktopView);
