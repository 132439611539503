import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as joinsHandActions from "redux/stores/join-hand/join-hand.store";
import { convertTextareaToHtml, NewGuid } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType, validateFile } from "utils/configuration";
import { Error, Success } from "components/support-create-form/validate-style";
import { JwtHelper } from "/utils/jwt-helper";
import { Config } from "@jest/types";
import HandleErrorMessaget from '../../../../utils/error-message';
function CreateJoinsHandForm(props) {
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  const [previewImg, setPreviewImg] = useState("");
  const [isShowUpload, setShowUpload] = useState(true);
  const [isToggleVote, setToggleVote] = useState(false);
  const [voteOptions, setVoteOptions] = useState([]);
  const [topicImage, setTopicImage] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  const onSubmit = async (data) => {
    if (!topicImage) {
      return;
    }
    // data.content = convertTextareaToHtml(data.content);
    data.content = data.content;
    data.options = [
      ...voteOptions.map((item) => {
        return { option_name: item.option_name };
      }),
    ];
    data.topic_image = topicImage;
    props.onCreateOrUpdateJoinsHand && props.onCreateOrUpdateJoinsHand(data);
    setValue("topic_name", null);
    setValue("location", null);
    setValue("responsible", null);
    setValue("content", null);
    setValue("poll_event_name", null);
    setValue("intend_time", null);
    setValue("topic_image", []);
    setVoteOptions([]);
    setPreviewImg("");
    setTopicImage(null);
    setShowUpload(true);
  };

  const onFileChange = (event) => {
    let file = event.target.files && event.target.files[0];

    if (file) {
      const validateMsg = validateFile(file);

      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage('');

      var reader = new FileReader();
      reader.onload = (e) => {
        setValue("attachmentUrl", e.target.result);
        setPreviewImg(e.target.result);
        setShowUpload(false);
      };
      reader.readAsDataURL(file);
      setTopicImage(file);
    }
  };

  const onAddMoreVoteOption = () => {
    setVoteOptions([
      ...voteOptions,
      {
        poll_id: NewGuid(),
        option_name: "",
        voted_people: [],
      },
    ]);
  };

  const onRemovedVoteOption = (voteOptionId) => {
    setVoteOptions([
      ...voteOptions.filter((vote) => vote.poll_id != voteOptionId),
    ]);
  };

  const onChangeVoteName = (voteOptionId, e) => {
    const optionIndex = voteOptions.findIndex(
      (vote) => vote.poll_id == voteOptionId
    );
    if (optionIndex >= 0 && !!e.target.value) {
      let newOptions = [...voteOptions];
      newOptions[optionIndex].option_name = e.target.value.trim();
      setVoteOptions([...newOptions]);
    }
  };

  const onDeleteImg = () => {
    setShowUpload(true);
    setPreviewImg(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-join-hand">
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <input
              type="text"
              name="topic_name"
              placeholder="Tên sự kiện..."
              className="form-control"
              disabled={!props.loggedIn}
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Tên sự kiện phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.topic_name && <Error content={errors.topic_name.message} />}
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <input
              type="text"
              name="location"
              placeholder="Địa điểm tổ chức"
              className="form-control"
              disabled={!props.loggedIn}
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Địa điểm tổ chức sự kiện phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.location && <Error content={errors.location.message} />}
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <input
              data-toggle="tooltip" data-placement="top" title="Tooltip on top"
              type="text"
              name="intend_time"
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setShowSuggestions(false)}
              placeholder="Thời gian diễn ra sự kiện"
              title="Ví dụ: 08/05/2020 - 10/05/2020"
              disabled={!props.loggedIn}
              className="form-control"
              ref={register({
                required: "Trường bắt buộc",
              })}
            />
            {errors.intend_time && (
              <Error content={errors.intend_time.message} />
            )}
          </div>
          {showSuggestions && <p>Ví dụ: 08/05/2020 - 10/05/2020</p>}
        </div>

        <div className="col-6">
          <div className="form-group">
            <input
              type="text"
              name="responsible"
              placeholder="Người chịu trách nhiệm"
              disabled={!props.loggedIn}
              className="form-control"
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Tên người chịu trách nhiệm phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.responsible && (
              <Error content={errors.responsible.message} />
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <textarea
              name="content"
              className="form-control"
              placeholder="Thông tin sự kiện..."
              disabled={!props.loggedIn}
              rows="5"
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Thông tin sự kiện phải có ít nhất 6 ký tự",
                },
              })}
            ></textarea>
            {errors.content && <Error content={errors.content.message} />}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group row no-gutters">
            {!!previewImg && (
              <div className="preview-img col-2">
                <img
                  src={previewImg}
                  alt="Mua bán nhà đất"
                  className="img-attachment"
                />
                <div className="overlay"></div>
                <img
                  onClick={(e) => onDeleteImg()}
                  src={require("assets/images/close-white.svg")}
                  alt="close-white"
                  className="img-close-white"
                />
              </div>
            )}

            <div>
              {isShowUpload && (
                <div className="file-upload">
                  <input
                    type="text"
                    className="hidden-text-field"
                    id="attachmentUrl"
                    name="attachmentUrl"
                    ref={register}
                  />
                  <div className="title-upload">
                    <img
                      src={require("assets/images/collections.png")}
                      alt="Image"
                      className="img-fluid mr-2 mb-1"
                    />{" "}
                    <span>Upload ảnh</span>
                  </div>
                  <input
                    type="file"
                    name="topic_image"
                    accept=".png, .jpg, .jpeg"
                    disabled={!props.loggedIn}
                    multiple
                    className="media-file"
                    onChange={onFileChange.bind(this)}
                    ref={register({
                      required: { value: true, message: "Trường bắt buộc" },
                    })}
                  />
                  {errors.topic_image && (
                    <Error content={errors.topic_image.message} />
                  )}
                  {validateImgMessage && <Error content={validateImgMessage} />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="render-vote">
            <div className="form-group position-relative">
              <div
                className={
                  isToggleVote
                    ? "create-vote form-control active"
                    : "create-vote form-control"
                }
                onClick={() => props.loggedIn && setToggleVote(!isToggleVote)}
              >
                <span>Tạo bình chọn</span>
                <img
                  src={require("assets/images/arrow-up.svg")}
                  className={`img-fluid ${isToggleVote ? "img-arrow-down" : "img-arrow-up"
                    }`}
                />
              </div>

              {isToggleVote && (
                <div className="form-create-vote rounded-bottom">
                  <hr></hr>
                  {/* <div className="form-group">
                  <input type="text" name="poll_event_name" className="form-control" placeholder="Tên chương trình" ref={register({ required: { value: true, message: 'Trường bắt buộc' } })} />
                  {errors.poll_event_name && <Error content={errors.poll_event_name.message} />}
                </div> */}

                  {voteOptions.map((vote, index) => (
                    <div key={vote.poll_id} className="form-group">
                      <input
                        type="text"
                        name="option_name"
                        placeholder={`Phương án ${index + 1}`}
                        className="form-control"
                        onChange={(e) => onChangeVoteName(vote.poll_id, e)}
                      />
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={() => onRemovedVoteOption(vote.poll_id)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ))}
                  <div className="text-right">
                    <a
                      onClick={() => onAddMoreVoteOption()}
                      className="add-vote"
                    >
                      Thêm phương án +
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-6 position-relative text-right">
          <button
            type="submit"
            className="btn btn-success pl-4 pr-5"
            disabled={!props.loggedIn}
          >
            Tạo sự kiện
          </button>
          <img src={require("assets/images/plus-white.svg")} className="img-fluid img-plus" alt="Plus" />
        </div>
      </div>
    </form>
  );
}

class AddJoinsHandDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
    };
  }

  UNSAFE_componentWillMount() { }

  onCreateOrUpdateJoinsHand = (data) => {
    if (!this.props.loggedIn) return;

    let submitedData = {
      ...data,
      customer_id: this.state.currentUser && this.state.currentUser.customerId,
      topic_id: null,
    };
    this.props.onToggleAddingState(true);
    this.props.createOrUpdateJoinsHand(submitedData).then(
      (res) => {
        AddNotification(
          "Tạo bài viết thành công!",
          NotificationMessageType.Success
        );
        this.props.onToggleAddingState(false);
        this.props.callBack && this.props.callBack();
      },
      (err) => {
        HandleErrorMessaget(err)
      }
    );
  };

  render() {
    const { loggedIn } = this.props;
    return (
      <CreateJoinsHandForm
        loggedIn={loggedIn}
        onCreateOrUpdateJoinsHand={this.onCreateOrUpdateJoinsHand.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateJoinsHand: joinsHandActions.CreateOrUpdateJoinsHand,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddJoinsHandDesktopView);
