import { NewGuid, randomDate } from 'utils/configuration';

export const AdHouses = [
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 1,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1,
        price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cần bán biệt thự',
        category_id: 2,
        category_name: 'Cần bán',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 1,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 3,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 3,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 4,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 1,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 2,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 3,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 4,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 1,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 2,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 3,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 4,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
    {
        ad_id: NewGuid(),
        ad_content: 'Cần bán căn hộ chung cư 128,3m2, tòa nhà CH104, sổ đỏ chính chủ. - Căn hộ gồm 3 phòng ngủ, 2 nhà tắm, phòng bếp, phòng khách, 2 ban công. - Căn hộ có đồ cơ bản (bình nóng lạnh, điều hòa, tủ quần áo, tủ bếp, bếp). - Khu chung cư nằm trong quần thể khu đô thị hiện đại, căn hộ thông minh, có bể bơi, trường học Quốc tế, khu vui chơi mua sắm đầy đủ. Khu chung cư có nhiều người nước ngoài thuê.',
        ad_name: 'Cho thuê biệt thự',
        category_id: 1,
        category_name: 'Cho thuê',
        create_date: randomDate(new Date(2012, 0, 1), new Date()),
        creator_id: 1,
        customer_id: 1,
        customer_phone: '0912312311',
        delete_date: randomDate(new Date(2012, 0, 1), new Date()),
        department_id: 1,
        acreage: 128,
        is_active: true,
        bedroomQuantity: 2,
        livingRoomQuantity: 1,
        kitchenQuantity: 1,
        toiletQuantity: 1, price: 25,
        attachmentUrl: require('/assets/images/can-ho-chung-cu.png'), attachmentFile: null
    },
];

export const AdHouseCategoryModels = [
    {
        id: 1,
        name: 'Nhà cần bán'
    },
    {
        id: 2,
        name: 'Nhà cho thuê'
    },
    {
        id: 3,
        name: 'Căn hộ cần bán'
    },
    {
        id: 4,
        name: 'Căn hộ cho thuê'
    },
];
