import { Map } from 'immutable';
import { createSaveLoading } from "core/app.store";
import AddNotification from 'components/react-notifications/react-notifications';
import Service from 'services/api.service';
import { createFormData, AppKey, ERRORTYPEEMPTYDATAERROR } from 'utils/configuration';
import { ApiUrl } from 'services/api-url';

const service = new Service();

const INITCALENDAR = "TENIST/INITCALENDAR";


const InitCalendar = data => ({
    type: INITCALENDAR, data: data
})

export const GetTennisCalendar = (date, pageIndex = 1, size = 100) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let result = [];
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        formData.append(`filter[date]`, date);
        let payLoad = createFormData(null, AppKey.GetPayloadPrefix, formData);
        await service.post(ApiUrl.TennisCalendar, payLoad).then(res => {
            result = ExchangeTennisCalendarData(res.content.items)
        }).catch(err => {
            console.log(err)
        })
        dispatch(InitCalendar(result))
    }
}

export const PostTennisRegisterUse = (registerData) => {
    return async (dispatch) => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(registerData, AppKey.PostPayloadPrefix);
        return await service.post(ApiUrl.TennisRegister, payLoad).then(res => {
            return true;
        }).catch(err => {
            if ((err.errorType != ERRORTYPEEMPTYDATAERROR)&&(err.errorType!="DuplicateDataError")) AddNotification("Có lỗi xảy ra trong quá trình đăng ký vui lòng thông báo cho BQL để nhận hỗ trợ", "error")
            console.log(err)
            return false;
        }).finally(() => {
            dispatch(createSaveLoading(false));
        })
    }
}

const initialState = Map({
    calendar: [],
}).toJS()

export default function TennisReducer(state = initialState, action) {
    switch (action.type) {
        case INITCALENDAR:
            return {
                ...state,
                calendar: action.data
            }
        default:
            return state
    }
}

function ExchangeTennisCalendarData(rawData) {
    const standartData = [];
    if (rawData != null) {
        rawData.map((data) => {
            let fakeData = {
                lat: data.latitude,
                lng: data.longitude,
                date: data.date,
                tennisId: data.tennisId,
                tennisName: data.tennisName,
                slots: []
            }
            data.slots.map((slot) => {
                fakeData.slots.push({
                    isFull: Boolean(slot.isReady),
                    slotName: slot.slotName,
                    slotId: slot.slotId,
                })
            })
            standartData.push(fakeData)
        })
    }
    return standartData
}