import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "../../modules/notification-desktop/notification-desktop.scss";
import { GetShopDetail } from '../../redux/stores/utillities/utilitis-shop.store';
import { getThisProjectLocation } from '../../modules/gmap/project-location';
class NotificationDetailDesktopView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            so: 4
        }
    }
    componentDidMount() {
        this.setState({
            so: 2
        })
    }
    render() {
        return (
            <div className="notification-desktop mt-4">
                {this.state.so}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    shopDetail: state.utilitiShop.shopDetail
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetailDesktopView);
