import {
    NewGuid
} from '../utils/configuration';

export const RepairingHouseholdFake = [{
        id: NewGuid(),
        avatar: "../../assets/images/repairman-avatar.svg",
        coverImage: '../../assets/images/splendora-cover.png',
        name: 'Trần Thanh Tuyền',
        job: 'Sửa điện, nước',
        address: 'Vân Lũng, An Khánh, Hoài Đức',
        tel: '0986123456',
        rate: 5
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/repairman-avatar.svg",
        coverImage: '../../assets/images/splendora-cover.png',
        name: 'Nguyễn Văn Anh',
        job: 'Sửa điều hòa',
        address: 'Vân Lũng, An Khánh, Hoài Đức',
        tel: '0986123457',
        rate: 5
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/repairman-avatar.svg",
        coverImage: '../../assets/images/splendora-cover.png',
        name: 'Hoàng Văn Tiện',
        job: 'Sửa máy giặt',
        address: 'Vân Lũng, An Khánh, Hoài Đức',
        tel: '0986123458',
        rate: 5
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/repairman-avatar.svg",
        coverImage: '../../assets/images/splendora-cover.png',
        name: 'Đỗ Nhất Chung',
        job: 'Sửa khóa',
        address: 'Vân Lũng, An Khánh, Hoài Đức',
        tel: '0986123459',
        rate: 5
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/repairman-avatar.svg",
        coverImage: '../../assets/images/splendora-cover.png',
        name: 'Dương Văn Dự',
        job: 'Sửa tủ lạnh',
        address: 'Vân Lũng, An Khánh, Hoài Đức',
        tel: '0986123456',
        rate: 5
    },
]

export const EvaluateFake = [{
        id: NewGuid(),
        avatar: "../../../../assets/images/29-12.png",
        name: 'Trần Thanh Tuyền',
        time: '07/03/2020',
        evaluate: 'Tay nghề rất tốt, rất nhiệt tình',
        rate: 4.5
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/repairman-avatar.svg",
        name: 'Nguyễn Văn Anh',
        time: '09/03/2020',
        evaluate: 'Tay nghề ok đó',
        rate: 5
    }
]

const hitchhike = [{
        id: NewGuid(),
        avatar: "../../assets/images/29-12.png",
        name: 'Nguyễn Thanh Tùng',
        address: 'Splendora - Trần Duy Hưng - Nguyễn Chí Thanh - Kim Mã - Bờ Hồ',
        time: '07/03/2020'
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/29-02.png",
        name: 'Nguyễn Hoàng Mai Ly',
        address: 'Splendora - Phạm Văn Đồng - Ngoại Giao Đoàn',
        time: '08/03/2020'
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/29-04.png",
        name: 'Nguyễn Hoàng Mai Ly',
        address: 'Splendora - Sân bay Nội Bài',
        time: '09/03/2020'
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/29-12.png",
        name: 'Nguyễn Thanh Tùng',
        address: 'Splendora - Trần Duy Hưng - Nguyễn Chí Thanh - Kim Mã - Bờ Hồ',
        time: '07/03/2020'
    },
    {
        id: NewGuid(),
        avatar: "../../assets/images/29-02.png",
        name: 'Nguyễn Hoàng Mai Ly',
        address: 'Splendora - Phạm Văn Đồng - Ngoại Giao Đoàn',
        time: '08/03/2020'
    },
]

const busSchedule = [{
        id: 1,
        busId: 1,
        areaId: 1,
        glandId: 1,
        busStationStart: "BX Gia Lâm",
        busStationEnd: 'BX Yên Nghĩa',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(BX Gia Lâm)',
            '5h05 - 21h00(BX Yên Nghĩa)',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Bến xe Gia Lâm - Ngô Gia Khảm - Ngọc Lâm - Nguyễn Văn Cừ - Cầu Chương Dương - Trần Nhật Duật - Yên Phụ - Điểm trung chuyển Long Biên - Hàng Đậu - Hàng Cót - Hàng Gà - Hàng Điếu - Đường Thành - Phủ Doãn - Triệu Quốc Đạt - Hai Bà Trưng - Lê Duẩn - Khâm Thiên - Đường mới (Vành đai 1) - Quay đầu tại điểm mở dải phân cách - Đường mới (Vành đai 1)- Nguyễn Lương Bằng - Tây Sơn - Ngã tư Sở - Nguyễn Trãi - Trần Phú (Hà Đông) - Quang Trung (Hà Đông) - Ba La - Quốc lộ 6 - Bến xe Yên Nghĩa.',
        end: 'Bến xe Yên Nghĩa - Quốc lộ 6 - Ba La - Quang Trung (Hà Đông) - Trần Phú (Hà Đông) - Nguyễn Trãi - Ngã tư Sở - Tây Sơn - Nguyễn Lương Bằng - Xã Đàn - Quay đầu tại đối diện ngõ Xã Đàn 2 - Xã Đàn - Khâm Thiên - Nguyễn Thượng Hiền - Yết Kiêu - Trần Hưng Đạo - Quán Sứ - Hàng Da - Đường Thành - Phùng Hưng - Lê Văn Linh - Phùng Hưng (đường trong) - Phan Đình Phùng - Hàng Đậu - Trần Nhật Duật - Cầu Chương Dương - Nguyễn Văn Cừ - Nguyễn Sơn - Ngọc Lâm - Ngô Gia Khảm - Bến xe Gia Lâm.'
    },
    {
        id: 2,
        busId: 1,
        areaId: 1,
        glandId: 2,
        busStationStart: "Bác Cổ",
        busStationEnd: 'Bến xe Yên Nghĩa',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(Bác Cổ)',
            '5h05 - 21h00(Bến xe Yên Nghĩa)',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Bác Cổ (bãi đỗ xe buýt Trần Khánh Dư) - Trần Khánh Dư (đường dưới) - Trần Hưng Đạo - Lê Thánh Tông - Hai Bà Trưng - Quang Trung - Tràng Thi - Điện Biên Phủ - Trần Phú - Chu Văn An - Tôn Đức Thắng - Nguyễn Lương Bằng - Tây Sơn - Ngã tư Sở - Nguyễn Trãi - Trần Phú (Hà Đông) - Quang Trung (Hà Đông) - Ba La - Quốc lộ 6 - Bến xe Yên Nghĩa',
        end: 'Bến xe Yên Nghĩa - Quốc lộ 6 - Ba La - Quang Trung (Hà Đông) - Trần Phú (Hà Đông) - Nguyễn Trãi - Ngã tư Sở - Tây Sơn - Nguyễn Lương Bằng - Tôn Đức Thắng - Nguyễn Thái Học - Phan Bội Châu - Hai Bà Trưng - Phan Chu Trinh - Tràng Tiền - Bác Cổ (bãi đỗ xe buýt Trần Khánh Dư).'
    },
    {
        id: 3,
        busId: 1,
        areaId: 2,
        glandId: 1,
        busStationStart: "Bến xe Giáp Bát",
        busStationEnd: 'Bến xe Gia Lâm',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(Bến xe Giáp Bát)',
            '5h05 - 21h00(Bến xe Gia Lâm)',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Bến xe Giáp Bát - Giải Phóng - Lê Duẩn - Nguyễn Thượng Hiền - Yết Kiêu - Trần Hưng Đạo - Trần Khánh Dư - Trần Quang Khải - Trần Nhật Duật - Long Biên (Điểm quay đầu trước phố Hàng Khoai) - Trần Nhật Duật - Cầu Chương Dương - Nguyễn Văn Cừ - Nguyễn Sơn - Ngọc Lâm - Ngô Gia Khảm - Bến xe Gia Lâm.',
        end: 'Bến xe Yên Nghĩa - Quốc lộ 6 - Ba La - Quang Trung (Hà Đông) - Trần Phú (Hà Đông) - Nguyễn Trãi - Ngã tư Sở - Tây Sơn - Nguyễn Lương Bằng - Tôn Đức Thắng - Nguyễn Thái Học - Phan Bội Châu - Hai Bà Trưng - Phan Chu Trinh - Tràng Tiền - Bác Cổ (bãi đỗ xe buýt Trần Khánh Dư).'
    },
    {
        id: 4,
        busId: 1,
        areaId: 2,
        glandId: 2,
        busStationStart: "Bến xe Giáp Bát",
        busStationEnd: 'Vincom - Phúc Lợi',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(Bến xe Giáp Bát)',
            '5h05 - 21h00(Vincom - Phúc Lợi)',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Bến xe Nước Ngầm - Ngọc Hồi - Giải Phóng - Bến xe Giáp Bát (Quảng trường Bến xe Giáp Bát) - Giải Phóng - Đại La - Minh Khai - Cầu Vĩnh Tuy - Đàm Quang Trung - Chu Huy Mân - Hội Xá - Vũ Xuân Thiều - Đường Phúc Lợi - Ngõ 193 Phúc lợi - Ngách 195/9 Phúc Lợi - Ngõ 195 Phúc Lợi - Phúc Lợi (đối diện trường THPT Phúc Lợi).',
        end: 'Phúc Lợi (đối diện trường THPT Phúc Lợi) - Đường Phúc Lợi - Vũ Xuân Thiều - Hội Xá - Chu Huy Mân - Đàm Quang Trung - Cầu Vĩnh Tuy - Minh Khai - Đại La - Trường Chinh - Ngã tư Vọng - Giải Phóng - Ngọc Hồi - quay đầu tại điểm mở đối diện công ty ABB - Ngọc Hồi - Bến xe Nước Ngầm.'
    },
    {
        id: 5,
        busId: 2,
        areaId: 1,
        glandId: 1,
        busStationStart: "Long Biên",
        busStationEnd: 'Bến xe Nước Ngầm',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(Long Biên)',
            '5h05 - 21h00(Bến xe Nước Ngầm)',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Long Biên- Điểm trung chuyển Long Biên - Trần Nhật Duật - Nguyễn Hữu Huân - Lý Thái Tổ - Ngô Quyền - Hai Bà Trưng - Lê Thánh Tông - Trần Thánh Tông - Tăng Bạt Hổ - Yecxanh - Lò Đúc - Trần Khát Chân - Quay đầu tại đối diện số nhà 274 Trần Khát Chân - Trần Khát Chân - Kim Ngưu - Nguyễn Tam Trinh - Đường Lĩnh Nam - Đường dẫn cầu Thanh Trì - Pháp Vân - Rẽ trái tại nút giao đường vành đai 3 và đường Ngọc Hồi (gần BX Nước Ngầm) - Ngọc Hồi - Quay đầu tại công ty ABB - Ngọc Hồi - BX Nước Ngầm',
        end: 'BX Nước Ngầm - Giải Phóng - Pháp Vân - Đường dẫn cầu Thanh Trì - Đường Lĩnh Nam - Nguyễn Tam Trinh - Kim Ngưu - Lò Đúc - Phan Chu Trinh - Lý Thái Tổ - Ngô Quyền - Hàng Vôi - Hàng Tre - Hàng Muối - Trần Nhật Duật - Điểm trung chuyển Long Biên - Yên Phụ - Long Biên'
    },
    {
        id: 6,
        busId: 2,
        areaId: 1,
        glandId: 2,
        busStationStart: "Linh Đàm",
        busStationEnd: 'Phú Diễn',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(Linh Đàm)',
            '5h05 - 21h00(Phú Diễn)',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Linh Đàm (Khu đô thị Linh Đàm) - Nguyễn Duy Trinh - Nguyễn Hữu Thọ - Cầu Dậu - Kim Giang - Khương Đình - Nguyễn Trãi - Quay đầu tại 177 Nguyễn Trãi - Nguyễn Trãi - Nguyễn Tuân - Hoàng Minh Giám - Nguyễn Chánh - Vũ Phạm Hàm - Trung Kính - Trần Thái Tông - Tôn Thất Thuyết - Nguyễn Hoàng - Hàm Nghi - Nguyễn Cơ Thạch - Hồ Tùng Mậu - Cầu Diễn - Đường K1 Cầu Diễn - Ga Phú Diễn - Phú Diễn (Trại Gà)',
        end: 'Phú Diễn (Trại Gà) - Đường K1 Cầu Diễn - Ga Phú Diễn - Cầu Diễn - Quay đầu Doanh trại quân đội nhân dân (nhà máy Z157) - Hồ Tùng Mậu - Nguyễn Cơ Thạch - Hàm Nghi - Nguyễn Hoàng - Tôn Thất Thuyết - Phạm Văn Bạch - Trần Thái Tông - Trung Kính - Vũ Phạm Hàm - Nguyễn Chánh - Hoàng Minh Giám - Nguyễn Tuân - Nguyễn Trãi - quay đầu tại ngã 4 Khuất Duy Tiến, Nguyễn Trãi - Nguyễn Trãi - Khương Đình - Kim Giang - Cầu Dậu - Nguyễn Hữu Thọ - Nguyễn Duy Trinh - Khu đô thị Linh Đàm'
    },
    {
        id: 7,
        busId: 2,
        areaId: 2,
        glandId: 1,
        busStationStart: "Bến xe Giáp Bát",
        busStationEnd: 'Cầu Giẽ',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(Bến xe Giáp Bát)',
            '5h05 - 21h00(Cầu Giẽ)',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Bến xe Giáp Bát - Giải Phóng - Kim Đồng - Giải Phóng - Ngọc Hồi - Quốc Lộ 1 - Liên Ninh - Quán Gánh - Thị trấn Thường Tín - Tía - Đỗ Xá - Nghệ - Thị trấn Phú Xuyên - Guột - Cầu Giẽ (bãi xe khu vực ngã 3 đường nối thông giữa QL1A và đường 75)',
        end: 'Cầu Giẽ (bãi xe khu vực ngã 3 đường nối thông giữa QL1A và đường 75) - Quốc Lộ 1A - Guột - Thị trấn Phú Xuyên - Nghệ - Đỗ Xá - Tía - Thị trấn Thường Tín - Quán Gánh - Liên Ninh - Ngọc Hồi - Giải Phóng - Bến xe Giáp Bát.'
    },
    {
        id: 8,
        busId: 2,
        areaId: 2,
        glandId: 2,
        busStationStart: "Bến xe Giáp Bát",
        busStationEnd: 'Hồng Vân (Thường Tín)',
        unit: 'Xí Nghiệp Xe Buýt Hà Nội (Hanoibus)',
        price: '7000đ/lượt',
        distance: '22.4 km',
        activeTime: [
            '5h00 - 21h00(Bến xe Giáp Bát)',
            '5h05 - 21h00(Hồng Vân (Thường Tín))',
            'CN: 5h00 - 21h00'
        ],
        planTime: '65 phút',
        tripNumber: '13 - 16 xe chuyến/ngày',
        tripSpacing: [
            'Ngày thường: 10 - 15 phút/chuyến',
            'Chủ nhật: 12-20 phút/chuyến phút'
        ],
        start: 'Bến xe Giáp Bát - Giải Phóng - Kim Đồng - quay đầu tại điểm mở - Kim Đồng - Giải Phóng - Ngọc Hồi - Thường Tín - Tía - Ngã ba Đỗ Xá - đường 429 (phía Đông) - Phú Minh (Phú Xuyên - Công ty Việt Tuyến).',
        end: 'Phú Minh (Phú Xuyên - Công ty Việt Tuyến) - đường 429 (phía Đông) - Ngã ba Đỗ Xá - Tía - Thường Tín - Ngọc Hồi - Giải Phóng - Bến xe Giáp Bát.'
    },
]

export default {
    hitchhike,
    busSchedule
}