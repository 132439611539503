import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adHouseRentActions from './buy-and-rent-houses.store';
import SharedService from 'services/share.service.js';
import PaginationControl from 'components/pagination/pagination.view';
import "../advertising-desktop.scss";
import { Modal, Button } from 'react-bootstrap';
const sharedService = new SharedService();
import { JwtHelper } from '/utils/jwt-helper';
import { transformCurrency, SetDefaultErrorImage } from 'utils/configuration';
import ReactTooltip from "react-tooltip";

export class BuyAndRentHousesDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landCategoris: [],
      currentPageIndex: 1,
      category_id: '',
      visibleModal: false,
      selectedLandId: null,
      currentUser: JwtHelper.currentUser()
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetLandList();
    this.onGetDropdownData();
  }

  onGetLandList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = null) => {
    this.setState({ isLoading: true });
    this.props.getListHouseRent(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => {
        this.setState({ isLoading: false });
      }
    );
  };

  onGetDropdownData = () => {
    Promise.all([
      sharedService.getLandCategoris(),
    ]).then(res => {
      const [landCategorisModel] = res;
      this.setState({
        landCategoris: landCategorisModel.content,
      })
    }).catch(err => {
    })
  };

  onFilterChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      currentPageIndex: 1
    }, () => {
      this.onGetLandList(1, 10, { category_id: this.state.category_id });
    });
  }

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetLandList(pageNumber);
    })
  }

  onDeleteAdHouse = (confirm) => {
    if (confirm) {
      this.props.deleteAdHouse(this.state.selectedLandId).then(res => {
        this.onToggleDeleteModal(false);
        this.onGetLandList(1);
      }, err => {

      });
    } else {
      this.onToggleDeleteModal(false);
    }
  }

  onToggleDeleteModal = (visibleModal, landId) => {
    this.setState({
      visibleModal: visibleModal,
      selectedLandId: landId
    })
  }

  render() {
    const { landCategoris, currentPageIndex, visibleModal, currentUser } = this.state;
    const { landList, totalItems, loggedIn } = this.props;

    return (
      <div className="advertising-desktop">
        <div className="row align-items-center">
          <div className="col-6">
            <h2 className="title-lg mb-0">Mua, Bán & cho thuê nhà</h2>
          </div>
          {loggedIn && <div className="col-6 text-right">
            <Link to="/rao-vat/mua-ban-nha/dang-tin" className="title-link"><img src={require('assets/images/add-new.svg')} alt="Add new" /> Đăng tin</Link>
          </div>}
        </div>

        <h3 className="title-md mt-4">Loại hình bất động sản</h3>
        <div className="row">
          <div className="col-6">
            <form className="form-house-type">
              <div className="form-group">
                <select className="form-control" disabled={this.state.isLoading} name="category_id" onChange={this.onFilterChange.bind(this)} value={this.state.category_id}>
                  <option value="">Chọn loại hình</option>
                  {landCategoris.map(item =>
                    <option key={item.categoryId} value={item.categoryId}>{item.categoryName}</option>
                  )}
                </select>
              </div>
            </form>
          </div>
        </div>

        <div className="house-list mt-3">
          {
            landList.map((land) =>
              <RenderField data={land} customerId={currentUser && currentUser.customerId} loggedIn={loggedIn} key={land.landId} openDeleteModal={this.onToggleDeleteModal.bind(this)} />
            )
          }
          {landList.length <= 0 && <p className="col-12">Chưa có bài đăng nào.</p>}
          <DeleteHouseModal showDeleteModal={visibleModal} deleteAdHouse={this.onDeleteAdHouse} />
        </div>
        <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
      </div>
    );
  }
}

function RenderField(props) {
  const data = props.data;
  const onOpenDeleteModal = (landId) => {
    props.openDeleteModal(true, landId);
  }

  return (
    <div className="house-list-item bg-white">
      <div className="row">
        <div className="col-6">
          <Link to={`/rao-vat/mua-ban-nha/${data.landId}`} className="house-image">
            <img onError={SetDefaultErrorImage} src={data.imageUrl} className="img-fluid w-100" />
          </Link>
        </div>
        <div className="col-6 p-3">
          <div className="row">
            <div className="col-12">
              <Link to={`/rao-vat/mua-ban-nha/${data.landId}`} className="house-title">[{data.categoryName && data.categoryName.toUpperCase()}] {data.title}</Link>
            </div>

            <div className="col-6">
              <span className="price">Giá: <span>{transformCurrency(data.price)} VNĐ</span></span>
            </div>
            <div className="col-6">
              <span className="square">Diện tích: <span>{data.size}m2</span></span>
            </div>

            <div className="col-6 house-general">
              <span>Phòng ngủ: {data.bedroom}</span>
              <br></br>
              <span>Phòng khách: {data.livingRoom}</span>
            </div>
            <div className="col-6 house-general">
              <span>Vệ sinh: {data.wc}</span>
              <br></br>
              <span>Bếp: {data.kitchen}</span>
            </div>

            <div className="col-6 mt-3">
              <a href={'tel:' + data.mobileNumber} className="btn btn-success mt-2" data-tip={`SĐT: +${data.mobileNumber}`}>
                <img src={require('assets/images/phone-call.svg')} alt="Phone Call" /> Liên hệ
              </a>
              <ReactTooltip place="bottom" type="dark" effect="float" />
            </div>
            {props.loggedIn && data.customerId == props.customerId && <div className="col-6 d-flex align-items-end text-right">
              <a className="text-danger" onClick={() => onOpenDeleteModal(data.landId)}>Xóa</a>
              <Link to={`/rao-vat/mua-ban-nha/dang-tin/${data.landId}`} className="text-primary ml-4">Chỉnh sửa</Link>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

function DeleteHouseModal(props) {
  return (
    <Modal show={props.showDeleteModal} className="modal-delete" centered onHide={() => props.deleteAdHouse(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Xóa bài viết?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Bài viết sẽ không thể khôi phục sau khi xóa.
        <br />
        Bạn có chắc muốn xóa bài viết này.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.deleteAdHouse(false)}>Hủy</Button>
        <Button variant="success" onClick={() => props.deleteAdHouse(true)}>Xóa</Button>
      </Modal.Footer>
    </Modal>
  )
}

const mapStateToProps = state => ({
  landList: state.adHouseRent.landList,
  totalItems: state.adHouseRent.totalItems,
  loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getListHouseRent: adHouseRentActions.GetAdHouseList,
  deleteAdHouse: adHouseRentActions.DeleteAdHouse,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BuyAndRentHousesDesktopView)