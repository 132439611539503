import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as repairingHouseholdActions from "redux/stores/utilities-plus/repairing-household.store";
import { SetDefaultErrorImage } from "utils/configuration";
import "../utilities-plus-desktop.scss";

class RepairingHouseholdDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetRepairingHouseholdList(1, 9999, {
      orderBy: "update_date",
      orderType: "DESC",
    });
  }

  onGetRepairingHouseholdList = (
    pageIndex = 1,
    size = 10,
    filterObjs = null
  ) => {
    this.props.getRepairingHouseholdList(pageIndex, size, filterObjs).then(
      (res) => {},
      (err) => {}
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetRepairingHouseholdList(e, 9999, {
          orderBy: "update_date",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  render() {
    const { currentPageIndex } = this.state;
    const { repairingHouseholdList, totalItems } = this.props;
    return (
      <div className="utilities-plus-desktop">
        <div className="row">
          <div className="col-12 mb-2">
            <h2 className="title-lg">Sửa chữa, lắp đặt đồ gia dụng</h2>
          </div>
          {repairingHouseholdList && repairingHouseholdList.length > 0 ? (
            repairingHouseholdList.map((item) => (
              <RenderItem key={item.serviceId.toString()} item={item} />
            ))
          ) : (
            <div className="col-12 mb-3">Không có dữ liệu</div>
          )}
        </div>
      </div>
    );
  }
}

function RenderItem(props) {
  const { item } = props;
  return (
    <div className="col-12 mb-3" key={item.serviceId.toString()}>
      <div className="box-body p-3">
        <div className="row align-items-center no-gutters">
          <div className="col-2 text-center">
            <Link to={`/sua-chua-lap-dat/${item.serviceId}`}>
              <img
                src={
                  item.avatar || require("assets/images/repairman-avatar.svg")
                }
                onError={SetDefaultErrorImage}
                alt={item.providerName}
                className="img-fluid avatar"
                width="50"
                height="50"
              />
            </Link>
          </div>
          <div className="col-4">
            <span className="fullname-lg">
              <Link to={`/sua-chua-lap-dat/${item.serviceId}`}>
                {item.providerName}
              </Link>
            </span>
            <br />
            <span className="job">{item.serviceName}</span>
          </div>
          <div className="col-4">
            <span>SĐT: {item.mobile_number}</span>
          </div>
          <div className="col-2">
            <a
              href={"tel:" + item.mobile_number}
              className="btn btn-success mt-3"
            >
              <img
                src={require("assets/images/phone-call.svg")}
                alt="Phone Call"
                width="15"
              />{" "}
              Liên hệ
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  repairingHouseholdList: state.repairingHousehold.repairingHouseholdList,
  totalItems: state.repairingHousehold.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRepairingHouseholdList:
        repairingHouseholdActions.GetRepairingHouseholdList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepairingHouseholdDesktopView);
