import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Error, Success } from 'components/support-create-form/validate-style';
import "../../advertising-desktop.scss";
import * as hourlyMaidActions from 'redux/stores/hourly-maid/hourly-maid.store';
import { convertTextareaToHtml } from 'utils/configuration';
import AddNotification from 'components/react-notifications/react-notifications';
import * as viVN from 'translation/vi-VN.json';
import { NotificationMessageType } from 'utils/configuration';
import { JwtHelper } from "utils/jwt-helper";

function CreateOrHourlyMaidForm(props) {

  const { loggedIn } = props;

  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange"
  });

  const onSubmit = async data => {
    data.content = convertTextareaToHtml(data.content);
    props.onCreateOrUpdateHourlyMaid && props.onCreateOrUpdateHourlyMaid(data);
    setValue('content', null);
    setValue('mobile_number', null);
  };

  return (
    <div className="bg-white p-3 create-hourly-maid">
      <h4 className="title-sm">Tạo tin mới</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input type="text" name="mobile_number" className="form-control" placeholder="Số điện thoại"
            disabled={!loggedIn}
            ref={register({
              required: 'Trường bắt buộc',
              pattern: {
                value: /(\(?\+[0-9]{2}\)|0)?([0-9]{9,10})/i,
                message: "Số điện thoại không hợp lệ"
              }
            })} />
          {errors.mobile_number && <Error content={errors.mobile_number.message} />}
        </div>
        <div className="form-group">
          <textarea name="content" className="form-control" rows="5" placeholder="Nội dung" disabled={!loggedIn}
            ref={register({
              required: 'Trường bắt buộc',
              minLength: {
                value: 6,
                message: "Nội dung phải có ít nhất 6 ký tự"
              }
            })}></textarea>
          {errors.content && <Error content={errors.content.message} />}
        </div>
        <div className="text-right">
          <button type="submit" className="btn btn-warning" disabled={!loggedIn}>Đăng tin</button>
        </div>
      </form>
    </div >
  );
}

class AddHourlyMaidDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
    }
  }

  UNSAFE_componentWillMount() {

  }

  onCreateOrUpdateHourlyMaid = (data) => {
    let submitedData = {
      ...data,
      customer_id: this.state.currentUser.customerId,
      assist_id: null,
    }
    this.props.onToggleAddingState(true);
    this.props.createOrUpdateHourlyMaid(submitedData).then(res => {
      AddNotification('Tạo bài viết thành công!', NotificationMessageType.Success);
      this.props.onToggleAddingState(false);
      this.props.callBack && this.props.callBack();
    }, err => {
    })
  }

  render() {
    const { loggedIn } = this.props;
    return (
      <CreateOrHourlyMaidForm loggedIn={loggedIn} onCreateOrUpdateHourlyMaid={this.onCreateOrUpdateHourlyMaid.bind(this)} />
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createOrUpdateHourlyMaid: hourlyMaidActions.CreateOrUpdateHourlyMaid,
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(AddHourlyMaidDesktopView)