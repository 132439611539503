/* eslint-disable react/prop-types */
import React, { Component, } from 'react';
import { connect } from "react-redux";

class AppLoading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { loading } = this.props;

        return (
            <div className="pre-loading">
                {
                    loading && <div id="loading" className="loading-app">
                        <div className="canvas">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.app.loading,
})

export default connect(mapStateToProps)(AppLoading);
