/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as hourlyMaidActions from 'redux/stores/hourly-maid/hourly-maid.store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { JwtHelper } from "utils/jwt-helper";
import { Link } from 'react-router-dom';

class HourlyMaidItemMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenEditPopup: false,
            isOpenDeletePopup: false,
            selectedAssistId: null,
            currentUser: JwtHelper.currentUser(),
            isLoadingFirstTime: true,
        }
    }

    componentDidUpdate() {
        if (this.state.isLoadingFirstTime && this.props.match && (this.props.match.params.typeId === "giup-viec-theo-gio") && document.getElementById(`mobile-post-ad-${this.props.match.params.idPost}`)) {
            location.href = `#mobile-post-ad-${this.props.match.params.idPost}`;
            this.setState({
                isLoadingFirstTime: false
            })
        }
    }

    onToggleEditPopup = (isOpen, assistId) => {
        this.setState({
            isOpenEditPopup: isOpen,
            selectedAssistId: assistId
        })
    }

    onToggleDeletePopup = (isOpen, assistId) => {
        this.setState({
            isOpenDeletePopup: isOpen,
            selectedAssistId: assistId
        })
    }

    onDeleteHourlyMaidPost = (isConfirm) => {
        if (isConfirm) {
            this.props.deleteHourlyMaid(this.state.selectedAssistId).then(res => {
                this.onToggleDeletePopup(false);
                AddNotification('Xoá tin thành công!', NotificationMessageType.Success);
                this.props.callBack && this.props.callBack();
            }, err => {
                this.onToggleDeletePopup(false);
            })
        }
    }

    render() {
        const { hourlyMaidList, loggedIn } = this.props;
        const { isOpenDeletePopup, currentUser } = this.state;

        return (
            <div className="hourly-maid-mobile">
                <h2 className="mt-3">Tin mới</h2>
                <div className="hourly-maid-list">
                    {hourlyMaidList.map(item =>
                        <div key={item.assistId} className="hourly-maid-item rounded p-2">
                            <div className="row no-gutters">
                                <div className="col-1">
                                    <img src={item.profileUrl || require('assets/images/29-12.png')} className="img-fluid" />
                                </div>
                                <div className="col-10 pl-2">
                                    <h5 className="fullname mb-0" id={`mobile-post-ad-${item.assistId}`}>{item.customerName}</h5>
                                    <span className="datetime">{moment(item.createDate).fromNow()}</span>
                                </div>
                                <div className="col-1 text-right">
                                    {loggedIn && item.customerId == currentUser.customerId && <button type="button" className="close" aria-label="Close" onClick={() => this.onToggleDeletePopup(true, item.assistId)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>}
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-1"></div>
                                <div className="col-11 pl-2">
                                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-1"></div>
                                <div className="col-6 pl-2">
                                    <a href={`tel:${item.mobileNumber}`} className="contact">
                                        <img src={require('assets/images/phone-call-round.svg')} className="img-fluid img-phone-call-round" alt="Phone Call" />
                                        <span className="contact">Liên hệ</span>
                                    </a>
                                </div>
                                {loggedIn && item.customerId == currentUser.customerId && <div className="col-5 text-right">
                                    <Link to={`/m/giup-viec-theo-gio/sua-tin/${item.assistId}`}>
                                        <img src={require('assets/images/edit-blue.svg')} alt="edit-blue" className="img-fluid img-edit-blue" />
                                    </Link>
                                </div>}
                            </div>
                        </div>)}
                </div>

                <Modal show={isOpenDeletePopup} className="modal-delete" centered onHide={() => this.onToggleDeletePopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xóa tin?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Tin đã đăng sẽ không thể khôi phục sau khi xóa. Bạn có chắc muốn xóa tin này.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onToggleDeletePopup(false)}>Hủy</Button>
                        <Button variant="success" onClick={() => this.onDeleteHourlyMaidPost(true)}>Xóa</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteHourlyMaid: hourlyMaidActions.DeleteHourlyMaid,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HourlyMaidItemMobileView)