import { Map } from 'immutable';
import { createFormData, AppKey } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { Procedures, ProcedureCategoris } from 'mock-data/procedure.mock';
import { createSaveLoading } from 'core/app.store';
let proceduresTemp = [...Procedures];
const service = new Service();
/** Action Types */
const PROCEDURESAVELIST = 'PROCEDURE/SAVELIST';
const PROCEDURESAVETOP4PROCEDURE = 'PPROCEDURE/SAVETOP4PPROCEDURE';
const PROCEDUREETAIL = 'PROCEDURE/DETAIL';
const PROCEDURESAVECATEGORYLIST = 'PROCEDURE/SAVECATEGORYLIST';

/** Actions */
export const createSaveProcedureList = (procedureModel) => ({ type: PROCEDURESAVELIST, payload: { procedureList: procedureModel.procedureList, totalItems: procedureModel.totalItems } });
export const createSaveProcedureDetail = () => ({ type: PROCEDUREETAIL });
export const createSaveTop4Procedures = (top4Procedures) => ({ type: PROCEDURESAVETOP4PROCEDURE, payload: top4Procedures });
export const createSaveProcedureCategories = (procedureCategories) => ({ type: PROCEDURESAVECATEGORYLIST, payload: procedureCategories });


/** Action creators */
export const GetProcedureList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date', folder_id: 2 };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ProcedureGetList, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            dispatch(createSaveProcedureList({ procedureList: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetProcedureCategories = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.ProcedureGetCategoryList, payLoad).then(res => {
            dispatch(createSaveProcedureCategories(res.content || []))
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetTop4ProcedureList = (pageIndex = 1, size = 4, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = {...filterObjs, orderBy: 'create_date', folder_id: 2 };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.ProcedureGetList, payLoad).then(res => {
            dispatch(createSaveTop4Procedures(res.content && res.content.items || []))
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const GetProcedureDetail = (procedureId) => {
    return dispatch => {
        let payLoad = createFormData({ legalId: procedureId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ProcedureGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteProcedure = (procedureId) => {
    return dispatch => {
        let payLoad = createFormData({ procedureId: procedureId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ProcedureDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateProcedure = (procedureModel) => {
    return dispatch => {
        let payLoad = createFormData(procedureModel, AppKey.PostPayloadPrefix);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ProcedureCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

/** Fake */
export const GetProcedureListFake = (pageIndex, size, filterObjs) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let procedureModels = filterObjs ?
                proceduresTemp.filter(item => {
                    return item.category_id == filterObjs['category_id'];
                }) :
                proceduresTemp;
            let procedureList = procedureModels.slice(pageIndex * size - size, pageIndex * size);
            if (procedureList) {
                dispatch(createSaveProcedureList({ procedureList: procedureList, totalItems: procedureModels.length }))
                resolve({
                    content: procedureList,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const GetProcedureCategoriesFake = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let procedureCategories = [...ProcedureCategoris];
            if (procedureCategories) {
                dispatch(createSaveProcedureCategories(procedureCategories))
                resolve({
                    content: procedureCategories,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const GetTop4ProceduresFake = (pageIndex, size = 4, filterObjs) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let procedureList = Procedures.slice(pageIndex * size - size, (pageIndex - 1) + size);
            if (procedureList) {
                dispatch(createSaveTop4Procedures(procedureList))
                resolve({
                    content: procedureList,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const GetProcedureDetailFake = (ProcedureId) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let procedureDetail = Procedures.find(lg => lg.id == ProcedureId);
            if (procedureDetail) {
                resolve({
                    content: procedureDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    procedureList: [],
    top4Procedures: [],
    procedureCategories: [],
    procedureDetail: {},
    totalItems: 0,
}).toJS();

/** Reducers */
export default function ProcedureReducer(state = initialState, action) {
    switch (action.type) {
        case PROCEDURESAVELIST:
            return {
                ...state,
                procedureList: [...action.payload.procedureList],
                totalItems: action.payload.totalItems,
            }
        case PROCEDURESAVETOP4PROCEDURE:
            return {
                ...state,
                top4Procedures: [...action.payload],
            }
        case PROCEDUREETAIL:
            return {
                ...state,
                procedureDetail: action.payload,
            }
        case PROCEDURESAVECATEGORYLIST:
            return {
                ...state,
                procedureCategories: [...action.payload],
            }
        default:
            return state;
    }
}