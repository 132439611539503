/* eslint-disable react/prop-types */
import React from 'react';
import { RenderMonthlyBill } from './water-bill-month.view.jsx';

export default function PayDetail(props) {
  const data = props.data;

  return (
    <div className="body mt-4">
      <div className="row">
        <div className="col-5">
          <h3 className="body-title">
            <img src={require('assets/images/detailed-arrow.svg')} alt="logo" className="img-fluid detailed-arrow" onClick={props.click} /> Lịch sử thanh toán
          </h3>
        </div>
        <div className="col-7"></div>
      </div>

      <hr></hr>

      <RenderMonthlyBill data={data} />
    </div>
  )

}