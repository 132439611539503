/* eslint-disable no-unused-vars */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationMessageType } from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import * as documentActions from "redux/stores/document/document.store";
import { Table, Modal, Button } from "react-bootstrap";
import PaginationControl from "components/pagination/pagination.view";
import UploadDocumentForm from "./add/upload-document-desktop.form";
import EditDocumentForm from "./edit/edit-document-desktop.form";
import "./document-management-desktop.scss";
import ReactTooltip from "react-tooltip";

class DocumentManagementDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDocumentManagement: true,
      isDocumentManagementDetail: false,
      isUploadDocument: false,
      isEditDocument: false,
      isShowModal: false,
      user: this.props.user || null,
      currentPageIndex: 1,
      documentItem: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.state.user &&
      this.onGetDocumentList(1, 10, {
        customer_id: this.state.user.customerId,
        orderBy: "update_date",
        orderType: "DESC",
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (!nextProps.isDocumentManagementDetail &&
        this.state.isDocumentManagementDetail) ||
      (!nextProps.isDocumentManagementDetail && this.state.isUploadDocument) ||
      (!nextProps.isDocumentManagementDetail && this.state.isEditDocument)
    ) {
      this.setState({
        isDocumentManagement: true,
        isDocumentManagementDetail: false,
        isUploadDocument: false,
        isEditDocument: false,
        isShowModal: false,
      });
      this.onGetDocumentList(1, 10, {
        customer_id: this.state.user.customerId,
        orderBy: "update_date",
        orderType: "DESC",
      });
    }
  }

  onGetDocumentList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.onGetListDocument(pageIndex, size, filterObjs).then(
      (res) => { },
      (err) => { }
    );
  };

  onDeleteDocument = (id) => {
    this.props.onDeleteDocument(id).then(
      (res) => {
        if (res && res.content) {
          AddNotification(viVN.Success.Delete, NotificationMessageType.Success);
          this.onGetDocumentList(1, 10, {
            customer_id: this.state.user.customerId,
            orderBy: "update_date",
            orderType: "DESC",
          });
          this.onCloseModal();
        } else {
          res &&
            res.errorType &&
            AddNotification(
              viVN.Errors[res && res.errorType],
              NotificationMessageType.Error
            );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetDocumentList(e, 10, {
          customer_id: this.state.user.customerId,
          orderBy: "update_date",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  onShowList = () => {
    this.setState({
      isDocumentManagement: true,
      isDocumentManagementDetail: false,
      isUploadDocument: false,
      isEditDocument: false,
    });
    this.onGetDocumentList(1, 10, {
      customer_id: this.state.user.customerId,
      orderBy: "update_date",
      orderType: "DESC",
    });
  };

  onShowDetail = (item) => {
    this.setState({
      isDocumentManagement: false,
      isDocumentManagementDetail: true,
      isUploadDocument: false,
      isEditDocument: false,
      documentItem: item,
    });
    this.props.onShowDocumentManagementDetail();
  };

  onUploadDocument = () => {
    this.setState({
      isDocumentManagement: false,
      isDocumentManagementDetail: false,
      isUploadDocument: true,
      isEditDocument: false,
    });
  };

  onEditDocument = (item) => {
    this.setState({
      isDocumentManagement: false,
      isDocumentManagementDetail: false,
      isUploadDocument: false,
      isEditDocument: true,
      documentItem: item,
    });
  };

  onShowModal = (item) => {
    this.setState({
      isShowModal: true,
      documentItem: item,
    });
  };

  onCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    const {
      isDocumentManagement,
      isDocumentManagementDetail,
      isUploadDocument,
      isEditDocument,
      currentPageIndex,
      documentItem,
      user,
    } = this.state;
    const { documentList, totalItems } = this.props;
    return (
      <div className="col-8">
        <div className="bg-white mt-4">
          <div className="document-management-list-desktop">
            <div className="header p-4">
              {isDocumentManagement && <h5>Giấy tờ của bạn</h5>}

              {isUploadDocument && <h5>Tải lên giấy tờ của bạn</h5>}

              {isEditDocument && <h5>Chỉnh sửa giấy tờ của bạn</h5>}

              {isDocumentManagementDetail && documentItem && (
                <div className="row no-guttes align-items-center">
                  <div className="col-6">
                    <h5>{documentItem.documentName}</h5>
                  </div>
                  <div className="col-6 text-right">
                    <span>{documentItem.uploadDate}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="body mt-3 pl-4 pr-4 pb-4">
              {isDocumentManagement && (
                <Table>
                  <thead>
                    <tr>
                      <th>Tiêu đề</th>
                      <th>Ngày tải lên</th>
                      <th></th>
                    </tr>
                  </thead>
                  {documentList && documentList.length > 0 ? (
                    <tbody>
                      {documentList.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <h5 onClick={() => this.onShowDetail(item)}>
                              {item.documentName}
                            </h5>
                          </td>
                          <td>{item.uploadDate}</td>
                          <td className="text-right">
                            <span
                              onClick={() => this.onEditDocument(item)}
                              data-tip="Chỉnh sửa">
                              <img
                                src={require("assets/images/edit-blue.svg")}
                                alt="Edit"
                                className="img-fluid img-edit"
                              />
                            </span>
                            <span
                              className="ml-3"
                              onClick={() => this.onShowModal(item)}
                              data-tip="Xóa"
                            >
                              <img
                                src={require("assets/images/delete-blue.svg")}
                                alt="Delete"
                                className="img-fluid img-delete"
                              />
                            </span>
                          </td>

                          <ReactTooltip place="bottom" type="dark" effect="float" />
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                      <tbody>
                        <tr>
                          <td colSpan="3" className="text-center">
                            Không có dữ liệu
                        </td>
                        </tr>
                      </tbody>
                    )}

                  <tfoot>
                    <tr>
                      <td>
                        {documentList && documentList.length > 0 && (
                          <PaginationControl
                            style="mt-0 pt-2"
                            pageChanged={this.onSelectPage.bind(this)}
                            totalItems={totalItems}
                            currentPageIndex={currentPageIndex}
                          />
                        )}
                      </td>

                      <td></td>

                      <td className="text-right">
                        <button
                          className="btn btn-success rounded-pill mt-2"
                          onClick={() => this.onUploadDocument()}
                        >
                          Tải lên{" "}
                          <img
                            src={require("assets/images/upload-file.svg")}
                            alt="Upload File"
                          />
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              )}

              {isDocumentManagementDetail && documentItem && (
                <DocumentDetail data={documentItem} />
              )}

              {isUploadDocument && user && (
                <UploadDocumentForm
                  user={user}
                  showList={this.onShowList.bind(this)}
                />
              )}

              {isEditDocument && documentItem && user && (
                <EditDocumentForm
                  user={user}
                  data={documentItem}
                  showList={this.onShowList.bind(this)}
                />
              )}

              {documentItem && (
                <Modal
                  show={this.state.isShowModal}
                  onHide={() => this.onCloseModal()}
                  className="modal-delete"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Xóa bài viết?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Giấy tờ sẽ không thể khôi phục sau khi xóa.
                    <br />
                    Bạn có chắc muốn xóa giấy tờ này.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.onCloseModal()}
                    >
                      Hủy
                    </Button>
                    <Button
                      variant="success"
                      onClick={() =>
                        documentItem && this.onDeleteDocument(documentItem.id)
                      }
                    >
                      Xóa
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function DocumentDetail(props) {
  const { data } = props;
  return (
    <div className="document-management-detail">
      <img
        src={data.fileUrl || require("assets/images/quan-ly-giay-to.png")}
        alt="Quan ly giay to"
        className="img-fluid"
      />

      <p className="mt-2 mb-0">{data.content}</p>
    </div>
  );
}

const mapStateToProps = (state) => ({
  documentList: state.document.documentList,
  totalItems: state.document.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetListDocument: documentActions.GetDocumentList,
      onDeleteDocument: documentActions.DocumentDelete,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentManagementDesktopView);
