/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs, Tab } from "react-bootstrap";
import { isMobile, isTablet } from "react-device-detect";

import * as advertisingActions from "redux/stores/advertising/advertising.store";

import GeneralSuggestionsForm from "./general-suggestions.form";
import ReportIncidentsForm from "./report-incidents.form";

import { TokenKey, getCookies } from "utils/configuration";

import "../feedback/feedback.scss";

class FeedbackView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: window.location.href,
      newPage: window.location.href,
    };

    this.onChangeCurrentPage = this.onChangeCurrentPage.bind(this);
  }

  onChangeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  render() {
    if (isMobile || isTablet) {
      return "";
    }
    const { advertising } = this.props;
    const advertisingHomeDesktop =
      (advertising &&
        advertising.length > 0 &&
        advertising.filter(
          (item) =>
            item.positionCode == advertisingActions.POSITION_AD.DESKTOP_BOTTOM
        )) ||
      [];

    const isLogin = getCookies(TokenKey.AuthToken) ? true : false;
    return (
      <div className="mt-5">
        {advertisingHomeDesktop &&
          advertisingHomeDesktop.length > 0 &&
          advertisingHomeDesktop.map((item) => (
            <div key={`home-top-${item.advertisingId}`} className="advertising">
              <a target="_blank" href={item.advertisingUrl}>
                <img
                  src={
                    item.advertisingFile ||
                    require("/assets/images/quang-cao.png")
                  }
                  alt={item.advertisingName}
                  className="object-fit-cover"
                />
              </a>
            </div>
          ))}
        <div className="gui-y-kien mt-5">
          <h2 className="title-header">Gửi ý kiến</h2>
          <Tabs
            defaultActiveKey="gop-y-chung"
            className="justify-content-end tab-control"
          >
            <Tab eventKey="gop-y-chung" title="Góp ý chung">
              <GeneralSuggestionsForm
                isLogin={isLogin}
                onChangeCurrentPage={this.onChangeCurrentPage}
                currentPage={this.state.currentPage}
                newPage={window.location.href}
              />
            </Tab>
            <Tab eventKey="bao-su-co" title="Báo sự cố">
              <ReportIncidentsForm
                isLogin={isLogin}
                onChangeCurrentPage={this.onChangeCurrentPage}
                currentPage={this.state.currentPage}
                newPage={window.location.href}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  advertising: state.homeMobile.advertising,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackView);
