/* eslint-disable no-unused-vars */
import { Map } from "immutable";
import { createFormData, AppKey, setCookies } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();
/** Action Types */
const PROJECT_LIST = "PROJECT_LIST";
/** Actions */
export const createSaveProjectList = (data) => ({
  type: PROJECT_LIST,
  payload: {
    projectList: data,
  },
});
/** Action creators */
export const GetProjectList = () => {
  return (dispatch) => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.ProjectGetList, payLoad)
      .then((res) => {
        let projectLocation = [];
        res.content &&
          res.content.map((project) => {
            projectLocation.push({
              id: project.clientId,
              location: {
                lat: project.latitude,
                lng: project.longitude,
              },
            });
          });
        if (projectLocation.length > 0) {
          setCookies("Location project", JSON.stringify(projectLocation));
        }

        dispatch(createSaveProjectList(res.content));
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetApartmentList = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      clientId: id,
    });
    return service
      .post(ApiUrl.ApartmentGetList, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

// Initial state
export const initialState = Map({
  projectList: [],
}).toJS();

/** Reducers */
export default function ProjectReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_LIST:
      return {
        ...state,
        projectList: action.payload.projectList,
      };
    default:
      return state;
  }
}
