/* eslint-disable react/prop-types */
import "../safe-mobile.scss";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import * as IncidenAreaStore from "redux/stores/safe/incidents-area.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";
import {
  SetDefaultErrorImage,
  RemoveNoneNumberFromString,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";
import * as GetcontentByKeywordActions from "redux/stores/get-content-by-keyword/getContentByKeyword.store";
import * as buidingManagementActions from "redux/stores/management/building-management.store";
import { PromiseAllTools } from "core/app.store";

const project = JwtHelper.currentProject() || 1;

function SafeMainMobileView(props) {
  const buildingManagementModel = props.buildingManagementModel || {};
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.3,
    slidesToScroll: 2,
    arrows: false,
  };

  useEffect(() => {
    props.PromiseAllTools([
      props.GetIncidentAreasDesktopNew(),
      props.getBuildingManagementDetail(project),
      props.GetUBNDPhone(),
    ]);
  }, []);

  const listIncidents =
    props.incidentNews && props.incidentNews.haveData
      ? props.incidentNews.listData
      : [];
  return (
    <div>
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">An toàn</div>
            </div>
          </div>
        </div>
      </header>

      <div className="safe-main-mobile p-3">
        <div className="an-toan">
          <div className="row no-gutters">
            <div className="col-6 pr-2">
              <div className="item">
                <Link to={`/m/an-toan/chat-luong-nuoc`}>
                  <img
                    src={require("assets/images/chat-luong-nuoc.jpg")}
                    alt="Image"
                    className="img-fluid"
                  />
                </Link>
                <div className="overlay"></div>
                <Link
                  to={`/m/an-toan/chat-luong-nuoc`}
                  className="read-more"
                  dangerouslySetInnerHTML={{
                    __html:
                      window.innerWidth > 320
                        ? "Xem <br /> chất lượng nước"
                        : "Xem chất lượng nước",
                  }}
                ></Link>
              </div>
            </div>
            <div className="col-6 pl-2">
              <div className="item">
                <Link to={`/m/an-toan/chat-luong-khong-khi`}>
                  <img
                    src={require("assets/images/cloud-blue-sky.jpg")}
                    alt="Image"
                    className="img-fluid"
                  />
                </Link>
                <div className="overlay"></div>
                <Link
                  to={`/m/an-toan/chat-luong-khong-khi`}
                  className="read-more"
                  dangerouslySetInnerHTML={{
                    __html:
                      window.innerWidth > 320
                        ? "Xem <br /> chất lượng không khí"
                        : "Xem chất lượng không khí",
                  }}
                ></Link>
              </div>
            </div>
          </div>
        </div>

        <div className="contact mt-4">
          <div className="row row-cols-5">
            <div className="col">
              {buildingManagementModel.hotline && (
                <a
                  href={`tel:${RemoveNoneNumberFromString(
                    buildingManagementModel.hotline
                  )}`}
                >
                  <img
                    src={require("assets/images/Hot-BQL.svg")}
                    className="img-fluid w-100"
                    alt="BQL"
                  />
                </a>
              )}
            </div>
            <div className="col">
              <a href="tel:113">
                <img
                  src={require("assets/images/Hot-113.svg")}
                  className="img-fluid w-100"
                  alt="113"
                />
              </a>
            </div>
            <div className="col">
              <a href="tel:114">
                <img
                  src={require("assets/images/Hot-114.svg")}
                  className="img-fluid w-100"
                  alt="114"
                />
              </a>
            </div>
            <div className="col">
              <a href="tel:115">
                <img
                  src={require("assets/images/Hot-115.svg")}
                  className="img-fluid w-100"
                  alt="115"
                />
              </a>
            </div>
            <div className="col">
              {RemoveNoneNumberFromString(props.ubndPhone) && (
                <a href={`tel:${RemoveNoneNumberFromString(props.ubndPhone)}`}>
                  <img
                    src={require("assets/images/Hot-UBND_phuong.svg")}
                    className="img-fluid w-100"
                    alt="UBND Phuong"
                  />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="su-co-trong-khu">
          <div className="row mt-4">
            <div className="col-8">
              <h4>Sự cố trong khu</h4>
            </div>
            <div className="col-4 text-right">
              <Link to="/m/an-toan/su-co-trong-khu" className="read-more">
                Xem thêm
              </Link>
            </div>
          </div>
          <div className="incident-list">
            <div className="row">
              <div className="col-12">
                <Slider {...settings}>
                  {listIncidents.map((data, index) => (
                    <IncidentList data={data} key={index} />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <a href="https://moh.gov.vn/" target="_blank">
            <img
              src={require("assets/images/theo-doi-dich-benh.png")}
              alt="Theo dõi tình hình dịch bệnh"
              className="img-fluid"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

const IncidentList = (props) => {
  const data = props.data;
  return (
    <div className="incident-item">
      <Link to={`/m/an-toan/su-co-trong-khu/${data.id}`}>
        <img
          src={data.url}
          alt="Image"
          onError={SetDefaultErrorImage}
          className="img-fluid img-incident"
        />
      </Link>
      <div className="item-body p-2">
        <h5 className="text-truncate">
          <Link to={`/m/an-toan/su-co-trong-khu/${data.id}`}>
            {data.content}
          </Link>
        </h5>
        <div className="row no-gutters">
          <div className="col-7">
            <span className="datetime">{data.time}</span>
          </div>
          <div className="col-5 text-right">
            {!data.solved ? (
              <span className="text-primary status">Đang xử lý</span>
            ) : (
              <span className="status text-success">Đã xử lý</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  incidentNews: state.incidentsArea.incidentNews,
  buildingManagementModel: state.buildingManagement.buildingManagementModel,
  ubndPhone: state.contentByKeyword.ubndPhoneNumber,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetIncidentAreasDesktopNew: IncidenAreaStore.GetIncidentAreasDesktopNew,
      getBuildingManagementDetail:
        buidingManagementActions.GetBuildingManagementDetail,
      GetUBNDPhone: GetcontentByKeywordActions.GetUBNDPhone,
      PromiseAllTools,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SafeMainMobileView);
