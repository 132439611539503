import React from 'react';
import '../navigation/navigation.scss';
import DrawerToggleButton from '../side-drawer/drawer-toggle-button';
import { NavLink, Link } from 'react-router-dom';
import RenderNotificationBell from '../header/notification-bell.view.jsx';
import { isMobile, isTablet } from 'react-device-detect';
import * as PageUrls from '../../modules/PageUrls';

function changeMbSearchControl() {
  let search = document.getElementsByClassName("mb-search-control")[0];
  let logo = document.getElementById('logomobiledevice');
  search.classList.toggle('hideSearch');
  search.classList.toggle('animationSearchAppear');
  logo.classList.toggle('hideSearch');
}

function isActiveParentMenu(paths) {
  let pathArr = paths || [];
  return pathArr.some(path => {
    return window.location.pathname.indexOf(path) != -1;
  })
}

const NavigationView = props => (
  <nav className="navigation">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className={"toolbar__toggle-button" + ((isMobile || isTablet) ? "" : " d-none")}>
            <div className="row no-gutters">
              <div className="col-2">
                <DrawerToggleButton click={props.drawerClickHandler} />
              </div>
              <div className="col-8 text-center">
                <Link to="/m">
                  <img src={require('assets/images/iLang_logo_mobile.svg')} alt="Logo mobile" id='logomobiledevice' className="img-fluid" />
                </Link>
                <div className="mb-search-control hideSearch">
                  <input type="text" className="form-control" placeholder="Tìm kiếm" />
                  <img src={require('assets/images/search-gray.svg')} alt="Tìm kiếm" />
                </div>
              </div>
              <div className="col-2 text-center">
                <Link to="/m/tim-kiem">
                  <img src={require('assets/images/search-white.svg')} alt="Search white" onClick={changeMbSearchControl} className="img-fluid" />
                </Link>
                <RenderNotificationBell forMobile={true} />
              </div>
            </div>
          </div>

          <ul className={"list-inline" + ((isMobile || isTablet) ? " d-none" : "")}>
            <li className="list-inline-item">
              <NavLink exact activeClassName="active" to="/">Trang chủ</NavLink>
            </li>
            <li className="list-inline-item">
              <NavLink exact activeClassName="active" to="/thong-tin-bql">BQL</NavLink>
            </li>
            <li className="list-inline-item">
              <NavLink exact activeClassName="active" to="/hot">HOT!</NavLink>
            </li>

            <li className="list-inline-item">
              <a className={isActiveParentMenu(['/thanh-toan/tien-dien', '/thanh-toan/tien-nuoc', '/thanh-toan/internet', '/thanh-toan/dich-vu-khac']) ? 'active' : ''}>Thanh toán <img src={require('assets/images/drop-down.svg')} alt="Drop menu" className="img-fluid" /></a>
              <ul className="child-menu">
                <li>
                  <NavLink exact activeClassName="active" to="/thanh-toan/tien-dien">Tiền điện</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/thanh-toan/tien-nuoc">Tiền nước</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/thanh-toan/internet">Internet</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/thanh-toan/dich-vu-khac">Dịch vụ khác</NavLink>
                </li>
              </ul>
            </li>

            <li className="list-inline-item">
              <a className={isActiveParentMenu(['/tien-ich/the-thao', '/tien-ich/phong-kham', '/tien-ich/lam-dep', '/tien-ich/nha-hang', '/tien-ich/cafe', '/tien-ich/sieu-thi', '/tien-ich/truong-hoc', '/tien-ich/tien-ich-khac']) ? 'active' : ''}>
                Tiện ích <img src={require('assets/images/drop-down.svg')} alt="Drop menu" className="img-fluid" />
              </a>
              <ul className="child-menu">
                <li className="list-child-menu">
                  <NavLink exact activeClassName="active" to={PageUrls.UtilitiesSportHomeDesktop}>Thể thao</NavLink>
                  <img src={require('assets/images/arrow-right-black.svg')} alt="Arrow Right" className="img-fluid" />
                  <ul>
                    <li>
                      <Link to={PageUrls.UtilitiesPoolDeskTop}>Bể bơi</Link>
                    </li>
                    <li>
                      <Link to={PageUrls.UtilitiesGymDeskTop}>Gym</Link>
                    </li>
                    <li>
                      <Link to={PageUrls.UtilitiesTennisDeskTop}>Tennis</Link>
                    </li>
                    <li>
                      <Link to={PageUrls.UtilitiesSoccerDeskTop}>Sân bóng</Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <NavLink exact activeClassName="active" to="/tien-ich/phong-kham">Phòng khám</NavLink>
                </li> */}
                <li>
                  <NavLink exact activeClassName="active" to={PageUrls.UtilitiesSpaHomeDeskTop}>Làm đẹp</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/tien-ich/nha-hang">Nhà hàng</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/tien-ich/cafe">Cafe</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/tien-ich/sieu-thi">Siêu thị</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/tien-ich/truong-hoc">Trường học</NavLink>
                </li>
                {/* <li>
                  <NavLink exact activeClassName="active" to="/tien-ich/tien-ich-khac">Tiện ích khác</NavLink>
                </li> */}
              </ul>
            </li>
            <li className="list-inline-item">
              <a className={isActiveParentMenu(['/lang-gieng-gan/ket-ban', '/lang-gieng-gan/giup-mot-tay', '/lang-gieng-gan/chung-tay']) ? 'active' : ''}>
                Láng giềng gần <img src={require('assets/images/drop-down.svg')} alt="Drop menu" className="img-fluid" />
              </a>
              <ul className="child-menu">
                {/* <li>
                  <NavLink exact activeClassName="active" to="/lang-gieng-gan/ket-ban">Kết bạn</NavLink>
                </li> */}
                <li>
                  <NavLink exact activeClassName="active" to="/lang-gieng-gan/cau-lac-bo">Câu lạc bộ</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/lang-gieng-gan/giup-mot-tay">Giúp một tay</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/lang-gieng-gan/chung-tay">Chung tay</NavLink>
                </li>
              </ul>
            </li>
            <li className="list-inline-item">
              <a className={isActiveParentMenu(['/rao-vat/cho-cu-dan', '/rao-vat/giup-viec-theo-gio', '/rao-vat/di-cho-thue', '/rao-vat/mua-ban-nha']) ? 'active' : ''}>Chợ làng <img src={require('assets/images/drop-down.svg')} alt="Drop menu" className="img-fluid" /></a>
              <ul className="child-menu">
                <li>
                  <NavLink exact activeClassName="active" to="/rao-vat/cho-cu-dan">Rao vặt</NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to="/rao-vat/giup-viec-theo-gio">Giúp việc theo giờ</NavLink>
                </li>
                {/* <li>
                  <NavLink exact activeClassName="active" to="/rao-vat/di-cho-thue">Đi chợ thuê</NavLink>
                </li> */}
                <li>
                  <NavLink exact activeClassName="active" to="/rao-vat/mua-ban-nha">Mua, bán & cho thuê nhà</NavLink>
                </li>
              </ul>
            </li>
            {/* <li className="list-inline-item">
              <NavLink exact activeClassName="active" to="/an-toan">An toàn</NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  </nav>
);

export default NavigationView;
