import { NewGuid } from 'utils/configuration';

export const BuildingManagements = [
    {
        building_management_id: NewGuid(),
        city_name: 'Công ty TNHH Savills Việt Nam - Ban QLVH Khu đô thị Splendora',
        address: 'Tầng 1, tòa 101 Chung cư KĐT Splendora Bắc An Khánh, An Khánh, Hoài Đức, Hà Nội',
        hotline: '091.680.8336',
        email: 'bqpsplendora@savills.com.vn',
        website: 'www.savills.com.vn',
        customer_id: "0123456789"
    },
    {
        customer_id: NewGuid(),
        building_management_id: NewGuid(),
        city_name: 'Công ty TNHH Savills Việt Nam - Ban QLVH Khu đô thị Splendora',
        address: 'Tầng 1, tòa 101 Chung cư KĐT Splendora Bắc An Khánh, An Khánh, Hoài Đức, Hà Nội',
        hotline: '091.680.8336',
        email: 'bqpsplendora@savills.com.vn',
        website: 'www.savills.com.vn',
    },
];