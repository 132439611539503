/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Error } from 'components/support-create-form/validate-style';
import AddNotification from 'components/react-notifications/react-notifications';
import * as residentMarketActions from 'redux/stores/resident/resident-market.store';
import { NewGuid, ValidImageTypes, convertTextareaToHtml, convertHtmlToTextarea, validateFile, CheckMaxFile, NotificationMessageType } from 'utils/configuration';
import { history } from 'redux/store';
import { JwtHelper } from "utils/jwt-helper";
import { Link } from 'react-router-dom';
import './edit-resident-market-mobile.scss';

function UpdateResidentMarketForm(props) {
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange"
  });

  const [previewActtachments, setPreviewActtachment] = useState(props.model.adFile || []);
  const [adFiles, setAdFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [validateImgMessage, setValidateImgMessage] = useState("");

  let intialValues = {
    content: convertHtmlToTextarea(props.model.content),
  };

  const onSubmit = data => {
    if (!adFiles.length && !previewActtachments.length) {
      return;
    }
    setValidateImgMessage('');

    data.ad_file = [...adFiles];
    data.delete_file = [...deleteFiles];
    data.content = convertTextareaToHtml(data.content);
    props.onUpdateResidentMarket && props.onUpdateResidentMarket(data);
    props.onCloseEditPopup && props.onCloseEditPopup(false);
  };

  const onFileChange = (event) => {
    let files = Array.from(event.target.files);
    if (files && files.length) {
      // validate maximum length
      var validateMsg = CheckMaxFile(adFiles.length + files.length);
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      // validate file
      files.map(file => {
        if (validateFile(file)) {
          validateMsg = validateFile(file);
        }
      });
      if (validateMsg) {
        setValidateImgMessage(validateMsg);
        return;
      }

      setValidateImgMessage('');

      var previewImgs = [];
      var imgFiles = [];
      files.map(file => {
        var reader = new FileReader();
        reader.onload = (e) => {
          previewImgs = [...previewImgs, {
            fileUrl: e.target.result,
            fileName: file.name,
            fileId: NewGuid(),
            fileType: ValidImageTypes.includes(file.type) ? 'image' : file.type,
          }];
          setPreviewActtachment([...previewActtachments, ...previewImgs]);
        };
        reader.readAsDataURL(file);
        imgFiles = [...imgFiles, file];
        setAdFiles([...adFiles, ...imgFiles]);
      });
    }
  };

  const onRemovedAttachment = (fileId) => {
    setPreviewActtachment(previewActtachments.filter(att => att.fileId != fileId));
    setDeleteFiles([...deleteFiles, fileId]);
  }

  return (
    <div className="edit-resident-mobile">
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m/rao-vat/cho-cu-dan">
                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
              </Link>
              <div className="header-title text-center">Thêm sản phẩm</div>
            </div>
          </div>
        </div>
      </header>
      <form className="edit-resident-market-mobile p-3" onSubmit={handleSubmit(onSubmit)} >
        <textarea defaultValue={intialValues.content} name="content" className="form-control" placeholder="Mô tả sản phẩm" rows="5"
          ref={register({
            required: 'Trường bắt buộc',
            minLength: {
              value: 6,
              message: "Nội dung phải có ít nhất 6 ký tự"
            }
          })} ></textarea>
        {errors.content && <Error content={errors.content.message} />}
        <div className="resident-market-upload">
          <div className="row">
            {
              previewActtachments.map(att =>
                <div key={att.fileId} className="col-4 position-relative mt-3 attachment-wrapper">
                  <img src={att.fileUrl} alt={att.fileName} className="img-resident-market img-fluid" />
                  <div className="overlay"></div>
                  <img onClick={() => onRemovedAttachment(att.fileId)} src={require("assets/images/close-white.svg")} alt="close-white" className="img-close-white" />
                </div>)
            }
          </div>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-6 file-upload">
            <div className="title-upload">
              <img src={require('assets/images/collections.png')} alt="Image" className="img-fluid" /> <span>Upload ảnh</span>
            </div>
            <input type="file" name="ad_file" accept="image/*" multiple className="media-file" onChange={onFileChange.bind(this)} ref={register({ required: { value: !previewActtachments.length, message: 'Trường bắt buộc' } })} />
            {errors.ad_file && <Error content={errors.ad_file.message} />}
            {validateImgMessage && <Error content={validateImgMessage} />}
          </div>
          <div className="col-6 text-right">
            <button type="submit" className="btn btn-success w-100">Lưu chỉnh sửa</button>
          </div>
        </div>
      </form>
    </div>
  );
}

class EditResidentMarketMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
      redientMarketPost: null,
      residentMarketId: this.props.match.params.residentMarketId,
    }
  }

  UNSAFE_componentWillMount() {
    this.onGetResidentMarketPostDetail();
  }

  onGetResidentMarketPostDetail = () => {
    if (this.state.residentMarketId) {
      this.props.getResidentMarketDetail(this.state.residentMarketId).then(res => {
        if (res.content) {
          this.setState({
            redientMarketPost: res.content
          })
        } else {
          history.push('/rao-vat/cho-cu-dan');
        }
      }, err => { 
      })
    }
  }

  onUpdateResidentMarket = (data) => {
    let submitedData = {
      ...data,
      ad_id: this.state.residentMarketId,
      customer_id: this.state.currentUser.customerId,
      comments: this.state.redientMarketPost.comments
    }
    this.props.createOrUpdateResidentMarket(submitedData).then(() => {
      AddNotification('Cập nhật bài viết thành công!', NotificationMessageType.Success);
      history.push('/m/rao-vat/cho-cu-dan');
    }, () => {
      this.props.onToggleEditPopup(false);
    })
  }

  render() {
    const { redientMarketPost } = this.state;

    return (
      <div className="resident-editting">
        {redientMarketPost && <UpdateResidentMarketForm onUpdateResidentMarket={this.onUpdateResidentMarket.bind(this)} model={redientMarketPost} />}
      </div>
    )
  }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createOrUpdateResidentMarket: residentMarketActions.CreateOrUpdateResidentMarket,
  getResidentMarketDetail: residentMarketActions.GetResidentMarketDetail,
},
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(EditResidentMarketMobileView)

