import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../management-desktop.scss';
import * as legalActions from '../../../redux/stores/management/legal.store';
import { Link } from 'react-router-dom';
import DocumentRendering from 'components/document-rendering/document-rendering';

class LegalSectionDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalList: [],
      isLoading: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetLegalList();
  }

  onGetLegalList = (pageIndex = 1, size = 4) => {
    this.setState({ isLoading: true });
    this.props.getTop4LegalList(pageIndex, size).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => { 
        this.setState({ isLoading: false });
      }
    );
  };

  render() {
    const { isLoading } = this.state;
    const { top4Legals } = this.props;
    return (
      <div className="legal-section">
        {isLoading && <div className="loading-icon"></div>}
        <div className="row no-gutters">
          <div className="col-3">
            <h2 className="title-lg mt-4">Văn bản pháp lý</h2>
          </div>
          <div className="col-9 position-relative text-right pt-3">
            <Link to="/van-ban-phap-ly">Xem thêm</Link>
            <div className="management-border w-100"></div>
          </div>
        </div>

        <div className="row">
          {top4Legals.map(item => (
            <div className="col-3" key={item.id}>
              <DocumentRendering
                file={item}
                iconUrl={require('assets/images/pdf-icon.png')}
                redirectUrl={`/van-ban-phap-ly/${item.id}`}
              />
            </div>
          ))}
          {!top4Legals.length && <div className="col-12">Không có bản ghi</div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  top4Legals: state.legal.top4Legals
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTop4LegalList: legalActions.GetTop4LegalList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalSectionDesktopView);
