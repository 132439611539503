import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import 'react-datepicker/dist/react-datepicker.css';
import * as memberActions from 'redux/stores/management/member.store';
import * as scheduleActions from 'redux/stores/management/schedule.store';
import * as viVN from 'translation/vi-VN.json';
import { history } from 'redux/store';
import AddNotification from 'components/react-notifications/react-notifications';
import { NotificationMessageType } from 'utils/configuration';
import DatePicker from 'react-datepicker';
import { Table } from 'react-bootstrap';
import moment from 'moment';
moment.locale('vi');
import PaginationControl from 'components/pagination/pagination.view';
import { Link } from 'react-router-dom';
import './member-info-mobile.scss';

class MemberInfoMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member: null,
            agentId: this.props.match.params.agentId,
            startDate: new Date(),
            endDate: new Date(),
            currentPageIndex: 1,
            totalItems: 0,
            schedules: []
        }
    }

    UNSAFE_componentWillMount() {
        const filterObject = {
            from: this.state.startDate,
            to: this.state.endDate,
        };
        this.onGetMember(this.state.agentId, filterObject);
    }

    onGetMember = (agentId, filterObj = null) => {
        this.props.getMember(agentId, filterObj).then(res => {
            if (res.content) {
                this.setState({
                    member: res.content
                })
                this.onGetScheduleForAgent(this.state.currentPageIndex, 10, {})
            } else {
                AddNotification(viVN.Errors[res.errorType], NotificationMessageType.Error);
                history.push('/thanh-vien-bql');
            }
        }, err => { 
        })
    }

    onGetScheduleForAgent(pageNumber, filterObj) {
        this.props.getScheduleListForAgent(pageNumber, 10, { ...filterObj, agent_id: this.state.agentId }).then(res => {
            this.setState({
                schedules: res.content && res.content.items || [],
                totalItems: res.content && res.content.totalItemCount || 0
            })
        }, err => { 
        })
    }

    handleChangeStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    handleChangeEndDate = date => {
        this.setState({
            endDate: date
        });
    };

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetScheduleForAgent(pageNumber);
        })
    }

    handleChangeScheduleList = () => {
        const filterObject = {
            from_date: moment(this.state.startDate).format('YYYY-MM-DD'),
            to_date: moment(this.state.endDate).format('YYYY-MM-DD'),
        };
        this.onGetScheduleForAgent(this.state.currentPageIndex, filterObject);
    }
    render() {
        const { startDate, endDate, member, totalItems, schedules, currentPageIndex } = this.state;

        return (

            <div className="member-info-mobile" >
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/thong-tin-bql/0">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">BQL</div>
                            </div>
                        </div>
                    </div>
                </header>

                {member && <div className="bg-white p-3">
                    <div className="row no-gutters align-items-center">
                        <div className="col-2">
                            <img src={require("assets/images/29-12.png")} className="img-fluid img-avatar" alt="Avatar" />
                        </div>
                        <div className="col-10 pl-3">
                            <h5>{member.agentName}</h5>
                            {member.roleName && <span>[{member.roleName}]</span>}
                            <br />
                            <span>SĐT: <a href={`tel:${member.mobileNumber}`}>{member.mobileNumber}</a></span>
                        </div>
                        <div className="col-12"></div>
                    </div>
                </div>}

                <div className="mobile-divide"></div>

                <div className="bg-white p-3">
                    <div className="row no-gutters">
                        <div className="col-1"></div>
                        <div className="col-4 position-relative pr-1">
                            <DatePicker selected={startDate} onChange={this.handleChangeStartDate} className="form-control rounded-pill" dateFormat="dd/MM/yyyy" />
                            <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
                        </div>
                        <div className="col-4 position-relative pr-1">
                            <DatePicker selected={endDate} onChange={this.handleChangeEndDate} className="form-control rounded-pill" dateFormat="dd/MM/yyyy" />
                            <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid img-calendar" />
                        </div>
                        <div className="col-3 text-right">
                            <button className="btn btn-success rounded-pill w-100" onClick={this.handleChangeScheduleList.bind(this)}>
                                <img src={require("assets/images/search-white.svg")} alt="Search White" className="img-search-white" /> Tìm
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Người trực</th>
                                        <th>Thời gian trực</th>
                                        <th>Nhiệm vụ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {schedules.map(item => <tr key={item.agentId}>
                                        <td>
                                            <h5 className="mb-0 align-middle text-capitalize">{member.agentName}</h5>
                                            <span>{item.mobileNumber}</span>
                                        </td>
                                        <td className="align-middle">{item.shiftName} - {item.workDate}</td>
                                        <td className="align-middle">{item.mission}</td>
                                    </tr>)}
                                    {!schedules.length && <tr><td className="text-center" colSpan="3">Không tìm thấy dữ liệu</td></tr>}
                                </tbody>
                            </Table>
                            <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    member: state.member.member,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getMember: memberActions.GetMemberDetail,
            getScheduleListForAgent: scheduleActions.GetScheduleListForAgent,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MemberInfoMobileView);