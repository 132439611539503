import { NewGuid, randomDate } from 'utils/configuration';

export const ResidentMarkets = [
    {
        post_id: NewGuid(),
        created_by: 'Tài Nguyễn',
        created_by_id: 1,
        created_date: randomDate(new Date(2020, 0, 1), new Date()),
        post_content: `Em về quê thu hoạch rau giúp bà nội. Rau và cà chua sạch 100% ạ. Bác nào ăn nhắn em ship ạ.<br>Rau cải ngọt : 15k/ kg<br>Cà chua: 18k/kg<br>Free ship Splendora ạ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        attachments: [
            {
                attachment_url: require('assets/images/ca-chua.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
        ],
        comments: [
            {
                comment_id: NewGuid(),
                comment_content: 'Bán cho cái quần',
                comment_by: 'Việt Nguyễn',
                comment_by_id: 2,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2020, 0, 1), new Date()),
                replies: [
                    {
                        reply_id: NewGuid(),
                        reply_content: '100k',
                        reply_by_id: 1,
                        reply_by: 'Tài Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2020, 0, 1), new Date()),
                        reply_for_id: 2,
                        reply_for: 'Việt Nguyễn',
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Free ship k?',
                        reply_by_id: 2,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2020, 0, 1), new Date()),
                        reply_for_id: 2,
                        reply_for: 'Việt Nguyễn',
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'KHÔNG!',
                        reply_by_id: 1,
                        reply_by: 'Tài Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2020, 0, 1), new Date()),
                        reply_for_id: 2,
                        reply_for: 'Việt Nguyễn',
                    },
                ]
            },
            {
                comment_id: NewGuid(),
                comment_content: 'Báo công an',
                comment_by: 'Mão Nguyễn',
                comment_by_id: 2,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2020, 0, 1), new Date()),
                replies: [
                    {
                        reply_id: NewGuid(),
                        reply_content: '???',
                        reply_by_id: 1,
                        reply_by: 'Tài Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2020, 0, 1), new Date()),
                        reply_for_id: 2,
                        reply_for: 'Việt Nguyễn',
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Mua cỏ không???',
                        reply_by_id: 2,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2020, 0, 1), new Date()),
                        reply_for_id: 2,
                        reply_for: 'Việt Nguyễn',
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: '1 kg!',
                        reply_by_id: 1,
                        reply_by: 'Mão Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2020, 0, 1), new Date()),
                        reply_for_id: 2,
                        reply_for: 'Việt Nguyễn',
                    },
                ]
            },
        ],
    },
    {
        post_id: NewGuid(),
        created_by: 'Việt Nguyễn',
        created_by_id: 2,
        created_date: randomDate(new Date(2019, 0, 1), new Date()),
        post_content: `Em về quê thu hoạch rau giúp bà nội. Rau và cà chua sạch 100% ạ. Bác nào ăn nhắn em ship ạ.<br>Rau cải ngọt : 15k/ kg<br>Cà chua: 18k/kg<br>Free ship Splendora ạ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        attachments: [
            {
                attachment_url: require('assets/images/vuon-rau.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
            {
                attachment_url: require('assets/images/ca-chua.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
        ],
        comments: [
            {
                comment_id: NewGuid(),
                comment_content: 'Nhiêu tiền 1 kí?',
                comment_by: 'Hậu Nguyễn',
                comment_by_id: 3,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2019, 0, 1), new Date()),
                replies: [
                    {
                        reply_id: NewGuid(),
                        reply_content: '100k',
                        reply_by_id: 1,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2019, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Ăn xin vài kí với, huhu',
                        reply_by_id: 2,
                        reply_by: 'Mão Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2019, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Ăn xin đi chỗ khác chơi!',
                        reply_by_id: 1,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2019, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Biến!',
                        reply_by_id: 3,
                        reply_by: 'Hậu Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2019, 0, 1), new Date()),
                    },
                ]
            },
        ],
    },
    {
        post_id: NewGuid(),
        created_by: 'Mão Nguyễn',
        created_by_id: 4,
        created_date: randomDate(new Date(2018, 0, 1), new Date()),
        post_content: `Em về quê thu hoạch rau giúp bà nội. Rau và cà chua sạch 100% ạ. Bác nào ăn nhắn em ship ạ.<br>Rau cải ngọt : 15k/ kg<br>Cà chua: 18k/kg<br>Free ship Splendora ạ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        attachments: [
            {
                attachment_url: require('assets/images/vuon-rau.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
            {
                attachment_url: require('assets/images/thu-hoach-rau.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
            {
                attachment_url: require('assets/images/rau-cai.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
        ],
        comments: [
            {
                comment_id: NewGuid(),
                comment_content: 'Nhiêu tiền 1 kí?',
                comment_by: 'Hậu Nguyễn',
                comment_by_id: 3,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2018, 0, 1), new Date()),
                replies: [
                    {
                        reply_id: NewGuid(),
                        reply_content: '100k',
                        reply_by_id: 1,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2018, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Ăn xin vài kí với, huhu',
                        reply_by_id: 2,
                        reply_by: 'Mão Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2018, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Ăn xin đi chỗ khác chơi!',
                        reply_by_id: 1,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2018, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Biến!',
                        reply_by_id: 3,
                        reply_by: 'Hậu Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2018, 0, 1), new Date()),
                    },
                ]
            },
        ],
    },
    {
        post_id: NewGuid(),
        created_by: 'Hậu Nguyễn',
        created_by_id: 3,
        created_date: randomDate(new Date(2017, 0, 1), new Date()),
        post_content: `Em về quê thu hoạch rau giúp bà nội. Rau và cà chua sạch 100% ạ. Bác nào ăn nhắn em ship ạ.<br>Rau cải ngọt : 15k/ kg<br>Cà chua: 18k/kg<br>Free ship Splendora ạ`,
        is_delete: false,
        profile_url: require('assets/images/29-12.png'),
        attachments: [
            {
                attachment_url: require('assets/images/vuon-rau.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
            {
                attachment_url: require('assets/images/ca-chua.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
            {
                attachment_url: require('assets/images/thu-hoach-rau.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
            {
                attachment_url: require('assets/images/rau-cai.jpg'),
                attachment_title: '',
                attactment_id: NewGuid(),
                attachment_type: 'image'
            },
        ],
        comments: [
            {
                comment_id: NewGuid(),
                comment_content: 'Nhiêu tiền 1 kí?',
                comment_by: 'Tài Nguyễn',
                comment_by_id: 1,
                commenter_profile_url: require('assets/images/29-12.png'),
                comment_date: randomDate(new Date(2017, 0, 1), new Date()),
                replies: [
                    {
                        reply_id: NewGuid(),
                        reply_content: '100k',
                        reply_by_id: 1,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2017, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Ăn xin vài kí với, huhu',
                        reply_by_id: 2,
                        reply_by: 'Mão Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2017, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Ăn xin đi chỗ khác chơi!',
                        reply_by_id: 1,
                        reply_by: 'Việt Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2017, 0, 1), new Date()),
                    },
                    {
                        reply_id: NewGuid(),
                        reply_content: 'Biến!',
                        reply_by_id: 3,
                        reply_by: 'Hậu Nguyễn',
                        replier_profile_url: require('assets/images/29-12.png'),
                        reply_date: randomDate(new Date(2017, 0, 1), new Date()),
                    },
                ]
            },
        ],
    },
];

export const AdHouseCategoryModels = [
    {
        id: 1,
        name: 'Nhà cần bán'
    },
    {
        id: 2,
        name: 'Nhà cho thuê'
    },
    {
        id: 3,
        name: 'Căn hộ cần bán'
    },
    {
        id: 4,
        name: 'Căn hộ cho thuê'
    },
];
