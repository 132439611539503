/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Select from "react-select";

import * as lendingEquipmentActions from "redux/stores/lending-equipment/lending-equipment.store";
import * as appActions from "core/app.store";
import PaginationControl from "components/pagination/pagination.view.jsx";
import SharedService from "services/share.service.js";
import {
  convertTextareaToHtml,
  ConvertNumberToString,
} from "utils/configuration";

import "./lending-equipment-desktop.scss";

const sharedService = new SharedService();

class LendingEquipmentDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      productList: [],
      selectedOption: null,
      search: "",
      currentPageIndex: 1,
      currentPageSize: 9,
      totalItems: 0,
      privacyPolicy: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.onGetDropdownData();
  }

  onGetDropdownData = () => {
    this.props.showLoading(true);
    Promise.all([
      sharedService.getListCategoryProduct(),
      this.onGetProductListPromise(),
      this.onGetContentByKeyword(),
    ])
      .then((res) => {
        const [categoryModels, productModels, privacyPolicyModel] = res;
        let searchByType = new URLSearchParams(this.props.location.search).get(
          "searchByType"
        );
        let categorySearch =
          searchByType &&
          categoryModels.content &&
          categoryModels.content.items &&
          categoryModels.content.items.length > 0
            ? categoryModels.content.items.find(
                (item) => item.categoryName == searchByType
              )
            : null;
        categorySearch &&
          this.handleChangeSelect({
            ...categorySearch,
            label: categorySearch.categoryName,
            value: categorySearch.categoryId,
          });

        this.setState({
          categoryList:
            (categoryModels.content &&
              categoryModels.content.items &&
              categoryModels.content.items.length > 0 &&
              categoryModels.content.items.map((item) => {
                return {
                  ...item,
                  label: item.categoryName,
                  value: item.categoryId,
                };
              })) ||
            [],
          productList:
            (productModels.content &&
              productModels.content.items &&
              productModels.content.items) ||
            [],
          totalItems:
            (productModels.content &&
              productModels.content.totalItemCount &&
              productModels.content.totalItemCount) ||
            0,
          privacyPolicy: privacyPolicyModel.content,
        });

        this.props.showLoading(false);
      })
      .catch((err) => {
        this.props.showLoading(false);
      });
  };

  onGetProductListPromise = (pageIndex = 1, size = 9, filterObjs = null) => {
    return new Promise((resolve, reject) => {
      this.props.onGetProductList(pageIndex, size, filterObjs).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  onGetContentByKeyword = () => {
    return new Promise((resolve, reject) => {
      this.props.onGetContentByKeyword().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  onGetProductList = (pageIndex = 1, size = 9, filterObjs = null) => {
    this.props.showLoading(true);
    this.props.onGetProductList(pageIndex, size, filterObjs).then(
      (res) => {
        this.setState({
          productList:
            (res.content && res.content.items && res.content.items) || [],
        });
        this.props.showLoading(false);
      },
      (err) => {
        this.props.showLoading(false);
      }
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        this.onGetProductList(e, this.state.currentPageSize);
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  handleChangeSelect = (selectedOption) => {
    this.setState({ selectedOption });
    this.onGetProductList(
      this.state.currentPageIndex,
      this.state.currentPageSize,
      {
        product_name: this.state.search,
        category_id: selectedOption && selectedOption.value,
      }
    );
  };

  handleChange(event) {
    this.setState({ search: event.target.value });
  }

  handleSubmit(event) {
    const {
      search,
      selectedOption,
      currentPageIndex,
      currentPageSize,
    } = this.state;
    this.onGetProductList(currentPageIndex, currentPageSize, {
      product_name: search,
      category_id: selectedOption && selectedOption.value,
    });
    event.preventDefault();
  }

  render() {
    const {
      categoryList,
      productList,
      selectedOption,
      search,
      currentPageIndex,
      currentPageSize,
      totalItems,
      privacyPolicy,
    } = this.state;

    return (
      <div className="lending-equipment-desktop">
        <h2 className="title">Cho thuê, mượn đồ</h2>

        <form
          onSubmit={this.handleSubmit}
          className="search-lending-equipment mt-3 mb-4"
        >
          <div className="row no-gutters align-items-center">
            <div className="col-3">
              <h6 className="mb-0 font-weight-normal">Danh mục đồ cho thuê</h6>
            </div>
            <div className="col-4 pr-4">
              <Select
                value={selectedOption}
                onChange={this.handleChangeSelect}
                options={categoryList}
                noOptionsMessage={() => "Không có dữ liệu"}
                className="slAutocomplete"
                classNamePrefix="select"
                placeholder="Chọn loại đồ"
                isDisabled={!this.props.isLoggedIn}
                isClearable={true}
              />
            </div>
            <div className="col-3">
              <input
                type="text"
                name="search"
                value={search}
                onChange={this.handleChange}
                className="form-control"
                placeholder="Tìm kiếm"
                disabled={!this.props.isLoggedIn}
              />
            </div>
            <div className="col-2 text-right">
              <button
                type="submit"
                className="btn btn-success"
                disabled={!this.props.isLoggedIn}
              >
                Tìm kiếm
              </button>
            </div>
          </div>
        </form>

        <div className="lending-equipment-list">
          <div className="row">
            {productList && productList.length > 0 ? (
              productList.map((item) => (
                <div key={item.productId} className="col-4">
                  <div className="item">
                    <Link to={`/cho-thue-muon-do/${item.productId}`}>
                      <img
                        src={item.productImage}
                        alt={item.productName}
                        className="w-100 img-fluid img-product"
                      />
                    </Link>
                    <div className="body bg-white p-2">
                      <h6 className="equipment-name">
                        <Link to={`/cho-thue-muon-do/${item.productId}`}>
                          {item.productName}
                        </Link>
                      </h6>
                      <h6 className="price-by-hours mb-1 font-weight-normal">
                        {ConvertNumberToString(item.hourPrice)}đ
                        <span>/giờ</span>
                      </h6>
                      <h6 className="price-by-day font-weight-normal">
                        {ConvertNumberToString(item.dayPrice)}đ
                        <span>/ngày</span>
                      </h6>
                      <Link to={`/cho-thue-muon-do/${item.productId}`}>
                        Chi tiết{" "}
                        <img
                          src={require("/assets/images/arrow-right-green.svg")}
                          alt="Arrow Right Green"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">Không có dữ liệu</div>
            )}
          </div>
        </div>

        {productList && productList.length > 0 && (
          <PaginationControl
            pageChanged={this.onSelectPage.bind(this)}
            totalItems={totalItems}
            currentPageIndex={currentPageIndex}
            pageSize={currentPageSize}
            style="border-top mt-0"
          />
        )}

        {privacyPolicy && (
          <div className="lending-equipment-policy mt-3">
            <h5>{privacyPolicy.contentName}</h5>

            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: convertTextareaToHtml(privacyPolicy.content),
              }}
            ></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProductList: lendingEquipmentActions.GetProductList,
      onGetContentByKeyword: lendingEquipmentActions.GetContentByKeyword,
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LendingEquipmentDesktopView);
