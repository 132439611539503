/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
//@ts-check
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import GmapDirect from '../../../../gmap/link-direct';
import ReactTooltip from "react-tooltip";
import './header-detail-restaurant.scss';
import { SetDefaultErrorImage } from "utils/configuration";
import { useRegisterFormRestaurantHook } from '../../../../../components/custom-hook/register-restaurant-form/register-restaurant-form';
import { Error } from "components/support-create-form/validate-style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as viVN from '../../../../../translation/vi-VN.json';

function RestaurantHeaderDetailView(props) {
    const {
        hasRegisted,
        hasShowReserve,
        setHasShowReserve,
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        peopleAmount,
        setPeopleAmount,
        timeReserve,
        setTimeReserve,
        dateReserve,
        setDateReserve,
        handleRegister,
        validateName,
        validatePhoneNumber,
        validatePeopleAmount,
    } = useRegisterFormRestaurantHook(props.data.id, props.registerFunction)

    const settingsSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }

    const [errorHours, setErrorHours] = useState("");

    //--- Validate time
    const today = new Date();
    function onValidateTime(dateReserve, timeReserve) {
        if (timeReserve.getTime() < today.getTime() &&
            dateReserve.getDate() == today.getDate() &&
            dateReserve.getUTCMonth() == today.getUTCMonth() &&
            dateReserve.getUTCFullYear() == today.getUTCFullYear()) {
            setErrorHours("Phải lớn hơn thời gian hiện tại");
        } else {
            setErrorHours("");
        }
    }

    return (
        <div className="header-container-detail">
            <div className="position-relative slide-container">
                <Link to={props.comeBackLink}><img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-arrow-back" /></Link>
            </div>

            <Slider {...settingsSlider}>
                {
                    props.data.imagesSlider.map((imageSlider, index) => <div key={index}>
                        <img className="img-fluid w-100" src={imageSlider.url} />
                    </div>)
                }
                {
                    (props.data.imagesSlider.length === 0) && <div>
                        <img className="img-fluid w-100" src="" onError={SetDefaultErrorImage} />
                    </div>
                }
            </Slider>

            <div className="p-3" hidden={hasShowReserve}>
                <h2>{props.data.name}</h2>
                <div className="row no-gutters">
                    <div className="col-12 mb-3">
                        <div className="address">
                            <img src={require('assets/images/place-red.svg')} alt="Place" className="img-fluid" /> <span className="text-gray">{props.data.address}</span>
                        </div>
                        <div className="open-time text-gray">
                            <img src={require('assets/images/clock-yellow.svg')} alt="Clock Yellow" className="img-fluid" /> Mở cửa: <span>{props.data.timeOpen}</span>
                        </div>
                    </div>

                    <div className="col-3 text-center">
                        <div className="social-icon">
                            <a href={props.data.faceBook} target="_blank"><img src={require('assets/images/facebook.svg')} alt="Place" className="img-fluid" /></a>
                            <br></br>
                            <a href={props.data.faceBook} target="_blank"><span>Facebook</span></a>
                        </div>
                    </div>

                    <div className="col-3 text-center">
                        <div className="social-icon">
                            <a href={`tel:${props.data.phone}`}>
                                <img src={require('assets/images/phone-call-round.svg')} alt="Place" className="img-fluid" />
                            </a>
                            <br></br>
                            <a href={`tel:${props.data.phone}`}><span>Liên hệ</span></a>
                            <ReactTooltip place="bottom" type="dark" effect="float" />
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="social-icon" onClick={() => setHasShowReserve(!hasShowReserve)}>
                            <img src={require('assets/images/table.svg')} alt="Place" className="img-fluid" />
                            <br></br>
                            <span>Đặt bàn</span>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="social-icon">
                            <GmapDirect daddr_lat={props.data.lat} daddr_lng={props.data.lng} content={<img src={require('assets/images/right-turn.svg')} alt="Place" className="img-fluid" />} />
                            <br></br>
                            <GmapDirect daddr_lat={props.data.lat} daddr_lng={props.data.lng} content={<span>Chỉ đường</span>} />
                        </div>
                    </div>
                </div>
                <hr />

                <h3>Giới thiệu</h3>
                <div className="utilities-intro-mobile" dangerouslySetInnerHTML={{ __html: props.data.introduce }}>

                </div>
            </div>
            <div className={` ${props.isLoggedIn ? '' : 'disabled-event'}`} hidden={!hasShowReserve}>
                <div
                    className="form-utilities-detail-mobile container pt-3"
                    hidden={hasRegisted}
                >
                    <h5 className="text-center">Đặt bàn</h5>
                    <div className="form-group">
                        <label className='text-black' htmlFor="userNameInput">Họ tên</label>
                        <input
                            id='userNameInput'
                            value={userName}
                            onChange={(event) => setUserName(event.target.value)}
                            className="form-control"
                            type="text"
                            name="customer_name"
                        />
                        {
                            validateName && <Error content={validateName} />
                        }
                    </div>
                    <div className="form-group">
                        <label className='text-black' htmlFor="phoneNumberInput">Số điện thoại</label>
                        <input
                            id="phoneNumberInput"
                            className="form-control"
                            type="text"
                            name="mobile_number"
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                        />
                        {
                            validatePhoneNumber && <Error content={validatePhoneNumber} />
                        }
                    </div>
                    <div className="row form-group ml-n1 mr-n1">
                        <div className="col-6 pl-1 pr-1">
                            <label className='text-black' htmlFor='peopleAmountInput'>Số người</label>
                            <input
                                id='peopleAmountInput'
                                type="number"
                                name="quantity"
                                min="0"
                                defaultValue={peopleAmount}
                                onMouseLeave={(event) => {
                                    setPeopleAmount(Number(event.target.value))
                                    if (!Number(event.target.value)) event.target.value = 0
                                }}
                                placeholder="Nhập số người"
                                className="form-control"
                            />
                            {
                                validatePeopleAmount && <Error content={validatePeopleAmount} />
                            }
                        </div>
                        <div className="col-6 pl-1 pr-1">
                            <label className='text-black' htmlFor='timePickerInput'>Thời gian đặt</label>
                            <input
                                type="time"
                                className="d-none"
                                value={timeReserve.toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                })}
                                name="intend_time"
                            />
                            <DatePicker
                                id='timePickerInput'
                                selected={timeReserve}
                                value={timeReserve}
                                onChange={(time) => {
                                    setTimeReserve(time);
                                    onValidateTime(dateReserve, time);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                className="form-control rounded-pill"
                                customInput={<CustomInputDatePicker />}
                            />

                            {errorHours && (
                                <p className="text-danger mb-0 mt-1 pl-1">
                                    {errorHours}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="form-group position-relative">
                        <label className='text-black' htmlFor="datepickerPickDate">Chọn ngày đặt</label>
                        <br />
                        <DatePicker
                            id="datepickerPickDate"
                            selected={dateReserve}
                            onChange={(event) => {
                                setDateReserve(event);
                                onValidateTime(event, timeReserve)
                            }}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                        />
                        <img
                            src={require("assets/images/calendar.svg")}
                            alt="calendar"
                            className="img-calendar"
                        />
                    </div>
                    <div>
                        <button className="btn btn-success w-100 rounded-pill mt-3" onClick={() => handleRegister()} disabled={errorHours !== ""}>
                            Đặt bàn
                        </button>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
                {
                    hasRegisted &&
                    (
                        <p className="text-success text-center mt-3">
                            {viVN.Utilities.RegisterSuccess}
                        </p>
                    )
                }
            </div>
        </div>
    )
}

const CustomInputDatePicker = ({ value, onClick }) => (
    <button
        type="button"
        className="form-control rounded-pill text-left"
        onClick={onClick}
    >
        {value}
    </button>
);

export default RestaurantHeaderDetailView