//@ts-check
import React, { useEffect } from 'react';
import HeaderDetailView from '../../child-component/detail-page-childs/header-detail/header-detail.view';
import FooterDetailView from '../../child-component/detail-page-childs/footer-detail/footer-detail.view';
import RegisterCalendar from '../childs/detail-page-childs/register-calendar/register-calendar.view';
import FakeData from '../../../../mock-data/soccer-detail-data';
import FakeSlot from '../../../../mock-data/tennis';
import { connect } from 'react-redux';
import * as PageUrls from '../../../PageUrls';
import * as UtilitiesStore from '../../../../redux/stores/utillities/utilitis-shop.store';
import { bindActionCreators } from 'redux';

/**
 * @param {{isLoggedIn?:boolean,GetShopDetail?:any,PostRegisterUseSoccer?:any,match:any,shopDetail:any,slotList:any}} [props]
*/
function UtilitiesSoccerDetailPageMobile(props) {
    /**
     * @param {{shopId: number,phoneNumber: string,customerName: string,slotId: number,date: string}} paramsObject
    */
    const registerFunction = (paramsObject) => {
        const params = new FormData();
        params.append('data[register_name]', paramsObject.customerName)
        params.append('data[football_id]', String(paramsObject.shopId))
        params.append('data[slot_id]', String(paramsObject.slotId))
        params.append('data[register_date]', paramsObject.date)
        params.append('data[mobile_number]', paramsObject.phoneNumber)
        return props.PostRegisterUseSoccer(params)
    }
    useEffect(() => {
        props.GetShopDetail(props.match.params.id, new Date())
    }, [])
    return (
        <div>
            <HeaderDetailView data={props.shopDetail} comeBackLink={PageUrls.UtilitiesSportHomeMobile} />
            <RegisterCalendar handleGetCalendarByDate={props.GetShopDetail} registerFunction={registerFunction} isLoggedIn={props.isLoggedIn} shopId={props.shopDetail.id} slotList={props.slotList} />
            <FooterDetailView listImage={props.shopDetail.listImage} />
        </div>
    )
}


const mapStateToProps = state => ({
    slotList: state.utilitiShop.shopDetailWithCalendar.slotList,
    shopDetail: state.utilitiShop.shopDetailWithCalendar,
    isLoggedIn: state.auth.isLoggedIn,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiesStore.GetSoccerDetail,
    PostRegisterUseSoccer: UtilitiesStore.PostRegisterUseSoccer,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesSoccerDetailPageMobile)
