/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NotificationMessageType,
  TokenKey,
  NewGuid,
  CheckFileSize,
  CheckMaxFile,
  CheckFileTypeMedia,
  CheckFileTypeAttachment,
  getCookies
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import * as feedbackActions from "redux/stores/feedback/feedback.store";
import * as projectActions from "redux/stores/project/project.store";
import { JwtHelper } from "utils/jwt-helper";

import AsyncPaginate from "react-select-async-paginate";

class GeneralSuggestionsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      houseList: [],
    };
  }

  UNSAFE_componentWillMount() {
    const project = getCookies(TokenKey.Project);
    project && this.onGetApartmentList(project);
  }

  onGetApartmentList = (id) => {
    this.props.onGetApartmentList(id).then(
      (res) => {
        if (res && res.content && res.content.length > 0) {
          this.setState({
            houseList: res.content.map((item) => {
              return {
                value: item.apartmentId,
                label: item.apartmentName,
              };
            }),
          });
        }
      },
      (err) => { }
    );
  };

  render() {
    const { houseList } = this.state;
    const {
      loggedIn,
      feedback,
      currentPage,
      newPage,
      onChangeCurrentPage,
    } = this.props;
    const userInfo = JwtHelper.currentUser();
    return (
      <GeneralSuggestionsForm
        userInfo={userInfo}
        houseList={houseList && houseList.length > 0 && houseList.filter(item => { return userInfo && userInfo.houseNumber && item.label != userInfo.houseNumber && item })}
        isLogin={loggedIn}
        feedback={feedback}
        currentPage={currentPage}
        newPage={newPage}
        onChangeCurrentPage={onChangeCurrentPage}
      />
    );
  }
}

function GeneralSuggestionsForm(props) {
  const {
    houseList,
    userInfo,
    feedback,
    currentPage,
    newPage,
    onChangeCurrentPage,
  } = props;

  const [show, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [errorFile, setErrorFile] = useState({
    status: false,
    maxFile: "",
    maxSize: "",
    typeError: "",
  });

  const [house, setHouse] = useState("");

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if ((errors.title || errors.description) && currentPage != newPage) {
      clearError(["title", "description"]);
      document.getElementById("generalSuggestionForm").reset();
      setFiles([]);
      onChangeCurrentPage(newPage);
    }
    errorFile.status &&
      setTimeout(() => {
        setErrorFile({
          status: false,
          maxFile: "",
          maxSize: "",
          typeError: "",
        });
      }, 3000);
  });

  const onChangeHouse = (data) => {
    if (data) {
      setHouse(data);
      clearError(["houseNumber"]);
    } else {
      setHouse("");
      setError("houseNumber", "required", "Trường này là bắt buộc");
    }
  };

  const loadOptions = (search, prevOptions) => {
    house
      ? clearError(["houseNumber"])
      : setError("houseNumber", "required", "Trường này là bắt buộc");
    let filteredOptions;
    if (!search) {
      filteredOptions = houseList;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = houseList.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  function ReadAttach(e) {
    if (e.target.files) {
      const listFile = Array.from(e.target.files);
      let fileCount = files.length;
      Promise.all(
        listFile.map((file) => {
          return new Promise((resolve, reject) => {
            if (CheckMaxFile(fileCount)) {
              setErrorFile({
                status: true,
                maxFile: CheckMaxFile(fileCount),
              });
              reject({});
            } else if (
              !errorFile.maxFile &&
              !errorFile.maxSize &&
              CheckFileSize(file.size)
            ) {
              setErrorFile({ status: true, maxSize: CheckFileSize(file.size) });
              reject({});
            } else if (
              !errorFile.maxFile &&
              !errorFile.typeError &&
              CheckFileTypeAttachment(file.type)
            ) {
              setErrorFile({
                status: true,
                typeError: CheckFileTypeAttachment(file.type),
              });
              reject({});
            } else {
              if (file.type.match("application")) {
                fileCount++;
                resolve({
                  id: NewGuid(),
                  name: file.name,
                  file: file,
                  url: "/assets/images/pdf-icon.png",
                  type: "application",
                });
              } else {
                setErrorFile({
                  status: true,
                  typeError: CheckFileTypeAttachment(file.type),
                });
                reject({});
              }
            }
          });
        })
      ).then(
        (images) => {
          setFiles([...files, ...images]);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  function ReadUri(e) {
    if (e.target.files) {
      const listFile = Array.from(e.target.files);
      let fileCount = files.length;
      Promise.all(
        listFile.map((file) => {
          return new Promise((resolve, reject) => {
            if (CheckMaxFile(fileCount)) {
              setErrorFile({
                status: true,
                maxFile: CheckMaxFile(fileCount),
              });
              resolve({
                err: true,
              });
            } else if (
              !errorFile.maxFile &&
              !errorFile.maxSize &&
              CheckFileSize(file.size)
            ) {
              setErrorFile({ status: true, maxSize: CheckFileSize(file.size) });
              resolve({
                err: true,
              });
            } else if (
              !errorFile.maxFile &&
              !errorFile.typeError &&
              CheckFileTypeMedia(file.type)
            ) {
              setErrorFile({
                status: true,
                typeError: CheckFileTypeMedia(file.type),
              });
              resolve({
                err: true,
              });
            } else {
              const reader = new FileReader();
              if (file.type.match("image")) {
                fileCount++;
                reader.addEventListener("load", (ev) => {
                  resolve({
                    id: NewGuid(),
                    name: file.name,
                    url: ev.target.result,
                    file: file,
                    err: false,
                    type: "image",
                  });
                });
                reader.addEventListener("error", reject);
                reader.readAsDataURL(file);
              } else if (file.type.match("video")) {
                fileCount++;
                reader.onload = function () {
                  let blob = new Blob([reader.result], {
                    type: file.type,
                  });
                  let url = URL.createObjectURL(blob);
                  let video = document.createElement("video");
                  let timeupdate = function () {
                    if (snapImage()) {
                      video.removeEventListener("timeupdate", timeupdate);
                      video.pause();
                    }
                  };
                  video.addEventListener("loadeddata", function () {
                    if (snapImage()) {
                      video.removeEventListener("timeupdate", timeupdate);
                    }
                  });
                  let snapImage = function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvas
                      .getContext("2d")
                      .drawImage(video, 0, 0, canvas.width, canvas.height);
                    let image = canvas.toDataURL();
                    let success = image.length > 100000;
                    if (success) {
                      resolve({
                        id: NewGuid(),
                        name: file.name,
                        err: false,
                        file: file,
                        url: image,
                        type: "video",
                      });
                      URL.revokeObjectURL(url);
                    }
                    return success;
                  };
                  video.addEventListener("timeupdate", timeupdate);
                  video.preload = "metadata";
                  video.src = url;
                  video.muted = true;
                  video.playsInline = true;
                  video.play();
                };
                reader.readAsArrayBuffer(file);
              } else {
                setErrorFile({
                  status: true,
                  typeError: CheckFileTypeMedia(file.type),
                });
                resolve({
                  err: true,
                });
              }
            }
          });
        })
      ).then(
        (images) => {
          let result = [];
          images.map((image) => {
            if (!image.err) {
              result.push(image);
            }
          });
          setFiles([...files, ...result]);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  function DeleteFile(id) {
    if (id) setFiles(files.filter((x) => x.id != id));
  }

  const onSubmit = (data) => {
    if (userInfo && data) {
      const formData = new FormData();
      files &&
        files.map((file) => {
          if (file.type == "application") {
            file.file && formData.append("inform_doc[]", file.file);
          } else {
            file.file && formData.append("inform_file[]", file.file);
          }
        });
      feedback(
        {
          inform_name: data.title,
          description: data.description,
          inform_type: "private",
          sender_type: "customer",
          sender_id: userInfo.customerId,
          receiver_type: show ? "customer" : "agent",
          apartment_id: show ? data.houseNumber : "",
          type: "SUGESTIONFROMNEIGHBORS",
        },
        formData
      ).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              "Góp ý thành công",
              NotificationMessageType.Success
            );
            document.getElementById("generalSuggestionForm").reset();
            setFiles([]);
          } else {
            res &&
              res.errorType &&
              AddNotification(
                viVN.Errors[res.errorType],
                NotificationMessageType.Error
              );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="generalSuggestionForm">
      <div className="form-group">
        <label>Đối tượng gửi</label>
        <div key={`custom-inline-radio`} className="mb-3">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              name="doi-tuong-gui"
              type="radio"
              id="bql"
              value="bql"
              className="custom-control-input"
              defaultChecked
              onClick={() => setShow(false)}
              disabled={!props.isLogin}
            />
            <label title="" htmlFor="bql" className="custom-control-label">
              BQL
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              name="doi-tuong-gui"
              type="radio"
              id="resident"
              value="resident"
              className="custom-control-input"
              onClick={() => setShow(true)}
              disabled={!props.isLogin}
            />
            <label title="" htmlFor="resident" className="custom-control-label">
              Cư dân
            </label>
          </div>
        </div>
      </div>
      {show && (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>
                Chọn căn hộ<span className="required"></span>
              </label>
              <AsyncPaginate
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Số căn hộ (*)"
                noOptionsMessage={() => "Không có dữ liệu"}
                value={house}
                loadOptions={loadOptions}
                onChange={(data) => onChangeHouse(data)}
              />
              <input
                name="houseNumber"
                type="hidden"
                value={house.value || ""}
                ref={register({
                  required: "Trường này là bắt buộc",
                })}
              />
              {errors.houseNumber && (
                <div className="invalid-feedback">
                  {errors.houseNumber.message}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="form-group">
        <label>
          Tiêu đề<span className="required"></span>
        </label>
        <input
          type="text"
          name="title"
          className={"form-control " + (errors.title ? "is-invalid" : "")}
          placeholder="Nhập tiêu đề"
          disabled={!props.isLogin}
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        ></input>
        {errors.title && (
          <div className="invalid-feedback">{errors.title.message}</div>
        )}
      </div>
      <div className="form-group">
        <label>
          Nội dung<span className="required"></span>
        </label>
        <textarea
          name="description"
          className={"form-control " + (errors.description ? "is-invalid" : "")}
          rows="5"
          placeholder="Nhập nội dung..."
          disabled={!props.isLogin}
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        ></textarea>
        {errors.description && (
          <div className="invalid-feedback">{errors.description.message}</div>
        )}
      </div>
      {files && (
        <div className="album-file">
          {files.map((item, index) => (
            <RenderFilePreview
              data={item}
              DeleteFile={DeleteFile.bind(this)}
              key={item.id}
              index={index}
            />
          ))}
        </div>
      )}

      {errorFile.status && (
        <div className="form-group mb-0">
          <div className="invalid-feedback">{errorFile.maxFile}</div>
          {!errorFile.maxFile && errorFile.maxSize && (
            <div className="invalid-feedback">{errorFile.maxSize}</div>
          )}
          {!errorFile.maxFile && errorFile.typeError && (
            <div className="invalid-feedback">{errorFile.typeError}</div>
          )}
        </div>
      )}
      <div className="row align-items-center">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="file-upload-feedback">
            <div className="title-upload">
              <img
                src={require("/assets/images/collections.png")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <span>Upload ảnh/video</span>
            </div>
            <input
              type="file"
              name="mediaFile"
              accept=".png, .jpg, .jpeg, .mp4"
              multiple
              className="media-file"
              onChange={ReadUri}
              disabled={!props.isLogin}
            />
          </div>
          <div className="file-upload-feedback">
            <div className="title-upload">
              <img
                src={require("/assets/images/paper-clip.png")}
                alt="Image"
                className="img-fluid ml-4"
              />{" "}
              <span>Đính kèm file</span>
            </div>
            <input
              type="file"
              name="attachFile"
              accept=".pdf"
              className="attach-file"
              multiple
              disabled={!props.isLogin}
              onChange={ReadAttach}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 text-right">
          <button
            type="submit"
            className="btn btn-success"
            disabled={!props.isLogin}
          >
            Góp ý
          </button>
        </div>
      </div>
    </form>
  );
}

function RenderFilePreview(props) {
  const data = props.data;
  if (data.type == "image") {
    return (
      <div className="item mb-2">
        <img src={data.url} alt={data.name} className="img-video" />
        <div className="overlay"></div>
        <img
          src={require("/assets/images/close-white.svg")}
          className="img-fluid img-delete"
          alt="Delete"
          onClick={() => props.DeleteFile(data.id)}
        />
      </div>
    );
  } else if (data.type == "video") {
    return (
      <div className="item mb-2">
        <img src={data.url} alt={data.name} className="img-video" />
        <div className="overlay"></div>
        <img
          src={require("/assets/images/drop-down.svg")}
          className="img-fluid video"
          alt="Video"
        />
        <img
          src={require("/assets/images/close-white.svg")}
          className="img-fluid img-delete"
          alt="Delete"
          onClick={() => props.DeleteFile(data.id)}
        />
      </div>
    );
  } else {
    return (
      <div className="item mb-2">
        <img src={data.url} alt={data.name} className="attach" />
        <div className="overlay"></div>
        <img
          src={require("/assets/images/close-white.svg")}
          className="img-fluid img-delete"
          alt="Delete"
          onClick={() => props.DeleteFile(data.id)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      feedback: feedbackActions.Feedback,
      onGetApartmentList: projectActions.GetApartmentList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSuggestionsView);
