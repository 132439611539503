/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import { createFormData, AppKey } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();

export const GetCalendarSlots = (filterObj) => {
  return (dispatch) => {
    let payLoad = createFormData(filterObj, AppKey.GetPayloadPrefix);
    return service
      .post(ApiUrl.bbqGetCalendarSlots, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateCalendarSlot = (data) => {
  return (dispatch) => {
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
    return service
      .post(ApiUrl.bbqAddOrUpdateCalendarSlot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const DeleteCalendarSlot = (slotId) => {
  return (dispatch) => {
    let payLoad = createFormData({
      slotId: slotId,
    });
    return service
      .post(ApiUrl.bbqDeleteCalendarSlot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};
