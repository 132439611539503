/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Error } from "components/support-create-form/validate-style";
import * as helpingHandActions from "redux/stores/help/helping-hand.store";
import { Form } from "react-bootstrap";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  convertTextareaToHtml,
} from "utils/configuration";
import { JwtHelper } from "utils/jwt-helper";

function CreateOrUpdateHelpingHandForm(props) {
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  const [isUrgency, setIsUrgency] = useState(false);

  const onSubmit = async (data) => {
    props.onCreateOrUpdateHelpingHand &&
      props.onCreateOrUpdateHelpingHand({
        content: convertTextareaToHtml(data.content),
        is_urgent: isUrgency ? 1 : 0,
      });
    setValue("content", null);
    setIsUrgency(false);
  };

  return (
    <div className="helping-hand-adding-form">
      <h2 className="title-lg">Giúp một tay</h2>
      <div className="post-form bg-white p-3 mb-4 rounded">
        <h4 className="title-sm">Gửi yêu cầu giúp đỡ</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <textarea
              name="content"
              placeholder="Nội dung"
              className="form-control"
              disabled={!props.isLoggedIn}
              rows="5"
              ref={register({
                required: "Trường bắt buộc",
                minLength: {
                  value: 6,
                  message: "Nội dung phải có ít nhất 6 ký tự",
                },
              })}
            ></textarea>
            {errors.content && <Error content={errors.content.message} />}
          </div>
          <div className="row">
            <div className="col-6">
              <Form.Group controlId="addUrgencyCheckbox">
                <Form.Check
                  custom
                  label="Khẩn cấp"
                  type="checkbox"
                  name="is_urgent"
                  disabled={!props.isLoggedIn}
                  checked={isUrgency}
                  onChange={() => setIsUrgency(!isUrgency)}
                />
              </Form.Group>
            </div>
            <div className="col-6 text-right">
              <button
                type="submit"
                className="btn btn-success text-uppercase"
                disabled={!props.isLoggedIn}
              >
                Giúp tôi
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

class AddHelpingHandDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JwtHelper.currentUser(),
    };
  }

  UNSAFE_componentWillMount() {}

  onCreateOrUpdateHelpingHand = (data) => {
    let submittedData = {
      ...data,
      helpId: null,
      comments: [],
      customer_id: this.state.currentUser.customerId,
    };
    this.props.onToggleAddingState(true);
    this.props.createOrUpdateHelpingHand(submittedData).then(
      (res) => {
        AddNotification(
          "Tạo bài đăng thành công, vui lòng chờ phê duyệt",
          NotificationMessageType.Success
        );
        this.props.onToggleAddingState(false);
        this.props.callBack && this.props.callBack();
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  render() {
    return (
      <CreateOrUpdateHelpingHandForm
        isLoggedIn={this.props.isLoggedIn}
        onCreateOrUpdateHelpingHand={this.onCreateOrUpdateHelpingHand.bind(
          this
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrUpdateHelpingHand: helpingHandActions.CreateOrUpdateHelpingHand,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddHelpingHandDesktopView);
