import React, { Component, Suspense } from "react";
import { Switch } from "react-router-dom";
import AppRoute from "./AppRoute";
import * as PageUrls from "./PageUrls";
import { AppUrls } from '../utils/config-redirect';

import LayoutDesktop from "../components/_layout/layout-desktop.jsx";
import LayoutUserDesktop from "../components/_layout/layout-user-desktop.jsx";
import LayoutMobile from "../components/_layout/layout-mobile.jsx";
import LayoutUserMobile from "../components/_layout/layout-user-mobile.jsx";
import LayoutLoginMobile from "../components/_layout/layout-login-mobile.jsx";
import LayoutNoFooterMobile from "../components/_layout/layout-no-footer-mobile.jsx";
import AppLoading from "../components/loading/loading.view.jsx";
import ErrorBoundary from "../components/error-boundary/error-boundary.view.jsx";
import LayoutFullWidthDesktop from "../components/_layout/layout-fullwidth-desktop.jsx";
import LayoutErrorDesktop from "../components/_layout/layout-error-desktop.jsx";
import LayoutErrorMobile from "../components/_layout/layout-error-mobile.jsx";

//--- Homepage Desktop
import HomeDesktopView from "./home/home-desktop.view.jsx";

//--- Homepage Mobile
import HomeMobileView from "./home/home-mobile.view.jsx";

//--- Search Desktop
import SearchResultDesktopView from "./search-desktop/search-result-desktop.view.jsx";

//--- Search Mobile
import SearchMobileView from "./search-mobile/search-mobile.view.jsx";
import SearchResultMobileView from "./search-mobile/search-result-mobile.view.jsx";

//--- Authentication Mobile
import LoginMobileView from "./login-mobile/login-mobile.view.jsx";
import RegistrationMobileView from "./registration-mobile/registration-mobile.view.jsx";
import ForgotMobileView from "./forgot-password-mobile/forgot-password-mobile.view.jsx";
import ConfirmCodeMobileView from "./confirm-code-mobile/confirm-code-mobile.view.jsx";
import ResetPasswordMobileView from "./reset-password-mobile/reset-password-mobile.view.jsx";
import TermsOfServiceMobileView from "./terms-of-service-mobile/terms-of-service-mobile.view.jsx";

//--- Notification Desktop
import NotificationDesktopView from "./notification-desktop/notification-desktop.view.jsx";
import NotificationDetailDesktopView from "./notification-desktop/notification-detail-desktop/notification-detail-desktop.view.jsx";

//--- Notification Mobile
import NotificationMobileView from "./notification-mobile/notification-mobile.view.jsx";
import NotificationDetailMobileView from "./notification-mobile/notification-detail/notification-detail-mobile.view.jsx";
//--- User Info Desktop
import UserInformationDesktopView from "./user-desktop/user-information/user-information-desktop.view.jsx";
import DocumentManagementDesktopView from "./user-desktop/user-information/document-management/document-management-desktop.view.jsx";

//--- User Info Mobile
import UserInformationMobileView from "./user-mobile/user-information/user-information-mobile.view.jsx";
import UserInformationFormMobileView from "./user-mobile/user-information/information/information-form.jsx";
import ResetPasswordFormMobileView from "./user-mobile/user-information/reset-password/reset-password-form.jsx";
import DocumentManagementMobileView from "./user-mobile/user-information/document-management/document-management-mobile.view.jsx";
import UploadDocumentForm from "./user-mobile/user-information/document-management/add/upload-document-mobile.form.jsx";
import EditDocumentForm from "./user-mobile/user-information/document-management/edit/edit-document-mobile.form.jsx";
import DocumentDetailMobileView from "./user-mobile/user-information/document-management/detail/detail-document-mobile.view.jsx";
import MessageBoxMobileView from "./user-mobile/user-information/message-box/message-box-mobile.view.jsx";
import RegisterShopMobileView from "./user-mobile/user-information/register-shop/register-shop-mobile.view.jsx";

//--- User Shop Desktop
import UserShopDesktopView from "./user-desktop/user-shop/user-shop-desktop.view.jsx";

//--- User Shop Mobile
import UserShopMobileView from "./user-mobile/user-shop/user-shop-mobile.view.jsx";
import ShopInformationMobileView from "./user-mobile/user-shop/information/shop-information-mobile.view.jsx";
import ShopPromotionMobileView from "./user-mobile/user-shop/promotion/promotion-mobile.view.jsx";
import AddPromotionMobileView from "./user-mobile/user-shop/promotion/add-promotion/add-promotion-mobile.view.jsx";
import EditPromotionMobileView from "./user-mobile/user-shop/promotion/edit-promotion/edit-promotion-mobile.view.jsx";
import UserShopCalendarSchedulerMobileView from "./user-mobile/user-shop/calendar-scheduler/calendar-scheduler-mobile.view.jsx";

//--- Hot Desktop
import HotDetailDesktopView from "./hot-desktop/hot-detail/hot-detail-desktop.view.jsx";
import HotDesktopView from "./hot-desktop/hot-desktop.view.jsx";

//--- Hot Mobile
import HotMobileView from "./hot-mobile/hot-mobile.view.jsx";
import HotDetailMobileView from "./hot-mobile/hot-detail/hot-detail-mobile.view.jsx";

//--- Payment Desktop
import PaymentElectricDesktopView from "./payment-desktop/electric/electric-desktop.view.jsx";
import PaymentWaterDesktopView from "./payment-desktop/water/water-desktop.view.jsx";
import PaymentInternetDesktopView from "./payment-desktop/internet/internet-desktop.view.jsx";
import PaymentOtherServiceDesktopView from "./payment-desktop/other-service/other-service-desktop.view.jsx";
import PaymentResultDesktopView from "./payment-desktop/payment-result/payment-result-desktop.view.jsx";

//--- Payment Mobile
import PaymentElectricMobileView from "./payment-mobile/electric/electric-mobile.view.jsx";
import PaymentElectricDetailMobileView from "./payment-mobile/electric/detail/electric-detail-mobile.view.jsx";
import PaymentElectricGuideMobileView from "./payment-mobile/electric/guide/electric-guide-mobile.view.jsx";
import PaymentWaterMobileView from "./payment-mobile/water/water-mobile.view.jsx";
import PaymentWaterDetailMobileView from "./payment-mobile/water/detail/water-detail-mobile.view.jsx";
import PaymentWaterGuideMobileView from "./payment-mobile/water/guide/water-guide-mobile.view.jsx";
import PaymentInternetMobileView from "./payment-mobile/internet/internet-mobile.view.jsx";
import PaymentOtherServiceMobileView from "./payment-mobile/other-service/other-service-mobile.view.jsx";
import PaymentOtherServiceGuideMobileView from "./payment-mobile/other-service/guide/other-service-guide-mobile.view.jsx";
import PaymentOtherDetailMobileView from "./payment-mobile/other-service/detail/other-service-mobile.view.jsx";
import PaymentResultMobileView from "./payment-mobile/payment-result/payment-result-mobile.view.jsx";

//---- Utilities List Desktop
import UtilitiesListDesktopView from "./utilities-desktop/utilities-list/utilities-list-desktop.view.jsx";

//--- Utilities Desktop
import SuperMarketHomeViewDesktop from "./utilities-desktop/supermarket/super-market-home-page.view";
import UtilitiesSuperMarketListPageDesktop from "./utilities-desktop/supermarket/super-market-list-page.view";
import UtilitiesSuperMarketDetailPageDesktop from "./utilities-desktop/supermarket/super-market-detail-page.view";
import CafeHomeViewDesktop from "./utilities-desktop/cafe/cafe-home-page.view";
import UtilitiesCafeListPageDesktop from "./utilities-desktop/cafe/cafe-list-page.view";
import UtilitiesCafeDetailPageDesktop from "./utilities-desktop/cafe/cafe-detail-page.view";
import UtilitiesRestaurantDetailPageDesktop from "./utilities-desktop/restaurant/restaurant-detail-page.view";
import UtilitiesRestaurantListPageDesktop from "./utilities-desktop/restaurant/restaurant-list-page.view";
import UtilitiesShoolDetailPageDesktop from "./utilities-desktop/school/school-detail-page.view";
import SchoolHomeViewDesktop from "./utilities-desktop/school/school-home-page.view";
import UtilitiesShoolListPageDesktop from "./utilities-desktop/school/school-list-page.view";
import UtilitiesBbqDesktopView from "./utilities-desktop/bbq/bbq-desktop.view.jsx";
import RestaurantHomeViewDesktop from "./utilities-desktop/restaurant/restaurant-home-page.view";
import UtilitiesSportDesktopView from "./utilities-desktop/sport/sport-desktop.view.jsx";
import UtilitiesSportListDesktopView from "./utilities-desktop/sport/sport-list/sport-list-desktop.view.jsx";
import UtilitiesGymDetailDesktopView from "./utilities-desktop/sport/gym/gym-detail.view";
import UtilitiesPoolDetailDesktopView from "./utilities-desktop/sport/pool/pool.view";
import UtilitiesSoccerDetailDesktopView from "./utilities-desktop/sport/soccer/soccer.view";
import UtilitiesTennisDetailDesktopView from "./utilities-desktop/sport/tennis/tennis.view";
import UtilitiesSpaHomeViewDesktop from "./utilities-desktop/spa/spa-home-desktop.view";
import UtilitiesSpaListPageDesktop from "./utilities-desktop/spa/spa-list-page.view";
import UtilitiesSpaDetailPageDesktop from "./utilities-desktop/spa/spa-detail-page-desktop.view";

//---- Utilities List Mobile
import UtilitiesListMobileView from "./utilities-mobile/utilities-list/utilities-list-mobile.view.jsx";

//--- Utilities Mobile
import UtilitiesMobileView from "./utilities-mobile/utilities-mobile.view.jsx";
import UtilitiesSportListPageMobile from "./utilities-mobile/sport/sport-list-page-mobile.view";
import UtilitiesSuperMarketDetailPageMobile from "./utilities-mobile/super-market/super-market-detail-mobile.view";
import PoolDetailPageMobile from "./utilities-mobile/sport/pool/pool-detail.view";
import GymDetailPageMobile from "./utilities-mobile/sport/gym/gym-detail.view";
import UtilitiesTennisDetailPageMobile from "./utilities-mobile/sport/tennis/tennis-detail.view";
import UtilitiesSoccerDetailPageMobile from "./utilities-mobile/sport/soccer/soccer-detail-page.view";
import UtilitiesSpaDetailPageMobile from "./utilities-mobile/spa/spa-detail/spa-detail-mobile.view.jsx";
import UtilitiesCafeDetailPageMobile from "./utilities-mobile/cafe/cafe-detail-mobile.view";
import UtilitiesSchoolDetailPageMobile from "./utilities-mobile/school/school-detail-mobile.view";
import UtilitiesRestaurantDetailPageMobile from "./utilities-mobile/restaurant/restaurant-detail-mobile.view";

//--- Utilities Plus Desktop
import RepairingHouseholdDesktopView from "./utilities-plus-desktop/repairing-household/repairing-household-desktop.view.jsx";
import RepairingHouseholdDetailDesktopView from "./utilities-plus-desktop/repairing-household/repairing-household-detail/repairing-household-detail-desktop.view.jsx";
import HitchhikeDesktopView from "./utilities-plus-desktop/hitchhike/hitchhike-desktop.view.jsx";
import BusScheduleDesktopView from "./utilities-plus-desktop/bus-schedule/bus-schedule-desktop.view.jsx";

//--- Utilities Plus Mobile
import UtilitiesPlusMobileView from "./utilities-plus-mobile/utilities-plus-mobile.view.jsx";
import RepairingHouseholdMobileView from "./utilities-plus-mobile/repairing-household/repairing-household-mobile.view.jsx";
import RepairingHouseholdDetailMobileView from "./utilities-plus-mobile/repairing-household/detail/repairing-household-detail-mobile.view.jsx";
import HitchhikeMobileView from "./utilities-plus-mobile/hitchhike/hitchhike-mobile.view.jsx";
import HitchhikeCreateMobileView from "./utilities-plus-mobile/hitchhike/create/hitchhike-create-mobile.view.jsx";
import BusScheduleMobileView from "./utilities-plus-mobile/bus-schedule/bus-schedule-mobile.view.jsx";

//--- Management Desktop
import ManagementInfoDesktopView from "./management-desktop/management-info/management-info-desktop.view.jsx";
import MemberDetailDesktopView from "./management-desktop/member/member-detail-desktop.view.jsx";
import LegalDetailDesktopView from "./management-desktop/legal/legal-detail-desktop.view.jsx";
import LegalListDesktopView from "./management-desktop/legal/legal-list-desktop.view.jsx";
import ProcedureDetailDesktopView from "./management-desktop/procedure/procedure-detail-desktop.view.jsx";
import ProcedureCategoryDesktopView from "./management-desktop/procedure/procedure-category-desktop.view.jsx";
import ProcedureListDesktopView from "./management-desktop/procedure/procedure-list-desktop.view.jsx";
import MemberListDesktopView from "./management-desktop/member/member-list-desktop.view.jsx";
import SecurityCameraListDesktopView from "./management-desktop/security-camera/security-camera-list-desktop.view.jsx";

//--- Management Mobile
import ManagementMobileView from "./management-mobile/management-mobile.view.jsx";
import MemberInfoMobileView from "./management-mobile/member-info/member-info-mobile.view.jsx";
import ProcedureListDocumentMobileView from "./management-mobile/procedure-list/procedure-list-mobile.view.jsx";
import ProcedureDetailMobileView from "./management-mobile/procedure-detail/procedure-detail-mobile.view.jsx";
import LegalDetailMobileView from "./management-mobile/legal/legal-detail-mobile.view.jsx";

//--- Advertising Desktop
import BuyAndRentHousesDesktopView from "./advertising-desktop/buy-and-rent-houses/buy-and-rent-houses-desktop.view.jsx";
import BuyAndRentHousesDetailDesktopView from "./advertising-desktop/buy-and-rent-houses/detail/buy-and-rent-houses-detail-desktop.view.jsx";
import BuyAndSaleHouseDesktopView from "./advertising-desktop/buy-and-rent-houses/form/buy-and-rent-desktop.form.jsx";
import RentToMarketDesktopView from "./advertising-desktop/rent-to-market/rent-to-market-desktop.view.jsx";
import HourlyMaidDesktopView from "./advertising-desktop/hourly-maid/hourly-maid-desktop.view.jsx";
import ResidentMarketDesktopView from "./advertising-desktop/resident-market/resident-market-desktop.view.jsx";
import ResidentMarketDetailDesktopView from "./advertising-desktop/resident-market/childs/resident-market-detail.view.jsx";
import AddResidentMarketDesktopView from "./advertising-desktop/resident-market/resident-market-actions/add-resident-market-desktop.view.jsx";

//--- Advertising Mobile
import AdvertisingMobileView from "./advertising-mobile/advertising-mobile.view.jsx";
import DetailResidentMarketMobileView from "./advertising-mobile/resident-market/detail/detail-resident-market-mobile.view.jsx";
import AddResidentMarketMobileView from "./advertising-mobile/resident-market/actions/add-resident-market-mobile.view.jsx";
import EditResidentMarketMobileView from "./advertising-mobile/resident-market/actions/edit-resident-market-mobile.view.jsx";
import EditHourlyMailMobileView from "./advertising-mobile/hourly-maid/actions/edit-hourly-maid-mobile.view.jsx";
import EditRentToMarketMobileView from "./advertising-mobile/rent-to-market/actions/edit-rent-to-market-mobile.view.jsx";
import BuyAndSaleHouseMobileView from "./advertising-mobile/buy-and-rent-houses/actions/buy-and-rent-mobile.form.jsx";
import DetailBuyAndRentHousesMobileView from "./advertising-mobile/buy-and-rent-houses/detail/detail-buy-and-rent-houses-mobile.view.jsx";

//--- Safe Desktop
import SafeMainDesktopView from "./safe-desktop/safe-main/safe-main-desktop.view.jsx";
import WaterQualityDesktopView from "./safe-desktop/water-quality/water-quality-desktop.view.jsx";
import WaterQualityDetailDesktopView from "./safe-desktop/water-quality/water-quality-detail/water-quality-detail-desktop.view.jsx";
import AirQualityDesktopView from "./safe-desktop/air-quality/air-quality-desktop.view.jsx";
import AirQualityDetailDesktopView from "./safe-desktop/air-quality/air-quality-detail/air-quality-detail-desktop.view.jsx";
import IncidentsAreaDesktopView from "./safe-desktop/incidents-area/incidents-area-desktop.view.jsx";
import IncidentsAreaDetailDesktopView from "./safe-desktop/incidents-area/incidents-area-detail/incidents-area-detail-desktop.view.jsx";

//--- Safe Mobile
import SafeMainMobileView from "./safe-mobile/safe-main/safe-main-mobile.view.jsx";
import WaterQualityMobileView from "./safe-mobile/water-quality/water-quality-mobile.view.jsx";
import AirQualityMobileView from "./safe-mobile/air-quality/air-quality-mobile.view.jsx";
import AirQualityDetailMobileView from "./safe-mobile/air-quality/air-quality-detail-mobile.view.jsx";
import WaterQualityDetailMobileView from "./safe-mobile/water-quality/water-quality-detail-mobile.view.jsx";
import IncidentsAreaMobileView from "./safe-mobile/incidents-area/incident-area-mobile.view.jsx";

//--- Neighbourhoods Desktop
import AddFriendDesktopView from "./neighbourhoods-desktop/add-friend/add-friend-desktop.view.jsx";
import AddFriendDetailDesktopView from "./neighbourhoods-desktop/add-friend/detail/add-friend-detail-desktop.view.jsx";
import HelpingHandDesktopView from "./neighbourhoods-desktop/helping-hand/helping-hand-desktop.view.jsx";
import HelpingHandDetailDesktopView from "./neighbourhoods-desktop/helping-hand/childs/helping-hand-detail.view.jsx";
import JoinsHandDesktopView from "./neighbourhoods-desktop/joins-hand/joins-hand-desktop.view.jsx";
import JoinsHandDetailDesktopView from "./neighbourhoods-desktop/joins-hand/detail/joins-hand-detail-desktop.view.jsx";
import EditJoinHandDesktopView from "./neighbourhoods-desktop/joins-hand/joins-hand-actions/edit-joins-hand-desktop.view.jsx";

//--- Neighbourhoods Mobile
import AddFriendDetailMobileView from "./neighbourhoods-mobile/add-friend/detail/add-friend-detail-mobile.view.jsx";
import HelpingHandMobileView from "./neighbourhoods-mobile/helping-hand/helping-hand-mobile.view.jsx";
import HelpingHandDetailMobileView from "./neighbourhoods-mobile/helping-hand/detail/helping-hand-detail-mobile.view.jsx";
import CreateHelpingHandMobileView from "./neighbourhoods-mobile/helping-hand/actions/create-helping-hand-mobile.view.jsx";
import EditHelpingHandMobileView from "./neighbourhoods-mobile/helping-hand/actions/edit-helping-hand-mobile.view.jsx";
import JoinsHandMobileView from "./neighbourhoods-mobile/joins-hand/joins-hand-mobile.view.jsx";
import CreateJoinsHandMobileView from "./neighbourhoods-mobile/joins-hand/actions/create-joins-hand-mobile.view.jsx";
import EditJoinsHandMobileView from "./neighbourhoods-mobile/joins-hand/actions/edit-joins-hand-mobile.view.jsx";
import JoinsHandDetailMobileView from "./neighbourhoods-mobile/joins-hand/detail/joins-hand-detail-mobile.view.jsx";

//--- Project Information Desktop
import ProjectInfoDesktopView from "./project-information/project-info-desktop.view.jsx";
import ProjectInfoMobileView from "./project-information/project-info-mobile.view.jsx";

//--- Life Desktop
import LifeDetailDesktopView from "./life-detail-desktop/life-detail-desktop.view.jsx";

//--- Life Mobile
import LifeDetailMobileView from "./life-detail-mobile/life-detail-mobile.view.jsx";

//--- Feedback Mobile
import FeedbackMobileView from "./feedback-mobile/feedback-mobile.view.jsx";

//--- Setup Mobile
import SetupMobileView from "./setup-mobile/setup-mobile.view.jsx";

//--- Not Found Desktop
import NotFoundDesktopView from "./error-page/not-found-desktop/not-found-desktop.view.jsx";

//--- Not Found Mobile
import NotFoundMobileView from "./error-page/not-found-mobile/not-found-mobile.view.jsx";

//--- Crash error page Desktop
import CrashErrorDesktopView from "./error-page/crash-error/crash-error-desktop.view.jsx";

//--- Crash error page Mobile
import CrashErrorMobileView from "./error-page/crash-error/crash-error-mobile.view.jsx";

//--- test
import TestFeatureView from "../components/tools/test-feature.view.jsx";

//--- Club Desktop
import ClubDesktopView from "./neighbourhoods-desktop/club/club.view.jsx";
import GolfDesktopView from "./neighbourhoods-desktop/club/golf/golf-desktop.view.jsx";
import AddGolfDesktopView from "./neighbourhoods-desktop/club/golf/golf-actions/add-golf-desktop.view.jsx";
import TennisDesktopView from "./neighbourhoods-desktop/club/tennis/tennis-desktop.view.jsx";
import AddTennisDesktopView from "./neighbourhoods-desktop/club/tennis/tennis-actions/add-tennis-desktop.view.jsx";
import PingPongDesktopView from "./neighbourhoods-desktop/club/ping-pong/ping-pong-desktop.view.jsx";
import AddPingPongDesktopView from "./neighbourhoods-desktop/club/ping-pong/ping-pong-actions/add-ping-pong-desktop.view.jsx";
import BadMintonDesktopView from "./neighbourhoods-desktop/club/badminton/badminton-desktop.view.jsx";
import AddBadMintonDesktopView from "./neighbourhoods-desktop/club/badminton/badminton-actions/add-badminton-desktop.view.jsx";

//--- Club Mobile
import ClubMobileView from "./neighbourhoods-mobile/club/club.view.jsx";
import AddGolfMobileView from "./neighbourhoods-mobile/club/golf/actions/add-golf-mobile.view.jsx";
import EditGolfMobileView from "./neighbourhoods-mobile/club/golf/actions/edit-golf-mobile.view.jsx";
import EditTennisMobileView from "./neighbourhoods-mobile/club/tennis/actions/edit-tennis-mobile.view.jsx";
import AddTennisMobileView from "./neighbourhoods-mobile/club/tennis/actions/add-tennis-mobile.view.jsx";
import EditPingPongMobileView from "./neighbourhoods-mobile/club/ping-pong/actions/edit-ping-pong-mobile.view.jsx";
import AddPingPongMobileView from "./neighbourhoods-mobile/club/ping-pong/actions/add-ping-pong-mobile.view.jsx";
import EditBadMintonMobileView from "./neighbourhoods-mobile/club/badminton/actions/edit-badminton-mobile.view.jsx";
import AddBadMintonMobileView from "./neighbourhoods-mobile/club/badminton/actions/add-badminton-mobile.view.jsx";

//--- Lending Equipment Desktop
import LendingEquipmentDesktopView from "./lending-equipment-desktop/lending-equipment-desktop.view.jsx";
import LendingEquipmentDetailDesktopView from "./lending-equipment-desktop/detail/lending-equipment-detail-desktop.view.jsx";

//--- Lending Equipment Mobile
import LendingEquipmentMobileView from "./lending-equipment-mobile/lending-equipment-mobile.view.jsx";
import LendingEquipmentPolicyMobileView from "./lending-equipment-mobile/policy/lending-equipment-policy-mobile.view.jsx";
import LendingEquipmentDetailMobileView from "./lending-equipment-mobile/detail/lending-equipment-detail-mobile.view.jsx";
import RegisterEquipmentMobileView from "./lending-equipment-mobile/register-equipment/register-equipment-mobile.view.jsx";

//--- Privacy Policy
import PrivacyPolicyView from "./privacy-policy/privacy-policy.view.jsx";

class App extends Component {
  render() {
    return (
      <div className="app-container">
        <ErrorBoundary>
          <AppLoading />
          <Suspense fallback={<div></div>}>
            <Switch>
              {/* Route Homepage Desktop */}
              <AppRoute
                exact
                path={AppUrls.home.desktop}
                component={HomeDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Homepage Mobile */}
              <AppRoute
                exact
                path={AppUrls.home.mobile}
                component={HomeMobileView}
                layout={LayoutMobile}
              />

              {/* Route Search Desktop */}
              <AppRoute
                exact
                path={AppUrls.searchResults.desktop}
                component={SearchResultDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Search Mobile */}
              <AppRoute
                exact
                path="/m/tim-kiem"
                component={SearchMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.searchResults.mobile}
                component={SearchResultMobileView}
                layout={LayoutMobile}
              />

              {/* Route User Mobile */}
              <AppRoute
                exact
                path={AppUrls.login.mobile}
                component={LoginMobileView}
                layout={LayoutLoginMobile}
              />
              <AppRoute
                exact
                path={AppUrls.register.mobile}
                component={RegistrationMobileView}
                layout={LayoutLoginMobile}
              />
              <AppRoute
                exact
                path={AppUrls.termsOfService.mobile}
                component={TermsOfServiceMobileView}
                layout={LayoutNoFooterMobile}
              />
              <AppRoute
                exact
                path={AppUrls.forgotPassword.mobile}
                component={ForgotMobileView}
                layout={LayoutNoFooterMobile}
              />
              <AppRoute
                exact
                path={AppUrls.confirmCode.mobile}
                component={ConfirmCodeMobileView}
                layout={LayoutNoFooterMobile}
              />
              <AppRoute
                exact
                path={AppUrls.resetPassword.mobile}
                component={ResetPasswordMobileView}
                layout={LayoutNoFooterMobile}
              />

              {/* Route Notification Desktop */}
              <AppRoute
                exact
                path={AppUrls.notification.desktop}
                component={NotificationDesktopView}
                layout={LayoutUserDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.notificationDetail.desktop}
                component={NotificationDetailDesktopView}
                layout={LayoutUserDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.notificationDetailForcusMode.desktop}
                component={NotificationDetailDesktopView}
                layout={LayoutUserDesktop}
              />

              {/* Route Notification Desktop */}
              <AppRoute
                exact
                path={AppUrls.notification.mobile}
                component={NotificationMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.notificationDetail.mobile}
                component={NotificationDetailMobileView}
                layout={LayoutMobile}
              />

              {/* Route User Info Desktop */}
              <AppRoute
                exact
                path={AppUrls.userInformation.desktop}
                component={UserInformationDesktopView}
                layout={LayoutUserDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.userInformationWithId.desktop}
                component={UserInformationDesktopView}
                layout={LayoutUserDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.documentManager.desktop}
                component={DocumentManagementDesktopView}
                layout={LayoutUserDesktop}
              />

              {/* Route User Info Mobile */}
              <AppRoute
                exact
                path={AppUrls.userInformation.mobile}
                component={UserInformationMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.userInformationWithId.mobile}
                component={UserInformationMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.userEditInformation.mobile}
                component={UserInformationFormMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.resetPassword.mobile}
                component={ResetPasswordFormMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.documentManager.mobile}
                component={DocumentManagementMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.uploadDocument.mobile}
                component={UploadDocumentForm}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.editDocument.mobile}
                component={EditDocumentForm}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.documentDetail.mobile}
                component={DocumentDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.messageBox.mobile}
                component={MessageBoxMobileView}
                layout={LayoutUserMobile}
              />
              <AppRoute
                exact
                path={AppUrls.registerShop.mobile}
                component={RegisterShopMobileView}
                layout={LayoutMobile}
              />

              {/* Route User Shop Desktop */}
              <AppRoute
                exact
                path={AppUrls.userShop.desktop}
                component={UserShopDesktopView}
                layout={LayoutUserDesktop}
              />

              {/* Route User Shop Mobile */}
              <AppRoute
                exact
                path={AppUrls.userShop.mobile}
                component={UserShopMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.shopInformation.mobile}
                component={ShopInformationMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.shopPromotion.mobile}
                component={ShopPromotionMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.shopAddPromotion.mobile}
                component={AddPromotionMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.shopEditPromotion.mobile}
                component={EditPromotionMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.shopSetupCalendar.mobile}
                component={UserShopCalendarSchedulerMobileView}
                layout={LayoutMobile}
              />

              {/* Route Hot Desktop */}
              <AppRoute
                exact
                path={AppUrls.hot.desktop}
                component={HotDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.hotDetail.desktop}
                component={HotDetailDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Hot Mobile */}
              <AppRoute
                exact
                path={AppUrls.hot.mobile}
                component={HotMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.hotDetail.mobile}
                component={HotDetailMobileView}
                layout={LayoutMobile}
              />

              {/* Route Utilities List Desktop */}
              <AppRoute
                exact
                path={AppUrls.utilitiesList.desktop}
                component={UtilitiesListDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Utilities Desktop */}
              <AppRoute
                exact
                path={AppUrls.utilitiesCafe.desktop}
                component={CafeHomeViewDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesCafeList.desktop}
                component={UtilitiesCafeListPageDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesCafeDetail.desktop}
                component={UtilitiesCafeDetailPageDesktop}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesSchoolList.desktop}
                component={UtilitiesShoolListPageDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSchool.desktop}
                component={SchoolHomeViewDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSchoolDetail.desktop}
                component={UtilitiesShoolDetailPageDesktop}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesSuperMarket.desktop}
                component={SuperMarketHomeViewDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSuperMarketList.desktop}
                component={UtilitiesSuperMarketListPageDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSuperMarketDetail.desktop}
                component={UtilitiesSuperMarketDetailPageDesktop}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesRestaurant.desktop}
                component={RestaurantHomeViewDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesRestaurantList.desktop}
                component={UtilitiesRestaurantListPageDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesRestaurantDetail.desktop}
                component={UtilitiesRestaurantDetailPageDesktop}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesBbq.desktop}
                component={UtilitiesBbqDesktopView}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesGymDetail.desktop}
                component={UtilitiesGymDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesGym.desktop}
                component={UtilitiesSportListDesktopView}
                layout={LayoutDesktop}
              />

              {/* <AppRoute exact path={PageUrls.UtilitiesSportHomeDesktop} component={UtilitiesSportDesktopView} layout={LayoutDesktop} /> */}
              <AppRoute
                exact
                path={AppUrls.utilitiesSport.desktop}
                component={UtilitiesSportListDesktopView}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesPool.desktop}
                component={UtilitiesSportListDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesPoolDetail.desktop}
                component={UtilitiesPoolDetailDesktopView}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesSoccer.desktop}
                component={UtilitiesSportListDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSoccerDetail.desktop}
                component={UtilitiesSoccerDetailDesktopView}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesTennisDetail.desktop}
                component={UtilitiesTennisDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesTennis.desktop}
                component={UtilitiesSportListDesktopView}
                layout={LayoutDesktop}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesBeauty.desktop}
                component={UtilitiesSpaHomeViewDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesBeautyList.desktop}
                component={UtilitiesSpaListPageDesktop}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesBeautyDetail.desktop}
                component={UtilitiesSpaDetailPageDesktop}
                layout={LayoutDesktop}
              />

              {/* Route Utilities List Mobile */}
              <AppRoute
                exact
                path={AppUrls.utilitiesList.mobile}
                component={UtilitiesListMobileView}
                layout={LayoutMobile}
              />

              {/* Route Utilities Mobile */}
              <AppRoute
                exact
                path={AppUrls.utilitiesSport.mobile}
                needGoogleMapScript={true}
                component={UtilitiesMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSportList.mobile}
                needGoogleMapScript={true}
                component={UtilitiesSportListPageMobile}
                layout={LayoutMobile}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesCafe.mobile}
                needGoogleMapScript={true}
                component={UtilitiesMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesCafeDetail.mobile}
                component={UtilitiesCafeDetailPageMobile}
                layout={LayoutMobile}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesSchool.mobile}
                needGoogleMapScript={true}
                component={UtilitiesMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSchoolDetail.mobile}
                component={UtilitiesSchoolDetailPageMobile}
                layout={LayoutMobile}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesBeauty.mobile}
                needGoogleMapScript={true}
                component={UtilitiesMobileView}
                layout={LayoutMobile}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesRestaurant.mobile}
                needGoogleMapScript={true}
                component={UtilitiesMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesRestaurantDetail.mobile}
                component={UtilitiesRestaurantDetailPageMobile}
                layout={LayoutMobile}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesSuperMarket.mobile}
                needGoogleMapScript={true}
                component={UtilitiesMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSuperMarketDetail.mobile}
                component={UtilitiesSuperMarketDetailPageMobile}
                layout={LayoutMobile}
              />

              <AppRoute
                exact
                path={AppUrls.utilitiesPoolDetail.mobile}
                component={PoolDetailPageMobile}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesGymDetail.mobile}
                component={GymDetailPageMobile}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesTennisDetail.mobile}
                component={UtilitiesTennisDetailPageMobile}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesSoccerDetail.mobile}
                component={UtilitiesSoccerDetailPageMobile}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.utilitiesBeautyDetail.mobile}
                component={UtilitiesSpaDetailPageMobile}
                layout={LayoutMobile}
              />

              {/* Route Utilities Plus Desktop */}
              <AppRoute
                exact
                path={AppUrls.repairingHousehold.desktop}
                component={RepairingHouseholdDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.repairingHouseholdDetail.desktop}
                component={RepairingHouseholdDetailDesktopView}
                layout={LayoutDesktop}
              />
              {/* <AppRoute exact path='/di-nho-xe' component={HitchhikeDesktopView} layout={LayoutDesktop} />
                            <AppRoute exact path='/lich-trinh-xe-buyt-tau-dien' component={BusScheduleDesktopView} layout={LayoutDesktop} /> */}

              {/* Route Utilities Plus Mobile */}
              <AppRoute
                exact
                path={AppUrls.utilitiesPlus.mobile}
                component={UtilitiesPlusMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.repairingHousehold.mobile}
                component={RepairingHouseholdMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.repairingHouseholdDetail.mobile}
                component={RepairingHouseholdDetailMobileView}
                layout={LayoutMobile}
              />
              {/* <AppRoute exact path='/m/di-nho-xe' component={HitchhikeMobileView} layout={LayoutMobile} />
                            <AppRoute exact path='/m/di-nho-xe/tao-lo-trinh' component={HitchhikeCreateMobileView} layout={LayoutMobile} />
                            <AppRoute exact path='/m/lich-trinh-xe-buyt-tau-dien' component={BusScheduleMobileView} layout={LayoutMobile} /> */}

              {/* Route Payment Desktop */}
              <AppRoute
                exact
                path={AppUrls.paymentElectric.desktop}
                needLoginToSee={true}
                component={PaymentElectricDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.paymentWater.desktop}
                needLoginToSee={true}
                component={PaymentWaterDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.paymentInternet.desktop}
                needLoginToSee={true}
                component={PaymentInternetDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.paymentOther.desktop}
                needLoginToSee={true}
                component={PaymentOtherServiceDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.paymentResult.desktop}
                needLoginToSee={true}
                component={PaymentResultDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Payment Mobile */}
              <AppRoute
                exact
                path={AppUrls.paymentElectric.mobile}
                needLoginToSee={true}
                component={PaymentElectricMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentElectricDetail.mobile}
                needLoginToSee={true}
                component={PaymentElectricDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paumentElectricGuide.mobile}
                component={PaymentElectricGuideMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentWater.mobile}
                needLoginToSee={true}
                component={PaymentWaterMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentWaterDetail.mobile}
                needLoginToSee={true}
                component={PaymentWaterDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentWaterGuide.mobile}
                component={PaymentWaterGuideMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentInternet.mobile}
                needLoginToSee={true}
                component={PaymentInternetMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentOther.mobile}
                needLoginToSee={true}
                component={PaymentOtherServiceMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentOtherDetail.mobile}
                needLoginToSee={true}
                component={PaymentOtherDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentOtherGuide.mobile}
                component={PaymentOtherServiceGuideMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.paymentResult.mobile}
                needLoginToSee={true}
                component={PaymentResultMobileView}
                layout={LayoutMobile}
              />

              {/* Route Management Desktop */}
              <AppRoute
                exact
                path={AppUrls.managerInformation.desktop}
                component={ManagementInfoDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.managerMemberList.desktop}
                component={MemberListDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.managerMemberDetail.desktop}
                component={MemberDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.legalDocumentList.desktop}
                component={LegalListDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.legalDocumentDetail.desktop}
                component={LegalDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.procedureCategory.desktop}
                component={ProcedureCategoryDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.procedureList.desktop}
                component={ProcedureListDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.procedureDetail.desktop}
                component={ProcedureDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.securityCamera.desktop}
                component={SecurityCameraListDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Management Mobile */}
              <AppRoute
                exact
                path={AppUrls.managerInformation.mobile}
                component={ManagementMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.managerInformationWithTypeId.mobile}
                component={ManagementMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.managerMemberDetail.mobile}
                component={MemberInfoMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.procedureList.mobile}
                component={ProcedureListDocumentMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.procedureDetail.mobile}
                component={ProcedureDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.legalDocumentDetail.mobile}
                component={LegalDetailMobileView}
                layout={LayoutMobile}
              />

              {/* Route Advertising Desktop */}
              {/* <AppRoute exact path='/rao-vat/di-cho-thue' component={RentToMarketDesktopView} layout={LayoutDesktop} /> */}
              <AppRoute
                exact
                path={AppUrls.residentMarket.desktop}
                component={ResidentMarketDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.residentMarketDetail.desktop}
                component={ResidentMarketDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.residentMarketAddNew.desktop}
                component={AddResidentMarketDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.hourlyMaid.desktop}
                component={HourlyMaidDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.buyAndRentHouses.desktop}
                component={BuyAndRentHousesDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.addBuyAndRentHouses.desktop}
                component={BuyAndSaleHouseDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.addBuyAndRentHousesWithLandId.desktop}
                component={BuyAndSaleHouseDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.buyAndRentHousesDetail.desktop}
                component={BuyAndRentHousesDetailDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Advertising Mobile */}
              <AppRoute
                exact
                path={AppUrls.advertising.mobile}
                component={AdvertisingMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.advertisingWithTypeId.mobile}
                component={AdvertisingMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.advertisingDetail.mobile}
                component={AdvertisingMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.residentMarketDetail.mobile}
                component={DetailResidentMarketMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.residentMarketAddNew.mobile}
                component={AddResidentMarketMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.residentMarketEdit.mobile}
                component={EditResidentMarketMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.hourlyMaidEdit.mobile}
                component={EditHourlyMailMobileView}
                layout={LayoutMobile}
              />
              {/* <AppRoute exact path='/m/di-cho-thue/sua-tin/:assistId' component={EditRentToMarketMobileView} layout={LayoutMobile} /> */}
              <AppRoute
                exact
                path={AppUrls.addBuyAndRentHouses.mobile}
                component={BuyAndSaleHouseMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.addBuyAndRentHousesWithLandId.mobile}
                component={BuyAndSaleHouseMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.buyAndRentHousesDetail.mobile}
                component={DetailBuyAndRentHousesMobileView}
                layout={LayoutMobile}
              />

              {/* Route Safe Desktop */}
              {/* <AppRoute exact path='/an-toan' component={SafeMainDesktopView} layout={LayoutDesktop} />
                            <AppRoute exact path='/xem-chat-luong-nuoc' component={WaterQualityDesktopView} layout={LayoutDesktop} />
                            <AppRoute exact path='/xem-chat-luong-nuoc/chi-tiet/:waterSafeId' component={WaterQualityDetailDesktopView} layout={LayoutDesktop} />
                            <AppRoute exact path='/xem-chat-luong-khong-khi' component={AirQualityDesktopView} layout={LayoutDesktop} />
                            <AppRoute exact path='/xem-chat-luong-khong-khi/chi-tiet/:airSafeId' component={AirQualityDetailDesktopView} layout={LayoutDesktop} /> */}
              <AppRoute
                exact
                path={AppUrls.incidentArea.desktop}
                component={IncidentsAreaDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.incidentAreaDetail.desktop}
                component={IncidentsAreaDetailDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Safe Mobile*/}
              {/* <AppRoute exact path='/m/an-toan' component={SafeMainMobileView} layout={LayoutMobile} />
                            <AppRoute exact path='/m/an-toan/chat-luong-nuoc' component={WaterQualityMobileView} layout={LayoutMobile} />
                            <AppRoute exact path='/m/an-toan/chat-luong-khong-khi' component={AirQualityMobileView} layout={LayoutMobile} />
                            <AppRoute exact path='/m/an-toan/chat-luong-khong-khi/chi-tiet/:airSafeId' component={AirQualityDetailMobileView} layout={LayoutMobile} />
                            <AppRoute exact path='/m/an-toan/chat-luong-nuoc/chi-tiet/:waterSafeId' component={WaterQualityDetailMobileView} layout={LayoutMobile} /> */}
              <AppRoute
                exact
                path={AppUrls.incidentArea.mobile}
                component={IncidentsAreaMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.incidentAreaDetail.mobile}
                component={IncidentsAreaMobileView}
                layout={LayoutMobile}
              />

              {/* Route Neighbourhoods Desktop */}
              <AppRoute
                exact
                path={AppUrls.neightborAddFriend.desktop}
                component={AddFriendDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.neightborAddFriendDetail.desktop}
                component={AddFriendDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.helpingHand.desktop}
                component={HelpingHandDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.helpingHandDetail.desktop}
                component={HelpingHandDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.joinHands.desktop}
                component={JoinsHandDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.joinHandsDetail.desktop}
                component={JoinsHandDetailDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.joinHandsEdit.desktop}
                component={EditJoinHandDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.club.desktop}
                component={ClubDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.golfClub.desktop}
                component={GolfDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.golfClubAddNew.desktop}
                component={AddGolfDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.tennisClub.desktop}
                component={TennisDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.tennisClubAddNew.desktop}
                component={AddTennisDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.pingPongClub.desktop}
                component={PingPongDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.pingPongClubAddNew.desktop}
                component={AddPingPongDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.badMintonClub.desktop}
                component={BadMintonDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.badMintonClubAddNew.desktop}
                component={AddBadMintonDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Neighbourhoods Mobile */}
              <AppRoute
                exact
                path={AppUrls.helpingHand.mobile}
                component={HelpingHandMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.helpingHandDetail.mobile}
                component={HelpingHandDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.helpingHandAddNew.mobile}
                component={CreateHelpingHandMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.helpingHandEdit.mobile}
                component={EditHelpingHandMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.joinHands.mobile}
                component={JoinsHandMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.joinHandsAddNew.mobile}
                component={CreateJoinsHandMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.joinHandsEdit.mobile}
                component={EditJoinsHandMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.joinHandsDetail.mobile}
                component={JoinsHandDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.clubWithTypeId.mobile}
                component={ClubMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.golfClubEdit.mobile}
                component={EditGolfMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.golfClubAddNew.mobile}
                component={AddGolfMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.tennisClubEdit.mobile}
                component={EditTennisMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.tennisClubAddNew.mobile}
                component={AddTennisMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.pingPongClubEdit.mobile}
                component={EditPingPongMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.pingPongClubAddNew.mobile}
                component={AddPingPongMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.badMintonClubEdit.mobile}
                component={EditBadMintonMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.badMintonClubAddNew.mobile}
                component={AddBadMintonMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.clubAddFriendWithFriendId.mobile}
                component={AddFriendDetailMobileView}
                layout={LayoutMobile}
              />

              {/* Route Project Info Desktop */}
              <AppRoute
                exact
                path={AppUrls.projectInformation.desktop}
                component={ProjectInfoDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Project Info Mobile */}
              <AppRoute
                exact
                path={AppUrls.projectInformation.mobile}
                component={ProjectInfoMobileView}
                layout={LayoutMobile}
              />

              {/* Route Life Desktop */}
              <AppRoute
                exact
                path={AppUrls.lifeDetail.desktop}
                component={LifeDetailDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Life Mobile */}
              <AppRoute
                exact
                path={AppUrls.lifeDetail.mobile}
                component={LifeDetailMobileView}
                layout={LayoutMobile}
              />

              {/* Route Feedback Mobile */}
              <AppRoute
                exact
                path={AppUrls.sendFeedBack.mobile}
                component={FeedbackMobileView}
                layout={LayoutMobile}
              />

              {/* Route Setup Mobile */}
              <AppRoute
                exact
                path={AppUrls.setting.mobile}
                component={SetupMobileView}
                layout={LayoutMobile}
              />

              {/* Route Lending Equipment Desktop */}
              <AppRoute
                exact
                path={AppUrls.lendingEquipment.desktop}
                component={LendingEquipmentDesktopView}
                layout={LayoutDesktop}
              />
              <AppRoute
                exact
                path={AppUrls.lendingEquipmentDetail.desktop}
                component={LendingEquipmentDetailDesktopView}
                layout={LayoutDesktop}
              />

              {/* Route Lending Equipment Mobile */}
              <AppRoute
                exact
                path={AppUrls.lendingEquipment.mobile}
                component={LendingEquipmentMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.lendingEquipmentPolicy.mobile}
                component={LendingEquipmentPolicyMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.lendingEquipmentDetail.mobile}
                component={LendingEquipmentDetailMobileView}
                layout={LayoutMobile}
              />
              <AppRoute
                exact
                path={AppUrls.registerLendingEquipment.mobile}
                component={RegisterEquipmentMobileView}
                layout={LayoutMobile}
              />

              {/* Privacy Policy */}
              <AppRoute
                exact
                path={AppUrls.privacyPolicy.desktop}
                component={PrivacyPolicyView}
                layout={LayoutDesktop}
              />

              {/* Route NotFound Desktop */}
              <AppRoute
                exact
                path={AppUrls.notFound.desktop}
                component={NotFoundDesktopView}
                layout={LayoutErrorDesktop}
              />

              {/* Route NotFound Mobile */}
              <AppRoute
                exact
                path={AppUrls.notFound.mobile}
                component={NotFoundMobileView}
                layout={LayoutErrorMobile}
              />

              {/* Route CrashError Desktop */}
              <AppRoute
                exact
                path={AppUrls.crashError.desktop}
                component={CrashErrorDesktopView}
                layout={LayoutFullWidthDesktop}
              />

              {/* Route CrashError Mobile */}
              <AppRoute
                exact
                path={AppUrls.crashError.mobile}
                component={CrashErrorMobileView}
                layout={LayoutMobile}
              />

              {/* Route not found */}
              <AppRoute
                component={NotFoundDesktopView}
                layout={LayoutErrorDesktop}
              />

              {/* Route test feature*/}
              <AppRoute
                exact
                path="/test-feature"
                component={TestFeatureView}
                layout={LayoutUserDesktop}
              />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </div>
    );
  }
}

export default App;
