//@ts-check
import React, { useState, useRef } from 'react';
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button } from 'react-bootstrap';
import './register-calendar.scss';
import { Error, Success } from 'components/support-create-form/validate-style';
import { ConverDateObjectToVietNamCalendar } from 'utils/configuration';
import { useRegisterCalendarHook } from '../../../../../components/custom-hook/register-calendar/register-calendar';
const StyleCollect = {
    inputRegister: {
        borderRadius: "20px"
    }
}

/**
 * @param {{isLoggedIn:boolean,
 * registerFunction:Function,
 * handleGetCalendarByDate:Function,
 * shopId:number,slotList: {
    slotName: string;
    slotId: number;
    isFull: boolean;
}[]}} [props]
*/
function RenderRegisterCalendarDesktop(props) {
    const {
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        slotIdRegister,
        setSlotIdRegister,
        slotNameRegister,
        setSlotNameRegister,
        hasShowRegisterModal,
        setHasShowRegisterModal,
        dateView,
        handleChangeDateView,
        handleShowRegisterModal,
        handleRegister,
        validationUserNameMessage,
        validationPhoneNumberMessage,
    } = useRegisterCalendarHook(props.shopId, props.registerFunction, props.handleGetCalendarByDate)

    return (
        <div className="register-calendara-container">
            <div className="row no-gutters p-1 bg-green-default header-calendar mb-4 mt-4">
                <div className="col-6 pl-2 text-size-sub-title text-white" style={{ fontWeight: 500 }}>
                    Lịch đặt chỗ
                </div>
                <div className="col-6 position-relative text-right">
                    <DatePicker minDate={subDays(new Date(), 0)} selected={dateView} onChange={handleChangeDateView} className="form-control" dateFormat="dd/MM/yyyy" />
                    <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-fluid position-absolute img-calendar" />
                </div>
            </div>
            {/* Calendar */}
            <div className="yard">
                <RenderYardCalendar slotList={props.slotList} clickShow={handleShowRegisterModal} />
                {
                    (props.slotList.length === 0) && <div className="text-center">Không có dữ liệu để hiển thị</div>
                }
            </div>
            {/* Modal register */}
            <Modal centered show={hasShowRegisterModal} onHide={() => setHasShowRegisterModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Đăng ký đặt lịch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div hidden={!props.isLoggedIn} className="form-register-container row no-gutters">
                        <div className="col-6 pr-2">
                            <div className="form-group">
                                <input style={StyleCollect.inputRegister} type="text" className="form-control " placeholder="Họ tên" defaultValue={userName} onChange={event => setUserName(event.target.value)} />
                                {
                                    validationUserNameMessage && <Error content={validationUserNameMessage} />
                                }
                            </div>
                            <div className="form-group">
                                <input style={StyleCollect.inputRegister} type="text" className="form-control " placeholder="Thời gian" defaultValue={slotNameRegister} disabled />
                            </div>
                        </div>
                        <div className="col-6 pl-2">
                            <div className="form-group">
                                <input style={StyleCollect.inputRegister} type="tel" className="form-control " placeholder="Số điện thoại" defaultValue={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} />
                                {
                                    validationPhoneNumberMessage && <Error content={validationPhoneNumberMessage} />
                                }
                            </div>
                            <div className="form-group">
                                <input style={StyleCollect.inputRegister} type="text" className="form-control " defaultValue={ConverDateObjectToVietNamCalendar(dateView)} placeholder="Ngày Đăng Ký" disabled />
                            </div>
                            <button style={StyleCollect.inputRegister} onClick={() => handleRegister()} className="btn btn-success w-100 text-white text-center">Đăng ký</button>
                        </div>
                    </div>
                    <div hidden={props.isLoggedIn}>
                        Bạn cần đăng nhập để dùng tính năng này
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

/**
 * @param {{slotList:{slotName:string,slotId:number,isFull:boolean}[],clickShow:(slotName:string,slotId:number)=>*}} [props]
*/
function RenderYardCalendar(props) {
    const content = [];
    props.slotList.map((data1, index) => {
        content.push(<RenderBox clickShow={props.clickShow} slotName={data1.slotName} key={index} slotId={data1.slotId} isFull={data1.isFull} />)
    })
    return (
        <div className="yard-item">
            <div className="yard-body">
                <div className="row align-items-center ml-n2 mr-n2">
                    {content}
                </div>
            </div>
        </div>
    )
}

/**
 * @param {{slotName:string,slotId:number,isFull:boolean,clickShow:(slotName:string,slotId:number)=>*}} [props]
*/
function RenderBox(props) {
    let content = (<div className="col-3 pl-2 pr-2">
        <div className="yard-box active" title="Bấm vào để đặt sân" onClick={() => props.clickShow(props.slotName, props.slotId)}>
            <h5>{props.slotName}</h5>
            <span>Còn chỗ</span>
        </div>
    </div>)
    if (props.isFull) content = (
        <div className="col-3 pl-2 pr-2">
            <div className="yard-box inactive" title="Sân này đã hết chỗ">
                <h5>{props.slotName}</h5>
                <span>Hết chỗ</span>
            </div>
        </div>
    )
    return content
}


export default RenderRegisterCalendarDesktop