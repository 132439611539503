import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from '../../../core/app.store';
import {
    createFormData,
    AppKey,
    compareValues
} from '../../../utils/configuration';
import Service from '../../../services/api.service';
import {
    ApiUrl
} from '../../../services/api-url';
import AddNotification from '../../../components/react-notifications/react-notifications'

const WAIT_TO_CONFIRM = 4338;
export const APPROVED = 4339;
export const UNAPPROVED = 4340;

const service = new Service();
/** Action Types */
const HITCHHIKESAVELIST = 'HITCHHIKE/SAVELIST';
const HITCHHIKE = 'HITCHHIKE/HITCHHIKE';
/** Actions */
export const createSaveHitchhikeList = (hitchhikeModel) => ({
    type: HITCHHIKESAVELIST,
    payload: {
        hitchhikeList: hitchhikeModel.items,
        totalItems: hitchhikeModel.totalItemCount
    }
});

export const PostWantToHitchHike = data => {
    return dispatch => {
        dispatch(createSaveLoading(true))
        // post api
        AddNotification("Gửi yêu cầu thành công, vui lòng chờ xác nhận", "success")
    }
}

export const createSaveHitchhike = data => ({
    type: HITCHHIKE,
    data
});

export const GetHitchhikeList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        // dispatch(createSaveLoading(true));
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.HitchhikeGetList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveHitchhikeList(res.content));
            // dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateHitchhikeRequest = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.HitchhikeCreateRequest, payLoad).then(res => {
            console.log(res) // will delete late
            AddNotification("Gửi yêu cầu thành công, vui lòng chờ xác nhận", "success")
            return true;
        }).catch(err => {
            throw (err);
        })
    };
}

export const AcceptOrDenineHitchhikeRequest = (data, type = APPROVED) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        data.approve_status_id = type;
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.HitchhikeAcceptOrDenineRequest, payLoad).then(res => {
            console.log(res) // will delete late
            AddNotification("Yêu cầu đã được xử lý", "success")
            return true;
        }).catch(err => {
            throw (err);
        })
    };
}

export const AddOrUpdateHitchhike = (data) => {
    return dispatch => {
        dispatch(createSaveLoading(true));
        let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.HitchhikeAddOrUpdate, payLoad).then(res => {
            dispatch(createSaveHitchhike(res.content));
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false))
            throw (err);
        })
    };
}

// Initial state
export const initialState = Map({
    hitchhikeList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function HitchhikeReducer(state = initialState, action) {
    switch (action.type) {
        case HITCHHIKESAVELIST:
            return {
                ...state,
                hitchhikeList: action.payload.hitchhikeList,
                totalItems: action.payload.totalItems,
            }
        case HITCHHIKE:
            return {
                ...state,
                content: action.data
            }
        default:
            return state;
    }
}