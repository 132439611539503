
//@ts-check
import React, { useState } from 'react';
import { isMobile, isTablet } from "react-device-detect";

const RangerPageNumber = (isMobile) ? 3 : 4;

/**@param {{totalItems:number,size:number,goTo?:Function,index?:number}} [props]*/
function Pagination(props) {
    const totalPages = Math.ceil(props.totalItems / props.size) || 1;
    const [index, setIndex] = useState(props.index || 1);
    const renderPages = [];
    let MinIndex = index - RangerPageNumber;
    let MaxIndex = index + RangerPageNumber;
    if (MinIndex < 1) MinIndex = 1;
    if (MaxIndex > totalPages) MaxIndex = totalPages

    let handleGoToPage = function Print(indexNumber) { };
    if (props.goTo) {
        handleGoToPage = (i) => {
            props.goTo(i);
            window.scrollTo(0, 0)
            setIndex(i);
        }
    }

    const handlePreviousPage = () => {
        if (index > 1) {
            handleGoToPage(index - 1);
            setIndex(index - 1)
        }
    }

    const handleNextPage = () => {
        if (index < totalPages) {
            handleGoToPage(index + 1)
            setIndex(index + 1)
        }
    }

    for (let i = MinIndex; i <= MaxIndex; i++) {
        let active = false;
        if (i === index) active = true;
        renderPages.push(<RenderPageSuquare click={() => handleGoToPage(i)} value={i} key={i} active={active} />)
    }

    return (
        <div className="pagination border-top">
            <ul className="list-inline w-100">
                {(index === 1) ? <PreviousButton disactive={true} click={() => handlePreviousPage()} /> : <PreviousButton click={() => handlePreviousPage()} />}
                {renderPages}
                {(index === totalPages) ? <NextButton disactive={true} click={() => handleNextPage()} /> : <NextButton click={() => handleNextPage()} />}
            </ul>
        </div>
    )
}

function NextButton(props) {
    let classList = "list-inline-item last-page";
    if (props.disactive) classList += " disabled";
    return (
        <li className={classList} onClick={props.click}>
            <a href="#">
                <img src={require('assets/images/last-page.svg')} alt="Clock" className="img-fluid" />
            </a>
        </li>
    )
}

function PreviousButton(props) {
    let classList = "list-inline-item fist-page";
    if (props.disactive) classList += " disabled";
    return (
        <li className={classList} onClick={props.click}>
            <a href="#">
                <img src={require('assets/images/last-page.svg')} alt="Clock" className="img-fluid" />
            </a>
        </li>
    )
}

function RenderPageSuquare(props) {
    if (props.active) return (<li className="list-inline-item">
        <a className="active">{props.value}</a>
    </li>); else return (<li className="list-inline-item" onClick={props.click}>
        <a href="#">{props.value}</a>
    </li>)
}
export default Pagination;
