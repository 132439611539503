/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { ConvertNumberToString } from "utils/configuration";
import '../payment-mobile.scss';

export default function PaymentResultMobileView(props) {
    const vnpResponseCode = new URLSearchParams(props.location.search).get(
        "vnp_ResponseCode"
    );

    const vnpAmount = new URLSearchParams(props.location.search).get(
        "vnp_Amount"
    );

    return (
        <div className="payment-result-mobile">
            <header className="header-mobile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/m">
                                <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                            </Link>
                            <div className="header-title text-center">Kết quả thanh toán</div>
                        </div>
                    </div>
                </div>
            </header>

            {
                vnpResponseCode && vnpResponseCode == '00' ? (
                    <div className="payment-success-mobile text-center p-3">
                        <img src={require("/assets/images/success.svg")} alt="Success" className="img-success mb-4" />

                        <h5 className="text-uppercase mb-3">Giao dịch thành công</h5>

                        <h6 className="mb-2">Hóa đơn đã được thanh toán qua VNPay</h6>

                        <h6 className="mb-3">Số tiền thanh toán <b>{ConvertNumberToString(vnpAmount / 100 || 0)} VNĐ</b></h6>

                        <Link to="/m" className="btn btn-success rounded-pill text-uppercase pl-4 pr-4 pt-2 pb-2">Về trang chủ</Link>
                    </div>
                ) : (
                        <div className="payment-fail-mobile text-center p-3">
                            <img src={require("/assets/images/fail.svg")} alt="Fail" className="img-fail mb-4" />

                            <h5 className="text-uppercase mb-3">GIAO DỊCH KHÔNG THÀNH CÔNG</h5>

                            <h6 className="mb-3">Vui lòng thanh toán lại hoặc liên hệ với chúng tôi!</h6>

                            <Link to="/m" className="btn btn-success rounded-pill text-uppercase pl-4 pr-4 pt-2 pb-2">Về trang chủ</Link>
                        </div>
                    )
            }
        </div>
    )
}