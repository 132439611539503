import Service from "./api.service";
import { ApiUrl } from "./api-url";
const service = new Service();
import { AdHouseCategoryModels } from "../mock-data/buy-and-rent-house.mock";
import { AppKey, createFormData } from "../utils/configuration";

export default class SharedService {
  constructor() {}

  getCareerCategories = () => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service.post(ApiUrl.CareerCategory, payLoad);
  };

  getHomeTowns = () => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service.post(ApiUrl.HomeTownGetList, payLoad);
  };

  getDistricts = (id) => {
    let payLoad = createFormData({
      provinceId: id,
    });
    return service.post(ApiUrl.DistricGetList, payLoad);
  };

  getShopCategory = () => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service.post(ApiUrl.ShopCategories, payLoad);
  };

  getHobbyCategoris = () => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service.post(ApiUrl.HobbyCategoris, payLoad);
  };

  getLandCategoris = () => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service.post(ApiUrl.LandCategoris, payLoad);
  };

  getListGender = () => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service.post(ApiUrl.GenderGetList, payLoad);
  };

  getAdHouseRentCategorisFake = (pageIndex = 1, size = 10) => {
    return new Promise((resolve, reject) => {
      let adHouseRentList = AdHouseCategoryModels.slice(
        pageIndex * size - size,
        pageIndex - 1 + size
      );
      if (adHouseRentList) {
        resolve({
          content: adHouseRentList,
          errorType: "",
          errorMessage: "",
        });
      } else {
        resolve({
          content: [],
          errorType: "Empty",
          errorMessage: "Empty",
        });
      }
    });
  };

  getListCategoryProduct = () => {
    let formData = new FormData();
    let payLoad = createFormData({}, AppKey.GetPayloadPrefix, formData);
    return service.post(ApiUrl.GetListCategory, payLoad);
  };
}
