import { NewGuid, randomDate } from 'utils/configuration';

export const Members = [
    {
        id: NewGuid(),
        name: 'Lê Văn Sơn',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Giám đốc',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',

            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Phan Đình Tuấn',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Phó Giám đốc',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Xuân Lộc',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Phó Giám đốc',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Quảng Bá',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Quảng Bá',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Đình Mão',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Văn Việt',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Văn Việt',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Văn Việt',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Văn Việt',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Văn Việt',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Văn Việt',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
    {
        id: NewGuid(),
        name: 'Nguyễn Văn Việt',
        profileUrl: require('assets/images/29-12.png'),
        position: 'Bảo vệ',
        phone: '0949260275',
        schedules: [
            {
                id: NewGuid(),
                schedule_time: randomDate(new Date(2020, 0, 1), new Date()),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống điện',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Bảo trì hệ thống nước',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
            {
                id: NewGuid(),
                schedule_time: new Date(),
                responsibility: 'Lễ tân',
                contact_phone: '0949260275',
                profileUrl: require('assets/images/29-01.png'),
            },
        ],
    },
]