import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Viewer, {
  Worker,
  SpecialZoomLevel,
  defaultLayout,
} from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import * as procedureActions from "redux/stores/management/procedure.store";
import * as viVN from "translation/vi-VN.json";
import { history } from "redux/store";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType } from "utils/configuration";
import { Link } from "react-router-dom";
import { renderError } from "components/pdf-error-rendering/pdf-error-rendering";

import { browserVersion, isSafari } from "react-device-detect";

if (isSafari === true && browserVersion <= 11) {
  require("intersection-observer");
}

class ProcedureDetailMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procedure: null,
      procedureId: this.props.match.params.procedureId,
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProcedureDetail(this.state.procedureId);
  }

  onGetProcedureDetail = (procedureId) => {
    this.props.getProcedureDetail(procedureId).then(
      (res) => {
        if (res.content) {
          this.setState({
            procedure: res.content,
          });
        } else {
          AddNotification(
            viVN.Errors[res.errorType],
            NotificationMessageType.Error
          );
          history.push("/m/thong-tin-bql/3");
        }
      },
      (err) => {}
    );
  };
  render() {
    const { procedure } = this.state;

    const fileExtension =
      procedure && procedure.url && procedure.url.split(".");
    const errorFile =
      fileExtension &&
      fileExtension.length > 0 &&
      fileExtension[fileExtension.length - 1] === "pdf"
        ? false
        : true;

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
          </div>

          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginLeft: "auto",
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
          </div>
        </div>
      );
    };

    const layoutPDFView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar
      );
    };

    return (
      <div className="procedure-detail-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to={`/m/thong-tin-bql/3`}>
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">BQL</div>
              </div>
            </div>
          </div>
        </header>

        {/* <div className="management-tags mt-3 mb-4">
                    <ul>
                        <li>
                            <a href="#">
                                <img src={require("assets/images/tag.svg")} alt="tag" className="img-tag" /> Tags
                      </a>
                        </li>
                        <li>
                            <a href="#">
                                thủ tục BQL
                      </a>
                        </li>
                        <li>
                            <a href="#">
                                biểu mẫu
                      </a>
                        </li>
                        <li>
                            <a href="#">
                                biểu mẫu đăng ký
                      </a>
                        </li>
                        <li>
                            <a href="#">
                                đăng ký cải tạo
                      </a>
                        </li>
                        <li>
                            <a href="#">
                                phiếu đăng ký
                      </a>
                        </li>
                        <li>
                            <a href="#">
                                thủ tục
                      </a>
                        </li>
                    </ul>
                </div> */}

        {errorFile && <p>Không đúng định dạng</p>}

        {!errorFile && procedure && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div className="pdf-view-container">
              <Viewer
                fileUrl={procedure.url}
                defaultScale={SpecialZoomLevel.PageFit}
                layout={layoutPDFView}
                renderError={renderError}
              />
            </div>
          </Worker>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProcedureDetail: procedureActions.GetProcedureDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcedureDetailMobileView);
