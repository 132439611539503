import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../project-information/project-info.scss";
import { Link } from "react-router-dom";

class ProjectInfoMobileView extends Component {
    render() {
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Thông tin dự án</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="project-info-mobile p-3">
                    <h4>Chủ đầu tư</h4>
                    <div className="row no-gutters">
                        <div className="col-3">
                            Tên công ty:
                        </div>
                        <div className="col-9">
                            Công ty TNHH phát triển đô thị mới An Khánh (An Khánh JVC)
                        </div>

                        <div className="col-3">
                            Địa chỉ:
                        </div>
                        <div className="col-9">
                            Km10+600, Đại lộ Thăng Long, Xã An Khánh, Huyện Hoài Đức, Hà Nội.
                        </div>

                        <div className="col-3">
                            Hotline:
                        </div>
                        <div className="col-9">
                            844. 3755 6541
                        </div>

                        <div className="col-3">
                            Email BQL:
                        </div>
                        <div className="col-9">
                            info@ankhanhjvc.com
                        </div>

                        <div className="col-3">
                            Website:
                        </div>
                        <div className="col-9">
                            <a href="https://splendora.vn/" target="_blank">www.splendora.vn</a>
                        </div>
                    </div>

                    <div className="mt-3">
                        <h4>Công ty quản lý vận hành</h4>
                        <div className="row no-gutters">
                            <div className="col-3">
                                Tên công ty:
                            </div>
                            <div className="col-9">
                                Công ty TNHH Savills Việt Nam - Ban QLVH Khu đô thị Splendora
                            </div>

                            <div className="col-3">
                                Địa chỉ:
                            </div>
                            <div className="col-9">
                                Tầng 1, tòa 101 Chung cư KĐT Splendora Bắc An Khánh, An Khánh, Hoài Đức, Hà Nội
                            </div>

                            <div className="col-3">
                                Hotline:
                            </div>
                            <div className="col-9">
                                091.680.8336
                            </div>

                            <div className="col-3">
                                Email BQL:
                            </div>
                            <div className="col-9">
                                bqlsplendora@savills.com.vn
                            </div>

                            <div className="col-3">
                                Website:
                            </div>
                            <div className="col-9">
                                <a href="https://www.savills.com.vn/" target="_blank">www.savills.com.vn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfoMobileView);
