
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as helpingHandActions from 'redux/stores/help/helping-hand.store';
import HelpingHandItemMobileView from './childs/helping-hand-item-mobile.view';
import PaginationControl from 'components/pagination/pagination.view';
import { Link } from 'react-router-dom';
import '../neighbourhoods-mobile.scss';

class HelpingHandMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
      isToggleAdding: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHelpingHandList();
  }

  onGetHelpingHandList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = null) => {
    this.setState({ isLoading: true });
    this.props.getHelpingHandList(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => {
        // console.log(err);
        this.setState({ isLoading: false });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetHelpingHandList(pageNumber);
    })
  }

  onToggleAddingState = () => {
    this.setState({
      isToggleAdding: !this.state.isToggleAdding
    })
  }
  render() {
    const { helpingHandList, totalItems } = this.props;
    const { currentPageIndex, isToggleAdding } = this.state;
    return (
      <div className="neighbourhoods-mobile helping-hand-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m">
                  <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                </Link>
                <div className="header-title text-center">Giúp một tay</div>
              </div>
            </div>
          </div>
        </header>
        <div className="helping-hand-mobile p-3">
          <HelpingHandItemMobileView helpingHandList={helpingHandList} isToggleAdding={isToggleAdding} callBack={this.onGetHelpingHandList.bind(this)} />
          <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
          <div className="add-helping-hand rounded p-2">
            <div className="row align-items-center no-gutters">
              <div className="col-1">
                <img src={require("assets/images/help-white.svg")} alt="Cần giúp đỡ" className="img-help-white" />
              </div>
              <div className="col-10">
                <h5>
                  <Link to="/m/lang-gieng-gan/giup-mot-tay/gui-yeu-cau">Tôi cần giúp đỡ</Link>
                </h5>
              </div>
              <div className="col-1 text-right">
                <Link to="/m/lang-gieng-gan/giup-mot-tay/gui-yeu-cau">
                  <img src={require("assets/images/plus-white.svg")} alt="plus" className="img-plus" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  helpingHandList: state.helpingHand.helpingHandList,
  totalItems: state.helpingHand.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getHelpingHandList: helpingHandActions.GetHelpingHandList,
  deleteHelpingHand: helpingHandActions.DeleteHelpingHand,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HelpingHandMobileView)