import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./search-mobile.scss";
import * as SearchStore from "../../redux/stores/search/search.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "redux/store";

function SearchMobileView(props) {
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    props.GetListHotSearchKeyword();
  }, []);

  function searchByKeyword(keyword) {
    history.push(`/m/ket-qua-tim-kiem/s=${keyword}`);
  }

  function changeSearchKey(e) {
    setSearchKeyword(e.target.value);
  }

  function checkKeyCode(e) {
    e.keyCode === 13 && document.getElementById("search-mobile-button").click();
  }

  const hotSearchList = props.hotSearchKeywords || [];
  return (
    <div>
      <header className="header-mobile">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <Link to="/m">
                <img
                  src={require("assets/images/arrow-back.svg")}
                  alt="Arrow Back"
                  className="img-fluid img-arrow-back"
                />
              </Link>
              <div className="header-title text-center">Tìm kiếm</div>
            </div>
          </div>
        </div>
      </header>

      <div className="search-mobile p-3">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            value={searchKeyword}
            onKeyDown={(e) => checkKeyCode(e)}
            onChange={changeSearchKey}
            placeholder="Nhập từ khóa"
          />
          <div className="input-group-append">
            <span
              className="input-group-text"
              id="search-mobile-button"
              onClick={() => searchByKeyword(searchKeyword)}
            >
              <img
                src={require("assets/images/search-gray.svg")}
                alt="search-gray"
                className="img-search-gray"
              />
            </span>
          </div>
        </div>

        <h2># Từ khóa hot</h2>

        <div className="hot-keyword">
          {hotSearchList.length > 0 &&
            hotSearchList.map((keyword, index) => (
              <RenderHotSearchKeyWord
                key={index}
                click={() => searchByKeyword(keyword.keywordName)}
                keyword={keyword.keywordName}
              />
            ))}
        </div>

        <hr />

        <div className="row no-gutters thanh-toan">
          <div className="col-4">
            <div className="tien-dien text-center p-1">
              <Link to="/m/thanh-toan/tien-dien">
                <img
                  src={require("assets/images/tien-dien.svg")}
                  alt="Tiền điện"
                  className="img-fluid"
                />
              </Link>
              <Link to="/m/thanh-toan/tien-dien">
                <h5>Tiền điện</h5>
              </Link>
            </div>
          </div>
          <div className="col-4">
            <div className="tien-nuoc text-center p-1">
              <Link to="/m/thanh-toan/tien-nuoc">
                <img
                  src={require("/assets/images/tien-nuoc.svg")}
                  alt="Tiền nước"
                  className="img-fluid"
                />
              </Link>
              <Link to="/m/thanh-toan/tien-nuoc">
                <h5>Tiền nước</h5>
              </Link>
            </div>
          </div>
          <div className="col-4">
            <div className="internet text-center p-1">
              <Link to="/m/thanh-toan/internet">
                <img
                  src={require("assets/images/internet.svg")}
                  alt="Internet"
                  className="img-fluid"
                />
              </Link>
              <Link to="/m/thanh-toan/internet">
                <h5>Internet</h5>
              </Link>
            </div>
          </div>
        </div>

        <hr />

        <div className="row no-gutters lang-gieng-gan">
          <div className="col-4 pr-2">
            <div className="item">
              <img
                src={require("assets/images/ket-ban.svg")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <Link to="/m/lang-gieng-gan/cau-lac-bo/ket-ban">Kết bạn</Link>
            </div>
          </div>
          <div className="col-4">
            <div className="item">
              <img
                src={require("assets/images/giup-mot-tay.svg")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <Link to="/m/lang-gieng-gan/giup-mot-tay">Giúp một tay</Link>
            </div>
          </div>
          <div className="col-4 pl-2">
            <div className="item">
              <img
                src={require("assets/images/chung-tay-15px.svg")}
                alt="Image"
                className="img-fluid"
              />{" "}
              <Link to="/m/lang-gieng-gan/chung-tay">Chung tay</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RenderHotSearchKeyWord(props) {
  return (
    <div>
      <a href="#" onClick={props.click}>
        {props.keyword}
      </a>
    </div>
  );
}

const mapStateToProps = (state) => ({
  hotSearchKeywords: state.search.hotSearchKeywords,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListHotSearchKeyword: SearchStore.GetListHotSearchKeyword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchMobileView);
