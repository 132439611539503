//@ts-check
import React, { useRef, useState } from 'react';
import './register-calendar.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Error, Success } from 'components/support-create-form/validate-style';
import { ConverDateObjectToVietNamCalendar } from 'utils/configuration';
import { Form, Modal, Button } from 'react-bootstrap';
import subDays from "date-fns/subDays";
import { useRegisterCalendarHook } from '../../../../../../components/custom-hook/register-calendar/register-calendar';
const StyleCollect = {
    inputRegister: {
        borderRadius: "20px"
    },
    iconCalendar: {
        position: 'absolute',
        right: '25px',
        top: '13px',
    },
    titleHeader: {
        fontSize: "18px",
    },
}

/**
 * @param {{registerFunction:Function,
 * handleGetCalendarByDate:Function,isLoggedIn:boolean,shopId:number,slotList: {
    slotName: string;
    slotId: number;
    isFull: boolean;
}[]}} [props]
*/

function RegisterCalendar(props) {
    const {
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        slotIdRegister,
        setSlotIdRegister,
        slotNameRegister,
        setSlotNameRegister,
        hasShowRegisterModal,
        setHasShowRegisterModal,
        dateView,
        handleChangeDateView,
        handleShowRegisterModal,
        handleRegister,
        validationUserNameMessage,
        validationPhoneNumberMessage,
    } = useRegisterCalendarHook(props.shopId, props.registerFunction, props.handleGetCalendarByDate)
    return (
        <div className="register-calendar-container p-3">
            <div className="utilities-detail-booking-schedule mt-3">
                <div className="header">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <h5>Lịch đăng ký</h5>
                        </div>
                        <div className="col-6 text-right position-relative">
                            <DatePicker
                                minDate={subDays(new Date(), 0)}
                                selected={dateView}
                                onChange={handleChangeDateView}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                popperPlacement="bottom-end" />
                            <img src={require('assets/images/calendar.svg')} alt="Calendar" className="img-calendar" />
                        </div>
                    </div>
                </div>
                {/* calendar */}
                <div className="yard mt-3">
                    <RenderYardCalendar slotList={props.slotList} clickShow={handleShowRegisterModal} />
                    {
                        (props.slotList.length === 0) && <div className='text-center'>Không có dữ liệu để hiển thị</div>
                    }
                </div>
                {/* Modal register */}
                <Modal centered show={hasShowRegisterModal} onHide={() => setHasShowRegisterModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title style={StyleCollect.titleHeader}>Đăng ký đặt lịch</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div hidden={props.isLoggedIn}>
                            Bạn cần đăng nhập để dùng tính năng này
                    </div>
                        <div hidden={!props.isLoggedIn}>
                            <div className="row">
                                <div className="col-12">
                                    <Form.Group>
                                        <Form.Control style={StyleCollect.inputRegister} type="text" onChange={event => setUserName(event.target.value)} value={userName} name="register_name" placeholder="Họ tên" />
                                    </Form.Group>
                                    {
                                        validationUserNameMessage && <Error content={validationUserNameMessage} />
                                    }
                                </div>
                                <div className="col-12">
                                    <Form.Group>
                                        <Form.Control style={StyleCollect.inputRegister} type="text" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} name="house_number" placeholder="Nhập số điện thoại" />
                                    </Form.Group>
                                    {
                                        validationPhoneNumberMessage && <Error content={validationPhoneNumberMessage} />
                                    }
                                </div>
                                <div className="col-6">
                                    <Form.Group>
                                        <Form.Control style={StyleCollect.inputRegister} type="text" name="slot_id" defaultValue={slotNameRegister} disabled>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-6 position-relative">
                                    <Form.Group>
                                        <Form.Control style={StyleCollect.inputRegister} name="register_date" type="text" defaultValue={ConverDateObjectToVietNamCalendar(dateView)} placeholder="Chọn ngày đăng ký" disabled />
                                    </Form.Group>
                                    <img src={require('/assets/images/calendar.svg')} alt="Calendar" style={StyleCollect.iconCalendar} className="img-fluid position-absolute" />
                                </div>

                                <div className="col-12">
                                    <Button variant="success" onClick={() => handleRegister()} className="form-control rounded-pill" >Đăng ký </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

/**
 * @param {{slotList:{slotName:string,slotId:number,isFull:boolean}[],clickShow:(slotName:string,slotId:number)=>*}} [props]
*/
function RenderYardCalendar(props) {
    const content = [];
    props.slotList.map((data1, index) => {
        content.push(<RenderBox clickShow={props.clickShow} slotName={data1.slotName} key={index} slotId={data1.slotId} isFull={data1.isFull} />)
    })
    return (
        <div className="yard-item">
            <div className="yard-body">
                <div className="row align-items-center">
                    {content}
                </div>
            </div>
        </div>
    )
}

/**
 * @param {{slotName:string,slotId:number,isFull:boolean,clickShow:(slotName:string,slotId:number)=>*}} [props]
*/
function RenderBox(props) {
    let content = (<div className="col-3">
        <div className="yard-box active" title="Bấm vào để đặt sân" onClick={() => props.clickShow(props.slotName, props.slotId)}>
            <h5>{props.slotName}</h5>
            <span>Còn chỗ</span>
        </div>
    </div>)
    if (props.isFull) content = (
        <div className="col-3">
            <div className="yard-box inactive" title="Sân này đã hết chỗ">
                <h5>{props.slotName}</h5>
                <span>Hết chỗ</span>
            </div>
        </div>
    )
    return content
}

export default RegisterCalendar