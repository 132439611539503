import React, { Component } from "react";
import GoogleMapMobile from '../../gmap/googlemap.view';
import Slider from "react-slick";
import * as UtilitiesConsts from '../../../redux/stores/utillities/utiliti-const';
import ItemOfSlider from '../child-component/item-of-list-shop-slide-mobile.view';
import * as PageUrls from '../../PageUrls';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiesStore from '../../../redux/stores/utillities/utilitis-shop.store';
import DefaultMarkerIcon from 'assets/images/processing.svg';

class UtilitiesSportMobileView extends Component {
    /**
     * @constructor
     * @param {{GetListSportNew:Function,
     * listData:{
     * logoUrl: string;
     name: string;
     address: string;
     id: number;
     * }[],
     * }} [props]
    */
    constructor(props) {
        super(props)
        this.state = {
            listMarkerCustom: {
                iconUrl: DefaultMarkerIcon,
                listPosition: []
            }
        }
    }
    /**
     * @param {string} type
     * @returns {string}
    */
    getBaseLinkByType = (type) => {
        switch (type) {
            case UtilitiesConsts.POOLTYPE: return PageUrls.UtilitiesSportPoolDetailMobile;
            case UtilitiesConsts.GYMTYPE: return PageUrls.UtilitiesSportGymDetailMobile;
            case UtilitiesConsts.TENNISTYPE: return PageUrls.UtilitiesSportTennisDetailMobile;
            case UtilitiesConsts.SOCCERTYPE: return PageUrls.UtilitiesSportSoccerDetailMobile;
            default: return ''
        }
    }
    componentDidMount() {
        this.props.GetListSportNew(1, 10)
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.listData.length != this.props.listData.length) {
            const newListMarker = [];
            nextProps.listData.map(shop => {
                newListMarker.push({
                    lng: shop.lng,
                    lat: shop.lat
                })
            })
            this.setState({
                listMarkerCustom: {
                    iconUrl: DefaultMarkerIcon,
                    listPosition: newListMarker
                }
            })
        }
        return true;
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2.3,
            slidesToScroll: 2,
            arrows: false,
        }

        return (
            <div>
                <GoogleMapMobile listMarkerCustom={this.state.listMarkerCustom} />
                <div className="utilities-recovery-mobile p-3">
                    <h2 className="text-center">Khám phá</h2>

                    <div className="row">
                        <div className="col-12">
                            <Slider {...settings}>
                                {
                                    this.props.listData.map((data, index) => <ItemOfSlider detailUrl={this.getBaseLinkByType(data.type)} key={index} data={data} />)
                                }
                            </Slider>
                        </div>
                    </div>
                    <span hidden={!(this.props.listData.length === 0)}>Không có dữ liệu để hiển thị</span>
                    <h5 className="text-center text-blue text-size-content mt-3"><Link to={PageUrls.UtilitiesSportListPageMobile}>Xem thêm <img src={require('../../../assets/images/right-arrow-blue.png')} /></Link></h5>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    listData: state.utilitiShop.sportList.listShop,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetListSportNew: UtilitiesStore.GetListSportNew
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesSportMobileView)