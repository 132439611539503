import {
    Map
} from 'immutable';
import {
    createSaveLoading
} from '../../../core/app.store';
import {
    createFormData,
    AppKey,
    compareValues
} from '../../../utils/configuration';
import Service from '../../../services/api.service';
import {
    ApiUrl
} from '../../../services/api-url';

const service = new Service();
/** Action Types */
const SLIDERSAVELIST = 'SLIDER/SAVELIST';

/** Actions */
export const createSaveSliderList = (sliderModel) => ({
    type: SLIDERSAVELIST,
    payload: {
        sliders: sliderModel.items,
        totalItems: sliderModel.totalItemCount
    }
});

/** Action creators */
export const GetSliderList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        // dispatch(createSaveLoading(true));
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.SliderGetList, payLoad).then(res => {
            //TODO: do something 
            dispatch(createSaveSliderList(res.content));
            // dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            // dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

// Initial state
export const initialState = Map({
    sliderList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function SliderReducer(state = initialState, action) {
    switch (action.type) {
        case SLIDERSAVELIST:
            return {
                ...state,
                sliderList: action.payload.sliders,
                    totalItems: action.payload.totalItems,
            }
            default:
                return state;
    }
}