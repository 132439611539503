import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import ReactStarsRating from 'react-awesome-stars-rating';

function RenderModel(props) {

    const [rating, setRating] = useState(parseFloat(props.vote.starNumber.toString()) || 5);

    const { register, errors, handleSubmit } = useForm({
        mode: "onChange"
    });

    const onSubmit = data => {
        if (props.isEdit) {
            let dataItem = {
                vote_id: props.vote.voteId,
                customer_id: props.vote.customerId,
                service_id: props.serviceId,
                star_number: rating,
                content: data.evaluate
            }
            props.onUpdateEvaluate(dataItem);
        } else {
            props.onDeleteVote(props.vote.voteId);
        }
    };

    return (
        <Modal show={props.show} className={props.isEdit ? "modal-edit" : "modal-delete"} centered onHide={() => props.setShow()}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.isEdit ? <span>Chỉnh sửa đánh giá</span> : <span>Xóa đánh giá?</span>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.vote &&
                        <div>
                            {props.isEdit ?
                                <div>
                                    <h5 className="mb-1">Xếp hạng</h5>
                                    <ReactStarsRating value={rating} onChange={value => setRating(value)} isEdit={true} size={20} />
                                    <h5 className="mt-2">Đánh giá</h5>
                                    <div className="form-group">
                                        <textarea name="evaluate" id="evaluate" defaultValue={props.vote.content || ''} className={"form-control " + (errors.evaluate ? "is-invalid" : "")} placeholder="Nhập đánh giá" rows="5"
                                            ref={register({
                                                required: "Trường này là bắt buộc"
                                            })}>
                                        </textarea>
                                        {errors.evaluate && <div className="invalid-feedback">{errors.evaluate.message}</div>}
                                    </div>
                                </div>
                                :
                                <div>
                                    Đánh giá sẽ không thể khôi phục sau khi xóa.
                                    Bạn có chắc muốn xóa đánh giá này.
                                </div>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setShow()}>
                        Hủy
                </Button>
                    {props.isEdit ?
                        <Button variant="success" type="submit">
                            Lưu thay đổi
                    </Button>
                        :
                        <Button variant="success" type="submit">
                            Xóa
                    </Button>
                    }
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default RenderModel;