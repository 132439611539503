/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import './buy-and-rent-houses-mobile.scss';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adHouseRentActions from 'modules/advertising-desktop/buy-and-rent-houses/buy-and-rent-houses.store';
import SharedService from 'services/share.service.js';
import PaginationControl from 'components/pagination/pagination.view';
import { Modal, Button } from 'react-bootstrap';
const sharedService = new SharedService();
import { JwtHelper } from '/utils/jwt-helper';
import { transformCurrency, SetDefaultErrorImage } from 'utils/configuration';

class BuyAndRentHousesMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            landCategoris: [],
            currentPageIndex: 1,
            category_id: '',
            visibleModal: false,
            selectedLandId: null,
            currentUser: JwtHelper.currentUser()
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetLandList();
        this.onGetDropdownData();
    }

    onGetLandList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = null) => {
        this.setState({ isLoading: true });
        this.props.getListHouseRent(pageIndex, size, filterObjs).then(
            res => {
                this.setState({ isLoading: false });
            },
            err => {
                this.setState({ isLoading: false });
            }
        );
    };

    onGetDropdownData = () => {
        Promise.all([
            sharedService.getLandCategoris(),
        ]).then(res => {
            const [landCategorisModel] = res;
            this.setState({
                landCategoris: landCategorisModel.content,
            })
        }).catch(err => {
        })
    };

    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            currentPageIndex: 1
        }, () => {
            this.onGetLandList(1, 10, { category_id: this.state.category_id });
        });
    }

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetLandList(pageNumber);
        })
    }

    onDeleteAdHouse = (confirm) => {
        if (confirm) {
            this.props.deleteAdHouse(this.state.selectedLandId).then(res => {
                this.onToggleDeleteModal(false);
                this.onGetLandList(1);
            }, err => {

            });
        } else {
            this.onToggleDeleteModal(false);
        }
    }

    onToggleDeleteModal = (visibleModal, landId) => {
        this.setState({
            visibleModal: visibleModal,
            selectedLandId: landId
        })
    }

    render() {
        const { landCategoris, currentPageIndex, visibleModal, currentUser } = this.state;
        const { landList, totalItems, loggedIn } = this.props;
        return (
            <div className="buy-and-rent-houses-mobile p-3">
                <div className="form-group select-type-estate">
                    <label>Chọn loại hình bất động sản</label>
                    <select className="form-control" disabled={this.state.isLoading} name="category_id" onChange={this.onFilterChange.bind(this)} value={this.state.category_id}>
                        <option value="">Chọn loại hình</option>
                        {landCategoris.map(item =>
                            <option key={item.categoryId} value={item.categoryId}>{item.categoryName}</option>
                        )}
                    </select>
                </div>

                <div className="buy-and-rent-houses-list">
                    {
                        landList.map((land) =>
                            <RenderBuyAndRentHousesItem data={land} customerId={currentUser && currentUser.customerId} loggedIn={loggedIn} key={land.landId} openDeleteModal={this.onToggleDeleteModal.bind(this)} />
                        )
                    }
                    {landList.length <= 0 && <p className="col-12">Chưa có bài đăng nào.</p>}
                </div>
                <DeleteHouseModal showDeleteModal={visibleModal} deleteAdHouse={this.onDeleteAdHouse} />

                <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />

                {loggedIn && <Link to="/m/mua-ban-nha/dang-tin" className="btn btn-success rounded-pill btn-add-buy-and-rent">
                    <img src={require('assets/images/report-problem-white.svg')} alt="report-problem-white" className="img-report-problem-white" /> Đăng tin mới
                </Link>}
            </div>
        );
    }
}

function RenderBuyAndRentHousesItem(props) {
    const data = props.data;
    const onOpenDeleteModal = (landId) => {
        props.openDeleteModal(true, landId);
    }

    return (
        <div className="buy-and-rent-houses-item rounded">
            <img onError={SetDefaultErrorImage} src={data.imageUrl} className="img-fluid w-100" />
            <div className="p-2">
                <h4>
                    <Link to={`/m/mua-ban-nha/${data.landId}`}>
                        [{data.categoryName && data.categoryName.toUpperCase()}] {data.title}
                    </Link>
                </h4>
                <div className="row no-gutters">
                    <div className="col-6">
                        <h5>Giá: <span>{transformCurrency(data.price)} VNĐ</span></h5>
                    </div>
                    <div className="col-6">
                        <h5>Diện tích: <span>{data.size}m²</span></h5>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-6">
                        <span>Phòng ngủ: {data.bedroom}</span>
                    </div>
                    <div className="col-6">
                        <span>Vệ sinh: {data.wc}</span>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-6">
                        <span>Phòng khách: {data.livingRoom}</span>
                    </div>
                    <div className="col-6">
                        <span>Bếp: {data.kitchen}</span>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-7">
                        {
                            props.loggedIn && data.customerId == props.customerId &&
                            (
                                <div>
                                    <Link to={`/m/mua-ban-nha/dang-tin/${data.landId}`} className="text-primary">Chỉnh sửa</Link>
                                    <a className="text-danger ml-4" onClick={() => onOpenDeleteModal(data.landId)}>Xóa</a>
                                </div>
                            )
                        }
                    </div>

                    <div className="col-5 text-right">
                        <a href={'tel:' + data.mobileNumber} className="btn btn-success">
                            <img src={require('assets/images/phone-call.svg')} alt="phone-call" className="img-phone-call" /> Liên hệ
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DeleteHouseModal(props) {
    return (
        <Modal show={props.showDeleteModal} className="modal-delete" centered onHide={() => props.deleteAdHouse(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Xóa bài viết?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Bài viết sẽ không thể khôi phục sau khi xóa.
          <br />
          Bạn có chắc muốn xóa bài viết này.
        </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.deleteAdHouse(false)}>Hủy</Button>
                <Button variant="success" onClick={() => props.deleteAdHouse(true)}>Xóa</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => ({
    landList: state.adHouseRent.landList,
    totalItems: state.adHouseRent.totalItems,
    loggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getListHouseRent: adHouseRentActions.GetAdHouseList,
    deleteAdHouse: adHouseRentActions.DeleteAdHouse,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BuyAndRentHousesMobileView)
