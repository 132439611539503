/* eslint-disable react/prop-types */
//@ts-check
import React from "react";
import { Link } from "react-router-dom";

import * as PageUrls from "../../../PageUrls";

import { SetDefaultErrorImage } from "../../../../utils/configuration";

const shopType = {
  FOOTBALL: "football",
  GYM: "gym",
  POOL: "pool",
  TENNIS: "tennis",
};

function AllListView(props) {
  function RenderPageUrlByShopType(type) {
    switch (type) {
      case shopType.FOOTBALL:
        return PageUrls.UtilitiesSoccerDetailDeskTop;
      case shopType.GYM:
        return PageUrls.UtilitiesGymDetailDeskTop;
      case shopType.POOL:
        return PageUrls.UtilitiesPoolDetailDeskTop;
      case shopType.TENNIS:
        return PageUrls.UtilitiesTennisDetailDeskTop;
    }
  }
  return (
    <div className="row">
      {props.listData.map((data, index) => (
        <Link
          key={index}
          to={RenderPageUrlByShopType(data.type) + data.id}
          style={{ textDecoration: "none" }}
          className="d-block col-6"
        >
          <div className="utilities-list-item">
            <img
              src={data.logoUrl}
              alt={data.name}
              onError={SetDefaultErrorImage}
            />
            <div className="utilities-list-body bg-white p-3">
              <h5 className="text-black">{data.name}</h5>
              <span className="d-block text-truncate">
                <img
                  src={require("assets/images/place-gray.svg")}
                  alt="place"
                  className="img-fluid"
                />
                {data.address}
              </span>
            </div>
          </div>
        </Link>
      ))}
      {props.listData.length === 0 && (
        <div className="col-12 text-center">Không có dữ liệu để hiển thị</div>
      )}
    </div>
  );
}

export default AllListView;
