import React, { useState } from 'react';
import { getThisProjectLocation } from './project-location';
// saddr : vị trí bắt đầu, daddr đích đến
// các props:  props.daddr_lat, props.daddr_lng, props.content
// có delay trong việc lấy vị trí hiện tại
export default function GmapDirect(props) {
    const projectLocation = getThisProjectLocation();
    const [saddr_lat, setSaddr_lat] = useState(projectLocation.lat);
    const [saddr_lng, setSaddr_lng] = useState(projectLocation.lng);
    let daddr_lat = props.daddr_lat || projectLocation.lat;
    let daddr_lng = props.daddr_lng || projectLocation.lng;
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((CurrentPosition) => {
            setSaddr_lat(String(CurrentPosition.coords.latitude))
            setSaddr_lng(String(CurrentPosition.coords.longitude))
        })
    }
    return (
        <a target="_blank" href={`https://maps.google.com/maps?saddr=${saddr_lat},${saddr_lng}&daddr=${daddr_lat},${daddr_lng}`}>{props.content}</a>
    )
}