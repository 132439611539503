/* eslint-disable react/prop-types */
//@ts-check
import React from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { Error } from "components/support-create-form/validate-style";
import { JwtHelper } from 'utils/jwt-helper';
import './register-form.scss';
import { useRegisterFormHook } from '../../../../../../components/custom-hook/register-form-sport/register-form';
import * as viVN from '../../../../../../translation/vi-VN.json';

const userInfo = JwtHelper.currentUser() || { customerId: 0, customerName: '', apartmentId: 0, mobileNumber: '', houseNumber: '' };

function RegisterFormView(props) {
    const {
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        validateUserName,
        validatePhoneNumber,
        setRegisterMonth,
        handleRegister,
        hasRegisted
    } = useRegisterFormHook(props.shopId, props.registerFunction)

    const from2to6 = props.from2to6;
    const from7to1 = props.from7to1;

    return (
        <div className="register-form-container p-3">
            <div hidden={(props.from2to6.length === 0) && (props.from7to1.length === 0)}>
                <h3 className="mt-3">Lịch Hoạt Động</h3>
                <Table striped>
                    <thead>
                        <tr>
                            <th className="border-0"></th>
                            <th className="border-0 text-center">Thứ 2 - Thứ 6</th>
                            <th className="border-0 text-center">Thứ 7 & Chủ nhật</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border-0"><strong>Sáng</strong></td>
                            <td className="border-0 text-center">{from2to6[0]}</td>
                            <td className="border-0 text-center">{from7to1[0]}</td>
                        </tr>
                        <tr>
                            <td className="border-0"><strong>Trưa</strong></td>
                            <td className="border-0 text-center">{from2to6[1]}</td>
                            <td className="border-0 text-center">{from7to1[1]}</td>
                        </tr>
                        <tr>
                            <td className="border-0"><strong>Chiều</strong></td>
                            <td className="border-0 text-center">{from2to6[2]}</td>
                            <td className="border-0 text-center">{from7to1[2]}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <h3>Gia hạn thành viên</h3>
            <div className="utilities-intro-mobile mb-3" dangerouslySetInnerHTML={{ __html: props.paymentGuide }}></div>
            <h3>Đăng ký sử dụng</h3>
            {!hasRegisted ? (
                <div className={`form-utilities-detail-mobile ${props.isLoggedIn ? "" : 'disabled-event'}`}>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    value={userName}
                                    onChange={event => setUserName(event.target.value)}
                                    name="register_name"
                                    placeholder="Họ tên"
                                    className="rounded-pill"
                                />
                                {
                                    validateUserName && <Error content={validateUserName} />
                                }
                            </Form.Group>
                        </div>
                        <div className="col-6 pr-1">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    value={phoneNumber}
                                    onChange={event => setPhoneNumber(event.target.value)}
                                    name="mobile"
                                    placeholder="Nhập SĐT"
                                    className="rounded-pill"
                                />
                                {
                                    validatePhoneNumber && <Error content={validatePhoneNumber} />
                                }
                            </Form.Group>
                        </div>
                        <div className="col-6 pl-1">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    defaultValue={userInfo.houseNumber}
                                    name="house_number"
                                    placeholder="Nhập số căn hộ"
                                    disabled
                                    className="rounded-pill"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6 pl-4 pr-1" hidden={!props.isGymBody}>
                            <div key={`custom-radio`} className="mb-3">
                                <Form.Check
                                    custom
                                    defaultChecked
                                    label="Gói 1 tháng"
                                    type="radio"
                                    id={`custom-gym-1`}
                                    name="register-gym"
                                    onClick={() => setRegisterMonth(1)}
                                />
                            </div>
                        </div>
                        <div className="col-6 pl-4">
                            <div key={`custom-radio`} className="mb-3" hidden={!props.isGymBody}>
                                <Form.Check
                                    custom
                                    label="Gói 3 tháng"
                                    type="radio"
                                    id={`custom-gym-2`}
                                    name="register-gym"
                                    onClick={() => setRegisterMonth(3)}
                                />
                            </div>
                        </div>
                        <div className="col-6 pl-4 pr-1">
                            <div key={`custom-radio`} className="mb-3" hidden={!props.isGymBody}>
                                <Form.Check
                                    custom
                                    label="Gói 6 tháng"
                                    type="radio"
                                    id={`custom-gym-3`}
                                    name="register-gym"
                                    onClick={() => setRegisterMonth(6)}
                                />
                            </div>
                        </div>
                        <div className="col-6 pl-4">
                            <div key={`custom-radio`} className="mb-3" hidden={!props.isGymBody}>
                                <Form.Check
                                    custom
                                    label="Gói 12 tháng"
                                    type="radio"
                                    id={`custom-gym-4`}
                                    name="register-gym"
                                    onClick={() => setRegisterMonth(12)}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <Button
                                variant="success"
                                className="form-control rounded-pill"
                                onClick={() => handleRegister()}
                            >
                                Đăng ký
                                </Button>
                        </div>
                    </div>
                </div>
            ) :
                (
                    <p className="text-success text-center mt-3">
                        {viVN.Utilities.RegisterSuccess}
                    </p>
                )
            }
        </div>
    )
}

export default RegisterFormView