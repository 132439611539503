import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as hourlyMaidActions from 'redux/stores/hourly-maid/hourly-maid.store';
import "../advertising-desktop.scss";
import AddHourlyMaidDesktopView from './hourly-maid-actions/add-hourly-maid-desktop.view';
import HourlyMaidItemDesktopView from './childs/hourly-maid-item.view';
import PaginationControl from 'components/pagination/pagination.view';
import { AssistType } from 'utils/configuration';

class HourlyMaidDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 1,
      isToggleAdding: false
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetHourlyMaidList();
  }

  onGetHourlyMaidList = (pageIndex = 1, size = 10, filterObjs = { category_type: AssistType.HourlyMaid }) => {
    this.setState({ isLoading: true });
    this.props.getHourlyMaidList(pageIndex, size, filterObjs).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => {
        this.setState({ isLoading: false });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState({
      currentPageIndex: pageNumber
    }, () => {
      this.onGetHourlyMaidList(pageNumber);
    })
  }

  onToggleAddingState = () => {
    this.setState({
      isToggleAdding: !this.state.isToggleAdding
    })
  }

  render() {
    const { hourlyMaidList, totalItems } = this.props;
    const { currentPageIndex, isToggleAdding } = this.state;
    return (
      <div className="advertising-desktop">
        <div className="row align-items-center mb-3">
          <div className="col-6">
            <h2 className="title-lg mb-0">Giúp việc theo giờ</h2>
          </div>
        </div>
        <AddHourlyMaidDesktopView onToggleAddingState={this.onToggleAddingState.bind(this)} callBack={this.onGetHourlyMaidList.bind(this)} />
        <HourlyMaidItemDesktopView hourlyMaidList={hourlyMaidList} isToggleAdding={isToggleAdding} callBack={this.onGetHourlyMaidList.bind(this)} />
        <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hourlyMaidList: state.hourlyMaid.hourlyMaidList,
  totalItems: state.hourlyMaid.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getHourlyMaidList: hourlyMaidActions.GetHourlyMaidList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HourlyMaidDesktopView)