/* eslint-disable no-unused-vars */
import { createSaveLoading } from "core/app.store";
import { createFormData, AppKey } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();

export const clubType = {
  GOLF: 1,
  TENNIS: 2,
  CAU_LONG: 3,
  BONG_BAN: 4,
};

export const GetListCategory = () => {
  return (dispatch) => {
    let payLoad = createFormData();
    return service
      .post(ApiUrl.GetListCategoryClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

//--- Post

export const GetListPost = (pageIndex, size, filterObj) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    let payLoad = createFormData(filterObj, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.GetListClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetPostDetail = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      clubId: id,
    });
    return service
      .post(ApiUrl.GetClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdatePost = (data, formData = new FormData()) => {
  return (dispatch) => {
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix, formData);
    return service
      .post(ApiUrl.UpdateClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const PostDelete = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      clubId: id,
    });
    return service
      .post(ApiUrl.DeleteClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

//--- Comment

export const GetListComment = (pageIndex, size, filterObj, id, parentId) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("clubId", id);
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    parentId && formData.append("parent_id", parentId);
    let payLoad = createFormData(filterObj, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.GetListCommentClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetCommentDetail = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      commentId: id,
    });
    return service
      .post(ApiUrl.GetCommentClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateComment = (data) => {
  return (dispatch) => {
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
    return service
      .post(ApiUrl.UpdateCommentClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const CommentDelete = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      commentId: id,
    });
    return service
      .post(ApiUrl.DeleteCommentClub, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};
