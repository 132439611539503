//@ts-check
import './utilities-mobile.scss';

import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import UtilitiesSportMobileView from './sport/sport-mobile.view.jsx';
import UtilitiesSpaMobileView from './spa/spa-mobile.view';
import HeaderSliderItemMobile from './child-component/home-page-child/header-slider-item.view';
import * as PageUrls from '../PageUrls';
import CafeHomePageMobile from './cafe/cafe-home-mobile.view';
import ShoolHomePageMobile from './school/school-home-mobile.view';
import SuperMarketHomePageMobile from './super-market/super-market-home-mobile.view';
import RestaurantHomePageMobile from './restaurant/restaurant-home-mobile.view';
import HeaderPageMobileView from '../../components/header-page-mobile/header-page-mobile.view';
import * as ImageCollects from '../../utils/image';
/**
 * @param {{match:{path:string},userInfo:object}} [props]
*/
function UtilitiesMobileView(props) {
  const SliderRef = useRef(null);
  const [mainContent, setMainContent] = useState(null)
  const CurrentPath = props.match.path;
  useEffect(() => {
    switch (props.match.path) {
      case PageUrls.UtilitiesSportHomeMobile: setMainContent(<UtilitiesSportMobileView />); break;
      case PageUrls.UtilitiesSpaHomeMobile: setMainContent(<UtilitiesSpaMobileView />); break;
      case PageUrls.UtilitiesCafeHomeMobile: {
        setMainContent(<CafeHomePageMobile />);
        SliderRef.current.slickPrev();
      }; break;
      case PageUrls.UtilitiesRestaurantHomeMobile: {
        setMainContent(<RestaurantHomePageMobile />);
        SliderRef.current.slickNext()
      }; break;
      case PageUrls.UtilitiesSchoolHomeMobile: setMainContent(<ShoolHomePageMobile />); break;
      case PageUrls.UtilitiesSuperMarketHomeMobile: setMainContent(<SuperMarketHomePageMobile />); break;
      default: setMainContent(null)
    }
  }, [props.match.path])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
  }

  return (
    <div className="utilities-mobile">
      <HeaderPageMobileView title="Tiện ích" goBackUrl={PageUrls.UtilitiesListMobile} />

      <div className="utilities-mobile-nav">
        <Slider {...settings} ref={slider => SliderRef.current = slider}>
          <HeaderSliderItemMobile iconPath={ImageCollects.IconSport}
            label="Thể thao" utilitiesUrl={PageUrls.UtilitiesSportHomeMobile} matchPath={CurrentPath} />
          <HeaderSliderItemMobile iconPath={ImageCollects.IconSpa}
            label="Làm đẹp" utilitiesUrl={PageUrls.UtilitiesSpaHomeMobile} matchPath={CurrentPath} />
          <HeaderSliderItemMobile iconPath={ImageCollects.IconCafe}
            label="Cafe" utilitiesUrl={PageUrls.UtilitiesCafeHomeMobile} matchPath={CurrentPath} />
          <HeaderSliderItemMobile iconPath={ImageCollects.IconRestaurant}
            label="Nhà hàng" utilitiesUrl={PageUrls.UtilitiesRestaurantHomeMobile} matchPath={CurrentPath} />
          <HeaderSliderItemMobile iconPath={ImageCollects.IconSupermarket}
            label="Siêu thị" utilitiesUrl={PageUrls.UtilitiesSuperMarketHomeMobile} matchPath={CurrentPath} />
          <HeaderSliderItemMobile iconPath={ImageCollects.IconSchool}
            label="Trường học" utilitiesUrl={PageUrls.UtilitiesSchoolHomeMobile} matchPath={CurrentPath} />
        </Slider>
      </div>

      <div className="mobile-divide"></div>
      {
        mainContent
      }
    </div>
  );
}

export default UtilitiesMobileView;