/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NotificationMessageType,
  SetDefaultErrorImage,
} from "utils/configuration";
import { history } from "redux/store";
import * as userActions from "redux/stores/user/user-information.store";
import * as shopActions from "redux/stores/shop/shop.store";
import AddNotification from "components/react-notifications/react-notifications";
import "react-datepicker/dist/react-datepicker.css";
import { JwtHelper } from "utils/jwt-helper";
import * as viVN from "translation/vi-VN.json";

import MyShopDesktopView from "./my-shop/my-shop-desktop.view";
import UserMessageDesktopView from "./user-message/user-message-desktop.view";
import MessageBoxDesktopView from "./message-box/message-box-desktop.view";
import DocumentManagementDesktopView from "./document-management/document-management-desktop.view";
import UserInformation from "./information/information-form";
import ResetPassword from "./reset-password/reset-password-form";
import RegisterShop from "./register-shop/register-shop.view";

import "../user-desktop.scss";
import "../user-information/user-information-desktop.scss";
class UserInformationDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInformation: this.props.isInformation || true,
      isResetPassword: this.props.isResetPassword || false,
      isShowMessage: this.props.isShowMessage || false,
      isRegisterShop: this.props.isRegisterShop || false,
      isDocumentManagement: this.props.isDocumentManagement || false,
      isDocumentManagementDetail: false,
      user: null,
      member: [],
      shopList: [],
    };

    !this.props.loggedIn && history.push("/");
  }

  UNSAFE_componentWillMount() {
    const userInfo = JwtHelper.currentUser();
    if (this.props.loggedIn && userInfo) {
      this.onGetUserInformation(userInfo.customerId);
    } else {
      history.push("/");
    }
  }

  onGetUserInformation(userId) {
    this.props.GetUserInformation(userId).then(
      (res) => {
        if (res && res.content) {
          this.onGetShopList();
          this.setState({
            user: res.content,
            member: res.content.otherCustomer,
            isInformation: Number(this.props.match.params.id) ? false : true,
            isShowMessage: Number(this.props.match.params.id) ? true : false,
          });
        } else {
          AddNotification(
            viVN.Errors[res && res.errorType],
            NotificationMessageType.Error
          );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[err && err.errorType],
          NotificationMessageType.Error
        );
        history.push("/");
      }
    );
  }

  onGetShopList = () => {
    this.props.onGetListShopByUser().then(
      (res) => {
        this.setState({
          shopList: res.content,
        });
      },
      () => {}
    );
  };

  onShowInformationForm = () => {
    this.setState({
      isInformation: true,
      isResetPassword: false,
      isShowMessage: false,
      isRegisterShop: false,
      isDocumentManagement: false,
      isDocumentManagementDetail: false,
    });
  };

  onShowResetPasswordForm = () => {
    this.setState({
      isInformation: false,
      isResetPassword: true,
      isShowMessage: false,
      isRegisterShop: false,
      isDocumentManagement: false,
      isDocumentManagementDetail: false,
    });
  };

  onShowMessageBox = () => {
    this.setState({
      isInformation: false,
      isResetPassword: false,
      isShowMessage: true,
      isRegisterShop: false,
      isDocumentManagement: false,
      isDocumentManagementDetail: false,
    });
  };

  onShowRegisterShop = () => {
    this.setState({
      isInformation: false,
      isResetPassword: false,
      isShowMessage: false,
      isRegisterShop: true,
      isDocumentManagement: false,
      isDocumentManagementDetail: false,
    });
  };

  onShowDocumentManagement = () => {
    this.setState({
      isInformation: false,
      isResetPassword: false,
      isShowMessage: false,
      isRegisterShop: false,
      isDocumentManagement: true,
      isDocumentManagementDetail: false,
    });
  };

  onShowDocumentManagementDetail = () => {
    this.setState({
      isInformation: false,
      isResetPassword: false,
      isShowMessage: false,
      isRegisterShop: false,
      isDocumentManagement: true,
      isDocumentManagementDetail: true,
    });
  };

  render() {
    const {
      user,
      member,
      shopList,
      isShowMessage,
      isInformation,
      isResetPassword,
      isRegisterShop,
      isDocumentManagement,
      isDocumentManagementDetail,
    } = this.state;
    return (
      <div className="user-desktop">
        {user && (
          <div>
            <div className="bg-white p-3 mt-4 user-info-header">
              <div className="row align-items-center">
                <div className="col-1">
                  <img
                    src={user.avatarUrl || require("assets/images/29-12.png")}
                    className="img-avatar-circle rounded-circle"
                    onError={SetDefaultErrorImage}
                    alt={user.customerName || "Avatar"}
                  />
                </div>
                <div className="col-4">
                  <h5 className="title-md mb-0">{user.customerName}</h5>
                  {user.houseNumber && (
                    <span className="font-weight-light">
                      Căn hộ: {user.houseNumber}
                    </span>
                  )}
                </div>
                <div className="col-7 text-right">
                  <a
                    onClick={() => this.onShowInformationForm()}
                    className={isInformation ? "active" : ""}
                  >
                    <img
                      src={require("assets/images/edit-blue.svg")}
                      className="img-fluid"
                      alt="Edit"
                    />
                    Thông tin cá nhân
                  </a>
                  <a
                    onClick={() => this.onShowResetPasswordForm()}
                    className={"ml-4" + (isResetPassword ? " active" : "")}
                  >
                    <img
                      src={require("assets/images/reset-password.svg")}
                      className="img-fluid"
                      alt="Reset password"
                    />
                    Đặt lại mật khẩu
                  </a>
                  <a
                    onClick={() => this.onShowRegisterShop()}
                    className={"ml-4" + (isRegisterShop ? " active" : "")}
                  >
                    <img
                      src={require("assets/images/registration.svg")}
                      className="img-fluid"
                      alt="Đăng ký bán hàng"
                    />
                    Đăng ký bán hàng
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                {member && member.length > 0 && (
                  <div className="bg-white p-3 mt-4">
                    <div
                      className={
                        "user-friend" +
                        (member.length > 5 ? "" : " overflow-hidden")
                      }
                    >
                      {member.map((item) => (
                        <UserFriend
                          user={item}
                          key={item.customerId}
                          scroll={member.length > 5}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <MyShopDesktopView shopList={shopList} />

                <div className="bg-white p-3 mt-4">
                  <div className="document-management">
                    <img
                      src={require("assets/images/double-file.svg")}
                      alt="Shop Blue"
                      className="img-fluid"
                    />
                    <a onClick={() => this.onShowDocumentManagement()}>
                      Quản lý giấy tờ
                    </a>
                  </div>
                </div>

                <UserMessageDesktopView
                  match={this.props.match}
                  isShowMessage={isShowMessage}
                  onShowMessageBox={this.onShowMessageBox.bind(this)}
                />
              </div>

              {isInformation && (
                <div className="col-8">
                  <div className="bg-white p-3 mt-4">
                    <h3 className="title-sm mb-0">Thông tin cá nhân</h3>
                    <hr></hr>
                    <div className="user-info">
                      <UserInformation user={user} />
                    </div>
                  </div>
                </div>
              )}

              {isResetPassword && (
                <div className="col-8">
                  <div className="bg-white p-3 mt-4">
                    <h3 className="title-sm mb-0">Đặt lại mật khẩu</h3>
                    <hr></hr>
                    <div className="user-reset-password">
                      <ResetPassword user={user} />
                    </div>
                  </div>
                </div>
              )}

              {isRegisterShop && (
                <div className="col-8">
                  <div className="bg-white p-3 mt-4">
                    <h3 className="title-sm mb-0">Đăng ký bán hàng</h3>
                    <hr></hr>
                    <div className="user-register-shop">
                      <RegisterShop user={user} />
                    </div>
                  </div>
                </div>
              )}

              {isShowMessage && <MessageBoxDesktopView />}

              {isDocumentManagement && (
                <DocumentManagementDesktopView
                  user={user}
                  isDocumentManagement={isDocumentManagement}
                  isDocumentManagementDetail={isDocumentManagementDetail}
                  onShowDocumentManagementDetail={this.onShowDocumentManagementDetail.bind(
                    this
                  )}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function UserFriend(props) {
  const { user, scroll } = props;
  return (
    <div className={"item" + (scroll ? " mr-2" : "")}>
      <div className="row align-items-center no-gutters">
        <div className="col-2">
          <img
            src={user.avatarUrl || require("assets/images/29-01.png")}
            className="img-fluid"
            alt={user.customerName || "Avatar"}
          />
        </div>
        <div className="col-6">
          <h5 className="mb-0 text-truncate">{user.customerName}</h5>
        </div>
        <div className="col-4 text-right">
          <span>Thành viên</span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetUserInformation: userActions.GetUserInformation,
      onGetListShopByUser: shopActions.GetShopList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformationDesktopView);
