import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './search-result-mobile.scss';
import * as SearchStore from '../../redux/stores/search/search.store';
import Pagination from '../../components/pagination/paging.view.jsx';

class SearchResultMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 8,
            pageIndex: 1,
            keyword: "",
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            keyword: this.props.match.params.keyword
        })
        this.props.GetSearchResulList(1, this.state.size, this.props.match.params.keyword)
    }

    goToPage(i) {
        this.props.GetSearchResulList(i, this.state.size, this.state.keyword)
        this.setState({
            pageIndex: i
        })
    }

    render() {
        const listResult = this.props.resultList || [];
        return (
            <div>
                <header className="header-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link to="/m/tim-kiem">
                                    <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                                </Link>
                                <div className="header-title text-center">Kết quả tìm kiếm</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="search-result-mobile p-3">
                    <ul>
                        {listResult.map((data, index) => <RenderItem data={data} index={(this.state.pageIndex - 1) * this.state.size + index + 1} key={index} />)}
                    </ul>
                    {(listResult.length === 0) && <p>Không có kết quả nào khớp cho: {this.props.match.params.keyword}</p>}
                </div>
                <Pagination goTo={(i) => this.goToPage(i)} size={this.state.size} totalItems={this.props.totalItems} />

            </div>
        );
    }
}

function RenderItem(props) {
    const data = props.data;
    return (
        <li>
            <a href={data.url} target="_blank">
                {props.index}. {data.title}
            </a>
        </li>
    )
}

const mapStateToProps = state => ({
    resultList: state.search.resultList,
    totalItems: state.search.totalResult
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetSearchResulList: SearchStore.GetSearchResulList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultMobileView)