//@ts-check
import React from 'react';
import './shop-list-page.scss';
import PaginationControl from '../../../../components/pagination/paging.view';
import * as PageUrls from '../../../PageUrls';
import ListThumbnailPreviewShopDesktop from './list-thumbnail.view';
import UtilitiesListOther from '../utilities-list-other/utilities-list-other.view';
/**
 * @param {{
 * currentTypeUrl:string,
 * pageSize:number,pageIndex:number,totalItems:number,getDataByPageIndex:Function,detailUrl:string,titleShopType:string,listData:{
    logoUrl: string;
    name: string;
    address: string;
    id: number;
    type: string;
}[]}} [props]
*/
function RenderShopListPageViewDesktop(props) {
    return (
        <div className="shop-list-page-container">
            <div className="row align-items-center">
                <div className="col-12">
                    <h2 className="title-lg mb-0 text-size-title">{props.titleShopType}</h2>
                </div>
            </div>

            <hr />

            <ListThumbnailPreviewShopDesktop detailUrl={props.detailUrl} listData={props.listData} />
            <PaginationControl totalItems={props.totalItems} goTo={props.getDataByPageIndex} index={props.pageIndex} size={props.pageSize} />

            <UtilitiesListOther currentTypeUrl={props.currentTypeUrl} />
        </div>
    )
}

export default RenderShopListPageViewDesktop