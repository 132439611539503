import { NewGuid } from 'utils/configuration';
let count = 1;
export const SecurityCameras = [
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-01.png',
        url: 'https://www.youtube.com/embed/RUYObkO8tsY',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-02.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-03.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
    {
        id: NewGuid(),
        name: 'Camera' + count++,
        thumbnail_url: 'assets/images/CAM-04.png',
        url: '',
    },
]