import React from 'react';
import "./authentication-warning.scss";

function handleClickLogin() {
    if (document.getElementById("buttonLogin")) document.getElementById("buttonLogin").click()
}

function handleClickRegistration() {
    if (document.getElementById("buttonRegistration")) document.getElementById("buttonRegistration").click()
}

function AuthenticationWarningDesktopView(props) {
    return (
        <div className="authentication-warning bg-white p-3 rounded">
            <div className="row">
                <div className="col-12 text-center">
                    <img src={require("/assets/images/atention.svg")}></img>
                    <h5>Bạn cần đăng nhập để xem nội dung này</h5>
                    <button className="btn btn-success" onClick={() => handleClickLogin()}>ĐĂNG NHẬP</button>
                    <p>Bạn chưa có tài khoản? <a href="#" onClick={() => handleClickRegistration()}>Đăng ký</a></p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(AuthenticationWarningDesktopView)
