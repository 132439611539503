/* eslint-disable react/prop-types */
//@ts-check
import React from 'react';
import './form-style.scss';

export function Error(props) {
    return (
        <p className="text-danger">
            {props.content}
        </p>
    )
}

export function Success(props) {
    return (
        <p className="text-success">
            {props.content}
        </p>
    )
}