//@ts-check
import { useState } from 'react';
import { JwtHelper } from 'utils/jwt-helper';
import { checkDateInput, getDateWithFormat } from "utils/configuration";

const userInfo = JwtHelper.currentUser() || { customerId: 0, customerName: '', apartmentId: 0, mobileNumber: '', houseNumber: '' };

export function useRegisterFormRestaurantHook(id, registerFunction) {
    const [hasRegisted, _setHasRegisted] = useState(false);
    const [hasShowReserve, setHasShowReserve] = useState(false);
    const [userName, setUserName] = useState(userInfo.customerName);
    const [phoneNumber, setPhoneNumber] = useState(userInfo.mobileNumber);
    const [peopleAmount, setPeopleAmount] = useState(1);
    const [timeReserve, _setTimeReserve] = useState(new Date());
    const [dateReserve, setDateReserve] = useState(new Date());
    const [validateName, _setValidateName] = useState('');
    const [validatePhoneNumber, _setValidatePhoneNumber] = useState('');
    const [validatePeopleAmount, _setValidatePeopleAmount] = useState('');

    const handleRegister = () => {
        if (userInfo.customerName) {
            let checkedValidate = true;
            if (!_functionValidatePhoneNumber()) checkedValidate = false;
            if (!userName.trim()) {
                _setValidateName("Trường này không được bỏ trống");
                checkedValidate = false;
            } else _setValidateName('')
            if (peopleAmount === 0) {
                _setValidatePeopleAmount('Số người phải lớn hơn 0')
                checkedValidate = false;
            } else _setValidatePeopleAmount('')
            if (!checkedValidate) return;
            const params = new FormData();
            params.append('data[customer_id]', String(userInfo.customerId))
            params.append('data[shop_id]', String(id))
            params.append('data[customer_name]', userName)
            params.append('data[mobile_number]', phoneNumber)
            params.append('data[quantity]', String(peopleAmount))
            params.append('data[intend_time]', timeReserve.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            }))
            params.append('data[intend_date]', getDateWithFormat(dateReserve))
            if (registerFunction(params)) {
                setHasShowReserve(false);
                _setHasRegisted(true)
            }
        }
    }
    const setTimeReserve = (date) => checkDateInput(date, _setTimeReserve)
    const _functionValidatePhoneNumber = () => {
        if (!phoneNumber.trim()) {
            _setValidatePhoneNumber("Trường này không được để trống");
            return false
        }
        if ((!!!Number(phoneNumber)) || ((phoneNumber.length < 10) || (phoneNumber.length > 11))) {
            _setValidatePhoneNumber("Định dạng số điện thoại không đúng");
            return false
        }
        _setValidatePhoneNumber('')
        return true
    }
    return ({
        hasRegisted,
        hasShowReserve,
        setHasShowReserve,
        userName,
        setUserName,
        phoneNumber,
        setPhoneNumber,
        peopleAmount,
        setPeopleAmount,
        timeReserve,
        setTimeReserve,
        dateReserve,
        setDateReserve,
        handleRegister,
        validateName,
        validatePhoneNumber,
        validatePeopleAmount,
    })
}