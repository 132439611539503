/* eslint-disable react/prop-types */
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as documentActions from "redux/stores/document/document.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { useForm } from "react-hook-form";
import {
  NotificationMessageType,
  CheckFileSize,
  CheckFileTypeImage,
} from "utils/configuration";

class UpdateDocumentDesktopView extends Component {
  render() {
    const { user, data, onUpdateDocument, showList } = this.props;
    return (
      <EditDocumentForm
        user={user}
        data={data}
        onUpdateDocument={onUpdateDocument}
        showList={showList}
      />
    );
  }
}

function EditDocumentForm(props) {
  const { user, data, onUpdateDocument, showList } = props;

  const [filePreview, setFile] = useState(data && data.fileUrl);
  const [documentFile, setDocumentFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const { register, errors, handleSubmit, clearError } = useForm({
    mode: "onChange",
  });

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (CheckFileSize(file.size)) {
        setFileError(CheckFileSize(file.size));
        return;
      } else if (CheckFileTypeImage(file.type)) {
        setFileError(CheckFileTypeImage(file.type));
        return;
      } else {
        setFileError("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        setFile(event.target.result);
        setDocumentFile(file);
      };
    }
    if (documentFile && errors.avatar) {
      clearError(["avatar"]);
    }
  };

  useEffect(() => {
    fileError &&
      setTimeout(() => {
        setFileError("");
      }, 3000);
  });

  const onSubmit = (item) => {
    if (item) {
      const formData = new FormData();
      documentFile && formData.append("file_name", documentFile);
      onUpdateDocument(
        {
          customer_id: user.customerId,
          paper_id: data.id,
          document_name: item.title,
          content: item.description,
        },
        formData
      ).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.Update,
              NotificationMessageType.Success
            );
            document.getElementById("editForm").reset();
            setFile(null);
            showList();
          } else {
            res &&
              res.errorType &&
              AddNotification(
                viVN.Errors[res && res.errorType],
                NotificationMessageType.Error
              );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="pl-5 pr-5 form-upload-document"
      id="editForm"
    >
      <div className="form-group row">
        <label className="col-2 col-form-label">
          Tiêu đề<span className="required"></span>
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            name="title"
            placeholder="Tiêu đề"
            defaultValue={data.documentName}
            className={"form-control " + (errors.title ? "is-invalid" : "")}
            ref={register({
              required: "Trường này là bắt buộc",
            })}
          />
          {errors.title && (
            <div className="invalid-feedback">{errors.title.message}</div>
          )}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-2 col-form-label">
          Ghi chú<span className="required"></span>
        </label>
        <div className="col-sm-10">
          <textarea
            name="description"
            id="description"
            rows="5"
            defaultValue={data.content}
            placeholder="Nội dung"
            className={
              "form-control " + (errors.description ? "is-invalid" : "")
            }
            ref={register({
              required: "Trường này là bắt buộc",
            })}
          ></textarea>
          {errors.description && (
            <div className="invalid-feedback">{errors.description.message}</div>
          )}
        </div>
      </div>

      {filePreview && (
        <div className="form-group row">
          <label className="col-2 col-form-label"></label>
          <div className="col-sm-10">
            <img
              src={filePreview}
              alt="Image"
              className="img-fluid img-preview"
            />
          </div>
        </div>
      )}

      <div className="form-group row">
        <label className="col-2 col-form-label"></label>
        <div className="col-10">
          <div className="file-upload">
            <div className="title-upload">
              <img
                src={require("assets/images/collections.png")}
                alt="Image"
                className="img-fluid mr-1"
              />{" "}
              <span>Upload ảnh</span>
            </div>
            <input
              type="file"
              name="avatar"
              accept=".png, .jpg, .jpeg"
              className="media-file"
              onChange={(e) => onChangeAvatar(e)}
              onBlur={(e) => onChangeAvatar(e)}
            />
          </div>
          {fileError && <div className="invalid-feedback">{fileError}</div>}
          {errors.avatar && (
            <div className="invalid-feedback">{errors.avatar.message}</div>
          )}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-2 col-form-label"></label>
        <div className="col-4">
          <button
            type="reset"
            onClick={showList}
            className="btn btn-secondary w-100 text-uppercase rounded-pill"
          >
            Hủy
          </button>
        </div>
        <div className="col-4">
          <button
            type="submit"
            className="btn btn-success w-100 text-uppercase rounded-pill"
          >
            Lưu thay đổi
          </button>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateDocument: documentActions.DocumentAddOrUpdate,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateDocumentDesktopView);
