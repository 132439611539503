/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button } from "react-bootstrap";

import * as hotActions from "redux/stores/hot/hot.store";
import * as shopActions from "redux/stores/shop/shop.store";

import {
  NotificationMessageType,
  SetDefaultErrorImage,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import PaginationControl from "components/pagination/pagination.view";

import AddPromotion from "./add-promotion/add-promotion-desktop.view";

const screen = {
  main: 1,
  information: 2,
  promotion: 3,
  editPromotion: 4,
};

class PromotionDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shop: this.props.shop,
      shopType: this.props.shopType,
      currentPageIndex: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { shop, shopType } = this.props;
    shop
      ? this.onGetHotList(1, 10, {
          shop_id: shop.shopId,
          category_id:
            shopActions.SHOP_TYPE_ID[
              (shopType && shopType.toUpperCase()) || ""
            ],
          orderBy: "update_date",
          orderType: "DESC",
        })
      : window.location.reload();
  }

  onGetHotList = (pageIndex = 1, size = 10, filterObjs = null) => {
    this.props.getHotList(pageIndex, size, filterObjs).then(
      () => {},
      () => {}
    );
  };

  onFilterChange = (e) => {
    this.setState(
      {
        currentPageIndex: e,
      },
      () => {
        const { shop } = this.props;
        this.onGetHotList(e, 10, {
          shop_id: shop.shopId,
          orderBy: "update_date",
          orderType: "DESC",
        });
      }
    );
  };

  onSelectPage = (pageNumber) => {
    if (pageNumber < 0) {
      return;
    }
    this.setState(
      {
        currentPageIndex: pageNumber,
      },
      () => {
        this.onFilterChange(pageNumber);
      }
    );
  };

  render() {
    const { currentPageIndex, shop, shopType } = this.state;
    const { hotList, totalItems, onDeleteHot } = this.props;
    return (
      <div>
        <div className="bg-white p-3 mt-4 shop-promotion">
          <h4 className="title-sm">Tin khuyến mãi</h4>
          <hr></hr>

          <div className="row mt-3">
            {hotList && hotList.length > 0 ? (
              hotList.map((item) => (
                <PromotionList
                  key={item.hotId}
                  data={item}
                  shop={shop}
                  deleteHot={onDeleteHot}
                  onGetHotList={this.onGetHotList.bind(this)}
                  onShowScreen={this.props.onShowScreen.bind(this)}
                />
              ))
            ) : (
              <p className="col-12">Không có dữ liệu.</p>
            )}
          </div>

          {hotList && hotList.length > 0 && (
            <PaginationControl
              pageChanged={this.onSelectPage.bind(this)}
              totalItems={totalItems}
              currentPageIndex={currentPageIndex}
            />
          )}
        </div>

        <div className="bg-white p-3 mt-4 add-promotion">
          <h4 className="title-sm">Đăng tin khuyễn mãi</h4>
          <hr></hr>
          <AddPromotion
            shop={shop}
            shopType={shopType}
            onGetHotList={this.onGetHotList.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const PromotionList = (props) => {
  const { data, shop, deleteHot, onGetHotList, onShowScreen } = props;

  const [showDelete, setShowDelete] = useState(false);

  const onDeleteHot = () => {
    deleteHot(data.hotId).then(
      (res) => {
        if (res && res.content) {
          AddNotification(viVN.Success.Delete, NotificationMessageType.Success);
          onGetHotList(1, 10, {
            shop_id: shop.shopId,
            orderBy: "update_date",
            orderType: "DESC",
          });
          setShowDelete(false);
        } else {
          res &&
            res.errorType &&
            AddNotification(
              viVN.Errors[res && res.errorType],
              NotificationMessageType.Error
            );
        }
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  return (
    <div className="promotion-item">
      <div className="row no-gutters">
        <div className="col-4">
          <img
            src={data.imageUrl || require("assets/images/cafe-splendora.png")}
            onError={SetDefaultErrorImage}
            className="img-fluid img-promotion"
            alt={data.title}
          />
        </div>
        <div className="col-8">
          <div className="p-3">
            <h5>{data.title}</h5>
            <span>
              <img
                src={require("assets/images/clock-15px.svg")}
                className="img-fluid img-clock"
                alt="Clock"
              />{" "}
              Từ {data.fromDate} đến {data.toDate}
            </span>
            <p>{RenderText(data.description, 256)}</p>
            <div className="text-right">
              <a className="text-danger" onClick={() => setShowDelete(true)}>
                Xóa
              </a>
              <a
                className="text-primary ml-4"
                onClick={() => onShowScreen(screen.editPromotion, data.hotId)}
              >
                Chỉnh sửa
              </a>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showDelete}
        className="modal-delete"
        centered
        onHide={() => setShowDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Xóa bài viết?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tin khuyến mãi sẽ không thể khôi phục sau khi xóa.
          <br />
          Bạn có chắc muốn xóa tin khuyến mãi này.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Hủy
          </Button>
          <Button variant="success" onClick={() => onDeleteHot()}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const RenderText = (textOld, length) => {
  let textNew = textOld.slice(0, length);
  if (textOld.length > length) textNew += "...";
  return <p>{textNew}</p>;
};

const mapStateToProps = (state) => ({
  hotList: state.hot.hotList,
  totalItems: state.hot.totalItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHotList: hotActions.GetHotList,
      onDeleteHot: hotActions.HotDelete,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionDesktopView);
