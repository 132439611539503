//@ts-check
import React, { useEffect } from 'react';
import RestaurantHeaderDetailView from '../child-component/detail-page-childs/header-detail-restaurant/header-detail-restaurant.view';
import FooterDetailView from '../child-component/detail-page-childs/footer-detail/footer-detail.view';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PageUrls from '../../PageUrls';
import * as UtilitiShopStore from 'redux/stores/utillities/utilitis-shop.store';

/**
 * @param {{
 * PostRegisterCart:Function,
 * shopDetail:{
    *  category: string,
    id: number,
    name: string,
    faceBook: string,
    introduce: string,
    timeOpen: string,
    address: string,
    lat: number,
    lng: number,
    phone: string,
    imagesSlider: Array,
    listImage: Array,},isLoggedIn:boolean,GetShopDetail:Function,match:{params:{id:number}},
    }} [props]
   */
function UtilitiesRestaurantDetailPageMobile(props) {
    useEffect(() => {
        props.GetShopDetail(props.match.params.id)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <div>
            <RestaurantHeaderDetailView registerFunction={props.PostRegisterCart} isLoggedIn={props.isLoggedIn} data={props.shopDetail} comeBackLink={PageUrls.UtilitiesRestaurantHomeMobile} />
            <FooterDetailView listImage={props.shopDetail.listImage} />
        </div>
    )
}

const mapStateToProps = state => ({
    shopDetail: state.utilitiShop.shopDetail,
    isLoggedIn: state.auth.isLoggedIn,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopDetail: UtilitiShopStore.GetShopDetail,
    PostRegisterCart: UtilitiShopStore.PostRegisterCart,
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesRestaurantDetailPageMobile)
