/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import * as confirmCodeActions from "redux/stores/user/confirm-code.store";
import * as forgotActions from "redux/stores/user/forgot-password.store";

import {
  NotificationMessageType,
  ScreenTypeMobile,
  TokenKey,
  getCookies,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import { history } from "redux/store";
import { JwtHelper } from "utils/jwt-helper";
import * as viVN from "translation/vi-VN.json";

import "./confirm-code-mobile.scss";

class ConfirmCodeMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
      title: "",
      username: "",
      isForgot: false,
      count: 0,
      running: false,
      clientId: getCookies(TokenKey.Project),
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.type) {
      let index = this.props.match.params.type.indexOf("&&");
      let type = this.props.match.params.type;
      if (index != -1 && type.slice(0, index) == ScreenTypeMobile.Register) {
        this.setState({
          type: ScreenTypeMobile.Register,
          title: "Xác nhận đăng ký",
          username: JwtHelper.decodeStringBase64(
            type.slice(index + 2, type.length)
          ),
        });
      } else if (
        index != -1 &&
        type.slice(0, index) == ScreenTypeMobile.Active
      ) {
        this.props
          .SendConfirmCode({
            username: JwtHelper.decodeStringBase64(
              type.slice(index + 2, type.length)
            ),
          })
          .then(
            (res) => {
              if (res && res.content) {
                this.setState({
                  type: ScreenTypeMobile.Active,
                  title: "Kích hoạt tài khoản",
                  username: JwtHelper.decodeStringBase64(
                    type.slice(index + 2, type.length)
                  ),
                });
              }
            },
            (err) => {
              err &&
                err.errorType &&
                AddNotification(
                  viVN.Errors[err && err.errorType],
                  NotificationMessageType.Error
                );
            }
          );
      } else if (
        index != -1 &&
        type.slice(0, index) == ScreenTypeMobile.Forgot
      ) {
        this.setState({
          type: ScreenTypeMobile.Forgot,
          title: "Đặt lại mật khẩu",
          username: JwtHelper.decodeStringBase64(
            type.slice(index + 2, type.length)
          ),
          isForgot: true,
        });
      } else {
        history.push("/m/dang-nhap");
      }
    } else {
      history.push("/m/dang-nhap");
    }
  }

  componentDidMount() {
    this.handleCountdown(60);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.running !== prevState.running) {
      switch (this.state.running) {
        case true:
          this.handleStart();
      }
    }
  }

  handleStart() {
    this.timer = setInterval(() => {
      const newCount = this.state.count - 1;
      this.setState({ count: newCount >= 0 ? newCount : 0 });
    }, 1000);
  }

  handleStop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState({ running: false });
    }
  }

  handleReset() {
    this.setState({ count: 0 });
  }

  handleCountdown(seconds) {
    this.setState({
      count: seconds,
      running: true,
    });
  }

  render() {
    const { count, isForgot, type, title, username, clientId } = this.state;
    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to={"/m/" + type}>
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">{title}</div>
              </div>
            </div>
          </div>
        </header>
        <ConfirmCodeMobileForm
          confirmCode={this.props.ConfirmCode}
          activeAccount={this.props.ActiveAccount}
          sendConfirmCode={this.props.SendConfirmCode}
          forgot={this.props.Forgot}
          onSetCountdown={this.handleCountdown.bind(this)}
          isForgot={isForgot}
          count={count}
          type={type}
          tel={username}
          clientId={clientId}
        />
      </div>
    );
  }
}

function ConfirmCodeMobileForm(props) {
  const {
    isForgot,
    confirmCode,
    activeAccount,
    sendConfirmCode,
    forgot,
    tel,
    count,
    type,
    onSetCountdown,
    clientId,
  } = props;

  const [success, setSuccess] = useState(false);

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSendCode = (data) => {
    if (
      !isForgot &&
      (type == ScreenTypeMobile.Active || type == ScreenTypeMobile.Register)
    ) {
      let params = {
        clientId: clientId,
        username: tel.trim(),
      };

      sendConfirmCode(params).then(
        (res) => {
          if (res && res.content) {
            AddNotification("Gửi thành công", "success");
            onSetCountdown(60);
          } else {
            AddNotification(
              viVN.Errors[(res && res.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    } else {
      let params = {
        clientId: clientId,
        username: tel.trim(),
        resetUrl: "https://ilang-test.bmbsoft.com.vn:9997",
      };

      forgot(params).then(
        (res) => {
          if (res && res.content) {
            AddNotification("Gửi thành công", "success");
            onSetCountdown(60);
          } else {
            AddNotification(
              viVN.Errors[(res && res.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
        }
      );
    }
  };

  const onSubmit = (data) => {
    if (data) {
      if (
        !isForgot &&
        (type == ScreenTypeMobile.Active || type == ScreenTypeMobile.Register)
      ) {
        let params = {
          clientId: clientId,
          username: tel,
          confirmCode: data.code,
        };

        activeAccount(params).then(
          (res) => {
            if (res && res.content) {
              setSuccess(true);
            } else {
              AddNotification(
                viVN.Errors[(res && res.errorType) || "UnknownError"],
                NotificationMessageType.Error
              );
            }
          },
          (err) => {
            AddNotification(
              viVN.Errors[(err && err.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        );
      } else {
        let params = {
          username: tel,
          resetCode: data.code,
        };

        confirmCode(params).then(
          (res) => {
            if (res && res.content) {
              history.push(
                "/m/dat-lai-mat-khau/" +
                  JwtHelper.encodeStringBase64(tel) +
                  "&&" +
                  JwtHelper.encodeStringBase64(data.code)
              );
            } else {
              AddNotification(
                viVN.Errors[(res && res.errorType) || "UnknownError"],
                NotificationMessageType.Error
              );
            }
          },
          (err) => {
            AddNotification(
              viVN.Errors[(err && err.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
          }
        );
      }
    }
  };

  const RenderTel = (data) => {
    let str = "";
    for (let i = 0; i < data.length - 3; i++) str += "*";
    str += data.slice(data.length - 2, data.length);
    return str;
  };

  return (
    <div>
      {!success && (
        <form onSubmit={handleSubmit(onSubmit)} className="row form-forgot">
          <div className="col-12">
            <div className="form-group">
              <label>
                Vui lòng kiểm tra tin nhắn trên điện thoại. Chúng tôi đã gửi mã
                xác nhận đến số: {tel && RenderTel(tel.toString())}
              </label>
            </div>

            <div className="form-group">
              <input
                name="code"
                type="text"
                placeholder="Mã xác nhận của bạn"
                className="form-control"
                ref={register({
                  required: "Trường này là bắt buộc",
                })}
              />
              {errors.code && (
                <div className="invalid-feedback">{errors.code.message}</div>
              )}
            </div>

            {isForgot && (
              <div className="form-group text-right">
                <button
                  type="button"
                  className="btn btn-light text-uppercase rounded-pill mr-2 pl-4 pr-4"
                  onClick={onSendCode}
                  disabled={count > 0}
                >
                  {count > 0 ? (
                    <span>Gửi lại ({count})</span>
                  ) : (
                    <span>Gửi lại</span>
                  )}
                </button>
                <button
                  type="submit"
                  className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
                >
                  Tiếp tục
                </button>
              </div>
            )}

            {!isForgot && (
              <div className="form-group text-right">
                <button
                  type="submit"
                  className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
                >
                  Xác nhận
                </button>
              </div>
            )}
          </div>
        </form>
      )}

      {type == ScreenTypeMobile.Register && success && (
        <div className="change-password-success text-center pt-5">
          <img
            src={require("assets/images/success-filled.svg")}
            className="img-fluid mb-3"
            alt="Success"
          />
          <h5>Đăng ký tài khoản thành công!</h5>
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
              onClick={() => history.push("/m/dang-nhap")}
            >
              Đăng nhập ngay
            </button>
          </div>
        </div>
      )}

      {type == ScreenTypeMobile.Active && success && (
        <div className="change-password-success text-center pt-5">
          <img
            src={require("assets/images/success-filled.svg")}
            className="img-fluid mb-3"
            alt="Success"
          />
          <h5>Kích hoạt tài khoản thành công!</h5>
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-success text-uppercase rounded-pill pl-4 pr-4"
              onClick={() => history.push("/m/dang-nhap")}
            >
              Đăng nhập ngay
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ConfirmCode: confirmCodeActions.ConfirmCode,
      ActiveAccount: confirmCodeActions.ActiveAccount,
      SendConfirmCode: confirmCodeActions.SendConfirmCode,
      Forgot: forgotActions.Forgot,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmCodeMobileView);
