import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../payment-mobile.scss';
import * as ElectricStore from '../../../../redux/stores/payment/electric/electric.store';
import { RenderMonthlyBill } from '../electric-mobile.view.jsx';
import { PromiseAllTools } from 'core/app.store';
class PaymentElectricDetailMobileView extends Component {

  componentDidMount() {
    this.props.PromiseAllTools([this.props.GetListElectricDetail(this.props.match.params.electricId)]);
  }

  render() {
    const data = this.props.detail;
    return (
      <div className="payment-mobile">
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/m/thanh-toan/tien-dien">
                  <img src={require('assets/images/arrow-back.svg')} alt="Arrow Back" className="img-fluid img-arrow-back" />
                </Link>
                <div className="header-title text-center">Thanh toán hóa đơn</div>
              </div>
            </div>
          </div>
        </header>

        <div className="payment-info bg-white p-3">
          <RenderMonthlyBill data={data} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  detail: state.electricBill.billDetail,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  GetListElectricDetail: ElectricStore.GetListElectricDetail,
  PromiseAllTools
},
  dispatch
)
export default connect(mapStateToProps, mapDispatchToProps)(PaymentElectricDetailMobileView)