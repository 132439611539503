/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NotificationMessageType,
  FormatDate,
  CheckFileSize,
  CheckFileTypeImage,
} from "utils/configuration";
import AddNotification from "components/react-notifications/react-notifications";
import * as hotActions from "redux/stores/hot/hot.store";
import * as shopActions from "redux/stores/shop/shop.store";
import * as viVN from "translation/vi-VN.json";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { history } from "redux/store";

import "../promotion-mobile.scss";

class EditPromotionMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hot: null,
      hotId: this.props.match.params.id,
      shopId: this.props.match.params.shopId,
      shopType:
        (this.props.match.params.type &&
          this.props.match.params.type.toUpperCase()) ||
        "",
    };

    (!this.props.match.params.id || !this.props.match.params.shopId) &&
      history.push(`/m/thong-tin-ca-nhan`);
  }

  UNSAFE_componentWillMount() {
    this.state.hotId &&
      this.onGetHotDetail(this.state.hotId, this.state.shopType);
  }

  onGetHotDetail = (hotId, shopType) => {
    this.props
      .getHotDetail(
        hotId,
        shopActions.SHOP_TYPE_ID[(shopType && shopType.toUpperCase()) || ""]
      )
      .then(
        (res) => {
          if (res && res.content) {
            this.setState({
              hot: res.content,
            });
          } else {
            AddNotification(
              viVN.Errors[(res && res.errorType) || "UnknownError"],
              NotificationMessageType.Error
            );
            history.push("/m/thong-tin-ca-nhan");
          }
        },
        (err) => {
          AddNotification(
            viVN.Errors[(err && err.errorType) || "UnknownError"],
            NotificationMessageType.Error
          );
          history.push("/m/thong-tin-ca-nhan");
        }
      );
  };

  render() {
    const { hot, shopId, shopType } = this.state;
    const { addOrUpdateHot } = this.props;
    return (
      <div>
        <header className="header-mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <Link
                  to={`/m/tin-khuyen-mai/${shopType.toLocaleLowerCase()}/${shopId}`}
                >
                  <img
                    src={require("assets/images/arrow-back.svg")}
                    alt="Arrow Back"
                    className="img-fluid img-arrow-back"
                  />
                </Link>
                <div className="header-title text-center">
                  Chỉnh sửa tin khuyến mãi
                </div>
              </div>
            </div>
          </div>
        </header>
        {hot && (
          <EditPromotion
            data={hot}
            shopType={shopType}
            shopId={shopId}
            addOrUpdateHot={addOrUpdateHot}
          />
        )}
      </div>
    );
  }
}

function EditPromotion(props) {
  const { data, shopId, addOrUpdateHot } = props;
  const today = new Date();
  const fromDateList = data && data.fromDate.split("/");
  const toDateList = data && data.toDate.split("/");
  const fromDate = new Date(
    (fromDateList && fromDateList.length == 3 && parseInt(fromDateList[2])) ||
      today.getUTCFullYear(),
    (fromDateList && fromDateList.length == 3 && parseInt(fromDateList[1])) ||
      today.getUTCMonth() + 1,
    (fromDateList && fromDateList.length == 3 && parseInt(fromDateList[0])) ||
      today.getDate(),
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );
  const toDate = new Date(
    (toDateList && toDateList.length == 3 && parseInt(toDateList[2])) ||
      today.getUTCFullYear(),
    (toDateList && toDateList.length == 3 && parseInt(toDateList[1])) ||
      today.getUTCMonth() + 1,
    (toDateList && toDateList.length == 3 && parseInt(toDateList[0])) ||
      today.getDate() + 1,
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );

  const [content, setContent] = useState(data.content);
  const [contentError, setContentError] = useState("");

  const [startDate, handleChangeStartDate] = useState(fromDate);
  const [endDate, handleChangeEndDate] = useState(toDate);

  const [avatar, setAvatar] = useState(data && data.imageUrl);
  const [avatarFile, setAvatarFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (CheckFileSize(file.size)) {
        setFileError(CheckFileSize(file.size));
        return;
      } else if (CheckFileTypeImage(file.type)) {
        setFileError(CheckFileTypeImage(file.type));
        return;
      } else {
        setFileError("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        setAvatar(event.target.result);
        setAvatarFile(file);
      };
    }
    if (avatarFile && errors.avatar) {
      clearError(["avatar"]);
    }
  };

  const onSetFromDate = (time) => {
    handleChangeStartDate(time);
    if (time.getTime() < endDate.getTime()) {
      clearError(["fromDate", "toDate"]);
    } else {
      setError("fromDate", "required", "Phải nhỏ hơn thời gian kết thúc");
    }
  };

  const onSetToDate = (time) => {
    handleChangeEndDate(time);
    if (time.getTime() > startDate.getTime()) {
      clearError(["fromDate", "toDate"]);
    } else {
      setError("toDate", "required", "Phải lớn hơn thời gian bắt đầu");
    }
  };

  const onChangeContent = (value) => {
    setContent(value);
    value && value !== "<p><br></p>"
      ? setContentError("")
      : setContentError("Trường này là bắt buộc");
  };

  const onSubmit = (value) => {
    if (errors && errors.length > 0) {
      endDate.getTime() <= startDate.getTime() &&
        setError("toDate", "required", "Phải lớn hơn thời gian bắt đầu");
    } else if (data.hotId && !contentError && value) {
      const formData = new FormData();
      avatarFile && formData.append("image", avatarFile);
      addOrUpdateHot(
        {
          hot_id: data.hotId,
          // shop_id: shopId,
          // category_id:
          //   shopActions.SHOP_TYPE_ID[
          //     (shopType && shopType.toUpperCase()) || ""
          //   ],
          title: value.promotionName,
          description: value.description,
          content: content,
          from_date: FormatDate(startDate),
          to_date: FormatDate(endDate),
        },
        formData
      ).then(
        (res) => {
          if (res && res.content) {
            AddNotification(
              viVN.Success.Update,
              NotificationMessageType.Success
            );
            history.push(`/m/tin-khuyen-mai/${shopId}`);
          } else {
            res &&
              res.errorType &&
              AddNotification(
                viVN.Errors[res && res.errorType],
                NotificationMessageType.Error
              );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    } else {
      AddNotification(viVN.Errors.UnknownError, NotificationMessageType.Error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="p-3 form-promotion"
      id="addForm"
    >
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>
              Tiêu đề<span className="required"></span>
            </label>
            <input
              type="text"
              name="promotionName"
              defaultValue={data.title}
              className={
                "form-control " + (errors.promotionName ? "is-invalid" : "")
              }
              placeholder="Tên chương trình khuyến mãi"
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            />
            {errors.promotionName && (
              <div className="invalid-feedback">
                {errors.promotionName.message}
              </div>
            )}
          </div>

          <div className="form-group">
            <label>
              Thời gian khuyến mãi<span className="required"></span>
            </label>
            <div className="row">
              <div className="col-6 col-sm-3">
                <DatePicker
                  name="fromDate"
                  selected={startDate}
                  onChange={(date) => date && onSetFromDate(date)}
                  className={
                    "form-control " + (errors.fromDate ? "is-invalid" : "")
                  }
                  dateFormat="dd/MM/yyyy"
                />
                {errors.fromDate && (
                  <div className="invalid-feedback">
                    {errors.fromDate.message}
                  </div>
                )}
              </div>
              <div className="col-6 col-sm-3">
                <DatePicker
                  name="toDate"
                  selected={endDate}
                  onChange={(date) => date && onSetToDate(date)}
                  className={
                    "form-control " + (errors.toDate ? "is-invalid" : "")
                  }
                  dateFormat="dd/MM/yyyy"
                />
                {errors.toDate && (
                  <div className="invalid-feedback">
                    {errors.toDate.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>
              Mô tả ngắn<span className="required"></span>
            </label>
            <textarea
              name="description"
              defaultValue={data.description}
              className={
                "form-control " + (errors.description ? "is-invalid" : "")
              }
              placeholder="Tóm lược chương trình khuyến mãi"
              rows="6"
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            ></textarea>
            {errors.description && (
              <div className="invalid-feedback">
                {errors.description.message}
              </div>
            )}
          </div>

          <div className="form-group">
            <label>
              Nội dung<span className="required"></span>
            </label>
            <SunEditor
              enableToolbar={true}
              showToolbar={true}
              setOptions={{
                height: "auto",
                minHeight: 500,
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "font",
                    "fontSize",
                    "formatBlock",
                    "paragraphStyle",
                    "blockquote",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "fontColor",
                    "hiliteColor",
                    "textStyle",
                    "removeFormat",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "lineHeight",
                    "table",
                    "link",
                    "image",
                    "video",
                    "audio",
                    "fullScreen",
                    "showBlocks",
                    "codeView",
                  ],
                ],
              }}
              setContents={data.content}
              onChange={onChangeContent}
              onBlur={(event, editorContents) =>
                onChangeContent(editorContents)
              }
            />
            {contentError && (
              <div className="invalid-feedback">{contentError}</div>
            )}
          </div>

          {avatar && (
            <div className="form-group album-file">
              <div className="item">
                <img src={avatar} alt={data.title} className="img-video" />
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="file-upload">
              <div className="title-upload">
                <img
                  src={require("assets/images/collections.png")}
                  alt="Image"
                  className="img-fluid"
                />{" "}
                <span>Upload ảnh</span>
              </div>
              <input
                type="file"
                name="mediaFile"
                accept=".png, .jpg, .jpeg"
                className="media-file"
                onChange={(e) => onChangeAvatar(e)}
                onBlur={(e) => onChangeAvatar(e)}
              />
            </div>
            {fileError && <div className="invalid-feedback">{fileError}</div>}
            {errors.avatar && (
              <div className="invalid-feedback">{errors.avatar.message}</div>
            )}
          </div>

          <div className="row">
            <div className="col-6">
              <button
                type="reset"
                className="btn btn-secondary rounded-pill text-uppercase w-100"
              >
                HỦY
              </button>
            </div>
            <div className="col-6">
              <button
                type="submit"
                className="btn btn-success text-uppercase rounded-pill w-100"
              >
                LƯU THAY ĐỔI
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHotDetail: hotActions.GetHotDetail,
      addOrUpdateHot: hotActions.AddOrUpdateHot,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPromotionMobileView);
