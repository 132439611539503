/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LoadScript } from "@react-google-maps/api";
import moment from "moment";

import * as shopActions from "redux/stores/shop/shop.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import {
  NotificationMessageType,
  NewGuid,
  CheckMaxFile,
  CheckFileSize,
  CheckFileTypeImage,
  ConvertTimeToNumber,
} from "utils/configuration";
import { GoogleMapAPI } from "modules/gmap/googlemap.view";
import SharedService from "services/share.service.js";

import Select from "react-select";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const sharedService = new SharedService();

class RegisterShopDesktopView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryList: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetDropdownData();
  }

  onGetDropdownData = () => {
    Promise.all([sharedService.getShopCategory()])
      .then((res) => {
        const [categoryModel] = res;
        this.setState({
          categoryList:
            (categoryModel.content &&
              categoryModel.content.length > 0 &&
              categoryModel.content.map((item) => {
                return {
                  ...item,
                  label: item.categoryName,
                  value: item.categoryCode,
                };
              })) ||
            [],
        });
      })
      .catch(() => {});
  };

  render() {
    const { user, onCreateShop } = this.props;
    const { categoryList } = this.state;
    return (
      <LoadScript
        id="script-loader"
        googleMapsApiKey={GoogleMapAPI}
        libraries={["places"]}
      >
        <RegisterShop
          user={user}
          categoryList={categoryList}
          onCreateShop={onCreateShop}
          convertFormDataFromShopType={shopActions.convertFormDataFromShopType}
        />
      </LoadScript>
    );
  }
}

function RegisterShop(props) {
  const {
    user,
    categoryList,
    onCreateShop,
    convertFormDataFromShopType,
  } = props;

  const today = new Date();

  const [avatar, setAvatar] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");

  const [startDate, setStartDate] = useState(
    new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getDate(), 8, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getDate(),
      17,
      0
    )
  );

  const [slides, setSlides] = useState([]);
  const [slidesError, setSlidesError] = useState("");
  const [album, setAlbum] = useState([]);
  const [albumError, setAlbumError] = useState("");
  const [category, setCategory] = useState("");

  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");

  const [calendarSchedule, setCalendarSchedule] = useState([
    {
      id: NewGuid(),
      openTime: new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        7,
        0
      ),
      closeTime: new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        8,
        0
      ),
    },
  ]);
  const [calendarScheduleError, setCalendarScheduleError] = useState("");

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  //--- Clear error image after 3 milliseconds
  useEffect(() => {
    slidesError &&
      setTimeout(() => {
        setSlidesError("");
      }, 3000);
    albumError &&
      setTimeout(() => {
        setAlbumError("");
      }, 3000);
  });

  //--- Add one item in calendar schedule
  const onAddCalendarSchedule = () => {
    let lastTime = calendarSchedule[calendarSchedule.length - 1];
    let timeList = [
      ...calendarSchedule,
      {
        id: NewGuid(),
        openTime: new Date(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getDate(),
          lastTime.closeTime.getHours(),
          0
        ),
        closeTime: new Date(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getDate(),
          lastTime.closeTime.getHours() + 1,
          0
        ),
      },
    ];
    setCalendarSchedule(timeList);
    onValidateCalendarSchedule(timeList);
  };

  //--- Delete one item in calendar schedule
  const onDeleteCalendarSchedule = (_item) => {
    if (calendarSchedule.length > 1) {
      let timeList = calendarSchedule.filter((item) => item.id !== _item.id);
      setCalendarSchedule(timeList);
      onValidateCalendarSchedule(timeList);
    }
  };

  //--- Change one item in calendar schedule
  const onChangeCalendarSchedule = (_item, _openTime, _closeTime) => {
    let timeList = calendarSchedule.map((item) => {
      if (item.id === _item.id) {
        item.openTime = _openTime;
        item.closeTime = _closeTime;
      }
      return item;
    });
    setCalendarSchedule(timeList);
    onValidateCalendarSchedule(timeList);
  };

  //--- Validate for calendar schedule
  const onValidateCalendarSchedule = (timeList) => {
    for (let index = 0; index < timeList.length; index++) {
      if (
        ConvertTimeToNumber(timeList[index].openTime) >=
        ConvertTimeToNumber(timeList[index].closeTime)
      ) {
        setCalendarScheduleError({
          id: timeList[index].id,
          index: index,
          errorMessage: "Thời gian mở cửa phải nhỏ hơn thời gian đóng cửa",
          errorType: "OPEN_CLOSE",
        });
        return;
      }

      if (index > 0) {
        if (
          ConvertTimeToNumber(timeList[index - 1].closeTime) >
          ConvertTimeToNumber(timeList[index].openTime)
        ) {
          setCalendarScheduleError({
            id: timeList[index].id,
            index: index,
            errorMessage: "Thời gian mở cửa phải lớn hơn thời gian đóng cửa",
            errorType: "CLOSE_OPEN",
          });
          return;
        }
      }

      if (index === timeList.length - 1) setCalendarScheduleError("");
    }
  };

  //--- Validate for SunEditor
  const onChangeContent = (value) => {
    setContent(value);
    value && value !== "<p><br></p>"
      ? setContentError("")
      : setContentError("Trường này là bắt buộc");
  };

  //--- Upload and validate for single file
  const onChangeAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (CheckFileSize(file.size)) {
        setFileError(CheckFileSize(file.size));
        return;
      } else if (CheckFileTypeImage(file.type)) {
        setFileError(CheckFileTypeImage(file.type));
        return;
      } else {
        setFileError("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        setAvatar(event.target.result);
        setAvatarFile(file);
      };
    }
    if (avatarFile && errors.avatar) {
      clearError(["avatar"]);
    }
  };

  const onSetCategory = (data) => {
    setCategory(data);
    clearError(["category"]);
  };

  //--- Validate for open - close time
  const onSetOpenTime = (time) => {
    setStartDate(time);
    ConvertTimeToNumber(time) < ConvertTimeToNumber(endDate)
      ? clearError(["openTime", "closeTime"])
      : setError("openTime", "required", "Phải nhỏ hơn thời gian đóng");
  };

  //--- Validate for open - close time
  const onSetCloseTime = (time) => {
    setEndDate(time);
    ConvertTimeToNumber(time) > ConvertTimeToNumber(startDate)
      ? clearError(["openTime", "closeTime"])
      : setError("closeTime", "required", "Phải lớn hơn thời gian mở");
  };

  //--- Upload and validate for multiple file
  function ReadUri(slide, e) {
    if (e.target.files) {
      const listFile = Array.from(e.target.files);
      Promise.all(
        listFile.map((file) => {
          return new Promise((resolve, reject) => {
            let slidesCount = slides.length;
            let albumCount = album.length;
            if (
              (slide && CheckMaxFile(slidesCount)) ||
              (!slide && CheckMaxFile(albumCount))
            ) {
              slide
                ? setSlidesError(CheckMaxFile(slidesCount))
                : setAlbumError(CheckMaxFile(albumCount));
              resolve({
                err: true,
              });
            } else if (CheckFileSize(file.size)) {
              slide
                ? setSlidesError(CheckFileSize(file.size))
                : setAlbumError(CheckFileSize(file.size));
              resolve({
                err: true,
              });
            } else if (CheckFileTypeImage(file.type)) {
              slide
                ? setSlidesError(CheckFileTypeImage(file.type))
                : setAlbumError(CheckFileTypeImage(file.type));
              resolve({
                err: true,
              });
            } else {
              slide ? slidesCount++ : albumCount++;
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  id: NewGuid(),
                  name: file.name,
                  url: ev.target.result,
                  err: false,
                  type: "image",
                  file: file,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            }
          });
        })
      ).then(
        (images) => {
          let result = [];
          images.map((image) => {
            if (!image.err) {
              result.push(image);
            }
          });
          if (slide) {
            setSlides([...slides, ...result]);
          } else {
            setAlbum([...album, ...result]);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  //--- Delete one file in files
  function DeleteFile(slide, id) {
    if (id && slide) {
      const slidesTemp = slides.filter((x) => x.id != id);
      setSlides(slidesTemp);
      const isErr = slidesTemp.filter((x) => x.err);
      (!isErr || isErr.length == 0) && setSlidesError("");
    }
    if (id && !slide) {
      const albumTemp = album.filter((x) => x.id != id);
      setAlbum(albumTemp);
      const isErr = albumTemp.filter((x) => x.err);
      (!isErr || isErr.length == 0) && setAlbumError("");
    }
  }

  //--- Get lat lng from address
  const onSelectAddress = async (value) => {
    let results = await geocodeByAddress(value);
    let latLng = await getLatLng(results[0]);
    setAddress(value);
    setLocation(latLng);
  };

  const classnames = (...args) => {
    const classes = [];
    args.forEach((arg) => {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key);
          }
        });
      } else {
        throw new Error(
          "`classnames` only accepts string or object as arguments"
        );
      }
    });

    return classes.join(" ");
  };

  const onSubmit = (data) => {
    if (
      (errors && errors.length > 0) ||
      calendarScheduleError ||
      slidesError ||
      albumError ||
      !data
    ) {
      return;
    }

    //--- If lat lng is null then get lat lng from address
    !location && onSelectAddress(address);

    onCreateShop(
      {
        customer_id: user.customerId,
        category_type: data.category,
        shop_name: data.restaurantName,
        shop_address: data.address,
        shop_hotline: data.tel,
        longitude: location.lng,
        latitude: location.lat,
        open_time: moment(startDate).format("HH:mm"),
        close_time: moment(endDate).format("HH:mm"),
        shop_fanpage_url: data.fanpage,
        content: content,
      },
      convertFormDataFromShopType(
        data.category,
        avatarFile,
        slides,
        album,
        category.hasSlot || 0,
        calendarSchedule
      )
    ).then(
      (res) => {
        AddNotification("Đăng ký thành công", NotificationMessageType.Success);
        window.location.reload();
      },
      (err) => {
        AddNotification(
          viVN.Errors[(err && err.errorType) || "UnknownError"],
          NotificationMessageType.Error
        );
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="addForm">
      <div className="form-group">
        <label>
          Chọn loại hình<span className="required"></span>
        </label>
        <Select
          onChange={(data) => data && onSetCategory(data)}
          onBlur={() =>
            category
              ? clearError(["category"])
              : setError("category", "required", "Trường này là bắt buộc")
          }
          value={category}
          options={categoryList}
          noOptionsMessage={() => "Không có dữ liệu"}
          className="slAutocomplete"
          placeholder="Chọn loại hình"
        />
        <input
          type="hidden"
          name="category"
          value={category && category.value}
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        />
        {errors.category && (
          <div className="invalid-feedback">{errors.category.message}</div>
        )}
      </div>

      <div className="form-group">
        <label>
          Ảnh đại diện<span className="required"></span>
        </label>
        <div>
          <img
            src={avatar || require("assets/images/shop-avatar-default.png")}
            alt="shop-avatar-default"
            className="img-shop-avatar-default"
          />
          <button className="btn btn-success btn-upload">
            Upload ảnh{" "}
            <img
              src={require("assets/images/upload.svg")}
              alt="upload"
              className="img-upload"
            />
          </button>
          <input
            type="file"
            name="avatar"
            accept=".png, .jpg, .jpeg"
            className="input-upload"
            onChange={(e) => onChangeAvatar(e)}
            onBlur={(e) => onChangeAvatar(e)}
            ref={register({
              required: "Trường này là bắt buộc",
            })}
          />
        </div>
        {fileError && <div className="invalid-feedback">{fileError}</div>}
        {errors.avatar && (
          <div className="invalid-feedback">{errors.avatar.message}</div>
        )}
      </div>

      <div className="form-group">
        <label>
          Tên<span className="required"></span>
        </label>
        <input
          type="text"
          name="restaurantName"
          className={
            "form-control " + (errors.restaurantName ? "is-invalid" : "")
          }
          placeholder="Nhập tên gian hàng"
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        />
        {errors.restaurantName && (
          <div className="invalid-feedback">
            {errors.restaurantName.message}
          </div>
        )}
      </div>

      <div className="form-group">
        <label>
          Địa chỉ<span className="required"></span>
        </label>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={onSelectAddress}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="map__search-bar-container">
                <div className="map__search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: "Nhập địa chỉ",
                      className:
                        "form-control " + (errors.address ? "is-invalid" : ""),
                    })}
                  />
                </div>
                {suggestions.length > 0 && (
                  <div className="map__autocomplete-container">
                    {suggestions.map((suggestion, index) => {
                      const className = classnames("map__suggestion-item", {
                        "map__suggestion-item--active": suggestion.active,
                      });
                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{" "}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    <div className="map__dropdown-footer">
                      <div>
                        <img
                          src={require("assets/images/powered_by_google_default.png")}
                          className="map__dropdown-footer-image"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        <input
          type="hidden"
          name="address"
          value={address}
          ref={register({
            required: "Trường này là bắt buộc",
          })}
        />
        {errors.address && (
          <div className="invalid-feedback">{errors.address.message}</div>
        )}
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>
              Số điện thoại<span className="required"></span>
            </label>
            <input
              type="text"
              name="tel"
              className={"form-control " + (errors.tel ? "is-invalid" : "")}
              placeholder="Nhập số điện thoại"
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 11,
              })}
            />
            {errors.tel && errors.tel.type === "required" && (
              <div className="invalid-feedback">Trường này là bắt buộc</div>
            )}
            {errors.tel && errors.tel.type === "minLength" && (
              <div className="invalid-feedback">Tối thiểu 8 số</div>
            )}
            {errors.tel && errors.tel.type === "maxLength" && (
              <div className="invalid-feedback">Tối đa 11 số</div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>
              Thời gian mở cửa<span className="required"></span>
            </label>
            <div className="row">
              <div className="col-6 pr-1">
                <DatePicker
                  name="openTime"
                  selected={startDate}
                  onChange={(date) => date && onSetOpenTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Từ"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  className={
                    "form-control " + (errors.openTime ? "is-invalid" : "")
                  }
                />
                {errors.openTime && (
                  <div className="invalid-feedback">
                    {errors.openTime.message}
                  </div>
                )}
              </div>
              <div className="col-6 pl-1">
                <DatePicker
                  name="closeTime"
                  selected={endDate}
                  onChange={(date) => date && onSetCloseTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Đến"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  className={
                    "form-control " + (errors.closeTime ? "is-invalid" : "")
                  }
                />
                {errors.closeTime && (
                  <div className="invalid-feedback">
                    {errors.closeTime.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label>
          Facebook<span className="required"></span>
        </label>
        <input
          type="text"
          name="fanpage"
          id="fanpage"
          className={"form-control " + (errors.fanpage ? "is-invalid" : "")}
          placeholder="https://facebook.com/Highlight.Restaurant"
          ref={register({
            required: "Trường này là bắt buộc",
            pattern: {
              // eslint-disable-next-line no-useless-escape
              value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
              message: "Đường dẫn không hợp lệ",
            },
          })}
        />
        {errors.fanpage && (
          <div className="invalid-feedback">{errors.fanpage.message}</div>
        )}
      </div>

      <div className="form-group">
        <label>
          Giới thiệu<span className="required"></span>
        </label>
        <SunEditor
          enableToolbar={true}
          showToolbar={true}
          setOptions={{
            height: "auto",
            minHeight: 500,
            buttonList: [
              [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "blockquote",
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "fontColor",
                "hiliteColor",
                "textStyle",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "lineHeight",
                "table",
                "link",
                "image",
                "video",
                "audio",
                "fullScreen",
                "showBlocks",
                "codeView",
              ],
            ],
          }}
          onChange={onChangeContent}
          onBlur={(event, editorContents) => onChangeContent(editorContents)}
        />
        {contentError && <div className="invalid-feedback">{contentError}</div>}
      </div>

      {category && category.hasSlot && category.hasSlot == 1 && (
        <div className="form-group mb-0">
          <label>
            Tạo lịch đăng ký{" "}
            <small className="text-primary">
              (Lịch sẽ được thiết lập trong vòng 1 năm)
            </small>
          </label>

          <div className="calendar-schedule">
            {calendarSchedule &&
              calendarSchedule.length > 0 &&
              calendarSchedule.map((item, index) => (
                <div
                  key={item.id}
                  className={`row ${index > 0 ? "mt-3" : ""} ${
                    index === calendarSchedule.length - 1 ? "mb-3" : ""
                  }`}
                >
                  <div className="col-5">
                    <DatePicker
                      name={`openTime${item.id}`}
                      selected={item.openTime}
                      onChange={(date) =>
                        date &&
                        onChangeCalendarSchedule(item, date, item.closeTime)
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Từ"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      className={
                        "form-control " +
                        ((calendarScheduleError.errorType === "CLOSE_OPEN" &&
                          calendarScheduleError.index === index) ||
                        (calendarScheduleError.errorType === "OPEN_CLOSE" &&
                          calendarScheduleError.index === index)
                          ? "is-invalid"
                          : "")
                      }
                    />
                  </div>
                  <div className="col-5">
                    <DatePicker
                      name={`closeTime${item.id}`}
                      selected={item.closeTime}
                      onChange={(date) =>
                        date &&
                        onChangeCalendarSchedule(item, item.openTime, date)
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Đến"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      className={
                        "form-control " +
                        ((calendarScheduleError.errorType === "CLOSE_OPEN" &&
                          calendarScheduleError.index - 1 === index) ||
                        (calendarScheduleError.errorType === "OPEN_CLOSE" &&
                          calendarScheduleError.index === index)
                          ? "is-invalid"
                          : "")
                      }
                    />
                  </div>
                  <div className="col-2 mt-2">
                    <img
                      src={require("/assets/images/plus-green.svg")}
                      alt="Plus"
                      onClick={onAddCalendarSchedule}
                    />
                    {index > 0 && (
                      <img
                        src={require("/assets/images/minus-red.svg")}
                        alt="Minus"
                        className="ml-2"
                        onClick={() => onDeleteCalendarSchedule(item)}
                      />
                    )}
                  </div>
                  {calendarScheduleError &&
                    calendarScheduleError.index === index && (
                      <div className="col-12 invalid-feedback">
                        {calendarScheduleError.errorMessage}
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      )}

      <div className="form-group">
        <label>Ảnh slide</label>
        <div className="image-gallery rounded">
          <div className="row no-gutters">
            {slides &&
              slides.map((item) => (
                <div
                  className="col-3 image-item border-0 position-relative mb-2"
                  key={item.id}
                >
                  <img
                    src={item.url}
                    alt={item.name}
                    className={
                      "img-fluid img-slider" +
                      (item.err ? " border border-danger" : "")
                    }
                  />
                  <img
                    src={require("assets/images/close-white.svg")}
                    alt="Close"
                    className="img-close-white"
                    onClick={() => DeleteFile(true, item.id)}
                  />
                </div>
              ))}
            <div className="col-3 mb-2">
              <div className="image-item">
                <img
                  src={require("assets/images/insert-photos.svg")}
                  alt="insert-photos"
                  className="img-insert-photos"
                />
                <input
                  type="file"
                  name="slide"
                  accept=".png, .jpg, .jpeg"
                  multiple
                  onChange={(e) => ReadUri(true, e)}
                />
              </div>
            </div>
          </div>
        </div>
        {slidesError && <div className="invalid-feedback">{slidesError}</div>}
      </div>

      <div className="form-group">
        <label>Thư viện ảnh</label>
        <div className="image-gallery rounded">
          <div className="row no-gutters">
            {album &&
              album.map((item) => (
                <div
                  className="col-3 image-item border-0 position-relative mb-2"
                  key={item.id}
                >
                  <img
                    src={item.url}
                    alt={item.name}
                    className={
                      "img-fluid img-slider" +
                      (item.err ? " border border-danger" : "")
                    }
                  />
                  <img
                    src={require("assets/images/close-white.svg")}
                    alt="Close"
                    className="img-close-white"
                    onClick={() => DeleteFile(false, item.id)}
                  />
                </div>
              ))}
            <div className="col-3 mb-2">
              <div className="image-item">
                <img
                  src={require("assets/images/insert-photos.svg")}
                  alt="insert-photos"
                  className="img-insert-photos"
                />
                <input
                  type="file"
                  name="album"
                  accept=".png, .jpg, .jpeg"
                  multiple
                  onChange={(e) => ReadUri(false, e)}
                />
              </div>
            </div>
          </div>
        </div>
        {albumError && <div className="invalid-feedback">{albumError}</div>}
      </div>

      <div className="form-group text-right">
        <button
          type="reset"
          className="btn btn-secondary mr-2 rounded-pill text-uppercase"
        >
          Hủy
        </button>
        <button
          type="submit"
          className="btn btn-success rounded-pill text-uppercase"
        >
          Tạo gian hàng
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreateShop: shopActions.AddOrUpdateShop,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterShopDesktopView);
