import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../advertising-desktop.scss";
import * as residentMarketActions from "redux/stores/resident/resident-market.store";
import AddNotification from "components/react-notifications/react-notifications";
import * as viVN from "translation/vi-VN.json";
import { NotificationMessageType } from "utils/configuration";
import { Dropdown, Modal, Button } from "react-bootstrap";
import moment from "moment";
import ResidentMarketAttachmentDesktopView from "./resident-market-attachment.view";
import ResidentMarketCommentDesktopView from "./resident-market-comment.view";
import EditResidentMarketDesktopView from "../resident-market-actions/edit-resident-market-desktop.view";
import { Link } from "react-router-dom";
import { JwtHelper } from "utils/jwt-helper";
import { SetDefaultErrorImage } from "utils/configuration";
import ShowMoreText from "react-show-more-text";

moment.locale("vi");
class ResidentMarketItemDesktopView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenEditPopup: false,
      isOpenDeletePopup: false,
      selectedAdId: null,
      currentUser: JwtHelper.currentUser(),
    };
  }

  UNSAFE_componentWillMount() { }

  onToggleEditPopup = (isOpen, adId) => {
    this.setState({
      isOpenEditPopup: isOpen,
      selectedAdId: adId,
    });
  };

  onToggleDeletePopup = (isOpen, adId) => {
    this.setState({
      isOpenDeletePopup: isOpen,
      selectedAdId: adId,
    });
  };

  onDeleteResidentMarketPost = (isConfirm) => {
    if (isConfirm) {
      this.props.deleteResidentMarket(this.state.selectedAdId).then(
        (res) => {
          this.onToggleDeletePopup(false);
          AddNotification(
            "Xoá bài viết thành công!",
            NotificationMessageType.Success
          );
          this.props.callBack && this.props.callBack();
        },
        (err) => {
          this.onToggleDeletePopup(false);
          //TODO: show err message
        }
      );
    }
  };

  render() {
    const { residentMarketList, loggedIn } = this.props;
    const {
      isOpenDeletePopup,
      isOpenEditPopup,
      currentUser,
      selectedAdId,
    } = this.state;
    return (
      <div className="resident-market-container">
        {residentMarketList.map((item) => (
          <div key={item.adId} className="item bg-white p-3">
            <div className="row no-gutters">
              <div className="col-1">
                <img
                  src={item.profileUrl || require("assets/images/29-01.png")}
                  onError={SetDefaultErrorImage}
                  className="img-fluid img-avatar"
                  alt="Avatar"
                />
              </div>
              <div className="col-10 pl-3">
                <h5 className="fullname mb-0 mt-1">
                  <Link to={`/rao-vat/cho-cu-dan/chi-tiet/${item.adId}`}>
                    {" "}
                    {item.customerName}
                  </Link>
                </h5>
                <span className="datetime">
                  {moment(item.createDate).fromNow()}
                </span>
              </div>
              {loggedIn && item.customerId == currentUser.customerId && (
                <div className="col-1 text-right">
                  <Dropdown alignRight>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img
                        src={require("assets/images/more.svg")}
                        className="img-fluid img-more"
                        alt="more"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.onToggleEditPopup(true, item.adId)}
                      >
                        <img
                          src={require("assets/images/pen.svg")}
                          className="img-fluid img-pen"
                          alt="pen"
                        />
                        <span>Chỉnh sửa bài viết</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          this.onToggleDeletePopup(true, item.adId)
                        }
                      >
                        <img
                          src={require("assets/images/delete.svg")}
                          className="img-fluid img-delete"
                          alt="pen"
                        />
                        <span>Xóa</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            <div className="description">
              <ShowMoreText
                lines={8}
                more="Xem thêm"
                less="Ẩn bớt"
                expanded={false}
              >
                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
              </ShowMoreText>
            </div>
            {/* Render images */}
            <ResidentMarketAttachmentDesktopView
              attachments={item.adFile || []}
              adId={item.adId}
            />

            <div className="comments">
              {/* Comments */}
              <ResidentMarketCommentDesktopView
                adId={item.adId}
              />
            </div>
          </div>
        ))}
        <Modal
          show={isOpenEditPopup}
          className="modal-edit"
          centered
          size="lg"
          onHide={() => this.onToggleEditPopup(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chỉnh sửa bài viết</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditResidentMarketDesktopView
              adId={selectedAdId}
              onToggleEditPopup={this.onToggleEditPopup.bind(this)}
              callBack={this.props.callBack}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={isOpenDeletePopup}
          className="modal-delete"
          centered
          onHide={() => this.onToggleDeletePopup(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Xóa bài viết?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Bài viết sẽ không thể khôi phục sau khi xóa.
            <br />
            Bạn có chắc muốn xóa bài viết này.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.onToggleDeletePopup(false)}
            >
              Hủy
            </Button>
            <Button
              variant="success"
              onClick={() => this.onDeleteResidentMarketPost(true)}
            >
              Xóa
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteResidentMarket: residentMarketActions.DeleteResidentMarket,
      deleteResidentMarketFake: residentMarketActions.DeleteResidentMarketFake,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentMarketItemDesktopView);
