import { Map } from 'immutable';
import { createFormData, AppKey, NewGuid } from 'utils/configuration';
import Service from 'services/api.service';
import { ApiUrl } from 'services/api-url';
import { createSaveLoading } from 'core/app.store';
import { ResidentMarkets } from 'mock-data/resident-market.mock';
let residentMarketsTemp = ResidentMarkets;

const service = new Service();
/** Action Types */
const RESIDENTMARKETSAVELIST = 'RESIDENTMARKET/SAVELIST';
const RESIDENTMARKETSAVELISTCOMMENTS = 'RESIDENTMARKET/SAVELISTCOMMENTS';


/** Actions */
export const createSaveResidentMarketList = (residentMarketModel) => ({ type: RESIDENTMARKETSAVELIST, payload: { residentMarkets: residentMarketModel.residentMarkets, totalItems: residentMarketModel.totalItems } });
export const createSaveResidentMarketListComments = (commentModel) => ({ type: RESIDENTMARKETSAVELISTCOMMENTS, payload: { residentMarkets: commentModel.residentMarkets, totalItems: commentModel.totalItems } });

/** Action creators */
export const GetResidentMarketList = (pageIndex, size, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        let filterObjDatas = { ...filterObjs, orderBy: 'create_date', orderType: 'desc' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ResidentMarketGetList, payLoad).then(res => {
            dispatch(createSaveResidentMarketList({ residentMarkets: [], totalItems: 0 }))
            dispatch(createSaveLoading(false));
            dispatch(createSaveResidentMarketList({ residentMarkets: res.content && res.content.items || [], totalItems: res.content && res.content.totalItemCount || 0 }))
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetResidentMarketDetail = (residentMarketId) => {
    return dispatch => {
        let payLoad = createFormData({ adId: residentMarketId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ResidentMarketGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetResidentMarketInfo = (residentMarketId) => {
    return dispatch => {
        let payLoad = createFormData({ adId: residentMarketId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ResidentMarketGetDetail, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const DeleteResidentMarket = (residentMarketId) => {
    return dispatch => {
        let payLoad = createFormData({ adId: residentMarketId });
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ResidentMarketDelete, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const CreateOrUpdateResidentMarket = (residentMarketModel) => {
    return dispatch => {
        const { ad_file, ...model } = residentMarketModel;
        const { delete_file, ...newModel } = model;
        let formFileData = createFormData({ 'ad_file': ad_file });
        let newFormFileData = createFormData({ 'delete_file': delete_file }, null, formFileData);
        let payLoad = createFormData(newModel, AppKey.PostPayloadPrefix, newFormFileData);
        dispatch(createSaveLoading(true));
        return service.post(ApiUrl.ResidentMarketCreateOrUpdate, payLoad).then(res => {
            dispatch(createSaveLoading(false));
            //TODO: do something 
            return res;
        }).catch(err => {
            dispatch(createSaveLoading(false));
            throw (err);
        })
    }
}

export const GetResidentMarketComments = (pageIndex, size, adId, filterObjs) => {
    return dispatch => {
        let formData = new FormData();
        formData.append('pageIndex', pageIndex);
        formData.append('size', size);
        formData.append('adId', adId);
        let filterObjDatas = { ...filterObjs, orderBy: 'create_date' };
        let payLoad = createFormData(filterObjDatas, AppKey.GetPayloadPrefix, formData);
        return service.post(ApiUrl.ResidentMarketGetListComment, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const CreateOrUpdateComment = (commentModel) => {
    return dispatch => {
        let payLoad = createFormData(commentModel, AppKey.PostPayloadPrefix);
        return service.post(ApiUrl.ResidentMarketCreateOrUpdateComment, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

export const DeleteComment = (commentId) => {
    return dispatch => {
        let payLoad = createFormData({ commentId: commentId });
        return service.post(ApiUrl.ResidentMarketDeleteComment, payLoad).then(res => {
            return res;
        }).catch(err => {
            throw (err);
        })
    }
}

/** Fake */

export const GetResidentMarketListFake = (pageIndex, size, filterObjs) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            residentMarketsTemp.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            })
            let residentMarketList = residentMarketsTemp.slice(pageIndex * size - size, pageIndex * size);
            if (residentMarketList) {
                dispatch(createSaveResidentMarketList({ residentMarkets: residentMarketList, totalItems: ResidentMarkets.length }))
                resolve({
                    content: {
                        items: residentMarketList,
                        totalItems: ResidentMarkets.length
                    },
                    errorType: "",
                    errorMessage: "",
                });
            } else {
                resolve({
                    content: [],
                    errorType: "Empty",
                    errorMessage: "Empty",
                });
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetResidentMarketDetailFake = (residentMarketId) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let residentMarketDetail = residentMarketsTemp.find(fr => fr.post_id == residentMarketId);
            if (residentMarketDetail) {
                resolve({
                    content: residentMarketDetail,
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const GetResidentMarketInfoFake = (residentMarketId) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            let residentMarketDetail = residentMarketsTemp.find(fr => fr.post_id == residentMarketId);
            if (residentMarketDetail) {
                resolve({
                    content: {
                        post_id: residentMarketDetail.post_id,
                        created_by: residentMarketDetail.created_by,
                        created_by_id: residentMarketDetail.created_by_id,
                        created_date: residentMarketDetail.created_date,
                        post_content: residentMarketDetail.post_content,
                        is_delete: residentMarketDetail.is_delete,
                        profile_url: residentMarketDetail.profile_url,
                        attachments: residentMarketDetail.attachments
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                resolve({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
            dispatch(createSaveLoading(false));
        })
    }
}

export const CreateOrUpdateResidentMarketFake = (residentMarketModel) => {
    return (dispatch) => {
        dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let newModel;
                let currentIndex = residentMarketsTemp.findIndex(fr => fr.post_id == residentMarketModel.post_id);
                if (currentIndex >= 0) {
                    residentMarketsTemp[currentIndex] = residentMarketModel;
                } else {
                    newModel = { ...residentMarketModel, post_id: NewGuid() };
                    residentMarketsTemp.push(newModel);
                }
                residentMarketsTemp.sort((a, b) => {
                    return new Date(b.created_date) - new Date(a.created_date);
                });
                dispatch(createSaveResidentMarketList({ residentMarkets: residentMarketsTemp, totalItems: residentMarketsTemp.length }))
                resolve({
                    content: newModel,
                    errorType: "",
                    errorMessage: "",
                })
                dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const DeleteResidentMarketFake = (residentMarketId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let currentresidentMarket = residentMarketsTemp.find(item => item.post_id == residentMarketId);
            let newresidentMarketList = residentMarketsTemp.filter(item => item.post_id != residentMarketId);
            newresidentMarketList.sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            });
            residentMarketsTemp = newresidentMarketList;
            dispatch(createSaveResidentMarketList({ residentMarkets: residentMarketsTemp.slice(0, 10), totalItems: residentMarketsTemp.length }))
            if (currentresidentMarket) {
                resolve({
                    content: {
                        residentMarketId: residentMarketId,
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                reject({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

export const CreateOrUpdateCommentFake = (commentModel) => {
    return (dispatch) => {
        // dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let currentPostIndex = residentMarketsTemp.findIndex(res => res.post_id == commentModel.post_id);
                if (currentPostIndex >= 0) {
                    let newComments = [];
                    let currentCommentIndex = residentMarketsTemp[currentPostIndex].comments.findIndex(cm => cm.comment_id == commentModel.id);
                    if (currentCommentIndex >= 0) {
                        let updatedComment = { ...commentModel, created_date: new Date() };
                        newComments = [...residentMarketsTemp[currentPostIndex].comments]
                        newComments[currentCommentIndex] = updatedComment;
                    } else {
                        newComments = [
                            ...residentMarketsTemp[currentPostIndex].comments,
                            {
                                ...commentModel,
                                comment_id: NewGuid(),
                                comment_date: new Date()
                            }];
                    }
                    residentMarketsTemp[currentPostIndex].comments = newComments;
                    dispatch(createSaveResidentMarketList({ residentMarkets: residentMarketsTemp.slice(0, 10), totalItems: residentMarketsTemp.length }))
                    resolve({
                        content: {
                            post_id: commentModel.post_id,
                        },
                        errorType: "",
                        errorMessage: "",
                    })
                } else {
                    reject({
                        content: null,
                        errorType: "NotFound",
                        errorMessage: "Empty",
                    })
                }
                // dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const CreateOrUpdateReplyFake = (replyModel) => {
    return (dispatch) => {
        // dispatch(createSaveLoading(true));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let currentPostIndex = residentMarketsTemp.findIndex(res => res.post_id == replyModel.post_id);
                if (currentPostIndex >= 0) {
                    let currentComments = residentMarketsTemp[currentPostIndex].comments.find(cm => cm.comment_id == replyModel.comment_id);

                    if (!currentComments) {
                        reject({
                            content: null,
                            errorType: "NotFound",
                            errorMessage: "Empty",
                        })
                        return;
                    }
                    let commentIndex = residentMarketsTemp[currentPostIndex].comments.findIndex(cm => cm.comment_id == replyModel.comment_id);
                    let newComments, newReplies = [];
                    let currentReplyIndex = currentComments.replies.findIndex(rp => rp.reply_id == replyModel.id);
                    if (currentReplyIndex >= 0) {
                        let updatedReply = { ...replyModel, created_date: new Date() };
                        newComments = [...residentMarketsTemp[currentPostIndex].comments]
                        newComments[commentIndex].replies[currentReplyIndex] = updatedReply;
                    } else {
                        newReplies = [
                            ...residentMarketsTemp[currentPostIndex].comments.find(cm => cm.comment_id == replyModel.comment_id).replies,
                            {
                                ...replyModel,
                                reply_id: NewGuid(),
                                reply_date: new Date()
                            }];
                        newComments = [...residentMarketsTemp[currentPostIndex].comments];
                        newComments[commentIndex].replies = [...newReplies];
                    }
                    residentMarketsTemp[currentPostIndex].comments = newComments;
                    dispatch(createSaveResidentMarketList({ residentMarkets: residentMarketsTemp.slice(0, 10), totalItems: residentMarketsTemp.length }))
                    resolve({
                        content: {
                            post_id: replyModel.post_id,
                        },
                        errorType: "",
                        errorMessage: "",
                    })
                } else {
                    reject({
                        content: null,
                        errorType: "NotFound",
                        errorMessage: "Empty",
                    })
                }
                // dispatch(createSaveLoading(false));
            }, 1000)
        })
    }
}

export const DeleteCommentFake = (commendModel) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let currentPostIndex = residentMarketsTemp.findIndex(res => res.post_id == commendModel.post_id);
            if (currentPostIndex >= 0) {
                let newComments = [];
                if (commendModel.type == 'comment') {
                    newComments = [...residentMarketsTemp[currentPostIndex].comments.filter(cm => cm.comment_id != commendModel.id)];
                } else {
                    let currentCommentIndex = residentMarketsTemp[currentPostIndex].comments.findIndex(cm => cm.comment_id == commendModel.comment_id);
                    let newReplies = [...residentMarketsTemp[currentPostIndex].comments.find(cm => cm.comment_id == commendModel.comment_id).replies.filter(rp => rp.reply_id != commendModel.id)];
                    newComments = [...residentMarketsTemp[currentPostIndex].comments];
                    newComments[currentCommentIndex].replies = [...newReplies];
                }
                residentMarketsTemp[currentPostIndex].comments = newComments;
                dispatch(createSaveResidentMarketList({ residentMarkets: residentMarketsTemp.slice(0, 10), totalItems: residentMarketsTemp.length }))
                resolve({
                    content: {
                        post_id: commendModel.post_id,
                    },
                    errorType: "",
                    errorMessage: "",
                })
            } else {
                reject({
                    content: null,
                    errorType: "NotFound",
                    errorMessage: "Empty",
                })
            }
        })
    }
}

// Initial state
export const initialState = Map({
    residentMarketList: [],
    totalItems: 0
}).toJS();

/** Reducers */
export default function residentMarketRentReducer(state = initialState, action) {
    switch (action.type) {
        case RESIDENTMARKETSAVELIST:
            return {
                ...state,
                residentMarketList: [...action.payload.residentMarkets],
                totalItems: action.payload.totalItems,
            }
        default:
            return state;
    }
}
