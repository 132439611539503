/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './resident-market-mobile.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as residentMarketActions from 'redux/stores/resident/resident-market.store';
import ResidentMarketItemMobileView from './childs/resident-market-item-mobile.view';
import PaginationControl from 'components/pagination/pagination.view';

class ResidentMarketMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageIndex: 1,
            isLoading: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.onGetResidentMarketList();
    }

    onGetResidentMarketList = (pageIndex = this.state.currentPageIndex, size = 10, filterObjs = null) => {
        this.setState({ isLoading: true });
        this.props.getResidentMarketList(pageIndex, size, filterObjs).then(
            res => {
                this.setState({ isLoading: false });
            },
            err => {
                this.setState({ isLoading: false });
            }
        );
    };

    onSelectPage = (pageNumber) => {
        if (pageNumber < 0) {
            return;
        }
        this.setState({
            currentPageIndex: pageNumber
        }, () => {
            this.onGetResidentMarketList(pageNumber);
        })
    }

    render() {
        const { residentMarketList, totalItems, loggedIn } = this.props;
        const { currentPageIndex } = this.state;
        
        return (
            <div className="resident-market-mobile p-3">
                {loggedIn &&
                    <Link to="/m/cho-cu-dan/them-san-pham" className="btn btn-success rounded-pill btn-add-resident-market">
                        <img src={require('assets/images/report-problem-white.svg')} alt="report-problem-white" className="img-report-problem-white" /> Thêm sản phẩm
                 </Link>
                }

                <div className="resident-market mt-3">
                    <ResidentMarketItemMobileView residentMarketList={residentMarketList} callBack={this.onGetResidentMarketList.bind(this)} />
                    <PaginationControl pageChanged={this.onSelectPage.bind(this)} totalItems={totalItems} currentPageIndex={currentPageIndex} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.isLoggedIn,
    residentMarketList: state.residentMarket.residentMarketList,
    totalItems: state.residentMarket.totalItems
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getResidentMarketList: residentMarketActions.GetResidentMarketList,
    deleteResidentMarket: residentMarketActions.DeleteResidentMarket,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ResidentMarketMobileView)