/* eslint-disable no-unused-vars */
import { Map } from "immutable";
import { createSaveLoading } from "core/app.store";
import { createFormData, AppKey } from "utils/configuration";
import Service from "services/api.service";
import { ApiUrl } from "services/api-url";

const service = new Service();

/** Action Types */
const HOT_SAVE_LIST = "HOT/SAVE_LIST";
const HOT_GET_LIST = "HOT/GET_LIST";

/** Actions */
export const createSaveHotList = (hotModel) => ({
  type: HOT_SAVE_LIST,
  payload: {
    hots: hotModel.items,
    totalItems: hotModel.totalItemCount,
  },
});
export const createGetHotList = () => ({
  type: HOT_GET_LIST,
});

/** Action creators */
export const GetHotList = (pageIndex, size, filterObjs) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    let payLoad = createFormData(filterObjs, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.HotGetList, payLoad)
      .then((res) => {
        dispatch(createSaveHotList(res.content));
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetHotDetail = (hotId, shopTypeId = "") => {
  return (dispatch) => {
    let payLoad = createFormData({
      hotId: hotId,
      // category_id: shopTypeId,
    });
    return service
      .post(ApiUrl.HotGetDetail, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateHot = (data, formData = new FormData()) => {
  return (dispatch) => {
    dispatch(createSaveLoading(true));
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix, formData);
    return service
      .post(ApiUrl.HotAddOrUpdate, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

export const HotDelete = (hotId) => {
  return (dispatch) => {
    dispatch(createSaveLoading(true));
    let payLoad = createFormData({
      hotId: hotId,
    });
    return service
      .post(ApiUrl.HotDelete, payLoad)
      .then((res) => {
        dispatch(createSaveLoading(false));
        return res;
      })
      .catch((err) => {
        dispatch(createSaveLoading(false));
        throw err;
      });
  };
};

//--- Comment
export const GetListComment = (pageIndex, size, filterObj, id, parentId) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("hotId", id);
    formData.append("pageIndex", pageIndex);
    formData.append("size", size);
    parentId && formData.append("parent_id", parentId);
    let payLoad = createFormData(filterObj, AppKey.GetPayloadPrefix, formData);
    return service
      .post(ApiUrl.GetListCommentHot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetCommentDetail = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      commentId: id,
    });
    return service
      .post(ApiUrl.GetCommentHot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const AddOrUpdateComment = (data) => {
  return (dispatch) => {
    let payLoad = createFormData(data, AppKey.PostPayloadPrefix);
    return service
      .post(ApiUrl.UpdateCommentHot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const CommentDelete = (id) => {
  return (dispatch) => {
    let payLoad = createFormData({
      commentId: id,
    });
    return service
      .post(ApiUrl.DeleteCommentHot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const UpdateLike = (data) => {
  return (dispatch) => {
    let payLoad = createFormData({ hot_id: data }, AppKey.PostPayloadPrefix);
    return service
      .post(ApiUrl.UpdateLikeHot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const IsLike = (data) => {
  return (dispatch) => {
    let payLoad = createFormData({ hot_id: data }, AppKey.GetPayloadPrefix);
    return service
      .post(ApiUrl.IsLikeHot, payLoad)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

// Initial state
export const initialState = Map({
  hotList: [],
  totalItems: 0,
}).toJS();

/** Reducers */
export default function HotReducer(state = initialState, action) {
  switch (action.type) {
    case HOT_SAVE_LIST:
      return {
        ...state,
        hotList: action.payload.hots,
        totalItems: action.payload.totalItems,
      };
    case HOT_GET_LIST:
      return state.hotList;
    default:
      return state;
  }
}
