import React, {  useEffect } from 'react';
import './notification-resident-mobile.scss';
import { PromiseAllTools } from 'core/app.store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as NotificationStore from 'redux/stores/notification/notifications.store';
import Pagination from 'components/pagination/paging.view.jsx';
import RenderItem from './notification-resident-mobile-item.view.jsx';

function NotificationResidentMobileView(props) {
        useEffect(() => {
            props.PromiseAllTools([props.GetNeightborNotificationList()])
        }, [])
        const listData = (props.neightborNotification.haveData) ? props.neightborNotification.listData : [];
        return (
            <div>
                {listData.map((data, index) => <RenderItem data={data} key={index} />)}
                {(props.neightborNotification.haveData) && <Pagination totalItems={props.neightborNotification.total} size={props.neightborNotification.pageSize} goTo={(i) => props.PromiseAllTools([props.GetNeightborNotificationList(i)])} />}
            </div>
        )
    }


const mapStateToProps = state => ({
    neightborNotification: state.notifications.neightborNotification,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetNeightborNotificationList: NotificationStore.GetNeightborNotificationList,
    PromiseAllTools
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationResidentMobileView);