/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "core/auth.store";
import * as loginActions from "redux/stores/user/login.store";
import * as projectActions from "redux/stores/project/project.store";
import { useForm } from "react-hook-form";
import { history } from "redux/store";
import AddNotification from "components/react-notifications/react-notifications";
import { NotificationMessageType, ScreenTypeMobile, TokenKey, setCookies, getCookies, isInitSubdomain } from "utils/configuration";
import * as viVN from "translation/vi-VN.json";
import { JwtHelper } from "utils/jwt-helper";
import AsyncSelect from 'react-select/async';

import "./login-mobile.scss";

const currentDomainList = window.location.host.split(".");
const HOSTNAME = window.location.host;

class LoginMobileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectList: [],
      projectListTemp: []
    };
  }

  UNSAFE_componentWillMount() {
    this.onGetProjectList();
  }

  onGetProjectList = () => {
    this.props.onGetProjectList().then(
      (res) => {
        if (res && res.content && res.content.length > 0) {
          this.setState({
            projectList: res.content.map((item) => {
              return { value: item.clientId, label: item.clientName };
            }),
            projectListTemp: res.content
          });
        }
      },
      (err) => { }
    );
  };

  render() {
    return (
      <div className="text-center">
        <img
          src={require("assets/images/logo.png")}
          alt="Logo"
          className="img-fluid img-logo"
        />
        <LoginDesktopForm
          projectList={this.state.projectList}
          projectListTemp={this.state.projectListTemp}
          onLogin={this.props.login}
          onSaveUserInfo={this.props.saveUserInfo}
        />
        <div className="info">
          Cung cấp bởi CTech - © Copyright 2020
        </div>
      </div>
    );
  }
}

function LoginDesktopForm(props) {
  const { onLogin, onSaveUserInfo, projectList, projectListTemp } = props;
  const [project, setProject] = useState({ value: getCookies(TokenKey.Project) || "", label: getCookies(TokenKey.ProjectName) || "" });
  const [firstLoad, setFirstLoad] = useState(true);
  const [placeholderAsync, setPlaceholderAsync] = useState(getCookies(TokenKey.ProjectName) || "Chọn dự án (*)");

  const { register, errors, handleSubmit, setError, clearError } = useForm({
    mode: "onChange",
  });

  const onChangeProject = (data) => {
    if (data) {
      setProject(data);
      clearError(["project"]);
    } else {
      setError("project", "required", "Trường này là bắt buộc");
    }
  };

  const onLoginWithoutAccount = (e) => {
    if (project && project.value && project.label) {
      setCookies(TokenKey.Project, project.value);
      setCookies(TokenKey.ProjectName, project.label);

      if (isInitSubdomain) {
        let projectTemp = projectListTemp.filter(x => x.clientId == project.value);
        if (projectTemp && projectTemp.length > 0 && projectTemp[0].domain && currentDomainList && currentDomainList.length > 0) {
          let projectTempList = projectTemp[0].domain.split(".");
          if (projectTempList[0] != currentDomainList[0]) {
            window.location.replace("http://" + projectTemp[0].domain);
          } else {
            window.location.replace("http://" + HOSTNAME);
          }
        } else {
          window.location.replace("http://" + HOSTNAME);
        }
      } else {
        window.location.replace("http://" + HOSTNAME);
      }
    } else {
      setError("project", "required", "Trường này là bắt buộc");
      clearError(["username", "password"]);
    }
  };

  const onSubmit = (data) => {
    if (data) {
      onLogin({
        username: data.username,
        password: data.password,
        clientId: data.project,
      }).then(
        (res) => {
          if (res && res.content) {
            onSaveUserInfo(res.content);
            setCookies(TokenKey.Project, project.value);
            setCookies(TokenKey.ProjectName, project.label);

            if (isInitSubdomain) {
              let projectTemp = projectListTemp.filter(x => x.clientId == project.value);
              if (projectTemp && projectTemp.length > 0 && projectTemp[0].domain && currentDomainList && currentDomainList.length > 0) {
                let projectTempList = projectTemp[0].domain.split(".");
                if (projectTempList[0] != currentDomainList[0]) {
                  window.location.replace("http://" + projectTemp[0].domain);
                } else {
                  window.location.replace("http://" + HOSTNAME);
                }
              } else {
                window.location.replace("http://" + HOSTNAME);
              }
            } else {
              window.location.replace("http://" + HOSTNAME);
            }
          }
        },
        (err) => {
          if (err.errorType == "NotActiveError") {
            setCookies(TokenKey.Project, project.value);
            setCookies(TokenKey.ProjectName, project.label);
            history.push(
              "/m/xac-nhan/" +
              ScreenTypeMobile.Active +
              "&&" +
              JwtHelper.encodeStringBase64(data.username.trim())
            );
          } else {
            AddNotification(
              viVN.Errors[err && err.errorType],
              NotificationMessageType.Error
            );
          }
        }
      );
    }
  };

  const filterProject = (inputValue) => {
    if (inputValue && inputValue.length > 3) {
      return projectList.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      return [];
    }
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      inputValue && firstLoad && setFirstLoad(false);
      resolve(filterProject(inputValue));
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-login">
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <AsyncSelect
              className={"react-select-container" + (firstLoad ? " first-load" : "")}
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions
              onChange={(data) => onChangeProject(data)}
              loadOptions={promiseOptions}
              noOptionsMessage={() => "Không có kết quả"}
              placeholder={placeholderAsync} />
            <input
              name="project"
              type="hidden"
              value={project && project.value}
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            />
            {errors.project && (
              <div className="invalid-feedback">{errors.project.message}</div>
            )}
          </div>

          <div className="form-group position-relative">
            <img
              src={require("assets/images/user-login.svg")}
              alt="User Login"
              className="img-user-login"
            />
            <input
              name="username"
              type="text"
              placeholder="Email hoặc số điện thoại (*)"
              className="form-control"
              autoComplete="off"
              ref={register({
                required: "Trường này là bắt buộc",
              })}
            />
            {errors.username && (
              <div className="invalid-feedback">{errors.username.message}</div>
            )}
          </div>

          <div className="form-group position-relative">
            <img
              src={require("assets/images/password.svg")}
              alt="User Login"
              className="img-password"
            />
            <input
              name="password"
              type="password"
              placeholder="Mật khẩu (*)"
              className="form-control"
              autoComplete="off"
              ref={register({
                required: "Trường này là bắt buộc",
                minLength: {
                  value: 6,
                  message: "Mật khẩu phải có ít nhất 6 ký tự",
                },
              })}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password.message}</div>
            )}
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-login text-uppercase">
              Đăng nhập
            </button>
          </div>

          <div className="form-group mb-2">
            <Link
              to="#"
              className="btn btn-past-login text-uppercase"
              onClick={onLoginWithoutAccount.bind(this)}
            >
              Truy cập không cần tài khoản
            </Link>
          </div>

          <div className="form-group text-center mb-2">
            <Link to="/m/quen-mat-khau" className="fogot-password">
              Quên mật khẩu?
            </Link>
          </div>

          <div className="form-group text-center">
            <Link to="/m/dang-ky" className="fogot-password">
              Đăng ký?
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: authActions.Login,
      saveUserInfo: loginActions.createSaveLoginInfo,
      onGetProjectList: projectActions.GetProjectList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginMobileView);
