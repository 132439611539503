//@ts-check
import React from 'react';
import RenderShopListPageViewDesktop from '../child-component/shop-list-page/shop-list-page.view';
import * as PageUrls from '../../PageUrls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UtilitiseStore from '../../../redux/stores/utillities/utilitis-shop.store';
import { useEffect } from 'react';

/**
 * @param {{total:number,GetShopList:Function,cafeList:{
 * logoUrl: string,
 name: string,
 address: string,
 id: number,
 type: string,}[]}} [props]
*/
function UtilitiesCafeListPageDesktop(props) {
    const PAGESIZE = 8;
    const PAGEINDEX = 1;
    useEffect(() => {
        props.GetShopList(PAGEINDEX, PAGESIZE, UtilitiseStore.CAFEKEY)
    }, [])
    const getDataByPageIndex = (index) => props.GetShopList(index, PAGESIZE, UtilitiseStore.CAFEKEY)
    return <RenderShopListPageViewDesktop
        currentTypeUrl={PageUrls.UtilitiesCafeHomeDeskTop}
        pageSize={PAGESIZE}
        pageIndex={1}
        totalItems={props.total}
        getDataByPageIndex={getDataByPageIndex}
        listData={props.cafeList} detailUrl={PageUrls.UtilitiesCafeDetailDeskTop} titleShopType="Cafe" />
}

const mapStateToProps = state => ({
    cafeList: state.utilitiShop.cafeList.listShop,
    total: state.utilitiShop.cafeList.total,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    GetShopList: UtilitiseStore.GetShopList,
},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesCafeListPageDesktop)